import { db } from '../firebase'


export default {
  async loadSettings () {
    let response
    try {
      response = {
        errorMsg: ''
      }
    
     
      const settingsDoc = await db.collection('appSettings').doc('girlap').get()
  

      response.appSettings = {
        ghinToken: settingsDoc.data().ghinToken,
        userToken: settingsDoc.data().userToken
      }

 
    }
    catch (error) {
      response = {
        errorMsg: 'Get app settings ' + error.code + ' ' + error.message
      }
    }
    
    return response

  }
  
  
  

  
  
  
  
}
