import { sortArrayObj} from '../lib'
import Game from './game'

export default class Games {
  constructor() {
    this.initialize()
  }
  initialize () {
    this.aGames = []
  }

  get (id) {
    let game = this.aGames.find(g=>g.id===id)
    if (game === undefined) game = null
    return game
  }

  getByProp(prop) {
    let game = this.aGames.find(g=>g.prop===prop)
    if (game === undefined) game = null
    return game
  }

  getNextId () {
    let maxId = 0
    let id = 0
    for (let game of this.aGames) {
      id = Number(game.id)
      if (id > maxId) maxId = id
    }
    maxId++

    return maxId.toString()
  }
 
  get totalBet () {
    let total = 0
    if (this.aGames.length > 0) {
      total = this.aGames.map(game => game.bet.amount).reduce((total, amount) => total + amount)
    }
    return total
  }
  totalPool (numPlayers) {
    // let total = 0
    // if (this.aGames.length > 0) {
    //   total = this.aGames.map(game => game.bet.pool).reduce((total, amount) => total + amount)
    // }
    return this.totalBet * numPlayers
  }

  hasStrokeGame (side='full18') {
    return this.aGames.findIndex(g=>g.prop==='stroke' && g.side===side) >= 0
  }

  hasScrambleGame (side='full18') {
    return this.aGames.findIndex(g=>g.prop==='stroke' && g.side===side && g.format==='scramble') >= 0
  }

  has123Game (side='full18') {
    return this.aGames.findIndex(g=>g.prop==='stroke' && g.side===side && g.format==='123') >= 0
  }

  hasGame (gameProp) {
    let hasGame = false
    
    hasGame = this.aGames.findIndex(g=>g.prop===gameProp) >= 0
    
    return hasGame
  }

  hasStablefordGame () {
    let hasGame = false
   
    hasGame = this.aGames.findIndex(g=>g.isStableford === true) >= 0
    
    return hasGame
  }


  hasUnassignedBlindDraws () {
   
    return this.aGames.some(g=>g.hasUnassignedBlindDraws()===true)
  }

  assignScores (scoresArray) {
    for(let game of this.aGames) {
      game.assignScores(scoresArray)
    }
  }

  get hasTeamGame () {
    let hasTeamGame = false
    for (let game of this.aGames) {
      if (game.isTeamGame) {
        hasTeamGame = true
        break
      }
    }
    return hasTeamGame
  }

  gameExists (id) {

    let game = this.aGames.find(g=>g.id===id)
    const exists = (game !== undefined)
    return exists
  }

  get size () {
    return this.aGames.length
  }

  // getMaxSeq () {
   
  //   let nSeq = 0
  //   for (let game in this.aGames) {
  //     if (game.seq.length > 0) {
  //       let seq = Number(game.seq)
  //       if (seq >= nSeq) nSeq = seq + 1
  //     }
  //   }
  //   return nSeq + ''
  // }

  add (game) {
    if (game.id === '') {
      game.id = this.getNextId()
    }
    this.aGames.push(game)
  }

  addNew () {
    const game = new Game(this.getNextId())
    this.aGames.push(game)
    return game
  }
  
  deleteByIndex (index) {
    this.aGames.splice(index,1)
  }

  deleteById (id) {
    const index = this.aGames.findIndex(g=>g.id===id)
    if (index >= 0) this.deleteByIndex(index)
  }
  
  sort (field) {
    
    sortArrayObj (this.aGames, field)
  }
  copy () {
    const games = new Games()
    games.setObj(this.getObj())
    return games
  }
  toJSON () {
    return JSON.stringify(this.getObj())
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  getObj () {
    return this.aGames.map(game => game.getObj())
  }
  setObj (data) {
    this.aGames = data.map(gameObj => {
      const game = new Game()
      game.setObj(gameObj)
      return game
    })
  
  }
  getData () {
    return this.aGames.map(game => game.getData())
  }
  setData (gamesData) {
    this.aGames = []
    if (gamesData !== undefined) {
      for (let gameData of gamesData) {
        const game = new Game()
        game.setData (gameData)
        this.add (game)
      }
    }
   
 
    
  }
}

