import TournamentController from '../controllers/tournament'
import Scores from '../models/scores'

import router from '../router'

import { createTournamentLeaderboards, createRoundLeaderboards, createPayouts }  from '../helpers/leaderBoards'
import DBUpdates from '../helpers/dbUpdates'
import { sortArrayObj } from '../lib'


export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: {
    tournament: null,
    leaderBoards: {},
    roundLeaderBoards: {},
    scores: new Scores(),
    payOuts: [],
 
    selectedGameId: '1',
    unSubOnTournament: null,
    unSubOnRounds: null,
    unSubOnTournamentScores: null,
    from: '',
    tab: 0,
    errorMsg: ''
  },
  // -----------------------------------------------------------------
  getters: {

    tournament (state) {
      return state.tournament
    },
    scores (state) {
    
      return state.scores
    },
    scoresArray (state) {
      if (state.scores) {
        return state.scores.aScores
      }
      else {
        return []
      }
    },
    errorMsg (state) {
      return state.errorMsg
    },
    tab (state) {
      return state.tab
    },
    selectedGameId (state) {
      return state.selectedGameId
    },
    rounds (state) {
      return state.rounds
    },
    
    from (state) {
      return state.from
    },
    leaderBoards (state) {
      return state.leaderBoards
    },
    roundLeaderBoards (state) {
      return state.roundLeaderBoards
    },
   
    payOuts (state) {
      return state.payOuts
    },


    
  },
  // -----------------------------------------------------------------
  mutations: {
    initialize (state) {
      state.tournament = null
      state.tab = 0
      state.errorMsg = ''

    },
    tournament (state, tournament) {
      state.tournament = tournament
    },
    scores (state, scores) {
      state.scores = scores
    },
    from (state, from) {
      state.from = from
    },
    selectedGameId (state, val) {
      state.selectedGameId = val
    },
    unsubscribeTournament (state) {
      if (state.unSubOnTournament) {
 
        state.unSubOnTournament ()
        state.unSubOnTournament = null
      }
    },

    subscribeTournament (state, fn) {
      state.unSubOnTournament = fn
    },
  
    subscribeRounds (state, fn) {
      state.unSubOnRounds = fn
    },

    unsubscribeRounds (state) {
      if (state.unSubOnRounds) {
 
        state.unSubOnRounds ()
        state.unSubOnRounds = null
      }
    },
    subscribeTournamentScores (state, fn) {
      state.unSubOnTournamentScores = fn
    },

    unsubscribeTournamentScores (state) {
      if (state.unSubOnTournamentScores) {
 
        state.unSubOnTournamentScores ()
        state.unSubOnTournamentScores = null
      }
     
    },

    subscribeScores (state, fn) {
      state.unSubOnScores = fn
    },

    unsubscribeScores (state) {
      if (state.unSubOnScores) {

        state.unSubOnScores ()
        state.unSubOnScores = null
      }
    },

    unsubscribeAll (state) {
      if (state.unSubOnTournament) {
 
        state.unSubOnTournament ()
        state.unSubOnTournament = null
      }
      if (state.unSubOnRounds) {
 
        state.unSubOnRounds ()
        state.unSubOnRounds = null
      }
      if (state.unSubOnTournamentScores) {
 
        state.unSubOnTournamentScores ()
        state.unSubOnTournamentScores = null
      }
      if (state.unSubOnScores) {

        state.unSubOnScores ()
        state.unSubOnScores = null
      }
     
    },
  

    errorMsg (state, msg) {
      state.errorMsg = msg
    },


    clear (state) {
      state.tournament = null
      state.unSubOnTournament = null
      state.unSubOnRounds = null
      state.unSubOnTournamentScores = null
      state.unSubOnScores = null

      state.leaderBoards = {}
      state.payOuts = []
    },
    tab (state, tab) {
      state.tab = tab
    },
    leaderBoards (state, leaderBoards) {
      state.leaderBoards = leaderBoards
    },
    roundLeaderBoards (state, leaderBoards) {
      state.roundLeaderBoards = leaderBoards
    },
 
    payOuts (state, payOuts) {
      state.payOuts = payOuts
    },


    updateLeaderboards (state) {
      const tournament = state.tournament
      const scores = state.scores // scores from all rounds

      if (tournament) {
        const leaderBoards = createTournamentLeaderboards(tournament, scores)

        state.leaderBoards = leaderBoards
  
        const players = tournament.scores.aScores.map(s=>({docId: s.playerDocId, name: s.name}))
      
        const tournamentPayOuts = createPayouts(tournament.games, players, tournament.roundPayouts, leaderBoards)
        for (let payOutItem of tournamentPayOuts) { // add these properties
          payOutItem.tournamentTotal = payOutItem.totalPayout
          payOutItem.rounds = []
        }
        state.roundLeaderBoards = {}
        for (let round of tournament.rounds.aRounds) {
      
          if (round.games.size > 0) {


            const roundScores = new Scores(scores.aScores.filter(s=>s.roundDocId===round.docId))
            round.assignScores(roundScores.aScores)

            // if (round.games.hasGame('skins')) {
            //   state.skins[round.docId] = calcSkins(roundScores, round.games.getByProp('skins').skinsHalfStroke ? .5 : 1)
            // }
            // else {
            //   state.skins[round.docId] = []
            // }
            state.roundLeaderBoards[round.docId] = createRoundLeaderboards(round, roundScores)
         
            let roundPayouts = createPayouts(round.games, players, round.roundPayouts, state.roundLeaderBoards[round.docId])
       
            roundPayouts = roundPayouts.filter(p=>p.totalPayout > 0)
            for (let player of players) {
              const payOutItem = tournamentPayOuts.find(item=>item.playerDocId===player.docId)
           
              const roundPayoutItem = roundPayouts.find(item=>item.playerDocId===player.docId)
              if (roundPayoutItem !== undefined) {
               
                payOutItem.rounds.push(
                  {
                    title: 'Round ' + round.roundNum,
                    games: round.games,
                    payOutItem: roundPayoutItem
                  }
                )
                payOutItem.tournamentTotal += roundPayoutItem.totalPayout
              }
             
            }
          }
        }

        state.payOuts = tournamentPayOuts.filter(p=>p.tournamentTotal > 0)
        sortArrayObj (state.payOuts, 'tournamentTotal', 'desc')

     
      }

      
    }

  
   
    
  },
  // -----------------------------------------------------------------
  actions: {

    async open (context, queryInfo) {
     
      const response = await TournamentController.getTournament(queryInfo)
    
      if(response.errorMsg.length > 0) {
        context.commit('group/errorMsg', response.errorMsg, {root: true})
      }
      else {
       
        context.commit('group/errorMsg', '', {root: true})
        
        context.commit('tournament', response.tournament)
        context.commit('from', queryInfo.from)
      
        context.commit('payOuts', [])
        context.commit('leaderBoards', {})
        if (response.tournament.games.size>0) context.commit('selectedGameId',response.tournament.games.aGames[0].id)

        TournamentController.onTournament(context, queryInfo)
        TournamentController.onRounds(context, queryInfo)
        TournamentController.onTournamentScores(context, queryInfo)
        TournamentController.onScores(context, queryInfo)

        if (router.currentRoute.path !== '/group/tournament') router.push('/group/tournament') 

      }

      
    },

    

    async addRounds (context, roundDates) {
      const group = context.rootGetters['group/group']
      const response = await TournamentController.addRounds(group, context.state.tournament, roundDates)
    
      if(response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      }
  
    },

    clear (context) {
      context.commit('unsubscribeAll')
      context.commit('clear')
    },

    async addTournamentScores (context, players) {
      const tournament = context.state.tournament
    
      let response = await TournamentController.addTournamentScores(tournament, players)

      if(response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      }
      else {
        context.commit('errorMsg', '')

      }
 
    },

    async deleteTournamentScore (context, tournamentScore) {
      const tournament = context.state.tournament
     
      const response = await TournamentController.deleteTournamentScore(tournament, tournamentScore)
      if(response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      }
      else {
        context.commit('errorMsg', '')
      }
    },

   

    async addTournamentScore (context, player) {
      const response = await TournamentController.addTournamentScore(context.state.tournament, player)
         if (response.errorMsg !== '') {
           context.commit('errorMsg', response.errorMsg)
         }
    },

    async saveTournamentScoreValues (context, tournamentScoreUpdates) {
      const tournament = context.state.tournament
      
      const response = await TournamentController.updateTournamentScoreValues(tournament, tournamentScoreUpdates)
     
      if(response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      }
      else {
        context.commit('errorMsg', '')
        
      }

    },

    async saveValues (context, tournamentUpdate) {

      const response = await TournamentController.updateValues(context.state.tournament.groupDocId, tournamentUpdate)

      if(response.errorMsg.length > 0){
        context.commit('errorMsg', response.errorMsg)
      }
      else {
        context.commit('errorMsg', '') 
     
        tournamentUpdate.clear()
      }

    },

    async resetRoundNums (context) {
      const tournament = context.state.tournament
      if (tournament.rounds.size > 0) {
        tournament.rounds.setRoundNums()
        const roundUpdates = new DBUpdates()
        for (let round of tournament.rounds.aRounds) {
          roundUpdates.add(round.docId, {roundNum: round.roundNum})
        }
        const response = await RoundController.updateRoundsValues(tournament.groupDocId, roundUpdates)
        if(response.errorMsg.length > 0) {
          context.commit('user/errorMsg', response.errorMsg, {root: true})
        }
        else {
          context.commit('user/errorMsg', '', {root: true})
        }
      }
      
    },

    async signUp (context, queryInfo) {
     
      const response = await TournamentController.signUp(queryInfo)
      if(response.errorMsg.length > 0) {
        context.commit('user/errorMsg', response.errorMsg, {root: true})
      }
      else {
        context.commit('user/errorMsg', '', {root: true})
      }
      
  
      
    },
  
    async cancelSignUp (context, queryInfo) {
  
      if (queryInfo.docId !== '') {
        const response = await TournamentController.cancelSignUp(queryInfo)
        if(response.errorMsg.length > 0) {
          context.commit('group/errorMsg', response.errorMsg, {root: true})
        }
        else {
          context.commit('group/errorMsg', '', {root: true})
        }
  
      }
     
    },

    async sendResults (context, tournamentInfo) {
      await TournamentController.sendResults(context.state.tournament.name, tournamentInfo)
    }
  }

  
}