import Tee from './tee'
// const Tee = require('./tee')

export default class Tees {
// class Tees {

  constructor() {
    this.initialize()
  }
  initialize () {
    this.tees = []
  }

  getPlayerTees (gender='') {
    let playerTees = []
    // sortArrayObj(this.tees, 'totalYards', 'desc')
    if (gender === '') {
      playerTees = this.tees.map(tee => tee.shortName)
    }
    else {
      playerTees = this.tees.filter(tee=>tee.gender===gender).map(tee => tee.shortName)
    }
 
    return playerTees
  }
  add (tee) {
    this.tees.push(tee)
  }
  teeExists (shortTee, gender='M') {
    return this.tees.findIndex(o => o.shortName === shortTee && o.gender === gender) !== -1
  }
  getSlope (shortTee, side='full18', gender='M') {
    let slope = 0
    if (shortTee !== '') {
      const tee = this.tees.find(o => o.shortName === shortTee && o.gender === gender)
      if (tee !== undefined) slope = tee.getSlope(side)
    }
    return slope
  }
  getRating (shortTee, side='full18', gender='M') {
    let rating = 0
    if (shortTee !== '') {
      const tee = this.tees.find(o => o.shortName === shortTee && o.gender === gender)
      if (tee !== undefined) rating = tee.getRating(side)
    }
    return rating
    
  }
  getPar (shortTee, side='full18', gender='M') {
    const tee = this.tees.find(o => o.shortName === shortTee)
    let par = 0
    if (tee !== undefined) par = tee.getPar(side, gender)
    return par
  }
  getTee (shortTee, gender='M') {
    let tee = this.tees.find(o => o.shortName === shortTee && o.gender === gender)

    if (tee === undefined) tee = this.tees[0]

    return tee
  }
  copy () {
    const tees = new Tees()
    tees.setObj(this.getObj())
    return tees
  }
  toJSON () {
    return JSON.stringify(this.getObj())
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  getObj () {
    return this.tees.map(tee => tee.getObj())
  }
  setObj (data) {
    this.tees = data.map(teeObj => {
      const tee = new Tee()
      tee.setObj(teeObj)
      return tee
    })
  }
  getData () {
    return this.tees.map(tee => tee.getData())
  }
  setData (data) {
    this.tees = data.map(teeData => {
      const tee = new Tee()
      tee.setData(teeData)
      return tee
    })
  }
}

// module.exports = Tees