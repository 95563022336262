<template>
  <div>
    <div class="modal-card" :style="isMobile?'width:auto;':'width:600px;'">
      <header class="modal-card-head">
        <p class="modal-card-title">Handicap Details - {{capitalizeWord(playerInfo.name)}}</p>
      </header>
      <div class="modal-card-body"  >

        <div class="level is-mobile has-margin-bottom-5">
          <div class="level-left">
            <div class="level-item">
              <p class="has-text-weight-bold">Handicap ({{myTee}} tee):</p>  
            </div>
            <div class="level-item">
              <p> {{ formatHandicap(handicap, handicapFac.full18) }}</p>
            </div>
            <div class="level-item">
            <b-select v-model="myTee" @input="calcHandicap" style="width:100px;">
              <option v-for="tee in playerTees" :value="tee" :key="tee">{{ tee }}</option>
            </b-select>
            </div>
          </div>
          <div class="level-item">
            <b-field>
            <b-radio-button v-if="indexLookup.handicapSettings.useGameIndex && playerInfo.type!=='Guest'" :value="indexSource" @input="value=>setDiffSource('Group',value)"
              native-value="Group">
              <span>Group</span>
            </b-radio-button>
        
            <b-radio-button v-if="indexLookup.handicapSettings.lookupGhinIndex" :value="indexSource" @input="value=>setDiffSource('GHIN',value)"
              native-value="GHIN">
              <span>GHIN</span>
            </b-radio-button>

          </b-field>
          </div>
        </div>
        
        <div class="level is-mobile  has-margin-bottom-5">
          <div class="level-left">
            <div class="level-item">
              <p class="has-text-weight-bold">Index:</p>
            </div>
            <div class="level-item">
              {{ index }}
            </div>
            <div class="level-item">
              <p>{{ indexMsg }}</p>
            </div>
          </div>
        
        </div>
      

        <b-field grouped>
          <p class="control has-text-weight-bold field-label" style="padding-top:10px;">
            {{numScores}}
          </p>
          
        </b-field>
            

        <b-table
          style="padding-bottom: 10px;"
          bordered
          narrowed
          :mobile-cards="false"
          :data="diffs" >
        
            <b-table-column field="diffUsed" label="Used" width="40" centered class="is-size-7" v-slot="props">
              <b-icon v-if="props.row.diffUsed" icon="check" size="is-small"/>
            </b-table-column>
            <b-table-column field="formattedDate" label="Date" width="100" centered class="is-size-7" v-slot="props">
              {{ props.row.formattedDate }}
            </b-table-column>
            <b-table-column field="score" label="Score" width="70" centered class="is-size-7" v-slot="props">
              {{ props.row.score === 0 ? '' : props.row.score + (Number.isInteger(props.row.pcc) && props.row.pcc !== 0 ? '*' : '')}}
            </b-table-column>
            <b-table-column field="diff" label="Diff" width="70" centered class="is-size-7" v-slot="props">
              <p>{{ props.row.diff }}</p>
            </b-table-column>
            <b-table-column field="course" label="Course" class="is-size-7" v-slot="props">
              {{ props.row.course}}
            </b-table-column>
     
        </b-table>

        <div v-if="errorMsg !== ''" class="level">
          <div class="level-left">
              <div class="level-item">
              <p style="padding-left: 10px;">{{ errorMsg }}</p>
            </div>
          </div>
        </div>
      </div>
      <footer class="modal-card-foot" style="justify-content: flex-end;">
        <b-button @click="closeDialog" >Close</b-button>
        <b-button v-if="!viewOnly && index!=='n/a'" type="is-primary" @click="useHandicap" >Use {{indexSource}} Handicap</b-button>
      </footer>
      <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="true"></b-loading>
    </div>
    <b-modal :active.sync="showPlayerLookup" has-modal-card>
      <ghin-player-lookup :lookup="lookup" :indexLookup="indexLookup" @selectPlayer="selectPlayer" />
    </b-modal>
  </div>        
</template>

<script>
import IndexItem from '../../helpers/indexItem'

import { roundTo, capitalizeWord, isEmpty, formatHandicap, sortArrayObj } from '../../lib'
import pluralize from 'pluralize'
import GhinPlayerLookup from './GhinPlayerLookup'

const parseFullName = require('parse-full-name').parseFullName

export default {
  data () {
    return {
      isLoading: false,
      myTee: this.playerInfo.tee,
      teeAdj: '',
      indexInfoGroup: new IndexItem(),
      indexInfoGhin: new IndexItem(),
      index: '',
      indexSource: 'Group',
      indexMsg: '',
      handicap: '',
 //     courseHandicap: '',
      diffs: [],

   
      state: this.playerInfo.state,
      clubName: this.playerInfo.clubName,
      ghinNum: '',
      errorMsg: '',
      showPlayerLookup: false,
      ghinFac: 1,
   
   
      lookup: {
        state: this.playerInfo.state,
        firstName: parseFullName(this.playerInfo.name).first,
        lastName: parseFullName(this.playerInfo.name).last
      }
    
    }
  },
  props: ['indexLookup','handicapCalc','playerInfo','playerTees','viewOnly','handicapFac'],

  components: {
    'ghin-player-lookup': GhinPlayerLookup 
  },

  async mounted () {
 
    await this.getGroupIndex()
    await this.setDiffSource (this.playerInfo.indexSource, true)

  
  },

  // watch: {
  //   diffSource(val) {
  //     if (val === 'Group' || val === 'GHIN') {
  //       this.getHandicaps()
  //     }
  //   }
  // },
  
  computed: {
   
    
    numScores () {
      let count = 'No Scores'
  
      if (this.diffs.length > 0) {
        count = `${this.diffs.length} ${pluralize(' Score',this.diffs.length)}`
       
      }
        
      return count
    }

   
  },
  methods: {
    isEmpty,
    capitalizeWord,
    formatHandicap,

    async setDiffSource (source, val) {
      if (val) {
        this.indexSource = source
        if (source === 'Group') {
          await this.getGroupIndex()
        }
        else {
          await this.getGhinIndex()
        }
      }
      this.calcHandicap ()
    },

    // async recalcHandicapNewDate(newDate) {
    
    //   this.setDiffSource(this.indexSource, true)
    // },



    calcHandicap () {
     
      let indexInfo
      if (this.indexSource === 'Group') {
        indexInfo = this.indexInfoGroup
      }
      else {
        indexInfo = this.indexInfoGhin
      }

      if (indexInfo.index !== null) {
        this.index = indexInfo.getIndex('full18')
        this.indexMsg = indexInfo.info
        this.handicap = this.handicapCalc.playingHandicapSide(indexInfo.getIndex('full18'), this.myTee, this.handicapFac.full18, 'full18', this.playerInfo.gender)
  //      this.courseHandicap = roundTo(this.handicapCalc.courseHandicapSide(indexInfo.getIndex('full18'), this.myTee, 'full18', this.playerInfo.gender),4)
        this.teeAdj = roundTo(this.handicapCalc.teeDiff(this.myTee, 'full18', this.playerInfo.gender),4)

         
      }
      else {
        if (indexInfo.indexDisplay === '') {
          this.index = 'n/a'
        }
        else {
          this.index = indexInfo.indexDisplay
        }
       
        if (indexInfo.info === '') {
          this.indexMsg = 'Not enough scores'
        }
        else {
          this.indexMsg = indexInfo.info
        }
        
        this.handicap = 'n/a'
  //      this.courseHandicap = 'n/a'
        this.teeAdj = 'n/a'
      }
    },

    async getGroupIndex () {
      this.isLoading = true

      this.indexInfoGroup = await this.indexLookup.getGroupIndexInfo(this.playerInfo)
      this.diffs = this.indexInfoGroup.indexScores.scoresArray
      sortArrayObj(this.diffs, 'roundDate','desc')

      this.isLoading = false
    },
    async getGhinIndex () {
      this.isLoading = true

      this.indexInfoGhin = await this.indexLookup.getGhinIndexInfo(this.playerInfo, true)
      this.diffs = this.indexInfoGhin.indexScores.scoresArray
      sortArrayObj(this.diffs, 'roundDate','desc')
      
      this.isLoading = false
    },
    
    
    async openSelectPlayers () {
  
      this.showPlayerLookup = true

    },
  
    async selectPlayer (selectedPlayer) {
      this.showPlayerLookup = false
      if ('ghinNum' in selectedPlayer) {
        this.ghinNum = selectedPlayer.ghinNum
        this.playerInfo.ghinNum = selectedPlayer.ghinNum
        await this.getGhinHandicap()
      }
      
      
    },
    // updateManualHandicap (index, value) {
    //   if (isNaN(value)) {
    //     this.diffs[index].diff = ''
    //   }
    //   else {
    //     this.diffs[index].diff = Number(value)
    //   }
    
    //   const indexTable = this.indexLookup.getIndexTable()
    //   const scoresToUse = indexTable.getScoresToUse(this.diffs.length)
    //   const diffs = this.diffs.filter(diff => diff.diff !== '') // copy array but keep ref to objects

    //   sortArrayObj(diffs, 'diff', 'asc')
    //   for(let i=0; i<diffs.length; i++) {
    //     diffs[i].diffUsed = (i < scoresToUse)  //update object in orig array, order of orginal array remains
    //   }

    //   this.calcHandicaps()
    // },
    
    closeDialog () {
      this.$parent.close()
    },
    useHandicap () {
      let indexInfo
      if (this.indexSource === 'Group') {
        indexInfo = this.indexInfoGroup
      }
      else {
        indexInfo = this.indexInfoGhin
      }

      this.$emit('useHandicap', indexInfo)
      this.$parent.close()
    }
  }
}

</script>

<style>
 


</style>