<template>
  <div>
    <p v-if="isKPNotInGroup" class="is-size-5 has-margin-bottom-15 has-text-danger">{{currentKP}}</p>

    <div v-for="(score,index) in currentTeeTimeScoresArray" :key="index">
      <div class="field has-margin-top-15">
        <b-checkbox
          class="is-fullwidth"
       
          :value="score.hasKP(groupHoleNum)"
          size="is-large" 
          @input="value=>setKP(score, groupHoleNum, value)" >
        
          {{score.name}}
          
        </b-checkbox>
      </div>
      <div v-if="score.hasKP(groupHoleNum)" class="level is-mobile">
        <div class="level-left">
          <div class="level-item">
            <p class="is-size-4">Distance</p>
          </div>
          <div class="level-item">
            <vue-numeric
              ref="distVal"
              class="input text-center is-medium"
    
              :value="getKPDist(score, groupHoleNum)"
              currency=""
              style="width:4em;"
              thousand-separator=""
              :min="0" 
              :precision="0"
              @blur="updateKPDist(score, groupHoleNum, $event.target.value)"
              
            /> 
    <!--        <b-numberinput
              size="is-medium"
              ref="distVal"
              :controls="false"
              :value="getKPDist(score, groupHoleNum)"
              style="width:4em;"
              :min="0" 
    
              @blur="updateKPDist(score, groupHoleNum, $event.target.value)"
              
            /> -->
          </div>
          <div class="level-item">
            <p class="is-size-4">Feet</p>
          </div>
        </div>
      </div>
      
    </div>

  </div>

</template>

<script>

import { formatDist } from "../../lib"
import DBUpdates from '../../helpers/dbUpdates'

export default {
  data () {
    return {
    }
  },

  props: ['round','groupHoleNum','selectedKPDocId','currentTeeTimeScoresArray'],

  computed: {

    isGuest () {
      return this.$store.getters['user/isGuest']
    },
    leaderBoards () {
      return this.$store.getters['round/leaderBoards']
    },
    isKPNotInGroup () {
      let isKP = true
    
      if (this.round.hasGame('kp')) {
    
        if (Object.entries(this.leaderBoards).length > 0) {
          const game = this.round.games.getByProp('kp')
          const kpLeaderboard = this.leaderBoards[game.id]

          const kp = kpLeaderboard.filter(k=>k.holeNum===this.groupHoleNum)
          const kpItem = kp.find(k=>k.kpPay>0)
        
          if (kpItem !== undefined) {
            for (let score of this.currentTeeTimeScoresArray) {
             
              if (kpItem.docId === score.docId) {
                isKP = false
                break
              }
            }
          }
        }
      }
      return isKP
    },
    currentKP () {
      let kpLabel = ''
      if (this.round.hasGame('kp')) {
        if (Object.entries(this.leaderBoards).length > 0) {
          const game = this.round.games.getByProp('kp')
          const kpLeaderboard = this.leaderBoards[game.id]

          const kp = kpLeaderboard.filter(k=>k.holeNum===this.groupHoleNum)
          const kpItem = kp.find(k=>k.kpPay>0)
          if (kpItem !== undefined) {
            kpLabel = 'Current KP - ' + kpItem.name + ' ' + formatDist(kpItem.kpDist, 'ft') + (kpItem.kpPay===2?' NO PAR':'')
          }
        }
        
      }
      return kpLabel
    }
  
   
  },
  

  methods: {
    formatDist,

    async updateKPDist (score, holeNum, value) {
      const scoreUpdates = new DBUpdates()
      const scoreUpdated = score.copy()
      scoreUpdated.updateKPDist(holeNum, value)

      scoreUpdates.add(score.docId, {kpDist: scoreUpdated.kpDist})

      await this.$store.dispatch('round/saveScoreValues', scoreUpdates)

     
    },

    async setKP (score, holeNum, val) {
    
      await this.$store.dispatch('round/setKP', {score, holeNum, val})
    
    
    },
    getKPDist (score, groupHoleNum) {
      let dist = score.getKPDist(groupHoleNum)
     
      if (dist === '' || Number.isNaN(dist)) dist = 0
      // this.$nextTick(() => {
      //   this.$refs.distVal[0].$el.focus()
      //   this.$refs.distVal[0].$el.select()

      // })


      return dist
    }

    
  }
}

</script>

<style lang="scss">


</style>
