
import dayjs from 'dayjs'

import IndexScore from './indexScore'
import { sortArrayObj, newDate, roundTo } from '../lib'



export default class IndexScores {
  constructor() {
    this.scoresArray = []
    this.numScoresToUse = 1
    this.maxScoresToUse = 20
  }


 
  add (indexScore) {
    this.scoresArray.push(indexScore)
  
  }

  setFromScores(scoresArray) {
    this.scoresArray = []
   
    let count = 0
    for(let score of scoresArray) {
      count++
      let roundDate
      if ('formattedDate' in score) {
        roundDate = dayjs(score.formattedDate).toDate()
      }
      else {
        roundDate = score.roundDate
      }
      this.add(new IndexScore(roundDate, score.course, score.score, score.tee, score.diff, score.pcc))
      if (count > this.maxScoresToUse) break
    }
  }

  addFillerScores(diff){
    const addCount = this.maxScoresToUse - this.size
    if (addCount > 0) {
      for(let i=0; i<addCount; i++) {
        this.add(new IndexScore(newDate(), 'Ghin Index', 0, '', diff, 0))
      }
    }
  }
 
  get (idx) {
    if (idx >=0 && idx < this.size) {
      return this.scoresArray[idx]
    }
    else {
      return null
    }
    
  }
  set (idx, indexScore) {
   
    this.scoresArray[idx] = indexScore
    
  }
  get size () {
    return this.scoresArray.length
  }
  
  clear () {
    this.scoresArray = []
  }

  sortByRoundDate() {
    sortArrayObj(this.scoresArray, 'roundDate', 'desc')
  }
  
  calcIndex (adj=0) {

    let index = null

    if (this.numScoresToUse < this.size) {

      sortArrayObj(this.scoresArray, 'diff', 'asc')

        
      let totalDiff = 0
      let count = 0
      
      for(let i=0; i<this.numScoresToUse; i++) {
        if (i < this.size) {
          this.scoresArray[i].diffUsed = true
          totalDiff += this.scoresArray[i].diff
          count++
        }
        else {
          break
        }
        
      }
      
      if (Math.abs(totalDiff) < .1) totalDiff = 0
      const avg = totalDiff / count
   
      index = roundTo(avg, 1) + adj
      

      
      
    }
    return index
  }
  
  
}


