<template>
  <div>

    <div v-if="isMobile" class="header">
      <p class="title is-5 header-level" >Posted Rounds</p>
    
    </div>


    <div :class="isMobile?'has-padding-top-50 has-margin-left-10':''">
      <div class="level is-mobile has-margin-bottom-5">
        <div class="level-left">
          <div class="level-item">
            <p class="label is-size-5">Season</p>
          </div>
          <div class="level-item">
            <b-select :value="currentSeason.name" @input="value=>refreshScores(value)">
              <option
                v-for="(season,index) in seasonsArray"
                :value="season.name"
                :key="index"
              >{{ season.name }}</option>
          </b-select>
          </div>
        </div>
      </div>
    </div>
   
    
    <template v-if="postedScoresArray.length>0">
      
      <template v-if="isMobile">
        <table class="table">
          <tbody>
            <tr v-for="(userScore, index) in postedScoresArray" :key="index" @click="openRoundDetails(userScore)">
              <td>
                <p>{{userScore.formattedDate + (userScore.wd ? ' (Withdrawn)':'')}}</p>
                <p class="is-size-7">{{userScore.courseLabel}}</p>
              </td>
              <td>
                {{ userScore.wd ? '' : (userScore.scoreFull18==='' ? '' : userScore.scoreFull18 + ' (' + formatToPar(userScore.toPar.full18) + ')')}}
              </td>
              <td >
                <a >
                  <b-icon icon="chevron-right"/>
                </a>
                
              </td>
            </tr>
          </tbody>
        </table>
      </template>
      <template v-else>

        <b-table
          :data="postedScoresArray" 
          :mobile-cards="false"
          default-sort="roundDate" 
          default-sort-direction="DESC"
          detailed
          detail-key="docId">
     
      
            <b-table-column
              class="vcenter"
              field="roundDate"
              label="Date"
              width="140"
              sortable
              v-slot="props"
            >
              <p>{{props.row.formattedDate + (props.row.wd ? ' (Withdrawn)':'')}}</p>
            </b-table-column>

            <b-table-column
              class="vcenter"
              field="roundName"
              label="Name"
              sortable
              v-slot="props"
            >
              <p>{{props.row.roundName}}</p>
            </b-table-column>

             <b-table-column
              class="vcenter"
              field="courselLabel"
              label="Course"
              v-slot="props"
            >
              <p>{{props.row.courseLabel}}</p>

            </b-table-column>

            <b-table-column
              class="vcenter"
              label="Index"
              width="80"
              centered
              field="index"
              sortable
              :visible="!isMobile"
              v-slot="props">
                {{ props.row.index.full18}}
            </b-table-column>

            <b-table-column
              class="vcenter"
              label="Score"
              width="80"
              field="scoreFull18"
              sortable
              centered
              v-slot="props"
            >{{ props.row.wd?'':(props.row.scoreFull18 + (props.row.exclude?'x':''))}}</b-table-column>
            <b-table-column
              class="vcenter"
              label="Handicap"
              width="80"
              centered
              field="handicap"
              sortable
              v-slot="props"
            >{{ props.row.handicap.full18 + ' (' + props.row.tee + ')'}}</b-table-column>
            <b-table-column
              class="vcenter"
              label="Net Score"
              width="120"
              centered
              field="netScoreFull18"
              sortable
              v-slot="props"
            ><p class="has-text-weight-bold">{{ props.row.wd?'':props.row.netScore.full18}}</p></b-table-column>
            <b-table-column
              class="vcenter"
              label="To Par"
              width="90"
              centered
              field="toPar"
              sortable
              v-slot="props">
              <p :class="props.row.full18<0?'has-text-danger':''"> {{ formatToPar(props.row.toPar.full18) }} </p>
            </b-table-column>
            <b-table-column
              class="vcenter"
              label="Payout"
              width="80"
              centered
              field="totalPayout"
              sortable
              v-slot="props"
            >
              <p :class="props.row.totalPayout>0?'has-text-success':''">{{ formatCurrency(props.row.totalPayout)}}</p>
            </b-table-column>

          <template slot="detail" slot-scope="props">
            <round-details :userScore="props.row" :groupDocId="groupDocId" />
          
          </template>
        </b-table>
      </template>
    </template>
    <template v-else>
      <p v-if="!isLoading" class="subtitle has-margin-top-10 has-margin-left-10" :class="isMobile?'has-margin-top-60':''">You have not played any rounds this season</p>
    </template>

    <b-modal 
      :active.sync="showRoundDetails"
      has-modal-card 
      full-screen
      style="z-index:100;"
      :can-cancel="false">
      <div class="modal-card" style="width:auto">
        <div class="modal-body">
          <div class="level is-mobile has-margin-top-10" @click="showRoundDetails=false">
            <div class="level-left">
              <div class="level-item">
                <a class="mt-1" >
                  <b-icon icon='chevron-left'></b-icon>
                </a>
              </div>
              <div class="level-item">
                <p class="subtitle">Round Details</p>
              </div>
            </div>
          </div>
          
          <round-details :userScore="roundDetails" :groupDocId="groupDocId"/>
        </div>
      </div>
       
    </b-modal>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="true"></b-loading>

  </div>
</template>

<script>

import { formatCurrency, formatToPar } from '../../lib'

import RoundDetails from '../../components/User/RoundDetails'
// const { formatCurrency } = require('../../lib')

//import { mapGetters } from "vuex"

export default {
  data() {
    return {
  
      scoreColumns: [
       
        {
          label: "Date",
          field: "formattedDate",
          width: 50
        },
        { label: "Course", field: 'courseLabel', width: '80'},
        { label: "Index", field: 'index', width: '80'},
        { label: "Score", field: 'scoreFull18', width: '80'},
        { label: "Handicap", field: 'handicapFull18', width: '80' },
        { label: "Net Score", field: 'netScoreFull18', width: '80' },
        { label: "To Par", field: 'toPar', width: '80' },
        { label: "Payout", field: 'totalPayout', width: '80' },
        { label: "WD", field: 'wd', width: '50' }
      ],
      showRoundDetails: false,
      roundDetails: null
    }
  },
   components: {
    'round-details': RoundDetails
  },
  props: ['user'],


  computed: {
    group () {
      return this.$store.getters['group/group']
    },
    postedScoresArray () {
      let aScores = []
      if (this.userPostedScores) aScores = this.userPostedScores.aScores
      return aScores
    },
    tab () {
      return this.$store.getters['user/tab']
    },
    userPostedScores () {
      return this.$store.getters['user/userPostedScores']
    },
    seasonsArray () {
      let seasons = []
      if (this.group) seasons = this.group.seasons.aSeasons
      return seasons
    },
    currentSeason () {
      return this.$store.getters['user/currentSeason']
    },
    groupDocId () {
      let docId = ''
      if (this.user) docId = this.user.groupDocId
      return docId
    },
    isLoading: {
      get () {
        return this.$store.getters['user/isLoadingPostedScores']
      },
      set (val) {
        this.$store.commit('user/isLoadingPostedScores', val)
      }
    }
  
  },
  methods: {
    formatCurrency,
    formatToPar, 

    openRoundDetails (userScore) {
      this.roundDetails = userScore
      this.showRoundDetails = true
    },
  

    async refreshScores (seasonName) {
      
      const season = this.group.seasons.aSeasons.find(s=>s.name===seasonName)
      if (season === undefined) {
        console.log('season not found', seasonName)
      }
      else {
   
        // await this.$store.dispatch('user/getUserPostedScores', season)
        await this.$store.dispatch('user/onUserPostedScores', season)
      
      }
      
    }
  }
}
</script>

<style>
  .vcenter {
    vertical-align: middle !important;
  }
  .scrollable {
    max-height: 220px;
    overflow-y: auto;
  }

  .card-margin-top {
    margin-top: 8px;
  }
  .card-margin-right {
    margin-right: 8px;
  }
  .card-margin-bottom {
    margin-bottom: 8px;
  }
  .card-margin {
    margin: 8px;
  }
  .card-width-mobile {
    width: 300px !important
  }
  .card-width {
    width: 400px !important
  }
  .card-content-padding {
    padding: 10px;
  }

 .header {
    position:fixed;
    width:100%;
    z-index:50;
    background-color:white;
    margin: 0 0 0 0;
  }
  
  .header-level {
    margin-left: 10px !important;
    margin-top:10px;
    padding-right:20px;
    margin-bottom:0px !important;
  }

</style>
