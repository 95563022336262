<template>
  <div class="modal-card" style="width: auto;">
    <header class="modal-card-head">
      <p class="modal-card-title">GHIN Player Lookup</p>
    </header>
    
    <div class="modal-card-body">
      <template v-if="isMobile">
        <div class="level is-mobile">
          <div class="level-left">
            
            <div class="level-item">
              <b-field label="Last Name">
                <b-input v-model="lookup.lastName" ></b-input>
              </b-field>
            </div>
            <div class="level-item">
              <b-field label="First Name" style="width:120px;">
                <b-input v-model="lookup.firstName" ></b-input>
              </b-field>
            </div>
            
          </div>
        </div>
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <b-field label="State">
                <b-input v-model="lookup.state" style="width:60px;" ></b-input>
              </b-field>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
            
              <b-button type="is-info" class="has-margin-top-30" @click="searchGhin" :loading="isSearch">Search</b-button>
            
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <b-field label="State">
                <b-input v-model="lookup.state" style="width:60px;" ></b-input>
              </b-field>
            </div>
            <div class="level-item">
              <b-field label="Last Name">
                <b-input v-model="lookup.lastName" ></b-input>
              </b-field>
            </div>
            <div class="level-item">
              <b-field label="First Name" >
                <b-input v-model="lookup.firstName" ></b-input>
              </b-field>
            </div>
            <div class="level-item">
            
              <b-button type="is-info" class="has-margin-top-30" @click="searchGhin" :loading="isSearch">Search</b-button>
            
            </div>
          </div>
        </div>
      </template>
      <p>{{players.length===0 && !isSearch?'No players found':''}}</p>
      <b-table
        class="has-margin-top-10"
        v-if="players.length>0"
        style="padding-bottom: 10px;"
        bordered
        narrowed
        :selected.sync="selectedPlayer"
        :mobile-cards="false"
        :data="players" >
       
          <b-table-column field="name" label="Name" v-slot="props">
            {{props.row.name}}
          </b-table-column>
          <b-table-column field="club" label="Club" v-slot="props">
            {{ props.row.club }}
          </b-table-column>
          <b-table-column field="state" label="State" width="70" centered v-slot="props">
            {{ props.row.state}}
          </b-table-column>
          <b-table-column field="index" label="Index" width="90" centered v-slot="props">
            {{ props.row.index }}
          </b-table-column>
        
      
      </b-table>
    </div>
    <footer class="modal-card-foot" style="justify-content: flex-end;">
      <b-button @click="closeDialog()" >Close</b-button>
      <b-button type="is-primary" @click="selectPlayer" :disabled="isEmpty(selectedPlayer)" >Select</b-button>
    </footer>
    
  </div>
   
       
</template>

<script>

import { isEmpty } from '../../lib'

export default {
  data () {
    return {
      isSearch: false,
      selectedPlayer: {},
      players: []
    }
  },
  props: ['lookup','indexLookup'],

  async mounted () {
    await this.searchGhin()
  
  },

  
  computed: {
    
  },
  methods: {
    isEmpty,

    async searchGhin () {
      this.isSearch = true
      this.selectedPlayer = {}

      this.players = await this.indexLookup.getGhinPlayers(this.lookup)

      this.isSearch = false
    },
    selectPlayer () {
    
      this.$emit('selectPlayer',this.selectedPlayer)
      
    },
    
    closeDialog () {
      this.$parent.close()
    }
  }
}

</script>

<style>
 


</style>