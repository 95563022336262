import store from '../store'


const checkHome = (to, from, next) => {
  let isAuth = store.getters['auth/isAuthenticated']

  if (isAuth) {
    next('/user')
  } else {
    next()
  }
  
  
}


const checkRound = (to, from, next) => {

  if (store.getters['round/round'] === null) {
    next('/user')
  } else {
    next()
  }
}

const checkGroup = (to, from, next) => {

  if (store.getters['group/group'] === null) {
    next('/user')
  } else {
    next()
  }
}

const checkTournament = (to, from, next) => {

  if (store.getters['tournament/tournament'] === null) {
    next('/user')
  } else {
    next()
  }
}

export { checkHome, checkRound, checkGroup, checkTournament }
