<template>
  <div>

    <div class="columns">
      <div class="column" style="padding-top: 0px;">
      <div class="card card-margin-bottom card-margin-side" >
        <div class="card-header">
          <div class="level is-mobile" style="padding-top:4px;padding-bottom:4px;">
            <div class="level-left">
              <div class="level-item">
                <p class="card-header-title title is-6 ">Score</p>
              </div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <b-button @click="openRound(userScore)">Open Round</b-button>
              </div>
              
            </div>
          </div>
        </div>
        <div class="card-content card-content-padding">
          <p v-if="userScore.wd">Withdrawn</p>
          <table v-else class="table">
            <tbody>
              <tr>
                <td colspan="2" class="has-text-weight-bold">{{userScore.courseLabel}}</td>
              </tr>
              <tr>
                <td class="has-text-weight-bold">{{userScore.tee}} Tees</td>
                <td></td>

              </tr>
              <tr>
                <td class="has-text-weight-bold">Score:</td>
                <td>{{userScore.score.full18}} {{userScore.score.full18==='' ? '' : '(' + userScore.score.front + '/' + userScore.score.back + ')'}}</td>

              </tr>
              <tr>
                <td class="has-text-weight-bold">Index:</td>
                <td>{{formatHandicapObj(userScore.index,'full18')}}</td>
  
              </tr>
              <tr>
                <td class="has-text-weight-bold">Handicap:</td>
                <td>{{formatHandicapObj(userScore.handicap,'full18')}} {{userScore.handicap.full18==='' ? '' : '(' + formatHandicapObj(userScore.handicap,'front') + '/' + formatHandicapObj(userScore.handicap,'back') + ')'}}</td>

              </tr>
              <tr>
                <td class="has-text-weight-bold">Net Score:</td>
                <td>{{userScore.netScore.full18}} {{userScore.netScore.full18==='' ? '' : '(' + userScore.netScore.front + '/' + userScore.netScore.back + ')'}}</td>
        
              </tr>
              <tr>
                <td class="has-text-weight-bold">To Par:</td>
                <td :class="userScore.toPar.full18<0?'has-text-danger':''">{{formatToPar(userScore.toPar.full18)}}</td>
    
              </tr>

            </tbody>
          </table>
        </div>
      </div>
      </div>
      <div class="column " style="padding-top: 0px;">
      <div class="card card-margin-bottom card-margin-side" >
        <div class="card-header">
          <p class="card-header-title title is-6 ">Games</p>
        </div>
        <div class="card-content card-content-padding">
        
          <table v-if="!userScore.wd" class="table">
            <tbody>
              <tr v-for="(gameResult,index) in userScore.gameResults" :key="index">
                <td class="has-text-weight-bold">{{gameResult.name}}</td>
                <td>{{gameResult.score}}</td>
                <td style="text-align:right;">{{gameResult.payOut}}</td>
              </tr>
              <tr>
                <td class="has-text-weight-bold">Total</td>
                <td></td>
                <td style="text-align:right;">{{ formatCurrency(userScore.totalPayout) }}</td>
              </tr>


            </tbody>
          </table>
        </div>
      </div>
      </div>
    </div>
      

  </div>
</template>

<script>

import { formatCurrency, formatToPar, formatHandicapObj } from '../../lib'
import Games from '../../models/games'
import GameDefs from '../../helpers/gameDefs'
// const { formatCurrency } = require('../../lib')

//import { mapGetters } from "vuex"

export default {
  data() {
    return {
      games: new Games()
    }
  },
  props: ['userScore','groupDocId'],


  computed: {
  
  },
  methods: {
    formatCurrency,
    formatToPar,
    formatHandicapObj,
   
    openRound(score) {
      this.loading = true
 
      this.$store.dispatch('round/open', {groupDocId: this.groupDocId, roundDocId: score.roundDocId, playerScoreDocId: score.docId, roundNum: ''})

      this.loading = false
    }
  }
}
</script>

<style>
 

  .card-margin-top {
    margin-top: 4px;
  }
  .card-margin-right {
    margin-right: 0px;
  }
  .card-margin-bottom {
    margin-bottom: 0px;
  }
  .card-margin-side {
    margin-left: 4px;
    margin-right: 4px;
  }
  .card-margin {
    margin: 8px;
  }
  
  .card-content-padding {
    padding: 10px;
  }


</style>
