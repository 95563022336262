<template>
  <div >
    <div v-if="isMobile" class="level has-margin-bottom-15">
      <div class="level-left">
        <div class="level-item">
          <b-dropdown class="has-margin-top-10 has-margin-left-10" v-if="userName==='Ken Elston'" :triggers="['hover']">
            <b-button class=" title is-4" icon-right="menu-down" slot="trigger">
              Ken Elston
            </b-button>
            <b-dropdown-item value="runUpdateProc" @click="runUpdateProc">Run Update Process</b-dropdown-item>
            <b-dropdown-item value="backupDb" @click="backupDb">Backup Database</b-dropdown-item>
            <b-dropdown-item value="delUsers" @click="deleteAnonymousUsers">Delete Anon Users</b-dropdown-item>
            

          </b-dropdown>
          <p v-else class="title is-4 has-margin-top-10 has-margin-left-10 has-margin-bottom-5">{{ userName }}</p>
        </div>
        
        <div v-if="roundInProgress!==null" class="level-item">
       
          <b-button type="is-info" @click="handleSchRoundClick(roundInProgress)">
             {{roundInProgressLabel}}
          </b-button>
         
        </div>
        <div v-if="roundInProgress!==null" class="level-item">
          <p class="is-size-6">{{roundInProgress.teeTimeLabel}}</p>
        </div>
      
      </div>
    </div>
    
    
    <div class="columns"> 
      <div class="column is-one-half" style="padding-top:0px;">
        <div class="card card-margin-top card-margin-side">
          <div class="card-header">
            <div class="level is-mobile">
              <div class="level-left">
                <div class="level-item">
                  <a class="card-header-title is-size-5 has-text-link" @click="gotoGroup">{{ groupName  }}</a>

                </div>
                <div v-if="hasMultipleGroups" class="level-item">
                  <b-dropdown>
                    <b-button :loading="loadingGroup" slot="trigger" slot-scope="{ active }" :icon-right="active ? 'menu-up' : 'menu-down'">
                      Change Group
                    </b-button>

                    <b-dropdown-item v-for="(groupObj,index) in groupObjArray" :key="index" @click="changeGroup(groupObj.docId)">{{groupObj.name}}</b-dropdown-item>
                    
                </b-dropdown>
             
                </div>
              </div>
            </div>
            
             
          </div>
          <div class="card-content card-content-padding">
           
            <p class="subtitle">
              {{courseName}}
            </p>
            <p class="label">
              {{userPostedScoresArray.length===0?'No Rounds':userPostedScoresArray.length + pluralize(' Round',userPostedScoresArray.length)}} Played This Season ({{currentSeason.name}})
            </p>
            <p class="label" v-if="numLimitScores>0">
              {{numLimitScores}} Rounds Played in the Last {{group.handicap.method.limitScoresMonths}} months
            </p>
            <a class="button" :href="'mailto:'+getGroupEmails()">Send Group Email</a>
          </div>
        </div>

        <div v-if="trackHandicap" class="card card-margin-top card-margin-side">
          <div class="card-header">
            <div class="level is-mobile ">
              <div class="level-left">
                <div class="level-item">
                  <p class="card-header-title title is-5 ">Current Handicap</p>
                </div>
                <b-button v-if="useGameIndex" class="level-item"  @click="openHandicapDetails()">Details</b-button>
              </div>
            </div>
          </div>
          <div class="card-content card-content-padding">
            <div class="columns">
              <div v-if="useGameIndex" class="card card-margin column"  >
                <div class="card-header">
                  <p class="card-header-title">Within Group</p>
                </div>
                <div class="card-content card-content-padding">
                  <template v-if="groupIndexLoading">
                    <b-skeleton ></b-skeleton>
                    <b-skeleton ></b-skeleton>
                  </template>
                  <template v-else>
                    <p class="label">Index: {{groupIndex}}</p>
                    <p class="label">Hdcp {{userTee}} tees: {{groupHandicap}}</p>
                  </template>
                  
                  
                </div>
              </div>
              <div v-if="useGhinIndex" class="card card-margin column">
                <div class="card-header">
                  <p class="card-header-title">GHIN</p>
                </div>
                <div class="card-content card-content-padding">
                  <template v-if="ghinIndexLoading">
                    <b-skeleton ></b-skeleton>
                    <b-skeleton ></b-skeleton>
                  </template>
                  <template v-else>
                    <p class="label">Index: {{ghinIndex}}</p>
                    <p class="label">Hdcp {{userTee}} tees: {{ghinHandicap}}</p>
                  </template>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="column is-one-half" style="padding-top:0px;padding-bottom:0px;">
        <div class="card card-margin-top card-margin-side">
          <div class="card-header">
            <p class="card-header-title title is-5 ">Upcoming Rounds</p>
          </div>
          <div class="card-content card-content-padding">
          <table class="table" width="100%">
            <tbody>
              <tr v-for="(userRound,index) in userSchRoundsArray" :key="index">
                <td>
                  <p>{{`${userRound.roundDateLabel} ${userRound.name} - ${userRound.numPlayers===0?'No':userRound.numPlayers} ${pluralize('Player',userRound.numPlayers)}  signed up`}}</p>
                  <p class="is-size-7">{{userRound.courseLabel}}</p>
                  <div class="buttons mt-5">
                    <b-button v-if="userRound.isScheduled" type="is-primary" @click="openRound(userRound)">Open</b-button>
                    <b-button v-if="!userRound.closeSignups" @click="handleSchRoundClick(userRound)" :type="schRoundButtonLabel(userRound)==='Open'?'is-primary':''">{{schRoundButtonLabel(userRound)}}</b-button>
                    <b-button @click="sendRoundEmail(userRound)">Send Email</b-button>
                    <p v-if="userRound.closeSignups" class="has-text-info mb-1">Round is closed to signups</p>
                  </div>
                </td>
                <td >
                  <p class="has-text-info">{{schRoundLabel(userRound)}}</p>
                  <p v-if="userRound.scoreDocId===''" class="has-text-info">You are not signed up</p>
                  <template v-else>
                    <template v-if="userRound.isScheduled">
                      <p class="has-text-info mb-1">{{userRound.teeTimeLabel}}</p>
                      <b-input placeholder="Tee Time Request" :value="userRound.teeTimeRequest" @blur="updateTeeTimeRequest(userRound, $event.target.value)"></b-input>
                      </template>
                  </template>
            <!--      <a v-if="userRound.hasTeeTimeRequest" class="has-text-link" @click="chgTeeTimeRequest(userRound)">Make Request</a>-->

                  
                </td>
                
              </tr>


            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
            
    <b-modal :active.sync="showHandicapDetails" has-modal-card :full-screen="isMobile">
      <handicap-details 
        :indexLookup = "indexLookup" 
        :handicapCalc = "handicapCalc"
        :playerInfo = "handicapPlayerInfo"
        :playerTees = "playerTees"

        :viewOnly = "true"
        :handicapFac = "group.getHandicapFac()"
        @useHandicap = "setIndex" />
    </b-modal>

  <!--  <b-modal :active.sync="showTeeTimeRequest" has-modal-card :full-screen="isMobile">
      <tee-time-request
        :startingTime = "group.startingTime"
        @saveModal = "setTeeTimeRequest" />
    </b-modal>-->

    
  </div>
</template>

<script>

import GroupController from '../../controllers/group'
import ScoreController from '../../controllers/score'

import HandicapDetails from '../Dialogs/HandicapDetails'
import IndexLookup from '../../helpers/indexLookup'
import HandicapCalc from '../../helpers/handicapCalc'

import dayjs from 'dayjs'
import pluralize from 'pluralize'

import DBUpdates from '../../helpers/dbUpdates'

export default {
  data () {
    return {
      roundsNotSignedUp: [],
      showHandicapDetails: false,
      indexLookup: null,
      handicapCalc: null,
      handicapPlayerInfo: {},
      groupIndex: '',
      ghinIndex: '',
      groupHandicap: '',
      ghinHandicap: '',
      useGroupIndex: false,
      groupIndexLoading: false,
      ghinIndexLoading: false,
      courseName: '',
      groupObjArray: [],
      loadingGroup: false
      // showTeeTimeRequest: false,
      // teeTimeRound: null
    }
  },
  props: ['user'],
  components: {
    'handicap-details': HandicapDetails
    // 'tee-time-request': TeeTimeRequest
  },

  async created () {
    await this.loadGroupObjArray()
  },
  
  mounted () {

    if (this.groupIndex==='' || this.ghinIndex==='') this.getHandicap()

  },
  computed: {
    error: {
      set (newValue) {
        if (!newValue) {
          this.$store.commit('user/errorMsg','')
        }
      },
      get () {
        return this.errorMsg.length > 0
      }
    },
    group () {
      return this.$store.getters['group/group']
    },

    playersArray () {
      return this.$store.getters['group/playersArray']
    },
 
    playerTees () {
      let tees = []
      if (this.group) {
        tees = this.group.course.getPlayerTees()
      }
      return tees
    },
    tab () {
      return this.$store.getters['user/tab']
    },
   

    userSchRoundsArray () {
      const userRounds = this.$store.getters['user/userSchRounds']
      let aRounds = []
      if (userRounds) aRounds = userRounds.aRounds
      return aRounds
    },
    userPostedScoresArray () {
      const userScores = this.$store.getters['user/userPostedScores']
      let aScores = []
      if (userScores) aScores = userScores.aScores
      return aScores
    },
    roundInProgress () {
      let round = this.userSchRoundsArray.find(r => r.isInProgress && (r.scoreDocId !== ''))

      if (round === undefined) round = null
     
      return round

    },
    roundInProgressLabel () {
   
      return this.roundInProgress.formattedDate + ' Round IN PROGRESS'
    },
    numLimitScores () {
      return this.$store.getters['user/numLimitScores']
    },
    currentSeason () {
      return this.$store.getters['user/currentSeason']
    },
    onPlayerLoaded () {
      return this.$store.getters['user/onPlayerLoaded']
    },
    userName () {
      let name = ''
      if (this.user) name = this.user.name
      return name
    },
    groupName () {
      let name = ''
      if (this.user) name = this.user.groupName
      return name
    },
    userTee () {
      let tee = ''
      if (this.user) tee = this.user.tee
      return tee
    },
    hasMultipleGroups () {
      let mult = false
      if (this.user) mult = this.user.groups.length > 1
      return mult
    },
    
    trackHandicap () {
      let track = false
      if (this.group) track = this.group.handicap.lookupGhinIndex || this.group.handicap.useGameIndex
      return track
    },
    useGameIndex () {
      let gameIndex = false
      if (this.group) gameIndex = this.group.handicap.useGameIndex
      return gameIndex
    },
    useGhinIndex () {
      let ghinIndex = false
      if (this.group) ghinIndex = this.group.handicap.lookupGhinIndex
      return ghinIndex
    }
    
  
  },
  watch: {
    'user.tee': function(newTee, oldTee) {
      if (newTee !== oldTee) this.getHandicap()
    },
    onPlayerLoaded: function (newVal, oldVal) {
      if (newVal) this.getHandicap()

    }
  },
  methods: {
    pluralize,
    async loadGroupObjArray () {
    
      this.groupObjArray = []
      for (let groupObj of this.user.groups) {
  
        if (groupObj.name === '') {
          const response = await GroupController.getGroupName(groupObj.docId)
       
          if (response.errorMsg === '') {
            groupObj.name = response.groupName
       
          }
        
           if (groupObj.docId !== this.user.groupDocId) {
      
              this.groupObjArray.push({docId: groupObj.docId, name: groupObj.name})
            } 
        }
        else {
          if (groupObj.docId !== this.user.groupDocId) {
       
            this.groupObjArray.push({docId: groupObj.docId, name: groupObj.name})
          } 
        }
       
        
      }
    
     
    },
    
    schRoundLabel (userRound) {
      let label = ''
     
      if (userRound.isScheduled) {
        if (userRound.scoreDocId !== '') label = 'You are Signed Up'
      }
      else {
        label = userRound.roundStatus
      }
        
     
      return label
    },
   
    schRoundButtonLabel (userRound) {
      let label = ''
      if (userRound.scoreDocId === '' && userRound.isScheduled) {
        label = "Sign Up"
      }
      else {
        if (userRound.isInProgress || userRound.isCompleted) {
          label = "Open"
        }
        else {
          label = "Cancel Signup"
        }
        
      }
      return label
    },

    async openRound (userRound) {
  
      if (userRound.tournamentDocId === null) {
        await this.$store.dispatch('round/open', {groupDocId: this.group.docId, roundDocId: userRound.docId, playerScoreDocId: userRound.scoreDocId, roundNum: userRound.roundNum, from: 'user'})
 
      }
      else {
        await this.$store.dispatch('tournament/open', {groupDocId: this.group.docId, tournamentDocId: userRound.tournamentDocId, from: 'user'})

      }
     },
    async handleSchRoundClick (userRound) {
 
      if (userRound.scoreDocId === '' && userRound.isScheduled) {
        const player = this.playersArray.find(p => p.docId === this.user.playerDocId)

        if (userRound.tournamentDocId === null) {
          await this.$store.dispatch('round/signUp',{groupDocId: this.group.docId, roundDocId: userRound.docId, player})
        }
        else {
          await this.$store.dispatch('tournament/signUp',{groupDocId: this.group.docId, tournamentDocId: userRound.tournamentDocId, player})
        }
         
      }
      else {
        if (userRound.isInProgress || userRound.isCompleted) {
           this.$store.dispatch('round/open', {groupDocId: this.group.docId, roundDocId: userRound.docId, playerScoreDocId: userRound.scoreDocId, roundNum: ''})
  
          // await this.openRound (userRound)
         }
        else {
          if (userRound.tournamentDocId === null) {
            await this.$store.dispatch('round/cancelSignUp',{groupDocId: this.group.docId, scoreDocId: userRound.scoreDocId})
          }
          else {
            await this.$store.dispatch('tournament/cancelSignUp',{groupDocId: this.group.docId, tournamentDocId: userRound.tournamentDocId, playerDocId: this.user.playerDocId},{ root: true })
          }
          this.$buefy.toast.open({
            message: 'Signup canceled for ' + userRound.dateLabel,
            type: 'is-success'
          })
        }
        
      }
    },
    getGroupEmails () {
      
      const emails = this.playersArray.filter(player => player.type==='Active').map(player => player.email).sort()

      return  emails.join(',')
      // if (emails.length > 0) {
      //   const txt = emails.join(',')
  
      //   copyToClipboard(txt)

      //   this.$buefy.toast.open({
      //     message: 'Emails Copied to Clipboard',
      //     type: 'is-success'
      //   })
      // }
     

   
    },
    async sendRoundEmail (userRound) {
      const emails = await this.$store.dispatch('round/getEmails', {groupDocId: this.group.docId, roundDocId: userRound.docId})
      
      
      const element = document.createElement('a');
      element.setAttribute('href', 'mailto:' + emails.join(','))

      element.style.display = 'none'
      document.body.appendChild(element)

      element.click()

      document.body.removeChild(element)
    },

    async changeGroup (groupDocId) {
      this.loadingGroup = true
      await this.$store.dispatch('user/changeUserGroup', groupDocId)
      await this.getHandicap()
      await this.loadGroupObjArray()
      this.loadingGroup = false
    },
    
    gotoGroup () {
      this.$router.push('/group')
    },
    openHandicapDetails() {
      this.handicapPlayerInfo.indexSource = this.group.handicap.useGameIndex?'Group':'GHIN'
      this.showHandicapDetails = true
    },
   
    setIndex (indexInfo) {
     
      this.index = indexInfo.getIndex('full18')
      this.indexSource = indexInfo.indexSource
      this.handicap = this.handicapCalc.playingHandicapSide(this.index, this.user.tee, this.group.getHandicapFac().full18, 'full18')

    },
    async getHandicap () {
      if (this.group === null && this.user)  await this.$store.dispatch('group/getGroup', this.user.groupDocId)
     
      if (this.group === null) {
      
        return
      }
      this.useGroupIndex = this.group.handicap.useGameIndex
      this.courseName = this.group.course.name
  
      this.handicapPlayerInfo = {
        playerDocId: this.user.playerDocId,
        name: this.user.name,
        ghinNum: this.user.ghinNum,
        indexSource: this.group.handicap.useGameIndex?'Group':'GHIN',
        tee: this.user.tee,
        gender: this.user.gender,
        type: this.user.groupStatus,
        state: this.group.course.state,
        clubName: this.group.course.name
      }
      const settings = {
        handicapSettings: this.group.handicap,
        groupDocId: this.group.docId,
        // state: this.group.course.state,
        // lookupGroupScores: this.group.handicap.useGameIndex,
        // lookupGhinIndex: this.group.handicap.lookupGhinIndex
      }
      this.indexLookup = new IndexLookup(settings)
      this.handicapCalc = new HandicapCalc(this.group.course, this.group.handicap.maxHandicap)

      const lookUps = []

      if (this.group.handicap.useGameIndex) {
        this.groupIndexLoading = true
        lookUps.push(
          this.indexLookup.getGroupIndexInfo(this.handicapPlayerInfo).then((indexInfo) => {
           
            if (indexInfo.index) {
              this.groupIndex = indexInfo.getIndex('full18')
              this.groupHandicap = this.handicapCalc.playingHandicapSide(this.groupIndex, this.user.tee, this.group.getHandicapFac().full18, 'full18')
  
            }
            else {
              this.groupIndex = 'n/a'
              this.groupHandicap = 'n/a'
            }
           
            
            this.groupIndexLoading = false
          })
        )
      }
      
      if (this.group.handicap.lookupGhinIndex) {
        this.ghinIndexLoading = true
        lookUps.push(
          this.indexLookup.getGhinIndexInfo(this.handicapPlayerInfo).then((indexInfo) => {
        
            if (indexInfo.index) {
              this.ghinIndex = indexInfo.getIndex('full18')
              this.ghinHandicap = this.handicapCalc.playingHandicapSide(this.ghinIndex, this.user.tee, this.group.getHandicapFac().full18, 'full18')
            
            }
            else {
              this.ghinIndex = 'n/a'
              this.ghinHandicap = 'n/a'
            }
       
           
            this.ghinIndexLoading = false
    
          })
        )
      }
      
      if (lookUps.length > 0) await Promise.all(lookUps)

      
    
      
    },
    // chgTeeTimeRequest (userRound) {
    //   this.showTeeTimeRequest = true
    //   this.teeTimeRound = userRound
    // },

    async updateTeeTimeRequest (userRound, val) {
      if (val !== userRound.teeTimeRequest) {
        const scoreUpdates = new DBUpdates()
        scoreUpdates.add (userRound.scoreDocId, {teeTimeRequest: val})

        const response = await ScoreController.updateScoreValues (this.user.groupDocId, scoreUpdates)

      if(response.errorMsg.length > 0) {
        this.error = response.errorMsg
      }
      else {
        this.error = ''
      }
      }
   
    },



    changeTee () {

    },
    runUpdateProc () {

      this.$store.dispatch('group/updateAllRounds')
   

    },
    backupDb () {

     this.$store.dispatch('user/backupDb')
    },

    deleteAnonymousUsers () {
      this.$store.dispatch('group/deleteAnonymousUsers')
    }
  }
}

</script>

<style>
 .mobile-width-adjust {
    margin-left:10px;
    margin-right:10px;
  }
  .card-margin-top {
    margin-top: 8px;
  }
  .card-margin-side {
    margin-left: 4px;
    margin-right: 4px;
  }
  .card-margin {
    margin: 8px;
  }
  .card-width {
    width: 600px !important
  }
  .card-content-padding {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;

  }
</style>
