<template>
  <div>
    <toolbar @selected="selectTab"/>
    <div class="section">
      <div class="columns is-centered">
        <div class="column is-two-fifths">
          <b-notification :active.sync="error">
            {{ errorMsg }}
          </b-notification>
          <div class="card">
            <div class="card-header">
              <p class="card-header-title is-size-4 is-centered">
                Create Group
              </p>
            </div>
            <div class="card-content">
              <b-steps v-model="activeStep" @input="changeStep()">
                <b-step-item step="1" label="Your Info" >
              
                  <template v-if="isUserAuthenticated">
                    <p v-if="alreadySignedIn" class="is-size-5 has-text-centered has-margin-top-40">You are already signed into girlap and a member of one or more groups</p>
                    <br v-if="alreadySignedIn">
                 
                    <p class="is-size-5 has-text-centered has-margin-bottom-40">For this new Group, you will be added as a member and be the Administrator</p>
                  </template>
                 
                  <template v-else>
                    <b-field label="Email" :message="emailErrors">
                      <b-input 
                        type="email"
                        v-model="email"
                        @blur="checkEmail()"
                        maxlength="30" />
                    </b-field>

                    <p v-if="doSignIn" class="is-size-5 has-margin-top-10 has-margin-bottom-20">You are already registered in girlap, enter your password and sign in to continue</p>
                    

                    <b-field v-if="doUserInfo" label="Name">
                      <b-input
                        v-model="name"
                        size="is-capitalized"
                        @blur="capName('name')" />
                    </b-field>
                    <b-field v-show="doSignIn || doUserInfo" label="Password" :message="passwordErrors" >
                      <b-input
                        ref="password"
                        type="password"
                        maxlength="30"
                        v-model="password"
                        @blur="$v.password.$touch()"
                        password-reveal />
                    </b-field>
                    <b-field v-show="doUserInfo" label="Confirm Password" :message="confirmPasswordErrors" >
                      <b-input
                      
                        type="password"
                        maxlength="30"
                        v-model="confirmPassword"
                        @blur="$v.confirmPassword.$touch()"
                        password-reveal />
                    </b-field>
                    <b-button 
                      v-show="doSignIn"
                      class="is-fullwidth" 
                      type="is-primary"
                      :disabled="$v.email.$invalid || $v.password.$invalid" 
                      @click="signIn()"
                      @keyup.native.enter="enter" >
                      Sign In
                    </b-button>
                  </template>

                
                </b-step-item>

                <b-step-item step="2" label="Group Info">
                  <b-field label="Group Name">
                    <b-input
                      ref="groupName"
                      v-model="groupName"
                      size="is-capitalized"
                      @blur="capName('groupName')" />
                  </b-field>
                  <select-course 
                    :course="course" 
                    label="Home Course" 
                    message="Type in course name and select from list. If course can't be found, leave field blank and add course after your group has been created."
                    @courseSelected="selectCourse" 
                    :disabled="false"/>

                 
                   <b-field grouped :group-multiline="isMobile">
                     <b-field label="Season Name" expanded>
                      <b-input 
                        v-model="seasonName"
                      />
                    </b-field>
                    <b-field label="From">
                      <b-datepicker
                        v-model="seasonFromDate" style="width:105px;"/>
                    </b-field>
                    <b-field label="To">
                      <b-datepicker
                        v-model="seasonToDate"  style="width:105px;"/>
                    </b-field>
                  </b-field>
                  
                </b-step-item>

                <b-step-item step="3" label="Round Defaults">
                   <b-field grouped>
                    <b-field label="Tee Start Time">
                      <b-timepicker
                        v-model="startingTime" 
                        icon="clock" 
                        style="width:9em;"
                         />
                    </b-field>
                    <b-field label="Interval (min)">
                      <b-numberinput
                        v-model="interval"
                        :max="59" :min="1"
                        style="width:5em;"
                        :controls="false"
                      />
                    </b-field>
                  </b-field>

                  <b-field :message="['Scores can entered as 9 hole scores or hole-by-hole','Hole-by-hole scoring allows players to enter scores during the round on a mobile device','Hole-by-hole scoring is also needed for some games like Skins']">
                    <b-switch v-model="holeByHoleScores">
                      Hole by Hole Scoring
                    </b-switch>
                  </b-field>

                  <b-field :message="['When setting up games, you can specify a bet for every game and girlap will calculate a total payout','Winnings are tracked and shown in the season standings']">
                    <b-switch v-model="betOnGames">Bet On Games</b-switch>
                  </b-field>

                </b-step-item>

                <b-step-item step="4" label="Finish">
                  <div class="content">
                    <p>After your Group has been created you can:</p>
                    <ul>
                      <li>
                        Add players to the group. You can send email invitations to each player to register for girlap. Registered players can review their handicap info, round statistics, and enter scores during a round. You also have the option of importing an existing player roster.
                      </li>
                      <li class="has-margin-top-10">
                        Create a set of default games for each round. You can optionally set a bet for each game and girlap will figure the payouts.
                      </li>
                      <li class="has-margin-top-10">
                        Track handicaps using scores played within the group. There are many calculation options. You also have the option if entering indexes or retreiving indexes from GHIN.
                      </li>
                      <li class="has-margin-top-10">
                        Schedule rounds. If desired, email invitations can be sent to all Active players to sign up.
                      </li>
                      <li class="has-margin-top-10">
                        Create tee time pairings for scheduled rounds and optionally turn on hole-by-hole scoring.
                      </li>
                    </ul>
                  </div>
        
                </b-step-item>

                <div
                    class="buttons is-centered"
                    slot="navigation"
                    slot-scope="{previous, next}">
                    <b-button
                      outlined
                      size="is-medium"
                      icon-left="chevron-left"
                      :disabled="previous.disabled"
                      @click.prevent="previous.action">
                        Previous
                    </b-button>
                    <b-button
                      v-if="activeStep===3"
                      outlined
                      size="is-medium"
                      @click="createGroup">
                        Create Group
                    </b-button>
                    <b-button
                      v-else
                      outlined
                      size="is-medium"
                      icon-right="chevron-right"
                      :disabled="(next.disabled && activeStep!==3) || (activeStep===0 && !userInfoOk) || (activeStep===1 && !groupInfoOk)"
                      @click.prevent="next.action">
                        Next
                    </b-button>
                </div>
              </b-steps>
            </div>
          </div>
          
        </div>
      </div>
      <b-modal  :active.sync="showAbout" >
        <about />
      </b-modal>
      <b-modal has-modal-card :active.sync="showAccount" >
        <account />
      </b-modal>
    </div>
  </div>


</template>

<script>
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'
import { capitalizeWord } from '../../lib'
import dayjs from 'dayjs'

import Toolbar from '../../components/Shared/toolbar'
import About from '../../components/Dialogs/About'
import Account from '../../components/Dialogs/Account'
import SelectCourse from '../../components/Course/SelectCourse'
import Course from '../../models/course'

import UserController from '../../controllers/user'

export default {
  data () {
    return {
      activeStep: 0,
      alreadySignedIn: this.isUserAuthenticated,

      doSignIn: false,
      doUserInfo: false,

      groupName: '',
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      course: new Course(),
      startingTime: (dayjs().set('h',9).set('m',0)).toDate(),
      interval: 10,
      holeByHoleScores: false,
      betOnGames: true,
      seasonName: `${dayjs().year()}`,
      seasonFromDate: dayjs().startOf('year').toDate(),
      seasonToDate: dayjs().endOf('year').toDate(),

      showAbout: false,
      showAccount: false
    }
  },


  components: {
    'toolbar': Toolbar,
    'about': About,
    'account': Account,
    'select-course': SelectCourse
  },
  validations: {
  
    name: {
      required
    },
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(6)
    },
    confirmPassword: {
      sameAsPassword: sameAs('password')
    }
    
  },
  computed: {
    emailErrors () {
      return this.$v.email.email ? '' : 'Not a valid email'
    },
    passwordErrors () {
      return this.$v.password.minLength ? '' : 'Password must be at least six characters'
    },
    confirmPasswordErrors () {
      return this.$v.confirmPassword.sameAsPassword ? '' : 'Passwords are not identical'
    },
    user () {
      return this.$store.getters['user/user']
    },
    isUserAuthenticated () {
      return this.$store.getters['auth/isAuthenticated']
    },
    error: {
      set (newValue) {
        if (!newValue) {
          this.$store.commit('group/errorMsg','')
        }
      },
      get () {
        return this.errorMsg.length > 0
      }
    },
    errorMsg () {
      return this.$store.getters['group/errorMsg']
    },
    groupInfoOk () {
      return this.groupName.length>0 && this.seasonName.length>0
    },
    userInfoOk () {
      return this.isUserAuthenticated
    }
  },
 
  methods: {
     selectTab (tabName) {
      
      if (tabName === 'about') {
        this.showAbout = true
      }
      else if (tabName === 'account') {
        this.showAccount= true
      }
      
    },
    capName (field) {
      this[field] = capitalizeWord(this[field])
    },

    changeStep (step) {
      if (step === 1) this.$refs['groupName'].focus()
    },

    async checkEmail () {
      this.$v.email.$touch()

      if (!this.$v.email.$invalid) {
        const userExists = await UserController.isRegistered(this.email)

        if (userExists) {
          this.doSignIn = true
          this.$nextTick(() => {
            this.$refs['password'].focus()
          })
        }
        else {
          this.doSignIn = false
          this.doUserInfo = true
        }
      }
     
    },

    async signIn () {
   
      const response = await UserController.signIn(this.email, this.password)


      if (response.errorMsg.length > 0) {
        this.error = response.errorMsg
      } 
      else {
        this.alreadySignedIn = false
        
        this.$store.commit('user/user', response.user)

      }
    },

    selectCourse (course) {
      this.course = course
    },
    
    async createGroup () {
      const createInfo = {
        groupName: this.groupName,
        courseDocId: this.course.docId,
        startingTime: this.startingTime,
        interval: this.interval,
        holeByHoleScores: this.holeByHoleScores,
        betOnGames: this.betOnGames,
        seasonName: this.seasonName,
        seasonFromDate: this.seasonFromDate,
        seasonToDate: this.seasonToDate
      }
      if (this.isUserAuthenticated) {
        if (this.user === null) {

          this.$store.commit('user/initialize') //try reloading from cache
          if (this.user === null) { // load from db
 
            const uid = this.$store.getters['auth/uid']
            const userDoc = await db.collection('users').doc(uid).get()
  
            const user = new User()
      
            user.docId = uid
            user.email = this.$store.getters['auth/email']

            user.setData(userDoc.data())
          }
         
        }
        createInfo.userDocId = this.user.docId
        createInfo.name = this.user.name
        createInfo.email = this.user.email
      }
      else {
        createInfo.userDocId = ''
        createInfo.name = this.name
        createInfo.email = this.email
        createInfo.password = this.password
      }

      await this.$store.dispatch('group/createGroup', createInfo)
    }
  }
}
</script>

<style>
  .autocapitalize input{
    text-transform: capitalize
  }
</style>
