
import { sortArrayObj, roundTo, formatHandicapObj } from '../lib'
import pluralize from 'pluralize'


export function createTournamentLeaderboards (tournament, scores) {
  
  const leaderBoards = {}

  for (let game of tournament.games.aGames) {
    game.totalPool = game.bet.amount * tournament.scores.size

    leaderBoards[game.id] = createTournamentLeaderboard (game, tournament, scores)

    calcPayouts(game, leaderBoards[game.id].leaderBoardItems, tournament.scores.size)

    leaderBoards[game.id] = leaderBoards[game.id].leaderBoardItems.concat(leaderBoards[game.id].notCountedPlayers)
  }
   
  return leaderBoards
}

function createTournamentLeaderboard (game, tournament, scores) { // scores is all scores from all rounds
 
  const leaderBoardItems = []
  const wdPlayers = []
  const notStartedPlayers = []
  let notCountedPlayers = []
  const unscoredPlayers = []

  if (game.prop === 'stroke') {
    let leaderBoardItem = {}
    if (!game.isTeamGame) {
      for (let tournamentScore of tournament.scores.aScores) {
  
        leaderBoardItem = {
          pos: '',
          rank: 0,
          ties: 1,
          docId: '',
          playerDocId: tournamentScore.playerDocId,
          name: tournamentScore.name,
          tee: tournamentScore.tee,
          nameLabel: `${tournamentScore.name} ${formatHandicapObj (tournamentScore.handicap,game.side)} (${tournamentScore.tee})`,
          toPar: 0,
          netScore: '',
          roundToPars: [],
          payOut: 0,
          teeTime: '', 
          thru: '',
          number: ''
        }

        let netScore = 0
       
        for (let round of tournament.rounds.aRounds) {
          if (scores.size === 0) break
          const roundGame = round.games.aGames.find(g=>(g.prop===game.prop && g.playType===game.playType && game.scoreType===game.scoreType))
          // if (roundGame !== undefined) {

            const score = scores.aScores.find(s=>(s.roundDocId===round.docId && s.playerDocId===tournamentScore.playerDocId))
            
            if (score !== undefined) {
            
              leaderBoardItem.docId = score.docId
              if (score.wd) {
                item.pos = 'WD'
                item.number = ''
                wdPlayers.push(item)
              }
              else {
                if (round.isScheduled) {
                  if (round.teeTimes.hasTeeTime(score.docId)) {
                    const teeTimeVal = round.teeTimes.getTeeTimeByScoreDocId(score.docId).time
                    leaderBoardItem.roundToPars.push(teeTimeVal)
                  }
                  else {
                    leaderBoardItem.roundToPars.push('')
                  }
                  
                }
                else {
                  if (round.isInProgress) leaderBoardItem.thru = score.final ? 'F' : score.holeThru
                  
                
                  if (game.isStableford) {
                    leaderBoardItem.toPar += score.toPoints(game.side, game.useQuota)
                    leaderBoardItem.roundToPars.push(score.toPoints(game.side, game.useQuota))
                  }
                  else {
                    leaderBoardItem.toPar += score.toPar(game.side)
                    leaderBoardItem.roundToPars.push(score.toPar(game.side))
                  }
                  
                  netScore += score.netScore[game.side]
              
                }
              }
              
            }
          
          }
          // else {
          //   leaderBoardItem.roundToPars.push(0)
          // }
          // console.log('leaaderitem', leaderBoardItem)
          if (tournament.isCompleted || tournament.isPosted) leaderBoardItem.thru = 'F'
          if (netScore > 0) leaderBoardItem.netScore = netScore

          if (leaderBoardItem.netScore === '') {
            unscoredPlayers.push(leaderBoardItem)
          }
          else {
            leaderBoardItems.push(leaderBoardItem)
          }
        }
      // }

    }
    else {
      for (let team of game.teamScores.aTeams) {
       
     
        teeTimeVal = ''
        if (round.teeTimes.hasTeeTime(team.scoreDocIds[0])) {
          teeTimeVal = round.teeTimes.getTeeTimeByScoreDocId(team.scoreDocIds[0]).time
        }
    
        leaderBoardItem = {
          pos: '',
          rank: 0,
          ties: 1,
          teamNum: team.teamNum,
          nameLabel: `Team ${team.teamNum} - ${team.teamName()}`,
          nameLabelShort: team.teamNameShort(),
          payOut: 0,
          handicap: '',
          handicapFull: '',
          netScore: '',
          roundToPars: [],
          toPar: 0,
          teeTime: teeTimeVal, 
          thru: team.holeThru
        }
      
        if (item.thru === 0 || item.thru === '') {
         
          item.thru = teeTimeVal
          item.handicap = team.handicap('full18')
          item.handicapFull = team.handicap('full18')
          notStartedPlayers.push(item)
        }
        else {
        // figure scores
          let netScore = 0
          for (let round of tournament.rounds.aRounds) {
            const roundTeam = team.copy()
            const teamPlayerDocIds = roundTeam.playerDocIds
            roundTeam.scores = []
            roundTeam.scoreDocIds = []
            for (let playerDocId of teamPlayerDocIds) {
              const score = scores.aScores.find(s=>(s.roundDocId===round.docId && s.playerDocId===playerDocId))
              if (score !== undefined) {
                roundTeam.scoreDocIds.push(score.docId)
                roundTeam.scores.push(score)
              }
            }
            leaderBoardItem.roundToPars.push(roundTeam.toPar(game.side))
            leaderBoardItem.toPar += roundTeam.toPar(game.side)
            netScore += roundTeam.netScore[game.side]
          
          }
          if (netScore > 0) leaderBoardItem.netScore = netScore
      
          if (leaderBoardItem.netScore === '') {
            unscoredPlayers.push(leaderBoardItem)
          }
          else {
            leaderBoardItems.push(leaderBoardItem)
          }
        }
      }
    }
    if (game.isStableford) {
      sortArrayObj(leaderBoardItems, 'toPar', 'desc')
    }
    else {
      sortArrayObj(leaderBoardItems, 'toPar', 'asc')
    }
   
    sortArrayObj(notStartedPlayers, 'teeTime', 'asc')
    notCountedPlayers = unscoredPlayers.concat(notStartedPlayers.concat(wdPlayers))
  }
  return {leaderBoardItems, notCountedPlayers}
}

export function createRoundLeaderboards (round, scores, skins=[]) {
 
  const leaderBoards = {}
 
  for(let game of round.games.aGames) {
    game.totalPool = game.bet.amount * scores.size
  }
  let totalDotPool = 0
  let totalDotCount = 0
  for(let game of round.games.aGames) {
    leaderBoards[game.id] = createRoundLeaderboard(game, round, scores, skins)
  
    if (game.dotPool) {
      totalDotPool += game.totalPool
    
      // leaderBoards[game.id].leaderBoardItems.forEach(item => totalCount += item.number)
      let totalCount = leaderBoards[game.id].leaderBoardItems.reduce((total, item) => total+item.number, 0)
      totalDotCount += totalCount

      
    }
  }
  let dotPayout = 0
  if (totalDotCount > 0) dotPayout = totalDotPool / totalDotCount

  for(let game of round.games.aGames) {

    if (game.dotPool) game.dotPayout = dotPayout

    calcPayouts(game, leaderBoards[game.id].leaderBoardItems, scores.size)
    leaderBoards[game.id] = leaderBoards[game.id].leaderBoardItems.concat(leaderBoards[game.id].notCountedPlayers)
  }
  
 
  return leaderBoards
}

function createRoundLeaderboard (game, round, scores, skins) {
  let teeTimeVal
  let item
  const wdPlayers = []
  const notStartedPlayers = []
  let notCountedPlayers = []

  const unscoredPlayers = []
  const leaderBoardItems = []

  if (game.prop === 'stroke') {
    if (!game.isTeamGame) {
      for (let score of scores.aScores) {
        teeTimeVal = ''
        if (round.teeTimes.hasTeeTime(score.docId)) {
          teeTimeVal = round.teeTimes.getTeeTimeByScoreDocId(score.docId).time
        }
        
        item = {
          pos: '',
          rank: 0,
          ties: 1,
          docId: score.docId,
          playerDocId: score.playerDocId,
          name: score.name,
          tee: score.tee,
          nameLabel: score.fullSlotLabel(),
          payOut: 0,
          score: '',
          handicap: '',
          handicapFull: '',
          netScore: '',
          toPar: '',
          teeTime: teeTimeVal, //dayjs(score.pairing.teeTime,'HH:mma').toDate(),
          thru: score.final ? 'F' : score.holeThru
        }
    
        if (score.wd) {
          item.pos = 'WD'
          item.number = ''
          wdPlayers.push(item)
        }
        else if (item.thru === 0 || item.thru === '') {
         
          item.thru = teeTimeVal
          if (score.handicap === null) {
            item.handicap = 0
            item.handicapFull = 0
          }
          else {
            item.handicap = score.handicap.full18
            item.handicapFull = score.handicap.full18
          }
        
          notStartedPlayers.push(item)
        }
        else {
        // figure scores
    
          item.score = score.score[game.side]
          if(item.score === 0 || item.score === '') {
            item.score = ''
            item.netScore = ''
            item.handicap = ''
            item.handicapFull = ''
            item.toPar = ''
            item.payOut = 0
          }
          else {
          
            item.handicap = score.handicapCompleted(game.side)
          
            item.handicapFull = score.handicap===null?0:score.handicap[game.side]
            if(typeof item.handicap === 'number') {
              item.netScore = score.score[game.side] - item.handicap
            }
            else {
              item.netScore = score.score[game.side]
            }
            let parCompleted = score.parCompleted(game.side)
        
            if (game.isStableford) {
              item.toPar = score.toPoints(game.side, game.useQuota)
            }
            else {
              item.toPar = item.netScore - parCompleted
            }
            
          }
          if (item.netScore === '') {
            unscoredPlayers.push(item)
          }
          else {
            leaderBoardItems.push(item)
          }
          
        }
       
      }
    }
    else {
      for (let team of game.teamScores.aTeams) {
       
        team.scoreCount = game.scoreCount
        
        teeTimeVal = ''
        if (round.teeTimes.hasTeeTime(team.scoreDocIds[0])) {
          teeTimeVal = round.teeTimes.getTeeTimeByScoreDocId(team.scoreDocIds[0]).time
        }
    

        item = {
          pos: '',
          rank: 0,
          ties: 1,
          // docId: score.docId,
          // name: team.teamName(),
          // tee: score.tee,
          teamNum: team.teamNum,
          nameLabel: `Team ${team.teamNum} - ${team.teamName()}`,
          nameLabelShort: team.teamNameShort(),
          payOut: 0,
          score: '',
          handicap: '',
          handicapFull: '',
          netScore: '',
          toPar: '',
          teeTime: teeTimeVal, 
          thru: team.holeThru
        }
      
        if (item.thru === 0 || item.thru === '') {
         
          item.thru = teeTimeVal
          item.handicap = team.handicap('full18')
          item.handicapFull = team.handicap('full18')
          notStartedPlayers.push(item)
        }
        else {
        // figure scores
   
          item.score = team.score(game.side)
          if (item.score === 0 || item.score === '') {
            item.score = ''
            item.netScore = ''
            item.handicap = ''
            item.handicapFull = ''
            item.toPar = ''
            item.payOut = 0
          }
          else {
            item.handicap = team.handicapCompleted(game.side)
            item.handicapFull = team.handicap(game.side)
            item.netScore = team.netScore(game.side)
            if (game.isStableford) {
              item.toPar = team.toPoints(game.side, game.useQuota)
            }
            else {
              item.toPar = team.toPar(game.side)
            }
 
          }

      
          if (item.netScore === '') {
            unscoredPlayers.push(item)
          }
          else {
            leaderBoardItems.push(item)
          }
          
        }
      }
    }
    
    if (game.isStableford) {
      sortArrayObj(leaderBoardItems, 'toPar', 'desc')
    }
    else {
      sortArrayObj(leaderBoardItems, 'toPar', 'asc')
    }
  
    sortArrayObj(notStartedPlayers, 'teeTime', 'asc')
    notCountedPlayers = unscoredPlayers.concat(notStartedPlayers.concat(wdPlayers))

  }
  else if (game.prop === 'skins') {

    if (!round.holeByHoleScores) { // NOT hole by hole scoring
      for (let score of scores.aScores) {
        if (score.skinsCount > 0) {
          item = {
            docId: score.docId,
            playerDocId: score.playerDocId,
            name: score.name,
            tee: score.tee,
            nameLabel: score.fullSlotLabel(),
            number: score.skinsCount,
            payOut: 0,
            pos: '',
            thru: 'Final'
          }
          
          leaderBoardItems.push(item)
        }
      }
    }
    else {
    
      for (let skin of skins) {
        const score = scores.aScores.find(s=>s.docId === skin.scoreDocId)
        item = {
          docId: score.docId,
          playerDocId: score.playerDocId,
          name: score.name,
          tee: score.tee,
          nameLabel: score.fullSlotLabel(),
          number: 1,
          holeNum: skin.holeNum,
          par: score.getHolePar(skin.holeNum),
          payOut: 0,
          pos: '',
          score: skin.score,
          netScore: skin.netScore,
          handicapStrokes: score.getHandicapStrokesHole(skin.holeNum, 'full18'),
          thru: scores.isHoleCompleted(skin.holeNum) ? 'Final' : 'Good Luck!'
        }
     
        leaderBoardItems.push(item)

      }
    }
      
    
    // for (let score of scores.aScores) {
    
    
    //   if (!round.holeByHoleScores) { // NOT hole by hole scoring
    //     if (score.skinsCount > 0) {
    //       item = {
    //         docId: score.docId,
    //         playerDocId: score.playerDocId,
    //         name: score.name,
    //         tee: score.tee,
    //         nameLabel: score.fullSlotLabel(),
    //         number: score.skinsCount,
    //         payOut: 0,
    //         pos: '',
    //         thru: 'Final'
    //       }
        
          
    //       leaderBoardItems.push(item)
    //     }
        
    //   }
    //   else {
    //     for (let skin of score.skins) {
    //       item = {
    //         docId: score.docId,
    //         playerDocId: score.playerDocId,
    //         name: score.name,
    //         tee: score.tee,
    //         nameLabel: score.fullSlotLabel(),
    //         number: 1,
    //         holeNum: skin.holeNum,
    //         par: score.getHolePar(skin.holeNum),
    //         payOut: 0,
    //         pos: '',
    //         score: skin.score,
    //         netScore: skin.netScore,
    //         handicapStrokes: score.getHandicapSkinStrokesHole(skin.holeNum, 'full18'),
    //         thru: scores.isHoleCompleted(skin.holeNum) ? 'Final' : 'Good Luck!'
    //       }
       
    //       leaderBoardItems.push(item)
        
    //     }
    //   }

    // }
    sortArrayObj(leaderBoardItems, 'holeNum', 'asc')
  }
  else if (game.prop === 'dots') {
    let leaderBoardItemNum = 0
    if (game.isTeamGame) {
      for (let team of game.teamScores.aTeams) {
     
        if (team.dotsCount > 0) {
          leaderBoardItemNum++
          item = {
            teamNum: team.teamNum,
            leaderBoardItemNum: leaderBoardItemNum,
            nameLabel: `Team ${team.teamNum} - ${team.teamName()}`,
            nameLabelShort: team.teamNameShort(),
            number: team.dotsCount,
            payOut: 0,
            pos: '',
            thru: team.holeThru
          }
          
          leaderBoardItems.push(item)
        }
       
      }
    }
    else {
      for (let score of scores.aScores) {
        teeTimeVal = ''
        if (round.teeTimes.hasTeeTime(score.docId)) {
          teeTimeVal = round.teeTimes.getTeeTimeByScoreDocId(score.docId).groupNum
        }
      
        if (!round.holeByHoleScores) { // NOT hole by hole scoring
          if (score.dotsCount > 0) {
            leaderBoardItemNum++
            item = {
              docId: score.docId,
              playerDocId: score.playerDocId,
              leaderBoardItemNum: leaderBoardItemNum,
              name: score.name,
              tee: score.tee,
              nameLabel: score.fullSlotLabel(),
              nameLabelShort: `${score.name} (${score.tee})`,
              number: score.dotsCount,
              payOut: 0,
              pos: '',
              groupNum: teeTimeVal,
              thru: score.holeThru
            }
                
            leaderBoardItems.push(item)
          }
          
        }
        else {
          for (let dot of score.dots) {
            leaderBoardItemNum++
            item = {
              docId: score.docId,
              playerDocId: score.playerDocId,
              leaderBoardItemNum: leaderBoardItemNum,
              name: score.name,
              tee: score.tee,
              nameLabel: score.fullSlotLabel(),
              nameLabelShort: `${score.name} (${score.tee})`,
              number: dot.number,
              holeNum: dot.holeNum,
              par: score.getHolePar(dot.holeNum),
              payOut: 0,
              pos: '',
              groupNum: teeTimeVal,
              handicapStrokes: score.getHandicapStrokesHole(dot.holeNum, 'full18', 'dots'),
              thru: score.holeThru
            }
         
            leaderBoardItems.push(item)
          
          }
        }
  
      }
    }
    
    sortArrayObj(leaderBoardItems, 'number', 'desc')
  }
  else if (game.prop === 'kp') {
    for (let score of scores.aScores) {
      teeTimeVal = ''
      if (round.teeTimes.hasTeeTime(score.docId)) {
        teeTimeVal = round.teeTimes.getTeeTimeByScoreDocId(score.docId).groupNum
      }
    
      
      if (!round.holeByHoleScores) { // NOT hole by hole scoring
        const item = {
          docId: score.docId,
          playerDocId: score.playerDocId,
          name: score.name,
          tee: score.tee,
          nameLabel: score.fullSlotLabel(),
          number: score.kpCount,
          payOut: 0,
          pos: '',
          kpPay: 1,
          groupNum: teeTimeVal,
          thru: score.holeThru
        }
        
        if (item.number > 0) {
          leaderBoardItems.push(item)
        }
      }
      else {
       
        for (let i=0; i<score.kp.length; i++) {
          const item = {
            docId: score.docId,
            playerDocId: score.playerDocId,
            name: score.name,
            tee: score.tee,
            nameLabel: score.fullSlotLabel(),
            number: 1,
            holeNum: score.kp[i],
            kpPay: 0,
            kpDist: score.getKPDist(score.kp[i]),
            payOut: 0,
            pos: '',
            groupNum: teeTimeVal,
            holeScore: score.getHoleScore(score.kp[i]),
            thru: score.holeThru
          }
          // if (score.isRoundComplete()) item.thru='F'
       
          leaderBoardItems.push(item)
        }

      }
         
     
    }
    if (round.holeByHoleScores) {
      const par3Holes = [...new Set(leaderBoardItems.map(i=>i.holeNum))]
      for (let holeNum of par3Holes) {
        const items = leaderBoardItems.filter(i=>i.holeNum===holeNum)

        if (game.kpUseDist) {
          sortArrayObj(items,'kpDist','asc')
        }
        else {
          sortArrayObj(items,'groupNum','desc')
        }
    
       

        for (let i = 0; i<items.length; i++) {
          if (i===0) {
            items[0].kpPay = 1 
            if (items[0].holeScore > 3 && game.kpParOrBetter) { // kp only good if par or better 
              items[0].kpPay = 2
              items[0].number = 0
            }
          }
          else {
            items[i].kpPay = 0
            items[i].number = 0
          }
         
        }

        
      }
      
    } 
    sortArrayObj(leaderBoardItems, 'holeNum', 'asc')
  }

  return {leaderBoardItems, notCountedPlayers, skins}
}
function calcPayouts (game, leaderBoardItems, numPlayers) {
  
 
// figure game payouts

  if (game.prop === 'stroke' || (game.prop === 'dots' && game.bet.payoutType==='places')) {
    leaderBoardItems.forEach((item, index) => item.rank = index+1)

// look for ties
    
    for(let i=1; i<leaderBoardItems.length; i++) {
      const lastItem = leaderBoardItems[i-1]
      const item = leaderBoardItems[i]
 
      if (game.prop === 'stroke') {
        if (item.toPar === lastItem.toPar) {
          item.rank = lastItem.rank
          item.pos = 'T' + item.rank
          lastItem.pos = 'T' + lastItem.rank
        }
      }
      else {
        if (item.number === lastItem.number) {
          item.rank = lastItem.rank
          item.pos = 'T' + item.rank
          lastItem.pos = 'T' + lastItem.rank
        }
      }
    }
    leaderBoardItems.forEach(item => {  // count ties
      if (item.pos[0] === 'T') {
        let count = 0
        leaderBoardItems.forEach(item2 => {
          if (item2.pos === item.pos) count++
        })
        item.ties = count
      }
    })
    leaderBoardItems.forEach(item => {
      if(item.netScore !== '') {
        item.payOut = 0
        if (item.pos === '') item.pos = item.rank
        const numPlaces = game.getNumPlaces(numPlayers)
       
        if (item.rank <= numPlaces) {
          if(item.ties > 1) {
            let percent = 0
            let countPlaces = item.ties

            if (leaderBoardItems.length < numPlaces || item.ties > numPlaces) countPlaces = numPlaces
           
       
            for (let i=1; i<=countPlaces; i++) {
              let placePos = (item.rank-2+i)
        
              const place = game.bet.payout.payTable.places()[placePos]
           
              if (game.hasPayTable()) { 
                percent += game.bet.payout.payTable.getPercent(numPlayers, place)
              
              }
              else {
                if (game.bet.payout.percents[place] !== undefined ) percent += game.bet.payout.percents[place]
              }
 
            }
       
            item.payOut = roundTo(percent * game.totalPool / 100 / item.ties, 2)
  
          }
          else {
           
            let percent
            const place = game.bet.payout.payTable.places()[item.rank-1]

            if(game.hasPayTable()) { // payout table
             
              percent = game.bet.payout.payTable.getPercent(numPlayers, place)
            
            }
            else {
              let unusedPercent = 0
              if (leaderBoardItems.length < numPlaces) {
                for(let i=leaderBoardItems.length+1; i<= numPlaces; i++) {
                  const place2 = game.bet.payout.payTable.places()[i-1]
                  unusedPercent = unusedPercent + (game.bet.payout.percents[place2] / numPlayers)
                }
              } 
              
              percent = unusedPercent + game.bet.payout.percents[place]
              
            }
           
            item.payOut = roundTo(percent * game.totalPool / 100, 2)
          }
        }
      }
      else {
        item.payOut = 0
      }
   
    })
  }
  else {
    if (!game.dotPool) {
      let totalCount = 0
      leaderBoardItems.forEach(item => totalCount += item.number)
      if (totalCount === 0) {
        game.dotPayout = 0
      }
      else {
        game.dotPayout = game.totalPool / totalCount
      }
      
    }
   
   
    leaderBoardItems.forEach(item => item.payOut = roundTo(game.dotPayout * item.number, 2))
  }

}
export function createPayouts (games, players, roundPayouts, leaderBoards) {
 
  
  // create Payouts array
   
  const payOuts = []


  for (let player of players) {

    const payOutItem = {
      playerDocId: player.docId,
      name: player.name,
      score: {},
      payOut: {},
      totalPayout: 0,
      pos: {}
    }
   
   
    for (let game of games.aGames) {
      
      const leaderBoardItems = leaderBoards[game.id]

      if (leaderBoardItems !== undefined) {
     
        if (!game.isTeamGame) {
          const leaderBoardItemsByScore = leaderBoardItems.filter(item => item.playerDocId === payOutItem.playerDocId)

          if (leaderBoardItemsByScore.length > 0) {
            let val = 0
            let paid = 0
            let pos = ''
            let holes = []
        
            for (let leaderBoardItemByScore of leaderBoardItemsByScore) {
              
              
              if (game.prop === 'stroke') {
                val = leaderBoardItemByScore.toPar
                if (val === 0) val = 'E'
                pos = leaderBoardItemByScore.pos
              }
              else if (game.prop === 'skins') {
                val += leaderBoardItemByScore.number
                if (leaderBoardItemByScore.payOut > 0) holes.push(leaderBoardItemByScore.holeNum)
              }
              else if (game.prop === 'dots') {
                val += leaderBoardItemByScore.number
                if (leaderBoardItemByScore.payOut > 0) holes.push(leaderBoardItemByScore.holeNum)
              }
              else if (game.prop === 'kp') {
                val += leaderBoardItemByScore.number
                if (leaderBoardItemByScore.payOut > 0) holes.push(leaderBoardItemByScore.holeNum)
              }
              
              paid += leaderBoardItemByScore.payOut
            }
            payOutItem.pos[game.id] = pos
            payOutItem.score[game.id] = val + (holes.length>0?' (' + pluralize('Hole',holes.length) + ' ' + holes.join(',') + ')' : '')
            payOutItem.payOut[game.id] = paid
        
          }
        }
        else {
        
          let leaderBoardItemsByTeam = []
          const team = game.teamScores.getTeamScoreByPlayerDocId (player.docId)
          if (team !== null) leaderBoardItemsByTeam = leaderBoardItems.filter(item => item.teamNum === team.teamNum)
        
          if (leaderBoardItemsByTeam.length > 0) {
            let val = 0
            let paid = 0
            let pos = ''
          
        
            for (let leaderBoardItemByTeam of leaderBoardItemsByTeam) {
            
              if (game.prop === 'stroke') {
                val = leaderBoardItemByTeam.toPar
                if (val === 0) val = 'E'
                pos = leaderBoardItemByTeam.pos
              }
              else if (game.prop === 'dots') {
                val += leaderBoardItemByTeam.number
                
              }
              
              paid += roundTo(leaderBoardItemByTeam.payOut / team.numPaid, 2)
            }
            payOutItem.pos[game.id] = pos
            payOutItem.score[game.id] = val
            payOutItem.payOut[game.id] = paid
        

          
          }
          
        }
        
      }
    }
  
    payOutItem.totalPayout = 0
    for (let prop in payOutItem.payOut) {
      payOutItem.totalPayout += payOutItem.payOut[prop]
    }
    

    if (roundPayouts) payOutItem.totalPayout = roundTo(payOutItem.totalPayout,0)

    
    
   
    payOuts.push(payOutItem)
    


  }

  if (payOuts.length > 0) {
    sortArrayObj(payOuts, 'totalPayout', 'desc')
    // with rounding calc total paid
    const totalPayouts = payOuts.map(payOutItem => payOutItem.totalPayout).reduce((total, amount) => total + amount, 0)
    
   
    // if total paid doesn't equal money collected (pool)
    // take the differnce from the top money winners
  
    const totalPool = games.totalPool(players.length)

  
    if (totalPayouts !== 0 && (totalPayouts !== totalPool)) {

     

      let inc = 0
      let leftover = 0
      let diff = Math.floor(totalPool) - totalPayouts
      
      if (roundPayouts) { // if all payouts rounded then distribute $1 to each player startubng with top money winner
        inc = 1
        if (diff < 0) inc = -1
      }
      else {  // if no rounding, then divide diff by number of payouts and distribute
        inc = roundTo(diff / payOuts.length, 2)
        leftover = roundTo(diff - (inc * payOuts.length),2)
      }

      let loopCount = 0
      while (diff !== 0) {
      
        for (let payOutItem of payOuts) {
         
          if ('leftover' in payOutItem.payOut === false)  payOutItem.payOut.leftover = 0
          payOutItem.payOut.leftover += inc
          diff -= inc
          if (leftover !== 0) {
            payOutItem.payOut.leftover += leftover
            diff -= leftover
            leftover = 0
          }
          if (diff === 0) break

        }
        loopCount++
        if (loopCount >= 1000) diff = 0 // bug causing endless loop so bail
      }

      for (let payOutItem of payOuts) {
        payOutItem.totalPayout = 0
        for (let prop in payOutItem.payOut) {
          payOutItem.totalPayout += payOutItem.payOut[prop]
        }
        if (roundPayouts) payOutItem.totalPayout = roundTo(payOutItem.totalPayout, 0)
      }

      
      
  
      // if (diff > payOuts.length) diff = payOuts.length

      // let inc
      // if (totalPayouts === totalPool) {
      //   inc = 0
      // }
      // else if (round.roundPayouts) {
      //   inc = (totalPayouts - totalPool) / diff
      // }
      // else {
      //   inc = roundTo(totalPayouts - totalPool,2)
      // }
 
      // if (inc !== 0) {
      //   // if ( round.status === 'Completed' || round.status === 'Posted') {
      //     if (round.roundPayouts) {
      //       for( let i=0; i<diff; i++) {
      //         payOuts[i].totalPayout = payOuts[i].totalPayout - inc // take $1 off each top money winner
      //       }
      //     }
      //     else {
           
      //       payOuts[0].totalPayout = payOuts[0].totalPayout - inc 
          
      //     }
          
        // }
      //   else {
      //     payOuts.push({
      //       docId: '',
      //       name: 'Rounding Leftover',
      //       score: {},
      //       payOut: {},
      //       totalPayout: -inc
      //     })
      //   }
      // }
     
    }
  }
  
  return payOuts
}

export function calcSkins (activeScores, round) {

  const skins = []

  const game = round.games.getByProp('skins');

  const halfStrokes = game.skinsHalfStroke ? .5 : 1
   
  let scoreVal
  let handicapStrokes
  let par
  
  for (let holeNum=1; holeNum<=18; holeNum++) {

    const skinsArray = []

    for (let score of activeScores) {
      const skinsItem = {
        docId: score.docId,
        netScore: 1000,
        scoreVal: 0
      }

      scoreVal = score.getHoleScore(holeNum)
      if (scoreVal !== null && !score.wd) {
      
        handicapStrokes = score.getHandicapStrokesHole(holeNum,'full18') 
        par = round.getHolePar(score.tee, holeNum)
        skinsItem.netScore = scoreVal - (handicapStrokes * halfStrokes)
        skinsItem.score = scoreVal
        
      }
    
      skinsArray.push(skinsItem)
      
    }

    if (skinsArray.length > 0) {
      sortArrayObj(skinsArray, 'netScore', 'asc')

      if (skinsArray.length === 1) {
        if (skinsArray[0].netScore !== 1000) skins.push({holeNum, scoreDocId: skinsArray[0].docId, netScore: skinsArray[0].netScore, score: skinsArray[0].score})
      }
      else {
        if ( (skinsArray[0].netScore !== skinsArray[1].netScore) && skinsArray[0].netScore !== 1000) skins.push({holeNum, scoreDocId: skinsArray[0].docId, netScore: skinsArray[0].netScore, score: skinsArray[0].score})
      }

    }
  }
  
  return skins
}



