import { roundTo }  from '../lib'

export default class PayTable {
  constructor() {

    this.initialize()
 
  }
  initialize () {
    this.rows = []
    this.invalid = []
  }
  places() {
    return ['1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th']
  } 

  addRow () {
    let to = 0
    let places = 0
    if (this.rows.length > 0) {
      const lastItem = this.rows[this.rows.length-1]
      to = lastItem.to
      places = lastItem.places
    }

    const item = {
      from: to+1,
      to: to+4,
      places: places,
      percent: {'1st': 100}
    }
    this.rows.push(item)
    this.invalid.push(false)
  }
  deleteRow () {
    if (this.rows.length > 1) {
      this.rows.splice(-1, 1)
    }
  }
  resetFromTo () {
    for(let i=0; i<this.rows.length-1; i++) {
      const item = this.rows[i]
      const nextItem = this.rows[i+1]
      const oldFrom = nextItem.from
      nextItem.from = item.to + 1
      nextItem.to = nextItem.to + (nextItem.from - oldFrom)
    }
  }
  size () {
    return this.rows.length
  }
  copy () {
    const table = new PayTable()
    table.setObj(this.getObj())
    return table
  }
  checkValidRow (index) {
    let total = 0

    for(let i=0; i<this.rows[index].places; i++) {
      const place = this.places()[i]
      total = total + this.rows[index].percent[place]
    }
    total = roundTo(total,0)
    this.invalid[index] = (total !== 100)
  }
  isInvalidRow (index) {
    return this.invalid[index]
  }
  isInvalid () {
    return this.invalid.some((item) => item)
  }
  clear () {
    this.rows = []
    this.invalid = []
  }
  maxPlaces () {
    return Math.max(...this.rows.map(o => o.places), 0)
  }
  getNumPlaces (numPlayers) {
    let places = 0
    for(let i=0; i<this.rows.length; i++) {
      const row = this.rows[i]
      
      if(numPlayers >= row.from && numPlayers <= row.to) {
        places = row.places
        break
      }
    }
    if ( places === 0 && this.rows.length > 0) {
      places = this.rows[this.rows.length-1].places
    }
   
    return places
  }
  getPercent(numPlayers, place) {
    let percent = 0

    for(let i=0; i<this.rows.length; i++) {
      const row = this.rows[i]
      
      if(numPlayers >= row.from && numPlayers <= row.to) {
        if (place in row.percent) {
          let unusedPercent = 0
          if (numPlayers < row.places) {
            for(let j=numPlayers+1; j<= row.places; j++) {
              const place2 = this.places()[j-1]
              unusedPercent = unusedPercent + (row.percent[place2] / numPlayers)
            }
          }
          percent = unusedPercent + row.percent[place]
         
        }
        else {
          percent = 0
        }
        break
      }
    }
    if (percent === 0) {
      const minRow = this.rows[0]
      const maxRow = this.rows[this.rows.length-1]
      if (numPlayers < minRow.from) {
        percent = 100
      }
      if (numPlayers > maxRow.to) {
      
        if (place in maxRow.percent) {
          percent = maxRow.percent[place]
        }
      }
    }
    return percent
  }

  getData () {
    const rows = this.rows.map(row => {
      const copyRow = {
        from: row.from,
        to: row.to,
        places: row.places,
        percent: {...row.percent}
      }
      return copyRow
    })
    return rows
  }
  setData (data) {
    this.rows = data.map(row => {
      const copyRow = {
        from: row.from,
        to: row.to,
        places: row.places,
        percent: {...row.percent}
      }
      return copyRow
    })
    this.invalid = new Array(this.rows.length).fill(false)
  }
  getObj () {
    return {
      rows: this.rows.map(row => {
        const copyRow = {
          from: row.from,
          to: row.to,
          places: row.places,
          percent: {...row.percent}
        }
        return copyRow
      }),
      invalid: this.invalid.slice()
    }
  }
  setObj (data) {
    this.rows = data.rows.map(row => {
      const copyRow = {
        from: row.from,
        to: row.to,
        places: row.places,
        percent: {...row.percent}
      }
      return copyRow
    })
    this.invalid = data.invalid.slice()
  }
}





