<template>

  <div>
    <section class="hero is-info is-medium is-bold">
      <div class="hero-body">
        <div class="container is-fluid">

          <h1 class="title is-1">
            Welcome to <span class="title is-1" style="font-family:'Contrail One'">girlap</span>
          </h1>
          
          <h2 class="subtitle has-margin-top-10">
            Have a weekly golf group and bet on games? <span style="font-family:'Contrail One'">girlap</span> will schedule your rounds, set up the games, manage bets, track handicaps and player payouts
          </h2>

          <div class="level has-margin-top-30">
            <div class="level-left">
              <div class="level-item">
                <p class="control is-size-5">Registered with a <span style="font-family:'Contrail One'">girlap</span> group?</p>
              </div>
              <div class="level-item">
                <a class="button is-info is-inverted is-outlined is-size-5" @click="onSignIn">
                  Sign In
                </a>
              </div>
            </div>
          </div>
          <div class="level">
          <div class="level-left">
            <div class="level-item">
              <p class="control is-size-5">Want to create a <span style="font-family:'Contrail One'">girlap</span> group?</p>
            </div>
            <div class="level-item">
              <a class="button is-info is-inverted is-outlined is-size-5" @click="onGetStarted">
                Get Started
              </a>
            </div>
          </div>
        </div>
        </div>

        
      </div>

      

    </section>
    <div class="box cta">
      <h4 class="has-text-centered title is-4">
          <span style="font-family:'Contrail One'">girlap</span> provides everything you need to manage your golf group
      </h4>
    </div>

    <section class="container is-fluid">
      <div class="columns features">
        <div class="column is-4">
            <div class="card is-shady">
                <div class="card-image has-text-centered">
                    <i class="fa fa-paw"></i>
                </div>
                <div class="card-content">
                    <div class="content">
                        <h4>Manage You Group </h4>
                        <p>Purus semper eget duis at tellus at urna condimentum mattis. Non blandit massa enim nec. Integer enim neque volutpat ac tincidunt vitae semper quis. Accumsan tortor posuere ac ut consequat semper viverra nam.</p>
                        <p><a href="#">Learn more</a></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="column is-4">
            <div class="card is-shady">
                <div class="card-image has-text-centered">
                    <i class="fa fa-empire"></i>
                </div>
                <div class="card-content">
                    <div class="content">
                        <h4>Calculate Handicaps</h4>
                        <p>Ut venenatis tellus in metus vulputate. Amet consectetur adipiscing elit pellentesque. Sed arcu non odio euismod lacinia at quis risus. Faucibus turpis in eu mi bibendum neque egestas cmonsu songue. Phasellus vestibulum lorem
                        sed risus.</p>
                        <p><a href="#">Learn more</a></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="column is-4">
            <div class="card is-shady">
                <div class="card-image has-text-centered">
                    <i class="fa fa-apple"></i>
                </div>
                <div class="card-content">
                    <div class="content">
                        <h4>Bet on Games and Calculate Payouts </h4>
                        <p>Imperdiet dui accumsan sit amet nulla facilisi morbi. Fusce ut placerat orci nulla pellentesque dignissim enim. Libero id faucibus nisl tincidunt eget nullam. Commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <p><a href="#">Learn more</a></p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </section>
    <footer class="footer">
      <div class="content has-text-centered">
        {{ version }}
      </div>
    </footer>
  
  </div> 
</template>

<script>

export default {
  name: 'home-page',
  computed: {
    version () {
      return 'v'+process.env.VUE_APP_VERSION
    }
  },
 
  methods: {
    onGetStarted () {
      this.$router.push('/group/create')
    },
    onSignIn () {
      this.$router.push('/signin')
    },
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body

      if (addRemoveClass === 'addClass') {
        el.classList.add(className)
      } else {
        el.classList.remove(className)
      }
    }
  }
}
</script>
