<template>
  <div>
    <b-tabs v-model="activeTab">
      <b-tab-item label="Tournament">
        <tournament-leaderboard />
      </b-tab-item>
      <b-tab-item v-for="(round,index) in tournament.rounds.aRounds" :key="index" :label="'Round ' + round.roundNum">
        <round-leaderboard  :round="round" :leaderBoards="getRoundLeaderBoards(round.docId)" :selectedGameId.sync="roundSelectedGameIds[index]"/>
      </b-tab-item>
    </b-tabs>
  </div>

</template>

<script>

import TournamentLeaderboard from './TournamentLeaderboard'
import RoundLeaderboard from '../Round/RoundLeaderboard'

import Tournament from '../../models/tournament'
import { isEmpty } from '../../lib'

export default {
  data() {
    return {
      activeTab: 0,
      roundSelectedGameIds: []
    }
  },

  mounted () {
    this.roundSelectedGameIds = []
    let gameId
    for (let round of this.tournament.rounds.aRounds) {
      gameId = ''
      if (round.games.size>0) gameId = round.games.aGames[0].id
   
      this.roundSelectedGameIds.push(gameId)
    }
  
  },

  components: {
    'tournament-leaderboard': TournamentLeaderboard,
    'round-leaderboard': RoundLeaderboard
  },


  computed: {
    tournament () {
      let tournament = this.$store.getters['tournament/tournament']
      if (tournament === null) tournament = new Tournament()
      return tournament
    },
    roundLeaderBoards () {
      const leaderBoards = this.$store.getters['tournament/roundLeaderBoards']
  
      return leaderBoards
    },
    
    
  },
  methods: {
    
    getRoundLeaderBoards (docId) {
      let leaderBoards = {}
      if (!isEmpty(this.roundLeaderBoards)) {
        leaderBoards = this.roundLeaderBoards[docId]
        if (leaderBoards === undefined) leaderBoards = {}
      }
   
      return leaderBoards
    }
    
  
    
  }
}
</script>

<style>

</style>
