

import dayjs from 'dayjs'

import { sortArrayObj, formatToPar, formatCurrency, nth, newDate } from '../lib'


export default class UserPostedScore {
  constructor(data) {
    if(data) {
      this.setObj(data)
    }
    else {
      this.initialize()
    }
  }
  
  initialize () {
    this.docId = ''
    this.roundDocId = null
    this.tournamentDocId = null
    this.roundDate = newDate()
    this.roundStatus = ''
    this.roundName = ''
    this.courseLabel = ''
    this.tee = ''
    this.index = {}
    this.handicap = {}
    this.wd = false
    this.exclude = false
    this.score = {}
    this.netScore = {}
    this.toPar = {}
    // this.parCompleted = {}
    this.kpCount = 0
    this.kp = []
    this.skinsCount = 0
    this.skins = []
    this.payOut = {}
    this.pos = {}
    this.totalBet = 0
    this.totalPayout = 0
    this.games = []
    this.gameResults = []
  }

  get formattedDate () {
    // return moment(this.roundDate).format('ddd L')
    return dayjs(this.roundDate).format('ddd MM/DD/YYYY')
  }

  get formattedLongDate () {
    return dayjs(this.roundDate).format('ddd MMM D, YYYY')
  }

  get netScoreFull18 () {
    return this.netScore.full18
  }

  get scoreFull18 () {
    return this.score.full18
  }

  calcGameResults () {
   
    this.gameResults = []
   
    let pos
    let order = 1
    for (let gameObj of this.games) {
     
      if (gameObj.id in this.payOut) {
        const gameResult = {
          name: gameObj.name,
          payOut: formatCurrency(this.payOut[gameObj.id]),
          order: order
        }
     
        if (gameObj.prop === 'stroke' && gameObj.side === 'full18') {
          pos = nth(this.pos[gameObj.id])
          if (pos.length > 0) {
            gameResult.score = pos + ' (' + formatToPar(this.toPar.full18) + ')'

          }
          else {
            gameResult.score = formatToPar(this.toPar.full18)
          }
        }
        else if ((gameObj.prop === 'stroke' && gameObj.side === 'front')) {
          pos = nth(this.pos[gameObj.id])
          if (pos.length > 0) {
            gameResult.score = nth(this.pos[gameObj.id]) + ' (' + formatToPar(this.toPar.front) + ')'
          }
          else {
            gameResult.score = formatToPar(this.toPar.front)
          }
        }
        else if (gameObj.prop === 'stroke' && gameObj.side === 'back') {
          pos = nth(this.pos[gameObj.id])
          if (pos.length > 0) {
            gameResult.score = nth(this.pos[gameObj.id]) + ' (' + formatToPar(this.toPar.back) + ')'
          }
          else {
            gameResult.score = formatToPar(this.toPar.back)
          }
          
        }
        else if (gameObj.prop === 'kp') {
        
          if (this.kpCount > 0) {
            gameResult.score = this.kpCount + (this.kp.length>0?' (H ' + this.kp.join(',') + ')':'')
          }
          else {
            gameResult.score = this.kp.length
          }

        }
        else if (gameObj.prop === 'skins') {
          if (this.skinsCount > 0) {
            gameResult.score = this.skinsCount + (this.skins.length>0?(' (H ' + this.skins.map(s=>`${s.holeNum} (${s.score})`).join(',') + ')'):'')
          }
          else {
            gameResult.score = this.skinsCount
          }
          
        }
        else if (gameObj.prop === 'dots') {
          if (this.dotsCount > 0) {
            gameResult.score = this.dotsCount + (this.dots.length>0?(' (H ' + this.dots.map(s=>`${s.holeNum} (${s.score})`).join(',') + ')'):'')
          }
          else {
            gameResult.score = this.dotsCount
          }
          
        }
        this.gameResults.push(gameResult)
      }
      
      
    }
    sortArrayObj(this.gameResults, 'order')
  }
  
  copy () {
    const score = new UserPostedScore()
    score.setObj(this.getObj())
    return score
  }
  toJSON () {
    return JSON.stringify(this.getObj())
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  getObj () {
    return {
      docId: this.docId,
      roundDocId: this.roundDocId,
      tournamentDocId: this.tournamentDocId,
      roundDate: new Date(Number(this.roundDate)),
      roundStatus: this.roundStatus,
      roundName: this.roundName,
      courseLabel: this.courseLabel,
      tee: this.tee,
      index: {...this.index},
      handicap: {...this.handicap},
      wd: this.wd,
      exclude: this.exclude,
      score: {...this.score},
      netScore: {...this.netScore},
      toPar: {...this.toPar},
      // parCompleted: {...this.parCompleted},
      totalPayout: this.totalPayout,
      payOut: {...this.payOut},
      pos: {...this.pos},
      gameResults: this.gameResults.map(gameResult => ({...gameResult})),
      games: {...this.games}
    }
  }
  setObj (data) {
    this.docId = data.docId,
    this.roundDocId = data.roundDocId,
    this.tournamentDocId = data.tournamentDocId,
    this.roundDate = new Date(Number(data.roundDate))
    if(typeof data.roundDate === "string") this.roundDate = new Date(data.roundDate)
    this.roundStatus = data.roundStatus
    this.roundName = data.roundName
    this.courseLabel = data.courseLabel
    this.tee = data.tee
    this.index = {...data.index}
    this.handicap = {...data.handicap}
    this.wd = data.wd
    this.exclude = data.exclude
    this.score = {...data.score}
    this.netScore = {...data.netScore}
    this.toPar = {...data.toPar}
    // this.parCompleted = {...data.parCompleted}
    this.totalBet = data.totalBet
    this.totalPayout = data.totalPayout
    this.payOut = {...data.payOut}
    this.pos = {...data.pos}
    this.gameResults = data.gameResults.map(gameResult => ({...gameResult}))
    this.games = {...data.games}
  }
  setFromScore (score) {
    this.pos = score.pos
    this.payOut = score.payOut
    this.roundDocId = score.roundDocId,
    this.tournamentDocId = score.tournamentDocId,
    this.roundDate = score.roundDate
    this.roundStatus = score.roundStatus
    this.roundName = score.roundName
    this.courseLabel = score.courseLabel
    this.tee = score.tee
    this.index = score.index
    this.handicap = score.handicap
    this.wd = score.wd
    this.exclude = score.exclude
    this.score = score.score
    this.netScore = score.netScore
    this.toPar = score.toParObj
    // this.parCompleted = data.parCompleted
    this.totalBet = score.totalBet
    this.totalPayout = score.totalPayout
    this.kp = score.kp
    this.kpCount = score.kpCount
    this.skins = score.skins
    this.skinsCount = score.skinsCount
    this.games = score.games

    this.calcGameResults()
  }
  
}


