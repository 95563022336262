<template>
  <div>
    <p class="title is-size-5 has-text-centered has-margin-top-15 has-margin-bottom-10">Group Totals </p>
    <table class="table is-narrow is-size-6" width="100%" >
      <tbody>
        <template v-if="isScramble || is123">
         
          <tr @click="openHoleScores(teamScore)" style="border-top:2px solid">
            <td rowspan="3">
              <ul>
                <li v-for="(score, index) in currentTeeTimeScoresArray" :key="index">
                  {{score.name}}
                </li>
              </ul>
              <p>{{'Hdcp ' + teamScore.handicap('full18')}}</p>
            </td>
            <td class="vert-middle" width="30">Front:</td>
            <td class="vert-middle" width="30">{{teamScore.score.front}}</td>
            <td class="vert-middle" :class="parClass2(scoreToPar(teamScore,'front'))" >{{scoreToPar(teamScore,'front') }}</td>
   
            <td rowspan="3" width="20" class="vert-middle">
              <a >
                <b-icon icon="chevron-right"/>
              </a>
            </td>
          </tr>

          <tr  style="border-top:2px solid">
            <td class="vert-middle" width="30">Back:</td>
            <td class="vert-middle" width="30">{{teamScore.score.back}}</td>
            <td class="vert-middle" :class="parClass2(scoreToPar(teamScore,'back'))" >{{scoreToPar(teamScore,'back') }}</td>
          </tr>

          <tr  style="border-top:2px solid">
            <td class="vert-middle" width="30">Total:</td>
            <td class="vert-middle" width="30">{{teamScore.score.full18}}</td>
            <td class="vert-middle" :class="parClass2(scoreToPar(teamScore,'full18'))" >{{scoreToPar(teamScore,'full18') }}</td>
          </tr>
          
       
        </template>
        <template v-else>
          <template v-for="(score,index) in currentTeeTimeScoresArray" >
            <tr  :key="index" @click="openHoleScores(score)" style="border-top:2px solid">
              <td rowspan="3">
                <p>{{score.name}}</p>
                <p class="is-size-7">Hdcp {{formatHandicapObj(score.handicap,'full18')}} ({{score.tee}})</p>
              </td>
              <td class="vert-middle" width="30">Front:</td>
              <td class="vert-middle" width="30">{{score.score.front}}</td>
              <td class="vert-middle" :class="parClass2(scoreToPar(score,'front'))" >{{scoreToPar(score,'front') }}</td>

              <td rowspan="3" width="20" class="vert-middle">
                <a >
                  <b-icon icon="chevron-right"/>
                </a>
              </td>
            </tr>
            <tr :key="index+10">
              <td class="vert-middle" >Back:</td>
              <td class="vert-middle" >{{score.score.back}}</td>
              <td class="vert-middle" :class="parClass2(scoreToPar(score,'back'))" >{{scoreToPar(score,'back') }}</td>


            </tr>
            <tr :key="index+20">
              <td class="vert-middle" style="border-bottom: 1px solid #dee2e5;">Total:</td>
              <td class="vert-middle" style="border-bottom: 1px solid #dee2e5;">{{score.score.full18}}</td>
              <td class="vert-middle" style="border-bottom: 1px solid #dee2e5;" :class="parClass2(scoreToPar(score,'full18'))" >{{scoreToPar(score,'full18') }}</td>

            </tr>
          </template>
        </template>
      
      </tbody>
    </table>
    <template v-if="isScorecardSigned">
      <p class="has-text-centered">Scorecard has been signed</p>
      <p class="has-text-centered has-padding-bottom-10">To change scores contact your Admin</p>
    </template>
    
    <b-button 
      v-else 
      class="is-full-width has-margin-bottom-10"
      type="is-info"
      @click="signScorecard()">
      Sign Scorecard
    </b-button>

    <b-modal 
      :active.sync="showHoleScores"
      has-modal-card 
      full-screen
      :can-cancel="false"
      style="z-index:100;"
     >
      <div class="modal-card" style="width:auto">
        <div class="modal-body">
          <div class="level is-mobile has-margin-bottom-5" @click="showHoleScores=false">
            <div class="level-left">
              <div class="level-item">
                <a class="mt-1" >
                  <b-icon icon='chevron-left'></b-icon>
                </a>
              </div>
              <div class="level-item">
                <p class="subtitle">{{activeScore.name }}</p>
              </div>
            </div>
          </div>
          
          <hole-scores 
            :score="activeScore" 
            :editScore="false" 
            :round="round" 
          />
        </div>
      </div>
     
  
    </b-modal>
  </div>
</template>

<script>

import { formatToPar, parClass, parClass2, formatHandicapObj } from "../../lib"
import Score from '../../models/score'
import DBUpdates from '../../helpers/dbUpdates'
import HoleScores from './HoleScores'

export default {
  data () {
    return {
      showHoleScores: false,
      activeScore: new Score()
    }
  },

  props: ['round','currentTeeTimeScoresArray','scoreCount'],

  components: {
    'hole-scores': HoleScores
  },

  
  computed: {
   
    isScorecardSigned () {
      let signed = true
      for (let score of this.currentTeeTimeScoresArray) {
        if (!score.final) {
          signed = false
          break
        }
      }
      return signed

    },

    isScramble () {
      return this.round.games.hasScrambleGame()
    },

    is123 () {
      return this.round.games.has123Game()
    },

   
    teamScore () {  // only works if scramble game
      let teamScore = null
      if (this.currentTeeTimeScoresArray.length > 0) {
        const firstScore = this.currentTeeTimeScoresArray[0]
        const game = this.round.games.aGames.find(g=>g.prop==='stroke' && (g.format==='scramble' || g.format==='123'))
        
        if (game !== undefined) teamScore = game.teamScores.getTeamScoreByScoreDocId (firstScore.docId)
      }
      if (teamScore === null) {

        teamScore = new TeamScore()
      }
      
      return teamScore
    }
   
  },
  
  //=------
    
    
  methods: {
    parClass2,
    parClass,
    formatHandicapObj,
  
   
    openHoleScores (score) {
      this.showHoleScores = true
      this.activeScore = score

    },

    toPar (score, side='full18') {
      return formatToPar(score.toPar(side))
    },

    scoreToPar (score, side='full18') {
      let toPar = 0
    
      if (this.round.games.hasStablefordGame()) {
        const game = this.round.games.aGames.find(g=>(g.prop==='stroke' && g.side===side && g.isStableford))
        if (game !== undefined) {
          toPar = score.toPoints(side, game.useQuota)
        }
        else {
          toPar = ''
        }
        
      }
      else {
        toPar = this.toPar(score, side)
      }
      return toPar
    },

    async signScorecard () {
      const scoreUpdates = new DBUpdates()
      for(let score of this.currentTeeTimeScoresArray) {
         scoreUpdates.add(score.docId, {final: true})
      }
      await this.$store.dispatch('round/saveScoreValues', scoreUpdates)
        
    }

   
  }
}

</script>

<style lang="scss">

  .text-center {
    text-align: center !important;
  }
  .vert-middle {
    vertical-align: middle;
  }

  .vert-bottom {
    vertical-align: bottom !important; 
  }

  .header-cell {
    background-color: black;
    color: white;
    font-weight: bold;
  }

  .checkbox-cell-mobile {
      width: 20px;
      .checkbox {
          vertical-align: middle;
          .check {
              transition: none;
          }
      }
  }
 



</style>