// import * as firebase from 'firebase'
import firebase from 'firebase/compat/app'

import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/functions'

const config = {
  apiKey: 'AIzaSyD-kAzYrsC5nmNB6jZkD0X57UiEhijOsx4',
  authDomain: 'girlap-b3a7a.firebaseapp.com',
  databaseURL: 'https://girlap-b3a7a.firebaseio.com',
  projectId: 'girlap-b3a7a',
  storageBucket: 'girlap-b3a7a.appspot.com',
  messagingSenderId: '564308779643'
}

firebase.initializeApp(config)

const auth = firebase.auth()
const emailAuthProvider = firebase.auth.EmailAuthProvider
const db = firebase.firestore()
const fs = firebase.firestore
const fbFunc = firebase.functions()

db.enablePersistence({synchronizeTabs:true})
  .catch(function(err) {
      if (err.code == 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
      } else if (err.code == 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
      }
    
  })



export { fs, db, auth, fbFunc, emailAuthProvider }

