import TeamScore from './teamScore'

export default class TeamScores {
  constructor() {
    this.initialize()
  }
  initialize () {
    this.aTeams = []
    this._teamSize = 0
  }
  get teamSize () {
    return this._teamSize
  }
  set teamSize (size) {
    this._teamSize = size
    for (let team of this.aTeams) {
      team.size = size
    }
  }
  add () {
    const teamScore = new TeamScore()
    
    this.aTeams.push(teamScore)
    teamScore.teamNum = this.aTeams.length
    teamScore.size = this.teamSize

    return teamScore
  }

  assign (scoreDocIds) {

    for (let docId of scoreDocIds) {
      let openTeam = this.findOpenTeam ()
      if (openTeam === null) {
        openTeam = this.add ()
      }
      openTeam.assignEmptySlot (docId)
    }
  
  }

  unassign (scoreDocIds) {
    for (let docId of scoreDocIds) {
      const teamScore = this.findTeam(docId)
   
      if (teamScore) {
        teamScore.unassignScoreDocId (docId)
      }
    }
  }

  findTeam (scoreDocId) {
    for (let teamScore of this.aTeams) {
      for (let docId of teamScore.scoreDocIds) {
        if (scoreDocId === docId) {
          return teamScore
        }
      }
    }
    return null
  }

  findOpenTeam () {
    for (let team of this.aTeams) {
      if (!team.isFull ()) {
        return team
      }
    }
   
    return this.add()
  }

  

  scoreDocIds () {
    let docIds = []
    for(let teamScore of this.aTeams) {
      docIds.push(...teamScore.scoreDocIdsArray())
    }
    return [...new Set(docIds)]  // return unique ids which can happen if blind draw exists

  }

  countScores () {
    let count = 0
    for(let teamScore of this.aTeams) {
      count += teamScore.countScores()
    }
    return count
  }
  scoresArray () {
    let scores = []
    for(let teamScore of this.aTeams) {
      scores.push(...teamScore.scoresArray())
    }
    return scores
  }

  assignScores (scoresArray) {
    for(let teamScore of this.aTeams) {
      teamScore.assignScores(scoresArray)
    }
  }

  getTeamScoreByIndex (index) {
    return this.aTeams[index]
  }

  getTeamScoreByScoreDocId (scoreDocId) {
    for (let teamScore of this.aTeams) {
      for (let docId of teamScore.scoreDocIds) {
        if (scoreDocId === docId) {
          return teamScore
        }
      }
    }

    return null
  }

  getTeamScoreByPlayerDocId (playerDocId) {
    for (let teamScore of this.aTeams) {
   
      for (let docId of teamScore.playerDocIds) {
        if (playerDocId === docId) {
          return teamScore
        }
      }
    }

    return null
  }

  getTeamScoreByTeamNum (teamNum) {
    const team = this.aTeams.find(t=>t.teamNum===teamNum)
    if (team === undefined) team = null
    return team
  }

  isFull (teamScoreIndex) {
    const teamScore = this.aTeams[teamScoreIndex]
    return teamScore.isFull()
    
  }

  get size () {
    return this.aTeams.length
  }

  deleteEmptyTeams () {
    for (let team of this.aTeams) {
      if (team.isEmpty()) this.delete(team)
    }
  }

  hasUnassignedBlindDraws () {
  
    return this.aTeams.some(t=>t.hasUnassignedBlindDraw()===true)
  }
  

  delete (teamScore) {
    const index = this.aTeams.findIndex(t => t.teamNum === teamScore.teamNum)

    this.deleteByIndex(index)

  }

  deleteByIndex (teamIndex) {
 
    this.aTeams.splice(teamIndex, 1)
    
  }
  
  clear () {
    this.aTeams = []
  }

 
  copy () {
    const teemScores = new TeamScores()

    teamScores.setObj(this.getObj())
    return teamScores
  }


  toJSON () {
    return JSON.stringify(this.getObj(),null,2)
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  getObj () {
    return {
      teamSize: this._teamSize,
      teamScores: this.aTeams.map(teamScore => teamScore.getObj())
    }
      
  }
  setObj (data) {
    this._teamSize = data.teamSize
    this.aTeams = data.teamScores.map(teamScoreObj => {
      const teamScore = new TeamScore()
      teamScore.setObj(teamScoreObj)
      return teamScore
    })
   
  }
  getData () {
    return {
      teamSize: this._teamSize,
      teams: this.aTeams.map(teamScore => teamScore.getData())
    }
    
  }
  setData (data) {
    if (data === undefined) {
      this.initialize()
    }
    else {
   
      if (data.teams !== undefined) {
        this._teamSize = data.teamSize
        for (let i=0; i<data.teams.length; i++) {
          const teamScore = new TeamScore()
          teamScore.setData(data.teams[i])
          teamScore.teamNum = i+1
          this.aTeams.push(teamScore)
        }
      }
      else {
        this.initialize()
      }
     
    }
   
  }
}

