<template>
  <div>
    <table class="table has-margin-bottom-5" width="100%">
      <thead>
        <th>Game</th>
        <th>Score</th>
        <th style="text-align:right;">Winnings</th>
      </thead>
      <tbody>
        <tr v-for="(game,index) in games" :key="index">
          <td class="has-text-weight-bold">{{game.name}}</td>
          <td v-if="game.prop==='stroke'" class="has-text-centered">{{result.avgScore[game.id]}}</td>
          <td v-else class="has-text-centered">{{result.count[game.id]}}</td>
          <td style="text-align:right;">{{formatCurrency(result.payOut[game.id])}}</td>
        </tr>
      </tbody>
    </table>
    <table class="table" width="100%">
      <thead>
        <th>Totals</th>
        <th></th>
      </thead>
      <tbody>
        <tr>
          <td class="has-text-weight-bold">Rounds:</td>
          <td style="text-align:right;">{{result.numRounds}}</td>
        </tr>
        <tr>
          <td class="has-text-weight-bold">Bet:</td>
          <td style="text-align:right;">{{formatCurrency(result.totalWagered)}}</td>
        </tr>
        <tr>
          <td class="has-text-weight-bold">Winnings:</td>
          <td style="text-align:right;">{{formatCurrency(result.netWinnings)}}</td>
        </tr>
        <tr>
          <td class="has-text-weight-bold">Winnings per Round:</td>
          <td style="text-align:right;">{{formatCurrency(result.netPerRound)}}</td>
        </tr>
      </tbody>
    </table>
  
  </div>
</template>

<script>

import { formatCurrency } from '../../lib'

export default {
  data() {
    return {
    }
  },
  props: ['result','games'],

  computed: {
   
  },
  
  methods: {
    formatCurrency
   
  }
}
</script>

<style>
 



</style>
