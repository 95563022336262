import User from './user'

export default class Users {
  constructor() {
    this.initialize()
  }
  initialize () {
    this.aUsers = []
  }
  addDoc (docId, data) {
    const user = new User()
    user.docId = docId
    user.setData(data)

    this.aUsers.push(user)

    return user
  }

  
  clear () {
    this.aUsers = []
  }
  get size () {
    return this.aUsers.length
  }
  
  toJSON () {
    return JSON.stringify(this.getObj(),null,2)
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  getObj () {
    return this.aUsers.map(user => user.getObj())
  }
  setObj (data) {
    this.aUsers = data.map(userObj => {
      const user = new User()
      user.setObj(userObj)
      return user
    })
  }
  getData () {
    return this.aUsers.map(user => user.getData())
  }
  setData (data) {
    this.aUsers = data.map(userData => {
      const user = new User()
      user.setData(userData)
      return user
    })
  }
}

