

export default class DBUpdate {
  constructor(docId='', update={}) {
    this.docId = docId
    this.update = update
  }
  
  add (update) {
    this.update = {...this.update, ...update}
  }

  clear () {
    this.update = {}
  }

  contains (field) {
    return field in this.update
  }

  getUpdate (field) {
    return this.update[field]
  }

  copy () {
    return new DBUpdate(this.docId, {...this.update})
  }

  hasUpdate () {
    return Object.entries(this.update).length > 0
  }

  toJSON () {
    return JSON.stringify(this.getObj())
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  getObj () {
    return {
      docId: this.docId,
      update: this.update
    }
  }
  setObj (data) {
    this.docId = data.docId
    this.update = data.update
  }
  
}


