<template>
  <div>
    <template v-if="isMobile">
      <p 
        v-if="readOnly"
        class="control" 
        style="margin-top:8px;margin-bottom:10px;">
        {{ (scoresArray.length===0?'No':scoresArray.length) + pluralize(' player',scoresArray.length) }} signed up
      </p>
      <b-collapse 
        v-else 
        class="panel has-margin-bottom-10" 
        :open.sync="isPlayersOpen"
      >
        <div
          slot="trigger"
          class="panel-heading has-padding-5"
          role="button"
        >
          <b-field 
            grouped 
            group-multiline
          >
            <a 
              class="control"
              style="margin-bottom:0px;"
            >
              <b-icon
                style="margin-top: 4px;margin-bottom:0px;"
                :icon="isPlayersOpen ? 'menu-down' : 'menu-up'"
              />
            </a>
            <p 
              class="control" 
              style="margin-top:8px;margin-bottom:10px;"
            >
              {{ (scoresArray.length===0?'No':scoresArray.length) + pluralize(' player',scoresArray.length) }} signed up
            </p>
            <b-dropdown
              v-if="!readOnly && round.isScheduled && !round.isTournament" 
              style="margin-bottom:10px;" 
              :triggers="['hover']"
            >
              <b-button
                slot="trigger"
                icon-left="account" 
                icon-right="menu-down"
              >
                Add
              </b-button>
              <b-dropdown-item 
                value="addGroup" 
                @click="addFromGroupPlayers"
              >
                Players from Roster
              </b-dropdown-item>
              <b-dropdown-item 
                value="addGuestPlayer" 
                @click="addGuestPlayer"
              >
                Guest Player
              </b-dropdown-item>
            </b-dropdown>
          </b-field>
        </div>
        <div class="panel-block">
          <b-field 
            grouped 
            group-multiline
          >
            <b-dropdown
              v-if="!readOnly"
              style="margin-bottom:10px;" 
              :triggers="['hover']"  
            >
              <b-button 
                slot="trigger" 
                icon-right="menu-down" 
              >
                {{round.isScheduled && !round.isTournament ? 'Import/Export' : 'Export'}}
              </b-button>
              <b-dropdown-item 
                v-if="round.isScheduled && !round.isTournament"
                value="addSpreadsheet" 
                @click="importPlayers"
              >
                Import players from csv file
              </b-dropdown-item>
              <b-dropdown-item 
                value="exportPlayers" 
                @click="exportPlayers"
              >
                Export players to csv file
              </b-dropdown-item>
              <b-dropdown-item value="exportNames" @click="exportNames">Export player names to Clipboard</b-dropdown-item>
              <b-dropdown-item value="exportEmails" @click="exportEmails">Export player emails to Clipboard</b-dropdown-item>

            </b-dropdown>
            <b-button class="has-margin-bottom-0" @click="updateHandicaps" v-if="!readOnly && round.isScheduled && !round.isTournament" style="margin-left:6px;">Update Handicaps</b-button>
           
            <b-button @click="showSendTeeTimesEmail=true" style="margin-left:6px;margin-bottom:10px;" v-if="!readOnly && round.isNotPosted">Send Tee Time Email...</b-button>
           
            <b-button @click="showSendInvitesEmail=true" style="margin-left:6px;margin-bottom:10px;" v-if="!readOnly && round.isNotPosted">Send Invites...</b-button>
            <b-switch 
              v-if="!readOnly && round.isScheduled"
              style="margin-left:6px;margin-bottom:10px;"
              :value="round.closeSignups" 
              @input="value=>updateCloseSignups(value)">
              Close Signups
            </b-switch>
          </b-field >
          <b-progress v-if="showHandicapProgress" type="is-success" :value="handicapProgressCount" />

     
        </div>
      </b-collapse>
    </template>
    <template v-else>
      <b-field grouped group-multiline >
        <p class="control" style="margin-top:8px;margin-bottom:10px;">
          {{(scoresArray.length===0?'No':scoresArray.length) + pluralize(' player',scoresArray.length) }} signed up
        </p>
        <b-dropdown style="margin-bottom:10px;" :triggers="['hover']" v-if="!readOnly && round.isScheduled && !round.isTournament" >
          <b-button icon-left="account" icon-right="menu-down" slot="trigger">
            Add
          </b-button>
          <b-dropdown-item value="addGroup" @click="addFromGroupPlayers">Players from Roster</b-dropdown-item>
          <b-dropdown-item value="addGuestPlayer" @click="addGuestPlayer">Guest Player</b-dropdown-item>
        
        </b-dropdown>
        <b-dropdown style="margin-bottom:10px;" :triggers="['hover']" v-if="!readOnly" >
          <b-button icon-right="menu-down" slot="trigger">
            {{round.isScheduled && !round.isTournament ? 'Import/Export' : 'Export'}}
          </b-button>
          <b-dropdown-item v-if="round.isScheduled && !round.isTournament" value="addSpreadsheet" @click="importPlayers">Import players from csv file</b-dropdown-item>
          <b-dropdown-item value="exportPlayers" @click="exportPlayers">Export players to csv file</b-dropdown-item>
          <b-dropdown-item value="exportNames" @click="exportNames">Export player names to Clipboard</b-dropdown-item>
          <b-dropdown-item value="exportEmails" @click="exportEmails">Export player emails to Clipboard</b-dropdown-item>

        </b-dropdown>
        <b-button class="has-margin-bottom-0" @click="updateHandicaps" v-if="!readOnly && round.isScheduled && !round.isTournament" style="margin-left:6px;">Update Handicaps</b-button>
        <b-button @click="showSendTeeTimesEmail=true" style="margin-left:6px;margin-bottom:10px;" v-if="!readOnly && round.isNotPosted">Send Tee Time Email...</b-button>
        <b-button @click="showSendInvitesEmail=true" style="margin-left:6px;margin-bottom:10px;" v-if="!readOnly && round.isNotPosted">Send Invites...</b-button>
        <b-switch 
              v-if="!readOnly && round.isScheduled"
              style="margin-left:6px;margin-bottom:10px;"
              :value="round.closeSignups" 
              @input="value=>updateCloseSignups(value)">
              Close Signups
            </b-switch>
      </b-field>
      <b-progress v-if="showHandicapProgress" type="is-success" :value="handicapProgressCount" />
      
    </template>
    <b-collapse v-if="!readOnly" class="panel has-margin-bottom-5" :open.sync="isAssignOpen">
      <div
        slot="trigger"
        class="panel-heading has-padding-5"
        role="button"
      >

        <b-field grouped group-multiline >
          <a  class="control" style="margin-bottom:0px;">
            <b-icon
              style="margin-top: 4px;margin-bottom:0px;"
              :icon="isAssignOpen ? 'menu-down' : 'menu-up'">
            </b-icon>
          </a>
          <p class="control has-margin-bottom-0 has-margin-top-10 " >
            {{ unassignedPlayers.length===0?'All players are assigned':(unassignedPlayers.length + pluralize(' players',unassignedPlayers.length) + ' left to assign') }}
          </p>
          <b-dropdown 
            v-if="unassignedPlayers.length>0"
            style="margin-bottom:10px;"
            :triggers="['hover']" 
            :disabled="readOnly"  >
            <b-button icon-right="menu-down" slot="trigger" >
              Auto Assign
            </b-button>
            <b-dropdown-item @click="autoPairPlayer">Player Entry Order</b-dropdown-item>
            <b-dropdown-item @click="autoPairRandom">Randomly</b-dropdown-item>
            <b-dropdown-item @click="autoPairAbcd">ABCD</b-dropdown-item>
            <b-dropdown-item @click="autoPairTee">Same Tee</b-dropdown-item>
            <b-dropdown-item @click="autoPairHandicap">Same Handicap</b-dropdown-item>
          </b-dropdown>
          <p class="control"  style="margin-bottom:10px;margin-left:10px;">
            <b-button :disabled="round.teeTimes.numAssigned()===0" @click.stop="unassignAll">Unassign All</b-button>
          </p>
          <div class="control buttons">
            <b-button @click.stop="setUnassignedList('tags')" :type="unassignedRadio==='tags'?'is-info':''">
              <b-icon icon="view-module"></b-icon>
            </b-button>
            <b-button @click.stop="setUnassignedList('list')" :type="unassignedRadio==='list'?'is-info':''">
              <b-icon icon="view-sequential"></b-icon>
            </b-button>
          </div>
      

        </b-field>
       
      </div>
      
      <div v-if="unassignedRadio==='tags'" class="panel-block">
        <div v-if="!readOnly" class="tags has-margin-bottom-5">

          <drag
            v-for="(score,rowIndex) in unassignedPlayers" :key="rowIndex"
            :tag="'a'"
            class="drag tag is-medium"
            :class="isHandicapCurrent(score)"
            :transfer-data="score"
            :effect-allowed="['copy']"
            @click.native="editPlayer(score)"
            drop-effect="copy" >
              {{ score.slotLabel }}
             <button class="delete is-small" :class="isHandicapCurrent(score)" @click.stop="deletePlayer(score)"></button>
            
          </drag> 

 
        </div>
      </div>
      <template v-else>
        <b-table v-if="unassignedPlayers.length>0"
          :data="unassignedPlayers"
          default-sort="name"
        >
          <b-table-column label="Name" field="name" sortable v-slot="props" width="220">
           <!-- <a @click="editPlayer(props.row)">{{props.row.name}}</a>-->
            <drag
              :tag="'a'"
              class="drag tag is-medium"
              :class="isHandicapCurrent(props.row)"
              :transfer-data="props.row"
              :effect-allowed="['copy']"
              @click.native="editPlayer(props.row)"
              drop-effect="copy" >
                {{ props.row.name }}            
            </drag> 
          </b-table-column>

          <b-table-column label="Index" field="index.full18" sortable v-slot="props" centered width="120">
            {{`${formatHandicapObj(props.row.index,'full18')} (${props.row.indexSource})`}}
          </b-table-column>

          <b-table-column label="Tee" field="tee" sortable v-slot="props" centered width="80">
            {{props.row.tee}}
          </b-table-column>

          <b-table-column label="Handicap" field="handicap.full18" sortable v-slot="props" centered width="80">
            {{formatHandicapObj(props.row.handicap,'full18')}}
          </b-table-column>

          <b-table-column label="Last Updated" field="handicapDate" sortable v-slot="props" width="200">
            {{props.row.handicapDateFormatted}}
          </b-table-column>

          <b-table-column label="Signed Up" field="signedUpDate" sortable v-slot="props" width="200">
            {{props.row.signedUpDateFormatted}}
          </b-table-column>

          <b-table-column label="Tee Time Request" v-slot="props">
            {{props.row.teeTimeRequest}}
          </b-table-column>

           <b-table-column cell-class="vert-middle" custom-key="delete" v-slot="props" width="20">
           
            <span class="icon has-margin-left-10" @click="deletePlayer(props.row)">
              <i class="delete"></i>
            </span>
            
          </b-table-column>
        </b-table>
      </template>
    </b-collapse>
    <template v-else>
      <div v-if="round.isScheduled" class="panel-block">
        <div class="tags has-margin-bottom-5">
          <div
            v-for="(score,rowIndex) in unassignedPlayers" :key="rowIndex"
            class="tag is-medium" >
              {{ score.slotLabel }}
            
          </div> 

        </div>
      </div>

    </template>
   
    <div 
      v-if="!readOnly" 
      class="level is-mobile  has-margin-bottom-10 has-margin-top-10" 
    >
      <div class="level-left">
        <div class="level-item has-text-weight-bold">
          Start
        </div>
        <div class="level-item">
         
          <b-timepicker
            v-if="$isSafari"
            :value="round.teeTimes.startTime" 
            icon="clock" 
            style="width:9em;"
            hour-format="12"
            :mobile-native="false"
            @input="value=>updateStartTime(value)"
          />
          <b-input
            v-else
            type="time"
            :value="round.teeTimes.startTime24"
            @blur="updateStartTimeVal($event.target.value)"
          />
        </div>
        <div class="level-item has-text-weight-bold">
          Interval
        </div>
        <div class="level-item">
          <b-numberinput 
            :value="round.teeTimes.interval"
            :max="59" :min="1"
            style="width:4em;"
            :controls="false"
            @blur="updateInterval($event.target.value)"
          />
        </div>
        <div class="level-item">
          <b-button @click="reorderTeeTimes()">Reorder</b-button>
        </div>
       
      </div>

    </div>

    <div class="columns is-multiline">
      <template v-if="readOnly">
        <div class="panel column"  v-for="(teeTime,index) in teeTimesArray" :key="index">
          <div class="panel-heading is-size-6">
            <div class="level is-mobile">
              <div class="level-left">
                <div class="level-item">
                  Group {{teeTime.groupNum}}
                </div>
                <div class="level-item">
                  {{ teeTime.time }}
                </div>
              
              </div>
              <div class="level-right">
                <template v-if="hasGroupStarted(teeTime.groupNum) && round.isInProgress" >
                  <div class="level-item">
                    {{ groupHoleThru(teeTime.groupNum)}}
                  </div>
                </template>
                <template v-else>
                  <div class="level-item">
                    Hole
                  </div>
                  <div class="level-item">
                    {{ teeTime.startHole }}
                  </div>
                </template>
               
              </div>
            </div>
            <div class="panel-block has-background-white" v-for="n in 4" :key="n">
              <p class="has-text-weight-normal">{{ teeTime.slots(n-1) ? teeTime.slots(n-1).slotLabel : '&nbsp;'}}</p>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="column" 
          v-for="(teeTime,index) in teeTimesArray" :key="index">

          <drop class="drop panel"
            @drop="handleGroupDrop(index,...arguments)">
            <drag
              class="drag" 
              :transfer-data="index"
              :effect-allowed="['copy']"
              drop-effect="copy">
              <div class="panel-heading is-size-6" >
                <div class="level is-mobile">
                  <div class="level-left">
                    <div class="level-item">
                      Group {{ teeTime.groupNum }}
                    </div>
                    <div class="level-item">
             
                      <b-timepicker
                        v-if="$isSafari"
                        :value="teeTime.timeVal" 
                        style="width:110px;"
                        editable
                        hour-format="12"
                        :mobile-native="false"
                        @input="value=>updateTime(teeTime.groupNum, value)" 
                      />

                      <b-input
                        v-else
                        type="time"
                        :value="teeTime.time24"
                        @blur="updateTimeVal(teeTime.groupNum, $event.target.value)"
                      />
                      
               <!--           
                      <vue-timepicker
                        manual-input
                        format="hh:mm A"
                      
                        :value="teeTime.timeObj"
                        hide-dropdown
                        @blur="updateTimeVal($event, teeTime.groupNum)"
                      />
                   
                   -->     
                    
              
                      
                    </div>
                     
                  </div>
                  <div class="level-right">
                    <template v-if="hasGroupStarted(teeTime.groupNum) && round.isInProgress">
                      <div  class="level-item">
                        {{ groupHoleThru(teeTime.groupNum) }}
                      </div>
                    </template >
                    <template v-else>
                      <div class="level-item has-margin-left-10">
                      Hole
                      </div>
                      <div class="level-item">
                        <b-input 
                          style="width:50px" 
                          :value="teeTime.startHole" 
                          @input="value => updateStartHole(teeTime.groupNum, value)" />
                      </div>
                    </template>
                    
                  </div>
                </div>  
              </div>
              
              <drop
                v-for="n in 4"
                :key="n"
                class="panel-block" 
                @drop="handleDrop(index, n-1, ...arguments)">
                  <a class="is-italic" v-if="teeTime.slotsDocId(n-1)===null" @click="unassign(index, n-1, '')">Not assigned</a>
                  <a class="is-italic" v-else-if="teeTime.slotsDocId(n-1)===''" @click="unassign(index, n-1, null)">Not in Game</a>
                  <drag
                    v-else
                    :tag="'a'"
                    class="tag is-medium"
                    :class="isHandicapCurrent(teeTime.slots(n-1))"
                    :transfer-data="teeTime.slots(n-1)"
                    :effect-allowed="['copy']"
                    drop-effect="copy"
                    @dragstart="handleChildDragstart"
                    @click.native="editPlayer(teeTime.slots(n-1))"> 
                      {{ teeTime.slots(n-1)===null ? '' : teeTime.slots(n-1).slotLabel}}
                      
                      <a v-if="teeTime.slots(n-1)" class="tag is-delete" :class="isHandicapCurrent(teeTime.slots(n-1))" @click.stop="unassign(index, n-1)"></a>

                  </drag> 
                  

              </drop>
              <div class="panel-block">
                <div class="buttons">
                  <b-tooltip v-if="index===0" label="Insert Group Before">
                    <a class="has-margin-right-5" @click="insertGroup()">
                      <b-icon icon="plus-circle-multiple-outline" :size="isMobile?'is-medium':''"/>
                 
                    </a>
                  </b-tooltip>
                  <b-tooltip label="Add Group">
                    <a class="has-margin-right-5" @click="addGroup(index)">
                      <b-icon icon="plus-circle" :size="isMobile?'is-medium':''"/>
                    </a>
                  </b-tooltip>
                  <b-tooltip v-if="round.isScheduled || (round.isInProgress && teeTime.isEmpty())" label="Delete This Group">
                    <a class="has-margin-right-10" @click="deleteGroup(index)">
                      <b-icon icon="minus-circle" :size="isMobile?'is-medium':''"/>
                    </a>
                  </b-tooltip>
                  <template v-if="round.isScheduled">
                    <b-button @click="addFromUnassigned(index)" :disabled="round.teeTimes.isFull(index) || unassignedPlayers.length===0 ">Assign Players</b-button>
      
                  </template >
                  <template v-else>
                    <b-button @click="addFromGroupPlayers(index)" :disabled="round.teeTimes.isFull(index) ">Add Players</b-button>
                    <b-button @click="addGuestPlayer(index)" :disabled="round.teeTimes.isFull(index) ">Add Guest</b-button>
  
                  </template>
                </div>
              </div>
          
            </drag>
          
          </drop> 
         
        </div>
      </template>
    </div>
      
    <b-modal 
      :active.sync="showSendTeeTimesEmail" 
      has-modal-card :full-screen="isMobile" 
      
    >
      <send-tee-times-email :groupName="group.name" :roundDate="round.date" :emails="emailList" @saveModal="sendEmails" />
    </b-modal>

    <b-modal 
      :active.sync="showSendInvitesEmail" 
      has-modal-card :full-screen="isMobile" 
    
    >
      <send-invites-email :round-date="round.formattedDate" :players="groupPlayersArray" @saveModal="sendInvites" />
    </b-modal>

    <b-modal 
      :active.sync="showPlayerEdit" 
      has-modal-card 
      :full-screen="isMobile" 
    
    >
      <player-edit 
        :group="group"
        :round="round" 
        :roundDate="round.date"
        :score="playerEditScore" 
        :add-guest="addGuest" 
   
        @saveModal="savePlayer" 
      />
    </b-modal>

    <b-modal 
      :active.sync="showAddPlayers" 
      has-modal-card 
   
     
    >
      <select-players 
        :players="playersToSelect" 
        :preSelectedPlayers="[]"
        @saveModal="addPlayers" 
 
      />
    </b-modal>

    <b-modal 
      :active.sync="showAddUnassigned" 
      has-modal-card 
      :full-screen="isMobile" 
  
    >
      <select-players 
   
        :players="unassignedPlayers" 
        :preSelectedPlayers="[]"
        @saveModal="addUnassigned"

      />
    </b-modal>
  </div>
 
</template>

<script>



import Score from '../../models/score'
import Scores from '../../models/scores'

import IndexLookup from '../../helpers/indexLookup'
import HandicapCalc from '../../helpers/handicapCalc'

import { mapGetters } from "vuex"
import pluralize from 'pluralize'

import { Drag, Drop } from 'vue-drag-drop'

import dayjs from 'dayjs'
import sendTeeTimesEmail from '../Dialogs/SendTeeTimes'
import sendInvitesEmail from '../Dialogs/SendRoundInvites'
import playerEdit from '../Dialogs/PlayerEdit'
import SelectPlayers from '../Dialogs/SelectPlayers'
import { sortArrayObj, copyToClipboard, uniqueId, formatHandicapObj } from '../../lib'
import Papa from 'papaparse'
import FileSaver from 'file-saver'
import DBUpdate from '../../helpers/dbUpdate'
import DBUpdates from '../../helpers/dbUpdates'


export default {
  
  components: {
    Drag, Drop,
    'send-tee-times-email': sendTeeTimesEmail,
    'send-invites-email': sendInvitesEmail,
    'player-edit': playerEdit,
    'select-players': SelectPlayers,
    // 'vue-timepicker': VueTimepicker
  },


  data() {
    return {
      selectedGroupIndex: -1,
      showAddPlayers: false,
      showAddUnassigned: false,
      showSendTeeTimesEmail: false,
      showSendInvitesEmail: false,
      showPlayerEdit: false,
      addGuest: false,
      playerEditScore: null,
      isPlayersOpen: false,
      isAssignOpen: true,
      showHandicapProgress: false,
      handicapProgressCount: 0,
      unassignedRadio: 'list',
      playerAdmin: null
  
    }
  },

  mounted () {
    this.playerAdmin = this.groupPlayersArray.find(p=>p.docId===this.user.playerDocId)
    if (this.playerAdmin === undefined) {
      this.playerAdmin = null
    }
    else {
      this.unassignedRadio = this.playerAdmin.pairingsView
    }
  },
  
  computed: {
    ...mapGetters('round', {
      round: 'round',
      scores: 'scores'
    }),
  
    scoresArray () {
      return this.scores.aScores
    },
    teeTimesArray () {
      return this.round.teeTimes.aTeeTimes
    },
    ...mapGetters('round', [
      'tab'
    ]),
    ...mapGetters('group', {
      group: 'group',
      groupPlayersArray: 'playersArray'
    }),
  
    user () {
      return this.$store.getters['user/user']
    },
    isAdmin () {
      return this.$store.getters['user/isAdmin']
    },
    readOnly () {
   
      return this.round.isPosted || !this.isAdmin
    },

    teeTimesScoresArray () {
      return this.round.teeTimes.scoresArray()
    },
   
    emailList () {
      const toList = this.teeTimesScoresArray.filter(score => score.email.length>0).map(score => score.email)
      return toList.join(', ')
    },
   
    unassignedPlayers () {
      // const unassignedPlayers = this.scoresArray.filter(score => score.pairing.slot===null)
      const unassignedPlayers = this.round.teeTimes.unassignedScores(this.scoresArray)
      sortArrayObj(unassignedPlayers, 'name')

      
      // const unassigned = []
    
      // let row = []
      // for(let player of unassignedPlayers) {
      
      //   row.push(player)
      
      //   if (row.length === 4) {
      //     unassigned.push([...row])
      //     row = []
     
      //   }
      
      // }
      // if (row.length > 0) {
      //   if (row.length < 4) {
      //     const rowNulls = new Array(4-row.length).fill(null)
      //     row = row.concat(rowNulls)
      //   }
        
      //   unassigned.push([...row])
      // }
      return unassignedPlayers
    },
    playersToSelect() {
      // remove players already listed for round
      const players = this.groupPlayersArray.filter(p => (this.scoresArray.findIndex(s => s.playerDocId === p.docId) === -1 ))
      sortArrayObj(players, 'name')
      return players
    }
   
  },
  
  methods: {
    pluralize, formatHandicapObj,
    


    async setUnassignedList (list) {
      this.unassignedRadio = list
      if (this.playerAdmin) {
        const playerUpdates = new DBUpdates()
        playerUpdates.add(this.playerAdmin.docId, {pairingsView: list})
        await this.$store.dispatch('group/savePlayerValues', playerUpdates)
      }
    
    },

    getTeeTime (teeTime) {
      return dayjs(teeTime).toDate()
    },

    async updateStartTimeVal(val) {

      
      const startTime = dayjs().hour(Number(val.substr(0,2))).minute(Number(val.substr(3,2)))

      await this.updateStartTime(startTime.toDate())
  

    },

    async updateStartTime(time) {
      const startTime = dayjs(this.round.date).clone().hour(time.getHours()).minute(time.getMinutes()).toDate()

      const teeTimes = this.round.teeTimes.copy()
      teeTimes.updateStartTime(startTime)
     
      const roundUpdate = new DBUpdate(this.round.docId, {teeTimes: teeTimes.getData()})
      await this.$store.dispatch('round/saveValues', roundUpdate)
  

    },

    async updateInterval(interval) {
      interval = Number(interval)

      const teeTimes = this.round.teeTimes.copy()
  
      teeTimes.updateInterval(interval)
      const roundUpdate = new DBUpdate(this.round.docId, {teeTimes: teeTimes.getData()})
      
      await this.$store.dispatch('round/saveValues', roundUpdate)
   

    },



     async updateTimeVal (groupNum, val) {
   
      const newTime = dayjs().hour(Number(val.substr(0,2))).minute(Number(val.substr(3,2)))
    
      await this.updateTime (groupNum, newTime.toDate())
    
      
    },
    
    async updateTime (groupNum, time) {
      const newTime = dayjs(this.round.date).clone().hour(time.getHours()).minute(time.getMinutes()).toDate()
      
      const teeTimes = this.round.teeTimes.copy()
      teeTimes.updateTime(groupNum, newTime)

      const roundUpdate = new DBUpdate(this.round.docId, {teeTimes: teeTimes.getData()})
      
      await this.$store.dispatch('round/saveValues', roundUpdate)
 
    },
   
    async updateStartHole(groupNum, newStartHole) {
      const teeTimes = this.round.teeTimes.copy()
      teeTimes.updateStartHole(groupNum, newStartHole)

    
      const roundUpdate = new DBUpdate(this.round.docId, {teeTimes: teeTimes.getData()})
      await this.$store.dispatch('round/saveValues', roundUpdate)

    },

     async updateCloseSignups(val) {

      const roundUpdate = new DBUpdate(this.round.docId, {closeSignups: val})
      await this.$store.dispatch('round/saveValues', roundUpdate)

    },
    
    async autoPairPlayer () {
      const teeTimes = this.round.teeTimes.copy()
      teeTimes.autoPair('player', this.unassignedPlayers)
      await this.updateTeeTimes(teeTimes)
    
    },
    async autoPairRandom () {
      const teeTimes = this.round.teeTimes.copy()
      teeTimes.autoPair('random', this.unassignedPlayers)
      await this.updateTeeTimes(teeTimes)
    
    },
    async autoPairAbcd () {
      const teeTimes = this.round.teeTimes.copy()
      teeTimes.autoPair('abcd', this.unassignedPlayers)
      await this.updateTeeTimes(teeTimes)
     
    },
    async autoPairTee () {
      const teeTimes = this.round.teeTimes.copy()
      teeTimes.autoPair('tee', this.unassignedPlayers)
      await this.updateTeeTimes(teeTimes)
   
    },
    async autoPairHandicap () {
      const teeTimes = this.round.teeTimes.copy()
      teeTimes.autoPair('handicap', this.unassignedPlayers)
      await this.updateTeeTimes(teeTimes)
   
    },

    async updateTeeTimes (teeTimes) {
   
      const roundUpdate = new DBUpdate(this.round.docId, {teeTimes: teeTimes.getData()})
      await this.$store.dispatch('round/saveValues', roundUpdate)
     
    },
   
    async unassign (teeTimeIndex, slotIndex, val=null) {
      
      const teeTimes = this.round.teeTimes.copy()

      const teeTime = teeTimes.getTeeTimeByIndex(teeTimeIndex)
      const score = teeTime.slots(slotIndex)
      if (score !== null && this.round.isInProgress) {
        await this.deletePlayer(score) //async wait
      }
      else {
        teeTime.unassign(slotIndex, val)

        await this.updateTeeTimes (teeTimes) 

      }

     
    },
   
    async unassignAll () {
      const teeTimes = this.round.teeTimes.copy()

      teeTimes.unassignAll()
      
      await this.updateTeeTimes (teeTimes) 

    },
    handleChildDragstart(data, event) {
      event.stopPropagation();
    },
  
    async handleGroupDrop (fromTeeTimeIndex, toTeeTimeIndex) {
    
      const teeTimes = this.round.teeTimes.copy()
      const fromTeeTime = teeTimes.getTeeTimeByIndex(fromTeeTimeIndex)
      const toTeeTime = teeTimes.getTeeTimeByIndex(toTeeTimeIndex)
   
      // swap scores
      for (let i=0; i<4; i++) {
        let tmpScore = toTeeTime.slots(i)

        toTeeTime.scores[i] = fromTeeTime.slots(i)
        toTeeTime.scoreDocIds[i] = fromTeeTime.scoreDocIds[i]

        fromTeeTime.scores[i] = tmpScore
        if (tmpScore === null) {
          fromTeeTime.scoreDocIds[i] = null
        }
        else {
          fromTeeTime.scoreDocIds[i] = tmpScore.docId
        }
        
        
      }

      await this.updateTeeTimes (teeTimes)
     
    },
  
    async handleDrop (toTeeTimeIndex, toSlotIndex, droppedScore, dragEvent) {
   
      const teeTimes = this.round.teeTimes.copy()

      const fromTeeTime = teeTimes.getTeeTimeByScoreDocId(droppedScore.docId)
      const toTeeTime = teeTimes.getTeeTimeByIndex(toTeeTimeIndex)

      if (toTeeTime.scoreDocIds[toSlotIndex] === -1) { // not in group can't move
        dragEvent.stopPropagation()
        return
      }

      if (fromTeeTime !== null) { // score dropped from another assigned time
        // if (toTeeTime.scoreDocIds[toSlotIndex] !== null ) { // score existing so swap
          const fromSlotIndex = fromTeeTime.getSlotIndexByScore(droppedScore)
          fromTeeTime.scores[fromSlotIndex] = toTeeTime.slots(toSlotIndex)
          fromTeeTime.scoreDocIds[fromSlotIndex] = toTeeTime.scoreDocIds[toSlotIndex]
        // }
      }
      toTeeTime.scores[toSlotIndex] = droppedScore
      toTeeTime.scoreDocIds[toSlotIndex] = droppedScore.docId
      
      dragEvent.stopPropagation()

      await this.updateTeeTimes (teeTimes)

    },

    async insertGroup () {

      const teeTimes = this.round.teeTimes.copy()
      teeTimes.insertBefore()

      await this.updateTeeTimes (teeTimes)
    
    },

    async addGroup (index) {

      const teeTimes = this.round.teeTimes.copy()
      teeTimes.insert(index)

      await this.updateTeeTimes (teeTimes)
    
    },
    async deleteGroup (index) {
    
      const teeTimes = this.round.teeTimes.copy()
      teeTimes.deleteByIndex(index)

      await this.updateTeeTimes (teeTimes)

    },
   

    addFromGroupPlayers (index=-1) {

      this.selectedGroupIndex = index
      this.showAddPlayers = true
      
    },
    addFromUnassigned (index) {
   
      this.showAddUnassigned = true
      this.selectedGroupIndex = index
    },
    addGuestPlayer (index=-1) {
      
      const score = new Score()

      score.playerDocId = uniqueId()
      score.indexSource = 'GHIN'
  
      score.setRound (this.round)

      score.type = "Guest"
      score.signUpDate = new Date()
   

      this.playerEditScore = score
      this.addGuest = true
      this.selectedGroupIndex = index
      this.showPlayerEdit = true

  

    },

    importPlayers () {

    },

    editPlayer (score) {
      this.showPlayerEdit = true
      this.playerEditScore = score
    },

    async savePlayer (playerData) {
      if (this.addGuest) {
        
        this.playerEditScore.name = playerData.name
        this.playerEditScore.email = playerData.email
        this.playerEditScore.tee = playerData.tee
        this.playerEditScore.gender = playerData.gender
        this.playerEditScore.ghinNum = playerData.ghinNum
        this.playerEditScore.index = playerData.indexInfo.getIndexObj()
        this.playerEditScore.indexSource = playerData.indexInfo.indexSource
        this.playerEditScore.handicap = playerData.handicap
        this.playerEditScore.type = 'Guest'
        this.playerEditScore.handicapDate = new Date()

        await this.$store.dispatch('round/addScore', this.playerEditScore)

        if (this.selectedGroupIndex >= 0) {
          this.addUnassigned([this.playerEditScore])
        }
      }
      else {
        const update = {}
        if (playerData.name !== this.playerEditScore.name) update.name = playerData.name
        if (playerData.email !== this.playerEditScore.email) update.email = playerData.email
        if (playerData.tee !== this.playerEditScore.tee) {
          update.tee = playerData.tee
   
        }
        if (playerData.ghinNum !== this.playerEditScore.ghinNum) update.ghinNum = playerData.ghinNum
        
        update.index = playerData.indexInfo.getIndexObj()
        update.indexSource = playerData.indexInfo.indexSource    
        update.handicap = playerData.handicap
        update.handicapDate = new Date()
      
        if (playerData.wd !== this.playerEditScore.wd) update.wd = playerData.wd

        if (Object.keys(update).length > 0) {
          const scoreUpdates = new DBUpdates()
          scoreUpdates.add(this.playerEditScore.docId, update)
          await this.$store.dispatch('round/saveScoreValues', scoreUpdates)
        
        }
      }
     
      this.playerEditScore = null
      this.addGuest = false
      this.addGuestIndex = -1
    },

    async deletePlayer(score) {
      if (score.hasScore()) {
        this.$buefy.dialog.confirm({
          title: 'Delete Player',
          message: 'Scores have been entered for this player. Do you REALLY want to delete?',
          onConfirm: async () => await this.$store.dispatch('round/deleteScore', score)
        })
      }
      else {
        await this.$store.dispatch('round/deleteScore', score)
      }
      

    },
    
    async addPlayers (selectedPlayers) {
      this.showAddPlayers = false
      const scores = new Scores()
      for (let player of selectedPlayers) {
      
        const score = scores.addNew()

        score.roundDate = this.round.date
        score.roundDocId = this.round.docId
        score.roundStatus = this.round.status
        score.courseLabel = this.round.course.label
        score.roundName = this.round.name
        score.name = player.name
        score.playerDocId = player.docId
        score.email = player.email
        score.type = player.type
        score.tee = player.tee
        score.gender = player.gender
        score.ghinNum = player.ghinNum
        score.totalBet = this.round.games.totalBet
        score.signUpDate = new Date()
        score.setRound (this.round)
      }

      await this.$store.dispatch('round/addScores', scores)
     
      if (this.selectedGroupIndex >= 0) this.addUnassigned(scores.aScores)

    },
    async addUnassigned (selectedScores) {
      this.showUnassigned = false
      const teeTimes = this.round.teeTimes.copy()
      const teeTime = teeTimes.getTeeTimeByIndex(this.selectedGroupIndex)

      for (let score of selectedScores) {
        teeTime.assignEmptySlot(score)
      }

      await this.updateTeeTimes (teeTimes)

    },
    
    hasGroupStarted (groupNum) {
      let hasStarted = false
      const teeTime = this.round.teeTimes.getTeeTimeByGroupNum(groupNum)
      if (teeTime) {
        for (let score of teeTime.scores) {
          if (score !== null) {
            if (score.holesCompleted > 0) {
              hasStarted = true
              break
            }
          }
        }
      }
      
      return hasStarted
    },

    groupHoleThru (groupNum) {
      let thru
      const teeTime = this.round.teeTimes.getTeeTimeByGroupNum(groupNum)
      if (teeTime) {
        for (let score of teeTime.scores) {
          if (score !== null) {
            thru = score.holesCompleted
          }
        }
      }
      if (thru === 0) {
        thru = ''
      }
      else {
        thru = 'Thru ' + thru
      }
     
      return thru
    },

    async sendInvites (data) {
     
      await this.$store.dispatch('round/sendInvitesEmail',data)

      
    },
   
    async sendEmails (data) {
     
        // remove any empty tee times

      let updateTeeTimes = false
      const teeTimes = this.round.teeTimes.copy()
      for (let teeTime of teeTimes.aTeeTimes) {
        if (teeTime.isEmpty()) {
          teeTimes.delete(teeTime)
          updateTeeTimes = true
        }
      }
    
      if (updateTeeTimes) {
        const roundUpdate = new DBUpdate(this.round.docId, {teeTimes: teeTimes.getData()})
        await this.$store.dispatch('round/saveValues', roundUpdate)
      }

      data.msg = data.msg.split('\n')
      data.groupName = this.group.name
     
      this.$store.dispatch('round/sendTeeTimesEmail',data)

      
    },
    isHandicapCurrent(score) {
      let warning = ''

      if (score && !this.round.isTournament) {
        if ( score.handicapDate===null || (dayjs(score.handicapDate).add(1,'day').isBefore(dayjs(this.round.date)))) warning = 'is-danger'
      }
       return warning
    },

    async updateHandicaps () {
      this.showHandicapProgress = true
      this.handicapProgressCount = 0

      const settings = {
        handicapSettings: this.round.handicap,
        groupDocId: this.round.groupDocId,
        // state: this.round.course.state,
        // roundDate: this.round.date
      }
      const indexLookup = new IndexLookup(settings)
      const hc = new HandicapCalc(this.round.course, this.round.handicap.maxHandicap)

      let count = 0

      for (let score of this.scoresArray) {

        const playerInfo = {
          // docId: score.docId,
          playerDocId: score.playerDocId,
          name: score.name,
          ghinNum: score.ghinNum,
          indexSource: score.indexSource,
          tee: score.tee,
          gender: score.gender,
          state: this.round.course.state,
          type: score.type,
        }

     
        const indexInfo = await indexLookup.getIndexInfo(playerInfo)

        if (indexInfo.index !== null) {
          const scoreUpdates = new DBUpdates()

          const handicapObj = hc.getPlayingHandicapObj(indexInfo, playerInfo.tee, this.round.getHandicapFac(), playerInfo.gender)

          // const update = updateHandicap(score, handicapObj, this.round)

          const update = {
            index: indexInfo.getIndexObj(),
            indexSource: indexInfo.indexSource,
            handicap: handicapObj,
            handicapDate: new Date()
          }
          
          scoreUpdates.add(score.docId, update)

          await this.$store.dispatch('round/saveScoreValues', scoreUpdates)

        }
        
        count++

        this.handicapProgressCount = count / this.scoresArray.length * 100

      }
   
     
      this.showHandicapProgress = false
    

    },
    reorderTeeTimes () {
      this.round.teeTimes.sort()
    },
    exportPlayers () {
      const players = this.scoresArray.map(score => {
        return {
          name: score.name,
          email: score.email,
          type: score.type,
          tee: score.tee,
          ghinNum: score.ghinNum,
          index: score.index.full18,
          indexSource: score.indexSource,
          handicap: score.handicap.full18,
          handicapFront: score.handicap.front,
          handicapBack: score.handicap.back
        }
      })
      sortArrayObj(players, 'name')
      const csv = Papa.unparse(players)

      const blob = new Blob([csv], {type: "text/plain;charset=utf-8"})
      FileSaver.saveAs(blob, 'player list.csv')
    },
    exportNames () {
      let scoresArray = this.scoresArray
      if (this.teeTimesScoresArray.length === this.scoresArray.length) {
        scoresArray = this.teeTimesScoresArray
      }
      const names = scoresArray.map(player => (player.name + '\t' + player.teeTimeRequest)).sort()
      let txt = `${this.group.name} Players for ${this.round.formattedDate}` + '\r\n' + '\r\n'
      txt += `${names.length} Players` + '\r\n' + '\r\n'
      txt += names.join('\r\n')
    
      copyToClipboard(txt)
    },
    exportEmails () {
      const emails = this.scoresArray.map(player => player.email).sort()

      const txt = emails.join(',')
    
      copyToClipboard(txt)
    }
  }
}
</script>

<style>
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px,1fr));
  }
  .teetimebordercolor {
    border-color: #5e5959 !important;
  }
  .vert-middle {
    vertical-align: middle;
  }

  .vert-center-middle {
    vertical-align: middle !important;
    text-align:center !important;
  }

  .align-right {
    text-align: right;
  }

  .selected-group {
    border-width: 2px;
    border-style: solid;
    border-color: blue;
  }
</style>