import { checkTournament, checkRound, checkGroup } from './guards'

import User from '../pages/user/UserMain'
// const User = () => import('../pages/user/UserMain')
import Home from '../pages/Home'
// const Home = () => import('../pages/Home')
import RoundSignup from '../pages/RoundSignup'
// const RoundSignup = () => import('../pages/RoundSignup')
import Courses from '../pages/course/Courses'
// const Courses = () => import('../pages/course/Courses')
import EditCourse from '../pages/course/EditCourse'
// const EditCourse = () => import('../pages/course/EditCourse')
import ViewCourse from '../pages/course/ViewCourse'
// const ViewCourse = () => import('../pages/course/ViewCourse')
// import GroupSetup from '../pages/group/Setup'
import Group from '../pages/group/GroupMain'
// const Group = () => import('../pages/group/GroupMain')
import GroupRound from '../pages/group/RoundMain'
// const GroupRound = () => import('../pages/group/RoundMain')
import GroupCreate from '../pages/group/CreateGroup'
// const GroupRegister = () => import('../pages/group/Register')
import GroupTournament from '../pages/group/TournamentMain'
import Signin from '../pages/user/Signin'
// const Signin = () => import('../pages/user/Signin')
import UserRegister from '../pages/user/Register'
// const UserRegister = () => import('../pages/user/Register')
// import PageNotFound from '../pages/404'
import NoGuest from '../pages/NoGuest'

export default [
  
  { path: '/roundsignup', name: 'RoundSignup', component: RoundSignup },
  { path: '/guestnotfound', name: 'No Guest', component: NoGuest },
  { path: '/signin', name: 'Signin', component: Signin },

  { 
    path: '/user', 
    name: 'User',
    component: User,
    meta: { requiresAuth: true }
  }, 

  { path: '/user/register', name: 'Register', component: UserRegister },

  { path: '/group/create', name: 'CreateGroup', component: GroupCreate },

  { 
    path: '/group', 
    name: 'Group',
    component: Group, 
    beforeEnter: checkGroup,
    meta: { requiresAuth: true }
  },
  { 
    path: '/group/rounds', 
    name: 'Rounds',
    component: Group, 
    beforeEnter: checkGroup, 
    props: {initialTab: 'rounds'},
    meta: { requiresAuth: true }
  },
  { 
    path: '/group/players', 
    component: Group, 
    name: 'GroupPlayers',
    beforeEnter: checkGroup, 
    props: {initialTab: 'players'},
    meta: { requiresAuth: true }
  },
  { 
    path: '/group/games', 
    component: Group, 
    name: 'Games',
    beforeEnter: checkGroup, 
    props: {initialTab: 'games'},
    meta: { requiresAuth: true} 
  },
  { 
    path: '/group/handicaps', 
    component: Group, 
    name: 'Handicaps',
    beforeEnter: checkGroup, 
    props: {initialTab: 'handicaps'},
    meta: { requiresAuth: true}
  },
  { 
    path: '/group/standings', 
    component: Group, 
    name: 'Standings',
    beforeEnter: checkGroup, 
    props: {initialTab: 'standings'},
    meta: { requiresAuth: true }
  },

  { 
    path: '/group/round', 
    component: GroupRound, 
    name: 'Round',
    beforeEnter: checkRound, 
    props: {initialTab: 'pairings'},
    meta: { requiresAuth: true }
  },
  { 
    path: '/group/round/pairings', 
    component: GroupRound, 
    name: 'Pairings',
    beforeEnter: checkRound, 
    props: {initialTab: 'pairings'},
    meta: { requiresAuth: true }
   },
  { 
    path: '/group/round/scores', 
    component: GroupRound, 
    name: 'Scores',
    beforeEnter: checkRound, 
    props: {initialTab: 'scores'},
    meta: { requiresAuth: true}
   },
  { 
    path: '/group/round/leaderboard', 
    component: GroupRound, 
    name: 'Leaderboard',
    beforeEnter: checkRound, 
    props: {initialTab: 'leaderboard'},
    meta: { requiresAuth: true}
   },
  { 
    path: '/group/round/payouts', 
    component: GroupRound, 
    name: 'Payouts',
    beforeEnter: checkRound, 
    props: {initialTab: 'payouts'},
    meta: { requiresAuth: true}
   },

   { 
    path: '/group/tournament', 
    component: GroupTournament, 
    name: 'Tournament',
    beforeEnter: checkTournament, 
    props: {initialTab: 'players'},
    meta: { requiresAuth: true }
  },
  { 
    path: '/group/tournament/players', 
    component: GroupTournament, 
    name: 'TournamentPlayers',
    beforeEnter: checkTournament, 
    props: {initialTab: 'players'},
    meta: { requiresAuth: true }
   },
  { 
    path: '/courses',  
    component: Courses,
    name: 'Courses',
    meta: { requiresAuth: true}
   },
  { 
    path: '/courses/add',  
    component: EditCourse,
    name: 'AddCourse',
    meta: { requiresAuth: true}
   },
  { 
    path: '/courses/edit',  
    component: EditCourse,
    name: 'EditCourse',
    meta: { requiresAuth: true}
  },
  { 
    path: '/courses/view', 
    component: ViewCourse,
    name: 'ViewCourse',
    meta: { requiresAuth: true}
  },
 
  { path: '/', component: Home, name: 'Home' },
  
   // Always leave this as last one
  { path: '*', redirect: '/' }
  
]
