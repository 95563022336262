<template>
  <div>
  
    <template v-if="isMobile">
      <div class="header">
        <div class="level is-mobile header-level">
          <div class="level-left">
            
            <div class="level-item  ">
              <p class="title is-5" style="margin-bottom:0px;">{{this.tournament.name}}</p>
            </div>
          </div>
         
        </div>
        <div class="level is-mobile header-level mb-1 mt-2">
          <div class="level-left">
            
            <div class="level-item  ">
              <p class="title is-6" style="margin-bottom:0px;">{{this.tournament.formattedDate}}</p>
            </div>
          </div>
         
        </div>

        <div class="level is-mobile header-level mt-1" >
          <div class="level-left">
            <div class="level-item">
              <p class="is-size-6">{{this.tournament.status}}</p>
            </div>
          </div>
        </div>

        <div class="level is-mobile header-level mt-1">
          <div class="level-left">
            <div class="level-item  ">
              <p class="title is-5" style="margin-bottom:0px;">{{this.title}}</p>
            </div>
          </div>
         
        </div>
      
      </div>
    </template>
    <div class="has-padding-10" :class="isMobile?'has-padding-top-120':''">
   
      <toolbar :itemSelected="itemSelected" @selected="selectTab" :group="group" :tournament="tournament"/>
      <div>
     
        <b-notification class="is-radiusless" :active.sync="error" @click.native="error=false">
          {{ errorMsg }}
        </b-notification>

        <template v-if="isMobile">

          <tournament-players v-if="itemSelected==='players'" />

          <tournament-rounds v-if="itemSelected==='rounds'"/>

          <games 
            v-if="itemSelected==='games'"
            :baseObj="tournament" 
            :numPlayers="tournament.numPlayers" 
            :readOnly="readOnly" 
          />
          
          <tournament-leaderboard 
            v-if="itemSelected==='leaderboard'" />
        
          <tournament-payouts 
            v-if="itemSelected==='payouts'" />

          <tournament-settings
            v-if="itemSelected==='settings'" 
          
  

          
          />
        </template>
        <template v-else>
          <div class="level is-mobile" >
            <div class="level-left">
             
              <template v-if="isAdmin">
                <div class="level-item">
                  <a class="mt-1 mr-1" @click="goBack()" >
                    <b-icon icon='arrow-left'></b-icon>
                  </a>
                  <p class="is-size-5">{{ tournament.name }}</p>
                </div>
                <div class="level-item">
                  <p class="subtitle">{{ tournament.formattedDate }}</p>
                </div>
                <div class="level-item">
                  <p class="subtitle has-text-info">{{ tournament.status }}</p>

                </div>
                <div v-if="tournament.isCompleted || tournament.isPosted" class="level-item">
                   <b-button @click="showSendResultsEmail=true">Send Results Email...</b-button>
                </div>
              </template>
              <template v-else>
                <div class="level-item">
                  <a class="is-size-5" @click="goBack()">{{ tournament.name }}</a>
                </div>
                <div class="level-item">
                  <p class="subtitle">{{ tournament.formattedDate + ' (' + tournament.status + ')' }}</p>
                </div>
              </template>
                
             

            </div>
            
          </div>

          <b-tabs
            v-model="activeTab"
            >
            <b-tab-item 
              label="Players"
              icon="account-multiple">
              <tournament-players />
            </b-tab-item>

            <b-tab-item 
              label="Games"
              icon="view-dashboard">
                <games 
                  :baseObj="tournament" 
                  :numPlayers="tournament.numPlayers" 
                  :readOnly="readOnly" 
                />
            </b-tab-item>
            
            <b-tab-item 
              label="Rounds"
              icon="table-edit">
                <tournament-rounds />
            </b-tab-item>
            <b-tab-item 
              label="Leaderboard"
              icon="google-analytics">
                <tournament-leaderboard  />
            </b-tab-item>
            <b-tab-item 
              v-if="tournament.betOnGames" 
              label="Payouts"
              icon="currency-usd">
              <tournament-payouts  />
            </b-tab-item>
            <b-tab-item 
              label="Settings"
              icon="settings">
              <tournament-settings
                @delete-tournament="deleteTournament"
                @show-send-results-email="showSendResultsEmail=true"
              
              
              />
            </b-tab-item>

          </b-tabs>
        </template>
      
        

      </div>
    </div>
    <b-modal  :active.sync="showAbout" >
      <about />
    </b-modal>
    <b-modal has-modal-card :active.sync="showAccount" >
      <account />
    </b-modal>
    
    <b-modal 
      :active.sync="showSendResultsEmail" 
      has-modal-card :full-screen="isMobile" 
      style="z-index:100;" 
    >
      <send-results-email :groupEmails="groupEmails" :roundEmails="tournamentEmails" @saveModal="sendResultsEmail" />
    </b-modal>
    
 
  </div>
</template>

<script>

import Players from '../../components/Tournament/Players'
import Rounds from '../../components/Tournament/Rounds'
import Tournament from '../../models/tournament.js'

import Leaderboard from '../../components/Tournament/Leaderboard'
import Settings from '../../components/Tournament/Settings'
import Payouts from '../../components/Tournament/Payouts'

import Games from '../../components/Games/Games'

import About from '../../components/Dialogs/About'
import Account from '../../components/Dialogs/Account'

import SendResultsEmail from '../../components/Dialogs/SendResults'

import Toolbar from '../../components/Shared/toolbar'

import { mapGetters } from 'vuex'


export default {
  data () {
    return {

      itemSelected: 'players',

      title: 'Players',

      showAbout: false,
      showAccount: false,
      showSendResultsEmail: false,
    
      tabs: {
        players: {
          title: 'Players',
          tab: 0
        },
        games: {
          title: 'Games',
          tab: 1
        },
        rounds: {
          title: 'Rounds',
          tab: 2
        },
        leaderboard: {
          title: 'Leaderboard',
          tab: 3
        },
        payouts: {
          title: 'Payouts',
          tab: 4
        },
        settings: {
          title: 'Settings',
          tab: 5
        }
      }
    }
  },

 
 
  components: {
 
    'tournament-players': Players,
    'tournament-rounds': Rounds,
    'tournament-leaderboard': Leaderboard,
    'tournament-payouts': Payouts,
    'tournament-settings': Settings,
    'games': Games,
    'toolbar': Toolbar,
    'about': About,
    'account': Account,
    'send-results-email': SendResultsEmail
  },
  mounted() {
   
  },
   props: {
    initialTab: {
      type: String,
      default: ''
    }
  },

  watch: {
    
    'tournament.games.aGames': {
      handler: function (val, oldVal) {
       
        if (val.length !== oldVal.length) { // if game is added or removed from Games
          const game = this.tournament.games.get(this.selectedGameId) // make sure selected game is stil in list
       
          if (game === undefined) {
            if (this.tournament.games.size === 0) {
              this.selectedGameId = ''
            }
            else {
              this.selectedGameId = this.tournament.games.aGames[0].id // if not, set selected game to first in list
            }
          }
        }
       
       
      }
    }
  },
  
  computed: {
    ...mapGetters('tournament', {
      payOuts: 'payOuts',
      leaderBoards: 'leaderBoards',
      from: 'from',
      errorMsg: 'errorMsg'
    }),
    ...mapGetters('group', ['group','playersArray']),
    tournament () {
      let tournament = this.$store.getters['tournament/tournament']
      if (tournament === null) tournament = new Tournament()
      return tournament
    },
    selectedGameId: {
      get () {
        return this.$store.getters['tournament/selectedGameId']
      },
      set (val) {
        this.$store.commit('tournament/selectedGameId', val)
      }
    },
    error: {
      set (newValue) {
        if (!newValue) {
          this.$store.commit('tournament/errorMsg','')
        }
      },
      get () {
        return this.errorMsg.length > 0
      }
    },
    activeTab: {
      get () {
        return this.$store.getters['tournament/tab']
      },
      set (val) {
        this.$store.commit('tournament/tab', val)
      }
    },
    isAdmin () {
      return this.$store.getters['user/isAdmin']
    },
    readOnly () {
      return this.tournament.isPosted || !this.isAdmin
    },
    user () {
      return this.$store.getters['user/user']
    },
    isGuest () {
      return this.$store.getters['user/isGuest']
    },
    // playerTees () {
    //   return this.round.course.getPlayerTees()
    // },
  
  
    
    playerDocId () {
      return this.$store.getters['user/playerDocId']
    },
    groupEmails () {
     
      return this.playersArray.filter(player => player.type==='Active' && player.email.length>0).map(player => player.email).sort().join(', ')
    },
    tournamentEmails () {
    
      return this.tournament.scores.aScores.filter(score => score.email.length>0).map(score => score.email).sort().join(', ')
 
    },
   
    
  },

  methods: {
    selectTab (tabName) {
      if (tabName === 'group') {
        this.goBack()
      }
      else if (tabName === 'about') {
        this.showAbout = true
      }
      else if (tabName === 'account') {
        this.showAccount = true
      }
      else {
        this.itemSelected = tabName
        this.title = this.tabs[tabName].title
        this.activeTab = this.tabs[tabName].tab
      }
      
    },
    goBack () {
      let path = '/group'
      if (this.from === 'user') path = '/user'
      this.$router.push(path)
    },
    deleteTournament () {
      if (this.tournament.isPosted) {
        this.$buefy.dialog.alert('This tournament has been posted. Unpost the rounds to delete.')
      }
      else {
        this.$buefy.dialog.confirm({
          message: 'Do you really want to delete this tournament?',
          onConfirm: async () =>  {
            await this.$store.dispatch("group/deleteTournament", this.tournament)
            this.goBack()
          }
        })
      }

    },

   
    async sendResultsEmail (emailInfo) {
      emailInfo.msg = emailInfo.msg.split('\n')
      // emailInfo.from = this.user.emailAddress
      emailInfo.from = this.user.emailAddressObj
     
      await this.$store.dispatch("tournament/sendResults", {tournament: this.tournament, payOuts: this.payOuts, emailInfo: emailInfo})
    }
   
  
    
  }

}

</script>

<style>
.header {
   position:fixed;
   width:100%;
   z-index:50;
   background-color:white;
   margin: 0 0 0 0;
}
.header-level {
  margin-left: 20px !important;
  margin-top:10px;
  padding-right:20px;
  margin-bottom:0px !important;
}
.header-level2 {
  margin-left:20px;
  margin-bottom:4px;
}


</style>


