export default class Tee {
// class Tee {
  constructor(data) {
    if (data) {
      this.setObj(data)
    } else {
      this.initialize()
    }
  }
  initialize() {
    this.name = ''
    this.shortName = ''
    this.gender = 'M'
    this.rating = {
      'full18': 0,
      'front': 0,
      'back': 0
    }
    this.slope = {
      'full18': 0,
      'front': 0,
      'back': 0
    }
    this.parFront = 36
    this.parBack = 36
    this.par = []
    this.yards = []
    this.handicap = []
    this._card = []
  }
  getSlope(side = 'full18') {
    return this.slope[side]
  }
  getRating(side = 'full18') {
    return this.rating[side]
  }
  getPar(side = 'full18') {
    let par = 0

    if (side === 'front') {
      par = this.par.slice(0, 9).reduce((total, num) => total + num)
    } else if (side === 'back') {
      par = this.par.slice(9).reduce((total, num) => total + num)
    } else {
      par = this.par.reduce((total, num) => total + num)
    }

    return par
  }
  get totalYards () {
    return this.yards.reduce((total, num) => total + num)
  }
  get listName() {
    let name = ''
    if (this.shortName.length > 0) {
      name = `${this.name} (${this.shortName})`
    } else {
      name = this.name
    }
    return name
  }
  generateShortName () {
    let teeNames = this.name.split(' ')
    if (teeNames.length === 1 && teeNames[0].includes('/')) teeNames = name.split('/')
    let shortName = teeNames.map(w=>w[0].toUpperCase()).join('')
    if (this.gender==='F') shortName = 'W' + shortName
    return shortName
  }
  copy() {
    const tee = new Tee()
    tee.setData(this.getData())
    return tee
  }
  setData(data) {

    this.name = data.name
    this.shortName = data.shortName
    this.gender = data.gender
    this.rating = {...data.rating}
    this.slope = {...data.slope}
    this.par = [...data.par]
    this.yards = [...data.yards]
    this.handicap = [...data.handicap]
    if (data.parFront === undefined) {
      this.parFront = 36
      this.parBack = 36
    }
    else {
      this.parFront = data.parFront
      this.parBack = data.parBack
    }
    
  }
  getData() {
    return {
      name: this.name,
      shortName: this.shortName,
      gender: this.gender,
      rating: {...this.rating},
      slope: {...this.slope},
      par: [...this.par],
      yards: [...this.yards],
      handicap: [...this.handicap],
      parFront: this.parFront,
      parBack: this.parBack
    }
  }
  setObj(data) {
    this.setData(data)
  }
  getObj() {
    return this.getData()
  }
  get card() {
    if (this._card.length === 0) {
      this._card = this.getHoles()
    }
    return this._card
  }
  set card (card) {
    this._card = card
  }
  initializeCard(tee) {
   
    if (tee !== null) {
      this.par = [...tee.par]
      this.yards = [...tee.yards]
      this.handicap = [...tee.handicap]
    } else {

      this.par = Array(18).fill(0)
      this.yards = Array(18).fill(0)
      this.handicap = Array(18).fill(0)
    }
    this._card = this.getHoles()
  }
  saveCard () {
    if (this._card.length > 0) {
      const parHoles = this._card[0]
      const yardHoles = this._card[1]
      const handicapHoles = this._card[2]
  
      this.par = []
      this.yards = []
      this.handicap = []
  
      for(let i=1; i<=18; i++) {
        this.par.push(parHoles['hole' + i])
        this.yards.push(yardHoles['hole' + i])
        this.handicap.push(handicapHoles['hole' + i])
      }
    }
  }
  getHoles(view='wide') {
    let holes = []
    if (view === 'wide') {

      const yards = {
        hole: 'Length',
        in: 0,
        out: 0,
        total: 0
      }
      let total = 0
      this.yards.forEach((yard, index) => {
        total = total + yard
        yards['hole' + (index + 1)] = yard
        if (index === 8) {
          yards.out = total
          total = 0
        }
        if (index === 17) yards.in = total
      })
      yards.total = yards.out + yards.in

      const pars = {
        hole: 'Par',
        in: 0,
        out: 0,
        total: 0
      }
      total = 0
      this.par.forEach((par, index) => {
        total = total + par
        pars['hole' + (index + 1)] = par
        if (index === 8) {
          pars.out = total
          total = 0
        }
        if (index === 17) pars.in = total
      })
      pars.total = pars.out + pars.in

      const handicaps = {
        hole: 'Handicap',
        in: '',
        out: '',
        total: ''
      }
      this.handicap.forEach((hc, index) => {
        handicaps['hole' + (index + 1)] = hc
      })

      holes = [
        pars,
        yards,
        handicaps
      ]
    }
    else {
      let yardsOut = 0
      let parOut = 0
      for (let i=0; i<9; i++) {
        holes.push(
          {
            hole: i+1,
            par: this.par[i],
            yards: this.yards[i],
            handicap: this.handicap[i]
          }
        )
        yardsOut += this.yards[i]
        parOut += this.par[i]
      }
      holes.push (
        {
          hole: 'OUT',
          par: parOut,
          yards: yardsOut,
          handicap: ''
        }
      )
      let yardsIn = 0
      let parIn = 0
      for (let i=9; i<18; i++) {
        holes.push(
          {
            hole: i+1,
            par: this.par[i],
            yards: this.yards[i],
            handicap: this.handicap[i]
          }
        )
        yardsIn += this.yards[i]
        parIn += this.par[i]
      }
      holes.push (
        {
          hole: 'IN',
          par: parIn,
          yards: yardsIn,
          handicap: ''
        }
      )
      holes.push (
        {
          hole: 'TOT',
          par: parIn+parOut,
          yards: yardsIn+yardsOut,
          handicap: ''
        }
      )
    }
    return holes
  }

}
// module.exports = Tee