<template>
  <div>
     <b-notification class="is-radiusless" has-icon :active.sync="showMsg" type='is-info' @click.native="showMsg=false">
        <p class="is-size-4">{{round.msg}}</p>
    </b-notification>
    <template v-if="isMobile">
      <div class="header">
        <div class="level is-mobile header-level">
          <div class="level-left">
            <div class="level-item  ">
              <p class="title is-5" style="margin-bottom:0px;">{{this.round.formattedDateLabel + ' - ' + this.title}}</p>
            </div>
          </div>
          <div v-if="isAdmin" class="level-right" >
            <div class="level-item" >
              <b-tooltip v-if="round.isInProgress" label="Send Message">
                <a @click="showSendMessage=true" style="margin-top:4px;"> 
                  <b-icon icon="message-alert-outline" />
                </a>
              </b-tooltip>
              
             
            </div>
          </div>
        </div>
        <div class="level is-mobile header-level2" >
          <div class="level-left">
            <div class="level-item">
              <p class="is-size-6">Round {{this.round.status}}</p>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="has-padding-10" :class="isMobile?'has-padding-top-70':''">
   
      <toolbar :itemSelected="itemSelected" @selected="selectTab" :group="group" :round="round"/>
      <div>
     
        <b-notification class="is-radiusless" :active.sync="error" @click.native="error=false">
          {{ errorMsg }}
        </b-notification>

        <template v-if="isMobile">

          <round-pairings 
            v-if="itemSelected==='pairings'"
          />
          
          <games 
            v-if="itemSelected==='games'"
            :baseObj="round" 
            :numPlayers="scores===null?0:scores.size" 
            :readOnly="readOnly" 
          />
          
          <round-scores 
            v-if="itemSelected==='scores'"
          
          />
          
          <round-leaderboard v-if="itemSelected==='leaderboard'" />
        
          <round-payouts v-if="itemSelected==='payouts'" />

          <round-settings
            v-if="itemSelected==='settings'" 
            :round="round"
          
            :scoresArray="scoresArray"
        
            @show-send-results-email="showSendResultsEmail=true"
            @start-round="startRound"
            @restart-round="restartRound"
            @complete-round="completeRound"
            @post-round="postRound"
            @stop-round="stopRound"
            @unpost-round="unPostRound"
            @delete-round="deleteRound"
          
          />
        </template>
        <template v-else>
          <div class="level is-mobile" >
            <div class="level-left">
             
              <template v-if="isAdmin">
                <div class="level-item">
                  
                  <a class="mt-1" @click="goBack()" >
                    <b-icon icon='arrow-left'></b-icon>
                  </a>
                </div>
                <div class="level-item">
                  <p class="subtitle">{{ round.fullName + ' ' + round.roundLabel }}</p>
                </div>
                <div class="level-item">
                  <p class="subtitle has-text-info">{{ round.status }}</p>

                </div>
                <div class="level-item">
                  <div v-if="!round.isTournament || (round.isTournament && tournament.isOkToStartRound(round.roundNum))" class="buttons">
                    <template v-if="round.isScheduled">
                      <b-button type="is-primary" @click="startRound()">Start Round</b-button>
                    </template>
                    <template v-else-if="round.isInProgress">
                      <b-button type="is-primary" @click="stopRound()">Stop Round</b-button>
                      <b-button type="is-primary" @click="completeRound()">Complete Round</b-button>
                    </template>
                    <template v-else-if="round.isCompleted">
                      <b-button type="is-primary" @click="restartRound()">Restart Round</b-button>
                      <b-button type="is-primary" @click="postRound()">Post Round</b-button>
  
                    </template>
                    <template v-else-if="round.isPosted">
                      <b-button type="is-primary" @click="unPostRound()">Unpost Round</b-button>
                      <b-button @click="showSendResultsEmail=true">Send Results Email...</b-button>
   
                    </template>
      
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="level-item">
                  <a class="is-size-5" @click="goBack()">{{ round.name }}</a>
                </div>
                <div class="level-item">
                  <p class="subtitle">{{ round.roundLabel + ' (' + round.status + ')' }}</p>
                </div>
              </template>
                
             

            </div>
            <div v-if="isAdmin" class="level-right">
              
              <div class="level-item">
                <b-tooltip v-if="round.isInProgress" label="Send Message" position="is-left">
                  <a @click="showSendMessage=true"> 
                    <b-icon icon="message-alert-outline" />
                  </a>
                </b-tooltip>
               
              </div>
            </div>
          </div>

          <b-tabs
            v-model="activeTab"
            >
            <b-tab-item 
              label="Pairings"
            
              icon="account-multiple">
              <round-pairings />
            </b-tab-item>
            <b-tab-item 
              label="Games"
              icon="view-dashboard">
                <games 
                  :baseObj="round" 
                  :numPlayers="round===null?0:round.teeTimes.scoresArray().length" 
                  :readOnly="readOnly" 
                />
            </b-tab-item>
            <b-tab-item 
              label="Scores"
              icon="table-edit">
                <round-scores />
            </b-tab-item>
            <b-tab-item 
              label="Leaderboard"
              icon="google-analytics">
                <round-leaderboard  />
            </b-tab-item>
            <b-tab-item 
              v-if="round.betOnGames" 
              label="Payouts"
              icon="currency-usd">
              <round-payouts  />
            </b-tab-item>
            <b-tab-item 
              label="Settings"
              icon="settings">
              <round-settings
                :round="round"
                :scoresArray="scoresArray"
             
                @show-send-results-email="showSendResultsEmail=true"
                @start-round="startRound"
                @restart-round="restartRound"
                @complete-round="completeRound"
                @post-round="postRound"
                @unpost-round="unPostRound"
                @delete-round="deleteRound"
              
              />
            </b-tab-item>

          </b-tabs>
        </template>
      
        

      </div>
    </div>
    <b-modal  :active.sync="showAbout" >
      <about />
    </b-modal>
    <b-modal has-modal-card :active.sync="showAccount" >
      <account />
    </b-modal>
    <b-modal 
      :active.sync="showSendResultsEmail" 
      has-modal-card :full-screen="isMobile" 
      style="z-index:100;" 
    >
      <send-results-email :groupEmails="groupEmails" :roundEmails="roundEmails" @saveModal="sendResultsEmail" />
    </b-modal>
    <b-modal :active.sync="showSendMessage">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Send Message to Group</p>
        </header>
        <section class="modal-card-body">
          <b-field horizontal label="Send to Group">
            <b-select v-model="msgGroupNum">
              <option :value="0">All</option>
              <option v-for="(teeTime, index) in round.teeTimes.aTeeTimes" :key="index" :value="teeTime.groupNum">{{teeTime.groupNum}}</option>
             
            </b-select>
          </b-field>
          <b-input
            v-model="groupMsg"
            size="is-medium"
            placeholder="Type in message">
          </b-input>
        </section>
        <footer class="modal-card-foot" style="justify-content: flex-end;">
          <b-button @click="showSendMessage=false">Cancel</b-button>
          <b-button type="is-primary" @click="sendMessage()">Send</b-button>
        </footer>
      </div>
    
     
    </b-modal>
 
  </div>
</template>

<script>

import Pairings from '../../components/Round/Pairings'
import Scores from '../../components/Round/Scores'
import Leaderboard from '../../components/Round/Leaderboard'
import Settings from '../../components/Round/Settings'
import Payouts from '../../components/Round/Payouts'
import Games from '../../components/Games/Games'
import About from '../../components/Dialogs/About'
import Account from '../../components/Dialogs/Account'
import SelectCourse from '../../components/Course/SelectCourse'

import SendResultsEmail from '../../components/Dialogs/SendResults'

import Toolbar from '../../components/Shared/toolbar'

import DBUpdate from '../../helpers/dbUpdate'
import GameDefs from '../../helpers/gameDefs'

import { mapGetters } from 'vuex'
import Tournament from '../../models/tournament'


export default {
  data () {
    return {

      itemSelected: 'pairings',

      title: 'Pairings',
   
      showAbout: false,
      showAccount: false,
      showSendResultsEmail: false,
      showSendMessage: false,
      groupMsg: '',
      msgGroupNum: 0,
    
      gameDefsObj: new GameDefs(),

      showMsg: false,
      tabs: {
        pairings: {
          title: 'Pairings',
          tab: 0
        },
        games: {
          title: 'Games',
          tab: 1
        },
        scores: {
          title: 'Scores',
          tab: 2
        },
        leaderboard: {
          title: 'Leaderboard',
          tab: 3
        },
        payouts: {
          title: 'Payouts',
          tab: 4
        },
        settings: {
          title: 'Settings',
          tab: 5
        }
      }
    }
  },

  mounted() {
  
   
    
  },
 
  components: {
 
    'round-pairings': Pairings,
    'round-scores': Scores,
    'round-leaderboard': Leaderboard,
    'round-payouts': Payouts,
    'round-settings': Settings,
    'games': Games,
    'toolbar': Toolbar,
    'about': About,
    'account': Account,
    'select-course': SelectCourse,
 
    'send-results-email': SendResultsEmail
  },
  created () {
   
    if (this.round.isInProgress ) {
      this.selectTab ('scores')
     
    }
    else if (this.initialTab !== '' && this.initialTab !== undefined) {
      this.selectTab (this.initialTab)
    }
   
   
  },

   props: {
    initialTab: {
      type: String,
      default: ''
    }
  },

  watch: {
    round: {
      handler: function (val, oldVal) {
        if (val === null) { // round has been deleted so go back to home page
          this.$router.replace('/')
        }
      }
    },
    'round.games.aGames': {
      handler: function (val, oldVal) {
       
        if (val.length !== oldVal.length) { // if game is added or removed from Games
          const game = this.round.games.get(this.selectedGameId) // make sure selected game is stil in list
       
          if (game === undefined) {
            if (this.round.games.size === 0) {
              this.selectedGameId = ''
            }
            else {
              this.selectedGameId = this.round.games.aGames[0].id // if not, set selected game to first in list
            }
          }
        }
       
       
      }
    },
    'round.msg' : {
      handler: function (val, oldVal) {
        if (val !== oldVal && this.round.isInProgress) {
          if (this.round.msgGroupNum === 0) {
            this.showMsg = true
          }
          else {
            const playerScore = this.scoresArray.find(s => s.playerDocId === this.playerDocId)

            if (playerScore !== undefined) {
              this.activeTeeTime = this.round.teeTimes.getTeeTimeByScoreDocId(playerScore.docId)
              this.showMsg = this.activeTeeTime.groupNum === this.round.msgGroupNum
            }
          }
        }
      }
    }
  },
  
  computed: {
    ...mapGetters('round', {
      round: 'round',
      scores: 'scores', 
      payOuts: 'payOuts',
      leaderBoards: 'leaderBoards',
      errorMsg: 'errorMsg',
      from: 'from'
    }),
    ...mapGetters('group', ['group','playersArray']),
    error: {
      set (newValue) {
        if (!newValue) {
          this.$store.commit('round/errorMsg','')
        }
      },
      get () {
        return this.errorMsg.length > 0
      }
    },
    selectedGameId: {
      get () {
        return this.$store.getters['round/selectedGameId']
      },
      set (val) {
        this.$store.commit('round/selectedGameId', val)
      }
    },
    activeTab: {
      get () {
        return this.$store.getters['round/tab']
      },
      set (val) {
        this.$store.commit('round/tab', val)
      }
    },
    isAdmin () {
      return this.$store.getters['user/isAdmin']
    },
    readOnly () {
      return this.round.isPosted || !this.isAdmin
    },
    user () {
      return this.$store.getters['user/user']
    },
    isGuest () {
      return this.$store.getters['user/isGuest']
    },
    playerTees () {
      return this.round.course.getPlayerTees()
    },
  
    gameDefs () {
      return this.gameDefsObj.gameDefs
    },
    groupEmails () {
     
      return this.playersArray.filter(player => player.type==='Active' && player.email.length>0).map(player => player.email).sort().join(', ')
    },
    roundEmails () {
      if (this.scores === null) {
        console.log('scores=null')
        return []
      }
      return this.scores.aScores.filter(score => score.email.length>0).map(score => score.email).sort().join(', ')
 
    },
    playerDocId () {
      return this.$store.getters['user/playerDocId']
    },
    scoresArray () {
      let scoresArray
      if (this.round === null) {
        scoresArray = []
      }
      else {
        scoresArray = this.round.teeTimes.scoresArray()
      }
      return scoresArray
    },
    tournament () {
      let tournament = this.$store.getters['tournament/tournament']
      if (tournament === null) tournament = new Tournament()
      return tournament
    }
    
  },

  methods: {
    selectTab (tabName) {
     
      if (tabName === 'group') {
        this.goBack()
      }
      else if (tabName === 'about') {
        this.showAbout = true
      }
      else if (tabName === 'account') {
        this.showAccount = true
      }
      else {
        this.itemSelected = tabName
        this.title = this.tabs[tabName].title
        this.activeTab = this.tabs[tabName].tab
      }
      
    },
    goBack () {
      
      let path = '/group'
      if (this.from === 'user') path = '/user'
      if (this.from === 'tournament') path = '/group/tournament'
   
      this.$router.push(path)
    },

   

    async startRound () {
      
      if (this.round.games.hasTeamGame) {
        if (this.round.games.hasUnassignedBlindDraws()) {
           this.$buefy.dialog.alert({
                title: 'Start Round',
                message: 'Blind draws must be assigned before starting the round!',
                confirmText: 'OK'
            })
        }
        else {
          const roundUpdate = new DBUpdate(this.round.docId, { status: 'In Progress' })
          await this.$store.dispatch('round/saveValues', roundUpdate)
        }
      }
      else {
        const roundUpdate = new DBUpdate(this.round.docId, { status: 'In Progress' })
      
        await this.$store.dispatch('round/saveValues', roundUpdate)
      }
      
    },

    async restartRound () {
      const roundUpdate = new DBUpdate(this.round.docId, { status: 'In Progress' })
      await this.$store.dispatch('round/saveValues', roundUpdate)
    },

    async stopRound () {
      const roundUpdate = new DBUpdate(this.round.docId, { status: 'Scheduled' })
      await this.$store.dispatch('round/saveValues', roundUpdate)
    },

    async completeRound () {
      if (this.round.isComplete) {
        const roundUpdate = new DBUpdate(this.round.docId, { status: 'Completed' })
        await this.$store.dispatch('round/saveValues', roundUpdate)
      
        this.$store.commit('round/updateLeaderboards')
      }
      else {
        let missingScoresName = ''
        for (let score of this.scoresArray) {
          if (!score.isRoundComplete(18)) {
            missingScoresName = score.name
            break
          }
        }
        this.$buefy.dialog.alert({
            title: 'Missing Scores',
            message: 'Unable to complete round because scores are missing for ' + missingScoresName,
            confirmText: 'OK'
        })
      }
  
   
    },


    async postRound () {

      await this.$store.dispatch('round/post')

      if (!this.error) {
        this.$buefy.toast.open({
          message: 'Round Posted',
          type: 'is-success'
        })
      }
    },
    async unPostRound () {

      await this.$store.dispatch('round/unpost')
      
      if (!this.error) {
        this.$buefy.toast.open({
          message: 'Round unposted',
          type: 'is-success'
        })
      }
    },
    deleteRound() {
      if (this.round.isPosted) {
        this.$buefy.dialog.alert('This round has been posted. Unpost to delete')
      }
      else {
        this.$buefy.dialog.confirm({
          message: 'Do you really want to delete this round?',
          onConfirm: async () =>  {
            await this.$store.dispatch("group/deleteRound", this.round)
            this.goBack()
          }
        })
      }
     
    
    },


    

   
    async sendResultsEmail (emailInfo) {
      emailInfo.msg = emailInfo.msg.split('\n')
      // emailInfo.from = this.user.emailAddress
      emailInfo.from = this.user.emailAddressObj
     
      await this.$store.dispatch("group/sendRoundResults", {round: this.round, payOuts: this.payOuts, emailInfo: emailInfo})
    },

    async sendMessage () {
      const roundUpdate = new DBUpdate(this.round.docId, {msg: this.groupMsg, msgGroupNum: this.msgGroupNum})
      await this.$store.dispatch('round/saveValues', roundUpdate)
     
      this.showSendMessage=false
      this.groupMsg = ''
    }
  
    
  }

}

</script>

<style>
.header {
   position:fixed;
   width:100%;
   z-index:50;
   background-color:white;
   margin: 0 0 0 0;
}
.header-level {
  margin-left: 20px !important;
  margin-top:10px;
  padding-right:20px;
  margin-bottom:0px !important;
}
.header-level2 {
  margin-left:20px;
  margin-bottom:4px;
}


</style>


