<template>
  <div>
    <toolbar @selected="selectTab"/>

    <b-notification :active.sync="error">{{ errorMsg }}</b-notification>
    <div class="section">
      <template v-if="isMobile">
        <b-field grouped>
          <b-input
            v-model="searchByName"
            expanded
            placeholder="Search for courses..."
            icon="magnify"
        
            type="search"
            style="width:300px"
            @keyup.enter.native="searchCourses"
          />
          <p class="control">
            <b-button  @click="searchCourses">Search</b-button>
          </p>
        </b-field>
         <b-button  v-if="isAdmin" @click="addCourse">Add Course</b-button>
      
      </template> 
      <template v-else>
        <p class="title is-size-5 has-margin-bottom-10">Courses</p>
      
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <b-field grouped>
                <b-input
                  v-model="searchByName"
                  expanded
                  placeholder="Search for courses..."
                  icon="magnify"
              
                  type="search"
                  style="width:300px"
                  @keyup.enter.native="searchCourses"
                />
                <p class="control">
                  <b-button  @click="searchCourses">Search</b-button>
                </p>
              </b-field>
              
            </div>
            <div class="level-item">
              <p>Results limited to 100 courses</p>
            </div>
          </div>
          <template v-if="isAdmin">
            <div v-if="isMobile" class="level is-mobile has-margin-top-10">
              <div class="level-left">
                <div class="level-item">
                  <b-button  v-if="!isMobile" @click="addTestData">Add Test Data</b-button>
                  <b-button  @click="addCourse">Add Course</b-button>
                </div>
              </div>
            </div>
            <div v-else class="level-right">
              <div class="level-item buttons">
                <b-button  @click="addTestData">Add Test Data</b-button>
                <b-button  @click="addCourse">Add Course</b-button>
              </div>
            </div>
          </template>
        </div>
        
      </template>
      <b-table
          :data="queryResults"
          :loading="loading"
          detailed
          :mobile-cards="false"
          detail-key="docId"
        >
       
            <template v-if="isMobile">
              <b-table-column cell-class="vert-middle" field="name" label="Name" v-slot="props">{{ props.row.name + ' - ' + props.row.location }}</b-table-column>
            </template>
            <template v-else>
              <b-table-column cell-class="vert-middle" field="name" label="Name" v-slot="props">{{ props.row.name }}</b-table-column>
              <b-table-column cell-class="vert-middle" field="location" label="Location" v-slot="props">{{ props.row.location }}</b-table-column>
            </template>
            <b-table-column cell-class="vert-middle" v-slot="props" width="120">
            
              <b-button type="is-primary" @click="editCourse(props.row)">Edit</b-button>
              
            </b-table-column>
          
       
          <template slot="detail" slot-scope="props">
            <tees :tees="props.row.tees.tees"/>
          </template>
        </b-table>
      
    </div>

    <b-modal  :active.sync="showAbout" >
      <about />
    </b-modal>
    <b-modal has-modal-card :active.sync="showAccount" >
      <account />
    </b-modal>
    

  </div>
</template>

<script>
import Tees from "../../components/Course/Tees"
import CourseController from "../../controllers/course"
import Toolbar from '../../components/Shared/toolbar'
import About from '../../components/Dialogs/About'
import Account from '../../components/Dialogs/Account'


export default {
  data() {
    return {
      searchByName: "",
      loading: false,
      queryLimit: 100,
      defaultSortOrder: "asc",
      showAbout: false,
      showAccount: false
  
    }
  },
  components: {
    tees: Tees,
    'toolbar': Toolbar,
    'about': About,
    'account': Account
  },

  mounted() {


    const course = this.$store.getters["course/course"]

    if (course) {
      const index = this.queryResults.findIndex(item => item.docId === course.docId)
      if (index === -1) {
        this.queryResults.unshift(course)
      } else {
        this.queryResults[index] = course
      }
      this.$store.commit("course/clear")
    }
  },

  computed: {
    queryResults () {
      return this.$store.getters["course/queryResults"]
    },
    errorMsg() {
      return this.$store.getters["course/errorMsg"]
    },
    error: {
      set(newValue) {
        if (!newValue) {
          this.$store.commit("course/errorMsg", "")
        }
      },
      get() {
        return this.errorMsg.length > 0
      }
    },
    isAdmin () {
      return this.$store.getters['user/isAdmin']
    },
  },
  methods: {
    selectTab (tabName) {
      
    
      if (tabName === 'about') {
        this.showAbout = true
      }
      else if (tabName === 'account') {
        this.showAccount= true
      }
      
      
    },
    async searchCourses() {
      this.loading = true;
      const search = {
        searchValue: this.searchByName,
        queryLimit: this.queryLimit
      };
      const response = await CourseController.query(search);
      if (response.errorMsg.length > 0) {
        this.error = true;
        this.errorMsg = response.errorMsg
        this.$store.commit('course/queryResults', [])
      
        this.total = 0
      } else {
        this.error = false
        this.$store.commit('course/queryResults', response.selectedItems)
      }
      this.loading = false;
    },
    addCourse() {
    
      this.$store.commit("course/setCaller", "/courses")
      this.$router.push("/courses/add")
    },
    editCourse(course) {
   
      this.$store.commit("course/course", course)
      this.$store.commit("course/setCaller", "/courses")
      this.$router.push("/courses/edit")
    },

   

    async addTestData() {
      this.loading = true;
      const response = await CourseController.loadTestData();
      if (response.errorMsg.length > 0) {
        this.error = true;
        this.errorMsg = response.errorMsg;
      } else {
        this.error = false;
      }
      this.loading = false;
    }
  }
};
</script>

<style>
 .vert-middle {
  vertical-align: middle !important;
}
</style>
