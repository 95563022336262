
import GroupController from '../controllers/group'
import RoundController from '../controllers/round'
import TournamentController from '../controllers/tournament'

import Round from '../models/round'
import Rounds from '../models/rounds'
import Tournaments from '../models/tournaments'
import Players from '../models/players'

import router from '../router'
import { sortArrayObj, roundTo, uniqueArray } from '../lib'
import dayjs from 'dayjs'
import Season from '../models/season';

import Tournament from '../models/tournament'

function sumScoreResult (playerDocId, name, uniqueGames, scoresArray) {
  const sumScore = {
    playerDocId: playerDocId,
    name: name,
    numRounds: scoresArray.length,
    totalWagered: 0,
    netWinnings: 0,
    payOut: {},
    avgScore: {},
    count: {}
  }
  let totalPayout = 0
 
  for (let gameObj of uniqueGames) {
  
    sumScore.payOut[gameObj.name] = 0
  
    if (gameObj.prop === 'stroke') {
      sumScore.avgScore[gameObj.name] = 0
    }
    else if (gameObj.prop === 'skins' || gameObj.prop === 'kp' || gameObj.prop === 'dots') {
      sumScore.count[gameObj.name] = 0
    }
    
  }
  let numScoredRounds = 0
  for (let score of scoresArray) {
    totalPayout += score.totalPayout
    
    sumScore.totalWagered += score.totalBet
   

    if (!score.wd) {
      numScoredRounds++
      
      for (let gameObj of score.games) {
        if (gameObj.id in score.payOut) sumScore.payOut[gameObj.name] = sumScore.payOut[gameObj.name] + score.payOut[gameObj.id]
        if (gameObj.prop === 'stroke' && gameObj.side === 'full18') {
          sumScore.avgScore[gameObj.name] = sumScore.avgScore[gameObj.name] + score.netScore.full18
        }
        else if (gameObj.prop === 'stroke' && gameObj.side === 'front'){
          if (score.netScore.front === '') score.netScore.front = 0
          sumScore.avgScore[gameObj.name] = sumScore.avgScore[gameObj.name] + score.netScore.front
          
        }
        else if (gameObj.prop === 'stroke' && gameObj.side === 'back'){
          if (score.netScore.back === '') score.netScore.back = 0
          sumScore.avgScore[gameObj.name] = sumScore.avgScore[gameObj.name] + score.netScore.back
        }
        else if (gameObj.prop === 'skins') {
          sumScore.count[gameObj.name] = sumScore.count[gameObj.name] + score.skinsCount
        }
        else if (gameObj.prop === 'kp') {
          sumScore.count[gameObj.name] = sumScore.count[gameObj.name] + score.kpCount
        }
        else if (gameObj.prop === 'dots') {
          sumScore.count[gameObj.name] = sumScore.count[gameObj.name] + score.dotsCount
        }

      }
    }
  }

  for (let gameObj of uniqueGames) {
    if (gameObj.prop === 'stroke') {
      sumScore.avgScore[gameObj.name] = roundTo(sumScore.avgScore[gameObj.name] / numScoredRounds,1)
    }
  
    sumScore.payOut[gameObj.name] = roundTo(sumScore.payOut[gameObj.name], 0)
  }
  sumScore.netWinnings = totalPayout - sumScore.totalWagered
  if (sumScore.numRounds === 0) {
    sumScore.netPerRound = 0
  }
  else {
    sumScore.netPerRound = roundTo(sumScore.netWinnings / sumScore.numRounds, 2)
  }


  return sumScore
}


export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: {
    group: null,
    rounds: new Rounds(),
    tournaments: new Tournaments(),
    players: new Players(),
    tab: 0,
    errorMsg: '',

    unSubOnRounds: null,
    unSubOnGroup: null,
    unSubOnPlayers: null,
    unSubOnTournaments: null,
 
    seasonStandings: [],
    standingsGames: [],
    currentSeasonStandings: new Season(),
    currentSeasonRounds: new Season(),

    isLoadingRounds: false,
    isLoadingTournaments: false,
    isLoadingStandings: false
  },
  // -----------------------------------------------------------------
  getters: {
    group (state) {
      return state.group
    },
    groupName (state) {
      let groupName = ''
      if (state.group) groupName = state.group.name
      return groupName
    },
    rounds (state) {
      return state.rounds
    },
    tournaments (state) {
      return state.tournaments
    },
    players (state) {
      return state.players
    },
   
    errorMsg (state) {
      return state.errorMsg
    },
    tab (state) {
      return state.tab
    },
    playersArray (state) {
      if (state.players) {
        return state.players.aPlayers
      }
      else {
        return []
      }
      
    },
    seasonStandings(state) {
      return state.seasonStandings
    },
    currentSeasonStandings (state) {
      return state.currentSeasonStandings
    },
    standingsGames (state) {
      return state.standingsGames
    },
    currentSeasonRounds (state) {
      return state.currentSeasonRounds
    },
    isLoadingRounds (state) {
      return state.isLoadingRounds
    },
    isLoadingTournaments (state) {
      return state.isLoadingTournaments
    },
    isLoadingStandings (state) {
      return state.isLoadingStandings
    }
   
    
  },
  // -----------------------------------------------------------------
  mutations: {
   
    errorMsg (state, msg) {
      state.errorMsg = msg
    },
    tab (state, tab) {
      state.tab = tab
    },
    group (state, group) {
      state.group = group
    },
    updateGroup (state, groupDoc) {
     
      if (state.group === null) {
        group = new Group()
        group.docId = groupDoc.id
        state.group = group
      }
     
      state.group.setData(groupDoc.data())
    },
    initRounds (state) {
      state.rounds = new Rounds()
    },
    round (state, round) {
      const index = state.rounds.aRounds.findIndex(r => r.docId === round.docId)

      state.rounds.aRounds[index] = round.copy()
    },
    rounds (state, rounds) {
      state.rounds = rounds
    },
    initTournaments (state) {
      state.tournaments = new Tournaments()
    },
    tournaments (state, tournaments) {
      state.tournaments = tournaments
    },
    players (state, players) {
      state.players = players
    },
    initPlayers (state) {
      state.players = new Players()
    },
    clear (state) {

      state.group = null
      state.rounds = new Rounds()
      state.tournaments = new Tournaments()
      state.players = new Players()
      state.unSubOnRounds = null
      state.unSubOnGroup = null
      state.unSubOnPlayers = null
      state.unSubOnTournaments = null
    },

    // addPlayer (state, player) {
    //   state.players.add(player)
    // },
    // deletePlayer (state, playerDocId) {
    //   state.players.delete(playerDocId)
    // },
  
   
    updateRoundStatus (state, update) {
      const index = state.rounds.aRounds.findIndex(r => r.docId === update.docId)
      if (index >= 0) state.rounds.aRounds[index].status = update.status
    },
    addRoundData (state, doc) {
      let round = state.rounds.aRounds.find(r => r.docId === doc.id)
      if (round !== undefined) {
     
        round.setData(doc.data())
      }
      else {
        round = new Round()
        round.docId = doc.docId
        round.groupDocId = state.group.docId
        round.setData(doc.data())
        state.rounds.add(round)
      }
      
    },
    deleteRound (state, round) {
      state.rounds.delete(round)
    },
    deleteRoundByDocId (state, docId) {
      state.rounds.deleteByDocId(docId)
    },
    setRoundData (state, doc) {
      const round = state.rounds.aRounds.find(r => r.docId === doc.id)
      if (round !== undefined) {
     
        round.setData(doc.data())
      }

    },
    clearRounds (state) {
      if (state.rounds) state.rounds.clear()
    },
    clearPlayers (state) {
      if (state.players) state.players.clear()
    },
    clearTournaments (state) {
      if (state.tournaments) state.tournaments.clear()
    },
    
    subscribeRounds (state, fn) {
      state.unSubOnRounds = fn
    },
    unsubscribeRounds (state) {
      if (state.unSubOnRounds) {

        state.unSubOnRounds ()
        state.unSubOnRounds = null
      }
    },
    subscribePlayers (state, fn) {
      state.unSubOnPlayers = fn
    },
    unsubscribePlayers (state) {
      if (state.unSubOnPlayers) {

        state.unSubOnPlayers ()
        state.unSubOnPlayers = null
      }
    },
    subscribeTournaments (state, fn) {
      state.unSubOnTournaments = fn
    },
    unsubscribeTournaments (state) {
      if (state.unSubOnTournaments) {

        state.unSubOnTournaments ()
        state.unSubOnTournaments = null
      }
    },
   
    sortRounds (state) {
      state.rounds.sort()
    },
    sortTournaments (state) {
      state.tournaments.sort()
    },
    seasonStandings (state, standings) {
      state.seasonStandings = standings
    },
    standingsGames (state, games) {
      state.standingsGames = games
    },
    subscribeGroup (state, fn) {
      state.unSubOnGroup = fn
    },
    unsubscribeGroup (state) {
      if (state.unSubOnGroup) {

        state.unSubOnGroup ()
        state.unSubOnGroup = null
      }
    },
    isLoadingRounds (state, isLoading) {
      state.isLoadingRounds = isLoading
    },
    isLoadingTournaments (state, isLoading) {
      state.isLoadingTournaments = isLoading
    },
    isLoadingStandings (state, isLoading) {
      state.isLoadingStandings = isLoading
    },
    currentSeasonRounds (state, season) {
      state.currentSeasonRounds = season.copy()
    },
    currentSeasonStandings (state, season) {
      state.currentSeasonStandings = season.copy()
    }
  },
  // -----------------------------------------------------------------
  actions: {
    async getGroup (context, groupDocId) {
     
      const response = await GroupController.getGroupAll(groupDocId)
   
      if (response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
    
      } 
      else {
        context.commit('errorMsg', '')

        const latestSeason = response.group.latestSeason
      
        context.commit('group', response.group)
        context.commit('players', response.players)
        context.commit('rounds', response.rounds)
        context.commit('tournaments', response.tournaments)

        context.commit('currentSeasonRounds', latestSeason)
        context.dispatch('createSeasonStandings', latestSeason)

        GroupController.onGroup(context, groupDocId)
        GroupController.onPlayers(context, groupDocId)
        RoundController.onRounds(context, {groupDocId, season: latestSeason})
        TournamentController.onTournaments(context, {groupDocId: context.state.group.docId, season: latestSeason})
      }
    
    },

    onRounds (context, season) {
      context.commit('clearRounds')
   
      RoundController.onRounds(context, {groupDocId: context.state.group.docId, season})
    },

    onTournaments (context, season) {
      TournamentController.onTournaments(context, {groupDocId: context.state.group.docId, season})
    },

    async save(context, group) {

      const response = await GroupController.saveGroup(group)
      if (response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      } 
      else {
        context.commit('errorMsg', '')
        context.commit('group', group.copy())
      }
    },

    async saveValues (context, groupUpdate) {
    
      const response = await GroupController.updateGroupValues(groupUpdate)

      if(response.errorMsg.length > 0){
        context.commit('errorMsg', response.errorMsg)
      }
      else {
        context.commit('errorMsg', '') 
     
        groupUpdate.clear()
      }

    },
    async savePlayerValues (context, playerUpdates) {
    
      const response = await GroupController.updatePlayerValues(context.state.group.docId, playerUpdates)
      if (response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      }
      if(response.errorMsg.length > 0){
        context.commit('errorMsg', response.errorMsg)
      }
      else {
        context.commit('errorMsg', '') 
   
       
        playerUpdates.clear()
      }

    },

    async addPlayer (context, player) {
      const response = await GroupController.addPlayer(context.state.group.docId, player)
         if (response.errorMsg !== '') {
           context.commit('errorMsg', response.errorMsg)
         }
    },

    async createGroup(context, createInfo) {
      const response = await GroupController.createGroup(createInfo)
      if (response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      } 
      else {
        context.commit('errorMsg', '')
        context.commit('user/user', response.user, { root: true })
        await context.dispatch('user/loadUserGroup',null,{ root: true })

        router.push('/group')
      }
    },

    

    async createRounds (context, settings) {
     
      const group = context.state.group

      const players = context.state.players.aPlayers.filter(p => p.autoSignUp===true)

      for (let roundDate of settings.roundDates) {
        const round = new Round()

        const roundDateTime = dayjs(roundDate).add(12, 'hour').toDate()

        round.groupDocId = group.docId
        round.date = roundDateTime
        round.status = "Scheduled"
        round.courseDocId = group.courseDocId
        round.course = settings.course
        round.name = settings.roundName
        if (round.name === '') round.name = group.name
   
        round.betOnGames = group.betOnGames
        round.roundPayouts = group.roundPayouts
        round.holeByHoleScores = group.holeByHoleScores
        round.games = group.games.copy()
        round.handicap = group.handicap.copy()
   
        round.teeTimes.startTime = group.startingTime
        round.teeTimes.interval = group.interval
        
        const response = await RoundController.addRound(round)
        if (response.errorMsg.length > 0) {
          context.commit('errorMsg', response.errorMsg)
          return
        }

       
        for (let player of players) {
          const response2 = await RoundController.signUp(round, player, '')
          if (response2.errorMsg.length > 0) {
            context.commit('errorMsg', response2.errorMsg)
            return
          }
        }
       
    
       
      }
    
    },
    async scheduleTournament (context, tournamentData) {
     
      const group = context.state.group

      const tournament = new Tournament()
      
      tournament.name = tournamentData.name
      tournament.groupDocId = group.docId
      tournament.betOnGames = group.betOnGames
      tournament.games = group.games.copy()
      tournament.course = tournamentData.course
      tournament.status = 'Scheduled'

      let roundNum = 1
    
      tournamentData.roundDates.sort((a, b) => (dayjs(a).isAfter(dayjs(b)) ? 1 : -1))
    
      for (let roundDate of tournamentData.roundDates) {
        const round = new Round()
        round.roundNum = roundNum
        round.date = roundDate
        round.name = tournament.name
        round.course = tournamentData.course
   
        round.betOnGames = group.betOnGames
        round.roundPayouts = group.roundPayouts
        round.holeByHoleScores = group.holeByHoleScores
        round.games = group.games.copy()
        round.handicap = group.handicap.copy()
   
        round.teeTimes.startTime = group.startingTime
        round.teeTimes.interval = group.interval

        tournament.rounds.add (round)
    
        roundNum++
      }
      tournament.setDates()
      const response = await TournamentController.createTournament(tournament)
      if (response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      }
      else {
        await context.dispatch('tournament/open',{groupDocId: group.docId, tournamentDocId: tournament.docId},{ root: true })
     
      }
    
    },
    resetSeasonRounds(context, season) {
      const group = context.state.group

      const queryInfo = {
        groupDocId: group.docId,
        season: season
      }
     
      RoundController.onRounds(context, queryInfo)
    
    },
    async delete(context, groupDocId) {
      const response = await GroupController.deleteGroup(groupDocId)
      if (response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      } 
      else {
        context.commit('user/unsubscribePlayer',null,{root: true})
        context.commit('errorMsg', '')
        router.push('/user')
      }
    },

    async deleteRound (context, round) {
      const response = await RoundController.deleteRound(round)
      if (response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      } 
      else {
        context.commit('errorMsg', '')
      }
      context.commit('round/unsubscribeAll', null, {root: true})
      
     
    },

    async deleteTournament (context, tournament) {
      const response = await TournamentController.delete(tournament)
    
      if(response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      }
      else {
        context.commit('tournament/unsubscribeAll', null, {root: true})
        
       
      }
    },

    async deleteSeason (context, season) {
      const response = await GroupController.deleteSeason(context.state.group.docId, season)
      if (response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      } 
      else {
        context.commit('errorMsg', '')
      }
      
    },
    
    clear (context) {

      context.commit('unsubscribeGroup')
      context.commit('unsubscribeRounds')
      context.commit('unsubscribePlayers')
      context.commit('unsubscribeTournaments')
     
      context.commit('clear')
    },

    async createSeasonStandings(context, season) {
      if (season === null) season = context.state.currentSeasonStandings
      let errorMsg = ''
      context.commit('isLoadingStandings', true)

      const response = await GroupController.getPostedScoresBySeason(context.state.group, season)

      if (response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      } 
      else {

        const results = []
        const scoresArray = response.scores

        let allGames = []
        for (let score of scoresArray) {
          allGames = allGames.concat(score.games)
        }

        const uniqueGames = uniqueArray (allGames, 'name') // get list if unique games names for all scores

        // get all scores for non guests
  
        let playerDocIds = [...(new Set(scoresArray.filter(s=>s.type!=='Guest').map(({ playerDocId }) => playerDocId)))] // get unqiue list of player ids
        let scoresForPlayer

        for (let playerDocId of playerDocIds) {
          scoresForPlayer = scoresArray.filter(s => s.playerDocId===playerDocId)
          results.push(sumScoreResult(playerDocId, scoresForPlayer[0].name, uniqueGames, scoresForPlayer))
        }

         // get all scores for guests
  
        scoresForPlayer = scoresArray.filter(s => s.type==='Guest')
        if (scoresForPlayer.length > 0) results.push(sumScoreResult('', 'Guest', uniqueGames, scoresForPlayer))
    

        sortArrayObj(results, 'netWinnings', 'desc')

        context.commit('seasonStandings', results)
        context.commit('currentSeasonStandings', season)
        context.commit('standingsGames', uniqueGames)
        context.commit('isLoadingStandings', false)
      }
      return errorMsg
    },

    async sendRoundResults (context, roundInfo) {
      await GroupController.sendRoundResults(context.state.group.name, roundInfo)
    },

    async sendRegisterInvite(context, email, fromEmail) {
      await GroupController.sendRegisterInvite(context.state.group, email, fromEmail)
    },
//     importRounds (context, scoresData) {
//       console.log('scoresData', scoresData)
//       const playersArray = context.state.group.players.aPlayers
//       // create rounds first
//       const scoresData2018 = scoresData.filter(scoreData => scoreData.season==='2019')

//       console.log('scoresData2019', scoresData2018)




      
//       roundNums.forEach(roundNum => {
//         const roundScoresData = scoresData2018.filter(score => score.roundNum === roundNum)

//         if (roundScoresData.length > 0) {

//           console.log('roundScoresData', roundScoresData)
        
//           const round = new Round()

//           round.num = Number(roundNum)
//           round.date = new Date(roundScoresData[0].roundDate)

//           round.course = context.state.group.course.copy()
//           round.status = 'Completed'
//           round.numPlayers = roundScoresData.length
//           round.betOnGames = context.state.group.betOnGames
//           round.games = context.state.group.games.copy()
//           round.handicap = context.state.group.handicap.copy()


//           const scores = new Scores()
//           roundScoresData.forEach(scoreData => {
//             const score = scores.addNew()

//             score.roundNum = Number(roundNum)
//             score.roundDate = new Date(scoreData.roundDate)
//             score.season = '2019'
//             score.playerId = Number(scoreData.playerId)
//             const player = playersArray.find(p => p.id === score.playerId)
//             if (player === undefined) {
//               console.log('cant find player', score.playerId, scoreData)
//             }
//             score.name = player.name
//             score.email = player.email
//             score.type = player.type
//             score.tee = player.tee
//             score.ghinNum = player.ghinNum
        
//             score.index.full18 = Number(scoreData.index)
        
//             score.handicap.front = Number(scoreData.handicap_front)
//             score.handicap.back = Number(scoreData.handicap_back)
//             score.handicap.full18 = Number(scoreData.handicap_full18)

//             score.diff.full18 = Number(scoreData.diff)
      
//             score.score.front = Number(scoreData.score_front)
//             score.score.back = Number(scoreData.score_back)
//             score.score.full18 = Number(scoreData.score_full18)

//             score.netScore.front = Number(scoreData.netScore_front)
//             score.netScore.back = Number(scoreData.netScore_back)
//             score.netScore.full18 = Number(scoreData.netScore_full18)

//             score.payOut.strokeFront = Number(scoreData.payout_front_1st) + Number(scoreData.payout_front_2nd)
//             score.payOut.strokeBack = Number(scoreData.payout_back_1st) + Number(scoreData.payout_back_2nd)
//             score.payOut.stroke18 = Number(scoreData.payout_full18_1st) + Number(scoreData.payout_full18_2nd) + Number(scoreData.payout_full18_3rd) + Number(scoreData.payout_full18_4th) + Number(scoreData.payout_full18_5th)

//             score.payOut.skins = Number(scoreData.payout_skins)
//             score.payOut.kp = Number(scoreData.payout_kp)

//             score.skins.count = Number(scoreData.skins)
//             score.kp.count = Number(scoreData.kp)

//             score.totalBet = Number(scoreData.totalBet)
//             score.totalPayout = Number(scoreData.totalPayout)
          

//             score.exclude = scoreData.exclude === '1'

//           })
//           console.log('scores', scores)
    
//           round.scores = scores

//           console.log('round', round)

//           context.commit('round/set', round, {root: true})
//           context.dispatch('round/save', null, {root: true})
//         }
//       })
//     },
    async updateAllRounds (context) {

      const response = await GroupController.updateAllRounds(context.state.group)
      // const response = await GroupController.convertPlayers(context.state.group)
      // const response = await GroupController.addPlayerDocIds(context.state.group)
      if (response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      } else {
        context.commit('errorMsg', '')
      }
    },

    async deleteAnonymousUsers (context) {

      const response = await GroupController.deleteAnonymousUsers()
     
      if (response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      } else {
        context.commit('errorMsg', '')
      }
    },

    async execGhinScoresLookup (context) {

      const response = await GroupController.ghinScoresLookup()
    
      if (response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      } else {
        context.commit('errorMsg', '')
      }
    }
  }
}