import TeeTime from './teeTime'
import dayjs from 'dayjs'

import { shuffle, sortArrayObj, FSTimestampToDate, DateToFSTimestamp } from '../lib'
import store from '../store'
import Scores from './scores'

export default class TeeTimes {
  constructor() {
    this.initialize()
  }
  initialize () {
    this.aTeeTimes = []
    this.startTime = dayjs().hour(9).toDate()
    this.interval = 10
  }

  get startTime24 () {
    return dayjs(this.startTime).format('HH:mm')
  }

  addTeeTime (timeVal) {
  
    const teeTime = new TeeTime(timeVal)
    
    this.aTeeTimes.push(teeTime)
    teeTime.groupNum = this.aTeeTimes.length

    return teeTime
  }

  add () {
    let nextTime
    if (this.aTeeTimes.length > 0) {
      const lastTeeTime = this.aTeeTimes[this.aTeeTimes.length-1]
      nextTime = dayjs(lastTeeTime.timeVal).add(this.interval, 'm').toDate()
    }
    else {
      nextTime = this.startTime
    }
    const teeTime = new TeeTime(nextTime)
    
    this.aTeeTimes.push(teeTime)

    sortArrayObj(this.aTeeTimes,'timeVal')

    for (let i=0; i<this.aTeeTimes.length; i++) {
      this.aTeeTimes[i].groupNum = i + 1
    }


    return teeTime
  }

  insertBefore () {
    
    sortArrayObj(this.aTeeTimes,'timeVal')

    const startTime = dayjs(this.aTeeTimes[0].timeVal).subtract(this.interval, 'm').toDate()
    let teeTime
    const newTeeTime = new TeeTime()
    
    this.aTeeTimes.unshift(newTeeTime)
  

    //reset times for times after insert

    for (let i=0; i<this.aTeeTimes.length; i++) {
      teeTime = this.aTeeTimes[i]
      if (!teeTime.editTime) {
        teeTime.timeVal = dayjs(startTime).add(this.interval*i, 'm').toDate()
      }
      teeTime.groupNum = i + 1
    }
  }

  insert (teeTimeIndex) {
    
    let teeTime = this.aTeeTimes[teeTimeIndex]
    const nextTime = dayjs(teeTime.timeVal).add(this.interval, 'm').toDate()
    
    const newTeeTime = new TeeTime(nextTime)
    this.aTeeTimes.splice(teeTimeIndex+1, 0, newTeeTime)
    newTeeTime.groupNum = teeTimeIndex + 1

    //reset times for times after insert

    for (let i=teeTimeIndex+2; i<this.aTeeTimes.length; i++) {
      teeTime = this.aTeeTimes[i]
      if (!teeTime.editTime) teeTime.timeVal = dayjs(teeTime.timeVal).add(this.interval, 'm').toDate()
      teeTime.groupNum++
    }
  }

  deleteByIndex (teeTimeIndex) {
    sortArrayObj (this.aTeeTimes, 'timeVal')

    let teeTime = this.aTeeTimes[teeTimeIndex]
    
    let nextTime = null
    if (this.aTeeTimes.length > (teeTimeIndex+1)) {
      nextTime = this.aTeeTimes[teeTimeIndex+1]
    }
    this.aTeeTimes.splice(teeTimeIndex, 1)

    if (!nextTime) return

   
     //reset times for times after delete only if consectitive
    const resetTimes = (nextTime.timeVal === dayjs(teeTime.timeVal).add(this.interval, 'm').toDate())



    for (let i=teeTimeIndex; i<this.aTeeTimes.length; i++) {
      teeTime = this.aTeeTimes[i]
      if (resetTimes) teeTime.timeVal = dayjs(teeTime.timeVal).subtract(this.interval, 'm').toDate()
      teeTime.groupNum--
    }
    
  }

  sort () {
    sortArrayObj(this.aTeeTimes,'timeVal')
  }

  scoreDocIds () {
    let docIds = []
    for(let teeTime of this.aTeeTimes) {
      docIds.push(...teeTime.scoreDocIdsArray())
    }
    return docIds

  }

  countScores () {
    let count = 0
    if (this.aTeeTimes.length === 0) {
      count = store.getters['round/scoresArray'].length
    }
    else {
      for(let teeTime of this.aTeeTimes) {
        count += teeTime.countScores()
      }
    }
  
   
    return count
  }

  get scores () {
    const scores = new Scores()
    for(let teeTime of this.aTeeTimes) {
      scores.aScores.push(...teeTime.scoresArray())
    }
    return scores
  }

  assignScores (scoresArray) {
   
    for(let teeTime of this.aTeeTimes) {
      teeTime.assignScores(scoresArray)
    }
  }

  scoresArray () {
    const scores = []
    for(let teeTime of this.aTeeTimes) {
      scores.push(...teeTime.scoresArray())
    }
    return scores
  }

  scoresArraybyGroup (groupNum) {
    let scoresArray = []
    const teeTime = this.getTeeTimeByGroupNum (groupNum)
    if (teeTime) {
      scoresArray = teeTime.scoresArray()
    }
    return scoresArray
  }

  getTeeTimeByTime (timeVal) {
   
    const timeStr = dayjs(timeVal).format('h:mma')
    for (let teeTime of this.aTeeTimes) {
    
      if (teeTime.time === timeStr) {
        return teeTime
      }
    
    }
    return null
  }

  getTeeTimeByIndex (index) {
    return this.aTeeTimes[index]
  }



  getTeeTimeByScoreDocId (scoreDocId) {
    for (let teeTime of this.aTeeTimes) {
      for (let docId of teeTime.scoreDocIds) {
        if (scoreDocId === docId) {
          return teeTime
        }
      }
    }

    return new TeeTime()
  }

  hasTeeTime (scoreDocId) {
    
    for (let teeTime of this.aTeeTimes) {
      for (let docId of teeTime.scoreDocIds) {
        if (scoreDocId === docId) {
          return true
        }
      }
    }
    return false
  }

  getTeeTimeByGroupNum (groupNum) {
    let teeTime = this.aTeeTimes.find(t=>t.groupNum===groupNum)
    if (teeTime === undefined) teeTime = null

    return teeTime
   
  }

  getGroupNum (score) {
    let groupNum = 0
    for (let teeTime of this.aTeeTimes) {
      if (teeTime.assigned(score)) {
        groupNum = teeTime.groupNum
        break
      }
    }
    return groupNum
  }

  updateStartTime (newTime) {
    this.startTime = newTime
    this.resetTimes()
  }

  updateInterval (newInterval) {
    this.interval = newInterval
    this.resetTimes()
  }

  updateTime (groupNum, newTime) {
    for (let teeTime of this.aTeeTimes) {
      if (teeTime.groupNum === groupNum) {
        teeTime.timeVal = newTime
        teeTime.editTime = true
        break
      }
    }
  }

  updateStartHole (groupNum, newStartHole) {
    for (let teeTime of this.aTeeTimes) {
      if (teeTime.groupNum === groupNum) {
        teeTime.startHole = newStartHole
        break
      }
    }
  }

  getNumTimes (numPlayers) {
    let numTimes = Math.floor(numPlayers / 4)
    if (numPlayers % 4 > 0) numTimes++

    return numTimes
  }

  setNumTimes (numPlayers) {
    let numTimes = this.getNumTimes(numPlayers)
    const timesToAdd = numTimes - this.size

    if (timesToAdd > 0) {
      for (let i=0; i<timesToAdd; i++) {
        this.add()
      }
    }
    // else {
    //   let teeTime
    //   for (let i=this.size-1; i>=numTimes; i--) {
    //     teeTime = this.aTeeTimes[i]
    //     if (teeTime.scoreDocIdsArray().length === 0) {
    //       this.deleteByIndex(i)
    //     }
    //   }
    // }
  }

  resetTimes () {
    this.aTeeTimes.forEach((teeTime, index) => {
      teeTime.timeVal = dayjs(this.startTime).add(this.interval*index, 'm').toDate()
    })
  }

  isFull (teeTimeIndex) {
    const teeTime = this.aTeeTimes[teeTimeIndex]
    return teeTime.isFull()
    
  }

  unassignedScores (scoresArray) {
    const unassigned = []
    for (let score of scoresArray) {
      if (!this.assigned(score)) unassigned.push(score)
    }
    return unassigned
  }

  // assignScore (score) {
  //   for (let teeTime of this.aTeeTimes) {
  //     teeTime.assignScore (score)
  //   }
  // }

  unassignScore (scoreDocId) {
    for (let teeTime of this.aTeeTimes) {
      teeTime.unassignScore (scoreDocId)
    }
  }

  unassignAll () {
    for (let teeTime of this.aTeeTimes) {
      teeTime.unassignAll ()
    }
  }

  // assignScores (scoresArray) {
  //   for (let score of scoresArray) {
  //     for (let teeTime of this.aTeeTimes) {
  //       teeTime.assignScore (score)
  //     }
  //   }
  // }
  
  assigned (score) {
    let found = false
    for (let teeTime of this.aTeeTimes) {
      found = teeTime.assigned(score)
      if (found) break
    }

    return found
  }

  numAssigned () {
    let count = 0
    for(let teeTime of this.aTeeTimes) {
      count += teeTime.numAssigned()
    }
    return count
  }
  
  get size () {
    return this.aTeeTimes.length
  }
  

  delete (teeTime) {
    const index = this.aTeeTimes.findIndex(t => t.groupNum === teeTime.groupNum)

    this.deleteByIndex(index)

  }
  
  clear () {
    this.aTeeTimes = []
  }

  assignEmptySlot (scoresArray, dir='lr') {
    let teeTime
    let emptyInfo
    for (let score of scoresArray) {
      if (dir === 'lr') {
        emptyInfo = this.findEmptySlotLeftToRight()
      }
      else {
        emptyInfo = this.findEmptySlotTopToBottom()
      }
      if (emptyInfo !== null) {
        teeTime = this.aTeeTimes[emptyInfo.teeTimeIndex]
        teeTime.scoreDocIds[emptyInfo.slotIndex] = score.docId
      }
     
    }
  }

  findEmptySlotLeftToRight () {
    for (let [index, teeTime] of this.aTeeTimes.entries()) {
      for (let i=0; i<4; i++) {
        if (teeTime.scoreDocIds[i] === null) {
          return {
            teeTimeIndex: index,
            slotIndex: i
          }
        }
      }
    }
    return null
  }

  findEmptySlotTopToBottom () {
    for (let i=0; i<4; i++){
      for (let [index, teeTime] of this.aTeeTimes.entries()) {
       
        if (teeTime.scoreDocIds[i] === null) {
          return {
            teeTimeIndex: index,
            slotIndex: i
          }
        }
       
      }
    }
   
    return null
  }

  autoPair (type, unassignedPlayers) {

    if (type === 'player') {
      this.assignEmptySlot (unassignedPlayers)
  
    }
    if (type === 'random') {
      const players = shuffle(unassignedPlayers)
      this.assignEmptySlot (players)
    
    }
    if (type === 'abcd') {
      sortArrayObj(unassignedPlayers, 'handicap', 'asc')
    
      this.assignEmptySlot (unassignedPlayers, 'tb')
    
    }
    if (type === 'tee') {
      sortArrayObj(unassignedPlayers, 'tee', 'asc')
      this.assignEmptySlot (unassignedPlayers)
    
    }
    if (type === 'handicap') {
      sortArrayObj(unassignedPlayers, 'handicap', 'asc')
      this.assignEmptySlot (unassignedPlayers)
     
    }
   
  }
  copy () {
    const teeTimes = new TeeTimes()

    teeTimes.setObj(this.getObj())
    return teeTimes
  }


  toJSON () {
    return JSON.stringify(this.getObj(),null,2)
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  getObj () {
    return {
      startTime: new Date(Number(this.startTime)),
      interval: this.interval,
      teeTimes: this.aTeeTimes.map(teeTime => teeTime.getObj())
    }
      
  }
  setObj (data) {
    this.startTime = new Date(Number(data.startTime))
    this.interval = data.interval
    this.aTeeTimes = data.teeTimes.map(teeTimeObj => {
      const teeTime = new TeeTime()
      teeTime.setObj(teeTimeObj)
      return teeTime
    })
   
  }
  getData () {

    
    return {
      teeTimes: this.aTeeTimes.map(teeTime => teeTime.getData()),
      startTime: DateToFSTimestamp(this.startTime),
      interval: this.interval
    }
    
  }
  setData (data) {
    if (data === undefined) {
      this.initialize()
    }
    else {
      this.startTime = FSTimestampToDate(data.startTime)
      this.interval = data.interval
      this.aTeeTimes = []
      if (data.teeTimes !== undefined) {
        for (let i=0; i<data.teeTimes.length; i++) {
          const teeTime = new TeeTime()
          teeTime.setData(data.teeTimes[i])
          teeTime.groupNum = i+1
          this.aTeeTimes.push(teeTime)
        }
      }
     
    }
   
  }
}

