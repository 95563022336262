import Tournament from '../models/tournament'

import { sortArrayObj } from '../lib'

export default class Tournaments {
  constructor() {
    this.initialize()
  }
  initialize () {
    this.aTournaments = []
    this.sortField = 'date'
    this.sortDir = 'desc'
  }
  addDoc (docId, data) {
    const tournament = new Tournament()
    tournament.docId = docId
    tournament.setData(data)

    this.aTournaments.push(tournament)

    return tournament
  }
  add (tournament) {
 
    this.aTournaments.push(tournament)
  } 
  create () {
    const tournament = new Tournament()

    return tournament
  }

  get (docId) {
    const tournament = this.aTournaments.find(o => o.docId === docId)
    return tournament
  }
  getByIndex (index) {
    return this.aTournaments[index]
  }

  exists (docId) {
    const index = this.aTournaments.findIndex(o => o.docId === docId)
    return index >= 0
  }

  set (tournament) {
    const idx = this.aTournaments.findIndex(o => o.docId === tournament.docId)
    if(idx === -1) {
      this.aTournaments.push(tournament)
    }
    else {
      this.aTournaments[idx] = tournament
    }
  }
  delete (tournament) {
    let index
  
    index = this.aTournaments.findIndex(o => o.docId === tournament.docId)
    
    if (index >= 0) this.aTournaments.splice(index, 1)
  }
  deleteByDocId (docId) {
    
    const index = this.aTournaments.findIndex(o => o.docId === docId)   
    this.aTournaments.splice(index, 1)
  }
  clear () {
    this.aTournaments = []
  }
  get size () {
    return this.aTournaments.length
  }

  sort (field='', dir='') {
    if (field === '') field = this.sortField
    if (dir === '') dir = this.sortDir
    sortArrayObj (this.aTournaments, field, dir)
    this.sortField = field
    this.sortDir = dir
  }


  toJSON () {
    return JSON.stringify(this.getObj(),null,2)
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  getObj () {
    return this.aTournaments.map(t => t.getObj())
  }
  setObj (data) {
    this.aTournaments = data.map(t => {
      const tournament = new Tournament()
      tournament.setObj(t)
      return tournament
    })
  }
  getData () {
    return this.aTournaments.map(t => t.getData())
  }
  setData (data) {
    this.aTournaments = data.map(t => {
      const tournament = new Tournament()
      tournament.setData(t)
      return tournament
    })
  }
}

