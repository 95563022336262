
import RoundController from '../controllers/round'
import ScoreController from '../controllers/score'
import router from '../router'


import Scores from '../models/scores'
import TeeTimes from '../models/teeTimes'

import { createRoundLeaderboards, createPayouts, calcSkins }  from '../helpers/leaderBoards'

import DBUpdate from '../helpers/dbUpdate'
import DBUpdates from '../helpers/dbUpdates'
import HandicapCalc from '../helpers/handicapCalc'

import { sortArrayObj} from '../lib'


function updateTeamGames (games, teeTimeData) {
  if (games.hasTeamGame) {
    const updatedGames = games.copy()
    const teeTimes = new TeeTimes()
    teeTimes.setData(teeTimeData)
    const teamTimeScoreDocIds = teeTimes.scoreDocIds()
    let saveGames = false
    for (let game of updatedGames.aGames) {
      if (game.isTeamGame) {

        let teamScoreDocIds = game.teamScores.scoreDocIds()

      // add new players added to tee times
        const scoreDocIdsToAdd = teamTimeScoreDocIds.filter(docId => {
          return !teamScoreDocIds.includes(docId)
        })
        if (scoreDocIdsToAdd.length > 0) {
        
          saveGames = true
          game.teamScores.assign (scoreDocIdsToAdd)
        }

      // remove players removed from tee times
        teamScoreDocIds = game.teamScores.scoreDocIds()
        const scoreDocIdsToDelete = teamScoreDocIds.filter(docId => {
          return !teamTimeScoreDocIds.includes(docId)
        })
        if (scoreDocIdsToDelete.length > 0) {
          saveGames = true
          game.teamScores.unassign (scoreDocIdsToDelete)
        }
      }
      
    }
    const update = {}
    if (saveGames) {
      update.games = updatedGames.getData()
    }

    return update
  }
}

function postRound (round, scores, leaderBoards, payOuts, skins) {
  const scoreUpdates = new DBUpdates()

  const zeroPayout = {}
  round.games.aGames.forEach(game => {
    zeroPayout[game.id] = 0
  })
  
  const hcCalc = new HandicapCalc(round.course, round.handicap.maxHandicap)

  const roundComplete = round.isComplete(scores.aScores) // exclude handicaps for everyone is round hasn't been completed


  const frontOnly = round.games.hasStrokeGame('front') && !round.games.hasStrokeGame('full18') && !round.games.hasStrokeGame('back')
  const backOnly = round.games.hasStrokeGame('back') && !round.games.hasStrokeGame('full18') && !round.games.hasStrokeGame('front')

  const scoreGames = round.games.aGames.map (g=> {
    return {
      id: g.id,
      name: g.name,
      side: g.side,
      prop: g.prop,
      handicapFac: g.percentHandicap / 100
    }
  })
  let sc
  for (let score of scores.aScores) {
    let update = {
      roundStatus: 'Posted',
      posted: true,
      msg: '',
      pos: {},
      skins: [],
      skinsCount: 0,
      games: scoreGames
      // teeTimeRequest: ''
    }
    
    update.diff = hcCalc.calcDiffObj(score.score, score.tee, score.pcc, score.gender)

    if (!roundComplete) update.exclude = true
  
    let payOutItem = payOuts.find(p => p.playerDocId === score.playerDocId)
    if (payOutItem === undefined) {
      update.payOut = {...zeroPayout}
      update.totalPayout = 0
    }
    else {
      round.games.aGames.forEach(game => {
        const leaderBoardItems = leaderBoards[game.id]
        const leaderBoardItem = leaderBoardItems.find(item => item.docId === score.docId)
        if (leaderBoardItem !== undefined) {
          update.pos[game.id] = leaderBoardItem.pos + ''
        }
      })
      update.payOut = {...payOutItem.payOut}
      update.totalPayout = payOutItem.totalPayout

    }

    let skinsItems = skins.filter(s => s.scoreDocId === score.docId)
    if (skinsItems.length > 0) {
      sc = score.copy()
      for(let skinsItem of skinsItems) {
        sc.addSkin(skinsItem.holeNum, sc.getHoleScore(skinsItem.holeNum), sc.getHoleNetScore(skinsItem.holeNum))
      }
      update.skins = sc.skins
      update.skinsCount = sc.skinsCount
    }

    if (frontOnly) { //remove all back 9 scores
      sc = score.copy()
      sc.clearScores('back')
      update.scores = sc.scores

      update.score = sc.score
      // update.netScore = sc.netScore
      // update.toPar = sc.toPar

      update.diff.back = 0
      update.diff.full18 = update.diff.front
    }
    if (backOnly) { //remove all front 9 scores
      sc = score.copy()
      sc.clearScores('front')

      update.scores = sc.scores

      update.score = sc.score
      // update.netScore = sc.netScore
      // update.toPar = sc.toPar

      update.diff.front = 0
      update.diff.full18 = update.diff.back
    }
      
    scoreUpdates.add(score.docId, update)
  }
  const rUpdate = {
    status: 'Posted', 
    posted: true
  }
  if (!roundComplete) rUpdate.excluded = true
  const roundUpdate = new DBUpdate(round.docId, rUpdate)

  console.log('post round', roundUpdate.update, scoreUpdates.update)

  return {roundUpdate, scoreUpdates}
}



export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: {
    round: null,
    scores:  null,
   
    playerScoreDocId: '',
    leaderBoards: {},
    payOuts: [],
    skins: [],
    tab: 0,
    selectedGameId: '',
    errorMsg: '',
    unSubOnScores: null,
    unSubOnRound: null,
    from: ''
  },
  // -----------------------------------------------------------------
  getters: {

    round (state) {
      return state.round
    },
    errorMsg (state) {
      return state.errorMsg
    },
    from (state) {
      return state.from
    },
    tab (state) {
      return state.tab
    },
    selectedGameId (state) {
      return state.selectedGameId
    },
    scores (state) {
    
      return state.scores
    },
    scoresArray (state) {
      if (state.scores) {
        return state.scores.aScores
      }
      else {
        return []
      }
    },
    // scoresLoaded (state) {
    //   return state.scoresLoaded
    // },
    leaderBoards (state) {
      return state.leaderBoards
    },
   
    payOuts (state) {
      return state.payOuts
    },

    skins (state) {
      return state.skins
    },

    playerScoreDocId (state) {
      return state.playerScoreDocId
    }
    
  },
  // -----------------------------------------------------------------
  mutations: {
    initialize (state) {
      state.round = null
      state.scores = new Scores()
      state.tab = 0
      state.errorMsg = ''
console.log('init scores len=',state.scores.size)
    },
    round (state, round) {
      state.round = round
    },
    scores (state, scores) {
      state.scores = scores
    },
    from (state, from) {
      state.from = from
    },
  
    errorMsg (state, msg) {
      state.errorMsg = msg
    },
    selectedGameId (state, val) {
      state.selectedGameId = val
    },
    playerScoreDocId (state, docId) {
      state.playerScoreDocId = docId
    },

    initializeScores (state) {
      state.scores = new Scores()
    },

    clear (state) {

      state.round = null
      state.scores = null
      state.unSubOnScores = null
      state.unSubOnRound = null
    },
    tab (state, tab) {
      state.tab = tab
    },
    leaderBoards (state, leaderBoards) {
     
      state.leaderBoards = leaderBoards
      
    },
 
    payOuts (state, payOuts) {
      state.payOuts = payOuts
    },

    skins (state, skins) {
      state.skins = skins
    },

    updateRoundValues (state, roundUpdates) {
      state.round.update(roundUpdates.aUpdates[0])
    },
    updateScoreValues (state, scoreUpdates) {
      state.scores.update(scoreUpdates)
    },
    unsubscribeScores (state) {
      if (state.unSubOnScores) {

        state.unSubOnScores ()
        state.unSubOnScores = null
      }
    },
    unsubscribeRound (state) {
      if (state.unSubOnRound) {
 
        state.unSubOnRound ()
        state.unSubOnRound = null
      }
    },
    unsubscribeAll (state) {
      if (state.unSubOnScores) {

        state.unSubOnScores ()
        state.unSubOnScores = null
      }
      if (state.unSubOnRound) {
 
        state.unSubOnRound ()
        state.unSubOnRound = null
      }
    },
    subscribeScores (state, fn) {
      state.unSubOnScores = fn
    },
    subscribeRound (state, fn) {
      state.unSubOnRound = fn
    },
    updateNumPlayers (state, numPlayers) {
      state.round.numPlayers = numPlayers
    },
    addScore (state, newScore) {
      const scoreIndex = state.scores.aScores.findIndex(s => s.docId === newScore.docId)
      if (scoreIndex === -1) {
        state.scores.add(newScore)
      }
      else {
        const score = state.scores.aScores[scoreIndex]
        score.setData(newScore.getData())
      }
    },
    updateScore (state, newScore) {
      const scoreIndex = state.scores.aScores.findIndex(s => s.docId === newScore.docId)
      if (scoreIndex !== -1) {
        const score = state.scores.aScores[scoreIndex]
        score.setData(newScore.getData())
      }
    },
    deleteScore (state, docId) {
      state.scores.deleteByDocId (docId)
    },
    updateLeaderboards (state) {
      const round = state.round
      if (round) {

        const scores = round.teeTimes.scores

        if (round.games.hasGame('skins')) {
          state.skins = calcSkins(scores.aScores, round)
        }
        else {
          state.skins = []
        }
        
        state.leaderBoards = createRoundLeaderboards(round, scores, state.skins)
  
        const players = scores.aScores.map(s=>({docId: s.playerDocId, name: s.name}))
      
        const payOuts = createPayouts(round.games, players, round.roundPayouts, state.leaderBoards)

        state.payOuts = payOuts.filter(p=>p.totalPayout > 0)
      }

    }
    

   
  },
  // -----------------------------------------------------------------
  actions: {

   
    async open (context, queryInfo) {

      const response = await RoundController.getRound(queryInfo)
      if(response.errorMsg.length > 0) {
        context.commit('group/errorMsg', response.errorMsg, {root: true})
      }
      else {
        const round = response.round
        round.roundNum = queryInfo.roundNum
       
        context.commit('group/errorMsg', '', {root: true})
        
        context.commit('round', round)
        context.commit('from', queryInfo.from)
        context.commit('playerScoreDocId', queryInfo.playerScoreDocId)
        context.commit('payOuts', [])
        context.commit('leaderBoards', {})
        if (round.games.size > 0) context.commit('selectedGameId', round.games.aGames[0].id)


        const resp = await ScoreController.getRoundScores(queryInfo)

        if(resp.errorMsg.length > 0) {
          context.commit('group/errorMsg', resp.errorMsg, {root: true})
        }
        else {
          resp.scores.setRound (round)
         
          context.commit('scores',resp.scores)
          round.assignScores (resp.scores.aScores)

          round.numPlayers = resp.scores.size
       
         
          RoundController.onRound(context, queryInfo)

          ScoreController.onScores(context, queryInfo)

          const roundUpdate = new DBUpdate(round.docId)
          // if (round.teeTimes.size == 0 && round.numPlayers > 0) {
          if (round.numPlayers > 0 && round.isScheduled) {
            const numNeededTimes = round.teeTimes.getNumTimes(round.numPlayers)
            if (numNeededTimes > round.teeTimes.size) {
       
              const teeTimes = round.teeTimes.copy()
              teeTimes.setNumTimes (round.numPlayers)
              roundUpdate.add({teeTimes: teeTimes.getData()})
            }
          }

          if (roundUpdate.hasUpdate()) await context.dispatch('saveValues', roundUpdate)
        }

        if (round.isTournament && context.rootGetters['tournament/tournament'] === null) {
          await context.dispatch('tournament/open',{groupDocId: queryInfo.groupDocId, tournamentDocId: round.tournamentDocId, from: 'user'},{ root: true })
        }
      
      }

      if (router.currentRoute.path !== '/group/round') {
     
        router.push('/group/round') 
      }
    },
    async getEmails (context, roundInfo) {
      let emails = []
      if (roundInfo.roundDocId !== '') {
        const response = await RoundController.getEmails(roundInfo)
        if(response.errorMsg.length > 0) {
          context.commit('group/errorMsg', response.errorMsg, {root: true})
        }
        else {
          context.commit('group/errorMsg', '', {root: true})
       
          emails = response.emails

        }
      }
      return emails
     
    },
    async addScore (context, score) {
      const response = await ScoreController.addScore(context.state.round.groupDocId, score)
      if(response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      }
      else {
        context.commit('errorMsg', '')
      }
    },
    async addScores (context, scores) {
      const numPlayers = scores.size + context.state.scores.size
      const response = await ScoreController.addScores(context.state.round.groupDocId, scores)
      if(response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      }
      else {
        context.commit('errorMsg', '')
        const teeTimes = context.state.round.teeTimes.copy()
        const numTeeTimesBefore = teeTimes.size
        teeTimes.setNumTimes (numPlayers)
        if (teeTimes.size !== numTeeTimesBefore) {
          const roundUpdate = new DBUpdate(context.state.round.docId,{teeTimes: teeTimes.getData()})
          context.dispatch('saveValues', roundUpdate)
        }
        
      }
 
    },
    async saveScoreValues(context, scoreUpdates) {
     
      // update local scores first (don't wait for the firestore update)
      
      // scoreUpdates.aUpdates.forEach(scoreUpdate => {
      //   const score = context.state.scores.get(scoreUpdate.docId)
      //   if (score !== undefined) {
      //     for (let field in scoreUpdate.update) {
      //       score[field] = scoreUpdate.update[field]
      //     }
      //   }
      // })
      
      const response = await ScoreController.updateScoreValues (context.state.round.groupDocId, scoreUpdates)

      if(response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      }
      else {
        // context.commit('group/errorMsg', '', {root: true})
        scoreUpdates.clear()
        context.commit('errorMsg', '')
      }
    },
    async saveScore (context, score) {
      const round = context.state.round

      score.courseLabel = round.course.label
      score.roundStatus = round.status
      score.roundName = round.name

      const response = await ScoreController.updateScore (round.groupDocId, score)

      if(response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      }
      else {
        // context.commit('group/errorMsg', '', {root: true})
        context.commit('errorMsg', '')
      }

    },
    async deleteScore (context, score) {
      const response = await ScoreController.deleteScore(context.state.round.groupDocId, score)
      if(response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      }
      else {
        context.commit('errorMsg', '')
      }
    },

    async unassignTeeTime (context, scoreDocId) {
      if (context.state.round.teeTimes.assigned(scoreDocId)) {
        context.state.round.teeTimes.unassignScore(scoreDocId)
        const roundUpdate = new DBUpdate(context.state.round.docId, {teeTimes: context.state.teeTimes.getData})
        await context.dispatch('saveValues', roundUpdate)
      
      }
    },

    async save (context, round) {
    
      const response = await RoundController.updateRound(round)

      if(response.errorMsg.length > 0){
        context.commit('errorMsg', response.errorMsg)
      }
      else {
        context.commit('errorMsg', '') 
        // context.commit('round', round) // round updated via onRound listener
      }

    },
    async saveValues (context, roundUpdate) {
      const round = context.state.round
      if (roundUpdate.contains('teeTimes') && round.games.hasTeamGame) {
        roundUpdate.add (updateTeamGames (round.games, roundUpdate.getUpdate('teeTimes')) )
      }

      const response = await RoundController.updateRoundValues(round.groupDocId, roundUpdate)

      if(response.errorMsg.length > 0){
        context.commit('errorMsg', response.errorMsg)
      }
      else {
        context.commit('errorMsg', '') 
     
        roundUpdate.clear()
      }

    },

    async saveRoundsValues (context, roundUpdates) {
    
      const response = await RoundController.updateRoundsValues(round.groupDocId, roundUpdates)

      if(response.errorMsg.length > 0){
        context.commit('errorMsg', response.errorMsg)
      }
      else {
        context.commit('errorMsg', '') 
     
        roundUpdates.clear()
      }

    },

    async sumDots (context, data) {
      const scoreUpdated = data.score.copy()

      let index
      if (data.val === null) { // delete score
        scoreUpdated.removeDots(data.holeNum)
      }
      else {
        scoreUpdated.addDots(data.holeNum, data.val)
      }
      

      const update = {
        dots: scoreUpdated.dots,
        dotsCount:  scoreUpdated.dots.reduce((total, hole) => hole.number + total,0)
      }

      const scoreUpdates = new DBUpdates()

      scoreUpdates.add(data.score.docId, update)

      await context.dispatch('saveScoreValues', scoreUpdates)
     
    },

    

    // async calcSkins2 (context, holeNums=[]) {
    //   const round = context.state.round
      
    //   if (round.games.hasGame('skins')) {
       
    //     let scoreVal
    //     let handicapStrokes
    //     let par
    //     let startHole
    //     let endHole

    //     if (holeNums.length == 0) {
    //       startHole = 1
    //       endHole = 18
    //     }
    //     else {
    //       holeNums = [...new Set(holeNums)].sort((a,b) => a-b)
    //       startHole = holeNums[0]
    //       endHole = holeNums[holeNums.length-1]
    //     }
      
    //     const activeScoresCopy = round.teeTimes.scoresArray().map(s=>s.copy())
      
    //     const halfStrokes = round.games.getByProp('skins').skinsHalfStroke ? .5 : 1

    //     let updateDocIds = []

    //     for (let holeNum=startHole; holeNum<=endHole; holeNum++) {

    //       const skinsArray = []
   
    //       for (let score of activeScoresCopy) {
    //         const skinsItem = {
    //           hasSkin: false,
    //           docId: score.docId,
    //           netScore: 1000,
    //           scoreVal: 0
    //         }

    //         scoreVal = score.getHoleScore(holeNum)
    //         if (scoreVal !== null && !score.wd) {
           
    //           handicapStrokes = score.getHandicapSkinStrokesHole(holeNum, 'full18') 
    //           par = round.getHolePar(score.tee, holeNum)
    //           skinsItem.netScore = scoreVal - (handicapStrokes * halfStrokes)
    //           skinsItem.scoreVal = scoreVal
             
    //         }

    //         skinsArray.push(skinsItem)
           
    //       }

    //       if (skinsArray.length > 0) {
    //         sortArrayObj(skinsArray, 'netScore', 'asc')

    //         if (skinsArray.length === 1) {
    //           if (skinsArray[0].netScore !== 1000) skinsArray[0].hasSkin = true
    //         }
    //         else {
    //           if ( (skinsArray[0].netScore !== skinsArray[1].netScore) && skinsArray[0].netScore !== 1000) skinsArray[0].hasSkin = true
    //         }

    //         for(let skinsItem of skinsArray) {
    //           const score = activeScoresCopy.find(s=>s.docId === skinsItem.docId)
           
    //           if (skinsItem.hasSkin) {
    //             if (!score.hasSkin(holeNum)) {
            
    //               score.addSkin(holeNum, skinsItem.scoreVal, skinsItem.netScore)
    //               updateDocIds.push(skinsItem.docId)
    //             }
    //           }
    //           else {
    //             if (score.hasSkin(holeNum)) {
                
    //               score.removeSkin(holeNum)
    //               updateDocIds.push(skinsItem.docId)
    //             }
    //           }
    //         }
    //       }
    //     }
        

    //     if (updateDocIds.length > 0) {

    //       const scoreUpdates = new DBUpdates()
    //       updateDocIds = [...new Set(updateDocIds)] // get unique scores
    //       for (let docId of updateDocIds) {
    //         const score = activeScoresCopy.find(s=>s.docId === docId)
    //         scoreUpdates.add(docId, {skins: score.skins, skinsCount: score.skinsCount})
    //       }
  
    //       await context.dispatch('saveScoreValues', scoreUpdates)
    //     }

    //   }
    // },

    async sumScore (context, data) {
      const round = context.state.round
      
      const holeScores = data.score.scores.map(s=>({...s})) // make a copy of the hole scores array
     

      let index
      if (data.val === null) { // delete score
        index = holeScores.findIndex(s => s.holeNum === data.holeNum)
        if (index >= 0) holeScores.splice(index, 1)
      }
      else {
        let index = holeScores.findIndex(h => h.holeNum === data.holeNum)
        if (index == -1) {
          holeScores.push({holeNum: data.holeNum, score: data.val})
        }
        else {
          holeScores[index].score = data.val
        }
      }
      
      const update = {
        score: {
          front: holeScores.filter(s => (s.holeNum >=1 && s.holeNum <= 9)).reduce((total, holeScore) => total+holeScore.score, 0),
          back: holeScores.filter(s => (s.holeNum >=10 && s.holeNum <= 18)).reduce((total, holeScore) => total+holeScore.score, 0),
          full18: holeScores.reduce((total, holeScore) => total+holeScore.score, 0)
        },
        scores: holeScores

      }

      // if (round.games.hasGame('skins')) {
       
      //   let holesCompleted = true
      //   const halfSkins = round.games.getByProp('skins').skinsHalfStroke ? .5 : 1
   
      //   if (holesCompleted) {
     
      //     const netScores = []
      //     let hasSkin = false
      //     let skinDocId = ''
      //     let grossScore
      //     let netScore

      //     if (activeScoresArray.length === 1) {
      //       hasSkin = true
      //       skinDocId = scoreUpdated.docId
      //     }
      //     else {
      //       for (let sc of activeScoresArray) {
      //         if (sc.isHoleCompleted(data.holeNum) || scoreUpdated.docId === sc.docId) {
      //           grossScore = 0
      //           if (scoreUpdated.docId === sc.docId) {
      //             grossScore = data.val
      //           }
      //           else {
      //             grossScore = sc.getHoleScore(data.holeNum)
      //           }
      //           if (grossScore === null || grossScore === 0  || grossScore === undefined) {
      //             netScores.length = 0
      //             break // check to see if all scores for hole have been entered, if not no skin
      //           }
      //           else {
      //             const strokes = sc.getHandicapSkinStrokesHole(data.holeNum, 'full18') * halfSkins
                
      //             const netScore = grossScore - strokes
      //             netScores.push({docId: sc.docId, grossScore, netScore})
      //           }
      //         }
      //       }
      //     }
  
      //     if (netScores.length > 0) {
      //       sortArrayObj(netScores, 'netScore', 'asc')
      //       if (netScores.length === 1) {
      //         hasSkin = true
      //       }
      //       else {
      //         hasSkin = netScores[0].netScore !== netScores[1].netScore
      //       }
      //       skinDocId = netScores[0].docId
      //       grossScore = netScores[0].grossScore
      //       netScore = netScores[0].netScore
      //     }
      //     else {
      //       hasSkin = false
      //     }
          
      //     if (hasSkin) {
          
      //       for (let sc of activeScoresArray) {
              
      //         if (skinDocId === sc.docId) {
      //           if (scoreUpdated.docId === sc.docId) {
      //             if (scoreUpdated.addSkin(data.holeNum, grossScore, netScore)) {
      //               scoreUpdates.add(skinDocId, {skins: scoreUpdated.skins, skinsCount: scoreUpdated.skinsCount})
      //             }
      //           }
      //           else {
      //             if (sc.addSkin(data.holeNum, grossScore, netScore)) {
      //               scoreUpdates.add(skinDocId, {skins: sc.skins, skinsCount: sc.skinsCount})
      //             }
      //           }
       
      //         }
      //         else {
      //           if (scoreUpdated.docId === sc.docId) {
      //             if (scoreUpdated.removeSkin(data.holeNum)) {
      //               scoreUpdates.add(sc.docId, {skins: scoreUpdated.skins, skinsCount: scoreUpdated.skinsCount})
      //             }
      //           }
      //           else {
      //             if (sc.removeSkin(data.holeNum)) {
      //               scoreUpdates.add(sc.docId, {skins: sc.skins, skinsCount: sc.skinsCount})
      //             }
      //           }
      //         }            
      //       }
      //     }
      //     else {
      //       for (let sc of activeScoresArray) {
      //         if (scoreUpdated.docId === sc.docId) {
      //           if (scoreUpdated.removeSkin(data.holeNum)) {
      //             scoreUpdates.add(scoreUpdated.docId, {skins: scoreUpdated.skins, skinsCount: scoreUpdated.skinsCount})
      //           }
      //         }
      //         else {
      //           if (sc.removeSkin(data.holeNum)) {
      //             scoreUpdates.add(sc.docId, {skins: sc.skins, skinsCount: sc.skinsCount})
      //           }
      //         }
      //       }
      //     }
      //   }
      
      // }
      const scoreUpdates = new DBUpdates()
      scoreUpdates.add(data.score.docId, update)

  
      await context.dispatch('saveScoreValues', scoreUpdates)
   
    },



    async setKP (context, data) {


      const scoreUpdated = data.score.copy()
 // multiple players can have a KP...player in Last group wins
 // except if player is in same group then remove the KP if they have on   

      const scoreUpdates = new DBUpdates()
      if (data.val) {

        scoreUpdated.addKP(data.holeNum)

        scoreUpdates.add(scoreUpdated.docId, {kp: scoreUpdated.kp, kpCount: scoreUpdated.kpCount, kpDist: scoreUpdated.kpDist})
      
      }
      else {

        if (scoreUpdated.removeKP(data.holeNum)) scoreUpdates.add(scoreUpdated.docId, {kp: scoreUpdated.kp, kpCount: scoreUpdated.kpCount, kpDist: scoreUpdated.kpDist})
      }
      await context.dispatch('saveScoreValues', scoreUpdates)
   
    },

    async signUp (context, inviteInfo) {
     
      let response = await RoundController.getRound({groupDocId: inviteInfo.groupDocId,roundDocId: inviteInfo.roundDocId})
      if(response.errorMsg.length > 0) {
        context.commit('user/errorMsg', response.errorMsg, {root: true})
      }
      else {
        context.commit('user/errorMsg', '', {root: true})

        response = await RoundController.signUp(response.round, inviteInfo.player, '')
        if(response.errorMsg.length > 0) {
          context.commit('user/errorMsg', response.errorMsg, {root: true})
        }
        else {
          context.commit('user/errorMsg', '', {root: true})
        }
      }

      
    },

    async cancelSignUp (context, signUpInfo) {

      if (signUpInfo.roundDocId !== '') {
        const response = await RoundController.cancelSignUp(signUpInfo)
        if(response.errorMsg.length > 0) {
          context.commit('group/errorMsg', response.errorMsg, {root: true})
        }
        else {
          context.commit('group/errorMsg', '', {root: true})
        }

      }
     
    },
    async post (context) {
      const round = context.state.round
      const scores = context.state.scores

      const unassignedPlayers = round.teeTimes.unassignedScores(scores.aScores)
      for (let score of unassignedPlayers) {
        await context.dispatch('deleteScore', score)
      }
      const postData = postRound (round, scores, context.state.leaderBoards, context.state.payOuts, context.state.skins)

      const response = await RoundController.postRound(round.groupDocId, round.docId, postData)

      if(response.errorMsg.length > 0){
        context.commit('errorMsg', response.errorMsg)
      }
      else {
        context.commit('errorMsg', '') 
        context.dispatch('group/createSeasonStandings', null, { root: true })
        // context.commit('updateRoundValues', postInfo.roundUpdates) // round updated via onRound listener
      }

    },
    async unpost (context) {

      const round = context.state.round
    
      const response = await RoundController.unpostRound(round)

      if(response.errorMsg.length > 0){
        context.commit('errorMsg', response.errorMsg)
      }
      else {
        context.commit('errorMsg', '') 
        // context.commit('unpostRound') // round updated via onRound listener
      }

    },
    async updatePcc (context, val) {
      
      const response = await RoundController.updatePcc(context.state.round, val)

      if(response.errorMsg.length > 0){
        context.commit('errorMsg', response.errorMsg)
      }
      else {
        context.commit('errorMsg', '') 


      }
    },
    async updateExcluded (context, val) {
      
      const response = await RoundController.updateExcluded(context.state.round, val)

      if(response.errorMsg.length > 0){
        context.commit('errorMsg', response.errorMsg)
      }
      else {
        context.commit('errorMsg', '') 


      }
    },
    // async updateTeeTimeRequest (context, reqData) {
    //   const scoreUpdates = new DBUpdates()
    //   scoreUpdates.add (reqData.scoreDocId, {teeTimeRequest: reqData.value})
    //   const response = await ScoreController.updateScoreValues (context.state.round.groupDocId, scoreUpdates)

    //   if(response.errorMsg.length > 0) {
    //     context.commit('errorMsg', response.errorMsg)
    //   }
    //   else {
    //     // context.commit('group/errorMsg', '', {root: true})
    //     scoreUpdates.clear()
    //     context.commit('errorMsg', '')
    //   }
    // },
    // async setTeeTime (context, reqData) {
    //   const teeTimes = reqData.teeTimes.copy()
    //   if (teeTimes.hasTeeTime(reqData.scoreDocId)) {
    //     teeTimes.unassignScore(reqData.scoreDocId)
    //   }
    //   let teeTime = teeTimes.getTeeTimeByTime(reqData.timeVal)
    //   if (teeTime === null) {
    //     teeTime = teeTimes.addTeeTime(reqData.timeVal)
    //   }
    //   if (!teeTime.isFull) {
    //     teeTime.assignEmptySlotByDocId (reqData.scoreDocId)

    //     const roundUpdate = new DBUpdate(reqData.roundDocId, {teeTimes: teeTimes.getData()})
    //     const response = await RoundController.updateRoundValues(context.state.round.groupDocId, roundUpdate)

    //     if(response.errorMsg.length > 0){
    //       context.commit('errorMsg', response.errorMsg)
    //     }
    //     else {
    //       context.commit('errorMsg', '') 
      
    //       roundUpdate.clear()
    //     }
    //   }
      

      
    // },
    clear (context) {

      context.commit('unsubscribeAll')
    
      context.commit('clear')
      
    },

    async sendInvitesEmail (context, data) {
      const group = context.rootGetters['group/group']
      const round = context.state.round

      // round.inviteRespondByDate = data.respondByDate

      // const roundUpdate = new DBUpdate(round.docId, {inviteRespondByDate: data.respondByDate})
      
      // const response = await RoundController.updateRoundValues(round.groupDocId, roundUpdate)

      // if(response.errorMsg.length > 0){
      //   context.commit('errorMsg', response.errorMsg)
      // }
      // else {
      //   context.commit('errorMsg', '') 
     
        // roundUpdate.clear()

        const user = context.rootGetters['user/user']
      
        await RoundController.sendInvitesEmail(group, round, data.players, user.emailAddressObj)
      // }
    
     
    
    },
    
    async sendTeeTimesEmail (context, data) {
      const user = context.rootGetters['user/user']
      // await RoundController.sendTeeTimesEmail(user.emailAddress, context.state.round, data)
  
      await RoundController.sendTeeTimesEmail(user.emailAddressObj, context.state.round, data)
    }
  }
}