

export default class GameDefs {
  constructor() {
    this.gameDefs = [
      { id: 1, prop: 'stroke', name: 'Stroke Play', order: 1 },
      { id: 2, prop: 'skins', name: 'Skins', order: 2},
      { id: 3, prop: 'kp', name: 'Closest To Pin', order: 3 },
      { id: 4, prop: 'dots', name: 'Dots', order: 4 }
    ],
    this.playTypeDefs = [
      {id: 'individual', name: 'Individual'},
      {id: 'team2', name: '2 Players'},
      {id: 'team3', name: '3 Players'},
      {id: 'team4', name: '4 Players'},
      {id: 'team5', name: '5 Players'}
    ],
    this.scoreTypeDefs = [
      {id: 'stroke', name: 'Strokes'},
      {id: 'stableford', name: 'Stableford'}
    ],
    this.scoreCountDefs = [
      {id: 1, name: 'Lowest Score'},
      {id: 2, name: 'Lowest 2 Scores'},
      {id: 3, name: 'Lowest 3 Scores'},
      {id: 4, name: 'Lowest 4 Scores'}
    ],
    this.sideDefs = [ 
      {id: 'full18', name: '18'},
      {id: 'front', name: 'Front'},
      {id: 'back', name: 'Back'}
    ],
    this.holes123Defs = [
      {id: 'seq', name: 'Hole 1, Hole2, Hole3, etc', help: 'Use 1 score on first hole, 2 scores on 2nd hole, 3 scores on 3rd hole and repeat'},
      {id: 'par', name: 'Par5, Par4, Par3', help: 'Use 1 score on par 5s, 2 scores on par 4s and 3 scores on par 3s'},
      {id: '666', name: 'First 6, Middle 6, Last 6', help: 'Use 1 score on holes 1-6, 2 scores on holes 7-12 and 3 scores on holes 13-18'},

    ],
    this.formatDefs = [
      {id: 'all', name: 'Add All Scores'},
      {id: 'bball', name: 'Best Ball'},
      {id: 'scramble', name: 'Scramble'},
      {id: '123', name: '1-2-3'}
    ]
  }

  getGameName (prop) {
    let name = ''
    const gameDef = this.gameDefs.find(g=>g.prop===prop)
    if (gameDef !== undefined) {
      name = gameDef.name
    }
    return name
  }

  getSide (id) {
    let side = ''
    const gameDef = this.sideDefs.find(g=>g.id===id)
    if (gameDef !== undefined) {
      side = gameDef.name
    }
    return side
  }

  getPlayType (id) {
    let type = ''
    const typeDef = this.playTypeDefs.find(g=>g.id===id)
    if (typeDef !== undefined) {
      type = typeDef.name
    }
    return type
  }

  getScoreType (id) {
    let type = ''
    const typeDef = this.scoreTypeDefs.find(g=>g.id===id)
    if (typeDef !== undefined) {
      type = typeDef.name
    }
    return type
  }

  getFormat (id) {
    let format = ''
    const formatDef = this.formatDefs.find(g=>g.id===id)
    if (formatDef !== undefined) {
      format = formatDef.name
    }
    return format
  }


  getHoles123 (id) {
    let holes = ''
    const holesDef = this.holes123Defs.find(g=>g.id===id)
    if (holesDef !== undefined) {
      holes = holesDef.name
    }
    return holes
  }

  getScoreCount (id) {
    let count = ''
    const typeDef = this.scoreCountDefs.find(g=>g.id===id)
    if (typeDef !== undefined) {
      count = typeDef.name
    }
    return count
  }
 
}

