<template>
  <div class="modal-card" >
    <header class="modal-card-head">
      <p class="modal-card-title">Send Round Results</p>
    </header>
    <div class="modal-card-body" >
      <div class="block">
        <b-radio
          v-model="emailType"
          native-value="active"
        >
          Active Group
        </b-radio>
        <b-radio
          v-model="emailType"
          native-value="round"
        >
          Round
        </b-radio>
      </div>
      <b-field label="To">
        <b-input
          v-model="to"
          type="textarea" rows="5"/>
       
      </b-field>
      
    
      <b-field label="Email Message">
        
        <b-input
          v-model="msg"
          type="textarea"
          rows="8" />
       
      </b-field>

      <p v-if="badEmails.length>0" class="has-text-danger">
        Fix the following bad emails: {{badEmails}}
      </p>
      
    </div>
    <footer class="modal-card-foot" style="justify-content: flex-end;">
      <button class="button" type="button" @click="$parent.close()">Cancel</button>
      <button class="button is-primary" @click="sendEmail" >Send</button>
    </footer>
  </div>


</template>

<script>

import dayjs from 'dayjs'

export default {
  data () {
    return {
      emailType: 'active',
      to: this.groupEmails,
      msg: '',
      badEmails: ''
    }
  },

  props: ['groupEmails','roundEmails'],

  mounted () {
   
  },

  watch: {
    emailType: function(val) {
      this.to = (val === 'active' ? this.groupEmails : this.roundEmails)
    }
  },

  computed: {
   
  },

  methods: {
   
    sendEmail () {
      const badEmailList = []
      for (let email of this.to.split(',')) {
       
        if (!this.validateEmail(email.trim())) badEmailList.push(email)
      }
 
      if (badEmailList.length === 0) {
        this.$emit('saveModal', {to: this.to, msg: this.msg})
        this.$parent.close()
      }
      else {
        this.badEmails = badEmailList.join(',')
      }
      
    },
    validateEmail(email) {
      const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
      return reg.test(email)
    }
  }
}
</script>

<style>

</style>
