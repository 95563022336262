<template>
  <div>
    <toolbar />
    <div class="section" style="padding-top: 5px;">
      <b-notification :active.sync="error">{{ errorMsg }}</b-notification>
      <div class="level is-mobile">
        <div class="level-left">
          <div class="level-item">
            <p class="title is-4">{{ title }}</p>
          </div>
          <div class="level-item">
             <b-button @click="showCourseLookup=true">Add from GHIN Course Database</b-button>
          </div>
        </div>

        <div class="level-right">
          <div class="level-items">
            <div class="buttons">
              <b-button @click="cancelCourse">Cancel</b-button>
              <b-button type="is-primary" :disabled="$v.$invalid" @click="okCourse">OK</b-button>
            </div>
          </div>
        </div>
      </div>
      
      <template v-if="isMobile">
        <b-field label="Course Name" :message="nameError">
          <b-input autofocus size="is-capitalized" v-model="name"/>
        </b-field>
       
        <b-field grouped>
          <b-field label="Location" :message="locationError">
            <b-input size="is-capitalized" v-model="location"/>
          </b-field>
          <b-field label="State" style="width:80px;">
            <b-input v-model="state"/>
          </b-field>
        </b-field>
      
      </template>
       <template v-else>
        <div class="columns">
          <div class="column is-one-third">
            <b-field label="Course Name" :message="nameError">
              <b-input autofocus size="is-capitalized" v-model="name"/>
            </b-field>
          </div>
          <div class="column is-one-third"> 
            <b-field label="Location" :message="locationError">
              <b-input size="is-capitalized" v-model="location"/>
            </b-field>
          </div>
          <div class="column is-one-fifth">
            <b-field label="State">
              <b-input v-model="state"/>
            </b-field>
          </div>
        </div>
       </template>

      <b-button class="has-margin-bottom-10" @click="addTee">Add Tee</b-button>
     
      <template v-if="isMobile">
        <table class="table" style="width:100%;">
          <tbody>
            <tr v-for="(tee, index) in tees" :key="index" @click="openTeeDetails(tee)">
              <td width=40>
                <span class="icon" @click.stop="deleteTee(index)">
                  <i class="delete"></i>
                </span>
              </td>
              <td>
                {{tee.name + ' (' + tee.shortName + ')'}}
              </td>
            
              <td width="40">
                <a >
                  <b-icon icon="chevron-right"/>
                </a>
                
              </td>
            </tr>
          </tbody>
        </table>
     
      </template>
      <template v-else>
        <b-table :data="tees"  narrowed bordered detailed detail-key="shortName">
         
          <b-table-column field="name" label="Tee" v-slot="props">
            <b-input :ref="`teeName${props.index+1}`" size="is-capitalized" v-model="props.row.name"/>
          </b-table-column>

          <b-table-column field="shortName" label="Abbr" :width="80" v-slot="props">
            <b-input size="is-uppercase" v-model="props.row.shortName"/>
          </b-table-column>

          <b-table-column field="gender" label="Gender" :width="80" v-slot="props">
            <b-select v-model="props.row.gender">
              <option value="M">M</option>
              <option value="F">F</option>
            </b-select>
          </b-table-column>

          <b-table-column label="Rating 18" v-slot="props">
            <vue-numeric
              class="input"
              v-model="props.row.rating.full18"
              currency
              thousand-separator
              :min="0"
              :max="100"
              :precision="1"
            />
            <!--       <b-input
              v-model="props.row.rating.full18"
            v-cleave="{numeral: true, numeralIntegerScale: 2, numeralThousandsGroupStyle: "none", numeralDecimalScale: 1}" />-->
          </b-table-column>
          <b-table-column label="Slope 18" v-slot="props">
            <b-numberinput
              v-model="props.row.slope.full18"
              :controls="false"
              :min="0"
              :max="200"
            />
            <!--       <b-input
              v-model="props.row.slope.full18"
            v-cleave="{numeral: true, numeralIntegerScale: 3, numeralThousandsGroupStyle: "none", numeralDecimalScale: 0}" />-->
          </b-table-column>
          <b-table-column label="Rating Front" v-slot="props">
            <vue-numeric
              class="input"
              v-model="props.row.rating.front"
              currency
              thousand-separator
              :min="0"
              :max="100"
              :precision="1"
            />
            <!--       <b-input
              v-model="props.row.rating.front"
            v-cleave="{numeral: true, numeralIntegerScale: 2, numeralThousandsGroupStyle: "none", numeralDecimalScale: 1}" />-->
          </b-table-column>
          <b-table-column label="Slope Front" v-slot="props">
            <b-numberinput
              v-model="props.row.slope.front"
              :controls="false"
              :min="0"
              :max="200"
            />
            <!--          <b-input
              v-model="props.row.slope.front"
            v-cleave="{numeral: true, numeralIntegerScale: 3, numeralThousandsGroupStyle: "none", numeralDecimalScale: 0}" />-->
          </b-table-column>
          <b-table-column label="Rating Back" v-slot="props">
            <vue-numeric
              class="input"
              v-model="props.row.rating.back"
              currency
              thousand-separator
              :min="0"
              :max="100"
              :precision="1"
            />
            <!--       <b-input
              v-model="props.row.rating.back"
            v-cleave="{numeral: true, numeralIntegerScale: 2, numeralThousandsGroupStyle: "none", numeralDecimalScale: 1}" />-->
          </b-table-column>
          <b-table-column label="Slope Back" v-slot="props">
            <b-numberinput
              v-model="props.row.slope.back"
              :controls="false"
              :min="0"
              :max="200"
            />
          </b-table-column>
          <b-table-column label="Front Par" v-slot="props">
            <b-numberinput
              v-model="props.row.parFront"
              :controls="false"
              :min="0"
              :max="200"
            />
          </b-table-column>
          <b-table-column label="Back Par" v-slot="props">
            <b-numberinput
              v-model="props.row.parBack"
              :controls="false"
              :min="0"
              :max="200"
            />
          </b-table-column>
          <b-table-column cell-class="vert-center-middle" custom-key="delete" v-slot="props">
            <span class="icon" @click="deleteTee(props.index)">
              <i class="delete"></i>
            </span>
              
          </b-table-column>
       
          <template slot="detail" slot-scope="props">
            <div class="level mb-0">
              <div class="level-left">
                <div class="level-item">
                  <p class="control">Copy From:</p>
                </div>
                <div class="level-item">
                  <b-select v-model="copyTeeShortName" @input="value=>copyTee(props.row.shortName)">
                    <option
                      v-for="tee in selectTees(props.row.shortName)"
                      :value="tee.shortName"
                      :key="tee.shortName" >
                        {{ tee.name }}
                    </option>
                  </b-select>
                </div>
              </div>
            </div>
            <div class="b-table">
              <table class="table is-bordered is-narrow center-table">
                <thead>
                  <tr>
                    <th class="is-size-7 has-background-black has-text-white">HOLE</th>
                    <th class="is-size-7 has-background-black has-text-white" style="text-align: center" v-for="i in 9" :key="i">{{ i }}</th>
                    <th class="is-size-7 has-background-black has-text-white" style="text-align: center">OUT</th>
                    <th class="is-size-7 has-background-black has-text-white" style="text-align: center" v-for="i in 9" :key="i+9">{{ i+9 }}</th>
                    <th class="is-size-7 has-background-black has-text-white" style="text-align: center">IN</th>
                    <th class="is-size-7 has-background-black has-text-white" style="text-align: center">TOT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in props.row.card" :key="index">
                    <td class="is-size-7">{{ row["hole"] }}</td>
                    <td v-for="i in 9" :key="i">
                      <b-input
                        size="is-small text-center"
                        v-model.number="row['hole'+i]"
                        @blur="sumFront(props.row, row)"
                        v-cleave="{numeral: true, numeralIntegerScale: 3, numeralThousandsGroupStyle: 'none', numeralDecimalScale: 0}"
                      />
                    </td>
                    <td class="is-size-7" style="text-align: center">{{ row.out }}</td>
                    <td v-for="i in 9" :key="i+10">
                      <b-input
                        size="is-small text-center"
                        v-model.number="row['hole'+(i+9)]"
                        @blur="sumBack(props.row, row)"
                        v-cleave="{numeral: true, numeralIntegerScale: 3, numeralThousandsGroupStyle: 'none', numeralDecimalScale: 0}"
                      />
                    </td>
                    <td class="is-size-7" style="text-align: center">{{ row.in }}</td>
                    <td class="is-size-7" style="text-align: center">{{ row.total }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
        </b-table>
      </template>
      
    </div>
    <b-modal 
      :active.sync="showTeeDetails"
      has-modal-card 
      full-screen
      style="z-index:100;"
      :can-cancel="false">
      <div class="modal-card" style="width:auto">
        <div class="modal-card-head" @click="showTeeDetails=false">
          <div class="level is-mobile">
            <div class="level-left">
              <div class="level-item">
                <a class="mt-5" >
                  <b-icon icon='chevron-left'></b-icon>
                </a>
              </div>
              <div class="level-item">
                <p class="subtitle">{{'Edit Tee'}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-card-body" style="overflow-y: auto;">
          <b-field grouped>
            <b-field label="Tee">
              <b-input size="is-capitalized" v-model="teeDetail.name"/>
            </b-field>

            <b-field label="Abbr" >
              <b-input size="is-uppercase" style="width:60px;" v-model="teeDetail.shortName"/>
            </b-field>

            <b-field label="Gender" >
              <b-select v-model="teeDetail.gender">
                <option value="M">M</option>
                <option value="F">F</option>
              </b-select>
            </b-field>
          </b-field>
          <b-field grouped>
            <b-field label="Rating 18">
              <vue-numeric
                class="input"
                v-model="teeDetail.rating.full18"
                currency
                thousand-separator
                :min="0"
                :max="100"
                :precision="1"
                style="width:80px"
              />
              <!--       <b-input
                v-model="props.row.rating.full18"
              v-cleave="{numeral: true, numeralIntegerScale: 2, numeralThousandsGroupStyle: "none", numeralDecimalScale: 1}" />-->
            </b-field>
            <b-field label="Slope 18">
              <b-numberinput
                v-model="teeDetail.slope.full18"
                :controls="false"
                thousand-separator
                :min="0"
                :max="200"
                style="width:80px"
              />
              <!--       <b-input
                v-model="props.row.slope.full18"
              v-cleave="{numeral: true, numeralIntegerScale: 3, numeralThousandsGroupStyle: "none", numeralDecimalScale: 0}" />-->
            </b-field>
          </b-field>
          <b-field grouped>
            <b-field label="Rating Front">
              <vue-numeric
                class="input"
                v-model="teeDetail.rating.front"
                currency
                thousand-separator
                :min="0"
                :max="100"
                :precision="1"
                style="width:80px"
              />
              <!--       <b-input
                v-model="props.row.rating.front"
              v-cleave="{numeral: true, numeralIntegerScale: 2, numeralThousandsGroupStyle: "none", numeralDecimalScale: 1}" />-->
            </b-field>
            <b-field label="Slope Front">
              <b-numberinput
                v-model="teeDetail.slope.front"
                :controls="false"
                thousand-separator
                :min="0"
                :max="200"
                style="width:80px"
              />
              <!--          <b-input
                v-model="props.row.slope.front"
              v-cleave="{numeral: true, numeralIntegerScale: 3, numeralThousandsGroupStyle: "none", numeralDecimalScale: 0}" />-->
            </b-field>
          </b-field>
          <b-field grouped>
            <b-field label="Rating Back">
              <vue-numeric
                class="input"
                v-model="teeDetail.rating.back"
                currency
                thousand-separator
                :min="0"
                :max="100"
                :precision="1"
                style="width:80px"
              />
              <!--       <b-input
                v-model="props.row.rating.back"
              v-cleave="{numeral: true, numeralIntegerScale: 2, numeralThousandsGroupStyle: "none", numeralDecimalScale: 1}" />-->
            </b-field>
            <b-field label="Slope Back">
              <b-numberinput
                v-model="teeDetail.slope.back"
                :controls="false"
                :min="0"
                :max="200"
                style="width:80px"
              />
            </b-field>
          </b-field>
          <b-field grouped>
            <b-field label="Front Par">
              <b-numberinput
                v-model="teeDetail.parFront"
                :controls="false"
                :min="0"
                :max="200"
                style="width:80px"
              />
            </b-field>
            <b-field label="Back Par">
              <b-numberinput
                v-model="teeDetail.parBack"
                :controls="false"
                :min="0"
                :max="200"
                style="width:80px"
              />
            </b-field>
          </b-field>

          <table class="table" style="width:100%;">
            <tbody>
              <tr @click="showHoleDetails=true">
                <td>
                  Holes
                </td>
              
                <td width="40">
                  <a >
                    <b-icon icon="chevron-right"/>
                  </a>
                  
                </td>
              </tr>
            </tbody>
          </table>

        </div>
         
   
      </div>
       
    </b-modal>
    <b-modal 
      :active.sync="showHoleDetails"
      has-modal-card 
      full-screen
      style="z-index:100;"
      :can-cancel="false">

      <div class="modal-card">
        <div class="modal-card-head" @click="showHoleDetails=false">
          <div class="level is-mobile">
            <div class="level-left">
              <div class="level-item">
                <a class="has-margin-top-5" >
                  <b-icon icon='chevron-left'></b-icon>
                </a>
              </div>
              <div class="level-item">
                <p class="subtitle">{{teeDetail.name + ' Tee Holes'}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-card-body">  
          <div class="b-table" >
            <table class="table is-bordered is-narrow center-table">
              <tr>
                <th class="is-size-7 has-background-black has-text-white" style="text-align: center">HOLE</th>
                <th class="is-size-7 has-background-black has-text-white" style="text-align: center">PAR</th>
                <th class="is-size-7 has-background-black has-text-white" style="text-align: center">LENGTH</th>
                <th class="is-size-7 has-background-black has-text-white" style="text-align: center">HDCP</th>
              </tr>
              <tbody>
                <tr v-for="n in 18" :key="n">
                  <td class="text-center">
                    {{n}}
                  </td>
                  <td>
                    <b-input
                      size="is-small text-center"
                      v-model.number="teeDetail.par[n-1]"
                      v-cleave="{numeral: true, numeralIntegerScale: 3, numeralThousandsGroupStyle: 'none', numeralDecimalScale: 0}"
                    />
                  </td>
                  <td>
                    <b-input
                      size="is-small text-center"
                      v-model.number="teeDetail.yards[n-1]"
                      v-cleave="{numeral: true, numeralIntegerScale: 3, numeralThousandsGroupStyle: 'none', numeralDecimalScale: 0}"
                    />
                  </td>
                  <td>
                    <b-input
                      size="is-small text-center"
                      v-model.number="teeDetail.handicap[n-1]"
                      v-cleave="{numeral: true, numeralIntegerScale: 3, numeralThousandsGroupStyle: 'none', numeralDecimalScale: 0}"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            
          </div>
          
        </div>
        
      </div>
 
       
    </b-modal>

    <b-modal :active.sync="showCourseLookup" has-modal-card>
      <ghin-course-lookup  @selectCourse="selectCourse" />
    </b-modal>
  </div>
</template>

<script>

import Tee from '../../models/tee'
import Course from '../../models/course'
import Toolbar from '../../components/Shared/toolbar'
import GhinCourseLookup from '../../components/Dialogs/GhinCourseLookup'

const { capitalizeWord, scoreCardHoleColumns, sortArrayObj } = require('../../lib')

import { required } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      loading: false,
      title: '',
      docId: '',
      name: '',
      location: '',
      state: '',
      tees: [],
      holeColumns: scoreCardHoleColumns(),
      showTeeDetails: false,
      showHoleDetails: false,
      teeDetail: new Tee(),
      showCourseLookup: false,
      copyTeeShortName: ''
    }
  },

  validations: {
    name: {
      required
    },
    location: {
      required
    }
  },
  components: {
    'toolbar': Toolbar,
    'ghin-course-lookup': GhinCourseLookup
  },
  mounted() {
    const course = this.$store.getters['course/course']
    if (course) {
      this.docId = course.docId
      this.name = course.name
      this.location = course.location
      this.state = course.state
      this.tees = course.tees.copy().tees
      this.title = 'Edit Course'
    } else {
      this.title = 'Add Course'
    }
  },
  computed: {
    errorMsg() {
      return this.$store.getters['course/errorMsg']
    },
    error: {
      set(newValue) {
        if (!newValue) {
          this.$store.commit('course/errorMsg', '')
        }
      },
      get() {
        return this.errorMsg.length > 0
      }
    },
    nameError() {
      return this.$v.name.required ? '' : 'Required'
    },
    locationError() {
      return this.$v.location.required ? '' : 'Required'
    }
  },
  methods: {
    addTee() {
      const tee = new Tee()
      let baseTee = null
      if (this.tees.length > 0) {
        baseTee = this.tees[0]
      }
      tee.initializeCard(baseTee)
      this.tees.push(tee)
      if (this.isMobile) {
        this.teeDetail = tee
        this.showTeeDetails = true
      }
      else {
        this.$nextTick(() => this.$refs['teeName' + this.tees.length].focus())  
      }
        
    },
    deleteTee(index) {
      this.tees.splice(index, 1)
    },
    selectTees (shortName) {
      return this.tees.filter(t=>t.shortName !== shortName)
    },
    copyTee (shortName) {
   
      const fromTee = this.tees.find(t=>t.shortName===this.copyTeeShortName)
      const toTee = this.tees.find(t=>t.shortName===shortName)
      toTee.initializeCard(fromTee)
     
      this.copyTeeShortName = ''
    },
    sumFront(tee, cardRow) {
      if (cardRow.hole !== 'Handicap') {
        cardRow.out = 0
        for (let i = 1; i <= 9; i++) {
          cardRow.out = cardRow.out + cardRow['hole' + i]
        }
        cardRow.total = cardRow.in + cardRow.out
        if (cardRow.hole === 'Par') {
          tee.parFront = cardRow.total
        }
      }
    },
    sumBack(tee, cardRow) {
      if (cardRow.hole !== 'Handicap') {
        cardRow.in = 0
        for (let i = 10; i <= 18; i++) {
          cardRow.in = cardRow.in + cardRow['hole' + i]
        }
        cardRow.total = cardRow.in + cardRow.out
        if (cardRow.hole === 'Par') {
          tee.parBack = cardRow.in
        }
      }
    },
    cancelCourse() {
      this.$store.dispatch('course/cancel')
    },
    okCourse() {
      const course = new Course()
      course.docId = this.docId
      course.name = capitalizeWord(this.name)
      course.location = capitalizeWord(this.location)
      course.state = this.state
      this.tees.forEach(tee => {
        tee.name = capitalizeWord(tee.name)
        tee.shortName = tee.shortName.toUpperCase()
        tee.saveCard()
      })
      sortArrayObj(this.tees, 'totalYards', 'desc')
      course.tees.tees = this.tees

      this.$store.dispatch('course/save', course)

     
    },
    openTeeDetails (tee) {
      this.teeDetail = tee
      this.showTeeDetails = true
    },
    selectCourse (course) {
   
      this.name = course.name
      this.location = course.location
      this.state = course.state
      this.tees = course.tees.tees
      this.showCourseLookup = false
    }
  }
}
</script>

<style>
.center-table th {
  text-align: center;
  vertical-align: middle;
}

.center-table td {
  text-align: center;
  vertical-align: middle;
}
.text-center {
  text-align: center;
}

.vert-center-middle {
  vertical-align: middle !important;
  text-align:center !important;
}
</style>