import Group from './group'

export default class Groups {
  constructor() {
    this.initialize()
  }
  initialize () {
    this.aGroups = []
  }
  addDoc (docId, data) {
    const group = new Group()
    group.docId = docId
    group.setData(data)

    this.aGroups.push(group)

    return group
  }
  add (group) {
 
    this.aGroups.push(group)
  } 
  create () {
    const group = new Group()

    return group
  }

  get (docId) {
    const group = this.aGroupss.find(o => o.docId === docId)
    return group
  }
  getByIndex (index) {
    return this.aGroups[index]
  }
  set (group) {
    const idx = this.aGroups.findIndex(o => o.docId === group.docId)
    if(idx === -1) {
      this.aGroups.push(group)
    }
    else {
      this.aGroups[idx] = group
    }
  }
  delete (group) {
    let index
   
    index = this.aRounds.findIndex(o => o.docId === group.docId)
    
    this.aGroups.splice(index, 1)
  }
  deleteByDocId (docId) {
    
    const index = this.aGroups.findIndex(o => o.docId === docId)   
    this.aGroups.splice(index, 1)
  }
  clear () {
    this.aGroups = []
  }
  get size () {
    return this.aGroups.length
  }
  
  toJSON () {
    return JSON.stringify(this.getObj(),null,2)
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  getObj () {
    return this.aGroups.map(group => group.getObj())
  }
  setObj (data) {
    this.aGroups = data.map(groupObj => {
      const group = new Group()
      group.setObj(groupObj)
      return group
    })
  }
  getData () {
    return this.aGroups.map(group => group.getData())
  }
  setData (data) {
    this.aGroups = data.map(groupData => {
      const group = new Group()
      group.setData(groupData)
      return group
    })
  }
}

