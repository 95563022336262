import { db } from '../firebase'


import Score from '../models/score'
import Round from '../models/round'
import Tournament from '../models/tournament'
import TournamentScores from '../models/tournamentScores'
import TournamentScore from '../models/tournamentScore'

export default {
  onTournament (context, queryInfo) {
    let unsubscribe = function (){}
    try {
      context.commit('unsubscribeTournament')      
      const groupRef = db.collection('groups').doc(queryInfo.groupDocId)
      unsubscribe = groupRef.collection('tournaments').doc(queryInfo.tournamentDocId)
        .onSnapshot( async tournamentDoc => {
          
          let tournament = context.state.tournament
        
          if (tournamentDoc.exists) {
            
            if (tournament === null) {
              tournament = new Tournament()
              tournament.docId = tournamentDoc.id
              context.state.tournament = tournament
            }
      
            const gamesBeforeStr = tournament.games.toJSON()
            const roundPayoutsBefore = tournament.roundPayouts
  
            tournament.setData(tournamentDoc.data())
            tournament.setStatus()

            const gamesAfterStr = tournament.games.toJSON()
            const roundPayoutsAfter = tournament.roundPayouts
          
            if (gamesBeforeStr !== gamesAfterStr || roundPayoutsBefore !== roundPayoutsAfter) {
              context.commit('updateLeaderboards')
            }
            

          }

         


        })
        context.commit('subscribeTournament', unsubscribe) 
    }
    catch (error) {
      context.commit('errorMsg', 'onTournament tournament.js - ' + error.code + ' ' + error.message)
    }
   
  
  },
  onTournaments (context, queryInfo) {
    let unsubscribe = function (){}
    try {
      context.commit('unsubscribeTournaments')
      // context.commit('clearTournaments')
      context.commit('isLoadingTournaments',true)

      const groupRef = db.collection('groups').doc(queryInfo.groupDocId)

      unsubscribe = groupRef.collection('tournaments')
        .where('startDate','>=',queryInfo.season.fromDateTS)
        .where('startDate','<=',queryInfo.season.toDateTS)
        .orderBy('startDate','desc')
        .onSnapshot( async snapshot => {
        
          if (context.state.rounds === null) context.commit('initTournaments')
      
          for (let change of snapshot.docChanges()) {
           
            if (change.type === 'added') {
              let tournament = context.state.tournaments.aTournaments.find(t => t.docId === change.doc.id)
              if (tournament === undefined) {
                tournament = new Tournament()
                tournament.docId = change.doc.id
                tournament.groupDocId = context.state.group.docId
                context.state.tournaments.add(tournament)
              }

            }
            if (change.type === 'modified') {
              const tournament = context.state.tournaments.aTournaments.find(t => t.docId === change.doc.id)
              if (tournament !== undefined) tournament.setData(change.doc.data())
 
            }
            if (change.type === 'removed') {
              if (context.state.tournaments.exists(change.doc.id)) {
                context.state.tournaments.deleteByDocId(change.doc.id)
              }

            }
          }
       
          context.commit('sortTournaments')

          context.commit('isLoadingTournaments',false)

  
        })
      context.commit('subscribeTournaments', unsubscribe)
    }
    catch (error) {
      context.commit('errorMsg', 'onTournaments tounament.js - ' + error.code + ' ' + error.message)
    }
   
    
  },

  onRounds (context, queryInfo) {
    let unsubscribe = function (){}
    try {
      context.commit('unsubscribeRounds')
     
      const groupRef = db.collection('groups').doc(queryInfo.groupDocId)

      unsubscribe = groupRef.collection('rounds')
        .where('tournamentDocId','==',queryInfo.tournamentDocId)
        .onSnapshot( async snapshot => {
      
          const tournament = context.state.tournament

          for (let change of snapshot.docChanges()) {
            if (change.type === 'added') {

              // let round = tournament.rounds.get(change.doc.id)
              // if (round === undefined) {
              //   round = new Round()
              //   round.docId = change.doc.id
              //   round.groupDocId = tournament.groupDocId
              //   round.tournamentDocId = tournament.docId
              //   tournament.rounds.add(round)
              // }
         
              // round.setData(change.doc.data())
              context.commit('updateLeaderboards')

            }

            if (change.type === 'modified') {
              const round = tournament.rounds.get(change.doc.id)
              if (round !== undefined) {
                const gamesBeforeStr = round.games.toJSON()
                const roundPayoutsBefore = round.roundPayouts

                round.setData(change.doc.data())

                const gamesAfterStr = round.games.toJSON()
                const roundPayoutsAfter = round.roundPayouts

                if (gamesBeforeStr !== gamesAfterStr || roundPayoutsBefore !== roundPayoutsAfter) {
                  context.commit('updateLeaderboards')
                }
              }

            }

            if (change.type === 'removed') {
              if (tournament.rounds.exists(change.doc.id)) {
                tournament.rounds.deleteByDocId(change.doc.id)

                context.commit('updateLeaderboards')
              }

            }
          }
        })
      context.commit('subscribeRounds', unsubscribe)
    }
    catch (error) {
      context.commit('errorMsg', 'onRounds tournament.js - ' + error.code + ' ' + error.message)
    }
   
    
  },

  onTournamentScores (context, queryInfo) {
    let unsubscribe = function (){}
    try {
      context.commit('unsubscribeTournamentScores')
     
      const groupRef = db.collection('groups').doc(queryInfo.groupDocId)
  
      unsubscribe = groupRef.collection('tournamentScores').where('tournamentDocId','==',queryInfo.tournamentDocId)
        .onSnapshot( async snapshot => {
          const tournament = context.state.tournament
          const tournamentScores = tournament.scores
      
          for (let change of snapshot.docChanges()) {
            if (change.type === 'added') {
              const tournamentScore = tournamentScores.get(change.doc.id)
              if (tournamentScore === undefined) {
                tournamentScores.addDoc(change.doc.id, change.doc.data())
              }
              else {
                tournamentScore.setData(change.doc.data())
              }
              

            }
            if (change.type === 'modified') {
              const tournamentScore = tournamentScores.get(change.doc.id)
              if (tournamentScore !== undefined) tournamentScore.setData(change.doc.data())
 
            }
            if (change.type === 'removed') {
              if (tournamentScores.exists(change.doc.id)) {
                tournamentScores.deleteByDocId(change.doc.id)
              }
            }
          }

         
  
        })
      context.commit('subscribeTournamentScores', unsubscribe)
    }
    catch (error) {
      context.commit('errorMsg', 'onTournamentScores tournament.js - ' + error.code + ' ' + error.message)
    }
   
    
  },

  onScores (context, queryInfo) {
    let unsubscribe = function (){}
    try {
      context.commit('unsubscribeScores')

      const groupRef = db.collection('groups').doc(queryInfo.groupDocId)

      unsubscribe = groupRef.collection('scores')
        .where('tournamentDocId','==',queryInfo.tournamentDocId)
        .onSnapshot( async snapshot => {

          const scores = context.state.scores
          const tournament = context.state.tournament
        
          let score

          const doLeaderBoards = []
          
          for (let change of snapshot.docChanges()) {
            if (change.type === 'added') {
              score = scores.aScores.find(s => s.docId === change.doc.id)

              if (score === undefined) {
                score = scores.addDoc(change.doc.id, change.doc.data())
              
              }
              else {
                score.setData(change.doc.data())
              }
              const round = tournament.rounds.get(change.doc.data().roundDocId)
              score.setRound (round)

              doLeaderBoards.push(true)

            }
            if (change.type === 'modified') {
             
              score = scores.aScores.find(s => s.docId === change.doc.id)
            
              if (score === undefined) {
                throw new Error('Score not found in existing store ' + change.doc.id)
              }
        
              score.setData(change.doc.data())
              const round = tournament.rounds.get(change.doc.data().roundDocId)
              score.setRound (round)

              doLeaderBoards.push(true)
            
            }
            if (change.type === 'removed') {
  
              scores.deleteByDocId (change.doc.id)
            
              doLeaderBoards.push(true)
              
            }
          
          }
                  
          if (doLeaderBoards.every(val => val) && tournament) {
  
            context.commit('updateLeaderboards')
          }

         
          
        })
        context.commit('subscribeScores', unsubscribe)
    }
    catch (error) {
      context.commit('errorMsg', 'onScores tournament.js - ' + error.code + ' ' + error.message)
    }
    
 
  },

  async getTournaments (queryInfo) {
    const response = {
      errorMsg: ''
    }
    try {
    
      const groupRef = db.collection('groups').doc(queryInfo.groupDocId)
      const tournamentDocs = await groupRef.collection('tournaments')
        .where('startDate','>=',queryInfo.season.fromDateTS)
        .where('startDate','<=',queryInfo.season.toDateTS)
        .get()

      const tournaments = new Tournaments()
      for (let doc of tournamentDocs.docs) {
        const tournament = tournaments.addDoc(doc.id, doc.data())
        tournament.groupDocId = queryInfo.groupDocId
      }
      
      response.tournaments = tournaments
     
    }
    catch (error) {
      response.errorMsg = 'getTournaments tournaments.js - ' + error.code + ' ' + error.message
    }
    return response
  },

  async createTournament (tournament) {
    const response = {
      errorMsg: ''
    }
    try {

      const groupRef = db.collection('groups').doc(tournament.groupDocId)

      // const tournamentDoc = await groupRef.collection('tournaments').add(tournament.getData())

      // tournament.docId = tournamentDoc.id

      await db.runTransaction(async tx => {
        const tournamentRef = groupRef.collection('tournaments').doc()

        await tx.set(tournamentRef, tournament.getData())

        tournament.docId = tournamentRef.id

        const roundDocIds = []

        for (let round of tournament.rounds.aRounds) {
          round.tournamentDocId = tournament.docId
          const roundRef = groupRef.collection('rounds').doc()

          await tx.set(roundRef, round.getData())
            
          round.docId = roundRef.id
          roundDocIds.push(roundRef.id)
          
        }

        tx.update(tournamentRef,{roundDocIds})

      })
    }
    catch(error) {
      response.errorMsg = 'createTournament tournament.js - ' + error.message
    }
    return response
  },
  async updateTournament (tournament) {
    const response = {
      errorMsg: ''
    }
    try {

      const groupRef = db.collection('groups').doc(tournament.groupDocId)

      await groupRef.collection('tournaments').doc(tournament.docId).update(tournament.getData())

    }
    catch(error) {
      response.errorMsg = 'updateTournament tournament.js - ' + error.message
    }
    return response
  },
  
  
  async delete (tournament) {
    const response = {
      errorMsg: ''
    }
    try {
      await db.collection('groups')
        .doc(tournament.groupDocId)
        .collection('tournaments')
        .doc(tournament.docId)
        .delete()

    }
    catch(error) {
      response.errorMsg = 'deleteTournament tournament.js - ' + error.message
    }
    return response
  },
  async getTournament (queryInfo) {

    const response = {
      errorMsg: ''
    }
    try {
      const groupRef = db.collection('groups').doc(queryInfo.groupDocId)

      const tournamentRef = groupRef.collection('tournaments').doc(queryInfo.tournamentDocId)
      const tournamentDoc = await tournamentRef.get()

      const tournament = new Tournament()
      tournament.docId = queryInfo.tournamentDocId
      tournament.groupDocId = queryInfo.groupDocId
    
      tournament.setData(tournamentDoc.data())

      const roundDocs = await groupRef
        .collection('rounds')
        .where('tournamentDocId','==',queryInfo.tournamentDocId)
        .get()

      for (let roundDoc of roundDocs.docs) {
        const round = tournament.rounds.get(roundDoc.id)
        if (round !== undefined) {
          round.setData(roundDoc.data())
        }
      }
      tournament.rounds.setRoundNums()
      tournament.setStatus()

      const tournamentScoreDocs = await groupRef
        .collection('tournamentScores')
        .where('tournamentDocId','==',queryInfo.tournamentDocId)
        .get()

      tournament.scores = new TournamentScores()

      if (tournamentScoreDocs.size > 0) {
        for (let tournamentScoreDoc of tournamentScoreDocs.docs) {
          tournament.scores.addDoc(tournamentScoreDoc.id, tournamentScoreDoc.data())
        }
      }

      response.tournament = tournament
     
    }
    catch(error) {
      response.errorMsg = 'getTournament tournament.js - ' + error
    }
    return response
  },

  async addRounds (group, tournament, roundDates) {
    const response = {
      errorMsg: ''
    }

    try {

      const groupRef = db.collection('groups').doc(tournament.groupDocId)
      const tournamentRef = groupRef.collection('tournaments').doc(tournament.docId)
      const newRoundDocIds = []

      for (let roundDate of roundDates) {
        const round = tournament.rounds.create()

        round.tournamentDocId = tournament.docId
        round.groupDocId = tournament.groupDocId
        round.date = roundDate
        round.name = tournament.name
  
        round.course = tournament.course
      
        round.betOnGames = false
        round.roundPayouts = group.roundPayouts
        round.holeByHoleScores = group.holeByHoleScores
     
        round.handicap = group.handicap

        round.teeTimes.startTime = roundDate
        round.teeTimes.interval = group.interval

        const roundRef = groupRef.collection('rounds').doc()

        await roundRef.set(round.getData())
          
        round.docId = roundRef.id
        newRoundDocIds.push(roundRef.id)
      }
      tournament.setDates()
      tournament.setStatus()
      tournament.rounds.setRoundNums()

      await tournamentRef.update(tournament.getData())

      for (let round of tournament.rounds.aRounds) {
        const roundRef = groupRef.collection('rounds').doc(round.docId)

        await roundRef.update({roundNum: round.roundNum})
      }

      for (let roundDocId of newRoundDocIds) {

        const round = tournament.rounds.get(roundDocId)

        for (let tournamentScore of tournament.scores.aScores) {
           
          const score = new Score()

          score.setRound (round)

          score.tournamentDocId = tournament.docId
       
          score.playerDocId = tournamentScore.playerDocId
          score.name = tournamentScore.name
          score.email = tournamentScore.email
          score.type = tournamentScore.type
          score.tee = tournamentScore.tee
          score.gender = tournamentScore.gender

          score.ghinNum = tournamentScore.ghinNum
      
          score.index = tournamentScore.index
          score.indexSource = tournamentScore.indexSource,
          score.handicap = tournamentScore.handicap
       
          score.signUpDate = tournamentScore.signUpDate

          const scoreRef = groupRef.collection('scores').doc()

          await scoreRef.set(score.getData())

          score.docId = scoreRef.id
            
        }
      }
    }
    catch (error) {
      response.errorMsg = 'addRounds tournament.js - ' + error.message
    }
    return response
  },

  async addTournamentScore (tournament, player) {
    const response = {
      errorMsg: ''
    }
    try {

      const groupRef = db.collection('groups').doc(tournament.groupDocId)


      const tournamentScore = new TournamentScore()
      tournamentScore.setTournament (tournament)
      tournamentScore.setPlayer (player)

     
      const tournamentScoreDoc = await tournamentRef.collection('tournamentScores').add(tournamentScore.getData())

      tournamentScore.docId = tournamentScoreDoc.id
    
      

    }
    catch(error) {
      response.errorMsg = 'addTournamentScore tournament.js - ' + error.message
    }
    return response
  },

  async addTournamentScores (tournament, players) {
    const response = {
      errorMsg: ''
    }

    try {
      const groupRef = db.collection('groups').doc(tournament.groupDocId)
    
      for (let player of players) {
        const tournamentScore = new TournamentScore()
        tournamentScore.setTournament (tournament)
        tournamentScore.setPlayer (player)
       
        const tournamentScoreRef = await groupRef.collection('tournamentScores').add(tournamentScore.getData())
        tournamentScore.docId = tournamentScoreRef.id
        
      }
     
    }
    catch(error) {
      response.errorMsg = 'addTournamentScores tournament.js - ' + error.message
    }
    return response
  },

  async deleteTournamentScore (tournament, tournamentScore) {
    const response = {
      errorMsg: ''
    }
    try {

      const groupRef = db.collection('groups').doc(tournament.groupDocId)

      await groupRef.collection('tournamentScores').doc(tournamentScore.docId).delete()

      

    }
    catch(error) {
      response.errorMsg = 'deleteTournamentScore tournament.js - ' + error.message
    }
    return response
  },

  async signUp (queryInfo) {
    const response = {
      errorMsg: ''
    }
    try {
     
      const groupRef = db.collection('groups').doc(queryInfo.groupDocId)
     
      const tournamentScoreDocs = await groupRef.collection('tournamentScores').where('playerDocId','==',queryInfo.player.docId).get()
  
      if (tournamentScoreDocs.empty) { // add tournamentScore
        await groupRef.collection('tournamentScores').add(queryInfo.tournamentScore.getData())
      }

    
    }
    catch(error) {
      response.errorMsg = 'signUp tournament.js - ' + error.message
    }
    return response
  },
 
  async cancelSignUp (queryInfo) {
    const response = {
      errorMsg: ''
    }
    try {

      const tournamentScoreRef = db.collection('groups').doc(queryInfo.groupDocId)
        .collection('tournamentScores').doc(queryInfo.docId)

      await tournamentScoreRef.delete()
        
    }
    catch(error) {
      response.errorMsg = 'cancelSignup round.js - ' + error.message
    }
    return response
  },

  async updateValues (groupDocId, tournamentUpdate) { // dbUpdate
   
    const response = {
      errorMsg: ''
    }
    try {
      const groupRef = db.collection('groups').doc(groupDocId)

      await groupRef.collection('tournaments').doc(tournamentUpdate.docId).update(tournamentUpdate.update)
     
      
    }
    catch(error) {

      response.errorMsg = 'updateValues tournament.js - ' + error.message
    }
    return response
  },
 

  async updateTournamentScoreValues (tournament, tournamentScoreUpdates) {
    const response = {
      errorMsg: ''
    }
    try {
 
      const groupRef = db.collection('groups').doc(tournament.groupDocId)

      if (tournamentScoreUpdates.size === 1) {
        const tournamentScoreUpdate = tournamentScoreUpdates.aUpdates[0]
        await groupRef
          .collection('tournamentScores')
          .doc(tournamentScoreUpdate.docId)
          .update(tournamentScoreUpdate.update)
       
      }
      else {
        await db.runTransaction(async tx => {
          const updates = []
          tournamentScoreUpdates.aUpdates.forEach(tournamentScoreUpdate => {
            const tournamentScoreRef = groupRef.collection('tournamentScores').doc(tournamentScoreUpdate.docId)
            updates.push(tx.update(tournamentScoreRef, tournamentScoreUpdate.update))
          })
          await Promise.all(updates)
        })
      }

      
      

    }
    catch(error) {
      response.errorMsg = 'updateTournamentScoreValues tournament.js - ' + error.message
    }
    return response
  },

  async sendResults (tournamentName, tournamentInfo) {
   
    const tournament = tournamentInfo.tournament

    const sendEmail = fbFunc.httpsCallable('sendMJEmail')
    
    const winners = tournamentInfo.payOuts.map(p=>{
      return {
        name: p.name,
        totalPayout: formatCurrency(p.tournamentTotal),
        details: gamesPayoutArray(tournament.games, p)
      }
     
    })

    let toArray = typeof tournamentInfo.emailInfo.to === 'string' ? tournamentInfo.emailInfo.to.split(',') : tournamentInfo.emailInfo.to
    toArray = toArray.filter(email => email!=='').map(email=>({Email:email}))


    const emailInfo = {
      templateId: 1430525,
      to: toArray,
      // from: roundInfo.emailInfo.from,
      replyTo: tournamentInfo.emailInfo.from,
      cc: [],
      groupName: tournamentName,
      subject: `${tournament.name} Results for ${tournament.formattedDate}`,
      data: {
        header: `${tournament.name} Results for ${tournament.formattedDate}`,
        subheader: `${tournament.course.name} - ${tournament.numPlayers} Players`,
        msg: tournamentInfo.emailInfo.msg,
        winners: winners
      }
      

    }

    await sendEmail([emailInfo])
  
   
  }
 
}