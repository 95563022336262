import UserController from '../controllers/user'
import GroupController from '../controllers/group'
import ScoreController from '../controllers/score'

import router from '../router'
// import lscache from 'lscache'
import UserPostedScores from '../models/userPostedScores'
import UserSchRounds from '../models/userSchRounds'

import { roundTo, uniqueArray } from '../lib'
import Season from '../models/season'
import DBUpdates from '../helpers/dbUpdates'

import Papa from 'papaparse'
import FileSaver from 'file-saver'
import dayjs from 'dayjs'

const parseFullName = require('parse-full-name').parseFullName

export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: {

    user: null,
    userPostedScores: null,
    userSchRounds: null,
    tab: 0,
    errorMsg: '',
    seasonResults: {
      numPostedRounds: 0,
      totalBet: 0,
      totalWinnings: 0,
      netWinnings: 0,
      netPerRound: 0,
      games: {}
    },
    currentSeason: new Season(),
    numLimitScores: 0,
    unSubOnUserSchRounds: null,
    unSubOnUserSchScores: null,
    unSubOnUserPostedScores: null,
    unSubOnUserLimitScores: null,
    unSubOnUserSchTournaments: null,
    unSubOnPlayer: null,
    unSubOnUser: null,
    isLoadingPostedScores: false,
    onPlayerLoaded: false
  },
  // -----------------------------------------------------------------
  getters: {
 
    user (state) {
      return state.user
    },
    seasonResults (state) {
      return state.seasonResults
    },
    userPostedScores (state) {
      return state.userPostedScores
    },
    userSchRounds (state) {
      return state.userSchRounds
    },
    
    isAdmin (state) {
      let groupAdmin = false
      if (state.user) {
        groupAdmin = state.user.groupAdmin
      }
      return groupAdmin
    },
    isGuest (state) {
      let guest = true
      if (state.user) {
        guest = state.user.playerDocId === ''
      }
      return guest
    },
    playerDocId (state) {
      let playerDocId = ''
      if (state.user) playerDocId = state.user.playerDocId
      return playerDocId
    },
    tab (state) {
      return state.tab
    },
    numLimitScores (state) {
      return state.numLimitScores
    },
    errorMsg (state) {
      return state.errorMsg
    },
    isLoadingPostedScores (state) {
      return state.isLoadingPostedScores
    },
    currentSeason (state) {
      return state.currentSeason
    },
    onPlayerLoaded (state) {
      return state.onPlayerLoaded
    }
  },
  // -----------------------------------------------------------------
  mutations: {

  
    user (state, user) {
      state.user = user
      state.userPostedScores = new UserPostedScores()
      state.userSchRounds = new UserSchRounds()
     
    },
    email (state, email) {
      if (state.user) {
        state.user.email = email
      }
    },

    clearUserPostedScores (state) {
      state.userPostedScores.clear()
    },
    clearUserSchRounds (state) {
      state.userSchRounds.clear()
    },

    changeDefaultGroup (state, groupObj) {
      state.user.groupDocId = groupObj.groupDocId
      state.user.playerDocId = groupObj.playerDocId
      // lscache.set('user', state.user.toJSON(), 43800) // 1 month
    },
 
    errorMsg (state, msg) {
      state.errorMsg = msg
    },
    signout (state) {
      // state.user.initialize()
      state.user = null
      state.userPostedScores = null
      state.userSchRounds = null
   
    },
    tab (state, tab) {
      state.tab = tab
    },
    numLimitScores (state, count) {
      state.numLimitScores = count
    },
    isLoadingPostedScores (state, isLoading) {
      state.isLoadingPostedScores = isLoading
    },

    addUserPostedScore (state, scoreDoc) {
      let userScore = state.userPostedScores.aScores.find(s => s.docId === scoreDoc.id)
      if (userScore === undefined) {
        userScore = state.userPostedScores.addDoc(scoreDoc.id, scoreDoc.data())
        
      }
      else {
        userScore.setData(scoreDoc.data())
    
      }
    },


    updatePlayer (state, player) {
      if (player !== undefined) {
    
        state.user.name = player.name

        state.user.groupAdmin = player.admin
        state.user.tee = player.tee
        state.user.ghinNum = player.ghinNum
  
        state.onPlayerLoaded = true
      }
     
    },
  

    updateGroupName (state, groupName) {
      state.user.groupName = groupName
      for (let groupObj of state.user.groups) {
        if (groupObj.docId === state.user.groupDocId) {
          groupObj.name = groupName
        }
      }
      // lscache.set('user', state.user.toJSON(), 43800) // 1 month
    },

    updateGroupAdmin (state, admin) {
      state.user.groupAdmin = admin
      // lscache.set('user', state.user.toJSON(), 43800) // 1 month
    },

    updateUserPostedScore (state, scoreDoc) {
      userScore = state.userPostedScores.aScores.find(s => s.docId === scoreDoc.id)
      userScore.setData(scoreDoc.data())
    },

    
    deleteUserPostedScore (state, docId) {
      if (state.userPostedScores.exists(docId)) {
        state.userPostedScores.deleteByDocId(docId)
      }
    },


    unsubscribeUser (state) {
      if (state.unSubOnUser) {
        state.unSubOnUser()
        state.unSubOnUser = null
      }
    },
    
    unsubscribePostedScores (state) {
      if (state.unSubOnUserPostedScores) {
     
        state.unSubOnUserPostedScores ()
        state.unSubOnUserPostedScores = null
        state.userPostedScores.clear()
      }
    },
    unsubscribeSchRounds (state) {
      if (state.unSubOnUserSchRounds) {
      
        state.unSubOnUserSchRounds ()
        state.unSubOnUserSchRounds = null
        state.userSchRounds.clear()
      }
    },
    unsubscribeSchTournaments (state) {
      if (state.unSubOnUserSchTournaments) {
      
        state.unSubOnUserSchTournaments ()
        state.unSubOnUserSchTournaments = null
       
      }
    },
    unsubscribeSchScores (state) {
      if (state.unSubOnUserSchScores) {
      
        state.unSubOnUserSchScores ()
        state.unSubOnUserSchScores = null
      }
    },
    unsubscribeLimitScores (state) {
      if (state.unSubOnUserLimitScores) {
       
        state.unSubOnUserLimitScores ()
        state.unSubOnUserLimitScores = null
        state.numLimitScores = 0
      }
    },
    subscribeUser (state, fn) {
      state.unSubOnUser = fn
    },
    subscribePostedScores (state, fn) {
      state.unSubOnUserPostedScores = fn
    },
    subscribeSchRounds (state, fn) {
      state.unSubOnUserSchRounds = fn
    },
    subscribeSchTournaments (state, fn) {
      state.unSubOnUserSchTournaments = fn
    },
    subscribeSchScores (state, fn) {
      state.unSubOnUserSchScores = fn
    },
    subscribeLimitScores (state, fn) {
      state.unSubOnUserLimitScores = fn
    },
    subscribePlayer (state, fn) {
      state.unSubOnPlayer = fn
    },
    unsubscribePlayer (state) {
      if (state.unSubOnPlayer) {
       
        state.unSubOnPlayer ()
        state.unSubOnPlayer = null
      }
    },
    onPlayerLoaded (state, val) {
      state.onPlayerLoaded = val
    },
    currentSeason (state, season) {
      state.currentSeason = season.copy()
    },
   
    createSeasonResults (state) {
    


      const scoresArray = state.userPostedScores.aScores
      let scoredRounds = 0


      let allGames = []
      for (let score of scoresArray) {
        allGames = allGames.concat(score.games)
      }
     
      const uniqueGames = uniqueArray (allGames, 'name') // get list if unique games names for all scores

    
      const sumScore = {
        numPostedRounds: 0,
        totalBet: 0,
        totalPayout: 0,
        netWinnings: 0,
        netPerRound: 0,
        gameResults: {}
      }
        
      if (uniqueGames.length > 0) {
        for (let gameObj of uniqueGames) {

          const gameResult = {
            id: gameObj.id,
            name: gameObj.name,
            prop: gameObj.prop,
            payOut: 0,
            avgScore: 0,
            count: 0
          }
          sumScore.gameResults[gameObj.name] = gameResult
        }

          
        let numPostedRounds = 0

        for (let score of scoresArray) {
  
          numPostedRounds++
    
          sumScore.totalBet += score.totalBet
          sumScore.totalPayout += score.totalPayout
              
          if (!score.wd) {
            let gameIndex
            let scoreGameObj
            let gameResult
            for (let gameObj of uniqueGames) {
              gameIndex = score.games.findIndex(g=>g.name === gameObj.name)
              if ( gameIndex >= 0) {

                scoreGameObj = score.games[gameIndex]

                gameResult = sumScore.gameResults[scoreGameObj.name]
                
                if (scoreGameObj.id in score.payOut) {
                  
                  gameResult.payOut += score.payOut[scoreGameObj.id]
                
                }

              
                if (gameObj.prop === 'stroke' && gameObj.side === 'full18') {
                
                  if (score.netScore.full18 !== '') {
                    gameResult.avgScore += score.netScore.full18
                    scoredRounds++
                  }
                
                }
                else if (gameObj.prop === 'stroke' && gameObj.side === 'front'){
                  if (score.netScore.front !== '') gameResult.avgScore += score.netScore.front
                }
                else if (gameObj.prop === 'stroke' && gameObj.side === 'back'){
                  if (score.netScore.back !== '') gameResult.avgScore += score.netScore.back
                }
                else if (gameObj.prop === 'skins') {
                  gameResult.count += score.skinsCount
                }
                else if (gameObj.prop === 'kp') {
                  gameResult.count += score.kpCount
                }
                else if (gameObj.prop === 'dots') {
                  gameResult.count += score.dotsCount
                }
                
              }
             
            }
          }
         
        
        }
            
        for(let gameObj of uniqueGames) {

          const gameResult = sumScore.gameResults[gameObj.name]
        
          if (gameObj.prop === 'stroke') {
            gameResult.avgScore = roundTo(gameResult.avgScore / scoredRounds,1)
          }
          gameResult.payOut = roundTo(gameResult.payOut, 0)
        }
        sumScore.numPostedRounds = numPostedRounds
      
      
        sumScore.netWinnings = sumScore.totalPayout - sumScore.totalBet
        sumScore.netPerRound = roundTo(sumScore.netWinnings / scoredRounds, 2)
      }
      
      state.seasonResults = sumScore
    }
  },
  // -----------------------------------------------------------------
  actions: {
    async initialize (context) {


      const response = await UserController.getUser(context.rootGetters['auth/uid'])
      if (response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      } 
      else {
        context.commit('user', response.user)

        await context.dispatch('user/loadUserGroup',null,{root:true})
      }
      
      
    },
 
    async signIn(context, credentials) {

      const response = await UserController.signIn(credentials.email, credentials.password)

      if (response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      } 
      else {

        context.commit('user', response.user)

        await context.dispatch('user/loadUserGroup',null,{root:true})

        if (router.currentRoute.path !== '/user') router.push('/user')

      }

    },
    async signInAsGuest (context, email) {
      const response = await UserController.signInAsGuest(email)

      if (response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      } 
      else {

        context.commit('user', response.user)

        if (response.roundDocId === null) {
 
          router.push('/guestnotfound')
        }
        else {
          await context.dispatch('group/getGroup', response.user.groupDocId, { root: true })

          if (context.rootGetters['group/errorMsg'] !== '') {
            context.commit('errorMsg', context.rootGetters['group/errorMsg'])
          }
          else {
            const group = context.rootGetters['group/group']
          
            context.commit('updateGroupName', group.name)
    
         
            context.commit('currentSeason', group.seasons.latest)
            context.commit('errorMsg', '')

            context.dispatch('round/open', {groupDocId: response.groupDocId, roundDocId: response.roundDocId, playerScoreDocId: '', roundNum: ''}, { root: true })
          }
        }

      }
    },
    async loadUserGroup (context) {
      const user = context.state.user



      if (user.groupDocId === '' && user.groups.length>0) {
        const groupDocId = user.groups[0].docId

        let resp = await GroupController.getPlayers(groupDocId, 'email', user.email)

        if (resp.errorMsg.length === 0) {
          const playerDocId = resp.players.aPlayers[0].docId

          const userUpdates = new DBUpdates()
          userUpdates.add(context.state.user.docId, {groupDocId: groupDocId, playerDocId: playerDocId})

          resp = await UserController.updateUserValues(userUpdates)

          if (resp.errorMsg.length === 0) {
            user.groupDocId = groupDocId
            user.playerDocId = playerDocId
          }
        }

      }
      if (user.groupDocId.length > 0) {
      
        await context.dispatch('group/getGroup', user.groupDocId, { root: true })

        if (context.rootGetters['group/errorMsg'] !== '') {
          context.commit('errorMsg', context.rootGetters['group/errorMsg'])
        }
        else {
          GroupController.onPlayer(context, {groupDocId: user.groupDocId, playerDocId: user.playerDocId})
  
          const group = context.rootGetters['group/group']
          
  
          context.commit('updateGroupName', group.name)
  
      
          context.commit('currentSeason', group.seasons.latest)
          context.commit('errorMsg', '')
  
          // await context.dispatch('getUserPostedScores', group.seasons.latest)
          context.dispatch('onUserPostedScores', group.seasons.latest)
        
          // const queryInfo = {
          //   groupDocId: user.groupDocId,
          //   playerDocId: user.playerDocId
          // }
        
          // await UserController.getuserSchRounds(context, queryInfo)
      
          
          context.dispatch('onUser')
          context.dispatch('onUserSchTournaments')
          context.dispatch('onUserSchRounds')
  
          if (group.handicap.method.limitScores && group.handicap.method.limitScoresMonths > 0) {
            context.dispatch('onUserLimitScores',group.handicap.method.limitScoresMonths)
          }
        
        }
      }
    
      
  
    },
    async changeUserGroup (context, groupDocId) {
      let response = await GroupController.getPlayers(groupDocId,'email',context.state.user.email)
      if (response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      }
      else {
        context.commit('errorMsg', '')
        if (response.players.size === 0) {
          context.commit('errorMsg', 'You are not listed on the Group roster')

        }
        else {
          const playerDocId = response.players.aPlayers[0].docId
          context.commit('changeDefaultGroup',{groupDocId, playerDocId})
          const userUpdates = new DBUpdates()
          userUpdates.add(context.state.user.docId, {groupDocId: groupDocId, playerDocId: playerDocId})
          response = await UserController.updateUserValues(userUpdates)
          await context.dispatch('loadUserGroup')
        }
      }
      
  
    },

    async getUserPostedScores (context, season) {

      const user = context.state.user
      
      const queryInfo = {
        groupDocId: user.groupDocId,
        season,
        playerDocId: user.playerDocId
      }
      context.commit('currentSeason', season)
      await UserController.getUserPostedScores(context, queryInfo)
      
    },

    onUserPostedScores (context, season) {

      const user = context.state.user
      
      const queryInfo = {
        groupDocId: user.groupDocId,
        season,
        playerDocId: user.playerDocId
      }
     
      UserController.onUserPostedScores(context, queryInfo)
      
    },

    onUserSchRounds (context) {
      const user = context.state.user
      
      const queryInfo = {
        groupDocId: user.groupDocId,
        playerDocId: user.playerDocId
      }
    
      UserController.onUserSchRounds(context, queryInfo)
      
    },

    onUserSchTournaments (context) {
      const user = context.state.user
      
      const queryInfo = {
        groupDocId: user.groupDocId
      }
    
      UserController.onUserSchTournaments(context, queryInfo)
      
    },

    onUser (context) {
      UserController.onUser(context)
    },

    onUserLimitScores (context, months) {
      const user = context.state.user
      
      const queryInfo = {
        groupDocId: user.groupDocId,
        playerDocId: user.playerDocId,
        months
      }
    
      UserController.onUserLimitScores(context, queryInfo)
    
    },

    async changeEmail (context, newEmail) {
   
      context.commit('email',newEmail)
      const userUpdates = new DBUpdates()
      userUpdates.add(context.state.user.docId, {email: newEmail})
      let response = await UserController.updateUserValues(userUpdates)

      if (response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      }
      else {
        const playerUpdates = new DBUpdates()
        if (context.state.user.groups.length===1) {
          playerUpdates.add(context.state.user.playerDocId, {email: newEmail})
          response = await GroupController.updatePlayerValues(context.state.user.groupDocId, playerUpdates)
          if (response.errorMsg.length > 0) context.commit('errorMsg', response.errorMsg)
        }
        else {
          for (let groupObj of context.state.user.groups) {
            response = await GroupController.getPlayers(groupObj.docId, 'email', context.state.user.email)
            if (response.errorMsg.length > 0) {
              context.commit('errorMsg', response.errorMsg)
              break
            }
            else {
              for (let player of response.players.aPlayers) {
                playerUpdates.add(player.docId, {email: newEmail})
              }
              const response2 = await GroupController.updatePlayerValues(groupObj.docId, playerUpdates)
              if (response2.errorMsg.length > 0) {
                context.commit('errorMsg', response2.errorMsg)
                break
              }
            }
           
          }
        }

       
      }

      
    },

  


    async signOut(context) {
      const user = context.state.user
      if (user) {
      
        context.commit('unsubscribePostedScores')
        context.commit('unsubscribeSchRounds')
        context.commit('unsubscribeSchTournaments')
        context.commit('unsubscribeSchScores')
        context.commit('unsubscribeLimitScores')
        context.commit('unsubscribePlayer')
        context.commit('unsubscribeUser')
       
        context.dispatch('group/clear', '', { root: true })
        context.dispatch('round/clear', '', { root: true })
        context.dispatch('tournament/clear', '', { root: true })
  
        await UserController.signOut(context)
  
        context.commit('signout')

        if (router.currentRoute.path !== '/') router.push('/')

    
      }
      
    },

    async saveUser({ commit }, user) {
      let response
      if (user.isNew()) {
        response = await UserController.add(user)
        if (response.errorMsg.length === 0) {
          user.id = response.id
        }
      } 
      else {
        response = await UserController.update(user)
      }

      if (response.errorMsg.length > 0) {
        commit('errorMsg', response.errorMsg)
      } 
      else {
        commit('user', user)
        commit('errorMsg', '')
      }

    },

   

    async backupDb (context) {
      const response = await UserController.backupDb()
  
      if (response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      } 
      else {
        context.commit('errorMsg', '')
      }

      
    },

    async exportRounds (context) {
      const response = await GroupController.getAllRounds(context.state.user.groupDocId)
    
      if (response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      } else {
        context.commit('errorMsg', '')
      }

      console.log('rounds found', response.rounds.aRounds.length)
      
      const rounds = response.rounds.aRounds.map(round => {
        let handicapData = round.handicap.getData()
        let handicapSettings = {
          methodType: 'modified usga',
          maxHandicap: round.handicap.maxHandicap,
          useGameIndex: round.handicap.useGameIndex,
          lookupGhinIndex: round.handicap.lookupGhinIndex
        }
        console.log('round ',dayjs(round.date).format('YYYY-MM-DD'))
        return {
          doc_id: round.docId,
          group_id: 6,
          owner_profile_id: 94,
          handicap_method: JSON.stringify(handicapData.method),
          start_time: round.teeTimes.startTime24,
          tee_time_interval: round.teeTimes.interval,
          course_id: 1,
          date: dayjs(round.date).format('YYYY-MM-DD'),
          status: round.status,
          name: round.name,
          num_players: round.numPlayers,
          bet_on_games: round.betOnGames,
          round_payouts: round.roundPayouts,
          payout_calc: 1,
          old_games: JSON.stringify(round.games.getData()),
          num_holes: 18,
          hole_by_hole_scores: true,
          pcc: round.pcc,
          excluded: round.excluded,
          close_registration: false,
          calc_handicaps: true,
          use_handicaps: true,
          handicap_settings: JSON.stringify(handicapSettings),
          tee_times: JSON.stringify(round.teeTimes.getData()),
        
        }
      })
    
      const csv = Papa.unparse(rounds)

      const blob = new Blob([csv], {type: "text/plain;charset=utf-8"})
      FileSaver.saveAs(blob, 'Rounds List.csv')
    },
    async exportScores (context) {
      
      const response = await ScoreController.getGroupScores(context.state.user.groupDocId)
    
      if (response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      } else {
        context.commit('errorMsg', '')
      }
      
      const scores = response.scores.aScores.map(score => {
        let handicapFront = score.handicap===null ? '' : score.handicap.front
        let handicapBack = score.handicap===null ? '' : score.handicap.back
        let handicapFull18 = score.handicap===null ? '' : score.handicap.full18

        if (handicapFront === 'n/a') handicapFront = ''
        if (handicapBack === 'n/a') handicapBack = ''
        if (handicapFull18 === 'n/a') handicapFull18 = ''

        return {
          doc_id: score.docId,
          player_doc_id: score.playerDocId,
          round_doc_id: score.roundDocId,

          first_name: parseFullName(score.name).first,
          last_name: parseFullName(score.name).last,
          email: score.email,
          type: score.type,
          tee: score.tee,
          gender: score.gender,
          ghin_num: score.ghinNum,

          skins_count: score.skinsCount,
          skins: JSON.stringify(score.skins),

          kp_count: score.kpCount,
          kp_unit: score.kpUnit,
          kp: JSON.stringify(score.kp),

          dots_count: score.dotsCount,
          dots: JSON.stringify(score.dots),

          index_front: score.index===null ? '' : score.index.front,
          index_back: score.index===null ? '' : score.index.back,
          index_full18: score.index===null ? '' : score.index.full18,
          index_source: score.indexSource,

          handicap_front: score.handicap===null ? '' : score.handicap.front,
          handicap_back: score.handicap===null ? '' : score.handicap.back,
          handicap_full18: score.handicap===null ? '' : score.handicap.full18,
          handicap_date: score.handicapDate===null ? '' : dayjs(score.handicapDate).format('YYYY-MM-DD'),
          
          diff_front: score.diff===null ? '' : score.diff.front,
          diff_back: score.diff===null ? '' : score.diff.front,
          diff_full18: score.diff===null ? '' : score.diff.full18,
          
          register_date: score.signUpDate===null ? '' : dayjs(score.signUpDate).format('YYYY-MM-DD'),

          wd: score.wd,

          total_score_front: score.score.front,
          total_score_back: score.score.back,
          total_score_full18: score.score.full18,
          hole_scores: JSON.stringify(score.scores),

          pos: JSON.stringify(score.pos),
          pay_out: JSON.stringify(score.payOut),
          total_bet: score.totalBet,
          total_payout: score.totalPayout,

          exclude: score.exclude,


        }
      })
    
      
      const csv = Papa.unparse(scores)

      const blob = new Blob([csv], {type: "text/plain;charset=utf-8"})
      FileSaver.saveAs(blob, 'Scores List.csv')

  
    },
    async exportPlayers (context) {
      const response = await GroupController.getAllPlayers(context.state.user.groupDocId)
    
      if (response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      } else {
        context.commit('errorMsg', '')
      }

      
     
      const players = response.players.aPlayers.map(player => {
        return {
          group_id: 6,
          doc_id: player.docId,
          first_name: parseFullName(player.name).first,
          last_name: parseFullName(player.name).last,
          email: player.email,
          gender: player.gender,
          type: player.type,
          tee: player.tee,
          ghin_num: player.ghinNum,
          index_front: player.index===null ? '' : player.index.front,
          index_back: player.index===null ? '' : player.index.back,
          index_full18: player.index===null ? '' : player.index.full18,
          index_source: player.indexSource,
          handicap_front: player.handicap===null ? '' : player.handicap.front,
          handicap_back: player.handicap===null ? '' : player.handicap.back,
          handicap_full18: player.handicap===null ? '' : player.handicap.full18,
          handicap_date: player.handicapDate===null ? '' : dayjs(player.handicapDate).format('YYYY-MM-DD'),
          registered: player.registered,
          auto_register: player.autoSignUp,
          register_date: player.signUpDate===null ? '' : dayjs(player.signUpDate).format('YYYY-MM-DD'),

        }
      })
      
      const csv = Papa.unparse(players)

      const blob = new Blob([csv], {type: "text/plain;charset=utf-8"})
      FileSaver.saveAs(blob, 'Players List.csv')
    },
    async exportUsers (context) {
      const response = await UserController.getAllUsers()
    
      if (response.errorMsg.length > 0) {
        context.commit('errorMsg', response.errorMsg)
      } else {
        context.commit('errorMsg', '')
      }

      
     
      const users = response.users.aUsers.map(user => {
        return {
          doc_id: user.docId,
          group_id: 6,
          first_name: parseFullName(user.name).first,
          last_name: parseFullName(user.name).last,
          email: user.email,
          gender: user.gender,
          tee: user.tee,
          ghin_num: user.ghinNum,

        }
      })
      
      const csv = Papa.unparse(users)

      const blob = new Blob([csv], {type: "text/plain;charset=utf-8"})
      FileSaver.saveAs(blob, 'Users List.csv')
    },
  }
}