import AppSettingsController from '../controllers/app'


export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: {
    isInited: false,
    isAuthed: false,
    isAnonymous: false,
    uid: '',
    email: '',
    appSettings: {}
  },
  // -----------------------------------------------------------------
  getters: {
    isAuthenticated(state) {
      return state.isAuthed
    },
    isInitialized (state) {
      return state.isInited
    },
    isAnonymous (state) {
      return state.isAnonymous
    },
    uid (state) {
      return state.uid
    },
    email (state) {
      return state.email
    },
    appSettings (state) {
      return state.appSettings
    }
  },
  // -----------------------------------------------------------------
  mutations: {
    authStateChanged(state, user) {
      
      state.isInited = true
      if (user) {
        state.uid = user.uid
        state.email = user.email
        state.isAuthed = true
        state.isAnonymous = user.isAnonymous
      }
      else {
        state.uid = ''
        state.email = ''
        state.isAuthed = false
        state.isAnonymous = false
      }
    
    
    },
    signOut (state) {
      state.isAuthed = false
    },
    appSettings (state, settings) {
      state.appSettings = settings
    }
    
  },
  actions: {
    async loadAppSettings (context) {
      const response = await AppSettingsController.loadSettings()
      if (response.errorMsg.length > 0) {
        // context.commit('errorMsg', response.errorMsg)
      } 
      else {
        context.commit('appSettings', response.appSettings)

      }
    }
  }
  
}