import { sortArrayObj } from '../lib'
import Season from './season'

// import { sortArrayObj } from '../lib'

export default class Seasons {
  constructor() {
    this.initialize()
  }
  initialize () {
    this.aSeasons = []
  }

  get currentSeason() {
    let season
    if (this.aSeasons.length > 0) {
      season = this.aSeasons[0]
    }
    else {
      season = new Season()
    }
    return season
  }
  add () {
    
    const season = new Season()
    
    this.aSeasons.push(season)

    // this.sort()

    return season
  }

  addData (data) {
    
    const season = new Season()

    season.setData(data)
    
    this.aSeasons.push(season)

    return season
  }

  seasonNames () {
    return this.aSeasons.map(s => s.name)
  }

  deleteByIndex (index) {
   
    this.aSeasons.splice(index, 1)

  }
  
  get size () {
    return this.aSeasons.length
  }

  get latest () {
    let season
    if (this.aSeasons.length === 0) {
      season = new Season()
    }
    else {
      sortArrayObj (this.aSeasons, 'fromDate', 'desc')
      season = this.aSeasons[0]
    }
    return season
  }

  // sort() {
  //   sortArrayObj(this.aSeasons, 'toDate', 'desc')
  // }
  

  delete (season) {
    const index = this.aSeasons.findIndex(s => s.name === season.name)

    this.deleteByIndex(index)

  }
  
  clear () {
    this.aSeasons = []
  }

  
  copy () {
    const seasons = new Seasons()

    for (let season of this.aSeasons) {
      seasons.aSeasons.push(season.copy())
    }
    return seasons
  }


  toJSON () {
    return JSON.stringify(this.getObj(),null,2)
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  getObj () {
    return this.aSeasons.map(season => season.getObj())
      
  }
  setObj (data) {
    this.aSeasons = data.map(seasonObj => {
      const season = new Season()
      season.setObj(seasonObj)
      return season
    })
  }
  getData () {
   
    return this.aSeasons.map(season => season.getData())
    
  }
  setData (data) {

    if (data === undefined) {
      this.initialize()
    }
    else {
      this.aSeasons = []
      for (let seasonData of data) {
        this.addData(seasonData)
      }
     
    }
    // this.sort()
   
  }
}

