<template>
  <div class="modal-card" >
    <header class="modal-card-head">
      <p class="modal-card-title">Send Tee Time Email</p>
    </header>
    <div class="modal-card-body" >
      <b-field label="To">
        <b-input
          v-model="to"
          type="textarea" rows="5"/>
       
      </b-field>
      <b-field label="cc">
        <b-input
          v-model="cc" />
       
      </b-field>
      <b-field label="Subject">
        <b-input
          v-model="subject" />
       
      </b-field>
      <b-field label="Email Message">
        
        <b-input
          v-model="msg"
          type="textarea"
          rows="8" />
       
      </b-field>
     
    </div>
    <footer class="modal-card-foot" style="justify-content: flex-end;">
      <button class="button" type="button" @click="$parent.close()">Cancel</button>
      <button class="button is-primary" @click="sendEmail" >Send</button>
    </footer>
  </div>


</template>

<script>

import dayjs from 'dayjs'

export default {
  data () {
    return {
  
      to: this.emails,
      msg: '',
      cc: '',
      subject: this.groupName + ' Tee Times for ' + dayjs(this.roundDate).format('dddd MMM D, YYYY')
    }
  },

  props: ['groupName','roundDate','emails'],



  methods: {
   
    sendEmail () {
      this.$emit('saveModal', {to: this.to, msg: this.msg, cc: this.cc, subject: this.subject})
      this.$parent.close()
    }
  }
}
</script>

<style>

</style>
