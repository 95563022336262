<template>
  <div class="modal-card">

    <header class="modal-card-head">
      <p class="modal-card-title">Add Rounds</p>
    </header>

    <div class="modal-card-body">
      
      <b-field >
        <b-datepicker
          v-model="roundDates"
          :date-formatter="formatDateLabel"
          multiple
          placeholder="Click to select dates..."
          icon="calendar-today"
          append-to-body
       >
        </b-datepicker>
      </b-field>
        <b-timepicker
          v-if="$isSafari"
          :value="startTime"
          editable
          hour-format="12"
          :mobile-native="false"
          @input="value=>updateTime(value)" 
        />

        <b-input
          v-else
          :value="dayjs(startTime).format('HH:mm')"
          type="time"
          @input="value=>updateTimeVal(value)" 
        />
      <b-field>

      </b-field>
      

    </div>
    <footer class="modal-card-foot" style="justify-content: flex-end;">
      <button class="button" type="button" @click="$parent.close()">Cancel</button>
      <button class="button is-primary" @click="createRound" >OK</button>
    </footer>
  </div>


</template>

<script>

import dayjs from 'dayjs'

export default {
  data () {
    return {
      roundDates: [],
      startTime: dayjs().hour(9).toDate()
    }
  },
 
  computed: {
  
  },
 
  methods: {
    dayjs,
    formatDateLabel (dates) {
      
      let label = ''
      if (Array.isArray(dates)) {
        
        const formattedDates = dates.map(d => dayjs(d).format('ddd MMM D, YYYY'))
       
        label = formattedDates.join(', ')
      }
      else {
     
        label = dayjs(dates).format('ddd MMM D, YYYY')
      }
     
      return label
    },

    updateTimeVal (val) {
   
      this.startTime = dayjs().hour(Number(val.substr(0,2))).minute(Number(val.substr(3,2)))
    
    },
    
    updateTime (time) {
      this.startTime = time
    },
   
  
    createRound () {
      const startHour = dayjs(this.startTime).hour()
      const startMin = dayjs(this.startTime).minute()
      const roundDates =  this.roundDates.map(d=>dayjs(d).hour(startHour).minute(startMin).toDate())
      this.$emit('saveModal', roundDates)
      this.$parent.close()
    }
  }
}
</script>

<style>


</style>
