<template>
  <div>
    
    <div class="level is-mobile has-margin-bottom-5">
      <div class="level-left">
        <div class="level-item">
          <p class="label is-size-5">Season</p>
        </div>
        <div class="level-item">
          <b-select :value="currentSeasonRounds.name" @input="value=>refreshRounds(value)">
            <option
              v-for="(season,index) in seasonsArray"
              :value="season.name"
              :key="index"
            >{{ season.name }}</option>
          </b-select>
        </div>
        <div v-if="!readOnly" class="level-item">
          <b-button @click="scheduleRound">Schedule Round</b-button>
        </div>
      </div>
      <div v-if="!isMobile" class="level-right">
        <div class="level-item">
          <b-button @click="exportRounds">Export Rounds</b-button>
        </div>
      </div>
    </div>

 
     
      <b-table 
        :data="roundsArray"         
        default-sort="date" 
        default-sort-direction="desc"
      >
      

          <b-table-column
            cell-class="vert-middle"
            label="Date"
            field="date"
            width="140"
            :sortable="!isMobile"
            v-slot="props"
          >
            <p>{{ props.row.formattedDate}}</p>

          </b-table-column>

           <b-table-column
            cell-class="vert-middle"
            label="Name"
            field="name"
            :sortable="!isMobile"
            v-slot="props"
          >
            <p>{{ props.row.fullName}}</p>

          </b-table-column>

          <b-table-column
            cell-class="vert-middle"
            field="course.label"
            label="Course"
            v-slot="props"
          
          >
            {{ props.row.course.label}}
          </b-table-column>

          <b-table-column
            cell-class="vert-middle"
            field="numPlayers"
            label="Players"
            width="50"
            centered
            :sortable="!isMobile"
            v-slot="props"
          >{{ props.row.numPlayers}}
          </b-table-column>
          <b-table-column
            cell-class="vert-middle"
            field="status"
            label="Status"
            :sortable="!isMobile"
            v-slot="props"
          >{{ props.row.excluded?'Excluded':props.row.status}}</b-table-column>
          <b-table-column cell-class="vert-middle" width="100" v-slot="props">
          
            <b-button type="is-primary" @click="openRound(props.row)">Open</b-button>
             
          </b-table-column>
   
      </b-table>
   

    <b-modal :active.sync="showSchRound" has-modal-card >
      <schedule-round :group="group" @saveModal="createRounds"/>
    </b-modal>

     <b-loading :is-full-page="true" :active.sync="isLoadingRounds" :can-cancel="true"></b-loading>
  </div>
</template>

<script>
import ScheduleRound from "../../components/Dialogs/ScheduleRound"
import Papa from 'papaparse'
import FileSaver from 'file-saver'
import dayjs from 'dayjs'

import { mapGetters } from "vuex"

export default {
  data() {
    return {
   
      file: null,
      showSchRound: false
    
    }
  },
 
  components: {
    'schedule-round': ScheduleRound
  },

  computed: {
    roundsArray () {
      return this.rounds.aRounds
    },
    ...mapGetters('group', [
      'group',
      'rounds',
      'tab',
      'currentSeasonRounds'
    ]),
    isAdmin () {
      return this.$store.getters['user/isAdmin']
    },
    readOnly () {
      return !this.isAdmin
    },
    isLoadingRounds: {
      get () {
        return this.$store.getters['group/isLoadingRounds']
      },
      set (val) {
        this.$store.commit('group/isLoadingRounds', val)
      }
    },
    seasonsArray () {
      let seasons = []
      if (this.group) seasons = this.group.seasons.aSeasons
      return seasons
    }
  },
  methods: {

    scheduleRound() {
      this.showSchRound = true
    },
    createRounds(settings) {
      this.$store.dispatch("group/createRounds", settings)
    },
    openRound(round) {
      this.loading = true

      this.$store.dispatch('round/open', {groupDocId: this.group.docId, roundDocId: round.docId, playerScoreDocId: '', roundNum: round.roundNum, from: 'group'})
 
      this.loading = false
    },
    

    refreshRounds (seasonName) {
      const season = this.group.seasons.aSeasons.find(s=>s.name===seasonName)
      if (season === undefined) {
        console.log('season not found', seasonName)
      }
      else {
        this.$store.dispatch('group/onRounds', season)
      
      }
      
    },
   
    exportRounds () {
     const headers = [
        'Date',
        'Name',
        'Course',
        'Players',
        'Status',
        'Start Time'
      ]
     

      const report = this.roundsArray.map(round => {
        const line = [
          dayjs(round.date).format('YYYY-MM-DD'),
          round.name,
          round.course.label,
          round.numPlayers,
          round.status,
          dayjs(round.teeTimes.startTime).format('HH:mm')
        ]

        return line
      })
      const csv = Papa.unparse({
        fields: headers,
        data: report
      })

      const blob = new Blob([csv], {type: "text/plain;charset=utf-8"})
      FileSaver.saveAs(blob, `${this.group.name} ${this.currentSeasonRounds.name} rounds.csv`)
    }
  }
};
</script>

<style>
.vert-middle {
  vertical-align: middle !important;
}
.scrollable {
  max-height: 560px;
  overflow-y: auto;
}




</style>
