<template>
  <div>

    <div class="panel" :style="isMobile?'width:100%;':'width:330px;'">
      <div class="level panel-heading is-marginless is-mobile">
        <div class="level-left level-item">
          <button class="button" style="border:none;" @click="backHole()">
            <b-icon type="is-dark" icon="arrow-left"  />
            <p class="is-size-5" v-if="groupHoleNum===19">18</p>
          </button> 
        </div>
        <template v-if="groupHoleNum!==19">
          <div class="level-item">
            <div class="has-text-centered">
            <p class="title is-4 has-text-weight-bold is-spaced">Hole {{groupHoleNum}}</p>
            <p class="subtitle is-6">Group {{groupNum}} Par {{getParHoles(groupHoleNum)}} Hdcp {{getHandicapHole(groupHoleNum)}}</p>
            </div>
          </div>
        </template>
        <template v-else>
          
          <div class="level-item">
            <b-dropdown  @change="(value) => gotoHole(Number(value))">
              <button class="button is-medium" slot="trigger" slot-scope="{ active }">
                <span >Hole</span>
                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
              </button>
              <b-dropdown-item v-for="n in 18" :key="n" :value="n">
                {{n}}
              </b-dropdown-item>
            </b-dropdown>
            
          </div>
        </template>

        <div class="level-right level-item">
          <button class="button" style="border:none;" @click="nextHole()">
            <p class="is-size-5" v-if="groupHoleNum===19">1</p>
            <b-icon type="is-dark" icon="arrow-right"  />
          </button> 
            <button v-if="isScoringCompleteGroup() && groupHoleNum!==19" class="button has-margin-left-5" style="border:none;" @click="groupHoleNum=19">
            <b-icon type="is-dark" icon="arrow-collapse-right"  />
          </button> 
        
        </div>
      </div>
      <template v-if="groupHoleNum===19">
        <mobile-group-totals
          :round="round"
          :currentTeeTimeScoresArray="currentTeeTimeScoresArray"
          :scoreCount="currentTeeTime.scoreCount"
        />
        
      </template>
      <template v-else>
        <template v-if="isScoresLoaded" >
          <template v-if="round.games.hasGame('dots') || isKPHole">
            <b-tabs class="has-margin-bottom-5" v-model="activeScorePanel" type="is-toggle" expanded>
              <b-tab-item label="Scores">
                <mobile-score-panel
                  :round="round"
                  :groupHoleNum="groupHoleNum"
                  :currentTeeTimeScoresArray="currentTeeTimeScoresArray"
                  :selectedScore="selectedScore"
                  :autoInc="autoInc"
                  :scoreCount="currentTeeTime.scoreCount"
                  @setKPTab="activeScorePanel=1"
                  @selectScore="selectScore"
                  @nextHole="nextHole"
                
                />
              </b-tab-item>

              <b-tab-item v-if="round.games.hasGame('dots')" label="Dots">
                <mobile-dots-panel
                  :round="round"
                  :groupHoleNum="groupHoleNum"
                  :currentTeeTimeScoresArray="currentTeeTimeScoresArray"
    
                
                />
              </b-tab-item>

              <b-tab-item v-if="isKPHole" label="Closet To Pin" @input="selectKP">
                <mobile-kp-panel
                  :round="round"
                  :groupHoleNum="groupHoleNum"
                  :currentTeeTimeScoresArray="currentTeeTimeScoresArray"
                  :selectedKPDocId="selectedKPDocId"
            
                />
              </b-tab-item>
            </b-tabs>

          </template>
         
          <template v-else>
            <mobile-score-panel
              :round="round"
              :groupHoleNum="groupHoleNum"
              :currentTeeTimeScoresArray="currentTeeTimeScoresArray"
              :selectedScore="selectedScore"
              :autoInc="autoInc"
              :scoreCount="currentTeeTime.scoreCount"
              @setKPTab="activeScorePanel=2"
              @selectScore="selectScore"
              @nextHole="nextHole"
            
            />
          </template>
        </template>
        
        <b-button
          type="is-info"
          size="is-medium"
          expanded
          :disabled="missingScores"
          @click="incHoleNum(1)">
          Next Hole
        </b-button>
      </template> 

        
    </div>


  </div>

</template>

<script>

import { formatToPar} from "../../lib"

import TeeTime from '../../models/teeTime'
import MobileScorePanel from '../Round/MobileScorePanel'
import MobileDotsPanel from '../Round/MobileDotsPanel'
import MobileKPPanel from '../Round/MobileKPPanel'
import MobileGroupTotals from '../Round/MobileGroupTotals'

export default {
  data () {
    return {
      activeScorePanel: 0,
      groupHoleNum: 1,
      autoInc: true,
      showScoreDetails: false,
      selectedKPDocId: 'None',
      selectedScore: null,
      scoresLoaded: false
    }
  },
  components: {
    'mobile-score-panel': MobileScorePanel,
    'mobile-dots-panel': MobileDotsPanel,
    'mobile-kp-panel': MobileKPPanel,
    'mobile-group-totals': MobileGroupTotals
  },

  created () {

    if (this.currentTeeTime === null) {
      this.$emit('setScoringView', 1)
    }
    
  },


  computed: {
    isScoresLoaded () {
      let loaded = this.scoresLoaded

      if (!loaded) {
        if (this.scoresArray.length === 0) {
          loaded = false
        }
        else {
          const teeTime = this.currentTeeTime
          const playerScore = this.scoresArray.find(s => s.playerDocId === this.playerDocId)

          if (playerScore !== undefined) {
        
            if (playerScore.holeThru === 0 || playerScore.holeThru === '') {
              this.groupHoleNum = Number.parseInt(teeTime.startHole)
            }
            else {
              this.groupHoleNum = Number.parseInt(playerScore.holeThru) + 1
            }
            if (this.groupHoleNum > 18) this.groupHoleNum = 1

        
            this.selectedScore = teeTime.firstScore

            this.scoresLoaded = true
            loaded = true
          }
        }
      }

      return loaded
    },
    currentTeeTime () {

      let teeTime = new TeeTime()
      let scoreDocId = this.playerScoreDocId

      if (scoreDocId === '') {
        const playerScore = this.scoresArray.find(s => s.playerDocId === this.playerDocId)
        if (playerScore !== undefined) scoreDocId = playerScore.docId
      }
      
      if (scoreDocId !== '') {
        teeTime = this.round.teeTimes.getTeeTimeByScoreDocId(scoreDocId)
      }

      return teeTime
    },
    activeScoresArray () {  // exclude WD scores
      let scoresArray
      if (this.round === null) {
        scoresArray = []
      }
      else {
        scoresArray = this.round.teeTimes.scoresArray().filter(s=>!s.wd)
      }
      return scoresArray
    },
    scoresArray () {
      let scoresArray
      if (this.round === null) {
        scoresArray = []
      }
      else {
        scoresArray = this.round.teeTimes.scoresArray()
      }
      return scoresArray
    },
   
    currentTeeTimeScoresArray () {
      let scoresArray = []
      if (this.currentTeeTime) scoresArray = this.currentTeeTime.scoresArray()

      return scoresArray
    },

   
    round () {
      return this.$store.getters['round/round']
    },

    playerScoreDocId () {
      return this.$store.getters['round/playerScoreDocId']
    },

    groupNum () {
      return this.currentTeeTime ? this.currentTeeTime.groupNum : ''
    },
    isAdmin () {
      return this.$store.getters['user/isAdmin']
    },
    isGuest () {
      return this.$store.getters['user/isGuest']
    },
    playerDocId () {
      return this.$store.getters['user/playerDocId']
    },
    missingScores () {
      let noScores = true
      let scoreIndex = 1
      for (let score of this.currentTeeTimeScoresArray) {
        const scoreVal = score.getHoleScore(this.groupHoleNum)
        if (scoreVal) {
          noScores = false
          break
        }
        if (scoreIndex === this.currentTeeTime.scoreCount) break
        scoreIndex++
      }
      return noScores
    },
    isKPHole () {
      let isHole = false
      const score = this.currentTeeTimeScoresArray[0]
   
      if (score) {
        isHole = this.round.isKPHole(score.tee, this.groupHoleNum) && this.round.games.hasGame('kp')

      }
    
      return isHole
    }
   
  },
    
  methods: {
    isScoringCompleteGroup () {
      
      let completed = true
      let scoreIndex = 1
      for (let score of this.currentTeeTimeScoresArray) {

        if (score.isRoundComplete(this.round.numHoles) && scoreIndex === this.currentTeeTime.scoreCount) {
          completed = true
          break
        }
        if (!score.isRoundComplete(this.round.numHoles)) {
          completed = false
          break
        }
        scoreIndex++
      }
      return completed
    },
    backHole () {
      this.autoInc = false
      this.incHoleNum(-1)
    },
    nextHole () {
      this.autoInc = false
      this.incHoleNum(1)
    },
    gotoHole(holeNum) {
      this.groupHoleNum = holeNum
      this.selectedScore = this.currentTeeTime.firstScore
    },
    incHoleNum (inc) {
      this.activeScorePanel = 0

      let minHoleNum = 1
      let maxHoleNum = 18
     
      if (this.round.numHoles === 9) {
        minHoleNum = this.currentTeeTime.startHole
        maxHoleNum = minHoleNum + 8
      }
      if (this.round.numHoles === 18) {
        minHoleNum = 1
        maxHoleNum = 18
      }
    
      const scoringCompleteGrp = this.isScoringCompleteGroup()
      if (scoringCompleteGrp && inc>0 && (this.groupHoleNum===9 || this.groupHoleNum===18)) {
        this.groupHoleNum = 19
      }
      else {
        this.groupHoleNum += inc
        if (this.groupHoleNum === 19 && !scoringCompleteGrp) this.groupHoleNum = minHoleNum
        if (this.groupHoleNum === 20) this.groupHoleNum = minHoleNum
        if (this.groupHoleNum < 1) this.groupHoleNum = maxHoleNum
      }

      let scoreIndex = 1
      for (let score of this.currentTeeTimeScoresArray) {
        const scoreVal = score.getHoleScore(this.groupHoleNum)
        if (!scoreVal || scoreVal === 0) {
          this.selectedScore = score
          break
        }
        if (scoreIndex === this.currentTeeTime.scoreCount) break
        scoreIndex++
      }
      
     

      if (this.missingScores) this.autoInc = true // no scores entered so turn auto advance to next hole when all scores entered

    },

    getParHoles (holeNum, scoreTee='') {
      let tee = ''
      if (scoreTee === '') {
      
        if (this.scoresArray.length > 0) tee = this.scoresArray[0].tee
      }
      else {
        tee = scoreTee
      }
   
      return this.round.parHoles(tee)[holeNum-1]
    },

    getHandicapHole (holeNum, scoreTee='') {
      let tee = ''
      if (scoreTee === '') {
      
        if (this.scoresArray.length > 0) tee = this.scoresArray[0].tee
      }
      else {
        tee = scoreTee
      }
   
      return this.round.handicapHoles(tee)[holeNum-1]
    },
  

    toPar (score, side='full18') {

      return formatToPar(score.toPar(side))
    },

    selectKP () {
      this.selectedKPDocId = 'None'
      for (let score of currentTeeTimeScoresArray) {
        if (score.hasKP(this.groupHoleNum)) {
          this.selectedKPDocId = score.docId
          break
        }
      }
      
    },

    selectScore (score) {
      this.selectedScore = score
    }
    
  }
}

</script>

<style lang="scss">



  .checkbox-cell-mobile {
      width: 20px;
      .checkbox {
          vertical-align: middle;
          .check {
              transition: none;
          }
      }
  }
 
  tr.rowhighlight td, tr.rowhighlight th{
    background-color:#caced1;
  }
  .group-score {
    width: 768px;
    flex: 0 0 768px;
  }

  .selected-player {
    border-width: 2px;
    border-style: solid;
    border-color: blue;

  }


</style>
