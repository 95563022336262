<template>
 
  <b-tabs>
    <b-tab-item v-for="(round, index) in tournament.rounds.aRounds" :key="index" :label="'Round ' + round.roundNum">
      <round-hole-scores :score="getRoundScore(round)" :round="round" :editScore="editScore"/>
    </b-tab-item>
  </b-tabs>
 
</template>

<script>

import RoundHoleScores from '../Round/HoleScores'
import Score from '../../models/score'

export default {
  data () {
    return {
     
    }
  },

  props: ['tournament','scores', 'playerDocId', 'editScore'],
  
  components: {
    'round-hole-scores': RoundHoleScores
  },


  computed: {
    
  },
  methods: {
    getRoundScore (round) {
        
      let score = this.scores.aScores.find(s=>(s.playerDocId===this.playerDocId && s.roundDocId === round.docId))
      if (score ===  undefined) {
        score = new Score()
      }
      return score
   

    }

  }
}

</script>

<style>
  
</style>