
import { capitalizeWord, getDataValue, FSTimestampToDate, DateToFSTimestamp } from '../lib'

export default class Player {
  constructor(data) {
    if(data) {
      this.setObj(data)
    }
    else {
      this.initialize()
    }
  }
  
  initialize () {

    this.docId = ''
    this.admin = false
    this.pairingsView = 'tags'
    this._name = ''
    this.email = ''
    this.gender = 'M'
    this.type = 'Active'
    this.tee = ''
    this.ghinNum = ''
    this.index = null
    this.handicap = null
    this.indexSource = '' 
    this.handicapDate = null 
    this.registered = false
    this.autoSignUp = false
    this.signUpDate = null
    this.sendInvite = true
  }
  get name () {
    return this._name
  }
  set name (val) {
    this._name = capitalizeWord(val)
  }

  get emailAddress () {
    return `${this._name} <${this.email}>`
  }


  update (playerUpdate) {
    for (let prop in playerUpdate.update) {
      this[prop] = playerUpdate.update[prop]
    }
  }
 
  toJSON () {
    return JSON.stringify(this.getObj())
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  copy () {
    const player = new Player()
    player.setObj(this.getObj())
    return player
  }
  getObj () {
    return {
      docId: this.docId,
      admin: this.admin,
      pairingsView: this.pairingsView,
      name: this._name,
      email: this.email,
      gender: this.gender,
      type: this.type,
      tee: this.tee,
      ghinNum: this.ghinNum,
      registered: this.registered,
      autoSignUp: this.autoSignUp,
      index: this.index,
      indexSource: this.indexSource,
      handicap: this.handicap,
      signUpDate: this.signUpDate===null?null:new Date(Number(this.handicapDate)),
      handicapDate: this.handicapDate===null?null:new Date(Number(this.handicapDate)),
      sendInvite: this.sendInvite
    }
  

  }
  setObj (data) {
  
    this.docId = data.docId
    this.admin = data.admin
    this.pairingsView = data.pairingsView
    this._name = data.name
    this.email = data.email
    this.gender = data.gender
    this.type = data.type
    this.tee = data.tee
    this.ghinNum = data.ghinNum
    this.index = data.index
    this.indexSource = data.indexSource
    this.handicap = data.handicap
    this.registered = data.registered
    this.autoSignUp = data.autoSignUp
    this.signUpDate = data.signUpDate===null?null:new Date(Number(data.signUpDate))
    this.handicapDate = data.handicapDate===null?null:new Date(Number(data.handicapDate))
    this.sendInvite = data.sendInvite
  }
  getData () {
    return {
      admin: this.admin,
      pairingsView: this.pairingsView,
      name: this._name,
      email: this.email,
      gender: this.gender,
      type: this.type,
      tee: this.tee,
      ghinNum: this.ghinNum,
      registered: this.registered,
      autoSignUp: this.autoSignUp,
      index: this.index,
      handicap: this.handicap,
      indexSource: this.indexSource,
      handicapDate: this.handicapDate===null?null:DateToFSTimestamp(this.handicapDate),
      signUpDate: this.signUpDate===null?null:DateToFSTimestamp(this.signUpDate), // convert date string to Timestamp object
      sendInvite: this.sendInvite
    }
  }

  setData (data) {
    this.admin = data.admin
    this.pairingsView = getDataValue(data, 'pairingsView', 'tags')
    this._name = data.name
    this.email = data.email
    this.gender = getDataValue(data,'gender','M')
    this.type = data.type
    this.tee = data.tee
    this.ghinNum = data.ghinNum
    this.registered = data.registered
    this.autoSignUp = getDataValue(data,'autoSignUp',false)
    this.sendInvite = getDataValue(data,'sendInvite',true)
    this.index = getDataValue(data,'index',null)
    this.handicap = getDataValue(data,'handicap',null)
    this.indexSource = getDataValue(data,'indexSource',''),
    this.handicapDate = (data.handicapDate===null||data.handicapDate===undefined)?null:FSTimestampToDate(data.handicapDate) // stored as Timestamp object

    this.signUpDate = (data.signUpDate===null||data.signUpDate===undefined)?null:FSTimestampToDate(data.signUpDate) // stored as Timestamp object
  }
  
 
  setScoreData (data) {

    this.admin = false
    this._name = data.name
    this.email = data.email
    this.gender = 'M'
    this.type = data.type
    this.tee = data.tee
    this.ghinNum = data.ghinNum
    this.index = data.index.full18
    this.indexSource = data.indexSource
    this.handicap = data.handicap.full18
   
  }

  
}

