<template>
  <div>
    <template v-if="isMobile">
      <table class="table is-narrow is-size-6" width="100%" >
        <thead>
          <tr>
            <th class="vert-bottom">POS</th>
            <th class="vert-bottom">{{game.isTeamGame?'TEAM':'NAME'}}</th>
            <th class="has-text-centered vert-bottom" style="width:70px;" >{{game.isStableford?(game.useQuota?'QUOTA':'POINTS'):'TO PAR'}}</th>
            <th class="has-text-centered vert-bottom" >THRU</th>
            <th v-if="tournament.holeByHoleScores"></th>
          </tr>
        </thead>
        <tbody>
          
          <template v-for="(row,index) in leaderBoardArray">
            <tr :key="index" @click="openHoleScores(row)">
              <td class="has-text-centered vert-middle" :class="row.payOut>0?'has-text-success has-text-weight-bold':''" >{{ row.pos }}</td>
              <td>
                <template v-if="game.isTeamGame">
                  <p>{{row.nameLabelShort}}</p>
                </template>
                <template v-else>
                  <p>{{row.name}}</p>
                  <p class="is-size-7">Hdcp {{formatHandicap(row.handicapFull)}} ({{row.tee}})</p>
                </template>
                
              </td>
              <td class="vert-middle" :class="parClass(row.toPar)" >{{ formatToPar(row.toPar) }}</td>
              <td class="has-text-centered is-size-5 vert-middle" >{{ row.thru }}</td>
              <td class="vert-middle" v-if="tournament.holeByHoleScores">
                <a >
                  <b-icon icon="chevron-right"/>
                </a>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </template>
    <template v-else>
      <table class="table is-fullwidth" >
        <thead>
          <tr>
            <th v-if="tournament.holeByHoleScores"></th>
            <th class="vert-bottom">POS</th>
            <th class="vert-bottom">{{game.isTeamGame?'TEAM':'NAME'}}</th> 
            <th class="has-text-centered" >{{game.isStableford ? (game.useQuota?'QUOTA':'POINTS') : 'TO PAR'}}</th>
            <th class="has-text-centered vert-bottom" >THRU</th>
            <th v-for="(round,roundIndex) in tournament.rounds.aRounds" :key="roundIndex" class="has-text-centered vert-bottom" >{{'R'+round.roundNum}}</th>
            <th class="has-text-centered vert-bottom" v-if="tournament.betOnGames">PAYOUT</th>
            
          </tr>
        </thead>
        <tbody>
          
          <template v-for="(row,index) in leaderBoardArray">
            <tr :key="index">
              <td v-if="tournament.holeByHoleScores" class="chevron-cell vert-middle" width="30">
                <a
                  role="button"
                  @click.stop="toggleDetails(index)">
                  <b-icon
                    icon="chevron-right"
                    both
                    :class="{'is-expanded': isVisibleDetailRow(index)}"/>
                </a>
              </td>
              <td class="has-text-centered vert-middle" width="30">{{ row.pos }}</td>
              <td class="vert-middle">{{ row.nameLabel }}</td>
              <td class="has-text-centered  vert-middle" width="80">{{ formatToPar(row.toPar) }}</td>
              <td class="vert-middle has-text-centered" width="40">{{ row.thru }}</td>
              <td v-for="(roundToPar,roundIndex) of row.roundToPars" :key="roundIndex" class="has-text-centered vert-middle has-text-weight-bold" width="80">{{ formatToPar(roundToPar) }}</td>
              <td class="vert-middle has-text-centered" v-if="tournament.betOnGames && !isMobile"  width="100">{{ formatCurrency(row.payOut) }}</td>
              
            </tr>
            <tr v-if="isVisibleDetailRow(index)" class="detail" :key="index+'d'">
              <td :colspan="8">
                <div class="detail-container is-paddingless">
                  <team-hole-scores v-if="game.isTeamGame" :tournament="tournament" :teamScore="game.teamScores.getTeamScoreByTeamNum(row.teamNum)" />
                  <hole-scores v-else :tournament="tournament" :scores="scores" :playerDocId="row.playerDocId" :editScore="false"  />
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </template>
    <b-modal 
      :active.sync="showHoleScores"
      has-modal-card 
      full-screen
      :can-cancel="false"
      style="z-index:100;"
     >
      <div class="modal-card" style="width:auto">
        <div class="modal-body">
          <div class="modal-card-body has-padding-5">
          <div class="level is-mobile" @click="showHoleScores=false">
            <div class="level-left">
              <div class="level-item">
                <a class="mt-2" >
                  <b-icon icon='chevron-left'></b-icon>
                </a>
              </div>
              <div class="level-item">
                <p class="subtitle">{{(game.isTeamGame?'Team ' + activeScore.teamNum:activeScore.name) + ' ' + (activeRow.thru==='F'?' Final': `${formatToPar(activeRow.toPar)} thru ${activeRow.thru}`) }}</p>
              </div>
            </div>
          </div>

          <team-hole-scores
            v-if="game.isTeamGame" 
            :tournament="tournament"
            :teamScore="activeScore" 
          
          />
 
          <hole-scores 
            v-else
            :tournament="tournament" 
            :scores="scores" 
            :playerDocId="activeScore.playerDocId"
            :editScore="false" 
           
           
          />
          </div>
        </div>
      </div>
     
  
    </b-modal>
  </div>
</template>

<script>

import { formatToPar, parClass, formatCurrency, formatHandicap } from "../../lib"
import HoleScores from './HoleScores'
import TeamHoleScores from './TeamHoleScores'
import Score from '../../models/score'

export default {
  data() {
    return {
      visibleDetailRows: [],
      showHoleScores: false,
      activeScore: new Score(),
      activeRow: {}
    }
  },
  props: ['tournament','game','leaderBoardArray','scores'],

  components: {
    'hole-scores': HoleScores,
    'team-hole-scores': TeamHoleScores
  },

  computed: {
    scoresArray () {
      return this.scores.aScores
    }
  
  },


  
  methods: {
    formatCurrency,
    formatToPar, 
    parClass,
    formatHandicap,

 
   
    toggleDetails (rowIndex) {
      const found = this.isVisibleDetailRow(rowIndex)
      if (found) {
        const i = this.visibleDetailRows.indexOf(rowIndex)
        this.visibleDetailRows.splice(i, 1)
        
      } else {
        this.visibleDetailRows.push(rowIndex)
      }
    
    },
    isVisibleDetailRow (rowIndex) {
      const result = this.visibleDetailRows.indexOf(rowIndex) >= 0
      return result
    },

    getScore (docId) {
      let score = this.scoresArray.find(s=>s.docId===docId)
      if (score ===  undefined) {
        score = new Score()
      }
      return score
    },
    openHoleScores (row) {
      this.showHoleScores = true
      if (this.game.isTeamGame) {
        this.activeScore = this.game.teamScores.getTeamScoreByTeamNum(row.teamNum)
      }
      else {
        this.activeScore = this.getScore(row.docId)
      }
      
      this.activeRow = row
    }
    
 
   
  }
}
</script>

<style>
 
  .text-center {
    text-align: center !important;
  }
  .vert-bottom {
    vertical-align: bottom !important;
  }
  .vert-middle {
    vertical-align: middle;
  }
</style>
