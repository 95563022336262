<template>
  <div>
    <div class="modal-card">

      <header class="modal-card-head">
        <p class="modal-card-title">Send Invites For {{roundDate}}</p>
      </header>

      <div class="modal-card-body">
        <div class='field is-grouped'>
          <p class="control mt-2">
            {{playersLabel}}
          </p>
          <p class="control">
            <b-button @click="showSelectPlayers=true">Select</b-button>
          </p>
        </div>
       
  <!--      <b-field label="Respond By">
          <b-datepicker
            v-model="respondByDate"
            :date-formatter="formatDateLabel"
            icon="calendar-today"
            position="is-top-right"
            append-to-body>
          </b-datepicker>
        </b-field>-->
        

      </div>
      <footer class="modal-card-foot" style="justify-content: flex-end;">
        <button class="button" type="button" @click="$parent.close()">Cancel</button>
        <button class="button is-primary" @click="sendInvites" >OK</button>
      </footer>
    </div>

    <b-modal 
      :active.sync="showSelectPlayers" 
      has-modal-card 
      :full-screen="isMobile" 
      style="z-index:100;"
    >
      <select-players 
        :players="players" 
        :preSelectedPlayers="selectedPlayers"
        @saveModal="selectPlayers" 
 
      />
    </b-modal>
  </div>

</template>

<script>

// import dayjs from 'dayjs'
import SelectPlayers from './SelectPlayers'
// import { newDate } from '../../lib.js'

export default {
  data () {
    return {
      showSelectPlayers: false,
      selectedPlayers: this.players.filter(p=>p.sendInvite===true),
   
      // respondByDate: newDate(),
      playersSelectType: 'active'
    }
  },
  props: ['roundDate','players'],
    components: {
    'select-players': SelectPlayers
  },
  computed: {
    playersLabel () {
      let label = 'Send to '
      if (this.playersSelectType === 'active') {
        label += `players (${this.selectedPlayers.length})`
      }
      else {
        label += `Selected Players (${this.selectedPlayers.length})`
      }
      return label
    }
  },
  
  methods: {

    // formatDateLabel (date) {
    //   return dayjs(date).format('ddd MMM D, YYYY')
    // },

    selectPlayers (players) {
      this.selectedPlayers = players
      this.playersSelectType = 'selected'
    },

    sendInvites () {

      this.$emit('saveModal', {
    
        players: this.selectedPlayers,
        // respondByDate: this.respondByDate,
       
      })
      this.$parent.close()
    }
  }
}
</script>

<style>


</style>
