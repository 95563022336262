<template>
  <div>
    
    <div class="level is-mobile has-margin-bottom-5">
      <div class="level-left">
        <div class="level-item">
          <p class="label is-size-5">Season</p>
        </div>
        <div class="level-item">
          <b-select :value="currentSeasonRounds.name" @input="value=>refreshTournaments(value)">
            <option
              v-for="(season,index) in seasonsArray"
              :value="season.name"
              :key="index"
            >{{ season.name }}</option>
          </b-select>
        </div>
         <div v-if="!readOnly" class="level-item">
          <b-button @click="showScheduleTournament=true">Schedule Tournament</b-button>
        </div>
      </div>
    </div>

   
     
      <b-table 
        :data="tournamentsArray"         
        default-sort="startDate" 
        default-sort-direction="desc"
      >
      

          <b-table-column
            cell-class="vert-middle"
            label="Date"
            width="260"
            :sortable="!isMobile"
            v-slot="props"
          >
            <p>{{ props.row.formattedDate}}</p>

          </b-table-column>

           <b-table-column
            cell-class="vert-middle"
            label="Name"
            :sortable="!isMobile"
            v-slot="props"
          >
            <p>{{ props.row.name}}</p>

          </b-table-column>

          <b-table-column
            cell-class="vert-middle"
            label="Status"
            :sortable="!isMobile"
            v-slot="props"
          >
            <p>{{ props.row.status}}</p>

          </b-table-column>

          <b-table-column
            cell-class="vert-middle"
            label="Rounds"
            width="50"
            centered
            :sortable="!isMobile"
            v-slot="props"
          >{{ props.row.numRounds}}
          </b-table-column>

          <b-table-column
            cell-class="vert-middle"
            field="numPlayers"
            label="Players"
            width="50"
            centered
            :sortable="!isMobile"
            v-slot="props"
          >{{ props.row.numPlayers}}
          </b-table-column>
         
          <b-table-column cell-class="vert-middle" width="100" v-slot="props">
          
            <b-button type="is-primary" @click="openTournament(props.row)">Open</b-button>
             
          </b-table-column>
   
      </b-table>
   

    <b-modal :active.sync="showScheduleTournament" has-modal-card >
      <schedule-tournament :group="group" @saveModal="scheduleTournament"/>
    </b-modal>

     <b-loading :is-full-page="true" :active.sync="isLoadingTournaments" :can-cancel="true"></b-loading>
  </div>
</template>

<script>
import ScheduleTournament from "../../components/Dialogs/ScheduleTournament"


import { mapGetters } from "vuex"

export default {
  data() {
    return {
   
      file: null,
      showScheduleTournament: false
      
    }
  },

  components: {
    'schedule-tournament': ScheduleTournament
  },

  computed: {
    tournamentsArray () {
      return this.tournaments.aTournaments
    },
    ...mapGetters('group', [
      'group',
      'tournaments',
      'tab',
      'currentSeasonRounds'
    ]),
    isAdmin () {
      return this.$store.getters['user/isAdmin']
    },
    readOnly () {
      return !this.isAdmin
    },
    isLoadingTournaments: {
      get () {
        return this.$store.getters['group/isLoadingTournaments']
      },
      set (val) {
        this.$store.commit('group/isLoadingTournaments', val)
      }
    },
    seasonsArray () {
      let seasons = []
      if (this.group) seasons = this.group.seasons.aSeasons
      return seasons
    }
  },
  methods: {


    async scheduleTournament(tournament) {
      await this.$store.dispatch("group/scheduleTournament", tournament)
    },
    async openTournament(tournament) {
      this.loading = true

      await this.$store.dispatch('tournament/open', {groupDocId: this.group.docId, tournamentDocId: tournament.docId, from: 'group'})
 
      this.loading = false
    },


    refreshTournaments (seasonName) {
      const season = this.group.seasons.aSeasons.find(s=>s.name===seasonName)
      if (season === undefined) {
        console.log('season not found', seasonName)
      }
      else {
        this.$store.dispatch('group/onTournaments', season)
      
      }
      
    }
   
   
  }
}
</script>

<style>
.vert-middle {
  vertical-align: middle !important;
}
.scrollable {
  max-height: 560px;
  overflow-y: auto;
}




</style>
