import Score from './score'
// const { Score } = require('./score')

export default class Scores {
  constructor(aScores=[]) {
    this.aScores = aScores
  }
 
  addDoc (docId, data) {
    let score
    const scoreIndex = this.aScores.findIndex(s=>s.docId===docId)
    if (scoreIndex < 0) {
      score = new Score()
      score.docId = docId
      score.setData(data)
    
      this.aScores.push(score)
    }
    else {
      score = this.aScores[scoreIndex]
      score.setData(data)
    }
    
    return score
  }
  addNew () {
    const score = new Score()
    score.type = "Guest"
    this.aScores.push(score)
   
    return score
  }
  create () {
    const score = new Score()
    score.type = "Guest"
   
    return score
  }
  add (score) {
    this.aScores.push(score)
  }

  setRound (round) {
    for (let score of this.aScores) {
      score.setRound (round)
    }
  }

  
 
  get (docId) {
    const score = this.aScores.find(o => o.docId === docId)
    return score
  }
  set (score) {
    const idx = this.aScores.findIndex(o => o.docId === score.docId)
    if(idx === -1) {
      this.aScores.push(score)
    }
    else {
      this.aScores[idx] = score
    }
  }
  get size () {
    return this.aScores.length
  }
  deleteByIndex (index) {
    
    this.aScores.splice(index, 1)
  }

  delete (score) {
    const index = this.aScores.findIndex(o => o.docId === score.docId)

    this.deleteByIndex(index)

  }
  deleteByDocId (docId) {
    const index = this.aScores.findIndex(o => o.docId === docId)

    this.deleteByIndex(index)

  }
  clear () {
    this.aScores = []
  }
  copy () {
    const scores = new Scores()

    for (let score of this.aScores) {
      scores.add(score.copy())
    }
    return scores
  }

  update (scoreUpdates) {
    for (let scoreUpdate of scoreUpdates.aUpdates) {
  
      const score = this.aScores.find(s => s.docId === scoreUpdate.docId)
      score.update(scoreUpdate)
   
    }
  }

  isHoleCompleted (holeNum) {
    let complete = true

    for (let score of this.aScores) {
      if (!score.isHoleCompleted(holeNum)) {
        complete = false
        break
      }
    }

    return complete
  }

  toJSON () {
    return JSON.stringify(this.getObj(),null,2)
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  getObj () {
    return this.aScores.map(score => score.getObj())
  }
  setObj (data) {
    this.aScores = data.map(scoreObj => {
      const score = new Score()
      score.setObj(scoreObj)
      return score
    })
  }
  getData () {
    return this.aScores.map(score => score.getData())
  }
  setData (data) {
    this.aScores = data.map(scoreData => {
      const score = new Score()
      score.setData(scoreData)
      return score
    })
  }
}

// module.exports = { Scores }
