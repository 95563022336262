<template>
  <div>

    <table class="table is-fullwidth">
      <tbody>
        <tr v-for="(score,index) in currentTeeTimeScoresArray" :key="index"  >
          <td class="vert-middle is-size-5"><p>{{ score.name }}</p></td>
        
          <td class="has-text-centered vert-middle" width="180">
            <template v-if="score.wd">
              <p class="has-text-weight-bold is-size-5">WD</p>
            </template>
            <template v-else-if="round.isInProgress && !score.final && !isGuest" >
              <b-numberinput 
                class="has-text-weight-bold" 
                min="0"
                :editable="false"
                :value="score.getDots(groupHoleNum)" 
                @input="value=>sumDots(score, groupHoleNum, value)" />

            </template>
            <template v-else>
              <p class="has-text-weight-bold is-size-5">{{score.getDots(groupHoleNum)}}</p>
            </template>
            
          </td>
          

        </tr>
      </tbody>
    </table>

  </div>

</template>

<script>
import DBUpdates from '../../helpers/dbUpdates'


export default {
  data () {
    return {
     
    
    }
  },


  props: ['round','groupHoleNum','currentTeeTimeScoresArray'],

  
  computed: {

    isGuest () {
      return this.$store.getters['user/isGuest']
    }
   
  },
  

  methods: {

    async sumDots (score, holeNum, val) {
       await this.$store.dispatch('round/sumDots', {score, holeNum, val})
    }

    
  }
}

</script>

<style lang="scss">


</style>
