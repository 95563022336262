import { db } from '../firebase'
import Course from '../models/course'

import Tee from '../models/tee'


export default {
  async query (search) {
    const response = {
      selectedItems: [],
      errorMsg: ''
    }
    try {
    
      const coursesRef = db.collection('courses')
    
      let query
      if(search.searchValue === '') {
        query = coursesRef
          .orderBy('searchField')
          .limit(search.queryLimit)

      }
      else {
        
        const searchName = search.searchValue.toLowerCase()

        const nameLen = searchName.length
        const strFrontCode = searchName.slice(0, nameLen - 1)
        const strEndCode = searchName.slice(nameLen - 1, nameLen)
        const endcode = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1)
  
        query = coursesRef
          .where('searchName', '>=', searchName)
          .where('searchName', '<', endcode)
          .orderBy('searchName')
          .limit(search.queryLimit)
        
      }
      const results = await query.get()
     
      const selectedCourses = []
      
      let doc
      for (doc of results.docs) {

        const course = new Course()

        course.docId = doc.id
        course.setData(doc.data())
        
        selectedCourses.push(course)
      }
      response.selectedItems = selectedCourses
     
    }
    catch(error) {
      response.errorMsg = error.message
    }
    return response
  },
  async get (docId) {
    const response = {
      errorMsg: ''
    }
    try {

      const doc = await db.collection('courses').doc(docId).get()

      const course = new Course()

      course.docId = doc.id
      course.setData(doc.data())
  
      response.course = course
     
    }
    catch(error) {
      response.errorMsg = error.message
    }
    return response
  },
  async add (course) {
    const response = {
      errorMsg: '',
      docId: ''
    }
    try {
      const courseDoc = await db.collection('courses').add(course.getData())

      response.docId = courseDoc.id
    }
    catch(error) {
      response.errorMsg = error.message
    }
    return response
  },
  async update (course) {
    const response = {
      errorMsg: ''
    }
    try {

      await db.collection('courses').doc(course.docId).update(course.getData())

    }
    catch(error) {
      response.errorMsg = error.message
    }
    return response
  },
  async loadTestData () {
    let response = {}
    let j = 0
    for(let i=1; i<100; i++) {
      j++
      if(j > 25) j = 0
      const prefix = String.fromCharCode(97 + j)
      const course = new Course()
      course.name = prefix + " test course " + i
      course.location = "course location"
      course.state = 'GA'

      const wTee = new Tee()
      wTee.name = 'White'
      wTee.shortName = 'W'
      wTee.rating = { 'full18': 71.5, 'front': 36.2, 'back': 35.3 }
      wTee.slope = { 'full18': 132, 'front': 132, 'back': 132}
      wTee.par = [4,5,4,3,4,3,5,4,4,5,4,5,4,3,4,4,3,4]
      wTee.yards = [345,510,405,175,405,160,535,405,365,525,275,495,340,140,390,400,180,405]
      wTee.handicap = [13,3,6,7,15,5,17,1,9,11,4,14,2,10,18,8,12,16,6]

      const bwTee = new Tee()
      bwTee.name = 'Blue White'
      bwTee.shortName = 'BW'
      bwTee.rating = { 'full18': 69.8, 'front': 35, 'back': 34.8 }
      bwTee.slope = { 'full18': 128, 'front': 127, 'back': 129}
      bwTee.par = [4,5,4,3,4,3,5,4,4,5,4,5,4,3,4,4,3,4]
      bwTee.yards = [325,510,390,175,355,160,470,320,365,495,275,495,330,140,390,375,180,335]
      bwTee.handicap = [13,3,6,7,15,5,17,1,9,11,4,14,2,10,18,8,12,16,6]

      course.tees.add(wTee)
      course.tees.add(bwTee)

      response = await this.add(course)

    }
    return response

  }
}
