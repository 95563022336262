<template>
  <div class="modal-card">

    <header class="modal-card-head">
      <p class="modal-card-title">Schedule Round</p>
    </header>

    <div class="modal-card-body">
       <b-field label="Name">
        <b-input v-model="roundName" />
      </b-field>
      
      <b-field >
        <b-datepicker
          v-model="roundDates"
          :date-formatter="formatDateLabel"
          multiple
          placeholder="Click to select dates..."
          icon="calendar-today"
          append-to-body
       >
        </b-datepicker>
       
      </b-field>

      <select-course :course="course" label="Course" @courseSelected="selectCourse" :disabled="false"/>

    </div>
    <footer class="modal-card-foot" style="justify-content: flex-end;">
      <button class="button" type="button" @click="$parent.close()">Cancel</button>
      <button class="button is-primary" @click="scheduleRound" >OK</button>
    </footer>
  </div>


</template>

<script>

import dayjs from 'dayjs'
import SelectCourse from '../Course/SelectCourse'

export default {
  data () {
    return {
      roundDates: [],
      roundName: this.group.name,
      course: this.group.course
    }
  },
  props: ['group'],
  components: {
    'select-course': SelectCourse
  },
  computed: {
  
  },
 
  methods: {
    formatDateLabel (dates) {
      
      let label = ''
      if (Array.isArray(dates)) {
        
        const formattedDates = dates.map(d => dayjs(d).format('ddd MMM D, YYYY'))
       
        label = formattedDates.join(', ')
      }
      else {
     
        label = dayjs(dates).format('ddd MMM D, YYYY')
      }
     
      return label
    },
    
    selectCourse (course) {
      this.course = course
    },
    scheduleRound () {

      this.$emit('saveModal', {
        roundDates: this.roundDates, 
        roundName: this.roundName,
        course: this.course
      })
      this.$parent.close()
    }
  }
}
</script>

<style>


</style>
