// import { db, fs } from '../firebase'
import { db, fs, fbFunc } from '../firebase'

import dayjs from 'dayjs'

import Score from '../models/score'
import Scores from '../models/scores'

import IndexLookup from '../helpers/indexLookup'
import HandicapCalc from '../helpers/handicapCalc'
import DBUpdates from '../helpers/dbUpdates'
import DBUpdate from '../helpers/dbUpdate'
import UserPostedScores from '../models/userPostedScores'


export default {
  onScores (context, queryInfo) {
    let unsubscribe = function (){}
    try {
      context.commit('unsubscribeScores')

      const groupRef = db.collection('groups').doc(queryInfo.groupDocId)
      // if (context.state.scores === null) context.commit('initializeScores')
      // if (context.state.scores) context.state.scores.clear()

      unsubscribe = groupRef.collection('scores')
        .where('roundDocId','==',queryInfo.roundDocId)
        .onSnapshot( async snapshot => {

          const scores = context.state.scores
          const round = context.state.round
          const group = context.rootState.group.group

          let score

          const doLeaderBoards = []
          const doHandicaps = []
          
          
          for (let change of snapshot.docChanges()) {
            score = scores.aScores.find(s => s.docId === change.doc.id)
            
            if (change.type === 'added') {
              
              if (score === undefined) {
                score = scores.addDoc(change.doc.id, change.doc.data())
              }
              else {
                score.setData(change.doc.data())
              }
              score.setRound (round)
  
              if (score.handicap === null) {
                doHandicaps.push(score.docId)
              }
           
              doLeaderBoards.push(true)

            }
            if (change.type === 'modified') {

              if (score === undefined) {
                throw new Error('Score not found in existing store ' + change.doc.id)
              }
        
              score.setData(change.doc.data())
              score.setRound (round)

              if (score.handicap === null) {
                doHandicaps.push(score.docId)
              }

              doLeaderBoards.push(true)
            
            }
            if (change.type === 'removed') {

             
              scores.deleteByDocId (change.doc.id)
            
              doLeaderBoards.push(true)
              
            }
            // if (snapshot.metadata.fromCache) console.log('on score from cache', score.name)
          }
          
          const roundUpdate = new DBUpdate(round.docId)

          if (doHandicaps.length > 0) {

          

            const scoreUpdates = new DBUpdates()
  
            const settings = {
              handicapSettings: round.handicap,
              groupDocId: round.groupDocId,
              // state: round.course.state,
              // roundDate: round.date,
              // lookupGroupScores: round.handicap.useGameIndex,
              // lookupGhinIndex: round.handicap.lookupGhinInex
            }
  
            const indexLookup = new IndexLookup(settings)
            const hc = new HandicapCalc(round.course, round.handicap.maxHandicap)

          
            for (let scoreDocId of doHandicaps) {
              const score = scores.aScores.find(s => s.docId === scoreDocId)

              if (score !== undefined) {
            
                const playerInfo = {
                  // docId: score.docId,
                  playerDocId: score.playerDocId,
                  name: score.name,
                  ghinNum: score.ghinNum,
                  indexSource: score.indexSource,
                  tee: score.tee,
                  gender: score.gender,
                  type: score.type,
                  state: round.course.state
                }
          
                const indexInfo = await indexLookup.getIndexInfo(playerInfo)


                if (indexInfo.index !== null) {
                  scoreUpdates.add(scoreDocId, {index: indexInfo.getIndexObj(), indexSource: indexInfo.indexSource, handicapDate: new Date()})
                
                  const handicapObj = hc.getPlayingHandicapObj(indexInfo, playerInfo.tee, round.getHandicapFac(), playerInfo.gender)

                  // const update = updateHandicap(score, handicapObj, round)

                  scoreUpdates.add(scoreDocId, {handicap: handicapObj, handicapDate: new Date()})
                  
                }
               
              }
            }

            if (scoreUpdates.hasUpdate()) await this.updateScoreValues (round.groupDocId, scoreUpdates)
            
          }
          
          if (roundUpdate.hasUpdate()) await context.dispatch('saveValues', roundUpdate)
                  
          if (doLeaderBoards.every(val => val) && round) {
           
            context.commit('updateLeaderboards')
            
          }
          
         
          
        })
        context.commit('subscribeScores', unsubscribe)
    }
    catch (error) {
      context.commit('errorMsg', 'onScores score.js - ' + error.code + ' ' + error.message)
    }
    
 
  },



  
  async addScore (groupDocId, score) {
    const response = {
      errorMsg: ''
    }
    try {

      const groupRef = db.collection('groups').doc(groupDocId)

      const scoreRef = await groupRef.collection('scores').add(score.getData())
     
      score.docId = scoreRef.id

    }
    catch(error) {
      response.errorMsg = 'addScore score.js - ' + error.message
    }
    return response
  },
  async addScores (groupDocId, scores) {
    const response = {
      errorMsg: ''
    }

    try {
      const groupRef = db.collection('groups').doc(groupDocId)
    
      for (let score of scores.aScores) {

        const scoreRef = await groupRef.collection('scores').add(score.getData())
        score.docId = scoreRef.id
        
       
      }
     
    }
    catch(error) {
      response.errorMsg = 'addScores score.js - ' + error.message
    }
    return response
  },
  async updateScore (groupDocId, score) {
    const response = {
      errorMsg: ''
    }
    try {

      const groupRef = db.collection('groups').doc(groupDocId)

      await groupRef.collection('scores').doc(score.docId).update(score.getData())

    }
    catch(error) {
      response.errorMsg = 'updateScore score.js - ' + error.message
    }
    return response
  },
  
  async updateScoreValues (groupDocId, scoreUpdates) {

    const response = {
      errorMsg: ''
    }
    try {

      const groupRef = db.collection('groups').doc(groupDocId)

      if (scoreUpdates.size === 1) {
        const scoreUpdate = scoreUpdates.aUpdates[0]
        await groupRef.collection('scores').doc(scoreUpdate.docId).update(scoreUpdate.update)
      }
      else {
        await db.runTransaction(async tx => {
          const updates = []
          scoreUpdates.aUpdates.forEach(scoreUpdate => {
            const scoreRef = groupRef.collection('scores').doc(scoreUpdate.docId)
            updates.push(tx.update(scoreRef, scoreUpdate.update))
          })
          await Promise.all(updates)
        })
      }
      
    }
    catch(error) {
      response.errorMsg = 'updateScoreValues score.js - ' + error.message
    }
    scoreUpdates.clear()
    return response
  },
  async deleteScore (groupDocId, score) {
    const response = {
      errorMsg: ''
    }
    try {

      const groupRef = db.collection('groups').doc(groupDocId)

      await groupRef.collection('scores').doc(score.docId).delete()

    }
    catch(error) {
      response.errorMsg = 'deleteScore score.js - ' + error.message
    }
    return response
  },

  async getRoundScores (queryInfo) {
    const response = {
      errorMsg: ''
    }
    try {
      const scoreDocs = await db.collection('groups')
      .doc(queryInfo.groupDocId)
      .collection('scores')
      .where('roundDocId', '==', queryInfo.roundDocId)
      .get()
  
      response.scores = new Scores()
  
      for (let scoreDoc of scoreDocs.docs) {
        response.scores.addDoc(scoreDoc.id, scoreDoc.data())
      }
    }
    catch(error) {
      response.errorMsg = 'getRoundScores score.js - ' + error.message
    }
    return response
  },
  
  async getTournamentScores (tournament) {
    const response = {
      errorMsg: ''
    }
    try {
      const scoreDocs = await db.collection('groups')
      .doc(tournament.groupDocId)
      .collection('scores')
      .where('tournamentDocId', '==', tournament.docId)
      .where('roundStatus', '!=', 'Scheduled')
      .get()
  
      response.scores = new Scores()
  
      for (let scoreDoc of scoreDocs.docs) {
        response.scores.addDoc(scoreDoc.id, scoreDoc.data())
      }
    }
    catch(error) {
      response.errorMsg = 'getTournamentScores score.js - ' + error.message
    }
    return response
  },

  async getGroupScores (groupDocId) {
    const response = {
      errorMsg: ''
    }
    try {
      const roundDate = dayjs('2023/3/31')
      let roundDateTS = fs.Timestamp.fromDate(roundDate.toDate())

      // const scoreDocs = await db.collection('groups')
      // .doc(groupDocId)
      // .collection('scores')
      // .where('roundDate', '>=', roundDateTS)
      // .get()

      const scoreDocs = await db.collection('groups')
      .doc(groupDocId)
      .collection('scores')
      // .where('roundDate', '>=', roundDateTS)
      .get()
  
      response.scores = new Scores()
  
      for (let scoreDoc of scoreDocs.docs) {
        response.scores.addDoc(scoreDoc.id, scoreDoc.data())
        response.lastDoc = scoreDoc
      }
    }
    catch(error) {
      response.errorMsg = 'getGroupScores score.js - ' + error.message
    }
    return response
  },
  async getUserPostedScores (queryInfo) {
    const response = {
      errorMsg: ''
    }
    try {
      let scoreWhere = db.collection('groups')
        .doc(queryInfo.groupDocId)
        .collection('scores')
        .where('roundDate', '>=', queryInfo.season.fromDateTS)
        .where('roundDate', '<=', queryInfo.season.toDateTS)
        .where('posted','==',true)

      if (queryInfo.playerDocId === '') {
        scoreWhere = scoreWhere.where('type', '==', 'Guest')
      }
      else {
        scoreWhere = scoreWhere.where('playerDocId', '==', queryInfo.playerDocId)
      }
      const scoreDocs =  await scoreWhere.get()
  
      response.postedScores = new UserPostedScores
  
      for (let scoreDoc of scoreDocs.docs) {
        const score = new Score()
        score.setData(scoreDoc.data())
        response.postedScores.addDoc(scoreDoc.id, score)
      }
    }
    catch(error) {
      response.errorMsg = 'getUserPostedScores score.js - ' + error.message
     
    }
    return response
  },

  async getPlayerScores (queryInfo) {
    const response = {
      errorMsg: ''
    }
    try {
     
      
      let scoreWhere = db.collection('groups')
        .doc(queryInfo.groupDocId)
        .collection('scores')
        .where('playerDocId', '==', queryInfo.playerDocId)
        .where('posted','==',true)

      if (queryInfo.fromDate !== null) {
        const fromDateTS = fs.Timestamp.fromDate(queryInfo.fromDate)
        scoreWhere = scoreWhere.where('roundDate', '>=', fromDateTS)
      }
      if (queryInfo.toDate !== null) {
        const toDateTS = fs.Timestamp.fromDate(queryInfo.toDate)
        scoreWhere = scoreWhere.where('roundDate', '<=', toDateTS)
      }
      if (queryInfo.limit !== null) {
        scoreWhere = scoreWhere.limit(queryInfo.limit)
      }
        
      const scoreDocs =  await scoreWhere.orderBy('roundDate', 'desc').get()
  
      response.scores = new Scores()
  
      for (let scoreDoc of scoreDocs.docs) {
        response.scores.addDoc(scoreDoc.id, scoreDoc.data())
      }
    }
    catch(error) {
      response.errorMsg = 'getPlayerScores score.js - ' + error.message
     
    }
    return response
  },

  async getPostedScores (queryInfo) {
    const response = {
      errorMsg: ''
    }
    try {
      const scoreDocs = await db.collection('groups')
      .doc(queryInfo.groupDocId)
      .collection('scores')
      .where('roundDate', '>=', queryInfo.season.fromDateTS)
      .where('roundDate', '<=', queryInfo.season.toDateTS)
      .where('posted','==',true)
      .get()
  
      response.postedScores = new Scores
  
      for (let scoreDoc of scoreDocs.docs) {
        response.postedScores.addDoc(scoreDoc.id, scoreDoc.data())
      }
    }
    catch(error) {
      response.errorMsg = 'getPostedScores score.js - ' + error.message
    }
    return response
  },

  async getPreviousPostedScore (groupDocId, playerDocId, roundDate) {
    const response = {
      errorMsg: '',
      score: null
    }
    try {
      const scoreDocs = await db.collection('groups')
      .doc(groupDocId)
      .collection('scores')
      .where('roundDate', '<', roundDate)
      .where('playerDocId','==',playerDocId)  
      .where('posted','==',true)
      .where('exclude', '==', false)
      .where('wd', '==', false)
      .orderBy('roundDate','desc')
      .limit(1)
      .get()
  
      if (!scoreDocs.empty) {
        response.score = new Score()
        const scoreDoc = scoreDocs.docs[0]
        response.score.docId = scoreDoc.id
        response.score.setData(scoreDoc.data())
      }
      
    }
    catch(error) {
      response.errorMsg = 'getLastPostedScore score.js - ' + error.message
    }
    return response
  },
  
  async getHandicapGroupScores (groupDocId, playerDocId, months=0, maxScores=20) {
    const response = {
      errorMsg: ''
    }
    try {

      let query = db.collection('groups').doc(groupDocId).collection('scores')
        .where('playerDocId', '==', playerDocId)
        .where('posted', '==', true)
        .where('exclude', '==', false)
        .where('wd', '==', false)
       
     

      if (months > 0) {
        const endDay = dayjs().subtract(months, 'month').add(1,'day')
        let endDateTS = fs.Timestamp.fromDate(endDay.toDate())
       
        query = query.where('roundDate', '>=', endDateTS)

      }
    

      const scoreDocs = await query.limit(maxScores).orderBy('roundDate','desc').get()

      const scoresArray = []
      for (let doc of scoreDocs.docs) {
        let score = new Score()
        score.setData(doc.data())
        scoresArray.push(
        {
          roundDate: score.roundDate,
          score: score.score.full18,
          diff: score.diff.full18,
          tee: score.tee,
          course: score.courseLabel,
          pcc: score.pcc
        })
       
      }

      response.scoresArray = scoresArray
    }
    catch(error) {
      response.errorMsg = 'getHandicapGroupScores score.js - ' + error.message
    }
    return response
  }
  
  

}