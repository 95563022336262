<template>
  <div>
    <b-dropdown class="mb-2" :triggers="['hover']" @change="addGame" :disabled="gamesToSelect.length===0">
      <button class="button" slot="trigger">
        <span>Add Game</span>
        <b-icon icon="menu-down"></b-icon>
      </button>
      <b-dropdown-item v-for="(game,index) in gamesToSelect" :key="index" :value="game.id">
        {{ game.name }}
      </b-dropdown-item>
    </b-dropdown>
    <b-collapse class="panel">
      <template #trigger>
        <div
            class="panel-heading is-size-6"
            role="button">
           <div class="level " >
            <div v-if="betOnGames" class="level-left">
              <div class="level-item" >
                <p class="has-text-weight-bold">Total Bet Per Player: {{isTournament?formatCurrency(tournament.totalBet):formatCurrency(games.totalBet)}}</p>
              </div>
              <div v-if="isRound" class="level-item">
                <p class="has-text-weight-bold">Total Pool: {{ formatCurrency(games.totalPool(baseObj.teeTimes.countScores())) }}</p>
              </div>
              <div v-else-if="isTournament" class="level-item">
                <p class="has-text-weight-bold">Total Pool: {{ formatCurrency(tournament.totalPool) }}</p>
              </div>
              <div class="level-item" v-if="betOnGames && baseObj.roundPayouts && Math.floor(games.totalBet) !== games.totalBet"  >
                <p class="has-text-weight-bold has-text-danger"> (Total Bet should be an even number)</p>
              </div>
            
            </div>
           
          </div>
        </div>
      </template>
      <template v-if="betOnGames && isTournament">
        <div class="panel-block">
          <div class="level-left">
            <div class="level-item">
              <p class="has-text-weight-bold">Tournament Bet: {{formatCurrency(games.totalBet)}}</p>
            </div>
          </div>
        </div>
        <div v-for="(round, roundIndex) of baseObj.rounds.aRounds" class="panel-block" :key="roundIndex">
          <div class="level-left">
            <div class="level-item">
              <p class="has-text-weight-bold">Round {{round.roundNum}} Bet: {{formatCurrency(round.games.totalBet)}}</p>
            </div>
          </div>
        </div>
      </template>
    </b-collapse>
    

    <template v-if="gamesArray.length===0">
      <div></div>
    </template>
    <template v-else>
      <div class="panel" v-for="(game, index) in gamesArray" :key="index" >
        <div class="panel-heading is-size-6">
          <div class="level ">
            <div class="level-left">
              <div class="level-item field-label">
                <label class="label has-text-info">{{ game.name }}</label>
              </div>

              <template v-if="(game.bet.amount*numPlayers)>0 && betOnGames">
                
                <div class="level-item ">
                  <p class="has-text-weight-bold">Pool: {{formatCurrency(game.bet.amount * numPlayers)}}</p>
                  <span class="panel-icon ml-5 mb-1" @click="deleteGame(game)" v-if="isMobile || !readOnly">
                    <i class="delete"></i>
                  </span>
                </div>
                
              </template>
              <template v-if="!readOnly && betOnGames && (game.prop==='skins' || game.prop==='kp' || (game.prop==='dots' && game.bet.payoutType==='split'))">
                <div class="level-item"></div>
                <div class="level-item ">
                  <b-checkbox :value="game.dotPool" @input="value=>updateDotPool(game, value)" >Single Pool</b-checkbox>
                </div>

              </template>

              
            </div>
            <div v-if="!isMobile" class="level-right">
              <span class="panel-icon level-item" @click="deleteGame(game)" v-if="!readOnly">
                <i class="delete"></i>
              </span>
            </div>
          </div>
        </div> 

    
        <template v-if="game.prop==='stroke'">
        <!--   <template v-if="isMobile">
            <table class="table">
              <tbody>
                <tr>
                  <td class="vert-middle">Players</td>
                  <td align="right">
                    <p class="control" v-if="readOnly">{{gameDefs.getPlayType(game.playType)}}</p>
                    <b-select v-else :value="game.playType" :ref="game.prop" @input="value=>updatePlayType(game, value)">
                      <option
                        v-for="type in gameDefs.playTypeDefs"
                        :value="type.id"
                        :key="type.id" >
                          {{ type.name }}
                      </option>
                    </b-select>
                  </td>
                </tr>

              </tbody>
            </table>
          </template>
          <template v-else> -->
            <div class="panel-block">
            
              <b-field grouped group-multiline class="mb-2">
                <b-field label="Players">
                  <p class="control" v-if="readOnly">{{gameDefs.getPlayType(game.playType)}}</p>
                  <b-select v-else :value="game.playType" :ref="game.prop" @input="value=>updatePlayType(game, value)">
                    <option
                      v-for="type in gameDefs.playTypeDefs"
                      :value="type.id"
                      :key="type.id" >
                        {{ type.name }}
                    </option>
                  </b-select>
                </b-field>
                <b-field label="Holes">
                  <p class="control" v-if="readOnly">{{gameDefs.getSide(game.side)}}</p>
                  <b-select v-else :value="game.side" @input="value=>updateGameField(game, 'side', value)">
                    <option
                      v-for="type in gameDefs.sideDefs"
                      :value="type.id"
                      :key="type.id" >
                        {{ type.name }}
                    </option>
                  </b-select>
                </b-field>
                <b-field  label="Scoring">
                  <p class="control" v-if="readOnly">{{gameDefs.getScoreType(game.scoreType)}}</p>
                  <b-field v-else grouped>
                    <b-select  :value="game.scoreType" @input="value=>updateScoreType(game, value)">
                      <option
                        v-for="type in gameDefs.scoreTypeDefs"
                        :value="type.id"
                        :key="type.id" >
                          {{ type.name }}
                      </option>
                    </b-select>
                    <div class="control" v-if="game.scoreType === 'stableford'">
                      <b-button @click="openPointsTable(game)">Edit Points</b-button>
                    </div>
                    <div class="control mt-2" v-if="game.scoreType === 'stableford'">
                      <b-switch :value="game.useQuota" @input="value=>updateGameField(game, 'useQuota', value)">Use Quota</b-switch>
                    </div>
                  </b-field>
                </b-field>

                <b-field v-if="game.playType !== 'individual'" label="Format">
                  <p class="control" v-if="readOnly">{{gameDefs.getFormat(game.format)}}</p>
                  <b-select v-else :value="game.format" @input="value=>updateGameFormat(game, value)">
                    <option
                      v-for="type in gameDefs.formatDefs"
                      :value="type.id"
                      :key="type.id" >
                        {{ type.name }}
                    </option>
                  </b-select>
                </b-field>

                <b-field v-if="game.format === 'bball'" label="Scores to Use">
                  <p class="control" v-if="readOnly">{{gameDefs.getScoreCount(game.scoreCount)}}</p>
                  <b-select v-else :value="game.scoreCount" @input="value=>updateScoreCount(game, value)">
                    <option
                      v-for="type in scoreCountArray(game)"
                      :value="type.id"
                      :key="type.id" >
                        {{ type.name }}
                    </option>
                  </b-select>
                </b-field>

                <b-field v-if="game.format === '123'" label="1-2-3 Holes">
                  <p class="control" v-if="readOnly">{{gameDefs.getType23(game.holes123)}}</p>
                  <b-select v-else :value="game.holes123" @input="value=>updateGameField(game, 'holes123', value)">
                    <option
                      v-for="type in gameDefs.holes123Defs"
                      :value="type.id"
                      :key="type.id" >
                        {{ type.name }}
                    </option>
                  </b-select>
                </b-field>

                <b-field label="% of Handicap" >
                  <template v-if="game.format === 'scramble' || game.format === '123'">
                    <b-select :value="game.teamHandicap" @input="value=>updateTeamHandicap(game, value)">
                      <option value="none" >None</option>
                      <option value="usga" >USGA</option>
                    </b-select>
                  </template>
                  <template v-else>
                    <p v-if="readOnly" class="control">{{game.percentHandicap + '%'}}</p>
                    <vue-numeric v-else
                      class="input"
                      :value="game.percentHandicap"
                      style="width:6em;"
                      currency="%"
                      currency-symbol-position="suffix"
                      :max="100" :min="0"
                      @blur="updatePercentHandicap(game, $event.target.value)"
                    />
                  </template>
                </b-field>
                
                
              </b-field>
    
              
              
            </div>
            <div v-if="betOnGames" class="panel-block">
              <b-field grouped group-multiline class="mb-2">
                <b-field label="Bet">
                  <p v-if="readOnly" class="control">{{formatCurrency(game.bet.amount)}}</p>
                  <vue-numeric v-else
                    class="input"
                    style="width:6em;"
                    :value="game.bet.amount"
                    currency="$"
                    :precision="2"
                    :disabled="readOnly"
                    @blur="updateBetAmount(game, $event.target.value)"
                  
                  />
                </b-field>

                <b-field label="Places">
                  <p v-if="readOnly" class="control">{{payOutPlaces(game.bet.payout.places)}}</p>
                  <b-field v-else grouped>
                
                    <b-select :value="game.bet.payout.places" @input="value=>updatePlaces(game,value)" >
                      
                      <option v-for="(place, n) in places" :value="n+1" :key="n">{{place}}</option>
                    </b-select>
                    <div class="control" v-if="showFourth(game)" >
                      <b-button  @click="openPayTable(game)" :disabled="readOnly">Payout Table</b-button>
                    </div>
                  </b-field>
                </b-field>

                <b-field label="1st" v-if="showFirst(game)">
                  <p v-if="readOnly" class="control">{{game.bet.payout.percents['1st']}}</p>
                  <vue-numeric v-else
                    class="input"
                    :value="game.bet.payout.percents['1st']"
                    currency="%"
                    currency-symbol-position="suffix"
                    :max="100" :min="0"
                    @blur="updatePercent(game, '1st', $event.target.value)"
                  />
                </b-field>

                <b-field label="2nd" v-if="showSecond(game)">
                  <p v-if="readOnly" class="control">{{game.bet.payout.percents['2nd']}}</p>
                  <vue-numeric v-else
                    class="input"
                    :value="game.bet.payout.percents['2nd']"
                    currency="%"
                    currency-symbol-position="suffix"
                    :max="100" :min="0"
                    :disabled="readOnly"
                    @blur="updatePercent(game, '2nd', $event.target.value)"
                  />
                </b-field>

                <b-field label="3rd" v-if="showThird(game)">
                  <p v-if="readOnly" class="control">{{game.bet.payout.percents['3rd']}}</p>
                  <vue-numeric v-else
                    class="input"
                    :value="game.bet.payout.percents['3rd']"
                    currency="%"
                    currency-symbol-position="suffix"
                    :max="100" :min="0"

                    @blur="updatePercent(game, '3rd', $event.target.value)"
                  />
                </b-field>
              </b-field>
            </div>
            <div v-if="isTeamGame(game)">
              <team-panel :round="baseObj" :game="game" :games="games" :readOnly="readOnly" />
            </div>
       <!--   </template> -->

        </template>

        <template v-else-if="game.prop==='kp'">
           <div class="panel-block">
             <b-field grouped group-multiline class="mb-2">
                <b-field v-if="betOnGames" label="Bet" >
                  <p v-if="readOnly" class="control">{{formatCurrency(game.bet.amount)}}</p>
                  <vue-numeric v-else
                    :ref="game.prop"
                    class="input"
                    :value="game.bet.amount"
                    style="width:6em;"
                    :precision="2"
                    currency="$"
                    @blur="updateBetAmount(game, $event.target.value)"
                  />
                </b-field>

                <b-field >
                  <b-switch
                    class="has-margin-top-35"
                    :value="game.kpUseDist"
                    @input="value=>updateGameField(game, 'kpUseDist', value)"
                  >
                  Use distances to determine winner
                  </b-switch>
                </b-field>
                 <b-field >
                  <b-switch
                    class="has-margin-top-35"
                    :value="game.kpParOrBetter"
                    @input="value=>updateGameField(game, 'kpParOrBetter', value)"
                  >
                  Par or better to Win
                  </b-switch>
                  
    
                </b-field>
             </b-field>
           </div>
        </template>

        <template v-else-if="game.prop==='skins'">
          <div class="panel-block">
             <b-field grouped group-multiline class="mb-2">

              <b-field v-if="betOnGames" label="Bet" >
                <p v-if="readOnly" class="control">{{formatCurrency(game.bet.amount)}}</p>
                <vue-numeric v-else
                  :ref="game.prop"
                  class="input"
                  :value="game.bet.amount"
                  style="width:6em;"
                  :precision="2"
                  currency="$"
                  @blur="updateBetAmount(game, $event.target.value)"
                />
              </b-field>

              <b-field label="% of Handicap" >
                <p v-if="readOnly" class="control">{{game.percentHandicap + '%'}}</p>
                <vue-numeric v-else
                  class="input"
                  :value="game.percentHandicap"
                  style="width:6em;"
                  currency="%"
                  currency-symbol-position="suffix"
                  :max="100" :min="0"
                  @blur="updatePercentHandicap(game, $event.target.value)"
                />
  
              </b-field>

              <b-field >
               
                <b-switch
                  class="has-margin-top-35"
                  :value="game.skinsHalfStroke"
                  @input="value=>updateGameField(game, 'skinsHalfStroke', value)"
                >
                Use Half Strokes
                </b-switch>
                <b-field label="Max Handicap">
                  <b-numberinput
                    class="text-center"
                  
                    :value="game.maxHandicap"
                    :controls="false"
                    style="width:4em;"
                    @blur="updateMaxHandicap(game, $event.target.value)"
                  />
                </b-field>
  
              </b-field>

          <!--    <b-field>
                <p v-if="readOnly" class="control">{{game.carryOver?'Carry Over':''}}</p>
              </b-field>-->

            </b-field>
          </div>
        </template>

        <template v-else-if="game.prop==='dots'">
          <div class="panel-block">
             <b-field grouped group-multiline class="mb-2">
              <b-field v-if="betOnGames" label="Bet" >
                <p v-if="readOnly" class="control">{{formatCurrency(game.bet.amount)}}</p>
                <vue-numeric v-else
                  :ref="game.prop"
                  class="input"
                  :value="game.bet.amount"
                  style="width:6em;"
                  :precision="2"
                  currency="$"
                  @blur="updateBetAmount(game, $event.target.value)"
                />
              </b-field>
              <b-field label="Players">
                <p class="control" v-if="readOnly">{{gameDefs.getPlayType(game.playType)}}</p>
                <b-select v-else :value="game.playType" :ref="game.prop" @input="value=>updatePlayType(game, value)">
                  <option
                    v-for="type in gameDefs.playTypeDefs"
                    :value="type.id"
                    :key="type.id" >
                      {{ type.name }}
                  </option>
                </b-select>
              </b-field>
              
              <b-field label="Payout">
                <p class="control" v-if="readOnly">{{game.bet.payoutType}}</p>
                <b-select v-else :value="game.bet.payoutType" @input="value=>updatePayoutType(game, value)">
                  <option
                    value="places"
                    key="1" >
                      Most Dots Win
                  </option>
                  <option
                    value="split"
                    key="2" >
                      Split Pool
                  </option>
                </b-select>
              </b-field>

              <template v-if="game.bet.payoutType==='places'">
                <b-field label="Places">
                  <p v-if="readOnly" class="control">{{payOutPlaces(game.bet.payout.places)}}</p>
                  <b-field v-else grouped>
                
                    <b-select :value="game.bet.payout.places" @input="value=>updatePlaces(game,value)" >
                      <option v-for="(place, n) in places2" :value="n+1" :key="n">{{place}}</option>
                    </b-select>
                  </b-field>
                </b-field>

                <b-field label="1st" v-if="showFirst(game)">
                  <p v-if="readOnly" class="control">{{game.bet.payout.percents['1st']}}</p>
                  <vue-numeric v-else
                    class="input"
                    :value="game.bet.payout.percents['1st']"
                    currency="%"
                    currency-symbol-position="suffix"
                    :max="100" :min="0"
                    @blur="updatePercent(game, '1st', $event.target.value)"
                  />
                </b-field>

                <b-field label="2nd" v-if="showSecond(game)">
                  <p v-if="readOnly" class="control">{{game.bet.payout.percents['2nd']}}</p>
                  <vue-numeric v-else
                    class="input"
                    :value="game.bet.payout.percents['2nd']"
                    currency="%"
                    currency-symbol-position="suffix"
                    :max="100" :min="0"
                    :disabled="readOnly"
                    @blur="updatePercent(game, '2nd', $event.target.value)"
                  />
                </b-field>

                <b-field label="3rd" v-if="showThird(game)">
                  <p v-if="readOnly" class="control">{{game.bet.payout.percents['3rd']}}</p>
                  <vue-numeric v-else
                    class="input"
                    :value="game.bet.payout.percents['3rd']"
                    currency="%"
                    currency-symbol-position="suffix"
                    :max="100" :min="0"

                    @blur="updatePercent(game, '3rd', $event.target.value)"
                  />
                </b-field>
              </template>
            </b-field>
          </div>
          <div v-if="isTeamGame(game)">
            <team-panel :round="baseObj" :game="game" :games="games" :readOnly="readOnly" />
          </div>
        </template>

      
      </div>
  
    </template>
    <b-modal :active.sync="showPayoutTable" has-modal-card :full-screen="isMobile">
      <payout-table :gameId="payTableGameId" :gamesArray="gamesArray" :payTable="payTable" @saveModal="updateGamePayTable"/>
    </b-modal>
    <b-modal :active.sync="showPointsTable" has-modal-card :full-screen="isMobile">
      <points-table :pointsTable="pointsTable" @saveModal="updateGamePointsTable"/>
    </b-modal>
  </div>

</template>

<script>

import PayoutTable from '../Dialogs/PayoutTable'
import PointsTableDialog from '../Dialogs/PointsTable'
import GameDefs from '../../helpers/gameDefs'
import Tournament from '../../models/tournament'
import TeamPanel from './TeamPanel'
import PointsTable from '../../models/pointsTable'
import DBUpdate from '../../helpers/dbUpdate'

import {formatCurrency, uniqueArray} from '../../lib'


export default {
  data () {
    return {
      totalBet: 0,

      showPayoutTable: false,
      payTable: {},
      payTableGameId: null,
      gameDefs: new GameDefs(),

      showPointsTable: false,
      pointsTable: null,
      pointsTableGameId: null,

     
      places: [
        'Pay First Only',
        'Pay Two Places',
        'Pay Three Places',
        'Vary By Players'
      ],

       places2: [
        'Pay First Only',
        'Pay Two Places',
        'Pay Three Places'
      ],

      switchTeamIndex: null,
      switchIndex: null
   
    }
  },

  mounted () {
    this.games.sort('order')
    
  },
  components: { 
    'payout-table': PayoutTable,
    'points-table': PointsTableDialog,
    'team-panel': TeamPanel 
  },
  props: ['baseObj','numPlayers','readOnly'],

  computed: {
    betOnGames () {
      return this.baseObj.betOnGames
    },
    isRound () {
      return this.baseObj.objName === 'Round'
    },
    isTournament () {
      return this.baseObj.objName === 'Tournament'
    },
    games () {
      return this.baseObj.games
    },
    gamesArray () {
      return this.games.aGames
    },
    group () {
       return this.$store.getters['group/group']
    },
    tournament () {
      let tournament = this.$store.getters['tournament/tournament']
      if (tournament === null) tournament = new Tournament()
       return tournament
    },
   
    groupTab () {
      return this.$store.getters['group/tab']
    },
    roundTab () {
      return this.$store.getters['round/tab']
    },
    tournamentTab () {
      return this.$store.getters['tournament/tab']
    },


    gamesToSelect () {
   
      let games = []

      if (this.isRound || this.isTournament) {
        for (let game of this.group.games.aGames) {
          // if (!this.games.gameExists(game.id)) games.push (game)
          games.push ({
            id: game.id,
            prop: game.prop,
            name: game.selectName
          })
        }
        games = uniqueArray(games, 'prop')
      }
      else {
        for (let def of this.gameDefs.gameDefs) {
          games.push ({
            id: def.id,
            name: def.name
          })
      
          // if (!this.games.gameExists(game.id)) games.push (game)
        }
      }
  
      return games
    }

  
    
  },
  methods: {
    formatCurrency,

    isTeamGame (game) {
      
      return this.isRound && game.isTeamGame
    }, 


    scoreCountArray (game) {
      let count = 1
      if (game.playType === 'team3') count = 2
      if (game.playType === 'team4') count = 3
      if (game.playType === 'team5') count = 4
      const scoreCounts = []
      for (let i = 0; i < count; i++) {
        scoreCounts.push(this.gameDefs.scoreCountDefs[i])
      }
      return scoreCounts
    },
    payOutPlaces (placeValue) {
      placeValue--

      let place = ''
      if (placeValue >= 0 || placeValue <= 3) {
        place = this.places[placeValue]
      }
      return place
    },

 
    async updateDotPool (game, value) {

      const games = this.games.copy()

      const updatedGame = games.get(game.id)
      updatedGame.dotPool = value

      await this.updateGames(games)
    },

    async updateBetAmount (game, value) {
      const games = this.games.copy()

      const updatedGame = games.get(game.id)
      updatedGame.bet.amount = Number(value)

      await this.updateGames(games)
    },

    async updatePayoutType (game, value) {
      const games = this.games.copy()

      const updatedGame = games.get(game.id)
      updatedGame.bet.payoutType = value

      await this.updateGames(games)
    },

    async updatePlaces (game, value) {
      const games = this.games.copy()

      const updatedGame = games.get(game.id)
      updatedGame.bet.payout.places = Number(value)
      let firstPercent = updatedGame.bet.payout.percents['1st']
      let secondPercent = updatedGame.bet.payout.percents['2nd']
      let thirdPercent = updatedGame.bet.payout.percents['3rd']
      if (updatedGame.bet.payout.places === 1) {
        firstPercent = 100
        updatedGame.bet.payout.percents = {'1st': firstPercent}
      }
      else if (updatedGame.bet.payout.places === 2) {
        if (firstPercent === undefined) firstPercent = 100
        if (secondPercent === undefined) secondPercent = 0
        updatedGame.bet.payout.percents = {
          '1st': firstPercent,
          '2nd': secondPercent
        }
      }
      else if (updatedGame.bet.payout.places === 3) {
        if (firstPercent === undefined) firstPercent = 100
        if (secondPercent === undefined) secondPercent = 0
        if (thirdPercent === undefined) thirdPercent = 0
        updatedGame.bet.payout.percents = {
          '1st': firstPercent,
          '2nd': secondPercent,
          '3rd': thirdPercent
        }
      }
      else {
        updatedGame.bet.payout.percents = {}
      }

      await this.updateGames(games)
    },

    async updatePercent (game, percent, value) {
      const games = this.games.copy()

      const updatedGame = games.get(game.id)
      updatedGame.bet.payout.percents[percent] = Number(value)

      await this.updateGames(games)
    },

    async updatePercentHandicap (game, value) {
      const games = this.games.copy()

      const updatedGame = games.get(game.id)
      updatedGame.percentHandicap = Number(value)

      await this.updateGames(games)
    },

    async updateMaxHandicap (game, value) {
      const games = this.games.copy()

      const updatedGame = games.get(game.id)
      updatedGame.maxHandicap = Number(value)

      await this.updateGames(games)
    },

   


    async updatePlayType(game, value) {
      const games = this.games.copy()

      const updatedGame = games.get(game.id)
      updatedGame.playType = value
      if (value === 'individual') updatedGame.scoreType = 'stroke'
      updatedGame.scoreCount = 1
      if (value === 'team2' && updatedGame.scoreCount > 1) updatedGame.scoreCount = 1
      if (value === 'team3' && updatedGame.scoreCount > 2) updatedGame.scoreCount = 2
      if (value === 'team4' && updatedGame.scoreCount > 3) updatedGame.scoreCount = 3
      if (value === 'team5' && updatedGame.scoreCount > 4) updatedGame.scoreCount = 4

      for (let team of updatedGame.teamScores.aTeams) {
        team.scoreCount = updatedGame.scoreCount
      }

      if (value === 'team2') updatedGame.teamScores.teamSize = 2
      if (value === 'team3') updatedGame.teamScores.teamSize = 3
      if (value === 'team4') updatedGame.teamScores.teamSize = 4
      if (value === 'team5') updatedGame.teamScores.teamSize = 5

      updatedGame.teamScores.clear()
      if (this.isRound && value !== 'individual') {
        const scoreDocIdsToAdd = this.baseObj.teeTimes.scoreDocIds()
       
        updatedGame.teamScores.assign (scoreDocIdsToAdd)
      }
     

      await this.updateGames(games)
    },

    async updateScoreType (game, value) {
      const games = this.games.copy()

      const updatedGame = games.get(game.id)
      updatedGame.scoreType = value
      if (value === 'stableford') {
        updatedGame.pointsTable = new PointsTable()
      }
      else {
        updatedGame.pointsTable = null
      }

      await this.updateGames(games)
    },

    async updateTeamHandicap (game, value) {
      const games = this.games.copy()

      const updatedGame = games.get(game.id)
      updatedGame.teamHandicap = value

      for (let team of updatedGame.teamScores.aTeams) {
          team.format = updatedGame.format
          team.handicapType = updatedGame.teamHandicap
          team.scoreCount = upatedGame.scoreCount
      }
     
      await this.updateGames(games)
    },

    async updateScoreCount (game, value) {
      const games = this.games.copy()

      const updatedGame = games.get(game.id)
      updatedGame.scoreCount = value

      for (let team of updatedGame.teamScores.aTeams) {
          team.format = updatedGame.format
          team.handicapType = updatedGame.teamHandicap
          team.scoreCount = upatedGame.scoreCount
      }
     
      await this.updateGames(games)
    },

    async updateGameFormat(game, value) {
      const update = {}
      const games = this.games.copy()
      
      const updatedGame = games.get(game.id)

      updatedGame.format = value

      if (this.isRound) {
        const teeTimes = this.baseObj.teeTimes.copy()
        if (value === 'scramble' || value === '123') {
          updatedGame.teamScores.clear()
          const teamTimeScoreDocIds = teeTimes.scoreDocIds()
          if (teamTimeScoreDocIds.length > 0) updatedGame.teamScores.assign (teamTimeScoreDocIds)

          for (let team of updatedGame.teamScores.aTeams) {
            team.format = updatedGame.format
            team.handicapType = updatedGame.teamHandicap
            team.scoreCount = updatedGame.scoreCount
          }
          updatedGame.scoreCount = 1
        }
        else if (value === 'all') {
          updatedGame.scoreCount = 0
        }
      
       
        for (let teeTime of teeTimes.aTeeTimes) {
          teeTime.scoreCount = updatedGame.scoreCount
        }
        update.teeTimes = teeTimes.getData()

        for (let team of updatedGame.teamScores.aTeams) {
          team.scoreCount = updatedGame.scoreCount
        }
      }
      else {
        if (value === 'scramble' || value === '123') {
          updatedGame.scoreCount = 1
        }
        else if (value === 'all') {
          updatedGame.scoreCount = 0
        }
      }
      
    

      update.games = games.getData()
     
      const gameUpdate = new DBUpdate(this.baseObj.docId, update)
    
      if (this.isRound) {
        await this.$store.dispatch('round/saveValues', gameUpdate)
      }
      else if (this.isTournament) {
        await this.$store.dispatch('tournament/saveValues', gameUpdate)
      }
      else {
        await this.$store.dispatch('group/saveValues', gameUpdate)
      }

     
    },
   
    

    async updateGameField(game, field, value) {
     
      const games = this.games.copy()

      const updatedGame = games.get(game.id)

      updatedGame[field] = value

      

      await this.updateGames(games)
    },
    

    async updateGames (games) {
      const gameUpdate = new DBUpdate(this.baseObj.docId, {games: games.getData()})
    
      if (this.isRound) {
        await this.$store.dispatch('round/saveValues', gameUpdate)
      }
      else if (this.isTournament) {
        await this.$store.dispatch('tournament/saveValues', gameUpdate)
      }
      else {
        await this.$store.dispatch('group/saveValues', gameUpdate)
      }
      
    },
    
   
    async updateGamePayTable (payTable) {
      const games = this.games.copy()
      const game = games.get(this.payTableGameId )
      game.bet.payout.payTable = payTable
  //    this.$store.commit(this.parentName + '/updateGamePayTable', {gameProp: this.payTableProp, payTable})
      this.payTableGameId = null
      this.payTable = {}
      await this.updateGames(games)
     
   
    },

    async updateGamePointsTable (pointsTable) {
      const games = this.games.copy()
      const game = games.get(this.pointsTableGameId)
      game.pointsTable = pointsTable

      this.pointsTableGameId = null
      this.pointsTable = null
      await this.updateGames(games)
     
   
    },

    async addGame (gameId) {
      let game
      const games = this.games.copy()
   
      if (this.isRound || this.isTournament) { // use game defaults from Group
    
        game = this.group.games.get(gameId).copy()
        game.id = ''
   
        games.add(game)
        
      }
     
      else {
        game = games.addNew()
        const def = this.gameDefs.gameDefs.find(g=>g.id===gameId)
        game.prop = def.prop

      }
  
      await this.updateGames(games)

     
    },
    async deleteGame (game) {
     
      this.$buefy.dialog.confirm({
        message: `Delete "${game.name}" game?`,
        onConfirm: async () => {
          const games = this.games.copy()
          games.deleteById(game.id)
          // if (game.prop === 'kp') this.kpHolesSelected = []
          await this.updateGames(games)

        }
      })
   
    },
    showFirst (game) {
      return ( this.betOnGames && (game.bet.payout.places === 2 || game.bet.payout.places === 3))
    },
    showSecond (game) {
      return (this.betOnGames && (game.bet.payout.places === 2 || game.bet.payout.places === 3))
    },
    showThird (game) {
      return (this.betOnGames && game.bet.payout.places === 3)
    },
    showFourth (game) {
      return (this.betOnGames && game.bet.payout.places === 4)
    },

    openPayTable (game) {
    
      this.payTable = game.bet.payout.payTable
      this.payTableGameId = game.id
      this.showPayoutTable = true
    },

    openPointsTable (game) {
     
      this.pointsTable = game.pointsTable
      this.pointsTableGameId = game.id
      this.showPointsTable = true
    },

    async updateGame (game) {
      const games = this.games.copy()
      const updatedGame = games.get(game.id)
      updatedGame.setObj(game.getObj())
      await this.updateGames(games)
    },

    switchPlayers (game, teamIndex, index) {
      if (this.switchIndex === null) {
        this.switchTeamIndex = teamIndex
        this.switchIndex = index

        this.$buefy.toast.open({
          message: 'Player Copied',
          type: 'is-success'
        })
  
      }
      else {
        const updatedGame = game.copy()
        const teams = updatedGame.teamScores

        const fromTeam = updatedGame.teamScores.aTeams[this.switchTeamIndex]
      
        const toTeam = updatedGame.teamScores.aTeams[teamIndex]

    

        const toScore =  toTeam.scores[index]
        const toScoreDocId =  toTeam.scoreDocIds[index]

       

        toTeam.scoreDocIds[index] = fromTeam.scoreDocIds[this.switchIndex]
        toTeam.scores[index] = fromTeam.scores[this.switchIndex]

        fromTeam.scoreDocIds[this.switchIndex] = toScoreDocId
        fromTeam.scores[this.switchIndex] = toScore

  
          
        updatedGame.teamScores.deleteEmptyTeams ()

        this.updateGame (updatedGame)
        this.switchTeamIndex = null
        this.switchIndex = null
      }
   

    }


  }
}

</script>

<style>
  .vert-middle {
    vertical-align: middle;
  }
  .align-right {
    text-align: right;
  }

</style>
