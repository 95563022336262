<template>
  <div class="modal-card" >
    <header class="modal-card-head">
      <p class="modal-card-title">Account Settings - {{user.name}}</p>
    </header>
    <div class="modal-card-body" >
     
      <div class="field">
        <label class="label">Email</label>
        <div class="field has-addons" >
          <div class="control is-expanded">
            <input class="input" :value="currentEmail" readonly />
          </div>
          <div class="control">
            <button class="button is-info" @click="openChangeEmail = !openChangeEmail">
              {{openChangeEmail?'Close':'Change'}}
            </button>
          </div>
        
        </div>
      </div>
      <b-collapse :open="openChangeEmail">
        <div class="notification">
          <div class="content">
            <b-field 
              label="Password Required to Change"
              :message="passwordErrors"
              >
              <b-input
                type="password"
                maxlength="30"
                v-model="password"
                @blur="$v.password.$touch()"
                password-reveal />
            </b-field>
            <b-field
              label="New Email"
              :message="emailErrors"
              >
              <b-input 
                type="email"
                v-model="newEmail"
                @blur="$v.newEmail.$touch()"
                maxlength="30" />
            </b-field>
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <b-button type="is-primary" :loading="isLoading" :disabled="changeEmailInvalid" @click="changeEmail" >Change</b-button>
                </div>
                <div class="level-item">
                  <p>{{ msg }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
      <div class="field">
        <label class="label">Password</label>
        <div class="field has-addons" >
          <div class="control is-expanded">
            <input class="input" type="password" value="xxxxxx" readonly />
          </div>
          <div class="control">
            <button class="button is-info" @click="openChangePassword = !openChangePassword">
              {{openChangePassword?'Close':'Change'}}
            </button>
          </div>
        </div>
      </div>
      <b-collapse :open="openChangePassword">
        <div class="notification">
          <div class="content">
            <b-field 
              label="Current Password"
              :message="passwordErrors"
              >
              <b-input
                type="password"
                maxlength="30"
                v-model="password"
                @blur="$v.password.$touch()"
                password-reveal />
            </b-field>
            <b-field 
              label="New Password"
              :message="newPasswordErrors"
              >
              <b-input
                type="password"
                maxlength="30"
                v-model="newPassword"
                @blur="$v.newPassword.$touch()"
                password-reveal />
            </b-field>
            <b-field 
              label="Confirm Password"
              :message="confirmPasswordErrors"
              >
              <b-input
                type="password"
                maxlength="30"
                v-model="confirmPassword"
                @blur="$v.confirmPassword.$touch()"
                password-reveal />
            </b-field>
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <b-button type="is-primary" :disabled="changePasswordInvalid" :loading="isLoading" @click="changePassword" >Change</b-button>
                </div>
                <div class="level-item">
                  <p>{{ msg }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
     
    </div>
    <footer class="modal-card-foot" style="justify-content: flex-end;">
      <button class="button" type="button" @click="$parent.close()">Close</button>
     
    </footer>
  </div>


</template>

<script>

import { auth } from '../../firebase'
import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'


export default {
  data () {
    return {
      openChangeEmail: false,
      openChangePassword: false,
      msg: '',
      newEmail: '',
      password: '',
      newPassword: '',
      confirmPassword: '',
      isLoading: false
    }
  },
  validations: {
    newEmail: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(6)
    },
    newPassword: {
      required,
      minLength: minLength(6)
    },
    confirmPassword: {
      sameAsPassword: sameAs('newPassword')
    }
    
  },
  computed: {
    user () {
      return this.$store.getters['user/user']
    },
    currentEmail () {
      return auth.currentUser.email
    },
    emailErrors () {
      return this.$v.newEmail.email ? '' : 'Not a valid email'
    },
    passwordErrors () {
      return this.$v.password.minLength ? '' : 'Password must be at least six characters'
    },
    newPasswordErrors () {
      return this.$v.newPassword.minLength ? '' : 'Password must be at least six characters'
    },
    confirmPasswordErrors () {
      return this.$v.confirmPassword.sameAsPassword ? '' : 'Passwords are not identical'
    },
    changeEmailInvalid () {
      return this.$v.newEmail.$invalid || this.$v.password.$invalid
    },
    changePasswordInvalid () {
      return this.$v.newPassword.$invalid || this.$v.password.$invalid || this.$v.confirmPassword.$invalid
    }
  
  },
  methods: {
   
    async changeEmail () {
      this.isLoading=true
      let user = auth.currentUser
      
      const cred = auth.emailAuthProvider.credential(this.currentEmail, this.password)
      try {
        await user.reauthenticateWithCredential(cred)
        user = auth.currentUser

        const email = this.newEmail.toLowerCase()
        await user.updateEmail(email)

        await this.$store.dispatch('user/changeEmail', email)

        const errorMsg = this.$store.getters['user/errorMsg']
        if (errorMsg.length > 0) {
          this.msg = errorMsg
        }
        else {
          this.msg = "Email Changed Successfully!"
        }       
      }
      catch (error) {
        this.msg = error.message
      }
      this.isLoading=false
    },

    async changePassword () {
      this.isLoading=true
      let user = auth.currentUser
      const cred = auth.emailAuthProvider.credential(this.currentEmail, this.password)
      try {
        await user.reauthenticateWithCredential(cred)
        user = auth.currentUser
        await user.updatePassword(this.newPassword)
        this.msg = "Password Changed Successfully!"
      }
      catch(error) {
        this.msg = error.message
      }
      this.isLoading=false
    }
  
  
  }
}
</script>

<style>

</style>
