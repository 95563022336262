import CourseController from '../controllers/course'

import router from '../router'


export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: {
    course: null,
    queryResults: [],
    caller: '',
    errorMsg: ''
  },
  // -----------------------------------------------------------------
  getters: {
    course(state) {
      return state.course
    },
    queryResults(state) {
      return state.queryResults
    },
    caller(state) {
      return state.caller
    },
    errorMsg(state) {
      return state.errorMsg
    }
  },
  // -----------------------------------------------------------------
  mutations: {
    course(state, course) {
      state.course = course
      const index = state.queryResults.findIndex(c => c.docId === course.docId)
      if (index >= 0) {
        state.queryResults[index] = course
      }
    },
    queryResults(state, results) {
      state.queryResults = results
    },
    clear(state) {
      state.course = null
      state.caller = ''
    },
    setCaller(state, caller) {
      state.caller = caller
    },
    errorMsg(state, msg) {
      state.errorMsg = msg
    }
  },
  // -----------------------------------------------------------------
  actions: {
    cancel({ commit, state }) {
      let caller = state.caller
      if (caller === '') caller = '/courses'
      commit('clear')

      router.push(caller)
    },
    async save({ commit, state }, course) {
      let response
      if (course.docId === '') {
        response = await CourseController.add(course)
        if (response.errorMsg.length === 0) {
          course.docId = response.docId
        }
      }
      else {
        response = await CourseController.update(course)
      }

      if (response.errorMsg.length > 0) {
        commit('errorMsg', response.errorMsg)
      } 
      else {
        commit('course', course)
        commit('errorMsg', '')

        let caller = state.caller
        if (caller === '') caller = '/courses'

        router.push(caller)
      }

    }
  }
}