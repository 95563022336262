import UserSchRound from './userSchRound'
import { sortArrayObj } from '../lib'

export default class userSchRounds {
  constructor() {
    this.initialize()
  }
  initialize () {
    this.aRounds = []
    this.sortField = 'roundDate'
    this.sortDir = 'asc'
  }
  addDoc (docId, data) {
    const userSchRound = new UserSchRound()
    userSchRound.docId = docId
    userSchRound.setData(data)
    
    this.aRounds.push(userSchRound)

    return userSchRound
  }



  add (userSchRound) {
    this.aRounds.push(userSchRound)
  }

  clear () {
    this.aRounds = []
  }

  deleteByDocId (docId) {
    const index = this.aRounds.findIndex(o => o.docId === docId)   
    this.aRounds.splice(index, 1)
  }

  exists (docId) {
    const index = this.aRounds.findIndex(o => o.docId === docId)
    return index >= 0
  }
 

 

  get size () {
    return this.aRounds.length
  }

  sort (field='', dir='') {
    if (field === '') field = this.sortField
    if (dir === '') dir = this.sortDir
    sortArrayObj (this.aRounds, field, dir)
    this.sortField = field
    this.sortDir = dir
  }
  
  toJSON () {
    return JSON.stringify(this.getObj())
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  getObj () {
    return {
      rounds: this.aRounds.map(round => round.getObj())
    }
  }
  setObj (data) {
    this.aRounds = data.rounds.map(roundObj => {
      const schScore = new userSchRound()
      schRound.setObj(roundObj)
      return schScore
    })
  }
  
}

