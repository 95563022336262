<template>
  <div>
    <div class="level has-margin-top-10">
      <div class="level-left">
        <div class="level-item">
          <p class="title is-4">Results - {{currentSeason.name}}</p>
        </div>
      </div>
      <div class="level-right">
          <div class="level-item">
            <a class="title has-text-link is-4" @click="gotoStandings()">{{groupName}} Standings</a>
          </div>
        </div>
    </div>
    <div class="columns">
      <div class="column ">
          <div class="card" :class="isMobile?'card-margin-side':'card-margin-right card-margin-bottom'" >
          <div class="card-header">
            <p class="card-header-title title is-6 ">Winnings</p>
          </div>
          <div class="card-content card-content-padding">
          
            <table class="table" width="100%">
              <tbody>
              
                <tr>
                  <td>Posted Rounds:</td>
                  <td style="text-align:right">{{ seasonResults.numPostedRounds }}</td>
                </tr>
                <tr>
                  <td>Total Bet:</td>
                  <td style="text-align:right">{{ formatCurrency(seasonResults.totalBet) }}</td>
                </tr>
                <tr>
                  <td>Total Winnings:</td>
                  <td style="text-align:right">{{ formatCurrency(seasonResults.totalWinnings) }}</td>
                </tr>
                <tr>
                  <td>Net Winnings:</td>
                  <td style="text-align:right">{{ formatCurrency(seasonResults.netWinnings) }}</td>
                </tr>
                <tr>
                  <td>Winnings per Round:</td>
                  <td style="text-align:right">{{ formatCurrency(seasonResults.netPerRound) }}</td>
                </tr>


              </tbody>
            </table>
          </div>
        </div>
      
        
      </div>
      <div class="column">
        <div class="card" :class="isMobile?'card-margin-side':'card-margin-right card-margin-bottom'">
        <div class="card-header">
          <p class="card-header-title title is-6 ">Game Totals</p>
        </div>
        <div class="card-content card-content-padding">
                
          <table class="table" width="100%">
            <tbody>
              <tr v-for="(gameResult, key) in seasonResults.gameResults" :key="key">
                <td >{{gameResult.name}}</td>
                <td width="40" v-if="gameResult.prop==='stroke'">{{gameResult.avgScore}}</td>
                <td width="40" v-else>{{gameResult.count}}</td>
                <td style="text-align:right;">{{formatCurrency(gameResult.payOut)}}</td>
              </tr>


            </tbody>
          </table>
        </div>
      </div>
      </div>
  
    </div>
  </div>
</template>

<script>

import numeral from 'numeral'

export default {
  data () {
    return {

      
    }
  },
  props: ['user'],
  computed: {
    group () {
      return this.$store.getters['group/group']
    },
    seasonResults () {
      return this.$store.getters['user/seasonResults']
    },
    userPostedScoresArray () {
      const userScores = this.$store.getters['user/userPostedScores']
      return userScores.aScores
    },
    groupName () {
      let name = ''
      if (this.group !== null) name = this.group.name
      return name
    },
    currentSeason () {
      return this.$store.getters['user/currentSeason']
      
    }

  },
  methods: {
    formatCurrency (val) {
      return numeral(val).format("$0,0[.]00")
    },
    gotoStandings () {
      this.$router.push('/group/standings')
    }
  }
}

</script>

<style>
 .card-margin-top {
    margin-top: 8px;
  }
  .card-margin-right {
    margin-right: 8px;
  }
  .card-margin-side {
    margin-left: 4px;
    margin-right: 4px;
  }
  .card-margin-bottom {
    margin-bottom: 8px;
  }
  .card-margin {
    margin: 8px;
  }

  .card-content-padding {
    padding: 10px;
  }
</style>
