import Player from '../models/player'


export default class Players {
  constructor() {
    this.initialize()
  }
  initialize () {
    this.aPlayers = []
  }



  addDoc (docId, data) {
    const player = new Player()
    player.docId = docId
    player.setData(data)
    
    this.aPlayers.push(player)

    return player
  }
  add (player) {
    this.aPlayers.push(player)
  }
  addNew () {

    const player = new Player()

    this.aPlayers.push(player)

    return player
  }

  addScoreDoc (docId, scoreData) {
    let player
    const playerIndex = this.aPlayers.findIndex(s=>s.docId===docId)
    if (playerIndex < 0) {
      player = new Player()
      player.docId = docId
      player.setScoreData(scoreData)
    
      this.aPlayers.push(player)
    }
    else {
      player = this.aPlayers[playerIndex]
      player.setScoreData(scoreData)
    }
    
    return player
  }
  get size () {
    return this.aPlayers.length
  }
  update (playerUpdates) {
    for (let playerUpdate of playerUpdates.aUpdates) {
      const player = this.aPlayers.find(p => p.docId === playerUpdate.docId)
      player.update(playerUpdate)
      
    }
  }
 
  get (docId) {
    const player = this.aPlayers.find(p => p.docId === docId)
    return player
  }

  set (player) {
    const idx = this.aPlayers.findIndex(o => o.docId === player.docId)
    if(idx === -1) {
      this.aPlayers.push(player)
    }
    else {
      this.aPlayers[idx] = player
    }
  }
  deleteByDocId (docId) {
    const index = this.aPlayers.findIndex(o => o.docId === docId)
    this.aPlayers.splice(index, 1)

  }

  exists (docId) {
    const index = this.aPlayers.findIndex(o => o.docId === docId)
    return index >= 0
  }
  clear () {
    this.aPlayers = []
  }
  toJSON () {
    return JSON.stringify(this.getObj(),null,2)
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  getObj () {
    return this.aPlayers.map(player => player.getObj())
  }
  setObj (data) {
    this.aPlayers = data.map(playerObj => {
      const player = new Player()
      player.setObj(playerObj)
      return player
    })
  }
  getData () {
    return this.aPlayers.map(player => player.getData())
  }
  getSignUpData () {
    return this.aPlayers.map(player => player.getSignUpData())
  }
  setData (data) {
    this.aPlayers = data.map(playerData => {
      const player = new Player()
      player.setData(playerData)
      return player
    })
  }
 
}


