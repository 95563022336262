export default class IndexAvgTable {
  constructor(initGhin=false) {

    this.initialize(initGhin)
   
  }
  initialize (initGhin) {
    if (initGhin) {
      
      this.minScores = 3
      this.rows = [
        {postedScores: 3, scoresToUse: 1, adjustment: -2},
        {postedScores: 4, scoresToUse: 1, adjustment: -1},
        // {postedScores: 3, scoresToUse: 1, adjustment: 0},
        // {postedScores: 4, scoresToUse: 1, adjustment: 0},
        {postedScores: 5, scoresToUse: 1, adjustment: 0},
        {postedScores: 6, scoresToUse: 2, adjustment: -1},
        // {postedScores: 6, scoresToUse: 2, adjustment: 0},
        {postedScores: 7, scoresToUse: 2, adjustment: 0},
        {postedScores: 8, scoresToUse: 2, adjustment: 0},
        {postedScores: 9, scoresToUse: 3, adjustment: 0},
        {postedScores: 10, scoresToUse: 3, adjustment: 0},
        {postedScores: 11, scoresToUse: 3, adjustment: 0},
        {postedScores: 12, scoresToUse: 4, adjustment: 0},
        {postedScores: 13, scoresToUse: 4, adjustment: 0},
        {postedScores: 14, scoresToUse: 4, adjustment: 0},
        {postedScores: 15, scoresToUse: 5, adjustment: 0},
        {postedScores: 16, scoresToUse: 5, adjustment: 0},
        {postedScores: 17, scoresToUse: 6, adjustment: 0},
        {postedScores: 18, scoresToUse: 6, adjustment: 0},
        {postedScores: 19, scoresToUse: 7, adjustment: 0},
        {postedScores: 20, scoresToUse: 8, adjustment: 0}
      ]
    
    }
    else {
      this.minScores = 0
      this.rows = [
      ]
    }
   
  }

  addRow () {
    let postedScores
    let scoresToUse
    let adjustment
    if (this.rows.length === 0) {
      postedScores = 1
      scoresToUse = 1
      adjustment = 0
    }
    else {
      const lastItem = this.rows[this.rows.length-1]
      postedScores = lastItem.postedScores+1
      scoresToUse = lastItem.scoresToUse
      adjustment = lastItem.adjustment
    }
    
    const item = {
      postedScores,
      scoresToUse,
      adjustment
    }
    this.rows.push(item)
  }

  getMinPostedScores () {
    return this.rows[0].postedScores
  }
  getMaxPostedScores () {
    return this.rows[this.size-1].postedScores
  }
  setMinPostedScores (num) {
    this.minScores = num
    this.resetRows()
  }
  setMaxPostedScores (num) {
    let inc = num - this.getMaxPostedScores()

    if (inc > 0) {
      for(let i=0; i<inc; i++) {
        this.addRow()
      }
    }
    if (inc < 0) {
      inc = -inc;
      for(let i=0; i<inc; i++) {
        this.deleteRow()
      }
    }
    this.resetRows()
  }
  getRow (numScores) {
    if (numScores < this.getMinPostedScores()) numScores = this.getMinPostedScores()
    if (numScores > this.getMaxPostedScores()) numScores = this.getMaxPostedScores()
   
    const row = this.rows.find(r => r.postedScores === numScores)

    return row
  }
  getScoresToUse (numScores) {
   
    const row = this.getRow(numScores)
    return row.scoresToUse
  }
  getAdjustment (numScores) {

    const row = this.getRow(numScores)
    return row.adjustment
  }

  getInfo (numScores) {
    const row = this.getRow(numScores)
    let msg = ''
    if (row.scoresToUse === 1) {
      msg = 'Lowest Score'
    }
    else {
      msg = `Average of Lowest ${row.scoresToUse} Scores`
    }
    if (row.adjustment !== 0) msg += ` adjusted ${row.adjustment}`
    
    return msg
  }
  deleteRow () {
    if (this.rows.length > 1) {
      this.rows.splice(-1, 1)
    }
  }
  resetRows () {
    this.rows.forEach((row, index) => row.postedScores=this.minScores+index)
  }

  get size () {
    return this.rows.length
  }
  copy () {
    const tab = new IndexAvgTable()
    tab.setObj(this.getObj())
    return tab
  }

  clear () {
    this.initialize()
  }
 

  getData () {
    return {
      minScores: this.minScores,
      rows: this.rows
    }
  }
  setData (data) {
    this.minScores = data.minScores
    this.rows = data.rows
    for (let row of this.rows) {
      if (!('adjustment' in row)) {
        row.adjustment = 0
      }
    }
  }
  getObj () {
    return {
      minScores: this.minScores,
      rows: this.rows.map(o=>{
        return {...o}
      })
    }
  }
  setObj (data) {
    this.minScores = data.minScores
    this.rows = data.rows.map(o=>{
      return {...o}
    })
  }
}




