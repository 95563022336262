<template>
  <div>
    <toolbar :itemSelected.sync="itemSelected" @selected="selectTab"/>

    <div  >
   
      <b-notification :active.sync="error">
          {{ errorMsg }}
      </b-notification>

      <template v-if="!isMobile">
        <div class="level has-margin-bottom-15">
          <div class="level-left">
            <div class="level-item">
              <b-dropdown class="has-margin-top-10 has-margin-left-10" v-if="userName==='Ken Elston'" :triggers="['hover']">
                <b-button class=" title is-4" icon-right="menu-down" slot="trigger">
                  Ken Elston
                </b-button>
                <b-dropdown-item value="runUpdateProc" @click="runUpdateProc">Run Update Process</b-dropdown-item>
                <b-dropdown-item value="backupDb" @click="backupDb">Backup Database</b-dropdown-item>
                <b-dropdown-item value="delUsers" @click="deleteAnonymousUsers">Delete Anon Users</b-dropdown-item>
                <b-dropdown-item value="exportRounds" @click="exportRounds">Export Rounds</b-dropdown-item>
                <b-dropdown-item value="exportScores" @click="exportScores">Export Scores</b-dropdown-item>
                <b-dropdown-item value="exportPlayers" @click="exportPlayers">Export Players</b-dropdown-item>
                <b-dropdown-item value="exportUsers" @click="exportUsers">Export Users</b-dropdown-item>


              </b-dropdown>
              <p v-else class="title is-4 has-margin-top-10 has-margin-left-10 has-margin-bottom-5">{{ userName }}</p>
            </div>
            
            <div v-if="roundInProgress!==null" class="level-item">
              <b-button type="is-info" @click="handleSchRoundClick(roundInProgress)">{{roundInProgressLabel}}</b-button>
              
            </div>
            <div v-if="roundInProgress!==null" class="level-item">
              <p class="is-size-6">{{roundInProgress.teeTimeLabel}}</p>
            </div>
   
          
          </div>
        </div>
        <b-tabs
          class="has-padding-10"
         
          v-model="activeTab" >
          <b-tab-item label="Home" icon="home"><user-home :user="user" /></b-tab-item>
          <b-tab-item label="Season Results" icon="view-dashboard"><user-game-summary :user="user"  /></b-tab-item>
          <b-tab-item label="Rounds" icon="golf"><user-rounds :user="user"  /></b-tab-item>
        </b-tabs>
      </template>
      <template v-else>
        <user-home v-if="itemSelected==='home'" :user="user" />
        <user-game-summary v-if="itemSelected==='games'" :user="user"  />
        <user-rounds v-if="itemSelected==='rounds'" :user="user"  />
      </template>
    </div>

    
    <b-modal  :active.sync="showAbout" >
      <about />
    </b-modal>
    <b-modal has-modal-card :active.sync="showAccount" >
      <account />
    </b-modal>
   
  </div>
</template>

<script>
import UserGameSummary from '../../components/User/GameSummary'
import UserHome from '../../components/User/Home'
import UserRounds from '../../components/User/Rounds'
import Toolbar from '../../components/Shared/toolbar'
import About from '../../components/Dialogs/About'
import Account from '../../components/Dialogs/Account'

import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      itemSelected: 'home',
      showAbout: false,
      showAccount: false
    }
  },

  components: {
    'user-home': UserHome,
    'user-game-summary': UserGameSummary,
    'user-rounds': UserRounds,
    'toolbar': Toolbar,
    'about': About,
    'account': Account
  },


  computed: {
    ...mapGetters('user',[
      'user',
      'userPostedScores',
      'errorMsg'
    ]),
    group () {
      return this.$store.getters['group/group']
    },
    rounds () {
      return this.$store.getters['group/rounds']
    },
    
    error: {
      set (newValue) {
        if (!newValue) {
          this.$store.commit('user/errorMsg','')
        }
      },
      get () {
        return this.$store.getters['user/errorMsg'].length > 0
      }
    },
    activeTab: {
      get () {
        return this.$store.getters['user/tab']
      },
      set (val) {
        this.$store.commit('user/tab', val)
      }
    },
    userSchRoundsArray () {
      const userRounds = this.$store.getters['user/userSchRounds']
      let aRounds = []
      if (userRounds) aRounds = userRounds.aRounds
      return aRounds
    },
    roundInProgress () {
      let round = this.userSchRoundsArray.find(userRound => userRound.isInProgress && userRound.scoreDocId!=='')
   
      if (round === undefined) round = null
      return round

    },
    roundInProgressLabel () {
   
      return this.roundInProgress.formattedDate + ' Round IN PROGRESS'
    },
    userName () {
      let name = ''
      if (this.user) name = this.user.name
      return name
    }
  },

  methods: {
  
    saveUser () {
      this.$store.dispatch('user/save', this.user)
      if (!this.error) {
  
        this.$buefy.toast.open({
          message: 'Player information saved',
          type: 'is-success'
        })
      }
    },
    
  
    gotoGroup () {
      this.$router.push('/group')
    },

    selectTab (tabName) {
      if (tabName === 'home') {
        this.activeTab = 0
   
        this.itemSelected = tabName
      }

      if (tabName === 'games') {
        this.activeTab = 1
   
        this.itemSelected = tabName
      }
     
      if (tabName === 'rounds') {
        this.activeTab = 3
     
        this.itemSelected = tabName
      }

      if (tabName === 'about') {
        this.showAbout = true
      }

      if (tabName === 'account') {
        this.showAccount = true
      }
    
    },
     async handleSchRoundClick (userRound) {
    
      if (userRound.scoreDocId === '' && userRound.isScheduled) {
        const player = this.playersArray.find(p => p.docId === this.user.playerDocId)
        this.$store.dispatch('round/signUp',player)
        
      }
      else {
        if (userRound.isInProgress || userRound.isCompleted) {
          await this.$store.dispatch('round/open', {groupDocId: this.group.docId, roundDocId: userRound.docId, playerScoreDocId: userRound.scoreDocId, roundNum: ''})
        }
        else {
          await this.$store.dispatch('round/cancelSignUp',{groupDocId: this.group.docId, scoreDocId: userRound.scoreDocId})
          this.$buefy.toast.open({
            message: 'Signup canceled for ' + userRound.formattedDate,
            type: 'is-success'
          })
        }
        
      }
    },
    runUpdateProc () {

      this.$store.dispatch('group/updateAllRounds')
    

    },
    backupDb () {

      this.$store.dispatch('user/backupDb')
    },
    deleteAnonymousUsers () {
      this.$store.dispatch('group/deleteAnonymousUsers')
    },
    exportRounds () {
      this.$store.dispatch('user/exportRounds')
    },
    exportScores () {
      this.$store.dispatch('user/exportScores')
    },
    exportPlayers () {
      this.$store.dispatch('user/exportPlayers')
    },
    exportUsers () {
      this.$store.dispatch('user/exportUsers')
    }

  
  }
 

}

</script>

<style>
 
  

</style>



