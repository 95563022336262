import { FSTimestampToDate, DateToFSTimestamp, getDataValue } from '../lib'
import dayjs from 'dayjs'

// import store from '../store'

export default class TeeTime {
  constructor(timeVal=dayjs().hour(9).toDate()) {
    this.groupNum = 1
    this.timeVal = timeVal
    this.editTime = false
    this.startHole = '1'
    this.scoreDocIds = [null, null, null, null],
    this.scores = [null, null, null, null]
    this.scoreCount = 0

  }

  get time () {
    return dayjs(this.timeVal).format('h:mm A')
  }

  get time24 () {
    return dayjs(this.timeVal).format('HH:mm')
  }

  get timeObj () {
    let hours = this.timeVal.getHours()
    let ampm = 'AM'
    if (hours > 12) hours -= 12
    if (hours > 11) ampm = 'PM'
    
    const time = {
      hh: hours.toString(),
      mm: this.timeVal.getMinutes().toString(),
      A: ampm
    }
   
    return time
  }

  scoreDocIdsArray () {
    return this.scoreDocIds.filter(id=>id!==null)
  }

  slots (index) {
    // if (this.scores[index] === null && (this.scoreDocIds[index] !== null && this.scoreDocIds[index] !== '')) {
    //   const score = store.getters['round/scoresArray'].find(s=>s.docId === this.scoreDocIds[index])
    //   if (score !== undefined) {
    //     this.scores[index] = score
       
    //   }
    
    // }
    return this.scores[index]
  }

  slotsDocId (index) {
    // if (this.scores[index] === null && (this.scoreDocIds[index] !== null && this.scoreDocIds[index] !== '')) {
    //   const score = store.getters['round/scoresArray'].find(s=>s.docId === this.scoreDocIds[index])
    //   if (score !== undefined) {
    //     this.scores[index] = score
       
    //   }
    
    // }
    return this.scoreDocIds[index]
  }

  assignScores (scoresArray) {
    for (let i=0; i<this.scoreDocIds.length; i++) {
      let docId = this.scoreDocIds[i]
     
      if (docId !== null) {
        const score = scoresArray.find(s=>s.docId===docId)
        if (score !== undefined) this.scores[i] = score
      }
    }
  }

  countScores () {
    let count = 0
    for (let i=0; i<4; i++) {
      if (this.scoreDocIds[i] !== null && this.scoreDocIds[i] !== '') {
        count++
      }
    }
    return count
  }

  get firstScore () {
    let score = null
    if (this.countScores() > 0) {
      score = this.scoresArray()[0]
    }

    return score
  }

  get lastScore () {
    let score = null
    const numScores = this.countScores()
    if (numScores > 0) {
      score = this.scoresArray()[numScores-1]
    }

    return score
  }

  scoresArray () {
    // check to see if there are any unloaded scores
    // for (let i=0; i<4; i++) {
    //   if (this.scores[i] === null && this.scoreDocIds[i] !== null) {
    //     const score = store.getters['round/scoresArray'].find(s=>s.docId === this.scoreDocIds[i])
    //     if (score !== undefined) {
       
    //       this.scores[i] = score
    //     }
       
    //   }
    // }
    return this.scores.filter(slot=>slot!==null)
  }

  // assignScore (score) {
  //   for (let i=0; i<4; i++) {
  //     if (this.scoreDocIds[i] === score.docId) {
  //       this.slots[i] = score
  //     }
  //   }
  // }
  assignEmptySlotByDocId (docId) {
    const index = this.findEmptySlot()
    
    let assigned = false
    if (index >= 0) {
    
      this.scoreDocIds[index] = docId
      assigned = true

      // if (this.scores[index] === null) {
      //   const sc = store.getters['round/scoresArray'].find(s=>s.docId === score.docId)
      //   if (sc !== undefined) {
      //     this.scores[index] = sc
      //   }
      // }
    }
    return assigned
  }
  assignEmptySlot (score) {
    return this.assignEmptySlotByDocId (score.docId)
  }

  findEmptySlot () {
    return this.scoreDocIds.indexOf(null)
  }

  numAssigned () {
    let count = 0
    for(let i=0; i<4; i++) {
      if (this.scoreDocIds[i] !== null && this.scoreDocIds[i] !== '') count++
    }
    return count
  }


  unassign (slotIndex, val=null) {
    this.scores[slotIndex] = null
    this.scoreDocIds[slotIndex] = val
  }

  unassignAll () {
    for (let i=0; i<4; i++) {
      this.unassign(i, null)
    }
  }

  unassignScore (score) {
    for (let i=0; i<4; i++) {
      if (this.scoreDocIds[i] === score.docId) {
        this.unassign(i, null)
      }
    }
  }

  isFull () {
    let full = true
    for (let docId of this.scoreDocIds) {
      if (docId === null) {
        full = false
        break
      }
    }
    return full
  }

  isEmpty () {
    let empty = true
    for (let docId of this.scoreDocIds) {
      if (docId !== null) {
        empty = false
        break
      }
    }
    return empty
  }

  assigned (score) {
    let scoreDocId=''
    if (typeof score === "string") {
      scoreDocId = score
    }
    else {
      scoreDocId = score.docId
    }
    const index = this.scoreDocIds.indexOf(scoreDocId) 
   
    // if (index >= 0) {
    //   if (this.scores[index] === null ) {
    //     const score = store.getters['round/scoresArray'].find(s=>s.docId === scoreDocId)
    //     if (score !== undefined) {
    //       this.scores[index] = score
    //     }
    //   }
    // }
    

    return index >= 0
  }

  getSlotIndexByScore (score) {
    let index = -1
    for (let i=0; i<4; i++) {
      if (score.docId === this.scoreDocIds[i]) {
        index = i
        break
      }
    }
    return index
  }

  copy () {
    const teeTime = new TeeTime()
    teeTime.setObj(this.getObj())
    return teeTime
  }

  toJSON () {
    return JSON.stringify(this.getObj())
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  getObj () {
    return {
      groupNum: this.groupNum,
      timeVal: new Date(Number(this.timeVal)),
      editTime: this.editTime,
      startHole: this.startHole,
      scoreDocIds: [...this.scoreDocIds],
      scores: [...this.scores], // make sure scores objects are copied by ref
      scoreCount: this.scoreCount
    }
  }
  setObj (data) {
    this.groupNum = data.groupNum,
    this.timeVal = new Date(Number(data.timeVal))
    this.editTime = data.editTime
    this.startHole = data.startHole,
    this.scoreDocIds = [...data.scoreDocIds]
    this.scores = [...data.scores] // make sure scores objects are copied by ref
    this.scoreCount = data.scoreCount
  }
  getData () {
   
    return {
      timeVal: DateToFSTimestamp(this.timeVal),
      editTime: this.editTime,
      startHole: this.startHole,
      scoreDocIds: this.scoreDocIds,
      scoreCount: this.scoreCount
    }
  }
  setData (data) {
    this.timeVal = FSTimestampToDate(data.timeVal)
    // this.time24 = dayjs(this.timeVal).format('HH:mm A')
    this.editTime = data.editTime===undefined?false:data.editTime
    this.startHole = data.startHole,
    this.scoreDocIds = data.scoreDocIds
    this.scores = [null, null, null, null]
    this.scoreCount = getDataValue(data, 'scoreCount', 0)
  }
}
 