<template>
  <div>
    <b-progress v-if="showHandicapProgress" type="is-success" :value="handicapProgressCount" />
    <div class="columns is-marginless">
      <div class="column is-paddingless has-padding-left-10">
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <b-select v-model="playerFilter">
                <option
                  v-for="playerType in playerTypes"
                  :value="playerType"
                  :key="playerType"
                >{{ playerType }}</option>
              </b-select>
            </div>
            <div class="level-item">
              <p>{{ playersArray.length===0 ? 'Add Players To Your Group' : `${filteredPlayers.length} players out of ${playersArray.length}` }}</p>
            </div>
            <div v-if="isAdmin" class="level-item">
               <b-button type="is-primary" class="control" icon-left="account" @click="addPlayer" :disabled="disableAdd">Add</b-button>
           
            </div>
          </div>
        </div>
     
      </div>
      <div class="column ">
        <div v-if="isAdmin" class="level is-mobile ">
          <div v-if="!isMobile" class="level-left"></div>
          <div class="level-right">
            <div class="level-item">
              <input ref="import" type="file" id="importButton" style="display:none;" accept=".csv" @change="importPlayers"/>
          
              <b-dropdown class="control" :triggers="['hover']">
                <b-button icon-right="menu-down" slot="trigger">
                  Import/Export
                </b-button>
                <b-dropdown-item value="importPlayers" @click="$refs.import.click()">Import players from csv file</b-dropdown-item>
                <b-dropdown-item value="exportPlayers" @click="exportPlayers">Export players to csv file</b-dropdown-item>
                <b-dropdown-item value="exportEmails" @click="exportEmails">Export emails to clipboard</b-dropdown-item>

              </b-dropdown>
            </div>
            <div class="level-item">
              <b-button class="control" @click="updateHandicaps" :disabled="readOnly">Lookup Handicaps</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isMobile">
      <table class="table" width="100%">
        <tbody>
          <tr v-for="(player, index) in filteredPlayers" :key="index" @click="openPlayerDetails(player)">
            <td>
              {{player.name}}
            </td>
            <td>
              {{player.type}}
            </td>
            <td>
              {{player.tee}}
            </td>
            <td >
              <a >
                <b-icon icon="chevron-right"/>
              </a>
              
            </td>
          </tr>
        </tbody>
      </table>
     
    </div>
    <div v-else>

      <b-table v-if="readOnly" :data="filteredPlayers" default-sort="name">
     
          
          <b-table-column field="name" label="Name" sortable v-slot="props">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column field="email" label="Email" sortable v-slot="props">
            {{ props.row.email }}
          </b-table-column>

           <b-table-column field="gender" label="Gender" sortable v-slot="props">
            {{ props.row.gender }}
          </b-table-column>
          
          <b-table-column field="type" label="Type" sortable v-slot="props">
            {{ props.row.type }}
          </b-table-column>

          <b-table-column
            cell-class="vert-middle"
            field="admin"
            label="Admin"
            width="40"
            centered
            v-slot="props"
          >
            <b-icon v-if="props.row.admin" icon="check" />
          </b-table-column>

          <b-table-column cell-class="vert-middle" label="Registered?" width="80" centered v-slot="props">
            <b-icon v-if="props.row.registered" icon="check" />
          </b-table-column>

          <b-table-column field="tee" label="Tee" v-slot="props">
            {{ props.row.tee }}
          </b-table-column>

          <b-table-column field="ghinNum" label="GHIN #" width="120" v-slot="props">
            {{ props.row.ghinNum }}
          </b-table-column>

      
      </b-table>
      <b-table v-else :data="filteredPlayers" default-sort="name" @keyup.enter="addPlayer">
     
         
          <b-table-column field="name" label="Name" sortable v-slot="props">
            <b-field>
              <b-input
                :ref="'playerName' + props.index"
                :value="props.row.name"
                size="is-capitalized"
                @blur="capName(props.row.docId, $event.target.value)"
              />
            </b-field>
          </b-table-column>
          <b-table-column cell-class="vert-middle" field="email" label="Email" sortable v-slot="props">
            <span  v-if="props.row.registered">
              {{ props.row.email }}
            </span>
            <b-field v-else>
              <b-input
                :value="props.row.email"
                @blur="updateEmail(props.row.docId,$event.target.value)"
              
              />
            </b-field>
          </b-table-column>

          <b-table-column field="gender" label="Gender" sortable v-slot="props">
            <b-select :value="props.row.gender" @input="value=>updateField('gender',props.row.docId,value)">
              <option value="M">M</option>
              <option value="F">F</option>
            </b-select>
          </b-table-column>
          
          <b-table-column field="type" label="Type" sortable v-slot="props">
            <b-select :value="props.row.type" @input="value=>updateField('type',props.row.docId,value)">
              <option
                v-for="playerType in playerTypes"
                :value="playerType"
                :key="playerType"
              >{{ playerType }}</option>
            </b-select>
          </b-table-column>

          <b-table-column
            cell-class="vert-middle"
            field="admin"
            label="Admin"
            width="40"
            centered
            v-slot="props"
          >
          
            <b-switch
              :value="props.row.admin"
              @input="value=>updateAdmin(props.row.docId, value)"
            />
           
          </b-table-column>

          <b-table-column cell-class="vert-middle" label="Registered?" width="80" centered v-slot="props">
            <b-icon v-if="props.row.registered" icon="check" />
            <a v-else class="has-text-link" @click="invitePlayer(props.row.email)">Invite</a>
          </b-table-column>
          <b-table-column
            cell-class="vert-middle"
            field="sendInvite"
            label="Send Invite"
            width="40"
            centered
            v-slot="props"
          >
          
            <b-switch
              :value="props.row.sendInvite"
              @input="value=>updateSendInvite(props.row.docId, value)"
            />
           
          </b-table-column>
          <b-table-column field="tee" label="Tee" v-slot="props">
            <b-select :value="props.row.tee" @input="value=>updateTee(props.row.docId,value)">
              <option v-for="tee in playerTees(props.row.gender)" :value="tee" :key="tee">{{ tee }}</option>
            </b-select>
          </b-table-column>
          <b-table-column field="ghinNum" label="GHIN #" width="120" v-slot="props">
            <b-input
              :value="props.row.ghinNum"
              @blur="updateField('ghinNum',props.row.docId,$event.target.value)"
            
            />
          </b-table-column>
       
 
          <b-table-column
            cell-class="vert-middle"
            field="handicap"
            label="Handicap"
            centered
            v-slot="props"
          >
            <a :ref="'indexSource'+props.row.docId" class="has-text-link" @click="lookupHandicap(props.row)">
              {{ props.row.handicap===null ? 'Lookup' : `${formatHandicapObj(props.row.handicap,'full18')} (${props.row.indexSource}) ${ props.row.index.full18}` }}
            </a>
          </b-table-column>
          <b-table-column cell-class="vert-middle" custom-key="delete" v-slot="props">

     <!--       <b-tooltip label="Link player to Guest rounds">
              <b-icon icon="link-variant" @click.native="linkPlayer(props.row)"/>
            </b-tooltip> -->
            
            <b-tooltip :label="'Delete ' + props.row.name">
              <span class="icon has-margin-left-10" @click="deletePlayer(props.row.docId)">
                <i class="delete"></i>
              </span>
            </b-tooltip>
          </b-table-column>
      
      </b-table>
    </div>
    <b-modal :active.sync="showHandicapDetails" has-modal-card :full-screen="isMobile">
      <handicap-details 
        :indexLookup = "indexLookup" 
        :handicapCalc = "handicapCalc"
        :playerInfo = "handicapPlayerInfo"
        :playerTees = "playerTees(handicapPlayerInfo.gender)"
        :viewOnly = "false"
        :handicapFac = "group.getHandicapFac()"
        @useHandicap="setIndex" />
    </b-modal>
    
    <b-modal :active.sync="showPlayerLookup" has-modal-card>
      <ghin-player-lookup :lookup="lookup" :indexLookup="indexLookup" @selectPlayer="selectPlayer" />
    </b-modal>

    <b-modal 
      :active.sync="showPlayerDetails"
      has-modal-card 
      full-screen
      :can-cancel="false"
      style="z-index:100;"
     >
      <player-details 
        :player="playerDetails" 
        :playerTypes="playerTypes" 
        :playerTees="playerTees(playerDetails===null?'M':playerDetails.gender)"
        :indexLookup="indexLookup"
        :handicapCalc="handicapCalc"
        :group="group"
        @savePlayerMobile="savePlayerMobile"
        @deletePlayerMobile="deletePlayerData"
      />
   
       
    </b-modal>
  </div>  
</template>

<script>

import GroupController from '../../controllers/group'
import UserController from '../../controllers/user'
import Player from '../../models/player'

import HandicapDetails from '../Dialogs/HandicapDetails'
import PlayerDetails from '../Group/PlayerDetails'
import IndexLookup from '../../helpers/indexLookup'
import GhinPlayerLookup from '../Dialogs/GhinPlayerLookup'

import HandicapCalc from '../../helpers/handicapCalc'
import IndexItem from '../../helpers/indexItem'
import DBUpdates from '../../helpers/dbUpdates.js'


import { mapGetters } from "vuex"

import { capitalizeWord, copyToClipboard, sortArrayObj, formatHandicapObj } from "../../lib"
const parseFullName = require('parse-full-name').parseFullName

import Papa from 'papaparse'
import FileSaver from 'file-saver'

import dayjs from 'dayjs'
import SettingsVue from '../Tournament/Settings.vue'

export default {
  data() {
    return {
      showHandicapDetails: false,
      showPlayerDetails: false,
      playerDetails: null,
      indexLookup: null,
      handicapCalc: null,
      handicapPlayerInfo: {},
      handicapPlayerDocId: '',
      playerFilter: 'Active',
      playerTypes: ['All','New', 'Active', 'Part Time', 'Inactive'],
      filteredPlayers: [],
      showPlayerLookup: false,
      lookup: {},
      showHandicapProgress: false,
      handicapProgressCount: 0
    };
  },
  components: {
    'handicap-details': HandicapDetails,
    'player-details' : PlayerDetails,
    'ghin-player-lookup': GhinPlayerLookup
  },


  mounted() {
    
    this.filterPlayers()
    
  },
 
  watch: {
    tab(val) {
      if (val === 0) {
      
        this.filterPlayers()
      }
    },
    playerFilter() {
      this.filterPlayers()
    },
    playersArray: {
      handler: function (val, oldVal) {
        if (val.length !== oldVal.length) {
          this.filterPlayers()
        }
      }
    }
  },
  computed: {
    ...mapGetters('group', [
      'group',
      'tab',
      'playersArray'
    ]),
   
    user () {
      return this.$store.getters['user/user']
    },
    isAdmin () {
      let admin = this.$store.getters['user/isAdmin']
     
      return admin
    },
    readOnly () {
      return !this.isAdmin
    },
    disableAdd () {
      return !this.isAdmin || this.playerFilter != 'New'
    },
    useGameIndex () {
      let gameIndex = false
      if (this.group) gameIndex = this.group.handicap.useGameIndex
      return gameIndex
    },
    useGhinIndex () {
      let ghinIndex = false
      if (this.round) ghinIndex = this.round.handicap.lookupGhinIndex
      return ghinIndex
    }
   
  },
  methods: {
    formatHandicapObj,
    
    playerTees (gender) {
      return this.group.course.getPlayerTees(gender)
    },

    openPlayerDetails (player) {
   
      if (player === '') {
        this.playerDetails = new Player()
      }
      else {
        this.playerDetails = player
      }
      
      this.showPlayerDetails = true
      this.indexLookup = new IndexLookup(this.getIndexLookupSettings())
      this.handicapCalc = new HandicapCalc(this.group.course, this.group.handicap.maxHandicap)
    },
    async savePlayerMobile (playerData) {
    
      let player
      if (playerData.docId === '') { 
        player = new Player()
        player.setData(playerData)
        this.$store.dispatch('group/addPlayer', player)
       
      }
      else {
        player = this.playersArray.find(p => p.docId === playerData.docId)
        const update = {}
     
        if (playerData.admin !== player.admin) update.admin = playerData.admin
        if (playerData.name !== player.name) update.name = playerData.name
        if (playerData.registered !== player.registered) update.registered = playerData.registered
        if (playerData.email !== player.email) update.email = playerData.email
        if (playerData.gender !== player.gender) update.gender = playerData.gender
        if (playerData.type !== player.type) update.type = playerData.type
        if (playerData.tee !== player.tee) update.tee = playerData.tee
        if (playerData.ghinNum !== player.ghinNum) update.ghinNum = playerData.ghinNum
        if (playerData.index.full18 !== player.index.full18) update.index = playerData.index
        if (playerData.indexSource !== player.indexSource) update.indexSource = playerData.indexSource
        if (playerData.handicap.full18 !== player.handicap.full18) update.handicap = playerData.handicap
      
        const playerUpdates = new DBUpdates()
        playerUpdates.add(playerData.docId, update)
        await this.$store.dispatch('group/savePlayerValues', playerUpdates)
    

        if (playerData.invite) {
          this.invitePlayer(playerData.email)
        }
      
    
      }
    },
    filterPlayers() {
      if (this.playerFilter !== 'All') {
        this.filteredPlayers = this.playersArray.filter(p => p.type === this.playerFilter)
      }
      else {
        this.filteredPlayers = this.playersArray
      }
      
      sortArrayObj(this.filteredPlayers, 'name')
     
    },
    async capName(playerDocId, name) {
      
      const value = {
        name: capitalizeWord(name)
      }
      await this.updatePlayerValue(playerDocId, value)
    },
    async addPlayer() {
      if (this.isMobile) {
        this.openPlayerDetails('')
      }
      else {
        const player = new Player()
        player.type = 'New'
        const response = await GroupController.addPlayer(this.group.docId, player)
        if (response.errorMsg === '') {
         
          this.filterPlayers()

          // this.$store.commit('group/addPlayer', player.copy())

          this.$nextTick(() => {
            
            const playerName = 'playerName' + (this.filteredPlayers.length - 1)
            if (this.isMobile) {
              this.$refs.players.toggleDetails(player)
              this.$nextTick(() => this.$refs[playerName].focus())
            }
            else {
              this.$refs[playerName].focus()
            }
          })
        }
        else {
          this.$store.commit('group/errorMsg', response.errorMsg)
        }
      }
      
      

    },
    deletePlayer(playerDocId) {
      this.$buefy.dialog.confirm({
        message: 'Delete Player?',
        onConfirm: async () => {
          this.deletePlayerData(playerDocId)
        }
      })
    },
    async deletePlayerData (playerDocId) {
      const response = await GroupController.deletePlayer(this.group.docId, playerDocId)
      if (response.errorMsg === '') {
        this.filterPlayers()
    
      }
      else {
        this.$store.commit('group/errorMsg', response.errorMsg)
      }
    },
    async linkPlayer (player) {

      const response = await GroupController.linkPlayer(this.group.docId, player)
      if (response.errorMsg === '') {
        let msg = ''
        if (response.numScores === 0) {
          msg = "No Guest rounds found"
        }
        else {
          msg = 'Player linked to ' + response.numScores + ' Guest rounds'
        }
        this.$buefy.toast.open(msg)
    
      }
      else {
        this.$store.commit('group/errorMsg', response.errorMsg)
      }
    },
   
    async updateAdmin (playerDocId, val) {
 
      await this.updateField('admin', playerDocId, val)
    },
    async updateSendInvite (playerDocId, val) {
 
      await this.updateField('sendInvite', playerDocId, val)
    },
    async updateEmail (playerDocId, email) {
      email = email.toLowerCase()
      const player = this.playersArray.find(p => p.docId === playerDocId)
      let resp
      if (player.email !== email) {
        const update = {
          email: email
        }
        resp = await UserController.isRegistered(email, this.group.docId, playerDocId)
     
        if (resp.registered) {
          update.registered = true
        // if member of another group get ghin number
          resp = await UserController.getGhinNum(email)
          if (resp.ghinNum.length > 0) {
            update.ghinNum = resp.ghinNum
            update.tee = resp.tee
          }
        }
        const playerUpdates = new DBUpdates()
        playerUpdates.add(playerDocId, update)
        await this.$store.dispatch('group/savePlayerValues', playerUpdates)
      
        resp = await GroupController.hasGuestRounds(this.group.docId, player)
        if (resp.hasGuestRounds) {
          this.$buefy.dialog.confirm({
              message: 'This player has played past rounds as a Guest. Convert guest rounds to player rounds? ',
              cancelText: 'No',
              confirmText: 'Yes',
              onConfirm: async () => await this.linkPlayer (player)
          })
        }
        
      }
     
    },
    async updateTee (playerDocId, val) {
      const player = this.playersArray.find(p => p.docId === playerDocId)
      const update = {tee: val}
      if (player.index !== null) {
        const hc = new HandicapCalc(this.group.course, this.group.handicap.maxHandicap)
        const indexInfo = new IndexItem()
        indexInfo.index = player.index.full18
        indexInfo.source = player.indexSource

        update.handicap = hc.getPlayingHandicapObj(indexInfo, val, this.group.getHandicapFac(), player.gender)
      }
      await this.updatePlayerValue (playerDocId, update)
   
    },

    async updateField(field, playerDocId, val) {
      const value = {}
      value[field] = val
      const player = this.playersArray.find(p => p.docId === playerDocId)
      if (value[field] !== player[field]) {
        // if (field === 'tee') {
        //   const hc = new HandicapCalc(this.group)
        //   const index = {full18: player.index}
        //   player.handicap = hc.adjCourseHandicapSide(index, player.tee, this.group.getHandicapFac(), 'full18')
        // }
        await this.updatePlayerValue(playerDocId, value)
      }
     

    },
    // updateIndex (playerDocId, index) {
    //   const player = this.playersArray.find(p => p.docId === playerDocId)
 
    //   player.index = index
    //   player.indexSource = 'Manual'
    //   const hc = new HandicapCalc(this.group.course, this.group.handicap.maxHandicap)
  
    //   player.handicap = hc.adjCourseHandicapSide(index, player.tee, this.group.getHandicapFac(), 'full18')
    
    // },

    async updatePlayerValue (playerDocId, value) {
      const playerUpdates = new DBUpdates()
      playerUpdates.add(playerDocId, value)
      await this.$store.dispatch('group/savePlayerValues', playerUpdates)
    },
  
    getIndexLookupSettings () {
      const settings = {
        handicapSettings: this.group.handicap,
        groupDocId: this.group.docId,
        // state: this.group.course.state,
        // roundDate: dayjs().toDate(),
        // lookupGroupScores: this.group.handicap.useGameIndex,
        // lookupGhinIndex: this.group.handicap.lookupGhinIndex
      }
        
      return settings
    },
    async updateHandicaps () {
      this.showHandicapProgress = true
      this.handicapProgressCount = 0

      const settings = this.getIndexLookupSettings()
      const hc = new HandicapCalc(this.group.course, this.group.handicap.maxHandicap)

      let count = 0
  
      for(let player of this.playersArray) {
       
        const indexLookup = new IndexLookup(settings) 

        const playerInfo = {
          playerDocId: player.docId,
          name: player.name,
          ghinNum: player.ghinNum,
          state: this.group.course.state,
          type: player.type
        }
        player.handicap = null

        const indexInfo = await indexLookup.getIndexInfo(playerInfo)
      
        if (indexInfo.index) {
          player.index = indexInfo.getIndexObj()
          player.indexSource = indexInfo.indexSource
          player.handicap = hc.getPlayingHandicapObj(indexInfo, player.tee, this.group.getHandicapFac(), player.gender)
        }
        else {
          player.index = null
          player.indexSource = ''
          player.handicap = null
        }

        count++

        const update = {
          index: player.index,
          handicap: player.handicap,
          indexSource: player.indexSource,
        }
        if (player.ghinNum==='' && indexInfo.ghinNum !== '') {
          player.ghinNum = indexInfo.ghinNum
          update.ghinNum = indexInfo.ghinNum
        }
        await this.updatePlayerValue (player.docId, update)

        this.handicapProgressCount = count / this.playersArray.length * 100
        
      }

      this.showHandicapProgress = false
    },

    async lookupHandicap (row) {
      const settings = this.getIndexLookupSettings()

      
      this.indexLookup = new IndexLookup(settings)
      this.handicapPlayerInfo = {
        playerDocId: row.docId,
        name: row.name,
        ghinNum: row.ghinNum,
        indexSource: row.indexSource===''?'Group':row.indexSource,
        tee: row.tee,
        gender: row.gender,
        type: row.type,
        clubName: this.group.course.name,
        state: this.group.course.state
      }
      this.handicapPlayerDocId = row.docId

      if (this.useGameIndex) {
        
        this.handicapCalc = new HandicapCalc(this.group.course, this.group.handicap.maxHandicap)
        this.showHandicapDetails = true
      }
      else {
        if (row.ghinNum === '') {
          this.lookup = {
            state: this.group.course.state,
            firstName: parseFullName(row.name).first,
            lastName: parseFullName(row.name).last
          }
          this.showPlayerLookup = true
        }
        else {
          const indexInfo = await this.indexLookup.getGhinIndex(this.handicapPlayerInfo, 'full18')
         
          await this.setIndex (indexInfo)
          
        }
        
      }
    },

    async selectPlayer (selectedPlayer) {
    
      this.showPlayerLookup = false
      const indexInfo = new IndexItem()
      indexInfo.index = selectedPlayer.index
      indexInfo.indexSource = 'GHIN'
      indexInfo.ghinNum = selectedPlayer.ghinNum
      await this.setIndex ( indexInfo )


  
      
    },
    async setIndex (indexInfo) {



      const player = this.filteredPlayers.find(p=>p.docId===this.handicapPlayerDocId)

      player.index = indexInfo.getIndexObj()
      player.indexSource = indexInfo.indexSource

      if (player.index === 'null') {
        player.handicap = null
      }
      else {

        const hc = new HandicapCalc(this.group.course, this.group.handicap.maxHandicap)

        player.handicap = hc.getPlayingHandicapObj(indexInfo, player.tee, this.group.getHandicapFac(), player.gender)
      }
      const update = {
        index: player.index,
        handicap: player.handicap,
        indexSource: player.indexSource,
      }

      if (player.ghinNum==='' && indexInfo.ghinNum !== '') {
          player.ghinNum = indexInfo.ghinNum
          update.ghinNum = indexInfo.ghinNum
      }
      await this.updatePlayerValue (player.docId, update)

    },
    invitePlayer (email) {
   
      this.$store.dispatch("group/sendRegisterInvite", {Email: email}, this.user.emailAddressObj)
      this.$buefy.toast.open({
        message: "Email Invitation Sent",
        type: 'is-success'
      })


    },
    
    importPlayers (event) {
    
      const file = event.target.files[0]
    
      Papa.parse(file, 
        {
          header: true,
          complete: async (results) => {
         
            let nameField = ''
            let ghinField = ''
            let teeField = ''
            let emailField = ''
            results.meta.fields.forEach(field => {
              if (field.toUpperCase() === 'NAME') nameField = field
              if (field.toUpperCase() === 'GHIN') ghinField = field
              if (field.toUpperCase() === 'TEE') teeField = field
              if (field.toUpperCase() === 'EMAIL') emailField = field
            })
         
          
            for(let row of results.data) {
            
              let playerIndex = -1
              if (emailField.length > 0) {
                playerIndex = this.playersArray.findIndex(player => player.email)
                let player
                if (playerIndex === -1) {
                  player = new Player()
                }
                else {
                  player = this.playersArray[playerIndex].copy()
                }
                if (nameField.length>0) {
                  player.name = row[nameField]
                }
                if (emailField.length>0) {
                  player.email = row[emailField]
                }
                if (ghinField.length>0) {
                  player.ghinNum = row[ghinField]
                }
                if (teeField.length>0) {
                  player.tee = row[teeField]
                }
                let response
                if (player.docId === '') {
                  response = await GroupController.addPlayer(this.group.docId, player)
                }
                else {
                  response = await GroupController.updatePlayer(this.group.docId, player)
                }
                
                if (response.errorMsg.length > 0) {
                  this.$store.commit('group/errorMsg', response.errorMsg)
                }
              }
            }
        
            this.filterPlayers()
          }
        }
      )
      
     
    },
    exportPlayers () {
      let filteredPlayers
      if (this.playerFilter === 'All') {
        filteredPlayers = this.playersArray
      }
      else {
        filteredPlayers = this.playersArray.filter(p => p.type === this.playerFilter)
      }
      const players = filteredPlayers.map(player => {
        return {
          name: player.name,
          email: player.email,
          gender: player.gender,
          type: player.type,
          tee: player.tee,
          ghinNum: player.ghinNum,
          index: player.index===null ? 'n/a' : player.index.full18,
          indexSource: player.indexSource,
          handicap: player.handicap===null ? 'n/a' : player.handicap.full18
        }
      })
      sortArrayObj(players, 'name')
      const csv = Papa.unparse(players)

      const blob = new Blob([csv], {type: "text/plain;charset=utf-8"})
      FileSaver.saveAs(blob, this.group.name + ' Player List.csv')
    },
    exportEmails () {
      let filteredPlayers
      if (this.playerFilter === 'All') {
        filteredPlayers = this.playersArray
      }
      else {
        filteredPlayers = this.playersArray.filter(p => p.type === this.playerFilter)
      }
      const emails = this.filteredPlayers.map(player => player.email).sort()

      const txt = emails.join(',')
    
      copyToClipboard(txt)
    }
  }
};
</script>

<style scoped>
.is-scrollable {
  height: 520px;
  overflow-y: auto;
}

.vert-middle {
  vertical-align: middle;
}
</style>
