<template>
  <div>
    <template v-if="isMobile">
      <template  v-if="haveGames">
        <b-collapse v-for="(game,index) in gamesArray"
          class="card has-margin-bottom-10" 
          :open="selectedGameId===game.id"
          @open="$emit('update:selectedGameId',game.id)"
          
          :key="index">
          <div
            slot="trigger" 
            slot-scope="props"
            class="card-header"
            role="button">
            <p class="card-header-title">
                {{game.name + ' ' + placesLabel(game, numPlayers(game.id))}}
            </p>
            <b-button class="mt-1" v-if="game.prop==='skins' && round.holeByHoleScores" @click="showSkinsByHole=true">
              Skins by Hole
            </b-button>
        
          
            <a class="card-header-icon">
              <b-icon
                  :icon="props.open ? 'menu-down' : 'menu-up'">
              </b-icon>
            </a>
          </div>
          <div class="card-content is-paddingless">
            <template v-if="game.prop==='stroke'">
              <div v-if="leaderBoardArray.length === 0">
                <p class="subtitle has-margin-left-10">No scores recorded yet</p>
              </div>
          
              <stroke-leaderboard v-else :round="round" :game="game" :leaderBoardArray="leaderBoardArray"/>
              
            </template>
            <template v-else-if="game.prop==='skins'">
              <div v-if="leaderBoardArray.length === 0">
                <p class="subtitle has-margin-10">{{'No ' + game.name + ' recorded yet' }}</p>
              </div>
              <skins-leaderboard v-else :round="round" :gameName="game.name" :leaderBoardArray="leaderBoardArray"/>
                

            </template>
            <template v-else-if="game.prop==='kp'">
              <div v-if="leaderBoardArray.length === 0">
                <p class="subtitle has-margin-10">{{'No ' + game.name + ' recorded yet' }}</p>
              </div>
              <kp-leaderboard v-else :round="round" :gameName="game.name" :leaderBoardArray="leaderBoardArray"/>
                

            </template>
            <template v-else-if="game.prop==='dots'">
              <div v-if="leaderBoardArray.length === 0">
                <p class="subtitle has-margin-10">{{'No ' + game.name + ' recorded yet' }}</p>
              </div>
              <dots-leaderboard v-else :round="round" :game="game" :leaderBoardArray="leaderBoardArray"/>
                

            </template>
          </div>
        </b-collapse>
      </template>
      <template v-else>
        <p class="subtitle has-margin-left-10">No games defined</p>
      </template>

    </template>

    <template v-else>
      <template v-if="haveGames" >
        <div class="level is-mobile" style="margin-top:5px;margin-bottom:5px;">
          <div class="level-left">
            <div class="level-item">
            
                <b-select :value="selectedGameId" @input="value=>$emit('update:selectedGameId',value)">
                  <option
                    v-for="(game,index) in gamesArray"
                    :value="game.id"
                    :key="index"
                  >{{ game.name }}</option>
                </b-select>
            
            </div>
            <template v-if="!isMobile && round.betOnGames">
              <div class="level-item">
                <p class="field-label has-text-weight-bold">Pool: {{formatCurrency(selectedGame.totalPool)}}</p>

              </div>
              <template v-if="selectedGame.prop==='skins'">
                <div class="level-item">
                  <b-button v-if="selectedGame.prop==='skins' && round.holeByHoleScores" @click="showSkinsByHole=true">
                    Skins by Hole
                  </b-button>
                </div>
              </template>
              <template v-else>
                <div class="level-item">
                  <p class="field-label">{{ placesLabel(selectedGame, numPlayers(selectedGame.id)) }}</p>
                </div>
              </template>
              
            </template>
          </div>
        </div>
        <template v-if="selectedGame.prop==='stroke'">
          <div v-if="leaderBoardArray.length === 0">
            <p class="subtitle has-margin-left-10">No scores recorded yet</p>
          </div>
          <stroke-leaderboard v-else :round="round" :game="selectedGame" :leaderBoardArray="leaderBoardArray"/>
        </template>

        <template v-else-if="selectedGame.prop==='skins'">
          <div v-if="leaderBoardArray.length === 0">
            <p class="subtitle has-margin-top-10 has-margin-left-10">{{'No ' + selectedGame.name + ' recorded yet' }}</p>
          </div>
          <skins-leaderboard v-else :round="round"  :leaderBoardArray="leaderBoardArray"/>
        </template>

        <template v-else-if="selectedGame.prop==='kp'">
          <div v-if="leaderBoardArray.length === 0">
            <p class="subtitle has-margin-top-10 has-margin-left-10">{{'No ' + selectedGame.name + ' recorded yet' }}</p>
          </div>
          <kp-leaderboard v-else :round="round"  :leaderBoardArray="leaderBoardArray"/>
        </template>

        <template v-else-if="selectedGame.prop==='dots'">
          <div v-if="leaderBoardArray.length === 0">
            <p class="subtitle has-margin-top-10 has-margin-left-10">{{'No ' + selectedGame.name + ' recorded yet' }}</p>
          </div>
          <dots-leaderboard v-else :round="round" :game="selectedGame" :leaderBoardArray="leaderBoardArray"/>
        </template>

      </template>
       
      <template v-else>
        <p class="subtitle has-margin-left-10">No games defined</p>
      </template>

    </template>
    
    <b-modal 
      v-if="round.games.hasGame('skins') && round.holeByHoleScores"
      :active.sync="showSkinsByHole"
      has-modal-card 
      :full-screen="isMobile"
      :can-cancel="true"
      style="z-index:50"
    >
      <div class="modal-card">
        
        <header v-if="!isMobile" class="modal-card-head">
          <button class="button mr-3" style="border:none;" @click="gotoSkinsHole(skinsHoleNum-1)">
            <b-icon type="is-dark" icon="arrow-left"  />
          </button> 
          <b-dropdown append-to-body @change="(value) => gotoSkinsHole(Number(value))">
            <button class="button is-medium" slot="trigger" slot-scope="{ active }">
              <span >Hole {{skinsHoleNum}}</span>
              <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
            </button>
            <b-dropdown-item v-for="n in 18" :key="n" :value="n">
              {{n}}
            </b-dropdown-item>
          </b-dropdown>
          <button class="button ml-3" style="border:none;" @click="gotoSkinsHole(skinsHoleNum+1)">
            <b-icon type="is-dark" icon="arrow-right"  />
          </button> 
              
        </header>
    
        <section class="modal-card-body" >
          <div v-if="isMobile" class="level is-mobile" >
            <div class="level-left">
              <div class="level-item">
                <a class="has-margin-top-5" @click="showSkinsByHole=false">
                  <b-icon icon='chevron-left'></b-icon>
                </a>
              </div>
              <div class="level-item">
                <button class="button mr-3" style="border:none;" @click="gotoSkinsHole(skinsHoleNum-1)">
                  <b-icon type="is-dark" icon="arrow-left"  />
                </button> 
                <b-dropdown append-to-body @change="(value) => gotoSkinsHole(Number(value))">
                  <button class="button is-medium" slot="trigger" slot-scope="{ active }">
                    <span >Hole {{skinsHoleNum}}</span>
                    <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                  </button>
                  <b-dropdown-item v-for="n in 18" :key="n" :value="n">
                    {{n}}
                  </b-dropdown-item>
                </b-dropdown>
                <button class="button ml-3" style="border:none;" @click="gotoSkinsHole(skinsHoleNum+1)">
                  <b-icon type="is-dark" icon="arrow-right"  />
                </button> 
              </div>
             
            </div>
          </div>
          <table class="table">
            <tbody>
              <tr v-for="(score, index) in skinsByHoleArray()" :key="index">
                <td>
                  <b-icon v-if="score.hasSkin" icon="check" />
                </td>
                <td>{{score.name}}</td>
                <td>{{score.skinScore}}</td>
              </tr>
            </tbody>
          </table>
        </section>
        <footer v-if="!isMobile" class="modal-card-foot">
          <button class="button" @click="showSkinsByHole=false">OK</button>
        </footer>
      </div>
     
    
    </b-modal>
  </div>

  
   
</template>

<script>

import StrokeLeaderboard from './StrokeLeadboard'
import SkinsLeaderboard from './SkinsLeaderboard'
import KpLeaderboard from './KpLeaderboard'
import DotsLeaderboard from './DotsLeaderboard'
import Game from "../../models/game"
import { mapGetters } from "vuex"
import { formatCurrency, sortArrayObj, handicapDotsLabel } from "../../lib"

export default {
  data() {
    return {
    
      strokePlayColumns: [
        { label: "Pos", field: "pos", centered: true, width: "50" },
        { label: "Name", field: "name" },
        { label: "Tee", field: "tee", centered: true, width: "50" },
        { label: "Score", field: "score", centered: true, width: "80" },
        { label: "To Par", field: "toPar", centered: true, width: "80" },
        { label: "Payout", field: "payOut", centered: true, width: "100" },
        { label: "Thru", field: "thru", centered: true, width: "80" }
      ],
      showSkinsByHole: false,
      skinsHoleNum: 1
    }
  },
  
  props: ['round', 'leaderBoards','selectedGameId'],

  components: {
    'stroke-leaderboard': StrokeLeaderboard,
    'skins-leaderboard': SkinsLeaderboard,
    'kp-leaderboard': KpLeaderboard,
    'dots-leaderboard': DotsLeaderboard
  },

  
  computed: {
    selectedGame () {
      let game
    
    
      game = this.round.games.get(this.selectedGameId)
      if (game === null) {
        if (this.gamesArray.length === 0) {
          game = new Game()
        }
        else {
          game = this.gamesArray[0]
        }
          
      }
      
      return game
    },
   
    games () {
      return this.round.games
    },
    gamesArray () {
      return this.round.games.aGames
    },
    haveGames () {
      return this.round.games.size > 0
    },

    leaderBoardArray () {
      let leaderBoard = []
     
      if (this.selectedGameId !== '') {
        if (Object.entries(this.leaderBoards).length > 0) {
        
          const selectedBoard = this.leaderBoards[this.selectedGameId]
          if (selectedBoard !== undefined) {
            leaderBoard = selectedBoard
          }
        }
      }
     
      return leaderBoard
      
    },
    activeScoresArray () {  // exclude WD scores
      let scoresArray
      if (this.round === null) {
        scoresArray = []
      }
      else {
        scoresArray = this.round.teeTimes.scoresArray().filter(s=>!s.wd)
      }
      return scoresArray
    },
 
    ...mapGetters('round', [
      // 'round',
      // 'leaderBoards',
      // 'scoresArray',
      'tab'
    ])
    
  },
  methods: {
    formatCurrency,
    skinsByHoleArray () {
      const skinsArray = []
    
      let scoreVal
      let handicapStrokes
      let par
      let netScore

      const game = this.games.getByProp('skins')
      for (let score of this.activeScoresArray) {
        if (!score.wd) {
          if (score.isHoleCompleted(this.skinsHoleNum)) {
            scoreVal = score.getHoleScore(this.skinsHoleNum)
            const halfStrokes = game.skinsHalfStroke ? .5 : 1
            handicapStrokes = score.getHandicapStrokesHole(this.skinsHoleNum, 'full18') 
            par = this.round.getHolePar(score.tee, this.skinsHoleNum)
            netScore = scoreVal - (handicapStrokes * halfStrokes)
            skinsArray.push({
              hasSkin: false,
              name: score.name + ' ' + handicapDotsLabel(handicapStrokes),
              netScore: netScore,
              skinScore: netScore // formatScore(par, scoreVal, netScore)
            })
          }
          else {
            skinsArray.push({
              hasSkin: false,
              name: score.name + ' ' + handicapDotsLabel(handicapStrokes),
              netScore: 1000,
              skinScore: 'n/a'
            })
          }
         
        }
      }
      sortArrayObj(skinsArray, 'netScore', 'asc')

      if (skinsArray.length > 0) {
        if (skinsArray.length === 1) {
          if (skinsArray[0].netScore !== 1000) skinsArray[0].hasSkin = true
        }
        else {
          if ( (skinsArray[0].netScore !== skinsArray[1].netScore) && skinsArray[0].netScore !== 1000) skinsArray[0].hasSkin = true
        }
      }

      return skinsArray
    },
    gotoSkinsHole (holeNum) {
      if (holeNum < 1) holeNum = 18
      if (holeNum > 18) holeNum = 1
      this.skinsHoleNum = holeNum
    },
    numPlayers (gameId) {
     
       if (Object.entries(this.leaderBoards).length === 0 || gameId === null) {
        return 0
      }
      else {
        if (gameId in this.leaderBoards) {
         
          return this.leaderBoards[gameId].length
        }
        else {
          return 0
        }
       
      }
    
    },

    placesLabel(game, numPlayers) {
      let label = ""
      if (game.prop === 'stroke') {
        const places = game.getNumPlaces(numPlayers)
        if (places === 1) {
          label = "first only)"
        } else {
          label = `${places} places)`
        }
       
        label = '(Pays ' + label
       
      }

      return label
    }
  }
}
</script>

<style>

</style>
