<template>
  <div>
  
    <div class="panel-block is-paddingless">
      
      <table class="table is-fullwidth ">
        <tbody>
          <template v-if="scoreCount===0">
            <tr v-for="(score,index) in currentTeeTimeScoresArray" :key="index" 
              :class="selectedScoreDocId === score.docId && round.isInProgress?'selected-player':''"
              @click="selectPlayer(score)" >
              <td class="vert-middle is-size-5"><p>{{nameLabel(score)}}</p></td>
            
              <td class="has-text-centered vert-middle">
                <p v-if="score.wd" class="has-text-weight-bold is-size-5">WD</p>
                <span v-else-if="round.isInProgress && !score.final" class="tag is-medium has-text-weight-bold is-size-5" :class="selectedScoreDocId===score.docId?'is-primary':''" style="width:40px;">{{score.getHoleScore(groupHoleNum)}}</span>
                <p v-else class="has-text-weight-bold is-size-5">{{score.getHoleScore(groupHoleNum)}}</p>
              </td>
              <td class="has-text-centered vert-middle">
                <p v-if="score.wd" class="has-text-weight-bold is-size-5"></p>
                <p v-else :class="parClass(scoreToPar(score, 'full18'))">{{scoreToPar(score, 'full18')}}</p>
              </td>

            </tr>
          </template>
          <template v-else>
            <tr v-for="(score,index) in currentTeeTimeScoresArray" :key="index"  >

              <td class="vert-middle is-size-5"><p>{{score.name}}</p></td>

              <td 
                v-if="index<scoreCount"
                :rowspan="currentTeeTimeScoresArray.length"
                class="has-text-centered vert-middle"
                :class="selectedScoreDocId === score.docId && round.isInProgress?'selected-player':''"
                @click="selectPlayer(score)"
              >
              
                <span v-if="round.isInProgress && !score.final" class="tag is-medium has-text-weight-bold is-size-5" :class="selectedScoreDocId===score.docId?'is-primary':''" style="width:40px;">{{score.getHoleScore(groupHoleNum)}}</span>
                <p v-else class="has-text-weight-bold is-size-5">{{teamScore.getHoleScore(groupHoleNum)}}</p>
                <p>{{handicapDotsLabel(teamScore.getHandicapStrokesHole(groupHoleNum))}}</p>
              </td>

              <td 
                 v-if="index<scoreCount"
                 :rowspan="currentTeeTimeScoresArray.length" 
                 class="has-text-centered vert-middle" >
                <p :class="parClass(scoreToPar(teamScore, 'full18'))">{{scoreToPar(teamScore, 'full18')}}</p>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <table class="has-margin-bottom-5" v-if="selectedScoreDocId!=='' && round.isInProgress && !isGuest && !selectedScoreFinal" width="100%">
      <tbody>
        <tr v-for="i in 3" :key="i"  >
          <td v-for="n in 3" :key="n" style="padding-top:4px;padding-left:2px;padding-right:2px;" :colspan="n===3&&(i<3)?'2':''" :width="n==3&&i===3?20:''">
            <a 
              class="button has-background-light is-medium" 
              style="width:100%;" 
              @click="setGroupHoleScore(buttonNum(i,n))"
            >
              <span class="is-size-3">{{buttonNum(i,n)}}</span>
            </a>
          </td>
          <td v-if="i===3" width="20" style="padding-top:4px;padding-left:2px;padding-right:2px;">
            <button 
              class="button has-background-light has-text-weight-bold is-medium" 
              style="width:100%;" 
              @click="deleteGroupHoleScore()"
            >
                <span class="icon is-size-4">
                <i class="mdi mdi-backspace"></i>
              </span>
            </button>
          </td>
          
        </tr>
      </tbody>
    </table>
    <div v-if="round.isInProgress && !isGuest && !selectedScoreFinal" class="has-margin-left-10 has-padding-bottom-10" >
      <a @click="setHighScores()">{{highScores?'Show Less Numbers':'Show More Numbers'}}</a>
    </div>
     
  </div>

</template>

<script>

import { formatToPar, parClass, handicapDotsLabel } from "../../lib"
import TeamScore from '../../models/teamScore'


export default {
  data () {
    return {
      highScores: false
    }
  },

  props: ['round','groupHoleNum','currentTeeTimeScoresArray','selectedScore','autoInc','scoreCount'],

  computed: {

    isGuest () {
      return this.$store.getters['user/isGuest']
    },
  
    leaderBoards () {
      return this.$store.getters['round/leaderBoards']
    },
    isKPHole () {
      let isHole = false
      const score = this.currentTeeTimeScoresArray[0]
   
      if (score) {
        isHole = this.round.isKPHole(score.tee, this.groupHoleNum) && this.round.games.hasGame('kp')

      }
    
      return isHole
    },
    selectedScoreDocId () {
      let docId = ''
      if (this.selectedScore) docId = this.selectedScore.docId
      return docId
    },
    selectedScoreFinal () {
      let final = false
      if (this.selectedScore) final = this.selectedScore.final
      return final
    },
    teamScore () {  // only works if scramble game
      let teamScore = null
      if (this.currentTeeTimeScoresArray.length > 0) {
        const firstScore = this.currentTeeTimeScoresArray[0]
        const game = this.round.games.aGames.find(g=>g.prop==='stroke' && (g.format==='scramble' || g.format==='123'))
        
        if (game !== undefined) teamScore = game.teamScores.getTeamScoreByScoreDocId (firstScore.docId)
      }
      if (teamScore === null) {
 
        teamScore = new TeamScore()
      }
      
      return teamScore
    }
  
   
  },
  

  methods: {
    parClass, handicapDotsLabel,

    buttonNum (row, col) {
      let num = ''
      if (this.highScores) {
        num = (row-1)*3 + (col+9)
      }
      else {
        num = (row-1)*3 + col
      }
      return num
    },
    toPar (score, side='full18') {
 
      return formatToPar(score.toPar(side))
    },

    nameLabel (score) {
      let label = score.name
      const strokes = score.getHandicapStrokesHole(this.groupHoleNum,'full18')
      label += (' ' + handicapDotsLabel(strokes))
      label += score.hasKP(this.groupHoleNum)?' (KP)':''

     
      return label
    },
  
  



    async setGroupHoleScore (holeScore) {
      if (holeScore === '') holeScore = 0
      const teeScores = this.currentTeeTimeScoresArray

      await this.$store.dispatch('round/sumScore', {score: this.selectedScore, holeNum: this.groupHoleNum, val: holeScore})

      let allScoresEntered = true
      let missingScore = null
    
      let scoreIndex = 1
      for(let score of teeScores) {
        if (scoreIndex === this.scoreCount) {
          allScoresEntered = true
          break
        }
        scoreIndex++
        if (score.getHoleScore(this.groupHoleNum) === null && this.selectedScoreDocId !== score.docId && !score.wd) {
          missingScore = score
          allScoresEntered = false
          break
        }
      }
    
      if (allScoresEntered) {
        if (this.isKPHole) {
          this.$emit('setKPTab')
        }
        else {
          if (this.autoInc) this.$emit('nextHole')
        }
        
      }
      else {
        this.$emit('selectScore', missingScore) 
      }
  
      
    },

    async deleteGroupHoleScore () {
      await this.$store.dispatch('round/sumScore', {score: this.selectedScore, holeNum: this.groupHoleNum, val: null})

    },    

    

    selectPlayer (score) {
      if (!score.wd) this.$emit('selectScore', score) 
    },

    setHighScores () {
      this.highScores = !this.highScores
    },

    scoreToPar (score, side) {
      let toPar = 0
   
   
      if (this.round.games.hasStablefordGame()) {
        const game = this.round.games.aGames.find(g=>(g.prop==='stroke' && g.side===side && g.isStableford))
          
        toPar = formatToPar(score.toPoints(side, game.useQuota))
      
      }
      else {
        toPar = this.toPar(score, side)
      }
    
  
    
      return toPar
    }
    
  }
}

</script>

<style lang="scss">

  .text-center {
    text-align: center !important;
  }
  .vert-middle {
    vertical-align: middle;
  }

  .vert-bottom {
    vertical-align: bottom !important; 
  }

  .header-cell {
    background-color: black;
    color: white;
    font-weight: bold;
  }

  .checkbox-cell-mobile {
      width: 20px;
      .checkbox {
          vertical-align: middle;
          .check {
              transition: none;
          }
      }
  }
 
 tr.rowhighlight td, tr.rowhighlight th{
    background-color:#caced1;
  }
  .group-score {
    width: 768px;
    flex: 0 0 768px;
  }

  .selected-player {
    border-width: 2px;
    border-style: solid;
    border-color: blue;

  }


</style>
