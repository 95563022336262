<template>

<div>
    <toolbar @selected="selectTab"/>
    <div class="section">
      <div class="columns is-centered">
        <div class="column is-one-third">
          <b-notification :active.sync="error">
            {{ errorMsg }}
          </b-notification>
          <div class="card">
            <div class="card-header">   
              <p class="card-header-title is-size-4 is-centered">
                {{groupName}} Registration
              </p>
            </div>
            <div class="card-content">
              <b-steps v-model="activeStep" @input="changeStep()">
                <b-step-item step="1" label="Create Account" >
              
                  <template v-if="group===null && pageLoaded">
                    <p class="is-size-5 has-text-centered has-margin-top-40">Unable to Register</p>
                    <br />
                 
                    <p class="is-size-5 has-text-centered has-margin-bottom-40">Your group can't be found. Contact your group Admin.</p>
                  </template>

                  <template v-else-if="player===null && pageLoaded">
                    <p class="is-size-5 has-text-centered has-margin-top-40">Unable to Register</p>
                    <br />
                 
                    <p class="is-size-5 has-text-centered has-margin-bottom-40">Your email can't be found on the player roster. Contact your group Admin.</p>
                  </template>

                  <template v-else-if="uid!=='' && pageLoaded">
                    <p class="is-size-5 has-text-centered has-margin-top-30 has-margin-bottom-30">You are signed in</p>
                  </template>
                 
                  <template v-else>
                    <template v-if="doSignin">
                      <p class="is-size-5 has-text-centered has-margin-top-40">You already have a girlap account</p>
                      <br />
                  
                      <p class="is-size-5 has-text-centered has-margin-bottom-40">Enter password and sign in to continue</p>
                    </template>

                    <b-field label="Email" >
                      <p>{{regEmail}}</p>
                    </b-field>

                    <b-field label="Password" :message="passwordErrors" >
                      <b-input
                        ref="password"
                        type="password"
                        maxlength="30"
                        v-model="password"
                        @blur="$v.password.$touch()"
                        password-reveal />
                    </b-field>
                    <b-field v-if="!hasAccount" label="Confirm Password" :message="confirmPasswordErrors" >
                      <b-input
                      
                        type="password"
                        maxlength="30"
                        v-model="confirmPassword"
                        @blur="$v.confirmPassword.$touch()"
                        password-reveal />
                    </b-field>

                    <b-button 
                      v-show="doSignin"
                      class="is-fullwidth" 
                      type="is-primary"
                      :disabled="$v.password.$invalid" 
                      @click="signIn()"
                      @keyup.native.enter="enter" >
                      Sign In
                    </b-button>
                 
                  </template>

                
                </b-step-item>

                

                <b-step-item step="2" label="Player Information">
                  <div class="content">
                   
                    <b-field label="Name">
                      <b-input
                        v-model="name" 
                        @blur="capName" />

                    </b-field>

                    <b-field label="Tee">
                      <b-select v-model="tee" >
                        <option v-for="tee in playerTees" :value="tee" :key="tee">{{ tee }}</option>
                      </b-select>

                    </b-field>

                    <b-field label="GHIN #">
                      <b-input
                        v-model="ghinNum" />
                    </b-field>
 
                  </div>
        
                </b-step-item>

                <div
                    class="buttons is-centered"
                    slot="navigation"
                    slot-scope="{previous, next}">
                    <b-button
                      outlined
                      size="is-medium"
                      icon-left="chevron-left"
                      :disabled="previous.disabled"
                      @click.prevent="previous.action">
                        Previous
                    </b-button>
                    <b-button
                      v-if="activeStep===1"
                      outlined
                      size="is-medium"
                      @click="register">
                        Register
                    </b-button>
                    <b-button
                      v-else
                      outlined
                      size="is-medium"
                      icon-right="chevron-right"
                      :disabled="(next.disabled && activeStep!==1) || (uid==='' && hasAccount) || (!hasAccount && $v.confirmPassword.$invalid)"
                      @click.prevent="next.action">
                        Next
                    </b-button>
                </div>
              </b-steps>
            </div>
          </div>
          
        </div>
      </div>
      <b-modal  :active.sync="showAbout" >
        <about />
      </b-modal>
      <b-modal has-modal-card :active.sync="showAccount" >
        <account />
      </b-modal>
    </div>
  </div>



</template>

<script>
import Toolbar from '../../components/Shared/toolbar'
import About from '../../components/Dialogs/About'
import Group from '../../models/group'
import Player from '../../models/player'
import Course from '../../models/course'
import User from '../../models/user'

import { db, auth } from '../../firebase'
import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'
import { capitalizeWord } from '../../lib'

export default {
  data () {
    return {
      pageLoaded: false,

      showAbout: false,
      showAccount: false,
      activeStep: 0,

      hasAccount: false,
      uid: '',
      
      group: null,
      player: null,
    
      regEmail: '',
      password: '',
      confirmPassword: '',

      name: '',
      tee: '',
      ghinNum: ''
    }
  },
  async created () {
    const groupDocId = this.$route.query.group
   
    this.regEmail = this.$route.query.email

    try {

      if (this.$store.getters['user/user']) {
        await this.$store.dispatch('user/signOut',false,{root:true})
      }
      if (this.$store.getters['auth/isAuthed']) {
        await auth.signOut()
      }
      const signInMethods = await auth.fetchSignInMethodsForEmail(this.regEmail)
      this.hasAccount = (signInMethods.length > 0) 

      await auth.signInAnonymously()

      const groupRef = db.collection('groups').doc(groupDocId)
      const groupDoc = await groupRef.get()
      if (groupDoc.exists) {
        this.group = new Group()
        this.group.docId = groupDocId
        this.group.setData(groupDoc.data())

        const courseDoc = await db.collection('courses').doc(this.group.courseDocId).get()
        this.group.course = new Course()
        this.group.course.docId = courseDoc.id
        this.group.course.setData(courseDoc.data())

        const playerDocs = await groupRef
          .collection('players')
          .where('email', '==', this.regEmail)
          .get()

        if (playerDocs.size > 0) {
          const playerDoc = playerDocs.docs[0]
          this.player = new Player()
          this.player.docId = playerDoc.id
          this.player.setData(playerDoc.data())

          this.name = this.player.name
          this.tee = this.player.tee
          this.ghinNum = this.player.ghinNum
        }
      }
      this.pageLoaded = true
    }
    catch (error) {
      this.error = error.message
    }
    
    
  },

  validations: {
  
    password: {
      required,
      minLength: minLength(6)
    },
    confirmPassword: {
      sameAsPassword: sameAs('password')
    }
    
  },
  components: {
    'toolbar': Toolbar,
    'about': About
  },

  mounted () {
    // this.$nextTick(() => {
    //   this.$refs['password'].focus()
    // })
  },
  computed: {

    passwordErrors () {
      return this.$v.password.minLength ? '' : 'Password must be at least six characters'
    },
   
    confirmPasswordErrors () {
      return this.$v.confirmPassword.sameAsPassword ? '' : 'Passwords are not identical'
    },

    error: {
      set (newValue) {
        if (!newValue) {
          this.$store.commit('user/errorMsg','')
        }
      },
      get () {
        return this.errorMsg.length > 0
      }
    },
    errorMsg () {
      return this.$store.getters['user/errorMsg']
    },
    playerTees () {
      let tees = []
  
      if (this.group) {
        tees = this.group.course.getPlayerTees()
      
      }
      return tees
    },
    groupName () {
      let name = ''
      if (this.group) name = this.group.name
      return name
    },
    doSignin () {
      return this.hasAccount && this.uid===''
    }
  },
  
  methods: {
    selectTab (tabName) {
      
      if (tabName === 'about') {
        this.showAbout = true
      }
      else if (tabName === 'account') {
        this.showAccount= true
      }
      
    },

    capName () {
      this.name = capitalizeWord(this.name)
    },

    changeStep (step) {
      
    },

    async signIn () {
      const userAccess = await auth.signInWithEmailAndPassword(this.regEmail, this.password)
      this.uid = userAccess.user.uid
    },

    async register () {
      
      if (!this.hasAccount) {
        await auth.signOut() // sign out anonymous user
        const userAccess = await auth.createUserWithEmailAndPassword(this.regEmail, this.password)
        this.uid = userAccess.user.uid
      }

      const userDoc = await db.collection('users').doc(this.uid).get()
     
      const user = new User()
      if (userDoc.exists ) {
        user.docId = userDoc.id
        user.setData(userDoc.data())
      }
      else {
        user.docId = this.uid
        user.email = this.regEmail
        user.groupDocId = this.group.docId
      }
      user.name = this.name
      user.email = this.regEmail
  
      user.playerDocId = this.player.docId
      user.tee = this.tee
      user.ghinNum = this.ghinNum

      user.groupDocId = this.group.docId
      user.groupName = this.group.name
       
      if (user.groups.find(g => g.docId === this.group.docId) === undefined) {
        user.groups.push({docId: this.group.docId})
      }


      const groupRef = db.collection('groups').doc(this.group.docId)

      await db.collection('users').doc(this.uid).set(user.getData())

      const update = {registered: true}

      if (this.tee !== this.player.tee) update.tee = this.tee
      if (this.ghinNum !== this.player.ghinNum) update.ghinNum = this.ghinNum
      if (this.name !== this.player.name) update.name = this.name

      await groupRef.collection('players').doc(this.player.docId).update(update)

      this.$store.commit('user/user', user)
      await this.$store.dispatch('user/loadUserGroup',null,{root:true})
      this.$router.push('/user')

    }
  }
}
</script>

<style>

</style>
