
import dayjs from 'dayjs'
import { formatHandicap, newDate, formatHandicapSummary, FSTimestampToDate, DateToFSTimestamp } from '../lib'


const parseFullName = require('parse-full-name').parseFullName

export default class Score {
  constructor(data) {
    if(data) {
      this.setObj(data)
    }
    else {
      this.initialize()
    }
  }
  
  initialize () {
    this.docId = ''
    this.tournamentDocId = null
 
    this.tournamentDate = newDate()
    this.tournamentStatus = ''
    this.tournamentName = ''
    this.courseLabel = ''
    this.coursePar = {front: 36, back: 36, full18: 72}


    this.posted = false
    this.playerDocId = ''
    this.name = ''
    this.email = ''
    this.type = ''
    this.tee = ''
    this.gender = ''
    this.ghinNum = ''
    this.index = null
    this.indexSource = 'Manual'
    this.handicap = null

    this.wd = false
    this.score = {front: 0, back: 0, full18: 0}
  
    this.points = null
    this.payOut = {}
    this.pos = {}
  
    this.totalBet = 0
    this.totalPayout = 0

    this.signUpDate = null
    this.handicapDate = null
    
    this.games = []

    this.points = null
  }


  get netScore () {
    const netScore = {
      front: this.score.front,
      back: this.score.back,
      full18: this.score.full18
    }
   
    if (this.handicap !== null) {
      netScore.front =  this.score.front - this.handicap.front
      netScore.back = this.score.back - this.handicap.back
      netScore.full18 = netScore.front + netScore.back
    }
 
    return netScore
  }



  setTournament (tournament) {

    this.tournamentDocId = tournament.docId
    this.tournamentDate = tournament.endDate
    this.tournamentStatus = tournament.status
    this.posted = tournament.posted
    this.courseLabel = tournament.course.label
    this.coursePar = {
      front: tournament.course.getPar(this.tee, 'front', this.gender),
      back: tournament.course.getPar(this.tee, 'back', this.gender),
      full18: tournament.course.getPar(this.tee, 'full18', this.gender)
    }
    this.tournamentName = tournament.name
    this.games = tournament.games.aGames.map (g=> {
      return {
        id: g.id,
        name: g.name,
        side: g.side,
        prop: g.prop
      }
    })
    this.totalBet = tournament.games.totalBet
  }

  setPlayer (player) {
    this.playerDocId = player.docId
    this.name = player.name
    this.email = player.email
    this.type = player.type
    this.tee = player.tee
    this.gender = player.gender
    this.ghinNum = player.ghinNum
    this.index = player.index
    this.indexSource = player.indexSource
    this.handicap = player.handicap
    this.signUpDate = player.signUpDate
    this.handicapDate = player.handicapDate
  }


  get formattedDate () {
   
    return dayjs(this.tournamentDate).format('MM/DD/YYYY')
  }
  get formattedDateLabel () {
    return dayjs(this.tournamentDate).format('ddd MMM D, YYYY')
  }

  get signedUpDateFormatted () {
    return this.signUpDate===null?'n/a':dayjs(this.signUpDate).format('ddd MM/DD/YYYY hh:mma')
  }

  get slotLabel () {
    let ghinSource = this.indexSource==='GHIN'?'*':''
    let guest = this.type==='Guest'?'(G)' : ''
    let hdcpLabel = ''
    if (this.handicap) {
      hdcpLabel = `Hdcp ${formatHandicap(this.handicap.full18===null?'n/a':this.handicap.full18)}${ghinSource}`
    }
    let label = `${this.name} ${guest} ${hdcpLabel} (${this.tee})`
    if (this.wd) label += ' (WD)'
    return label
  }

  get lastName () {
    const lastName = parseFullName(this.name).last
    return lastName
  }

  fullSlotLabel () {
    return `${this.name} ${this.handicapSummary()} (${this.tee})`
  }

  fullSlotLabelLastName () {
    return `${this.lastName} ${this.handicapSummary()} (${this.tee})`
  }

  toPar (side) {
    let handicap = 0
    if (this.handicap !== null) handicap = this.handicap[side]
    const netScore = this.score[side] - handicap
    return netScore - this.coursePar[side]
  }

  get toParObj () {
    const toPar = {
      front: this.toPar('front'),
      back: this.toPar('back'),
      full18: this.toPar('full18')
    }
    return toPar
  }

 

  handicapSummary () {
    const front = this.games.findIndex(g=>g.prop==='stroke' && g.side==='front') >= 0
    const back = this.games.findIndex(g=>g.prop==='stroke' && g.side==='back') >= 0
   
    return formatHandicapSummary (this.handicap, this.indexSource, front, back)
  }

  

  copy () {
    const score = new TournamentScore()
    score.setObj(this.getObj())
    return score
  }


  toJSON () {
    return JSON.stringify(this.getObj())
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  getObj () {
    return {
      docId: this.docId,
      playerDocId: this.playerDocId,
      tournamentDocId: this.tournamentDocId,
      tournamentDate: new Date(Number(this.tournamentDate)),
      tournamentStatus: this.tournamentStatus,
      tournamentName: this.tournamentName,
      courseLabel: this.courseLabel,
      coursePar: this.coursePar,
      posted: this.posted,
      name: this.name,
      email: this.email,
      type: this.type,
      tee: this.tee,
      gender: this.gender,
      ghinNum: this.ghinNum,
      index: {...this.index},
      indexSource: this.indexSource,
      handicap: {...this.handicap},
     
      wd: this.wd,
      score: {...this.score},
     
      payOut: {...this.payOut},
      pos: {...this.pos},
     
      totalBet: this.totalBet,
      totalPayout: this.totalPayout,

   
      signUpDate: this.signUpDate,
      handicapDate : this.handicapDate,

      games: this.games.map(g=>({...g})),
      points: this.points
    }
  }
  setObj (data) {
    this.docId = data.docId,
    this.playerDocId = data.playerDocId
  
    this.tournamentDocId = data.tournamentDocId,
    this.tournamentDate = new Date(Number(data.tournamentDate))
    if(typeof data.tournamentDate === "string") this.tournamentDate = new Date(data.tournamentDate)
    this.tournamentStatus = data.tournamentStatus
    this.tournamentName = data.tournamentName
    this.courseLabel = data.courseLabel
    this.coursePar = data.coursePar
    this.posted = data.posted
    this.name = data.name
    this.email = data.email
    this.type = data.type
    this.tee = data.tee
    this.ghinNum = data.ghinNum
    this.gender = data.gender
    this.index = {...data.index}
    this.indexSource = data.indexSource
    this.handicap = {...data.handicap}
    this.handicapDate = data.handicapDate
  
    this.wd = data.wd
    this.score = {...data.score}
  
 
    this.payOut = {...data.payOut}
    this.pos = {...data.pos}
    
    this.totalBet = data.totalBet
    this.totalPayout = data.totalPayout
  
    this.signUpDate = data.signUpDate
   
    this.games = data.games.map(g=>({...g}))
    this.points = data.points
  
  }
  getData () {


    const data = {
   
      tournamentDocId: this.tournamentDocId,
      playerDocId: this.playerDocId,
      tournamentDate: DateToFSTimestamp(this.tournamentDate), // convert date string to Timestamp object
      tournamentStatus: this.tournamentStatus,
      tournamentName: this.tournamentName,
      courseLabel: this.courseLabel,
      coursePar: this.coursePar,
      posted: this.posted,
      name: this.name,
      email: this.email,
      type: this.type,
      tee: this.tee,
      ghinNum: this.ghinNum,
      gender: this.gender,
      index: this.index,
      indexSource: this.indexSource,
      handicap: this.handicap,
    
      wd: this.wd,
      score: this.score,
  
    
      payOut: this.payOut,
      pos: this.pos,
    
      totalBet: this.totalBet,
      totalPayout: this.totalPayout,
   
      signUpDate: this.signUpDate===null?null:DateToFSTimestamp(this.signUpDate), // convert date string to Timestamp object
      handicapDate: this.handicapDate===null?null:DateToFSTimestamp(this.handicapDate), // convert date string to Timestamp object

      games: this.games,
      points: this.points
     
   
    }
   

    return data

  }
  setData (data) {
    
  
    this.tournamentDocId = data.tournamentDocId
    this.playerDocId = data.playerDocId
    this.tournamentDate = FSTimestampToDate(data.tournamentDate) // stored as Timestamp object
    this.tournamentStatus = data.tournamentStatus
    this.tournamentName = data.tournamentName
    this.courseLabel = data.courseLabel
    this.coursePar = data.coursePar
    this.posted = data.posted
    this.name = data.name
    this.email = data.email
    this.type = data.type
    this.tee = data.tee
    this.ghinNum = data.ghinNum
    this.gender = data.gender
    this.index = data.index
    this.indexSource = data.indexSource
    this.handicap = data.handicap
 
    this.wd = data.wd
    this.score = data.score
   
    this.payOut = data.payOut
    this.pos = data.pos
  
    this.totalBet = data.totalBet
    this.totalPayout = data.totalPayout
  
    this.signUpDate = data.signUpDate===undefined||data.signUpDate===null?null:data.signUpDate.toDate()
   
    this.games = data.games
    this.points = data.points
   
  }
}

