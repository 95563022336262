<template>
  <div class="card">
    <div class="card-content">
      <p class="title" style="font-family:'Contrail One';">girlap</p>
      <p class="subtitle is-6">Green in Regulation Long Ass Putt</p>
      <p class="subtitle">{{version}}</p>
      <p class="subtitle is-6">Copyright 2020 Kenneth Elston</p>
    </div>
   
  </div>
  
 
</template>

<script>



export default {
  data () {
    return {
      
    }
  },
 
  methods: {
   
  },
  computed: {
   
    version () {

      return 'v'+process.env.VUE_APP_VERSION
    }
   
  }
}

</script>

<style>
 
  

</style>