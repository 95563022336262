<template>
  <div>
    <template v-if="round.isTournament">
      <b-tabs v-model="activeTab">
       
        <b-tab-item label="This Round">
          <round-leaderboard  :round="round" :leaderBoards="leaderBoards" :selectedGameId.sync="selectedGameId"/>
        </b-tab-item>
        <b-tab-item label="Tournament">
          <tournament-leaderboard />
        </b-tab-item>
      </b-tabs>
    </template>
    <template v-else>
      <round-leaderboard :round="round" :leaderBoards="leaderBoards" :selectedGameId.sync="selectedGameId"/>
    </template>

  </div>
   
</template>

<script>

import RoundLeaderboard from './RoundLeaderboard'
import TournamentLeaderboard from '../Tournament/TournamentLeaderboard'


export default {
  data() {
    return {
      activeTab: 0
    }
  },


  components: {
    'round-leaderboard': RoundLeaderboard,
    'tournament-leaderboard': TournamentLeaderboard
  },

  
  computed: {
    round () {
      return this.$store.getters['round/round']
    },
    leaderBoards () {
      return this.$store.getters['round/leaderBoards']
    },
    selectedGameId: {
      get () {
        return this.$store.getters['round/selectedGameId']
      },
      set (val) {
        this.$store.commit('round/selectedGameId', val)
      }
    }
  },
  methods: {
    
  }
}
</script>

<style>

</style>
