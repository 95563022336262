<template>
  <div id="app">
    
 <!--   <div v-if="showInstallPrompt" class="card">
      <div class="card-content">
        <div class="content">
          Install to Home Screen?
        </div>
      </div>
      <div class="card-footer">
        <a href="#" class="card-footer-item" @click="installApp()">YES</a>
        <a href="#" class="card-footer-item" @click="showInstallPrompt=false">NO</a>
      </div>
    </div> -->
  
   
    <router-view />
  </div>
  
</template>

<script>



export default {
  name: 'girlap',
  data () {
    return {
      // showInstallPrompt: false,
      // deferredPrompt: null
    }
  },

  // mounted () {
    // window.addEventListener('beforeinstallprompt', (e) => {
    //   console.log('install event')
    //   e.preventDefault()
    //   this.deferredPrompt = e
    //   this.showInstallPrompt = true
    // })
  // },

  // created () {
   

    // if (this.$workbox) {
    //   this.$workbox.addEventListener("waiting", async () => {

    //     this.$buefy.toast('Updating to a new version')
    //     await this.$workbox.messageSW({ type: "SKIP_WAITING" })
    //   })
    // }
  // },

  // watch: {
  //   '$installer.canInstall' : (val) => {
  //     if (val) {
  //       this.$installer.prompt
  //     }
  //   }
  // },
  
  // methods: {
    // installApp () {
    //   this.showInstallPrompt = false
    //   this.deferredPrompt.prompt()
    //   deferredPrompt.userChoice.then((choiceResult) => {
    //     if (choiceResult.outcome === 'accepted') {
    //       console.log('User accepted the install prompt');
    //     } else {
    //       console.log('User dismissed the install prompt');
    //     }
    //     this.deferredPrompt = null
    //   })
    // }
  // }
 
}


</script>

<style lang="scss">
  

  // Import Bulma and Buefy styles
 

  @import "~bulmaswatch/flatly/_variables.scss";
  @import "~bulma/bulma";
  @import "~bulmaswatch/flatly/_overrides.scss";
  @import '~bulma-helpers/css/bulma-helpers.min.css';
  @import "~buefy/src/scss/buefy";
  


  
</style>
