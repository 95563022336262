import { roundTo } from '../lib'

export default class HandicapCalc {
  constructor(course, maxHandicap) {
    this.course = course
    this.maxHandicap = maxHandicap
  }
  
  // getCourseHandicapObj (indexInfo, tee, gender='M' ) {
  //   let handicap
  //   if (indexInfo.getIndex('full18') === null) {
  //     handicap = null
  //   }
  //   else {
  //     handicap = {
  //       front: this.courseHandicapSide(indexInfo.getIndex('front'), tee, 'front', gender),
  //       back: this.courseHandicapSide(indexInfo.getIndex('back'), tee, 'back', gender),
  //       full18: this.courseHandicapSide(indexInfo.getIndex('full18'), tee, 'full18', gender)
  //     }
  //   }
   
  //   return handicap
  // }
  // courseHandicapSide (indexSide, tee, side='full18', gender='M' ) {
   
  //   let hc = null
  //   if(tee.length > 0) {
  //     let maxHandicap = this.maxHandicap
  //     if (side !== 'full18') maxHandicap = maxHandicap / 2
  //     const slope = this.course.getSlope(tee, side, gender)
  //     if (slope > 0) {
  //       hc = slope * indexSide / 113
      
  //       if (hc > maxHandicap) hc = maxHandicap
  //     }
      
  //   }
   
  //   return hc
  // }
  teeDiff (tee, side='full18', gender='M') {
    const teeRating = this.course.getRating(tee, side, gender)

    let ratingDiff = teeRating - this.course.getPar(tee, side, gender)

    return ratingDiff
  }
  getCourseHandicapObj (indexInfo, tee, gender='M' ) {
    let handicap
    if (indexInfo.index === null || tee === '') {
      handicap = null
    }
    else {
      handicap = {
        front: this.courseHandicapSide(indexInfo.getIndex('front'), tee, 'front', gender),
        back: this.courseHandicapSide(indexInfo.getIndex('back'), tee, 'back', gender),
        full18: this.courseHandicapSide(indexInfo.getIndex('full18'), tee, 'full18', gender)
      }
    }
    
   
    return handicap
  }

  courseHandicapSide (indexSide, tee, side, gender='M' ) {
   
    let hc = null
   
    if(tee.length>0 ) {
      const slope = this.course.getSlope(tee, side, gender)

     
      if (slope > 0) {
        hc = slope * indexSide / 113  // course handicap
      }
      else {
        hc = indexSide
      }

      const ratingDiff = this.teeDiff(tee, side, gender)

    

      hc = Math.round(hc + ratingDiff)

    

      let maxHandicap = this.maxHandicap
      if (side !== 'full18') maxHandicap = maxHandicap / 2
      if (hc > maxHandicap) hc = maxHandicap
         
      
    }

    return hc
  }

  getPlayingHandicapObj (indexInfo, tee, handicapFac, gender='M' ) {
    let handicap
    if (indexInfo.index === null || tee === '') {
      handicap = null
    }
    if (handicapFac === null) handicapFac = {'front': 1.0, 'back':1.0, 'full18':1.0};
    else {
      handicap = {
        front: this.playingHandicapSide(indexInfo.getIndex('front'), tee, handicapFac.front, 'front', gender),
        back: this.playingHandicapSide(indexInfo.getIndex('back'), tee, handicapFac.back, 'back', gender),
        full18: this.playingHandicapSide(indexInfo.getIndex('full18'), tee, handicapFac.full18, 'full18', gender)
      }
    }
    
   
    return handicap
  }

  playingHandicapSide (indexSide, tee, handicapFac, side, gender='M' ) {
   
    let hc = null
    let hcInt = null
   
    if(tee.length>0 ) {
      const slope = this.course.getSlope(tee, side, gender)

     
      if (slope > 0) {
        hc = slope * indexSide / 113  // course handicap
      }
      else {
        hc = indexSide
      }

      const ratingDiff = this.teeDiff(tee, side, gender)

      hc += ratingDiff

      let maxHandicap = this.maxHandicap
      if (side !== 'full18') maxHandicap = maxHandicap / 2
      
      hcInt = Math.round(hc * handicapFac)
      if (hcInt > maxHandicap) hcInt = maxHandicap
         
      
    }

    return hcInt
  }

  calcDiffObj (grossScore, tee, pcc, gender='M') {
    const diff = {
      front: this.calcDiffSide(grossScore.front, tee, 'front', pcc, gender),
      back: this.calcDiffSide(grossScore.back, tee, 'back', pcc, gender),
      full18: this.calcDiffSide(grossScore.full18, tee, 'full18', pcc, gender)
    }
    return diff
  }
  calcDiffSide (grossScore, tee, side, pcc, gender='M') {
    return roundTo(((grossScore - pcc) - this.course.getRating(tee, side, gender)) * 113 / this.course.getSlope(tee, side, gender),1)
  }





  
}


