
import { sortArrayObj, sortArrayNum, roundTo } from '../lib'
import TeamHandicapDefs from '../helpers/teamHandicapDefs'

export default class TeamScore {
  constructor() {
    this.teamNum = 1
    this.objType = 'teamScore'
    this.format = ''
    this.handicapType = 'none'
    this.scoreDocIds = []
    this.scores = []
    this.scoreCount = 0
    this.blindDraw = []
  }

  set size (size) {
 
    if (size < this.scoreDocIds.length) {
      this.scoreDocIds.length = size
      this.scores.length = size
      this.blindDraw.length = size
    }
    else if (size > this.scoreDocIds.length) {
      const scoresToAdd = size - this.scoreDocIds.length
      this.scoreDocIds.push(...new Array(scoresToAdd).fill(null))
      this.scores.push(...new Array(scoresToAdd).fill(null))
      this.blindDraw.push(...new Array(scoresToAdd).fill(true))
    }
    
  }

  get size () {
    return this.scoreDocIds.length
  }

  teamName () {
    const names = []

    let score
    for (let i=0; i<this.scores.length; i++) {
      score = this.scores[i]
      if (score) {
        names.push(score.fullSlotLabel() + (score.wd?' (WD)':'') + (this.blindDraw[i]?' (BLIND)':''))
      }
    }
    return names.join(' / ')
  }

  fullSlotLabel () {
    return this.teamName()
  }

  get name () {
    return this.teamNameShort()
  }

  teamNameShort () {
    const names = []

    let score
    let blind
    for (let i=0; i<this.scores.length; i++) {
      score = this.scores[i]
      blind = this.blindDraw[i]
      if (score) {
        names.push(score.lastName + (blind?' (B)':'') + (score.wd?' (WD)':'')) 
      }
    }
    return names.join(' / ')
  }

  get holeThru () {
    let thru = 0
    let finalCount = 0
    let scoreThru
    for (let score of this.scores) {
     
      if (score) {
        if (score.final) finalCount++
        scoreThru = score.holeThru
        if (scoreThru !== '') {
          if (scoreThru > thru) thru = scoreThru
        }
      }
      
    }
    if (finalCount === this.scores.length) {
      thru = 'F'
    }
    
    return thru
  }

  get firstScore () {
    let score = null
   
    if (this.scores.length > 0) {
      
      score = this.scores[0]
    }
 
    return score
  }

  get playerDocIds () {
    return this.scores.filter(s=>s!==null).map(s=>s.playerDocId)
  }

  scoreDocIdsArray () {
    return this.scoreDocIds.filter(id=>id!==null)
  }

  get numPaid () {
    return this.blindDraw.filter(b=>b===false).length
  }


  assignScores (scoresArray) {
    for (let i=0; i<this.scoreDocIds.length; i++) {
      let docId = this.scoreDocIds[i]
      if (docId !== null) {
        const score = scoresArray.find(s=>s.docId===docId)
        if (score !== undefined) this.scores[i] = score
      }
    }
  }

 
  slots (index) {
    if (this.scores[index] === undefined) {
      console.log('slots undefined', index, this.scores, this.scoreDocIds)
    }
    return this.scores[index]
  }

  slotLabel (index) {
    let label = ''
    const score = this.slots(index)
    if (score !== undefined) {

    }
    label = score.slotLabel
    if (this.blindDraw[index]) {
      label += ' (BLIND)'
    }
    return label
  }

  slotsDocId (index) {
    return this.scoreDocIds[index]
  }

  countScores () {
    let count = 0
    for (let i=0; i<this.size; i++) {
      if (this.scoreDocIds[i] !== null && this.scoreDocIds[i] !== '') {
        count++
      }
    }
    return count
  }

  get scoresArray () {
  
    return this.scores.filter(slot=>slot!==null)
  }

  assignBlindDraw (teamScoreDocIds, index) {
    
    this.scoreDocIds[index] = teamScoreDocIds[Math.floor(Math.random() * teamScoreDocIds.length)]
    this.scores[index] = null
   
  }


  assignEmptySlot (scoreDocId) {
    const index = this.findEmptySlot()
    
    let assigned = false
    if (index >= 0) {
    
      this.scoreDocIds[index] = scoreDocId
      this.scores[index] = null
      this.blindDraw[index] = false

      assigned = true


    }
    return assigned
  }

  findEmptySlot () {
    return this.scoreDocIds.indexOf(null)
  }

  numAssigned () {
    let count = 0
    for(let i=0; i<this.size; i++) {
      if (this.scoreDocIds[i] !== null && this.scoreDocIds[i] !== '') count++
    }
    return count
  }


  unassign (slotIndex, val=null) {
    this.scores[slotIndex] = null
    this.scoreDocIds[slotIndex] = val
    this.blindDraw[slotIndex] = true
  }

  unassignAll () {
    for (let i=0; i<this.size; i++) {
      this.unassign(i, null)
    }
  }

  unassignScore (score) {
   
    for (let i=0; i<this.size; i++) {
      if (this.scoreDocIds[i] === score.docId) {
        this.unassign(i, null)
      }
    }
   
  }

  unassignScoreDocId (scoreDocId) {
 
    for (let i=0; i<this.size; i++) {
      if (this.scoreDocIds[i] === scoreDocId) {
        this.unassign(i, null)
      }
    }
 
  }

  isFull () {
    let full = true
    for (let docId of this.scoreDocIds) {
      if (docId === null) {
        full = false
        break
      }
    }
    return full
  }

  isEmpty () {
    let empty = true
    for (let docId of this.scoreDocIds) {
      if (docId !== null) {
        empty = false
        break
      }
    }
    return empty
  }

  assigned (score) {
    let scoreDocId=''
    if (typeof score === "string") {
      scoreDocId = score
    }
    else {
      scoreDocId = score.docId
    }
    const index = this.scoreDocIds.indexOf(scoreDocId) 

    return index >= 0
  }

  hasUnassignedBlindDraw () {
    let unassigned = false
    const blindIndex = this.blindDraw.findIndex(b=>b===true)
    if (blindIndex >= 0) unassigned = this.scores[blindIndex] === null
  
    return unassigned
  }

  getSlotIndexByScore (score) {
    let index = -1
    for (let i=0; i<this.size; i++) {
      if (score.docId === this.scoreDocIds[i]) {
        index = i
        break
      }
    }
    return index
  }
  getHandicapStrokes (side='full18') {  // get handicapstrokes per side based on fuill18 handicap
   
    return this.handicap(side)
  }
  handicap (side) {
    let handicap = 0
  
    if ((this.format === 'scramble' || this.format === '123') && this.handicapType !== 'none') {
      
      const hcDefs = new TeamHandicapDefs()
      const teamFac = hcDefs.getHandicapFac(this.format, this.scores.length)
    
      const scoreHandicaps = this.scores.map(s=>s===null?{front: 0, back: 0, full18: 0}:s.handicap)
      sortArrayObj (scoreHandicaps, 'full18', 'asc') // sort by A B C D player
   
      for (let i=0; i<scoreHandicaps.length; i++) {
        let fac = teamFac[i]
        
        if (fac !== undefined) handicap += scoreHandicaps[i][side] * fac
      }
      handicap = roundTo(handicap,0)
    
    }
    else {
      const scoreData = this.scores.filter(s=>s!==null).map(s=>{
        return {
          handicap: s.handicap[side],
          netScore: s.netScore[side]
        }
      })
      sortArrayObj(scoreData,'netScore')
  
    
      for (let i=0; i<scoreData.length; i++) {
        if (scoreData[i].handicap === '') {
          handicap = ''
          return
        }
        else {
          handicap += scoreData[i].handicap
        }
      }
    }

    return handicap
  }

  handicapCompleted (side) {
    const scoresArray =  this.scoresArray

    let handicap = 0

    if (scoresArray.length === 0) return handicap

    if (scoresArray[0].scores.length === 0) { // if no hole-by-scores then just use lowest netScore total
      const scoreData = scoresArray.map(s=>{
        return {
          handicap: s.handicapCompleted(side),
          netScore: s.netScore[side]
        }
      })
      sortArrayObj(scoreData,'netScore')
     
      for (let i=0; i<scoreData.length; i++) {
        if (scoreData[i].handicap === '') {
          handicap = ''
          return
        }
        else {
          handicap += scoreData[i].handicap
        }
      }
    }
    else {
      if (this.scoreCount === 0) {
        for (let score of scoresArray) {
          handicap += score.handicapCompleted()
        }
      }
      else {
        const lowestHandicaps = []
      
        let scores
        let startHole = 1
        let lastHole = this.holesCompleted
        if (side === 'front' && lastHole > 9) lastHole = 9
        if (side === 'back') {
          startHole = 10
          if (lastHole < 10) lastHole = 10
        }
  
        for (let i=startHole; i<=lastHole; i++) {
   
          scores = scoresArray.map(s=>{
              return {
                handicap: s.getHandicapStrokesHole(i, side),
                netScore: s.getHoleScore(i) - s.getHandicapStrokesHole(i, side)
              }
            }
          )
          
          sortArrayObj(scores, 'netScore', 'asc')
        
         
          let lowestHandicapTotal = 0
          for (let j=0; j<this.scoreCount; j++) {
            if (j < scores.length) lowestHandicapTotal += scores[j].handicap
          }
          lowestHandicaps.push(lowestHandicapTotal)
       
          
        }
       
        handicap = lowestHandicaps.reduce((total, val) => total + val, 0)
      }
      
    }
    return handicap
  }

  parCompleted (side) {
    // this.loadScores ()

    let parCompleted = 0

    if (this.scoresArray.length === 0) return parCompleted

    for (let score of this.scoresArray) {
      const par = score.parCompleted(side)
      if (par > parCompleted) parCompleted = par
    }
    if (this.scoreCount === 0) {
      parCompleted = parCompleted * this.scoresArray.length
    }
    else {
      parCompleted = parCompleted * this.scoreCount
    }

    return parCompleted
  }

  score (side) {
    const scoresArray =  this.scoresArray

    let score = 0

    if (scoresArray.length === 0) return score

    if (scoresArray[0].scores.length === 0) { // if no hole-by-scores then just use lowest netScore total
      const scoreData = scoresArray.map(s=>{
        return {
          handicap: s.handicap[side],
          score: s.score[side],
          netScore: s.netScore[side]
        }
      })
      sortArrayObj(scoreData,'netScore')
      
      for (let i=0; i<scoreData.length; i++) {
        if (scoreData[i].score === '') {
          score = ''
          return
        }
        else {
          score += scoreData[i].score
        }
      }
    }
    else {
      if (this.scoreCount === 0) { // add all scores
      
        for (let scoreObj of scoresArray) {
          score += scoreObj.score[side]
        }
      }
      else {
        if (this.format === 'scramble') {
          score = this.firstScore.score[side]
        }
        else if (this.format === '123') {
          
          for (let i=0; i<this.scoreCount; i++) {
            score += scoresArray[i].score[side]
          }
        }
        else if (this.format === 'bball') {
          const lowestScores = []
          let scores
          let startHole = 1
          let lastHole = this.holesCompleted
          if (side === 'front' && lastHole > 9) lastHole = 9
          if (side === 'back') {
            startHole = 10
            if (lastHole < 10) lastHole = 10
          }
         
          for (let i=startHole; i<=lastHole; i++) {
         
            scores = scoresArray.map(s=>{
                return {
                  score: s.getHoleScore(i),
                  netScore: s.getHoleScore(i) - s.getHandicapStrokesHole(i, side)
                }
              }
            )
        
            sortArrayObj(scores, 'netScore', 'asc')
           
            // console.log('HOLE ', i, this.scoreCount, scores)
           
          
            let lowestScoreTotal = 0
            for (let j=0; j<this.scoreCount; j++) {
              if (j < scores.length) lowestScoreTotal += scores[j].score
            }
            lowestScores.push(lowestScoreTotal)
  
          }
          score = lowestScores.reduce((total, val) => total + val, 0)
        }
       
      }
      
    }
    return score
  }

  netScore (side) {
    const scoresArray =  this.scoresArray

    let netScore = 0

    if (scoresArray.length === 0) return netScore

   
    if (scoresArray[0].scores.length === 0) { // if no hole-by-scores then just use lowest netScore total
      const scoreData = scoresArray.map(s=>{
        return {
          handicap: s.handicap[side],
          netScore: s.netScore[side]
        }
      })
      sortArrayObj(scoreData,'netScore')
     
      for (let i=0; i<scoreData.length; i++) {
        if (scoreData[i].netScore === '') {
          netScore = ''
          return
        }
        else {
          netScore += scoreData[i].netScore
        }
      }
    }
    else {
      if (this.scoreCount === 0) {
        for (let score of scoresArray) {
          netScore += score.netScore[side]
        }
      }
      else {
        if (this.format === 'scramble') {
          netScore = scoresArray[0].netScore[side]
        }
        else if (this.format === '123') {
          
          for (let i=0; i<this.scoreCount; i++) {
            netScore += scoresArray[i].netScore[side]
          }
        }
        else if (this.format === 'bball') {
          const lowestScores = []
          let netScores
          let startHole = 1
          let lastHole = this.holesCompleted
          if (side === 'front' && lastHole > 9) lastHole = 9
          if (side === 'back') {
            startHole = 10
            if (lastHole < 10) lastHole = 10
          }
          for (let i=startHole; i<=lastHole; i++) {
            netScores = scoresArray.map(s=>( scoreDocIds.getHoleScore(i) - s.getHandicapStrokesHole(i, side) ))
          
            sortArrayNum(netScores, 'asc')
  
            let lowestScoreTotal = 0
            
            for (let j=0; j<scoresArray.length; j++) {
              if (j < netScores.length) lowestScoreTotal += netScores[j]
            }
            lowestScores.push(lowestScoreTotal)
      
          }
      
          netScore = lowestScores.reduce((total, val) => total + val, 0)
        }
        
      }
      
    }
    
    return netScore
  }

  toPoints (side='full18', useQuota=true) {
    const scoresArray =  this.scoresArray

    let points = 0

    if (scoresArray.length === 0) return points

    if (scoresArray[0].scores.length === 0) { // if no hole-by-scores then just use lowest netScore total
    
      const scoreData = scoresArray.map(s=>s.getPoints(side))
      points = scoreData.reduce((total, val) => total + val, 0)
    
    }
    else {
      if (this.scoreCount === 0) { // add all scores
        for (let score of scoresArray) {
          points += score.toPoints(side, useQuota)
        }
      }
      else {
        if (this.format === 'scramble') {
          points = scoresArray[0].toPoints(side, useQuota)
        }
        else if (this.format === '123') {
          
          for (let i=0; i<this.scoreCount; i++) {
            points += scoresArray[i].toPoints(side, useQuota)
          }
        }
        else if (this.format === 'bball') {
          const highestPoints = []
          const netPoints = []
          let startHole = 1
          let lastHole = this.holesCompleted
          if (side === 'front' && lastHole > 9) lastHole = 9
          if (side === 'back') {
            startHole = 10
            if (lastHole < 10) lastHole = 10
          }
        
          // get points for each player for holes in question
          for (let i=startHole; i<=lastHole; i++) {
            for (let score of scoresArray) {
              let points = score.getHoleNetPoints(i)
              if (useQuota) points -= score.getHolePoints(i)
              netPoints.push (points)
            }
        
            sortArrayNum(netPoints, 'desc')
          
            // console.log('HOLE ', i, this.scoreCount, scores)
          
            let highestPointTotal = 0
          
            for (let j=0; j<this.scoreCount; j++) {
              if (j < scoresArray.length) highestPointTotal += netPoints[j]
            }
            highestPoints.push(highestPointTotal)
          }
     
          points = highestPoints.reduce((total, val) => total + val, 0)
        }
        
      }
     
    }
    return points
  }
  
  getHoleScoreToPar (holeNum) {
    let toPar = null
   
    let holeScore = 0
    if (this.format === 'scramble') {
      holeScore = this.firstScore.scores.find(h => h.holeNum === holeNum)
      if (holeScore !== undefined) {
        toPar = holeScore.score - this.getHolePar(holeNum)
      }
    }
    else {
      toPar = 0
      for (let score of this.scores) {
        holeScore = this.scores.find(h => h.holeNum === holeNum)
        if (holeScore !== undefined) {
          toPar += holeScore.score - this.getHolePar(holeNum)
        }
      }
      
    }
   
    return toPar
  }
  toPar (side='full18') {
    return this.netScore(side) - this.parCompleted(side)
  }

  getHoleScore (holeNum) {
    let scoreVal = null
    if (this.format === 'scramble') {
      let holeScore = this.firstScore.scores.find(h => h.holeNum === holeNum)
      if (holeScore !== undefined) {
        scoreVal = holeScore.score
      }
    }
    else {
      scoreVal = 0
      for (let score of this.scores) {
        let holeScore = score.scores.find(h => h.holeNum === holeNum)
        if (holeScore !== undefined) {
          scoreVal += holeScore.score
        }
      }
    }
    
    return scoreVal
  }

  netHoleScore (holeNum) {
    const scoresArray =  this.scoresArray

    let netScore = 0

    if (scoresArray.length === 0) return netScore

    if (this.scoreCount === 0) { // add all scores
      for (let score of scoresArray) {
        netScore += score.getHoleScore(holeNum) - score.getHandicapStrokesHole(holeNum)
      }
    }
    else {
      if (this.format === 'scramble') {
        netScore = scoresArray[0].getHoleScore(holeNum) - scoresArray[0].getHandicapStrokesHole(holeNum)
      }
      else if (this.format === '123') {
        
        for (let i=0; i<this.scoreCount; i++) {
          netScore += scoresArray[i].getHoleScore(holeNum) - scoresArray[i].getHandicapStrokesHole(holeNum)
        }
      }
      else if (this.format === 'bball') {
        const lowestScores = []
   

        const netScores = scoresArray.map(s=> (s.getHoleScore(holeNum) - s.getHandicapStrokesHole(holeNum)))
         
        sortArrayNum(netScores, 'asc')
    
        let lowestScoreTotal = 0
        let count = this.scoreCount
        if (count === 0) count = scores.length
        for (let j=0; j<count; j++) {
          if (j < netScores.length) lowestScoreTotal += netScores[j]
        }
        lowestScores.push(lowestScoreTotal)
        
        netScore = lowestScores.reduce((total, val) => total + val, 0)
      }
    }

   
    
    
    return netScore
  }

  getHolePoints (holeNum) {
    let holePoints = 0
    const scoresArray =  this.scoresArray

    if (scoresArray.length === 0) return holePoints

    const points = scoresArray.map(s=>s.getHolePoints(holeNum))
     
    sortArrayNum(points, 'desc')

   
    for (let j=0; j<this.scoreCount; j++) {
      if (j < points.length) holePoints += points[j]
    }
    
    return holePoints
  }

  getHoleNetPoints (holeNum) {

    let holePoints = 0

    const points = this.scores.map(s=>s.getHoleNetPoints(holeNum))
    
    if (this.scoreCount === 0) {
      holePoints = points.reduce((total, val) => total + val, 0)
    }
    else {
      sortArrayNum(points, 'desc')
      for (let j=0; j<this.scoreCount; j++) {
        if (j < points.length) holePoints += points[j]
      }
    }
   
   
    
    return holePoints
   

  }
  getNetPoints (side='full18') {
   
    let totalPoints = 0
   
  
    if (side === 'front') {
      for (let i=1; i<=9; i++) {
        totalPoints += this.getHoleNetPoints (i)
      }
    
    }
    else if (side === 'back') {
      for (let i=10; i<=18; i++) {
        totalPoints += this.getHoleNetPoints (i)
      }
  
    }
    else {
      for (let i=1; i<=18; i++) {
        totalPoints += this.getHoleNetPoints (i)
      }

    }
 
    
    return totalPoints
  }

 
  totalPoints (side='full18') {
   
    let totalPoints = 0
   
  
    if (side === 'front') {
      for (let i=1; i<=9; i++) {
        totalPoints += this.getHolePoints (i)
      }
    }
    else if (side === 'back') {
      for (let i=10; i<=18; i++) {
        totalPoints += this.getHolePoints (i)
      }
    }
    else {
      for (let i=1; i<=18; i++) {
        totalPoints += this.getHolePoints (i)
      }
    }
  
    return totalPoints
  }

  getDots (holeNum) {
    let holeDots = 0

    if (this.scoresArray.length === 0) return holeDots

    for (let score of this.scoresArray) {
      holeDots += score.getDots(holeNum)
    }
    
    return holeDots

  }

  totalDots (side='full18') {
    let totalDots = 0
    
    for (let score of this.scoresArray) {
      totalDots += score.totalDots(side)
    }
    return totalDots
  }

  getHandicapHoleAlloc (holeNum) {
    return this.firstScore.getHandicapHoleAlloc (holeNum)
  }

  getHolePar (holeNum) {
    return this.firstScore.getHolePar (holeNum)
  }

  getPar (side='full18') {
    return this.firstScore.getPar (side)
  }

  get holesCompleted () {
    let holesCompleted = 0
    if (this.format === 'scramble') {
      holesCompleted = this.firstScore.holesCompleted
    }
    else {
      for (let score of this.scoresArray) {
        if (score.holesCompleted > holesCompleted) holesCompleted = score.holesCompleted
      }
    }
  
    return holesCompleted
  }

  get dotsCount () {
    // this.loadScores ()
    let dots = 0
    for (let score of this.scores) {
      if (score) dots += score.dotsCount
    }
    return dots
  }

  getHandicapStrokesHole (holeNum, side='full18') {
    let strokes = 0
    let handicapStrokes
   
    if (side === 'front') {
      if (holeNum < 10) {
        handicapStrokes = this.handicapStrokesSide('front')
        strokes = handicapStrokes[holeNum-1]
      }
    }
    else if (side === 'back') {
      if (holeNum > 9 && holeNum <= 18) {
        handicapStrokes = this.handicapStrokesSide('back')
        strokes = handicapStrokes[holeNum-10]
      }
    }
    else {
      handicapStrokes = this.handicapStrokesSide('full18')
      strokes = handicapStrokes[holeNum-1]
    }
    return strokes
  }

  handicapStrokesSide (side) {
   
    let handicap = this.handicap(side)

    let holeOffset = 0
    let totalHoles = 9
    if (side === 'back') holeOffset = 9
    if (side === 'full18') totalHoles = 18
    const handicapHolesSide = []
    const handicapStrokes = new Array(totalHoles).fill(0)

    if (this.scores.length > 0) {
      const score = this.scores[0]
      if (score !== null) {
      
        for (let i=0; i<totalHoles; i++) {
          handicapHolesSide.push(score.holeHandicaps[i+holeOffset])
        }
        
        let handicapHolesSideSorted = []
       
    
        if (handicap > 0) {
          while (handicap > 0) {
            if (handicapHolesSideSorted.length === 0) {
                handicapHolesSideSorted = [...handicapHolesSide]
                handicapHolesSideSorted.sort(function(a, b){return a - b})
            }
          
            let holeNum = handicapHolesSideSorted.shift()
            let holeIndex = handicapHolesSide.indexOf(holeNum)
            handicapStrokes[holeIndex] += 1
            handicap--
          }
        }
        else if (handicap < 0) {  // plus handicap, so allocate -1 strokes to easiest holes
          while (handicap < 0) {
            if (handicapHolesSideSorted.length === 0) {
                handicapHolesSideSorted = [...handicapHolesSide]
                handicapHolesSideSorted.sort(function(a, b){return b - a})
            }
          
            let holeNum = handicapHolesSideSorted.shift()
            let holeIndex = handicapHolesSide.indexOf(holeNum)
            handicapStrokes[holeIndex] -= 1
            handicap++
          }
        }
      }
    }
    

    return handicapStrokes
  }


  copy () {
    const teamScore = new TeamScore()
    teamScore.setObj(this.getObj())
    return teamScore
  }

  toJSON () {
    return JSON.stringify(this.getObj())
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  getObj () {
    return {
      objType: this.objType,
      teamNum: this.teamNum,
      format: this.format,
      handicapType: this.handicapType,
      scoreCount: this.scoreCount,
      scoreDocIds: [...this.scoreDocIds],
      scores: [...this.scores], // make sure scores objects are copied by ref
      blindDraw: [...this.blindDraw]
    }
  }
  setObj (data) {
    this.objType = data.objType
    this.teamNum = data.teamNum
    this.format = data.format
    this.handicapType = data.handicapType
    this.scoreCount = data.scoreCount
    this.scoreDocIds = [...data.scoreDocIds]
    this.scores = [...data.scores], // make sure scores objects are copied by ref
    this.blindDraw = [...data.blindDraw]
  }
  getData () {
   
    return {
      teamNum: this.teamNum,
      format: this.format,
      handicapType: this.handicapType,
      scoreDocIds: this.scoreDocIds,
      blindDraw: this.blindDraw,
      scoreCount: this.scoreCount
    }
  }
  setData (data) {
    this.teamNum = data.teamNum
    this.format = data.format
    this.handicapType = data.handicapType
    this.scoreDocIds = data.scoreDocIds
    this.scores = new Array(this.size).fill(null)
    this.blindDraw = data.blindDraw
    this.scoreCount = data.scoreCount
  }
}
