<template>
  <div>
    <template v-if="isField">
      <b-field :label="label" :message="message">
    
        <b-autocomplete
          v-model="courseLabel"
          icon="magnify"
          append-to-body
          style="width:100%"
          :data="courses"
          field="label"
          :disabled="disabled"
          @input="searchCourses"
          @select="selectCourse" >
        </b-autocomplete>
      </b-field>
    </template>
    <template v-else>
      <b-autocomplete
        v-model="courseLabel"
      
        style="width:100%"
        :data="courses"
        field="label"
        :disabled="disabled"
        @input="searchCourses"
        @select="selectCourse"
        append-to-body
      >
      </b-autocomplete>
    </template>
   </div>
  
</template>

<script>

import CourseController from '../../controllers/course'  


export default {
  data () {
    return {
      loading: false,
      courses: []
    }
  },
 
  props: {
    course: {type: Object},
    label: {type: String},
    disabled: {type: Boolean, default: false},
    isField: {type: Boolean, default: true},
    message: {type: String, default: ''},
    index: {type: Number, default: null}
  },
  
  computed: {
  
    courseLabel: {
      set () {
        
      },
      get () {
        return this.course.label
      }
    }
  },
  methods: {
    async searchCourses (name) {
      this.loading = true
      const search = {
        searchValue: name,
        queryLimit: 1000
      }
      const response = await CourseController.query(search)
      if(response.errorMsg.length > 0) {
        this.$store.commit('group/errorMsg', response.errorMsg)
      } else {
        this.$store.commit('group/errorMsg','')
        this.courses = response.selectedItems
      }
      this.loading = false

    },
    selectCourse (course) {
      if (this.index===null) {
        this.$emit('courseSelected', course)
      }
      else {
        this.$emit('courseSelected', this.index, course)
      }
      
    }

  }

}

</script>

<style>

</style>
