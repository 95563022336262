
<template>
  <div>
    <b-field label="Group Name">
      <b-input :value="group.name" @blur="updateGroupName($event.target.value)"/>
    </b-field>

    <select-course :course="group===null?null:group.course" label="Home Course" @courseSelected="selectCourse" :disabled="false"/>

    <b-field grouped>
      <b-field label="Tee Start Time">
        <b-timepicker
          :value="group.startingTime" 
          icon="clock" 
          style="width:11em;"
          append-to-body
          @input="value=>updateStartingTime(value)"  />
      </b-field>
      <b-field label="Interval (min)">
        <b-numberinput
          :value="group.interval"
          :max="59" :min="1"
          style="width:5em;"
          :controls="false"
          @input="value=>updateInterval(value)" />
      </b-field>
      <b-field v-if="!isMobile">
        <b-switch class="has-margin-top-35" :value="group.holeByHoleScores" @input="value=>updateHoleByHoleScores(value)">Hole by Hole Scoring</b-switch>
      </b-field>
      
    </b-field>
    <b-field v-if="isMobile">
      <b-switch :value="group.holeByHoleScores" @input="value=>updateHoleByHoleScores(value)">Hole by Hole Scoring</b-switch>
    </b-field>

    <div class="level has-margin-bottom-5 is-mobile">
      <div class="level-left">
        <div class="level-item">
          <p class="label">Seasons</p>
        </div>
          <div class="level-item">
          <b-button type="is-info" @click="addSeason" >Add</b-button>
        </div>
      </div>
    </div>
    <b-table
      :data="group.seasons.aSeasons"
      narrowed
      mobile-cards
      style="padding-bottom:10px;"
    >
     
        <b-table-column field="name" label="Name" v-slot="props">
          <b-input 
            :value="props.row.name"
            @blur="updateSeasonName(props.index, $event.target.value)"
          />
        </b-table-column>
        <b-table-column field="fromDate" label="From" width="120" v-slot="props">
          <b-datepicker
            v-model="seasonFromDates[props.index]"
            append-to-body
           
            position="is-bottom-left"
           />
        </b-table-column>
        <b-table-column field="toDate" label="To" width="120" v-slot="props">
          <b-datepicker
            v-model="seasonToDates[props.index]"
            append-to-body
            position="is-bottom-left"
             />
        </b-table-column>
        <b-table-column width="40" v-slot="props">
          <span class="icon" @click="deleteSeason(props.index)">
            <i class="delete"></i>
          </span>
        </b-table-column>
     
    </b-table>
      
    <b-field grouped>
      <b-field>
        <b-switch :value="group.betOnGames" @input="value=>updateBetOnGames(value)">Bet On Games</b-switch>
      </b-field>
      <b-field v-if="group.betOnGames && !isMobile">
        <b-switch :value="group.roundPayouts" @input="value=>updateRoundPayouts(value)">Round Payouts to Whole Number</b-switch>
      </b-field>
    </b-field>
    <b-field v-if="group.betOnGames && isMobile">
      <b-switch :value="group.roundPayouts" @input="value=>updateRoundPayouts(value)">Round Payouts to Whole Number</b-switch>
    </b-field>

    <b-button type="is-danger" outlined @click="deleteGroup">Delete this group</b-button>
  </div>
</template>

<script>

import SelectCourse from '../../components/Course/SelectCourse'
import RoundController from '../../controllers/round'
import { mapGetters } from "vuex"
import DBUpdate from '../../helpers/dbUpdate'

export default {
  data() {
    return {
      seasonFromDates: null,
      seasonToDates: null
    }
  },
 
  components: {
    'select-course': SelectCourse
  },

  created () {
    this.seasonFromDates = this.group.seasons.aSeasons.map(s=>s.fromDate)
    this.seasonToDates = this.group.seasons.aSeasons.map(s=>s.toDate)
  },

  watch: {
    seasonFromDates: {
      deep: true,
      handler() {
        this.updateSeasonFromDates()
      }
    },
    seasonToDates: {
      deep: true,
      handler() {
        this.updateSeasonToDates()
      }
    }
  },
  
  computed: {
     ...mapGetters('group', [
      'group',
      'errorMsg'
    ]),
   
    error: {
      set (newValue) {
        if (!newValue) {
          this.$store.commit('group/errorMsg','')
        }
      },
      get () {
        return this.errorMsg.length > 0
      }
    }
    
  },
  methods: {
  
    async updateGroupName (name) {
      
      const groupUpdate = new DBUpdate(this.group.docId, {name: name})
    
      await this.$store.dispatch('group/saveValues', groupUpdate)
      this.$store.commit('user/updateGroupName', name)
    },

    async selectCourse (course) {
      if (course.docId !== this.group.course.docId) {

         
        const groupUpdate = new DBUpdate(this.group.docId, {courseDocId: course.docId, course: course.getData()})
        await this.$store.dispatch('group/saveValues', groupUpdate)
       
      }
    },
    async updateStartingTime (val) {
  
      const groupUpdate = new DBUpdate(this.group.docId, {startingTime: val})
      await this.$store.dispatch('group/saveValues', groupUpdate)
      
    },

    async updateInterval (val) {
     
      const groupUpdate = new DBUpdate(this.group.docId, {interval: val})
      await this.$store.dispatch('group/saveValues', groupUpdate)
     
    },

    async updateHoleByHoleScores (val) {
      const groupUpdate = new DBUpdate(this.group.docId, {holeByHoleScores: val})
    
      await this.$store.dispatch('group/saveValues', groupUpdate)
     
    },

    async addSeason () {
      const seasons = this.group.seasons.copy()
      seasons.add()
     
      const groupUpdate = new DBUpdate(this.group.docId, {seasons: seasons.getData()})
      await this.$store.dispatch('group/saveValues', groupUpdate)
    

    },
    async updateSeasonName (index, name) {
      const seasons = this.group.seasons.copy()
      const season = seasons.aSeasons[index]
      season.name = name
   
      const groupUpdate = new DBUpdate(this.group.docId, {seasons: seasons.getData()})
      await this.$store.dispatch('group/saveValues', groupUpdate)
    

    },

    async updateSeasonFromDates () {
      
      const seasons = this.group.seasons.copy()
      for (let i=0; i<seasons.aSeasons.length; i++) {
        seasons.aSeasons[i].fromDate = this.seasonFromDates[i]
      }
    
      const groupUpdate = new DBUpdate(this.group.docId, {seasons: seasons.getData()})

      await this.$store.dispatch('group/saveValues', groupUpdate)
    

    },

    async updateSeasonToDates () {
      const seasons = this.group.seasons.copy()
       for (let i=0; i<seasons.aSeasons.length; i++) {
        seasons.aSeasons[i].toDate = this.seasonToDates[i]
      }
      
      const groupUpdate = new DBUpdate(this.group.docId, {seasons: seasons.getData()})
      
      await this.$store.dispatch('group/saveValues', groupUpdate)

    },

    async deleteSeason (index) {
      const seasons = this.group.seasons.copy()
      const season = this.group.seasons.aSeasons[index]

      const response = await RoundController.hasRounds (this.group.docId, season)
     
      if (response.errorMsg.length === 0) {
        if (response.numRounds === 0) {
          seasons.deleteByIndex(index)
        
          const groupUpdate = new DBUpdate(this.group.docId, {seasons: seasons.getData()})
          await this.$store.dispatch('group/saveValues', groupUpdate)
        }
        else {
          this.$buefy.dialog.confirm({
            title: 'Delete Season',
            message: `There are ${response.numRounds} rounds this Season. All rounds and scores will be deleted. Are you sure you want to <b>DELETE</b>?  This action cannot be undone.`,
            confirmText: 'Delete Season',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: async () => {
              await this.$store.dispatch('group/deleteSeason', season)
            }
          })
        }

      }
      else {
        this.$store.commit('group/errorMsg', response.errorMsg)
      }
     

     
    },

    async updateBetOnGames (val) {
    
      const groupUpdate = new DBUpdate(this.group.docId, {betOnGames: val})
      await this.$store.dispatch('group/saveValues', groupUpdate)
    },

    async updateRoundPayouts (val) {
      const groupUpdate = new DBUpdate(this.group.docId, {roundPayouts: val})
      
      await this.$store.dispatch('group/saveValues', groupUpdate)
    },

  

    deleteGroup () {
      this.$buefy.dialog.confirm({
        title: 'Delete Group',
        message: 'Are you sure you want to <b>delete</b> this Group? All rounds and scores will be deleted. This action cannot be undone.',
        confirmText: 'Delete Group',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$buefy.dialog.confirm({
              message: 'Are you REALLY sure you want to delete this Group?',
              onConfirm: () => {
                this.$store.dispatch('group/delete', this.group.docId)
              }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
