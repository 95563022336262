
import dayjs from 'dayjs'


import Games from './games'
import Course from './course'
import Handicap from './handicap'

import TeeTimes from './teeTimes'
import { sortArrayObj, formatPayout, formatCurrency, nth, newDate, getDataValue, FSTimestampToDate, DateToFSTimestamp } from '../lib'

export default class Round {
  constructor(data) {
    if(data) {
      this.setObj(data)
    }
    else {
      this.initialize()
    }
  }
  
  initialize () {
    this.docId = ''
    this.groupDocId = ''
    this.tournamentDocId = null
    this.course = new Course() // save copy of course for history
    this.date = newDate()
    this.roundNum = ''
    this.status = 'Scheduled'
    this.name = ''
    this.posted = false
    this.numPlayers = 0
    this.betOnGames = false
    this.roundPayouts = true
    this.games = new Games()
    this.handicap = new Handicap()
    this.teeTimes = new TeeTimes()
    this.numHoles = 18
   
    // this.inviteRespondByDate = null
    this.holeByHoleScores = false
    this.pcc = 0
    this.excluded = false
    this.closeSignups = false
   
    this.msg = ''
    this.msgGroupNum = 0

    this.objName = 'Round'
  }

 
  get formattedDate () {
    // return moment(this.date).format('ddd L')
    return dayjs(this.date).format('ddd MM/DD/YYYY')
  }

  get formattedLongDate () {
    // return moment(this.date).format('dddd ll')
    return dayjs(this.date).format('dddd MMM D, YYYY')
  }

  get formattedDateLabel () {
    return dayjs(this.date).format('ddd MMM D, YYYY')
  }

  get tsDate () {
    return fs.Timestamp.fromDate(this.date)
  }

  get title () {
    return `${this.name} ${dayjs(this.date).format('ddd MMM D, YYYY')} at ${this.course.name} `
  }

  get roundLabel () {
    return `${dayjs(this.date).format('ddd MMM D, YYYY')} at ${this.course.name} `
  }

  get fullName () {
    let name = this.name
    if (this.isTournament) {
      name = `Round ${this.roundNum} ${name}`
    }
    return name
  }

  get isPosted () {
    return this.status === 'Posted'
  }

  get isNotPosted () {
    return !this.isPosted
  }

  get isInProgress () {
    return this.status.includes('In Progress')
  }

  get isScheduled () {
    return this.status.includes('Scheduled')
  }

  get isCompleted () {
    return this.status === 'Completed'
  }

  get isTournament () {
    return this.tournamentDocId !== null
  }

  assignScores (scoresArray) {
   
    this.teeTimes.assignScores (scoresArray)
    this.games.assignScores (scoresArray)
   
  }

  update (roundUpdate) {
   
    for (let prop in roundUpdate.update) {
      if (prop === 'handicap') {
        this.handicap.setData(roundUpdate.update['handicap'])
      }
      else if (prop === 'games') {
        this.games.setData(roundUpdate.update['games'])
      }
      else if (prop === 'course') {
        this.course.setData(roundUpdate.update['course'])
      }
      else if (prop === 'teeTimes') {
        this.teeTimes.setData(roundUpdate.update['teeTimes'])
      }
      else {
        this[prop] = roundUpdate.update[prop]
      }
      
    }
   
  }

  inviteUrl(hostName, playerDocId, status) {
    let url = ''
    if (playerDocId === '') {
      url = `${hostName}/roundsignup?group=${this.groupDocId}&roundId=${this.docId}&status=${status}`
    }
    else {
      url = `${hostName}/roundsignup?group=${this.groupDocId}&roundId=${this.docId}&playerId=${playerDocId}&status=${status}`
    }
    return encodeURI(url)
  }
  hasGame (gameProp) {
    return this.games.hasGame(gameProp)
  }
  handicapHoles (tee, gender='M') {
    return this.course.tees.getTee(tee, gender).handicap
  }
  getHolePar (tee, holeNum, gender='M') {
    const par = this.course.tees.getTee(tee, gender).par
    let parVal = 0
    if (holeNum >= 1 && holeNum <= 18) parVal = par[holeNum-1]
    return parVal
  }
  parHoles (tee, gender='M') {
    const par = this.course.tees.getTee(tee, gender).par
    return par
  }
  isKPHole (tee, holeNum) {
   
    return this.parHoles(tee)[holeNum-1] === 3
   
  }
  parFront (tee) {
    const holes = this.parHoles(tee)
    let par = 0
    for(let i=0; i<9; i++) {
      par += holes[i]
    }
    return par
  }
  parBack(tee) {
    const holes = this.parHoles(tee)
    let par = 0
    for(let i=9; i<18; i++) {
      par += holes[i]
    }
    return par
  }

  isComplete (scoresArray, numHoles=18) {
    let complete = true

    for (let score of scoresArray) {
      if (!score.isRoundComplete(numHoles)) {
        complete = false
        break
      }
    }

    return complete
  }

  isHoleCompleted (holeNum, scoresArray) {
    let complete = true

    for (let score of scoresArray) {
      if (!score.isHoleCompleted(holeNum)) {
        complete = false
        break
      }
    }

    return complete
  }

  holesCompleted (scoresArray) {
    let holeNum = 0

    for (let score of scoresArray) {
      const scoreHolesCompleted = score.holesCompleted
      if (scoreHolesCompleted > holeNum) {
        holeNum = scoreHolesCompleted
      }
    }

    return holeNum
  }
  
  copy () {
    const round = new Round()
    round.setObj(this.getObj())
    return round
  }

  getHandicapFac() {
    let facFull18 = 1.0
    let facFront = 1.0
    let facBack = 1.0

    let game = this.games.aGames.find(g=>(g.prop==='stroke' && g.side==='full18'))
    if (game !== undefined) {
      facFull18 = game.handicapFac
    }
    game = this.games.aGames.find(g=>(g.prop==='stroke' && g.side==='front'))
    if (game !== undefined) {
      facFront = game.handicapFac
    }
    game = this.games.aGames.find(g=>(g.prop==='stroke' && g.side==='back'))
    if (game !== undefined) {
      facBack = game.handicapFac
    }

    return {
      'full18': facFull18,
      'front': facFront,
      'back': facBack,
    }
  }

  getStrokeHandicapFac (side) {
    let fac = 1
    const game = this.games.aGames.find(g=>(g.prop==='stroke' && g.side===side))
    if (game !== undefined) {
      fac = game.handicapFac
    }
    return fac
  }

  getSkinsHandicapFac () {
    let fac = 1
    const game = this.games.aGames.find(g=>(g.prop==='skins'))
    if (game !== undefined) {
      fac = game.handicapFac
    }
    return fac
  }

  
  toJSON () {
    return JSON.stringify(this.getObj())
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))

  }
  getObj () {

    return {
      docId: this.docId,
      groupDocId: this.groupDocId,
      tournamentDocId: this.tournamentDocId,
      course: this.course.getObj(),
      date: new Date(Number(this.date)),
      status: this.status,
      name: this.name,
      posted: this.posted,
      numPlayers: this.numPlayers,
      games: this.games.getObj(),
      handicap: this.handicap.getObj(),
      pcc: this.pcc,
      excluded: this.exclude,
      betOnGames: this.betOnGames,
      roundPayouts: this.roundPayouts,
      holeByHoleScores: this.holeByHoleScores,
      teeTimes: this.teeTimes.getObj(),
      numHoles: this.numHoles,
   
      // inviteRespondByDate: this.inviteRespondByDate ? new Date(Number(this.inviteRespondByDate)) : null,
      roundNum: this.roundNum,
    
      msg: this.msg,
      msgGroupNum: this.msgGroupNum
    }
  }
  setObj (data) {
    this.docId = data.docId,
    this.groupDocId = data.groupDocId,
    this.tournamentDocId = data.tournamentDocId,
    this.course.setObj(data.course)
    this.date = new Date(Number(data.date))
    if(typeof data.date === "string") this.date = new Date(data.date)
    this.status = data.status
    this.name = data.name
    this.posted = data.posted
    this.numPlayers = data.numPlayers
    this.games.setObj(data.games)
    this.handicap.setObj(data.handicap)
    this.pcc = data.pcc
    this.excluded = data.excluded
    this.closeSignups = data.closeSignups
    this.holeByHoleScores = data.holeByHoleScores
    this.roundPayouts = data.roundPayouts
    this.betOnGames = data.betOnGames
    this.teeTimes.setObj(data.teeTimes)
    this.numHoles = data.numHoles
  
    // this.inviteRespondByDate = data.inviteRespondByDate ? new Date(Number(data.inviteRespondByDate)) : null
    this.roundNum = data.roundNum
   
    this.msg = data.msg
    this.msgGroupNum = data.msgGroupNum
  }
  getData () {

    return {
      tournamentDocId: this.tournamentDocId,
      course: this.course.getData(),
      date: DateToFSTimestamp(this.date), // convert date string to Timestamp object
      status: this.status,
      name: this.name,
      posted: this.posted,
      numPlayers: this.numPlayers,
      games: this.games.getData(),
      handicap: this.handicap.getData(),
      pcc: this.pcc,
      excluded: this.excluded,
      closeSignups: this.closeSignups,
      holeByHoleScores: this.holeByHoleScores,
      betOnGames: this.betOnGames,
      roundPayouts: this.roundPayouts,
      teeTimes: this.teeTimes.getData(),
      numHoles: this.numHoles,
    
      // inviteRespondByDate: this.inviteRespondByDate ? fs.Timestamp.fromDate(this.inviteRespondByDate) : null,
      roundNum: this.roundNum,
      msg: this.msg,
      msgGroupNum: this.msgGroupNum
    }
  }
  
  setData (data) {
    this.tournamentDocId = getDataValue(data, 'tournamentDocId', null)
    if (data.course !== undefined) {
      this.course.setData(data.course)
    }
    
    this.date = FSTimestampToDate(data.date) // stored as Timestamp object
    this.status = data.status
    this.name = getDataValue(data, 'name', '')
    this.posted = data.posted
    this.numPlayers = data.numPlayers
    this.games.setData(data.games)
    this.handicap.setData(data.handicap)
    this.pcc = getDataValue(data,'pcc', 0)
    this.excluded = getDataValue(data, 'excluded', false)
    this.closeSignups = getDataValue(data, 'closeSignups', false)
    this.holeByHoleScores = data.holeByHoleScores
    this.betOnGames = data.betOnGames
    this.roundPayouts = getDataValue(data, 'roundPayouts', true)
    if (data.teeTimes !== undefined) this.teeTimes.setData(data.teeTimes)
    this.numHoles = getDataValue(data, 'numHoles', 18)
 
    // this.inviteRespondByDate = data.inviteRespondByDate ? data.inviteRespondByDate.toDate() : null
    this.roundNum = getDataValue(data,'roundNum', '')
    this.msg = getDataValue(data, 'msg', '')
    this.msgGroupNum = getDataValue(data, 'msgGroupNum', 0)


    sortArrayObj(this.games.aGames,'order','asc')
  }
}

