<template>
  <div>
   
    <toolbar :itemSelected="itemSelected" @selected="selectTab"/>
   
    <template v-if="isMobile">
      <div class="header">
        <div class="level is-mobile header-level">
          <div class="level-left">
            <div class="level-item  ">
              <p class="title is-5" style="margin-bottom:0px;">{{groupName + ' - ' + this.title}}</p>
            </div>
          </div>
          
        </div>
      
      </div>
    </template>
    <div class="has-padding-10" :class="isMobile?'has-padding-top-60':''">
  
      <div v-if="!isMobile" class="level  has-margin-bottom-5 is-mobile">
        <div class="level-left">
          <p class="level-item title is-size-5">
            {{ groupName }}
          </p>
        </div>
      
      </div>

      <b-notification :active.sync="error">
        {{ errorMsg }}
      </b-notification>
        
      <template v-if="isMobile">
         <group-settings
          v-if="itemSelected==='settings'"
       
        />

        <group-players 
          v-if="itemSelected==='roster'"
       
        />
        
        <games 
          v-if="itemSelected==='games'"
          :baseObj="group" 
          :numPlayers="0" 
          :readOnly="readOnly" 
        />
       
        <group-handicaps 
          v-if="itemSelected==='handicaps'"
        />
       
        <group-rounds 
          v-if="itemSelected==='rounds'"
       
        />

     
        <group-results 
          v-if="itemSelected==='standings'"
          :group="group" />
        
      </template>
      <template v-else>
        <b-tabs
          v-model="activeTab"
           >
           
          <b-tab-item label="Roster" icon="account">
            <group-players />
          </b-tab-item>
          <b-tab-item :visible="groupBetOnGames" label="Games" icon="view-dashboard">
            <games 
              :baseObj="group" 
              :numPlayers="0" 
              :readOnly="readOnly"  />
          </b-tab-item>
          <b-tab-item :visible="isAdmin" label="Handicaps" icon="emoticon-outline">
            <group-handicaps 
            />
          </b-tab-item>
          <b-tab-item label="Tournaments" icon="tournament">
            <group-tournaments />
          </b-tab-item>
          <b-tab-item label="Rounds" icon="golf">
            <group-rounds />
          </b-tab-item>
          <b-tab-item label="Standings" icon="receipt">
            <group-results />
          </b-tab-item>
          <b-tab-item label="Settings" icon="settings">
            <group-settings />
          </b-tab-item>
        </b-tabs>
      </template>
      

      
      <b-modal  :active.sync="showAbout" >
        <about />
      </b-modal>
      <b-modal has-modal-card :active.sync="showAccount" >
        <account />
      </b-modal>

      <b-loading is-full-page :active.sync="isLoading" :can-cancel="true"></b-loading>
    </div>
 </div>
</template>

<script>
// import { fbFunc } from '../../firebase'

import GroupController from '../../controllers/group'
import GroupTournaments from '../../components/Group/Tournaments'
import GroupRounds from '../../components/Group/Rounds'
import GroupSettings from '../../components/Group/Settings'
import GroupPlayers from '../../components/Group/Players'
import GroupHandicaps from '../../components/Group/Handicaps'
import GroupResults from '../../components/Group/Results'
import Games from '../../components/Games/Games'
import Seasons from '../../models/seasons'

import dayjs from 'dayjs'

import Toolbar from '../../components/Shared/toolbar'
import SelectCourse from '../../components/Course/SelectCourse'
import About from '../../components/Dialogs/About'
import Account from '../../components/Dialogs/Account'

import DBUpdates from '../../helpers/dbUpdates'
import DBUpdate from '../../helpers/dbUpdate'

import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      isLoading: false,
      title: 'Roster',
      itemSelected: 'roster',

      showAbout: false,
      showAccount: false,
      tabs: [
        {
          name: 'roster',
          title: 'Roster',
          tab: 0
        },
        {
          name: 'games',
          title: 'Games',
          tab: 1
        },
        {
          name: 'handicaps',
          title: 'Handicaps',
          tab: 2
        },
        {
          name: 'tournaments',
          title: 'Tournaments',
          tab: 3
        },
        {
          name: 'rounds',
          title: 'Rounds',
          tab: 4
        },
        {
          name: 'standings',
          title: 'Standings',
          tab: 5
        },
        {
          name: 'settings',
          title: 'Settings',
          tab: 6
        }
      ]
    }
  },

  components: {
    'group-tournaments': GroupTournaments,
    'group-rounds': GroupRounds,
    'group-players': GroupPlayers,
    'group-settings': GroupSettings,
    'group-handicaps': GroupHandicaps,
    'group-results': GroupResults,
    'games': Games,
    'toolbar': Toolbar,
    'select-course': SelectCourse,
    'about': About,
    'account': Account
  },

  created () {

  
    this.$store.dispatch('round/clear')
    
    if (this.initialTab === undefined) {
      const tab = this.tabs.find(t => t.tab === this.activeTab)
      this.selectTab (tab.name)
    }
    if (this.initialTab !== '' && this.initialTab !== undefined) {
      this.selectTab (this.initialTab)
    }
  },
  props: {
    initialTab: {
      type: String,
      default: ''
    }
  },
    


  computed: {
    ...mapGetters('group',{
      group: 'group',
      rounds: 'rounds',
      tournaments: 'tournaments',
      errorMsg: 'errorMsg',
      tab: 'tab',
      seasonStandings: 'seasonStandings'
    }),
    error: {
      set (newValue) {
        if (!newValue) {
          this.$store.commit('group/errorMsg', '')
        }
      },
      get () {
        return this.errorMsg.length > 0
      }
    },
    activeTab: {
      get () {
        return this.tab
      },
      set (val) {
        this.$store.commit('group/tab', val)
      }
    },
    isAdmin () {
      return this.$store.getters['user/isAdmin']
    },
    readOnly () {
      return !this.isAdmin
    },
    playerTees () {
      let tees = []
      if (this.group) tees = this.group.course.getPlayerTees()
      return tees
    },
    seasonsArray () {
      let seasons = []
      if (this.group) seasons = this.group.seasons.aSeasons
      return seasons
    },
    groupName () {
      let name = ''
      if (this.group) name = this.group.name
      return name
    },
    groupBetOnGames () {
      let bet = false
      if (this.group) bet = this.group.betOnGames
      return bet
    }
  },

  methods: {
    selectTab (tabName) {
      
    
      if (tabName === 'about') {
        this.showAbout = true
      }
      else if (tabName === 'account') {
        this.showAccount= true
      }
      else {
        this.itemSelected = tabName
        const tab = this.tabs.find(t => t.name === tabName)
        this.title = tab.title
        this.activeTab = tab.tab
      }
      
    },


    async testCF () {
      // const lookupGhinIndex = fbFunc.httpsCallable('lookupGhinIndex')
      // const sendEmail = fbFunc.httpsCallable('sendEmail')

      try {
        // const playerInfo = {
        //   lastName: 'Elston',
        //   firstName: 'Ken',
        //   state: 'GA',
        //   ghinNum: ''
        // }
        // const response = await lookupGhinIndex(playerInfo)
        // sortArrayObj(this.group.rounds.aRounds, 'num', 'desc')
        
        // const round = this.group.rounds.aRounds[0]
        // console.log('url', round.inviteUrl(this.$hostname, 13, 1))
        // const emailInfo = {
        //   to: 'kjelston@gmail.com',
        //   groupName: 'Ish Group',
        //   roundDate: moment('2019-03-30').format('dddd ll'),
        //   roundDay: moment('2019-03-30').format('dddd'),
        //   body1: 'The Golf Shop will assign tee times starting around 11am. An email with your exact tee time will be sent the night before.',
        //   body2: 'If you need to cancel, contact the Golf Shop and replace your name with a TBD in case someone else in our group wants your tee time.',
        //   acceptUrl: round.inviteUrl(this.$hostname, 13, 1),
        //   declineUrl: round.inviteUrl(this.$hostname, 13, 2)
        // }

        // const response = await sendEmail(emailInfo)

        // console.log('response', response)
      }
      catch (error) {
        console.log('lookup err', error)
      }
    }
  }

}

</script>

<style>
  .mobile-padding {
    margin-top: 60px;
    padding-left:8px; 
    padding-right:8px;
  }
  .header {
    position:fixed;
    width:100%;
    z-index:50;
    background-color:white;
    margin: 0 0 0 0;
  }
  
  .header-level {
    margin-left: 20px !important;
    margin-top:10px;
    padding-right:20px;
    margin-bottom:0px !important;
  }
  
 
</style>

