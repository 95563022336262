<template>
  <div>
    <template v-if="isMobile">
      <table class="table is-narrow is-size-6" width="100%" >
      <tbody>
        <tr @click="openHoleScores(teamScore)" style="border-top:2px solid">
          <td :rowspan="round.games.hasGame('dots')?4:3">
            <p>Team</p>
          </td>
          <td class="vert-middle" width="30">Front Net:</td>
          <td class="vert-middle" width="30">{{teamScore.netScore('front')}}</td>
          <td class="vert-middle" :class="parClass2(teamScore.toPar('front'))" >{{teamScore.toPar('front') }}</td>
          

          <td :rowspan="round.games.hasGame('dots')?4:3" width="20" class="vert-middle">
            <a >
              <b-icon icon="chevron-right"/>
            </a>
          </td>
        </tr>
        <tr>
          <td class="vert-middle" >Back Net:</td>
          <td class="vert-middle" >{{teamScore.netScore('back')}}</td>
          <td class="vert-middle" :class="parClass2(teamScore.toPar('back'))" >{{teamScore.toPar('back') }}</td>


        </tr>
        <tr>
          <td class="vert-middle" style="border-bottom: 1px solid #dee2e5;">Total Net:</td>
          <td class="vert-middle" style="border-bottom: 1px solid #dee2e5;">{{teamScore.netScore('full18')}}</td>
          <td class="vert-middle" style="border-bottom: 1px solid #dee2e5;" :class="parClass2(teamScore.toPar('full18'))" >{{teamScore.toPar('full18')}}</td>

        </tr>

        <tr v-if="round.games.hasGame('dots')" >
          <td class="vert-middle" width="30">Dots:</td>
          <td class="vert-middle" width="30">{{teamScore.totalDots()}}</td>
          <td></td>
        </tr>
        <template v-for="(score,index) in teamScore.scores" >
          <tr  :key="index" @click="openHoleScores(score)" style="border-top:2px solid">
            <td :rowspan="round.games.hasGame('dots')?4:3">
              <p>{{score.name}}</p>
              <p class="is-size-7">Hdcp {{score.handicap.full18}} ({{score.tee}})</p>
            </td>
            <td class="vert-middle" width="30">Front:</td>
            <td class="vert-middle" width="30">{{score.score.front}}</td>
            <td class="vert-middle" :class="parClass2(toPar(score,'front'))" >{{toPar(score,'front') }}</td>
            

            <td :rowspan="round.games.hasGame('dots')?4:3" width="20" class="vert-middle">
              <a >
                <b-icon icon="chevron-right"/>
              </a>
            </td>
          </tr>
          <tr :key="index+10">
            <td class="vert-middle" >Back:</td>
            <td class="vert-middle" >{{score.score.back}}</td>
            <td class="vert-middle" :class="parClass2(toPar(score,'back'))" >{{toPar(score,'back') }}</td>


          </tr>
          <tr :key="index+20">
            <td class="vert-middle" style="border-bottom: 1px solid #dee2e5;">Total:</td>
            <td class="vert-middle" style="border-bottom: 1px solid #dee2e5;">{{score.score.full18}}</td>
            <td class="vert-middle" style="border-bottom: 1px solid #dee2e5;" :class="parClass2(toPar(score,'full18'))" >{{toPar(score,'full18') }}</td>

          </tr>

          <tr v-if="round.games.hasGame('dots')" :key="index+30">
            <td class="vert-middle" width="30">Dots:</td>
            <td class="vert-middle" width="30">{{score.dotsCount}}</td>
            <td></td>
          </tr>
        </template>
      
      </tbody>
    </table>
    </template>
    <template v-else>
      <p class="has-text-weight-bold is-size-5 has-padding-5">Team</p>
      <hole-scores :score="teamScore" :editScore="false"  :round="round"  />
      <template v-if="teamScore.format !== 'scramble'">
        <div v-for="(score,index) in teamScore.scores" :key="index">
          <p class="has-text-weight-bold is-size-5 has-padding-5">{{score.name}}</p>
          <hole-scores :score="score" :editScore="false"  :round="round"  />
        </div>
      </template>
    </template>

    <b-modal 
      :active.sync="showHoleScores"
      has-modal-card 
      full-screen
      :can-cancel="false"
      style="z-index:100;"
     >
      <div class="modal-card" style="width:auto">
        <div class="modal-body">
          <div class="level is-mobile has-margin-bottom-5" @click="showHoleScores=false">
            <div class="level-left">
              <div class="level-item">
                <a class="mt-2" >
                  <b-icon icon='chevron-left'></b-icon>
                </a>
              </div>
              <div class="level-item">
                <p class="subtitle">{{activeScore.name }}</p>
              </div>
            </div>
          </div>
          
          <hole-scores 
            :score="activeScore" 
            :editScore="false" 
            :round="round" 
      
       
          />
        </div>
      </div>
     
  
    </b-modal>
    
  </div>
</template>
 
<script>

import { formatToPar, parClass, parClass2 } from "../../lib"
import HoleScores from './HoleScores'
import Score from '../../models/score'

export default {
  data () {
    return {
      showHoleScores: false,
      activeScore: new Score()
    }
  },

  props: {
    teamScore: Object,
    round: Object
  },

  components: {
    'hole-scores': HoleScores
  },
  
  computed: {
    isAdmin () {
      return this.$store.getters['user/isAdmin']
    },
    readOnly () {
      return !this.isAdmin || !this.round.isInProgress
    }
  
   
  },
  methods: {
    parClass,
    parClass2,

    getHandicapHoles(holeNum) {
      return this.round.handicapHoles(this.teamTee)[holeNum-1]
    },

    getParHoles (holeNum) {
      return this.round.parHoles(this.teamTee)[holeNum-1]
    },

    toPar (score, side='full18') {
 
      return formatToPar(score.toPar(side))
    },

    openHoleScores (score) {
      this.showHoleScores = true
      this.activeScore = score
    }
  
  }
  
  
}

</script>

<style lang="scss">
  
</style>