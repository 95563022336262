<template>
  <div>
    <toolbar  @selected="selectTab"/>
    <div class="columns is-centered" :class="!isMobile?'is-vcentered':''">
      <div class="column is-4 ">
        <b-notification :active.sync="error">
          {{ errorMsg }}
        </b-notification>
        <h3 class="title has-text-centered has-text-dark has-margin-top-10">Sign In</h3>
    
        <form @keyup.enter="signIn()" class="box">
          <b-field
            label="Email"
            :message="emailErrors"
            >
            <b-input 
              ref="email"
              type="email"
              v-model.trim.lazy="email"
              @blur="$v.email.$touch()"
              maxlength="30" />
          </b-field>
          <b-field 
            label="Password"
            :message="passwordErrors"
            style="margin-bottom: 0px;"
            >
            <b-input
              type="password"
              maxlength="30"
              v-model="password"
              @blur="$v.password.$touch()"
              password-reveal />
          </b-field>
          <b-field>
            <a class="password-remind-link has-text-dark is-pulled-right" :class="$v.email.$invalid?'isDisabled':''" @click="forgotPassword()">I forgot my password</a>
          </b-field>
          <b-button 
            class="is-fullwidth" 
            type="is-primary"
            :disabled="$v.email.$invalid || $v.password.$invalid" 
            @click="signIn()"
            @keyup.native.enter="enter" >
            Sign In
          </b-button>
          <b-button 
            class="is-fullwidth has-margin-top-10" 
            :disabled="$v.email.$invalid" 
            @click="signInAsGuest()" >
            Sign In As Guest
          </b-button>
        </form>
       
      </div>
    </div>
    <b-modal  :active.sync="showAbout" >
      <about />
    </b-modal>
  </div>
 

</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators'
import { auth } from '../../firebase'
import Toolbar from '../../components/Shared/toolbar'
import About from '../../components/Dialogs/About'

export default {
  data () {
    return {
      showAbout: false,
      showPassword: false,
      email: '',
      password: '',
      loading: false
    }
  },
  components: {
    'toolbar': Toolbar,
    'about': About
  },
  created () {
    this.$store.commit('user/errorMsg','')
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(6)
    }
  },
  computed: {
    userId () {
      return this.$store.getters['user/id']
    },
    emailErrors () {
      return this.$v.email.email ? '' : 'Not a valid email'
    },
    passwordErrors () {
      return this.$v.password.minLength ? '' : 'Password must be at least six characters'
    },
    error: {
      set (newValue) {
        if (!newValue) {
          this.$store.commit('user/errorMsg','')
        }
      },
      get () {
        return this.errorMsg.length > 0
      }
    },
    errorMsg () {
      return this.$store.getters['user/errorMsg']
    }
  },
  watch: {
    userId (value) {
      if (value !== null && value !== undefined) {
        this.$router.push('/')
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs['email'].focus()
    })
  
    

  },
  
  methods: {
    selectTab (tabName) {
      
      if (tabName === 'about') {
        this.showAbout = true
      }
      
      
    },
    signIn () {
     
      const credentials = { email: this.email, password: this.password }
   
      this.$store.dispatch('user/signIn', credentials )
    },
    signInAsGuest () {
      this.$store.dispatch('user/signInAsGuest', this.email )
    },
    async forgotPassword () {
      try {
        await auth.sendPasswordResetEmail(this.email)
        this.$buefy.toast.open('Password reset email sent!')
      }
      catch (error) {
        this.error = error.message
      }
    },
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body

      if (addRemoveClass === 'addClass') {
        el.classList.add(className)
      } else {
        el.classList.remove(className)
      }
    }
  }
}

</script>

<style>
.password-remind-link {
	margin-bottom: 10px;
}

.isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}
</style>
