// import { db, auth, fbFunc, fs } from '../firebase'
import { db, auth, fbFunc, fs} from '../firebase'

import Round from '../models/round'

import Group from '../models/group'
import Game from '../models/game'
import Course from '../models/course'
import User from '../models/user'
import Season from '../models/season'
import Player from '../models/player'
import Players from '../models/players'
import Rounds from '../models/rounds'
import Tournaments from '../models/tournaments'


import RoundController from '../controllers/round'

import dayjs from 'dayjs'

import {formatCurrency, sortArrayObj, gamesPayoutArray, roundTo} from '../lib'

import axios from 'axios'
import Score from '../models/score'

import Papa from 'papaparse'
import FileSaver from 'file-saver'


export default {
  onGroup (context, groupDocId) {
    let unsubscribe = function (){}
    try {
    
      context.commit('unsubscribeGroup')
      unsubscribe = db.collection('groups').doc(groupDocId)
        .onSnapshot( groupDoc => {
          
          context.commit('updateGroup', groupDoc)
          context.commit('user/updateGroupName', groupDoc.data().name, {root:true})
         
        })
      context.commit('subscribeGroup', unsubscribe)
    
    }
    catch (error) {
      context.commit('errorMsg', 'onGroup group.js - ' + error.code + ' ' + error.message)
    }
    
  },
  async createGroup (createInfo) { //user, group pased by ref so OK to update
    let response = {
      errorMsg: ''
    }
    try {
      const user = new User()
      const existingPlayer = new Player()
      if (createInfo.userDocId === '') {  // user doesn't exist
       
        user.name = createInfo.name

        const userAccess = await auth.createUserWithEmailAndPassword(createInfo.email, createInfo.password)
        user.docId = userAccess.user.uid

        await db.collection('users').doc(userAccess.user.uid).set(user.getData())
        
      }
      else {
        const userDoc = await db.collection('users').doc(createInfo.userDocId).get()
        user.docId = createInfo.userDocId
        user.setData(userDoc.data())

        const playerDoc = await db.collection('groups').doc(user.groupDocId).collection('players').doc(user.playerDocId).get()
        existingPlayer.docId = playerDoc.id
        existingPlayer.setData(playerDoc.data())
      }
     
      const group = new Group()
      group.name = createInfo.groupName
      group.courseDocId = createInfo.courseDocId
      group.startingTime = createInfo.startingTime
      group.interval = createInfo.interval
      group.holeByHoleScores = createInfo.holeByHoleScores
      group.betOnGames = createInfo.betOnGames
      const season = new Season()
      season.name = createInfo.seasonName
      season.fromDate = createInfo.seasonFromDate
      season.toDate = createInfo.seasonToDate
      group.seasons.add(season)

      const groupRef = await db.collection('groups').add(group.getData())
     
      group.docId = groupRef.id

      const player = new Player()
      if (existingPlayer.docId !== '') {
        player.name = existingPlayer.name
        player.email = existingPlayer.email
        player.tee = existingPlayer.tee
        player.ghinNum = existingPlayer.ghinNum
        player.gender = existingPlayer.gender
      }
      else {
        player.name = createInfo.name
        player.email = createInfo.email
      }
      player.admin = true
      player.registered = true
      player.type = 'Active'

      const playerRef = await groupRef.collection('players').add(player.getData())
      player.docId = playerRef.id

     
      user.groupDocId = group.docId
      user.playerDocId = player.docId
      user.groups.push({docId: group.docId})
      const update = {
        groupDocId: group.docId,
        playerDocId: player.docId,
        groups: user.groups
      }
      await db.collection('users').doc(user.docId).update(update)
     
    
      response.user = user
      response.group = group
      
    }
    catch(error) {
      response.errorMsg = 'createGroup group.js - ' + error.code + ' ' + error.message
    }
    return response
  },
  onPlayers (context, groupDocId) {
    let unsubscribe = function (){}
    try {
      context.commit('unsubscribePlayers')
      // context.commit('clearPlayers')
     
      const groupRef = db.collection('groups').doc(groupDocId)
  
      unsubscribe = groupRef.collection('players')
        .orderBy('name','asc')
        .onSnapshot( async snapshot => {
        
          if (context.state.players === null) context.commit('initPlayers')
      
          for (let change of snapshot.docChanges()) {
            if (change.type === 'added') {
              const player = context.state.players.aPlayers.find(r => r.docId === change.doc.id)
              if (player === undefined) {
                context.state.players.addDoc(change.doc.id, change.doc.data())
              }
              else {
                player.setData(change.doc.data())
              }
             

            }
            if (change.type === 'modified') {
              const player = context.state.players.aPlayers.find(r => r.docId === change.doc.id)
              if (player !== undefined) player.setData(change.doc.data())
 
            }
            if (change.type === 'removed') {
              if (context.state.players.exists(change.doc.id)) {
                context.state.players.deleteByDocId(change.doc.id)
              }
            }
          }

         
  
        })
      context.commit('subscribePlayers', unsubscribe)
    }
    catch (error) {
      context.commit('errorMsg', 'onPlayers group.js - ' + error.code + ' ' + error.message)
    }
   
    
  },
  onPlayer (context, subInfo) {
    let unsubscribe = function (){}
    try {
    
      context.commit('unsubscribePlayer')
      unsubscribe = db.collection('groups').doc(subInfo.groupDocId)
        .collection('players').doc(subInfo.playerDocId)
        .onSnapshot( playerDoc => {
     
          context.commit('updatePlayer', playerDoc.data())
         
        })
      context.commit('subscribePlayer', unsubscribe)
    
    }
    catch (error) {
      context.commit('errorMsg', 'onPlayer group.js - ' + error.code + ' ' + error.message)
    }
    
  },
  async getGroupAll (groupDocId) {
    const response = {
      errorMsg: ''
    }
    // try {
      if (groupDocId === '') {
        throw 'groupDocId must not be empty'
      }
      const groupRef = db.collection('groups').doc(groupDocId)
      const groupDoc =  await groupRef.get()

      const group = new Group()
      group.docId = groupDocId
      group.setData(groupDoc.data())

      if (group.courseDocId.length > 0) {
        const courseDoc = await db.collection('courses').doc(group.courseDocId).get()
        group.course = new Course()
        group.course.docId = courseDoc.id
        group.course.setData(courseDoc.data())
      }
   

      const players = new Players()
      const playerDocs = await groupRef.collection('players').get()

      if (playerDocs.size > 0) {
        for (let playerDoc of playerDocs.docs) {
          players.addDoc(playerDoc.id, playerDoc.data())
        }
      }

      const season = group.latestSeason
      
      const rounds = new Rounds()
      const roundDocs = await groupRef.collection('rounds')
        .where('date','>=',season.fromDateTS)
        .where('date','<=',season.toDateTS)
        .get()

      if (roundDocs.size > 0) {
        for (let roundDoc of roundDocs.docs) {
          rounds.addDoc(roundDoc.id, roundDoc.data())
        }
      }

      const tournaments = new Tournaments()
      const tournamentDocs = await groupRef.collection('tournaments')
        .where('startDate','>=',season.fromDateTS)
        .where('startDate','<=',season.toDateTS)
        .get()

      if (tournamentDocs.size > 0) {
        for (let tournamentDoc of tournamentDocs.docs) {
          tournaments.addDoc(tournamentDoc.id, tournamentDoc.data())
        }
      }
      
      response.group = group
      response.players = players
      response.rounds = rounds
      response.tournaments = tournaments
    // }
    // catch(error) {
    //   response.errorMsg = 'getGroupAll group.js - ' + error.code + ' ' + error.message
    // }
    return response
  },

  async getGroup (groupDocId) {
    const response = {
      errorMsg: ''
    }
    try {
      if (groupDocId === '') {
        throw 'groupDocId must not be empty'
      }
      const groupRef = db.collection('groups').doc(groupDocId)
      const groupDoc =  await groupRef.get()

      const group = new Group()
      group.docId = groupDocId
      group.setData(groupDoc.data())


      const courseDoc = await db.collection('courses').doc(group.courseDocId).get()
      group.course = new Course()
      group.course.docId = courseDoc.id
      group.course.setData(courseDoc.data())

   

      response.group = group
    }
    catch(error) {
      response.errorMsg = 'getGroup group.js - ' + error.code + ' ' + error.message
    }
    return response
  },

  async getGroupName (groupDocId) {
    const response = {
      errorMsg: '',
      groupName: ''
    }
    try {
      if (groupDocId === '') {
        throw 'groupDocId must not be empty'
      }
      const groupRef = db.collection('groups').doc(groupDocId)
      const groupDoc =  await groupRef.get()
  
      response.groupName = groupDoc.data().name
    }
    catch (error) {
      response.errorMsg = 'getGroupName group.js - ' + error.code + ' ' + error.message
    }
    return response
  },
  async saveGroup (group) { 
    let response = {
      errorMsg: ''
    }
    try {

      let groupRef
      if(group.docId === '') {
        groupRef = await db.collection('groups').add(group.getData())
        group.docId = groupRef.id
      }
      else {
        groupRef = db.collection('groups').doc(group.docId).update(group.getData())
      }
      
    }
    catch(error) {
      response.errorMsg = 'saveGrouo group.js - ' + error.code + ' ' + error.message
    }
    return response
  },
  async updateGroupValue (updateInfo) {
    const response = {
      errorMsg: ''
    }
    try {

      const groupRef = db.collection('groups').doc(updateInfo.docId)

      await groupRef.update(updateInfo.value)

    }
    catch(error) {
      response.errorMsg = 'uodateGroupValue group.js - ' + error.message
    }
    return response
  },
  async updateGroupValues (groupUpdate) {
    const response = {
      errorMsg: ''
    }
    try {
      const groupsRef = db.collection('groups')

      await groupsRef.doc(groupUpdate.docId).update(groupUpdate.update)
      
    }
    catch(error) {
      response.errorMsg = 'updateGroupValues group.js - ' + error.message
    }
    return response
  },
  async addPlayer (groupDocId, player) {
    const response = {
      errorMsg: ''
    }
    try {

      const groupRef = db.collection('groups').doc(groupDocId)

      const userDocs = await db.collection('users').where('email','==',player.email).get()
      player.registered = (userDocs.docs.length > 0)
    
      const playerDoc = await groupRef.collection('players').add(player.getData())

      player.docId = playerDoc.id

    }
    catch(error) {
      response.errorMsg = 'addPlayer group.js - ' + error.message
    }
    return response
  },
  async deletePlayer (groupDocId, playerDocId) {
    const response = {
      errorMsg: ''
    }
    try {

      const groupRef = db.collection('groups').doc(groupDocId)
  
      await groupRef.collection('players').doc(playerDocId).delete()

    }
    catch(error) {
      response.errorMsg = 'deletePlayer group.js - ' + error.message
    }
    return response
  },
  async getPlayers (groupDocId, field, value) {
    const response = {
      errorMsg: '',
      players: new Players()
    }
    try {

      const groupRef = db.collection('groups').doc(groupDocId)
  
      const playerDocs = await groupRef.collection('players').where(field, '==', value).get()

      if (playerDocs.size > 0) {
      
        for (let playerDoc of playerDocs.docs) {
          response.players.addDoc(playerDoc.id, playerDoc.data())
        }

      }

    }
    catch(error) {
      response.errorMsg = 'getPlayers group.js - ' + error.message
    }
    return response
  },

  async getAllPlayers (groupDocId) {
    const response = {
      errorMsg: '',
      players: new Players()
    }
    try {

      const groupRef = db.collection('groups').doc(groupDocId)
  
      const playerDocs = await groupRef.collection('players').get()

      if (playerDocs.size > 0) {
      
        for (let playerDoc of playerDocs.docs) {
          response.players.addDoc(playerDoc.id, playerDoc.data())
        }

      }

    }
    catch(error) {
      response.errorMsg = 'getAllPlayers group.js - ' + error.message
    }
    return response
  },

  async getAllRounds (groupDocId) {
    const response = {
      errorMsg: '',
      rounds: new Rounds()
    }
    try {

      const roundDate = dayjs('2024/2/24')
      let roundDateTS = fs.Timestamp.fromDate(roundDate.toDate())
      
      const groupRef = db.collection('groups').doc(groupDocId)
  
      // const roundDocs = await groupRef.collection('rounds').where('date', '>=', roundDateTS).get()
      const roundDocs = await groupRef.collection('rounds').orderBy('date').get()

      if (roundDocs.size > 0) {
      
        for (let roundDoc of roundDocs.docs) {
          response.rounds.addDoc(roundDoc.id, roundDoc.data())
        }

      }

    }
    catch(error) {
      response.errorMsg = 'getAllRounds group.js - ' + error.message
    }
    return response
  },

  async hasGuestRounds (groupDocId, player) {
    const response = {
      errorMsg: '',
      hasGuestRounds: false
    }
    try {
      const groupRef = db.collection('groups').doc(groupDocId)

      let scoreDocs = null

      // try email first

      if (player.email.length > 0) {
        scoreDocs = await groupRef.collection('scores')
          .where('email', '==', player.email)
          .where('type', '==', 'Guest')
          .get()
      }

      // try by name
      if (scoreDocs === null || scoreDocs.size === 0) {

        scoreDocs = await groupRef.collection('scores')
          .where('name', '==', player.name)
          .where('type', '==', 'Guest')
          .get()

      }

      response.hasGuestRounds = (scoreDocs.size > 0) 

    }
    catch(error) {
      response.errorMsg = 'hasGuestRounds group.js - ' + error.message
    }
    return response
  },
  async linkPlayer (groupDocId, player) {
    const response = {
      errorMsg: '',
      numScores: 0
    }
    try {
      const groupRef = db.collection('groups').doc(groupDocId)

      let scoreDocs = null

      // try email first

      if (player.email.length > 0) {
        scoreDocs = await groupRef.collection('scores')
          .where('email', '==', player.email)
          .where('type', '==', 'Guest')
          .get()
      }

      // try by name
      if (scoreDocs === null || scoreDocs.size === 0) {

        scoreDocs = await groupRef.collection('scores')
          .where('name', '==', player.name)
          .where('type', '==', 'Guest')
          .get()

      }

      if (scoreDocs.size > 0) {
        response.numScores = scoreDocs.size
        await db.runTransaction(async tx => {
          const updates = []
          for (let scoreDoc of scoreDocs.docs) {
          
            const scoreRef = groupRef.collection('scores').doc(scoreDoc.id)
            const update = {
              playerDocId: player.docId,
              name: player.name,
              email: player.email,
              type: player.type,
              ghinNum: player.ghinNum
            }
            updates.push(tx.update(scoreRef, update))
          }
          await Promise.all(updates)   
        })
      }
       
     

    }
    catch(error) {
      response.errorMsg = 'linkPlayer group.js - ' + error.message
    }
    return response
  },
  async updatePlayer (groupDocId, player) {
    const response = {
      errorMsg: ''
    }
    try {

      await db.collection('groups')
        .doc(groupDocId)
        .collection('players')
        .doc(player.docId)
        .update(player.getData())

    }
    catch(error) {
      response.errorMsg = 'updatePlayer group.js - ' + error.message
    }
    return response
  },
  async updatePlayerValues (groupDocId, playerUpdates) {
    const response = {
      errorMsg: ''
    }
    try {
 
      const groupRef = db.collection('groups').doc(groupDocId)

      if (playerUpdates.size === 1) {
        const playerUpdate = playerUpdates.aUpdates[0]
        await groupRef
          .collection('players')
          .doc(playerUpdate.docId)
          .update(playerUpdate.update)
       
      }
      else {
        await db.runTransaction(async tx => {
          const updates = []
          playerUpdates.aUpdates.forEach(playerUpdate => {
            const playerRef = groupRef.doc(playerUpdate.docId)
            updates.push(tx.update(playerRef, playerUpdate.update))
          })
          await Promise.all(updates)
        })
      }

      
      

    }
    catch(error) {
      response.errorMsg = 'updatePlayerValues group.js - ' + error.message
    }
    return response
  },

  async deleteGroup (groupDocId) {
    const response = {
      errorMsg: ''
    }
    try {


        await db.collection('groups')
          .doc(groupDocId)
          .delete()
      
    }
    catch(error) {
      response.errorMsg = 'deleteGroup group.js - ' + error.message
    }
    return response
  },

  async deleteSeason (groupDocId, season) {
    const response = {
      errorMsg: ''
    }
    try {

      const groupDoc = await db.collection('groups').doc(groupDocId).get()

      const seasons = groupDoc.data().seasons
      const seasonIndex = seasons.findIndex(s=>s.name=season.name)
      if (seasonIndex >= 0) seasons.splice(seasonIndex, 1)

      const roundDocs = await db.collection('groups')
        .doc(groupDocId)
        .collection('rounds')
        .where('date', '>=', season.fromDateTS)
        .where('date', '<=', season.toDateTS)
        .get()

        for (let roundDoc of roundDocs.docs) {
          await db.runTransaction( async tx => {
      
            const scoreDocs = await db.collection('groups')
              .doc(groupDocId)
              .collection('scores')
              .where('roundDocId','==',roundDoc.id)
              .get()
              
            for (let scoreDoc of scoreDocs.docs) {
          
              await tx.delete(scoreDoc.ref)
            }
            await tx.delete(roundDoc.ref)
          })
        }
        await db.collection('groups').doc(groupDocId).update({seasons: seasons})

     
    }
    catch(error) {
      response.errorMsg = 'deleteSeason group.js - ' + error.message
    }
    return response
  },

  async handleRoundInvite(inviteInfo) {

    const response = {
      errorMsg: '',
      signedUpBefore: false,
      scoreDocId: ''
    }
    try {
      const groupRef = db.collection('groups').doc(inviteInfo.groupDocId)

      const groupDoc = await groupRef.get()

      // if (!groupDoc.exists) {
      //   throw new Error('Unable to find group')
      // }

      const group = new Group()
      group.docId = inviteInfo.groupDocId
      group.setData(groupDoc.data())

      const roundRef = groupRef.collection('rounds').doc(inviteInfo.roundDocId)

      const roundDoc = await roundRef.get()

      if (!roundDoc.exists) {
        throw new Error('Unable to find round')
      }

      const round = new Round()
      round.docId = inviteInfo.roundDocId
      round.groupDocId = inviteInfo.groupDocId
      round.setData(roundDoc.data())

      if ( (dayjs(round.date).isBefore(dayjs()) || !round.isScheduled) && inviteInfo.status!==3) {
        throw new Error('Expired round date')
      }
      if (round.closeSignups) {
        response.errorMsg = 'This round has been close to signups'
      }
      else {
   
        if (inviteInfo.status === 1 || inviteInfo.status === 2) { // accepted need tee time
          const playerDoc = await groupRef.collection('players').doc(inviteInfo.playerDocId).get()
        
          if (!playerDoc.exists) throw new Error('Player not found')
          const player = new Player()
          player.setData(playerDoc.data())
          player.docId = playerDoc.id
          
          let teeTimeRequest = ''
          // if (inviteInfo.status === 2) teeTimeRequest = 'I do not need a tee time'
          const response2 = await RoundController.signUp (round, player)

          if (response2.errorMsg.length > 0) throw new Error(response2.errorMsg)

          response.scoreDocId = response2.scoreDocId

          if (response2.signedUpBefore) {
            response.signedUpBefore = true
          }
      
          
        }
      
        const scoreDocs = await groupRef.collection('scores').where('roundDocId','==',round.docId).get()
        const playerList = []
        for (let scoreDoc of scoreDocs.docs) {
          playerList.push(scoreDoc.data().name)
          if (inviteInfo.playerDocId === scoreDoc.data().playerDocId) response.scoreDocId = scoreDoc.id
        }
      
      
        response.group = group
        response.round = round
        response.playerList = playerList
      }
    }
    catch (error) {
      response.errorMsg = 'handleRoundInvite group.js - ' + error.message
    }

    return response
  },

  async deleteAnonymousUsers () {
    const response = {
      errorMsg: ''
    }
   
    const deleteFunc = fbFunc.httpsCallable('deleteAnonymousUsers')

    
    await deleteFunc()

    return response
   
  },

  

  async sendRegisterInvite (group, email, fromEmail) {
    // const sendEmail = fbFunc.httpsCallable('sendEmail')
    const sendEmail = fbFunc.httpsCallable('sendMJEmail')
  
    const url = `${process.env.VUE_APP_BASE_URL}/user/register?group=${group.docId}&email=${email.Email}`
   
    // const emailInfo = {
    //   template: 'register',
    //   to: email,
    //   from: fromEmail,
    //   cc: '',
    //   groupName: group.name,
    //   acceptUrl: encodeURI(url)
    // }

    const emailInfo = {
      templateId: 1431266,
      to: [email],
      // from: fromEmail,
      replyTo: fromEmail,
      subject: 'Register for girlap!',
      cc: [],
      data: {
        groupName: group.name,
        acceptUrl: encodeURI(url)
      }
    
    }

    //  emailInfo.to = 'kjelston@gmail.com'

    await sendEmail([emailInfo])
  
   
  },

  async sendRoundResults (groupName, roundInfo) {
    // const sendEmail = fbFunc.httpsCallable('sendEmail')
    const sendEmail = fbFunc.httpsCallable('sendMJEmail')
    
    const winners = roundInfo.payOuts.map(p=>{
      return {
        name: p.name,
        totalPayout: formatCurrency(p.totalPayout),
        details: gamesPayoutArray(roundInfo.round.games, p)
      }
     
    })

    let toArray = typeof roundInfo.emailInfo.to === 'string' ? roundInfo.emailInfo.to.split(',') : roundInfo.emailInfo.to
    toArray = toArray.filter(email => email!=='').map(email=>({Email:email}))

  
    // const emailInfo = {
    //   template: 'roundWinners',
    //   to: toArray,
    //   from: roundInfo.emailInfo.from,
    //   cc: '',
    //   groupName: groupName,
    //   title: `${groupName} Results for ${roundInfo.round.formattedDateLabel}`,
    //   header: `${groupName} Results for ${roundInfo.round.formattedDateLabel}`,
    //   subheader: `${roundInfo.round.course.name} - ${roundInfo.round.numPlayers} Players`,
    //   msg: roundInfo.emailInfo.msg,
    //   winners: winners

    // }

    const emailInfo = {
      templateId: 1430525,
      to: toArray,
      // from: roundInfo.emailInfo.from,
      replyTo: roundInfo.emailInfo.from,
      cc: [],
      groupName: groupName,
      subject: `${groupName} Results for ${roundInfo.round.formattedDateLabel}`,
      data: {
        header: `${groupName} Results for ${roundInfo.round.formattedDateLabel}`,
        subheader: `${roundInfo.round.course.name} - ${roundInfo.round.numPlayers} Players`,
        msg: roundInfo.emailInfo.msg,
        winners: winners
      }
      

    }

    await sendEmail([emailInfo])
  
   
  },

  async getPostedScoresBySeason (group, season) {

    const response = {
      errorMsg: ''
    }
    try {

      const scoreDocs = await db.collection('groups')
        .doc(group.docId)
        .collection('scores')
        .where('roundDate', '>=', season.fromDateTS)
        .where('roundDate', '<=', season.toDateTS)
        .where('posted', '==', true)
        .get()

      const scores = []
      for (let scoreDoc of scoreDocs.docs) {
        const score = new Score()
        score.docId = scoreDoc.id
        score.setData(scoreDoc.data())
        scores.push(score)
      }
      response.scores = scores
    }
    catch(error) {
      response.errorMsg = 'getPostedScoresBySeason group.js - ' + error.message
    }
    return response
  },

 
  
  
  async updateAllRounds(group) { 
    let response = {
      errorMsg: ''
    }
  
    try {
 
     
      const bandonGroupRef = db.collection('groups').doc('a7fNlvMi5YXT3PTdcA8B')

      // const bandonGroup = new Group()
      // bandonGroup.setData(bandonGroupDoc.data())
      // bandonGroup.docId = bandonGroupDoc.id

      // bandonGroup.games = group.games

      await bandonGroupRef.update({games: group.games.getData()})

      const bandonRoundDocs = await bandonGroupRef.collection('rounds').get()

      for (let bandonRoundDoc of bandonRoundDocs.docs) {
        bandonRoundDoc.ref.update({games: group.games.getData()})
      }

      // this.sendRegisterInvite (group, {Email:'kjelston@gmail.com'}, {Email:'kjelston@gmail.com'})

      // const roundDocId = 'v4HqBgXa4G77UTuPiY2m'
      // const roundDoc = await groupRef.collection('rounds').doc(roundDocId).get()
      
      // const round = new Round()
      // round.docId = roundDocId
      // round.setData(roundDoc.data())

      // await this.sendRoundInvites (group, round, {Email:'kjelston@gmail.com'})

      // const groupDoc = await groupRef.get()
  
      // const grp = new Group()

      // grp.setData(groupDoc.data())

 

      // await db.collection('groups').doc(group.docId).update(group.getData())

      // const docs = await groupRef.collection('scores').where('season','==','2019').get()
      // const scoreDocs = await groupRef.collection('scores').get()
      // for (let scoreDoc of scoreDocs.docs) {

      //   const scoreRef = groupRef.collection('scores').doc(scoreDoc.id)
      //   scoreRef.update({
      //     pairingSlot: fs.FieldValue.delete(),
      //     pairing: {teeTime:'', slot: 0}
      //   })
       
      // }

      // const scoreDocs = await groupRef.collection('scores')
      //   .where('posted', '==', true)
      //   .orderBy('roundDate','desc')
      //   .where('roundDate', '<=', new Date('2020/1/1'))
      //   // .limit(1)
      //   .get()

      // console.log('scores found ' + scoreDocs.size)

      // let roundDocId = ''
      // let scoreGames = []
      // let roundDoc
      // let noPayout

      // for (let scoreDoc of scoreDocs.docs) {
      //   console.log(scoreDoc.data().name, scoreDoc.data().roundDate.toDate());
      //   noPayout = false
      //   if (isNaN(scoreDoc.data().totalPayout)) {
      //     noPayout = true
      //     console.log('no payout')
      //   }
     

      //   if (noPayout) {
      
      //     // if (roundDocId !== scoreDoc.data().roundDocId) {
      //     //   roundDocId = scoreDoc.data().roundDocId
      //     //   roundDoc = await groupRef.collection('rounds')
      //     //     .doc(roundDocId)
      //     //     .get()
  
      //     // }

      //     // scoreGames = roundDoc.data().games.map( g=>{
      //     //   const game = new Game()
      //     //   game.setData(g)
      //     //   return {
      //     //     id: game.id,
      //     //     name: game.name,
      //     //     side: game.side,
      //     //     prop: game.prop,
      //     //     handicapFac: game.handicapFac
      //     //   }
      //     // })

      //     let totalPayout = 0
      //     for(let key in scoreDoc.data().payOut) {
      //       totalPayout += scoreDoc.data().payOut[key]
      //     }
      //     totalPayout = roundTo(totalPayout,0)
        
      //     scoreDoc.ref.update({totalPayout: totalPayout})
      //   }
        
      // }
      
     
     
      // for (let doc of docs.docs) { 
       
      // const handicapData = group.handicap.getData()
      // const roundDocs = await groupRef.collection('rounds')
      //   // .where('posted','==',true)
      //   // .limit(4)
      //   .orderBy('date','desc')
      //   .get()

   

      // for (let roundDoc of roundDocs.docs) {
      //   console.log('Round', roundDoc.data().date.toDate())
      //   const games = roundDoc.data().games

      //   let saveGames = false
      //   for(let game of games) {
      //     if (game.scoreCount !== undefined) {
      //       if (typeof game.scoreCount === 'string') {
      //         game.scoreCount = parseInt(game.scoreCount)
      //         saveGames = true
      //       }
      //     }
      //   }
      //   if (saveGames) {
      //     console.log('game ${game.prop} updated')
      //     await roundDoc.ref.update({games: games})
      //   }
      // }

   
      

      // const roundDocs = await groupRef.collection('rounds')
      //   .orderBy('date','desc')
      //   .limit(2)
      //   .get()
     

      // for (let roundDoc of roundDocs.docs) {

      //   const round = new Round()
      //   round.setData(round.data())
      //   round.docId = roundDoc.id

      
      //   const scoreDocs = await groupRef.collection('scores').where('roundDocId','==',roundDoc.id).get()
       
      //   for (let scoreDoc of scoreDocs.docs) {
      //     scoreRef = groupRef.collection('scores').doc(scoreDoc.id)

      //     const handicapHoles = round.handicapHoles(scoreDoc.data().tee)
      
      //     await scoreRef.update({handicapHoles})

        
      //   }


       
      // }


      //  console.log('update', doc.id)
        // const updates = {}
        // updates['handicap.lookupGhinIndex'] = fs.FieldValue.delete()
      
     //   let deleteVal = await groupRef.collection('rounds').doc(roundDoc.id).update({['handicap.lookupGhinIndex']: fs.FieldValue.delete()})
     //   console.log(deleteVal)
      // }

      // const addField = {
      //   inviteRespondByDate: fs.Timestamp.fromDate(new Date())
      // }
      // console.log('update started')
      
      // const scoreDocs = await groupRef.collection('scores').orderBy('roundDate','desc').get()

      // for (let scoreDoc of scoreDocs.docs) {
      //   console.log('update score', scoreDoc.id)
      //   const scoreRef = groupRef.collection('scores').doc(scoreDoc.id)
       
      //   await scoreRef.update({roundName: 'Ish Game'})

       
      // }
    
     

      // const courseDocs = await db.collection('courses').get()

      // for (let courseDoc of courseDocs.docs) {
      //   console.log('update course', courseDoc.data().name)
      //   const course = new Course()
      //   course.setData(courseDoc.data())

      //   for (let tee of course.tees.tees) {
      //     tee.parHoles = tee.par
      //   }
      //   console.log('course updated', course.getData())
      //   await db.collection('courses').doc(courseDoc.id).update(course.getData())
        
      // }

      // const roundDocs = await groupRef.collection('rounds').where('date', '<=', fs.Timestamp.fromDate(new Date('2019/03/02'))).orderBy('date','desc').get()

      // for (let roundDoc of roundDocs.docs) {
      //   const round = new Round()
      //   round.setData(roundDoc.data())
        
      //   for (let tee of round.course.tees.tees) {
      //     tee.parHoles = tee.par

      //     const teeRating = new TeeRating()
      //     teeRating.shortName = tee.shortName
      //     teeRating.gender = tee.gender
      //     teeRating.rating = tee.rating
      //     teeRating.slope = tee.slope

      //     round.course.teeRatings.add(teeRating)
      //   }

      //   await groupRef.collection('rounds').doc(roundDoc.id).update({course: round.course.getData()})
        
      // }

     

      // const roundDocs = await groupRef.collection('rounds')
      //   .where('status','==','Scheduled')
      //   .orderBy('date','desc')
      //   .get()

      // console.log('roundDocs', roundDocs.size)
      // for (let roundDoc of roundDocs.docs) {

      //   console.log('Round', roundDoc.data().date.toDate())

      //   const scoreDocs = await groupRef.collection('scores').where('roundDocId','==',roundDoc.id).get()
      //   console.log('scores', scoreDocs.size)
      
        
      //   for (let scoreDoc of scoreDocs.docs) {
      //     const update = {
      //       skinsCount: 0,
      //       kpCount: 0,
      //       scores: [],
      //       missingHoles: fs.FieldValue.delete(),
      //       roundNum: fs.FieldValue.delete(),
      //       parCompleted: fs.FieldValue.delete(),
      //       handicapCompleted: fs.FieldValue.delete(),
      //       holesCompleted: fs.FieldValue.delete(),
      //       holeScores: fs.FieldValue.delete(),
      //       pairing: fs.FieldValue.delete()
      //     }
        
      //     await groupRef.collection('scores').doc(scoreDoc.id).update(update)
      //   }
     
      // }
      // const courseDocs = await db.collection('courses').get()
      // for (let courseDoc of courseDocs.docs) {
      //   console.log('update course', courseDoc.data().name)
      //   await db.collection('courses').doc(courseDoc.id).update({gender: fs.FieldValue.delete()})
      //   const tees = new Tees()
      //   tees.setData(courseDoc.data().tees)
      //   for (let tee of tees.tees) {
      //     tee.gender= 'M'
      //   }
      //   await db.collection('courses').doc(courseDoc.id).update({tees: tees.getData()})
 
      // }

      // const roundDocs = await groupRef.collection('rounds').orderBy('date','desc').get()
      // for (let roundDoc of roundDocs.docs) {
      //   console.log('update round', roundDoc.data().date.toDate())
      
   
      // }


      // const scoreDocs = await groupRef.collection('scores').orderBy('roundDate','desc').get()
      // for (let scoreDoc of scoreDocs.docs) {
      //   console.log('update score', scoreDoc.data().name, scoreDoc.data().roundDate.toDate())
      //   const update = {
      //     final: scoreDoc.data().posted
      //   }
      //   await groupRef.collection('scores').doc(scoreDoc.id).update(update)
      // }

      // const userDocs = await db.collection('users').get()
      // for (let userDoc of userDocs.docs) {
      //   console.log('user', userDoc.data().name)
      //   const playerDocs = await groupRef.collection('players').where('name','==',userDoc.data().name).get()
      //   if (playerDocs.size > 0) {
      //     const playerDoc = playerDocs.docs[0]
      //     const update = {
      //       email: playerDoc.data().email
      //     }
      //     await db.collection('users').doc(userDoc.id).update(update)
      //   }
      //   else {
      //     console.log('player not found', userDoc.data().name)
      //   }
      
        
      // }
 


      // const roundDocs = await groupRef.collection('rounds')
      //   .where('status','==','Posted')
      //   .where('processed','==',false)
      //   .limit(10)
      //   .orderBy('date','desc')
      //   .get()

      // console.log('roundDocs', roundDocs.size)
      // for (let roundDoc of roundDocs.docs) {

      //   console.log('Round', roundDoc.data().date.toDate())

      //   const scoreDocs = await groupRef.collection('scores').where('roundDocId','==',roundDoc.id).get()
      //   console.log('scores', scoreDocs.size)
      //   let totalSkins = 0
      //   let totalKp = 0
      //   let trashAmount = 0
      //   let totalSkinsCount = 0
      //   let totalKpCount = 0
      //   for (let scoreDoc of scoreDocs.docs) {
          
      //     if ('kp' in scoreDoc.data().payOut) {
      //       if (scoreDoc.data().payOut.kp > 0) {
      //         totalKp += scoreDoc.data().payOut.kp
      //         if (trashAmount === 0) trashAmount = scoreDoc.data().payOut.kp
      //         if (scoreDoc.data().payOut.kp < trashAmount) trashAmount = scoreDoc.data().payOut.kp
      //       }
           
      //     }
      //     if ('skins' in scoreDoc.data().payOut) {
      //       if ( scoreDoc.data().payOut.skins > 0) {
      //         totalSkins += scoreDoc.data().payOut.skins
      //         if (trashAmount === 0) trashAmount = scoreDoc.data().payOut.skins
      //         if (scoreDoc.data().payOut.skins < trashAmount) trashAmount = scoreDoc.data().payOut.skins
      //       }
        
      //     }
      //   }
      //   console.log('trash', totalSkins, totalKp, trashAmount)
      //   for (let scoreDoc of scoreDocs.docs) {
      //     const update = {
      //       skinsCount: 0,
      //       kpCount: 0,
      //       missingHoles: fs.FieldValue.delete(),
      //       roundNum: fs.FieldValue.delete(),
      //       parCompleted: fs.FieldValue.delete(),
      //       handicapCompleted: fs.FieldValue.delete(),
      //       holesCompleted: fs.FieldValue.delete(),
      //       holeScores: fs.FieldValue.delete(),
      //       pairing: fs.FieldValue.delete()
      //     }
      //     if ('skins' in scoreDoc.data().payOut) {
      //       update.skinsCount = roundTo(scoreDoc.data().payOut.skins / trashAmount,0)
      //       totalSkinsCount += update.skinsCount
      //     }
      //     if ('kp' in scoreDoc.data().payOut) {
      //       update.kpCount = roundTo(scoreDoc.data().payOut.kp / trashAmount,0)
      //       totalKpCount += update.kpCount
      //     }
          
      //     await groupRef.collection('scores').doc(scoreDoc.id).update(update)
      //   }
      //   if (roundTo(totalSkinsCount * trashAmount,2) !== roundTo(totalSkins,2)) console.log('skins mismatch', totalSkinsCount*trashAmount, totalSkins)
      //   if (roundTo(totalKpCount * trashAmount,2) !== roundTo(totalKp,2)) console.log('kp mismatch', totalKpCount*trashAmount, totalKp)
  
      //   await groupRef.collection('rounds').doc(roundDoc.id).update({processed: true})
      // }
      
     

      // const scoreDocs = await groupRef.collection('scores').get()
      // for (let scoreDoc of scoreDocs.docs) {
      //   console.log('update score', scoreDoc.data().name, scoreDoc.data().roundDate.toDate())

      //   const update = {
      //     skinsCount,
      //     kpCount
      //   }

      //   await groupRef.collection('scores').doc(scoreDoc.id).update(update)
      // }


      console.log('all done')
    }
    catch(error) {
      console.log(error.code + ' ' + error.message)
    }
    return response
  },

  async exportScores (groupRef) {
    const cutoffDate = fs.Timestamp.fromDate(new Date('1/1/2021'))

    // const playerDocId = 'gMFynXpc9wXSzbzGEFTQ' //Robby

    const scoreDocs = await groupRef.collection('scores')
    .where('roundDate','>=',cutoffDate)
    .where('posted', '==', true)
    .orderBy('roundDate','desc').get()
    // const scoreDocs = await groupRef.collection('scores')
    //   .where('playerDocId','==',playerDocId)
    //   .where('posted', '==', true)
    //   .orderBy('roundDate','desc').get()

      console.log('num scores', scoreDocs.size)
    const report = []
    for (let scoreDoc of scoreDocs.docs) {
   
      const score = new Score()
      score.docId = scoreDoc.id
      score.setData(scoreDoc.data())

      console.log('round date', score.formattedDate)

      if (!score.excluded) {
        const row = []
        row.push(score.formattedDate)
        row.push(score.name)
        row.push(score.index===null ? 'n/a' : score.index.full18)
        row.push(score.tee)
        row.push(score.handicap===null ? 'n/a' : score.handicap.full18)
        row.push(score.diff.full18)
        row.push(score.pcc)
        row.push(score.score.full18)
        // report.push(score.toPar.full18)

        sortArrayObj(score.scores, 'holeNum')
        for (let hole of score.scores) {
          row.push(hole.score)
        }

        report.push(row)
      }
     
    }
    const headers = [
      'Round Date',
      'Name',
      'Index',
      'Tee',
      'Handicap',
      'Diff',
      'PCC',
      'Score'
      // 'To Par'
    ]

    for (let i=1; i<=18; i++) {
      headers.push('Hole ' + i)
    }
    
    const csv = Papa.unparse({
      fields: headers,
      data: report
    })

    const blob = new Blob([csv], {type: "text/plain;charset=utf-8"})
    // FileSaver.saveAs(blob, 'Ish Game Hole Scores.csv')
    FileSaver.saveAs(blob, 'Buffington Hole Scores.csv')
  },


  async ghinScoresLookup() {
    const ghinUrl = 'https://api2.ghin.com/api/v1/golfers/5250300/scores.json'
    const token = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIyIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNTc4NDMzNzQ3LCJleHAiOjE1Nzg0NzY5NDcsImp0aSI6IjhlNTU0MDI0LWU1NjYtNDA1NS05YzJmLWRiOTYyZjc3MGQ3OCJ9.WMHQjLk7Ik1QCLgFHjvsoUd1pjj5pmVvzS9UkOVAV50'
    
    const headers = {
      Authorization: "Bearer " + token,
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json"
    }
    
    const ret = { errorMsg: ''}
      try {
        
        // if (playerInfo.ghinNum !== '') {
        //   options.params.ghinno = playerInfo.ghinNum
        // }
        // else {
        //   if (playerInfo.state === '' || playerInfo.state === undefined) {
        //     throw new Error('Provide a GHIN number or State to get GHIN scores')
        //   }
        //   options.params.ln = playerInfo.lastName
        //   options.params.fn = playerInfo.firstName
        //   options.params.st = playerInfo.state
        // }
        
        let scoresArray = []
     
        const response = await axios.get(ghinUrl, {headers})
   
        // // get Club name
        //   const clubName = $('#'+clubId).text()
        // // parse html table of recent scores and return an array of arrays, one for each column
        //   let scoresData = $('#'+recentScoresId).parsetable(true, true, true)
      
          
        //   if (scoresData.length > 0) {
        //     for(let i=1; i<scoresData[0].length; i++) {
        //       scoresArray.push({
        //         formattedDate: scoresData[1][i],
        //         score: Number(scoresData[2][i]),
        //         diff: Number(scoresData[4][i])
        //       })
        //     }
        //   }
        //   // console.log('scoresArray', scoresArray)
        //   ret.clubName = clubName
        //   ret.scoresArray = scoresArray
        //   if (scoresArray.length === 0) {
        //     ret.errorMsg = "Player not found in GHIN"
        //   }
      }
      catch (error) {
        ret.errorMsg = error.message
    console.log('error', error.message)
      }
    
      return ret
    
    }


  
}
