import UserPostedScore from './userPostedScore'
import { sortArrayObj } from '../lib'

export default class UserPostedScores {
  constructor() {
    this.initialize()
  }
  initialize () {
    this.aScores = []
    this.sortField = 'roundDate'
    this.sortDir = 'desc'
  }
  addDoc (docId, score) {
    const userScore = new UserPostedScore()
    userScore.docId = docId
    userScore.setFromScore(score)
    
    this.aScores.push(userScore)

    return userScore
  }
  add (userScore) {
    this.aScores.push(userScore)
  }

  exists (docId) {
    const index = this.aScores.findIndex(o => o.docId === docId)
    return index >= 0
  }

  existsRound (roundDocId) {
    const index = this.aScores.findIndex(o => o.roundDocId === roundDocId)
    return index >= 0
  }

  clear () {
    this.aScores = []
  }
  deleteByDocId (docId) {
    const index = this.aScores.findIndex(o => o.docId === docId)   
    this.aScores.splice(index, 1)
  }


  deleteByRoundDocId (roundDocId) {
    const index = this.aScores.findIndex(o => o.roundDocId === roundDocId)   
    this.aScores.splice(index, 1)
  }
 
  get size () {
    return this.aScores.length
  }

  sort (field='', dir='') {
    if (field === '') field = this.sortField
    if (dir === '') dir = this.sortDir
    sortArrayObj (this.aScores, field, dir)
    this.sortField = field
    this.sortDir = dir
  }
  
  toJSON () {
    return JSON.stringify(this.getObj())
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  getObj () {
    return {
      scores: this.aScores.map(score => score.getObj())
    }
  }
  setObj (data) {
    this.aScores = data.scores.map(scoreObj => {
      const score = new UserPostedScore()
      score.setObj(scoreObj)
      return score
    })
  }
  
}

// module.exports = { UserPostedScores }
