import IndexAvgTable from "./indexAvgTable"
// const { IndexAvgTable } = require("./indexAvgTable")

export default class Handicap {
  constructor(data) {
    if(data) {
      this.setObj(data)
    }
    else {
      this.initialize()
    }
  }
  
  initialize () {
    this.useGameIndex = false
    this.lookupGhinIndex = true
   
    this.maxHandicap = 36
    this.method = {
 
      groupIndexTable: new IndexAvgTable(true),

      groupIndexAvg: "ghin",

      limitScores: true,
      limitScoresMonths: 12,

      // minScores: false,
      // numMinScores: 0,
      // numMinScoresMonths: 4,

      noGroupScoresGhin: false,
      noGroupScoresGhinFac: 1,

      // lowestGhinGroup: true,
      // useGhinIndexScores: 20,
      // useGhinIndexTable: true

      guestGhinFac: 1,
    }
    
  }
 
  copy () {
    const handicap = new Handicap()
    handicap.setObj(this.getObj())
    return handicap
  }
  toJSON () {
    return JSON.stringify(this.getObj())
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  getObj () {
    return this.getData()
  }
  setObj (data) {
    this.setData(data)
  }
  getData () {
    const data = {
      useGameIndex: this.useGameIndex,
      lookupGhinIndex: this.lookupGhinIndex
    }
    if(this.useGameIndex) {

      data.maxHandicap = this.maxHandicap
     
        data.method = {
       
          groupIndexTable: this.method.groupIndexTable.getData(),

          groupIndexAvg: this.method.groupIndexAvg,

          // minScores: this.method.minScores,
          // numMinScores: this.method.numMinScores,
          // numMinScoresMonths: this.method.numMinScoresMonths,

          limitScores: this.method.limitScores,
          limitScoresMonths: this.method.limitScoresMonths,

          noGroupScoresGhin: this.method.noGroupScoresGhin,
          noGroupScoresGhinFac: this.method.noGroupScoresGhinFac,

          // lowestGhinGroup: this.method.lowestGhinGroup,
          // useGhinIndexScores: this.method.useGhinIndexScores,
          // useGhinIndexTable: this.method.useGhinIndexTable

          guestGhinFac: this.method.guestGhinFac
        }
      
    }
 
    return data
  }
  setData (data) {
    
    this.useGameIndex = data.useGameIndex
    this.lookupGhinIndex = data.lookupGhinIndex
    if (this.useGameIndex) {
      
      this.maxHandicap = data.maxHandicap
    
        this.method = {
     
          groupIndexAvg: data.method.groupIndexAvg,

          groupIndexTable: new IndexAvgTable(true),
          limitScores: data.method.limitScores,
          limitScoresMonths: data.method.limitScoresMonths,

          // minScores: data.method.minScores===undefined?false:data.method.minScores,
          // numMinScores: data.method.numMinScores===undefined?0:data.method.numMinScores,
          // numMinScoresMonths: data.method.numMinScoresMonths===undefined?0:data.method.numMinScoresMonths,

          noGroupScoresGhin: data.method.noGroupScoresGhin===undefined?false:data.method.noGroupScoresGhin,
          noGroupScoresGhinFac: data.method.noGroupScoresGhinFac===undefined?1:data.method.noGroupScoresGhinFac,

          // lowestGhinGroup: data.method.lowestGhinGroup,
          // useGhinIndexScores: data.method.useGhinIndexScores,
          // useGhinIndexTable: data.method.useGhinIndexTable

          guestGhinFac: data.method.guestGhinFac===undefined?1:data.method.guestGhinFac,
        }
        if (data.method.groupIndexTable !== undefined) this.method.groupIndexTable.setData(data.method.groupIndexTable)

      
    }
  }
}


