
import Games from './games'
import Handicap from './handicap'
import Course from './course'
import dayjs from 'dayjs'

import Seasons from './seasons'
import { FSTimestampToDate, DateToFSTimestamp } from '../lib'


export default class Group {
  constructor() {
    this.initialize()
  }
  initialize(){
    this.docId = ''
    this.name = ''
    this.courseDocId = ''
    this.course = new Course()
    this.betOnGames = false
    this.roundPayouts = true
    this.handicap = new Handicap()
    this.startingTime = dayjs().hour(9).toDate()
    this.interval = 10
    this.holeByHoleScores = false
    this.games = new Games()
    this.seasons = new Seasons()
    this.results = []


    this.objName = 'Group'
  }
 
  get title () {
    let title = ''
    if (this.currentSeason.name === '') {
      title = this.name
    }
    else {
      title = this.name + ' - ' + this.currentSeason.name
    }
    return title
  }
  get storeName () {
    return 'group'
  }
  
  get latestSeason () {
    
    return this.seasons.latest
  }

  getHandicapFac() {
    let facFull18 = 1.0
    let facFront = 1.0
    let facBack = 1.0

    let game = this.games.aGames.find(g=>(g.prop==='stroke' && g.side==='full18'))
    if (game !== undefined) {
      facFull18 = game.handicapFac
    }
    game = this.games.aGames.find(g=>(g.prop==='stroke' && g.side==='front'))
    if (game !== undefined) {
      facFront = game.handicapFac
    }
    game = this.games.aGames.find(g=>(g.prop==='stroke' && g.side==='back'))
    if (game !== undefined) {
      facBack = game.handicapFac
    }

    return {
      'full18': facFull18,
      'front': facFront,
      'back': facBack,
    }
  }

  
  isNew () {
    return this.docId === ''
  }
  toJSON () {
    return JSON.stringify(this.getObj())
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  copy () {
    const group = new Group()

    group.setObj(this.getObj())

    return group
  }
  getObj () {
    const obj = {
      docId: this.docId,
      name: this.name,
      courseDocId: this.courseDocId,
      course: this.course.getObj(),

      betOnGames: this.betOnGames,
      roundPayouts: this.roundPayouts,
      holeByHoleScores: this.holeByHoleScores,
      games: this.games.getObj(),
      seasons: this.seasons.getObj(),
      
      handicap: this.handicap.getObj(),

      startingTime: new Date(Number(this.startingTime)),
      interval: this.interval
    }

    return obj
  }
  setObj (data) {
    this.docId = data.docId
    this.name = data.name
    this.courseDocId = data.courseDocId
    this.course.setObj(data.course)

    this.betOnGames = data.betOnGames
    this.roundPayouts = data.roundPayouts
    this.holeByHoleScores = data.holeByHoleScores
    this.games.setObj(data.games)
    this.seasons.setObj(data.seasons)
  
    this.handicap.setObj(data.handicap)
    this.startingTime = new Date(Number(data.startingTime))
    if(typeof data.startingTime === "string") this.startingTime = new Date(data.startingTime)
    this.interval = data.interval

   
  }
  getData () {
    const data = {
      name: this.name,
      courseDocId: this.courseDocId,

      betOnGames: this.betOnGames,
      roundPayouts: this.roundPayouts,
      holeByHoleScores: this.holeByHoleScores,
      seasons: this.seasons.getData(),
      handicap: this.handicap.getData(),
      games: this.games.getData(),
     
      startingTime: DateToFSTimestamp(this.startingTime), // convert date string to Timestamp object
      interval: this.interval
   
    }
   
    return data
  }
  setData (data) {
  
    this.name = data.name
    this.courseDocId = data.courseDocId

    this.betOnGames = data.betOnGames
    this.roundPayouts = data.roundPayouts === undefined ? true : data.roundPayouts
    this.holeByHoleScores = data.holeByHoleScores
    this.seasons.setData(data.seasons)
   
    this.handicap.setData(data.handicap)
    this.games.setData(data.games),
    this.startingTime = FSTimestampToDate(data.startingTime) // stored as Timestamp object
    this.interval = data.interval

    
  
   
  }
}
