<template>
  <div class="modal-card" >
    <header class="modal-card-head has-padding-10">
      <p class="modal-card-title">{{player.docId==='' ? 'Add Player' : 'Edit Player'}}</p>
    </header>
    <div class="modal-card-body">

      <b-field v-if="isAdmin" grouped>
        <div class="field">
          <div class="control" style="margin-top:2px;">
            <b-switch
              v-model="admin" >
              Make an Administrator
            </b-switch>
          </div>
        </div>
      </b-field>

      <b-field label="Name">
        <b-input
          :readonly="!isAdmin"
          v-model="name"
          size="is-capitalized"
        />
      </b-field>

      <b-field label="Email" :message="emailErrors">
        <b-input
          :readonly="!isAdmin"
          v-model="email"
          @blur="$v.email.$touch()"
          maxlength="30"
        />
      </b-field>

      <b-field label="Gender">
          <b-select :disabled="!isAdmin" v-model="gender">
            <option value="M">M</option>
            <option value="F">F</option>
          </b-select>
        </b-field>

      <b-field grouped>
        <b-field label="Type">
          <b-select :disabled="!isAdmin" v-model="type">
            <option
              v-for="playerType in playerTypes"
              :value="playerType"
              :key="playerType"
            >{{ playerType }}</option>
          </b-select>
        </b-field>
        <b-field label="Tee">
          <b-select :disabled="!isAdmin" v-model="tee">
            <option v-for="tee in playerTees" :value="tee" :key="tee">{{ tee }}</option>
          </b-select>
        </b-field>
     
      </b-field>
      <b-field label="GHIN #">
        <b-input
          :readonly="!isAdmin"
          style="width:100px;"
          v-model="ghinNum"
        />
      </b-field>
     

      <b-field grouped>
        <b-field label="Index">
          <vue-numeric
            class="input text-center"
            style="width:5em;"
            v-model="index.full18"
            currency
            thousand-separator
            :precision="1"
            minus
          />
        </b-field>
        <b-field label="Source">
          <p class="control" style="padding-top:8px;">
            <a 
              ref="indexSource" 
              class="has-text-link" 
              @click="openHandicapDetails()"
              >
              {{ handicap===null ? 'Lookup' : indexSource }}</a>
          </p>
        </b-field>

        <b-field label="Handicap">
          <input class="input is-static has-text-centered" v-model="handicap" readonly style="width:5em;"/>
        </b-field>

      </b-field>

      <b-field v-if="!registered">
        <div class="control" style="margin-top:2px;">
          <b-switch
            v-model="invite" >
            Invite Player to Register
          </b-switch>
        </div>
      </b-field> 

      
   
    </div>

    <footer class="modal-card-foot has-padding-10" style="justify-content: flex-end;">
      
      <b-button @click="cancelDialog" >{{ isAdmin?'Cancel':'Close'}}</b-button>
      <b-button v-if="isAdmin" type="is-primary" :disabled="!$v.email.email" @click="okDialog" >OK</b-button>
      <b-button v-if="player.docId!=='' && isAdmin" @click="deletePlayer" >Delete</b-button>
    </footer>

    <b-modal :active.sync="showHandicapDetails" has-modal-card full-screen :can-cancel="false">
      <handicap-details 
        :indexLookup = "indexLookup" 
        :handicapCalc = "handicapCalc"
        :playerInfo = "handicapPlayerInfo"
        :playerTees = "playerTees"
        :viewOnly = "false"
        :handicapFac = "group.getHandicapFac()"
        @useHandicap="setIndex" />
    </b-modal>
    
  </div>

</template>

<script>
import HandicapDetails from '../Dialogs/HandicapDetails'
import { capitalizeWord } from "../../lib"
import { email} from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      admin: this.player.admin,
      name: this.player.name,
      registered: this.player.registered,
      email: this.player.email,
      gender: this.player.gender,
      type: this.player.type,
      tee: this.player.tee,
      ghinNum: this.player.ghinNum,
      index: this.player.index,
      indexSource: this.player.indexSource,
      handicap: this.player.handicap,

      showHandicapDetails: false,
      handicapPlayerInfo: {},

      invite: false
    }
  },
  props: ['player','playerTypes','playerTees','indexLookup','handicapCalc','group'],

  components: {
    'handicap-details': HandicapDetails
  },
  validations: {
    email: {
      email
    }
  },
  computed: {
    emailErrors () {
      return this.$v.email.email ? '' : 'Not a valid email'
    },
    isAdmin () {
      return this.$store.getters['user/isAdmin']
    }
  },
  methods: {
    cancelDialog () {
      this.$parent.close()
    },
    
    okDialog () {
      this.$emit('savePlayerMobile', {
        docId: this.player.docId,
        admin: this.admin,
        name: capitalizeWord(this.name),
        email: this.email.toLowerCase(),
        gender: this.gender,
        type: this.type,
        tee: this.tee,
        ghinNum: this.ghinNum,
        index: this.index,
        indexSource: this.indexSource,
        handicap: this.handicap,
        invite: this.invite,
        registered: this.registered
     
      })
      this.$parent.close()
    },
    deletePlayer () {
      this.$buefy.dialog.confirm({
        message:
          'Delete ' + this.name + '?',
        onConfirm: () => {
          this.$emit('deletePlayerMobile', this.player.docId)
          this.$parent.close()
        }
      })
    
    },
    async openHandicapDetails() {

      this.handicapPlayerInfo = {
        playerDocId: this.player.docId,
        name: this.name,
        ghinNum: this.ghinNum,
        indexSource: this.indexSource,
        gender: this.gender,
        tee: this.tee,
        type: this.type,
        clubName: this.group.course.name
      }
    
      this.showHandicapDetails = true
    },
    setIndex (indexInfo) {
    
      this.index = indexInfo.index
      this.indexSource = indexInfo.indexSource

      const index = indexInfo.getIndexObj()

      this.handicap = this.handicapCalc.getPlayingHandicapObj(index, this.tee, this.group.getHandicapFac(), this.gender)

    }
   
  }
}
</script>

<style>
 



</style>
