<template>
  
  <div class="modal-card">
    <div class="modal-card-head">
      
      <p class="modal-card-title">Players</p>
      <b-select v-model="playerFilter">
        <option
          v-for="playerType in playerTypes"
          :value="playerType"
          :key="playerType"
        >{{ playerType }}</option>
      </b-select>
    </div>
    <div class="modal-card-body">  
      
   <!--   <div class="panel">
        <label class="panel-block" v-for="(player,index) in filteredPlayers" :key="index" @click="selectPlayer(player)">
          <b-checkbox :value="isChecked(player)" >{{player.name}}</b-checkbox>
        
        </label>
      </div>-->
      <b-table
        :data="filteredPlayers"
        :columns="columns"
        checkable
        :checked-rows.sync="selectedPlayers"
        :is-row-checkable="(row) => true"
        @click="onRowClicked"
        :mobile-cards="false"
      >

      </b-table>
    </div>
    <footer class="modal-card-foot" style="justify-content: flex-end;">

      <b-button @click="$parent.close()">Cancel</b-button>
      <b-button type="is-primary" @click="okDialog" :disabled="selectedPlayers.length===0">OK</b-button>
    </footer>
  </div>
           
</template>

<script>
import { sortArrayObj } from "../../lib"

export default {
  data () {
    return {
      columns: [
        { field: 'name', label: 'Name' },
        { field: 'type', label: 'Type' }
      ],
    
      filteredPlayers: [],
      playerFilter: 'Active',
      selectedPlayers: this.preSelectedPlayers,
      playerTypes: ['All','New','Active', 'Part Time', 'Inactive']
    }
  },

  mounted () {
   
    this.filterPlayers()
    
  },
  watch: {
    playerFilter() {
      this.filterPlayers()
    }
  },
  props: ['players','preSelectedPlayers'],
  methods: {
    filterPlayers() {
      if (this.playerFilter !== 'All') {
        this.filteredPlayers = this.players.filter(p => p.type === this.playerFilter)
      }
      else {
        this.filteredPlayers = this.players
      }
      
      sortArrayObj(this.filteredPlayers, 'name')
     
    },
    
    
    onRowClicked (player) {
      const index = this.selectedPlayers.findIndex(p => p.docId === player.docId)
      if (index === -1) {
        this.selectedPlayers.push(player)
      }
      else {
        this.selectedPlayers.splice(index, 1)
      }
      
    },
    okDialog () {
      this.$parent.close()
      this.$emit('saveModal', this.selectedPlayers)
      
    }
  }
}

</script>

<style>
 


</style>