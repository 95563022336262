import Round from '../models/round'

import { sortArrayObj } from '../lib'

export default class Rounds {
  constructor() {
    this.initialize()
  }
  initialize () {
    this.aRounds = []
    this.sortField = 'date'
    this.sortDir = 'desc'
  }
  addDoc (docId, data) {
    const round = this.create()
    round.docId = docId
    round.setData(data)

    return round
  }
  add (round) {
    this.aRounds.push(round)
  } 

  addNew (docId) {
    const round = this.create()
    round.docId = docId

    return round
  }
  create () {
    const round = new Round()
    this.add(round)

    return round
  }

  get (docId) {
    const round = this.aRounds.find(o => o.docId === docId)
    return round
  }
  getByIndex (index) {
    return this.aRounds[index]
  }

  exists (docId) {
    const index = this.aRounds.findIndex(o => o.docId === docId)
    return index >= 0
  }

  set (round) {
    const idx = this.aRounds.findIndex(o => o.docId === round.docId)
    if(idx === -1) {
      this.aRounds.push(round)
    }
    else {
      this.aRounds[idx] = round
    }
  }
  delete (round) {
    let index
    if (round.docId === '') {
      index = this.aRounds.findIndex(o => o.roundNum === round.roundNum)
    }
    else {
      index = this.aRounds.findIndex(o => o.docId === round.docId)
    }
    this.deleteByIndex (index)
  }
  deleteByDocId (docId) {
    
    const index = this.aRounds.findIndex(o => o.docId === docId)   
    this.deleteByIndex (index)
  }
  deleteByIndex (index) {
    this.aRounds.splice(index, 1)
  }
  clear () {
    this.aRounds = []
  }
  get size () {
    return this.aRounds.length
  }

  sort (field='', dir='') {
    if (field === '') field = this.sortField
    if (dir === '') dir = this.sortDir
    sortArrayObj (this.aRounds, field, dir)
    this.sortField = field
    this.sortDir = dir
  }

  setRoundNums () {
    this.sort('date','asc')
    for (let i=0; i<this.size; i++) {
      this.aRounds[i].roundNum = i + 1
    }
  }

  update (roundUpdates) {
    for (let roundUpdate of roundUpdates.aUpdates) {
      const round = this.aRounds.find(r => r.docId === roundUpdate.docId)
      round.update(roundUpdate)
    }
  }
  
  toJSON () {
    return JSON.stringify(this.getObj(),null,2)
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  getObj () {
    return this.aRounds.map(round => round.getObj())
  }
  setObj (data) {
    this.aRounds = data.map(roundObj => {
      const round = new Round()
      round.setObj(roundObj)
      return round
    })
  }
  getData () {
    return this.aRounds.map(round => round.getData())
  }
  setData (data) {
    this.aRounds = data.map(roundData => {
      const round = new Round()
      round.setData(roundData)
      return round
    })
  }
}

// module.exports = { Rounds }
