import dayjs from 'dayjs'

export default class IndexScore {
  constructor(roundDate, course, score, tee, diff, pcc) {
    this.roundDate = roundDate
    this.course = course
    this.score = score
    this.tee = tee
    this.diff = diff
    this.pcc = pcc
    this.diffUsed = false
  
  }
  
  get formattedDate () {
   
    return dayjs(this.roundDate).format('MM/DD/YYYY')
  }
  
}


