
import dayjs from 'dayjs'
import { roundTo, sortArrayObj, sortArrayNum, formatHandicap, newDate, getDataValue, formatHandicapSummary, FSTimestampToDate, DateToFSTimestamp } from '../lib'
import PointsTable from './pointsTable'
import Course from './course'



const parseFullName = require('parse-full-name').parseFullName

export default class Score {
  constructor(data) {
    if(data) {
      this.setObj(data)
    }
    else {
      this.initialize()
    }
  }
  
  initialize () {
    this.objType = 'score'
    this.docId = ''
    this.roundDocId = null
    this.tournamentDocId = null
 
    this.roundDate = newDate()
    this.roundStatus = ''
    this.roundName = ''
    this.courseLabel = ''
    this.course = null
    this.holeByHoleScores = false
    this.holeHandicaps = []
    this.holePars = []
    this.posted = false
    this.playerDocId = ''
    this.name = ''
    this.email = ''
    this.type = ''
    this._tee = ''
    this.gender = 'M'
    this.ghinNum = ''
    this.lastIndex = ''
    this.index = null
    this.indexSource = 'Manual'
    this.handicap = null
    this.handicapDate = null
    this.useGameIndex = false
   
    this.diff = {front: 0, back: 0, full18: 0}
    this.pcc = 0
    this.wd = false
    this.score = {front: 0, back: 0, full18: 0}
   
    this.points = null
    this.payOut = {}
    this.pos = {}
    this.scores = [] // hole by hole scores
   
    this.skins = []
    this.skinsCount = 0
    this.kp = []
    this.kpDist = []
    this.kpCount = 0
    this.kpUnit = 1
    this.dots = []
    this.dotsCount = 0
    this.totalBet = 0
    this.totalPayout = 0
   
    this.exclude = false

    this.signUpDate = null
    this.final = false
    
    this.games = []

    this.pointsTable = null
    this.teeTimeRequest = ''
  }

  set tee(tee) {
    this._tee = tee
    if (this.course) {
      this.holeHandicaps = [...this.course.tees.getTee(this._tee, this.gender).handicap]
      this.holePars = [...this.course.tees.getTee(this._tee, this.gender).par]
    }
   
  }
  get tee() {
    return this._tee
  }

  get netScore () {
   
    const netScore = {
      front: this.score.front - this.handicapCompleted('front'),
      back: this.score.back - this.handicapCompleted('back')
    }
    netScore.full18 = netScore.front + netScore.back


  
    return netScore
  }

  setRound (round) {

    this.roundDocId = round.docId
    this.roundDate = round.date
    this.roundStatus = round.status
    this.posted = round.posted
    this.courseLabel = round.course.label
    this.roundName = round.name
    this.holeByHoleScores = round.holeByHoleScores
    this.course = round.course
    this.holeHandicaps = [...round.course.tees.getTee(this.tee, this.gender).handicap]
    this.holePars = [...round.course.tees.getTee(this.tee, this.gender).par]
    this.games = round.games.aGames.map (g=> {
      return {
        id: g.id,
        name: g.name,
        side: g.side,
        prop: g.prop,
        handicapFac: g.handicapFac
     
      }
    })
    this.totalBet = round.games.totalBet
    this.useGameIndex = round.handicap.useGameIndex

    const index = round.games.aGames.findIndex(g=>g.isStableford === true)
    if (index >= 0) {
      this.pointsTable = round.games.aGames[index].pointsTable
    }
  }


  get formattedDate () {
   
    return dayjs(this.roundDate).format('MM/DD/YYYY')
  }
  get formattedDateLabel () {
    return dayjs(this.roundDate).format('ddd MMM D, YYYY')
  }

  get signedUpDateFormatted () {
    return this.signUpDate===null?'n/a':dayjs(this.signUpDate).format('ddd MM/DD/YYYY hh:mma')
  }

  get handicapDateFormatted () {
    return this.handicapDate===null?'n/a':dayjs(this.handicapDate).format('ddd MM/DD/YYYY hh:mma')
  }

  get slotLabel () {
    let ghinSource = this.indexSource==='GHIN' && this.useGameIndex?'*':''
    let guest = this.type==='Guest'?'(G)' : ''
    let hdcpLabel = ''
    if (this.handicap) {
      hdcpLabel = `Hdcp ${formatHandicap(this.handicap.full18===null?'n/a':this.handicap.full18)}${ghinSource}`
    }
    let label = `${this.name} ${guest} ${hdcpLabel} (${this.tee})`
    if (this.wd) label += ' (WD)'
    return label
  }

  get lastName () {
    const lastName = parseFullName(this.name).last
    return lastName
  }

  fullSlotLabel () {
    return `${this.name} ${this.handicapSummary()} (${this.tee})`
  }

  fullSlotLabelLastName () {
    return `${this.lastName} ${this.handicapSummary()} (${this.tee})`
  }

  get holesCompleted () {
    let holesCompleted = 0
    let hasScores = false
    if (this.scores !== undefined) {
      if (this.scores.length > 0) hasScores = true
    }
    if (!hasScores) {
      if (this.score.front > 0) {
        holesCompleted = 9
      }
      if (this.score.back > 0) {
        holesCompleted = 18
      }
    }
    else {
      holesCompleted = this.scores.filter(s=>s.score>0).length
    }
    return holesCompleted
  }

  isRoundComplete (numHoles=18) {
 
    let completed = false
    if (numHoles === 18) {
      completed = this.holesCompleted === 18
      
    }
    else if (numHoles === 9) {
      completed = this.holesCompleted >= 9 && (this.isHoleCompleted(1) && this.isHoleCompleted(9) || this.isHoleCompleted(10) && this.isHoleCompleted(18) )
    }
    return completed || this.wd
  }

  hasScore () {
    return this.score.full18 !== 0
  }

  get holeThru () {
    let thru = ''
    let hasScores = false
    if(this.scores !== undefined) {
      if (this.scores.length > 0) hasScores = true
    }
   
    if (hasScores) {
      sortArrayObj(this.scores,'holeNum')
      thru = this.scores[this.scores.length-1].holeNum
    }
    else {
      if (this.score.front !== 0) thru = 9
      if (this.score.back !== 0) thru = 18

    }
    return thru
  }

  toPar (side) {
    const netScore = this.score[side] - this.handicapCompleted(side)
  
    return netScore - this.parCompleted(side)
  }

  get toParObj () {
    const toPar = {
      front: this.toPar('front'),
      back: this.toPar('back'),
      full18: this.toPar('full18')
    }
    return toPar
  }

  handicapStrokes () {
   
    const handicapStrokes = {
      front: this.handicapStrokesSide('front'),
      back: this.handicapStrokesSide('back'),
      full18: this.handicapStrokesSide('full18')
    }

    return handicapStrokes
  }

  handicapStrokesSide (side, maxHandicap) {
    // let fac =
    // const game = this.games.find(g=>g.side===side && g.prop===gameProp)
    // if (game !== undefined) fac = game.handicapFac

    let handicap = 0
    if (this.handicap !== null) {
      let handicapSide = this.handicap[side]
      if (maxHandicap != undefined) {
        if(side == 'back' || side == 'front') maxHandicap = maxHandicap / 2
        if (handicapSide > maxHandicap) handicapSide = maxHandicap
      }
      // handicap = roundTo(handicapSide*fac, 0)
      handicap = handicapSide
    }

    const handicapHolesSide = []
    let holeOffset = 0
    let totalHoles = 9
    if (side === 'back') holeOffset = 9
    if (side === 'full18') totalHoles = 18
    for (let i=0; i<totalHoles; i++) {
      handicapHolesSide.push(this.holeHandicaps[i+holeOffset])
    }
    
    let handicapHolesSideSorted = []
    const handicapStrokes = new Array(totalHoles).fill(0)

    if (handicap > 0) {
      while (handicap > 0) {
        if (handicapHolesSideSorted.length === 0) {
            handicapHolesSideSorted = [...handicapHolesSide]
            handicapHolesSideSorted.sort(function(a, b){return a - b})
        }
      
        let holeNum = handicapHolesSideSorted.shift()
        let holeIndex = handicapHolesSide.indexOf(holeNum)
        handicapStrokes[holeIndex] += 1
        handicap--
      }
    }
    else if (handicap < 0) {  // plus handicap, so allocate -1 strokes to easiest holes
      while (handicap < 0) {
        if (handicapHolesSideSorted.length === 0) {
            handicapHolesSideSorted = [...handicapHolesSide]
            handicapHolesSideSorted.sort(function(a, b){return b - a})
        }
      
        let holeNum = handicapHolesSideSorted.shift()
        let holeIndex = handicapHolesSide.indexOf(holeNum)
        handicapStrokes[holeIndex] -= 1
        handicap++
      }
    }
    
  console.log('handicapStrokes=',handicapStrokes)
    return handicapStrokes
  }


  isHoleCompleted (holeNum) {
    if (this.scores.length === 0) return false
    let index = this.scores.findIndex(h => h.holeNum === holeNum)
    return index >= 0 || this.wd
  }

  getHoleScore (holeNum) {
    let score = null
    let holeScore = this.scores.find(h => h.holeNum === holeNum)
    if (holeScore !== undefined) {
      score = holeScore.score
    }
    return score
  }

  getHoleNetScore (holeNum) {
    let holeScore = this.scores.find(h => h.holeNum === holeNum)
    let netScore = null
    
    if (holeScore !== undefined) {
     
      const strokes = this.getHandicapStrokesHole (holeNum, 'full18') 
      netScore = holeScore.score - strokes
    }
    return netScore
  }

  getHandicapHoleAlloc (holeNum) {
    let strokes = 0
    if (holeNum >= 1 && holeNum <= 18) strokes = this.holeHandicaps[holeNum-1]
    return strokes
  }

  getHolePar (holeNum) {
    let par = 0
    if (holeNum >= 1 && holeNum <= 18) par = this.holePars[holeNum-1]
    return par
  }

  getPar (side='fuli18') {
    let par
    if (side === 'front') {
      par = this.holePars.slice(0, 9).reduce((total, num) => total + num,0)
    } else if (side === 'back') {
      par = this.holePars.slice(9).reduce((total, num) => total + num,0)
    } else {
      par = this.holePars.reduce((total, num) => total + num,0)
    }
    return par
  }

  initializePoints () {
    if (this.pointsTable === null) {
      this.pointsTable = new PointsTable()
      
    }
    if (this.points === null) {
      this.points = {front: 0, back: 0, full18: 0}
    }
  }

 

  getPoints (side='full18') {
    this.initializePoints ()
    let totalPoints = 0
   
    if (this.holeByHoleScores) {
      if (side === 'front') {
        for (let i=1; i<=9; i++) {
          totalPoints += this.getHolePoints (i)
        }
        this.points.front = totalPoints
      }
      else if (side === 'back') {
        for (let i=10; i<=18; i++) {
          totalPoints += this.getHolePoints (i)
        }
        this.points.back = totalPoints
      }
      else {
        for (let i=1; i<=18; i++) {
          totalPoints += this.getHolePoints (i)
        }
        this.points.full18 = totalPoints
      }
    }
    
    return totalPoints
  }

  getNetPoints (side='full18') {
    this.initializePoints ()
    let totalPoints = 0
   
    if (this.holeByHoleScores) {
      if (side === 'front') {
        for (let i=1; i<=9; i++) {
          totalPoints += this.getHoleNetPoints (i)
        }
        this.points.front = totalPoints
      }
      else if (side === 'back') {
        for (let i=10; i<=18; i++) {
          totalPoints += this.getHoleNetPoints (i)
        }
        this.points.back = totalPoints
      }
      else {
        for (let i=1; i<=18; i++) {
          totalPoints += this.getHoleNetPoints (i)
        }
        this.points.full18 = totalPoints
      }
    }
    
    return totalPoints
  }

  getPointsCompleted (side='full18') {
    let pointsCompleted = 0

    if (this.holeByHoleScores) {
      if (side === 'front') {
        
        const scores = this.scores.filter(s => (s.holeNum >=1 && s.holeNum <= 9))
        if (scores.length > 0) {
          for (let score of scores) {
            pointsCompleted += this.getHolePoints(score.holeNum) 
          }
        }
        
      }
      else if (side === 'back') {
        const scores = this.scores.filter(s => (s.holeNum >=10 && s.holeNum <= 18))
        if (scores.length > 0) {
          for (let score of scores) {
            pointsCompleted += this.getHolePoints(score.holeNum) 
          }
        }
       
      }
      else {
    
        for (let score of this.scores) {
      
          pointsCompleted += this.getHolePoints(score.holeNum) 
        }
       
      }
    
    }
    else {
      pointsCompleted = this.getPoints(side)
    }
    
    return pointsCompleted
  }

  getNetPointsCompleted (side='full18') {
    let pointsCompleted = 0

    if (this.holeByHoleScores) {
      if (side === 'front') {
        
        const scores = this.scores.filter(s => (s.holeNum >=1 && s.holeNum <= 9))
        if (scores.length > 0) {
          for (let score of scores) {
            pointsCompleted += this.getHoleNetPoints(score.holeNum) 
          }
        }
        
      }
      else if (side === 'back') {
        const scores = this.scores.filter(s => (s.holeNum >=10 && s.holeNum <= 18))
        if (scores.length > 0) {
          for (let score of scores) {
            pointsCompleted += this.getHoleNetPoints(score.holeNum) 
          }
        }
       
      }
      else {
    
        for (let score of this.scores) {
      
          pointsCompleted += this.getHoleNetPoints(score.holeNum) 
        }
     
      }
    }
    else {
      pointsCompleted = this.getNetPoints(side)
    }
    
    return pointsCompleted
  }

  getHolePoints (holeNum) {
    this.initializePoints ()
    
    // const strokes = this.getHandicapStrokesHole (holeNum, 'full18') 
    const points = this.pointsTable.getPoints (0)
    
  
    return points
  }

  getHoleNetPoints (holeNum) {
    this.initializePoints ()
    let points = 0
    let holeScore = this.scores.find(h => h.holeNum === holeNum)
    if (holeScore !== undefined) {
 
      const toPar = this.getHoleNetScoreToPar (holeNum)
      points = this.pointsTable.getPoints (toPar)
    }
  
    return points
  }

  toPoints (side='full18', useQuota=true) {
    let points = this.getNetPointsCompleted(side)
  
    if (useQuota) points =  points - this.getPointsCompleted(side)
  
  
    return points
  }

  getHoleScoreToPar (holeNum) {
    let toPar = null
    let holeScore = this.scores.find(h => h.holeNum === holeNum)
    
    if (holeScore !== undefined) {

      toPar = holeScore.score - this.getHolePar(holeNum)
  
    }
    return toPar
  }

  getHoleNetScoreToPar (holeNum) {
    let toPar = null
    let holeScore = this.scores.find(h => h.holeNum === holeNum)
    
    if (holeScore !== undefined) {
     
      const strokes = this.getHandicapStrokesHole (holeNum, 'full18') 
      toPar = holeScore.score - strokes - this.getHolePar(holeNum)
    }
    return toPar
  }
  
  
  getDots (holeNum) {
    let dots = 0
    let dot = this.dots.find(h => h.holeNum === holeNum)
    if (dot !== undefined) {
      dots = dot.number
    }
    return dots
  }

  totalDots (side='full18') {
    let totalDots = 0
    if (side === 'front') {
      const dots = this.dots.filter(s => (s.holeNum >=1 && s.holeNum <= 9))
      if (dots.length > 0) {
        totalDots = dots.reduce((total, holeScore) => total+holeScore.number, 0)
      }
    }
    else if (side === 'back') {
      const dots = this.dots.filter(s => (s.holeNum >=10 && s.holeNum <= 18))
      if (dots.length > 0) {
        totalDots = dots.reduce((total, holeScore) => total+holeScore.number, 0)
      }
    }
    else {
      totalDots = this.dots.reduce((total, holeScore) => total+holeScore.number, 0)
    }
    return totalDots
  }

  addDots (holeNum, numDots) {
    if (numDots === 0) return
    let dotsHoleIndex = this.dots.findIndex(s=>s.holeNum===holeNum)
    if (dotsHoleIndex === -1) {
      this.dots.push({holeNum, number: numDots})
    }
    else {
      this.dots[dotsHoleIndex] = {holeNum, number: numDots}
    }
    this.dotsCount = this.dots.reduce((total, hole) => hole.number + total,0)
  }

  removeDots (holeNum) {
    let remove = false

    const dotsHoleIndex = this.dots.findIndex(s=>s.holeNum===holeNum)
    if (dotsHoleIndex >= 0) {
      this.dots.splice(dotsHoleIndex, 1)
      this.dotsCount = this.dots.reduce((total, hole) => hole.number + total,0)
      remove = true
    }
    return remove
  }

  handicapCompleted (side) {
    let handicapCompleted = 0
    let handicapStrokes
  
    if (this.holeByHoleScores) {
     
      if (side === 'front') {
        const scores = this.scores.filter(s => (s.holeNum >=1 && s.holeNum <= 9))
        if (scores.length > 0) {
          handicapStrokes = this.handicapStrokesSide('front')
          for (let score of scores) {
            handicapCompleted += handicapStrokes[score.holeNum-1]
          }
        }
      }
      else if (side === 'back') {
        const scores = this.scores.filter(s => (s.holeNum >=10 && s.holeNum <= 18))
        if (scores.length > 0) {
          handicapStrokes = this.handicapStrokesSide('back')
          for (let score of scores) {
            handicapCompleted += handicapStrokes[score.holeNum-10]
          }
        }
      }
      else {
        handicapStrokes = this.handicapStrokesSide('full18')
        for (let score of this.scores) {
          handicapCompleted += handicapStrokes[score.holeNum-1]
        }
      }
      
    }
    else {
      if (this.handicap === null) {
        handicapCompleted = 0
      }
      else {
        if (side === 'front') {
          if (this.score.front > 0) {
            handicapCompleted = this.handicap.front
          }
        }
        else if (side === 'back') {
          if (this.score.back > 0) {
            handicapCompleted = this.handicap.back
          }
        }
        else {
          if (this.score.full18 > 0) {
            handicapCompleted = this.handicap.full18
          }
        }
      }
      
    }
    
    return handicapCompleted
  }

  parCompleted (side='full18') {
    let parCompleted = 0

    if (this.holeByHoleScores) {
      if (side === 'front') {
        
        const scores = this.scores.filter(s => (s.holeNum >=1 && s.holeNum <= 9))
        if (scores.length > 0) {
          for (let score of scores) {
            parCompleted += this.getHolePar(score.holeNum) 
          }
        }
        
      }
      else if (side === 'back') {
        const scores = this.scores.filter(s => (s.holeNum >=10 && s.holeNum <= 18))
        if (scores.length > 0) {
          for (let score of scores) {
            parCompleted += this.getHolePar(score.holeNum) 
          }
        }
       
      }
      else {
    
        for (let score of this.scores) {
      
          parCompleted += this.getHolePar(score.holeNum) 
        }
       
      }
    }
    else {
      parCompleted = this.getPar(side)
    }
    
    return parCompleted
  }

  totalScore (side='full18') {
    let totalScore = 0
    if (side === 'front') {
      const scores = this.scores.filter(s => (s.holeNum >=1 && s.holeNum <= 9))
      if (scores.length > 0) {
        totalScore = scores.reduce((total, holeScore) => total+holeScore.score, 0)
      }
    }
    else if (side === 'back') {
      const scores = this.scores.filter(s => (s.holeNum >=10 && s.holeNum <= 18))
      if (scores.length > 0) {
        totalScore = scores.reduce((total, holeScore) => total+holeScore.score, 0)
      }
    }
    else {
      totalScore = this.scores.reduce((total, holeScore) => total+holeScore.score, 0)
    }
    return totalScore
  }

  clearScores (side='full18', selectedHoles=[]) {
   
    if (side === 'front') {
      this.scores = this.scores.filter(s=>s.holeNum>9)
      this.score.front = 0
      this.score.full18 = this.score.back

      const kp = [...this.kp]
      for (let i=0; i<kp.length; i++) {
        if (kp[i] < 10) {
          this.kp.splice(i,1)
          this.kpDist.splice(i,1)
        }
      }
      this.kpCount = this.kp.length

      this.skins = this.skins.filter(s=>s.holeNum > 9)
      this.skinsCount = this.skins.length

      this.dots = this.dots.filter(s=>s.holeNum > 9)
      this.dotsCount = this.dots.reduce((total, hole) => hole.number + total,0)
      

    }
    else if (side === 'back') {
      this.scores = this.scores.filter(s=>s.holeNum<10)
      this.score.back = 0
      this.score.full18 = this.score.front

      const kp = [...this.kp]
      for (let i=0; i<kp.length; i++) {
        if (kp[i] > 9) {
          this.kp.splice(i,1)
          this.kpDist.splice(i,1)
        }
      }
      this.kpCount = this.kp.length

      this.skins = this.skins.filter(s=>s.holeNum < 10)
      this.skinsCount = this.skins.length

      this.dots = this.dots.filter(s=>s.holeNum < 10)
      this.dotsCount = this.dots.reduce((total, hole) => hole.number + total,0)
    
    }
    else if (side === 'full18') {
      this.exclude = false
      this.final = false
      this.wd = false

      this.scores = []
      this.score.front = 0
      this.score.back = 0,
      this.score.full18 = 0


      this.kp = []
      this.kpDist = []
      this.kpCount = 0

      this.skins = []
      this.skinsCount = 0

      this.dots = []
      this.dotsCount = 0

      this.payOut = {}
      this.pcc = 0
    }
    else if (side === 'select') {
      let index
      for (let holeNum of selectedHoles) {
        index = this.scores.findIndex(s=>s.holeNum===holeNum)
        if (index >= 0) {
          this.scores.splice(index,1)

          this.score.front = this.totalScore('front')
          this.score.back = this.totalScore('back')
          this.score.full18 = this.score.front + this.score.back
        }
      
        this.removeKP(holeNum)
        this.removeSkin(holeNum)
 
      }
    }
  }

  handicapSummary () {
    const front = this.games.findIndex(g=>g.prop==='stroke' && g.side==='front') >= 0
    const back = this.games.findIndex(g=>g.prop==='stroke' && g.side==='back') >= 0
   
    return formatHandicapSummary (this.handicap, this.indexSource, front, back)
  }

  getHandicapStrokes (side='full18') {  // get handicapstrokes per side based on fuill18 handicap
    let strokes = 0

    let startHole = 1
    let endHole = 18
   
    if (side === 'front') {
      startHole = 1
      endHole = 9
    }
    else if (side === 'back') {
      startHole = 10
      endHole = 18
    }

    for (let i=startHole; i<=endHole; i++) {
      strokes += this.getHandicapStrokesHole(i)
    }
    return strokes
  }

  getHandicapStrokesHole (holeNum, side='full18') {
    let strokes = 0
    let handicapStrokes
   
    if (side === 'front') {
      if (holeNum < 10) {
        handicapStrokes = this.handicapStrokesSide('front')
        strokes = handicapStrokes[holeNum-1]
      }
    }
    else if (side === 'back') {
      if (holeNum > 9 && holeNum <= 18) {
        handicapStrokes = this.handicapStrokesSide('back')
        strokes = handicapStrokes[holeNum-10]
      }
    }
    else {
      handicapStrokes = this.handicapStrokesSide('full18')
      strokes = handicapStrokes[holeNum-1]

        }
    return strokes
  }

  // getHandicapSkinStrokesHole (holeNum, maxHandicap, side='full18') {
  //   let strokes = 0
  //   let handicapStrokes
   
    
  //   if (side === 'front') {
  //     if (holeNum <= 9) {
  //       handicapStrokes = this.handicapStrokesSide('front',maxHandicap)
  //       strokes = handicapStrokes[holeNum-1]
  //     }
  //   }
  //   else if (side === 'back') {
  //     if (holeNum >= 9 && holeNum <= 18) {
  //       handicapStrokes = this.handicapStrokesSide('back',maxHandicap)
  //       strokes = handicapStrokes[holeNum-1]
  //     }
  //   }
  //   else {
  //     handicapStrokes = this.handicapStrokesSide('full18',maxHandicap)
  //     strokes = handicapStrokes[holeNum-1]
  //   }
  //   return strokes
  // }
 
  // calcNetScore (grossScore, handicap) {
  //   let netScore = {
  //     front: this.calcNetScoreSide(grossScore, handicap, 'front'),
  //     back: this.calcNetScoreSide(grossScore, handicap, 'back'),
  //     full18: this.calcNetScoreSide(grossScore, handicap, 'full18')
  //   }
  //   return netScore
  // }
  // calcNetScoreSide (grossScore, handicap, side) {
  //   let netScore
  //   if(Number.isInteger(handicap[side]) && Number.isInteger(grossScore[side])) {
  //     if (grossScore[side] > 0) {
  //       netScore = grossScore[side] - handicap[side]
  //     }
  //     else {
  //       netScore = ''

  //     }
      
  //   }
  //   else {
  //     netScore = grossScore[side]
  //   }
  //   return netScore
  // }

  hasKP (holeNum) {
    return this.kp.indexOf(holeNum) >= 0 
  }
  getNumKPs(side) {
    let numKPs = 0
    if (side==='front') {
      for (let kpHole of this.kp) {
        if (kpHole >= 1 && kpHole <= 9) numKPs++
      }
    }
    else if (side==='back') {
      for (let kpHole of this.kp) {
        if (kpHole >= 10 && kpHole <= 18) numKPs++
      }
    }
    else {
      numKPs = this.kp.length
    }
    return numKPs
  }

  removeKP (holeNum) {
    let remove = false

    const kpHoleIndex = this.kp.indexOf(holeNum)
    if (kpHoleIndex >= 0) {
      this.kp.splice(kpHoleIndex, 1)
      this.kpDist.splice(kpHoleIndex, 1)
      this.kpCount = this.kp.length
      remove = true
    }
    return remove
  }

  addKP (holeNum) {
    
    let kpHoleIndex = this.kp.indexOf(holeNum)
    if (kpHoleIndex === -1) {
      this.kp.push(holeNum)
      this.kpDist.push (0)
      sortArrayNum(this.kp)
      this.kpCount = this.kp.length
     
    }
    
  
  }

  updateKPDist (holeNum, dist) {
  // dist is in feet...store in inchdes
    let kpHoleIndex = this.kp.indexOf(holeNum)
    if (kpHoleIndex > -1) {
      this.kpDist[kpHoleIndex] = Number(dist)*this.kpUnit
    }
  }

  getKPDist (holeNum) {
    let dist = 0 // return feet, stored value is inchdes
    const kpHoleIndex = this.kp.indexOf(holeNum)
    if (kpHoleIndex > -1) {
      dist = this.kpDist[kpHoleIndex] / this.kpUnit
    }
        
    return dist
  }

  hasSkins () {
    return this.skinsCount > 0
  }
  hasSkin (holeNum) {
    return (this.skins.find(s=>s.holeNum===holeNum) !== undefined)
  }
  getNumSkins(side) {
    let numSkins = 0
    if (side==='front') {
      for (let skin of this.skins) {
        if (skin.holeNum >= 1 && skin.holeNum <= 9) numSkins++
      }
    }
    else if (side==='back') {
      for (let skin of this.skins) {
        if (skin.holeNum >= 10 && skin.holeNum <= 18) numSkins++
      }
    }
    else {
      numSkins = this.skins.length
    }
    return numSkins
  }

  removeSkin (holeNum) {
    let removed = false
    const skinHoleIndex = this.skins.findIndex(s=>s.holeNum===holeNum)
    if (skinHoleIndex >= 0) {
      this.skins.splice(skinHoleIndex, 1)
      removed = true
    }
    this.skinsCount = this.skins.length
    return removed
  }

  addSkin (holeNum, scoreVal, netScoreVal) {
    let added = false
    
    const skinHoleIndex = this.skins.findIndex(s=>s.holeNum===holeNum)
    if (skinHoleIndex === -1) {
      this.skins.push({holeNum, score: scoreVal, netScore: netScoreVal})
      added = true
    }
    else {
      added = (this.skins[skinHoleIndex].holeNum !== holeNum) || (this.skins[skinHoleIndex].score !== scoreVal)
      this.skins[skinHoleIndex] = {holeNum, score: scoreVal, netScore: netScoreVal}
    }
    sortArrayObj(this.skins, 'holeNum')
    this.skinsCount = this.skins.length
    return added
  }

  copy () {
    const score = new Score()
    score.setObj(this.getObj())
    return score
  }

  update (scoreUpdate) {
    for (let prop in scoreUpdate.update) {
      this[prop] = scoreUpdate.update[prop]
    }
  }
  toJSON () {
    return JSON.stringify(this.getObj())
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  getObj () {
    return {
      objType: this.objType,
      docId: this.docId,
      playerDocId: this.playerDocId,
      roundDocId: this.roundDocId,
      tournamentDocId: this.tournamentDocId,
      roundDate: new Date(Number(this.roundDate)),
      roundStatus: this.roundStatus,
      roundName: this.roundName,
      courseLabel: this.courseLabel,
      course: this.course.getObj(),
      holeHandicaps: [...this.holeHandicaps],
      holePars: [...this.holePars],
      holeByHoleScores: this.holeByHoleScores,
      posted: this.posted,
      name: this.name,
      email: this.email,
      type: this.type,
      tee: this.tee,
      gender: this.gender,
      ghinNum: this.ghinNum,
      lastIndex: this.lastIndex,
      index: {...this.index},
      indexSource: this.indexSource,
      handicap: {...this.handicap},
      handicapDate: this.handicapDate===null ? null : new Date(Number(this.handicapDate)), 
      useGameIndex: this.useGameIndex,
      diff: {...this.diff},
      pcc: this.pcc,
      wd: this.wd,
      score: {...this.score},
      // netScore: {...this.netScore},
      scores: this.scores.map(s=>({...s})),
   
      payOut: {...this.payOut},
      pos: {...this.pos},
      skins: this.skins.map(s=>({...s})),
      skinsCount: this.skinsCount,
      kp: [...this.kp],
      kpDist: [...this.kpDist],
      kpCount: this.kpCount,
      kpUnit: this.kpUnit,
      dots: this.dots.map(s=>({...s})),
      dotsCount: this.dotsCount,
      totalBet: this.totalBet,
      totalPayout: this.totalPayout,

      exclude: this.exclude,
      // toPar: {...this.toPar},
      signUpDate: this.signUpDate===null?null:new Date(Number(this.signUpDate)),
      final: this.final,

      games: this.games.map(g=>({...g})),
      points: this.points,
      teeTimeRequest: this.teeTimeRequest
    }
  }
  setObj (data) {
    this.objType = data.objType,
    this.docId = data.docId,
    this.playerDocId = data.playerDocId
    this.roundDocId = data.roundDocId,
    this.tournamentDocId = data.tournamentDocId,
    this.roundDate = new Date(Number(data.roundDate))
    if(typeof data.roundDate === "string") this.roundDate = new Date(data.roundDate)
    this.roundStatus = data.roundStatus
    this.roundName = data.roundName
    this.courseLabel = data.courseLabel
    if (this.course === null) this.course = new Course()
    this.course.setObj(data.course)
    this.holeByHoleScores = data.holeByHoleScores
    this.holeHandicaps = data.holeHandicaps
    this.holePars = data.holePars
    this.posted = data.posted
    this.name = data.name
    this.email = data.email
    this.type = data.type
    this.tee = data.tee
    this.gender = data.gender
    this.ghinNum = data.ghinNum
    this.lastIndex = data.lastIndex
    this.index = {...data.index}
    this.indexSource = data.indexSource
    this.handicap = {...data.handicap}
    this.handicapDate = data.handicapDate === null ? null : new Date(Number(data.handicapDate))
    this.useGameIndex = data.useGameIndex
    this.diff = {...data.diff}
    this.pcc = data.pcc
    this.wd = data.wd
    this.score = {...data.score}
    // this.netScore = {...data.netScore}
    this.scores = data.scores.map(s=>({...s}))
 
    this.payOut = {...data.payOut}
    this.pos = {...data.pos}
    this.skins = data.skins.map(s=>({...s}))
    this.skinsCount = data.skinsCount
    this.kp = [...data.kp]
    this.kpDist = [...data.kpDist]
    this.kpCount = data.kpCount
    this.kpUnit = data.kpUnit
    this.dots = data.dots.map(s=>({...s}))
    this.dotsCount = data.dotsCount
    this.totalBet = data.totalBet
    this.totalPayout = data.totalPayout
    this.exclude = data.exclude,
    // this.toPar = {...data.toPar}
    this.signUpDate = data.signUpDate===null?null:new Date(Number(data.signUpDate))
    this.final = data.final
    this.games = data.games.map(g=>({...g}))
    this.points = data.points
    this.teeTimeRequest = data.teeTimeRequest
  }
  getData () {

    const data = {
      roundDocId: this.roundDocId,
      tournamentDocId: this.tournamentDocId,
      playerDocId: this.playerDocId,
      roundDate: DateToFSTimestamp(this.roundDate), // convert date string to Timestamp object
      roundStatus: this.roundStatus,
      roundName: this.roundName,
      courseLabel: this.courseLabel,
      holeHandicaps: this.holeHandicaps,
      holePars: this.holePars,
      posted: this.posted,
      name: this.name,
      email: this.email,
      type: this.type,
      tee: this._tee,
      gender: this.gender,
      ghinNum: this.ghinNum,
      lastIndex: this.lastIndex,
      index: this.index,
      indexSource: this.indexSource,
      handicap: this.handicap,
      handicapDate: this.handicapDate===null?null:DateToFSTimestamp(this.handicapDate),
      useGameIndex: this.useGameIndex,
      diff: this.diff===null ? {front: 0, back: 0, full18: 0} : this.diff,
      pcc: this.pcc,
      wd: this.wd,
      score: this.score,
      scores: this.scores,
    
      payOut: this.payOut,
      pos: this.pos,
      skins: this.skins,
      skinsCount: this.skinsCount,
      kp: this.kp,
      kpDist: this.kpDist,
      kpCount: this.kpCount,
      kpUnit: this.kpUnit,
      dots: this.dots,
      dotsCount: this.dotsCount,
      totalBet: this.totalBet,
      totalPayout: this.totalPayout,
      exclude: this.exclude,
  
      signUpDate: this.signUpDate===null?null:DateToFSTimestamp(this.signUpDate), // convert date string to Timestamp object
      final: this.final,
      games: this.games,
      teeTimeRequest: this.teeTimeRequest
    }
    if (this.points !== null) {
      data.points = this.points
    }

    return data

  }
  
  setData (data) {
    const pos = {}
    for (let prop in data.pos) {
      if (prop === 'stroke18') {
        pos['strokeFull18'] = data.pos['stroke18']
      }
      else {
        pos[prop] = data.pos[prop]
      }
    }
    const payOut = {}
    for (let prop in data.payOut) {
      if (prop === 'stroke18') {
        payOut['strokeFull18'] = data.payOut['stroke18']
      }
      else {
        payOut[prop] = data.payOut[prop]
      }
    }
    this.roundDocId = data.roundDocId
    this.tournamentDocId = getDataValue(data,'tournamentDocId',null)
    this.playerDocId = data.playerDocId
    this.roundDate = FSTimestampToDate(data.roundDate) // stored as Timestamp object
    this.roundStatus = data.roundStatus
    this.roundName = getDataValue(data,'roundName','')
    this.courseLabel = data.courseLabel
    this.holeHandicaps = getDataValue(data,'holeHandicaps',[])
    this.holePars = getDataValue(data,'holePars',[])
    this.posted = data.posted
    this.name = data.name
    this.email = data.email
    this.type = data.type
    this._tee = data.tee
    this.gender = getDataValue(data,'gender','M')
    this.ghinNum = data.ghinNum
    this.lastIndex = data.lastIndex
    this.index = data.index
    this.indexSource = data.indexSource
    this.handicap = data.handicap
    
    if (this.handicap !== null) {
      if (this.handicap.front === 'n/a') this.handicap.front = ''
      if (this.handicap.back === 'n/a') this.handicap.back = ''
      if (this.handicap.full18 === 'n/a') this.handicap.full18 = ''
    }

    this.handicapDate =  data.handicapDate===undefined || data.handicapDate===null ? null : FSTimestampToDate(data.handicapDate)
    this.useGameIndex = getDataValue(data,'useGameIndex',false)
    this.diff = data.diff
    this.pcc = getDataValue(data,'pcc',0)
    this.wd = data.wd
    this.score = data.score
    // this.netScore = data.netScore
    this.scores = data.scores
    this.payOut = payOut
    this.pos = pos
    this.skins = data.skins
    this.skinsCount = data.skinsCount
    this.kp = data.kp
    this.kpDist = getDataValue(data,'kpDist',[])
    this.kpCount = data.kpCount
    this.kpUnit = getDataValue(data,'kpUnit',12)
    this.dots = getDataValue(data,'dots', [])
    this.dotsCount = getDataValue(data,'dotsCount', 0)
    this.totalBet = data.totalBet
    this.totalPayout = data.totalPayout
    this.exclude = data.exclude
    // this.toPar = data.toPar
    this.signUpDate = data.signUpDate===undefined||data.signUpDate===null?null:FSTimestampToDate(data.signUpDate)
    this.final = data.final
    this.games = getDataValue(data,'games',[])
    this.points = getDataValue(data,'points', null)
    this.teeTimeRequest = getDataValue(data, 'teeTimeRequest', '')

    
  }
}

