import { fs } from './firebase'

export function newDate() {
// module.exports.newDate = function () {
    const dt = new Date()
    dt.setHours(0,0,0,0) // always return midnight of current day
    return dt
}

export function capitalizeWord  (str) {
// module.exports.capitalizeWord = function (str) {
    let result = ''
    
    if(str) {
        result = str.trim().toLowerCase().split(' ')
       
        result = result.map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1)
        }).join(' ')
       
    }
    return result
    
}

export function capitalizeFirst (str) {
// module.exports.capitalizeFirst = function (str) {
    let result = ''
    if(str) {
        if(str.length > 0) {
            result = str[0].toUpperCase() +  str.substr(1)
        }
    }
   
    return result
}

export function sortArrayNum (array, dir='asc') {
// module.exports.sortArrayNum = function (array, dir='asc') {
    array.sort((a, b) => a-b)
    if (dir === 'desc') {
        array.reverse()
    }
}

export function sortArrayObj (array, field, dir='asc') {
// module.exports.sortArrayObj = function (array, field, dir='asc') {
    array.sort((a,b) => (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0))
   
   
    // const min = Number.MIN_SAFE_INTEGER
    // array.sort( (a,b) => ( a[field] || min - b[field] || min ) )

    if (dir === 'desc') {
        array.reverse()
    }

    // if (dir == "asc") {
    //   this.leaderBoardItems.sort(function (a, b) {
    //     return (a[field] || "|||").toUpperCase().localeCompare((b[field] || "|||").toUpperCase())
    //   })
    // } else {
    //   this.leaderBoardItems.sort(function (a, b) {
    //     return (b[field] || "!!!").toUpperCase().localeCompare((a[field] || "!!!").toUpperCase())
    //   })
    // }

}

export const sumObjValues = obj => Object.values(obj).reduce((a, b) => a + b, 0)
// module.exports.sumObjValues = obj => Object.values(obj).reduce((a, b) => a + b, 0)



export function formatToPar (overUnder) {
// module.exports.formatToPar = function (overUnder) {
    let toPar = overUnder

    if (toPar > 0) toPar = '+' + toPar
    if (toPar === 0) toPar = 'E'

    return toPar
}

export function formatHandicapObj (handicap, side) {
    let val = ''
    if (handicap !== null) {
        val = formatHandicap (handicap[side])
    }
    return val
}
export function formatHandicap (val, fac=1.0) {
// module.exports.formatHandicap = function (val) {
    if (val === null || val === undefined) {
        val = ''
    }
    else {
        if (val < 0) {
            val = '+' + (-val)
        }
    }
    if (fac < 1) val += ` (${fac*100}%)`
    
    return val
}

export function formatHandicapSummary (handicapFull, indexSource, front=false, back=false) {
// module.exports.formatHandicapSummary = function  (handicapFull, indexSource, front=false, back=false) {
    let hdcp = ''
    if (handicapFull !== null) {
        hdcp = 'Hdcp ' + formatHandicap(handicapFull.full18) + (indexSource==='GHIN'?'*':'')
        let fbHdcp = ''
        if (front) {
            fbHdcp += formatHandicap(handicapFull.front)
        }
        if (back) {
            if (fbHdcp.length > 0) fbHdcp += '/'
            fbHdcp += formatHandicap(handicapFull.back)
        }
        if (fbHdcp.length > 0) {
            hdcp += ' (' + fbHdcp + ')'
        }
    }
   
    return hdcp
}

export function formatDist (dist, type) {
// module.exports.formatDist = function  (dist, type) {
    let label = ''
    if (dist === 0) {
        label = "0 ft"
    }
    else {
        let feet
        if (type === 'inch') {
            feet = truncate(dist / 12, 0)
        }
        else {
            feet = dist
        }
        
        if (feet > 0) label = feet + ' ft'
        const inches = dist - (feet*12)
        if (inches > 0) label += ' ' + inches + ' in'
    }
    

    return label
}

export function parClass (toPar) {
// module.exports.parClass = function  (toPar) {
    
    let classVal = 'has-text-centered has-text-weight-bold is-size-5'
    if (toPar < 0) classVal += ' has-text-danger'
    if (toPar > 0) classVal += ' has-text-info'
    return classVal
 
}

export function parClass2 (toPar) {
// module.exports.parClass2 = function  (toPar) {
    
    let classVal = 'has-text-centered has-text-weight-bold'
    if (toPar < 0) classVal += ' has-text-danger'
    if (toPar > 0) classVal += ' has-text-info'
    return classVal
 
}

export function truncate (numToBeTruncated, numOfDecimals) {
// module.exports.truncate = function  (numToBeTruncated, numOfDecimals) {
    var theNumber = numToBeTruncated.toString()
    var pointIndex = theNumber.indexOf('.')
    return Number(theNumber.slice(0, pointIndex > -1 ? ++numOfDecimals + pointIndex : undefined))
}

export function roundTo(value, decimals) {
// module.exports.roundTo = function (value, decimals) {
    let num = Math.round(value + 'e' + decimals);
    return Number(num + 'e' + -decimals);
  }

export function mode (array){
// module.exports.mode = function  (array){
    if(array.length === 0) return null
    var modeMap = {}
    var maxEl = array[0], maxCount = 1
    for(var i = 0; i < array.length; i++)
    {
        var el = array[i]
        if(modeMap[el] === null)
            modeMap[el] = 1
        else
            modeMap[el]++; 
        if(modeMap[el] > maxCount)
        {
            maxEl = el
            maxCount = modeMap[el]
        }
    }
    return maxEl
}
import numeral from "numeral"
// const numeral = require("numeral")

export function formatCurrency (val) {
// module.exports.formatCurrency = function  (val) {
    if (val === undefined) {
        return ''
    }
    else {
        const showFraction = (val % 1) !== 0
        const formatter = new Intl.NumberFormat('en-US', 
        {
            style: 'currency', 
            currency: 'USD', 
            currencySign: 'accounting',
            minimumFractionDigits: showFraction?2:0
        })
        
        return formatter.format(val)
        // return numeral(val).format("($0,0[.]00)")
    }
    
}

export function formatPercent (val) {
// module.exports.formatPercent = function  (val) {
    if (val === undefined) {
        return ''
    }
    else {
      
        const formatter = new Intl.NumberFormat('en-US', 
        {
            style: 'percent', 
            minimumFractionDigits: 0
        })
        
        return formatter.format(val)
      
    }
    
}
export function scoreCardHoleColumns (view='wide') {
// module.exports.scoreCardHoleColumns = function  (view='wide') {
    let cols = []
    if (view === 'wide') {
        cols = [
            {label: 'HOLE', field: 'hole', centered: true, headerClass:'has-background-black has-text-white'},
            {label: '1', field: 'hole1', centered: true, headerClass:'has-background-black has-text-white'},
            {label: '2', field: 'hole2', centered: true, headerClass:'has-background-black has-text-white'},
            {label: '3', field: 'hole3', centered: true, headerClass:'has-background-black has-text-white'},
            {label: '4', field: 'hole4', centered: true, headerClass:'has-background-black has-text-white'},
            {label: '5', field: 'hole5', centered: true, headerClass:'has-background-black has-text-white'},
            {label: '6', field: 'hole6', centered: true, headerClass:'has-background-black has-text-white'},
            {label: '7', field: 'hole7', centered: true, headerClass:'has-background-black has-text-white'},
            {label: '8', field: 'hole8', centered: true, headerClass:'has-background-black has-text-white'},
            {label: '9', field: 'hole9', centered: true, headerClass:'has-background-black has-text-white'},
            {label: 'OUT', field: 'out', centered: true, headerClass:'has-background-black has-text-white'},
            {label: '10', field: 'hole10', centered: true, headerClass:'has-background-black has-text-white'},
            {label: '11', field: 'hole11', centered: true, headerClass:'has-background-black has-text-white'},
            {label: '12', field: 'hole12', centered: true, headerClass:'has-background-black has-text-white'},
            {label: '13', field: 'hole13', centered: true, headerClass:'has-background-black has-text-white'},
            {label: '14', field: 'hole14', centered: true, headerClass:'has-background-black has-text-white'},
            {label: '15', field: 'hole15', centered: true, headerClass:'has-background-black has-text-white'},
            {label: '16', field: 'hole16', centered: true, headerClass:'has-background-black has-text-white'},
            {label: '17', field: 'hole17', centered: true, headerClass:'has-background-black has-text-white'},
            {label: '18', field: 'hole18', centered: true, headerClass:'has-background-black has-text-white'},
            {label: 'IN', field: 'in', centered: true, headerClass:'has-background-black has-text-white'},
            {label: 'TOT', field: 'total', centered: true, headerClass:'has-background-black has-text-white'}
          ]
    }
    else {
        cols = [
            {label: 'HOLE', field: 'hole', centered: true},
            {label: 'Par', field: 'par', centered: true},
            {label: 'Yards', field: 'yards', centered: true},
            {label: 'Hdcp', field: 'handicap', centered: true}
        ]
    }
    return cols
}

export function shuffle (array) {
// module.exports.shuffle = function  (array) {
    let currentIndex = array.length
    let temporaryValue
    let randomIndex

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1

        // And swap it with the current element.
        temporaryValue = array[currentIndex]
        array[currentIndex] = array[randomIndex]
        array[randomIndex] = temporaryValue
    }

    return array
}


export function copyToClipboard (str)  {
// module.exports.copyToClipboard = function  (str)  {
    const el = document.createElement('textarea')  // Create a <textarea> element
    el.value = str                                // Set its value to the string that you want copied
    el.setAttribute('readonly', '')                // Make it readonly to be tamper-proof
    el.style.position = 'absolute'                 
    el.style.left = '-9999px'                      // Move outside the screen to make it invisible
    document.body.appendChild(el)                  // Append the <textarea> element to the HTML document
    const selected =            
        document.getSelection().rangeCount > 0        // Check if there is any content selected previously
        ? document.getSelection().getRangeAt(0)     // Store selection if found
        : false                                    // Mark as false to know no selection existed before
    el.select()                                    // Select the <textarea> content
    document.execCommand('copy')                   // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el)                  // Remove the <textarea> element
    if (selected) {                                 // If a selection existed before copying
        document.getSelection().removeAllRanges()    // Unselect everything on the HTML document
        document.getSelection().addRange(selected)   // Restore the original selection
    }
}

export function calcHCDiff (score, courseRating, courseSlope) {
// module.exports.calcHCDiff = function  (score, courseRating, courseSlope) {
    // const courseRating = course.getRating(this.tee, side)
    // const courseSlope = course.getSlope(this.tee, side)
    return roundTo((score - courseRating) * 113 / courseSlope,1)
}



// export function updateHandicap (score, updatedHandicap, round) {
  
//     let updatedNetScore
//     if (round.holeByHoleScores) {
//         updatedNetScore = {
//             front: score.score.front===''?'':score.score.front - score.handicapCompleted('front', round.getStrokeHandicapFac('front')),
//             back: score.score.back===''?'':score.score.back - score.handicapCompleted('back', round.getStrokeHandicapFac('back')),
//             full18: score.score.full18===''?'':score.score.full18 - score.handicapCompleted('full18', round.getStrokeHandicapFac('full18'))
//         }
//     }
//     else {
//         updatedNetScore = updateNetScore (score.score, score.netScore, updatedHandicap)
//     }
//     const updatedToPar = {
//         front: updatedNetScore.front===''?'':(updatedNetScore.front - round.course.getPar(score.tee, 'front')),
//         back: updatedNetScore.back===''?'':(updatedNetScore.back - round.course.getPar(score.tee, 'back')),
//         full18: updatedNetScore.full18===''?'':(updatedNetScore.full18 - round.course.getPar(score.tee, 'full18'))
//     }
   

//     const update = {
//         handicap: updatedHandicap,
//         netScore: updatedNetScore,
//         toPar: updatedToPar
       
//     }

//     return update
 
// }
// export function updateNetScore (grossScore, netScore, handicap) {
  
//     const net = {...netScore}

//     if (handicap !== null) {
//         if(grossScore.front > 0) {
//             net.front = grossScore.front - handicap.front
//         }
//         else {
//             net.front = grossScore.front
//         }

//         if(grossScore.back > 0) {
//             net.back = grossScore.back - handicap.back
//         }
//         else {
//             net.back = grossScore.back
//         }

//         if (grossScore.full18 > 0) {
//             net.full18 = grossScore.full18 - handicap.full18
//         }
//         else {
//             net.full18 = grossScore.full18
//         }
//     }
  
    
  
      
    
      
//     return net
// }

// export function updateGrossScore (grossScore) {
//     const gross = {...grossScore}

//     if (gross.back === '') {
//         gross.full18 = gross.front
//     }
//     else if (gross.front === '') {
//         gross.full18 = gross.back
//     }
//     else {
//         gross.full18 = gross.front + gross.back
//     }

//     return gross
// }

export function formatScore (par, score, netScore) {

    let label = netScore
    if (score !== netScore)  label = 'Net '
    switch (netScore) {
        case par+4:
        case par+4.5:
            label += 'Quad Bogey - seriously?'
            break
        case par+3:
        case par+3.5:
            label += 'Triple Bogey'
            break
        case par+2:
        case par+2.5:
            label += 'Double Bogey'
            break
        case par+1:
        case par+1.5:
            label += 'Bogey'
            break
        case par:
        case par+.5:
            label += 'Par'
            break 
        case par-1:
        case par-.5:
            label += 'Birdie'
            break
        case par-2:
        case  par-1.5:
            label += 'Eagle'
            break
        case par-3:
        case par-2.5:
            label += 'Double Eagle'
            break
        default:
            label = score + '/' + netScore
    }
   
    return label
  }

export function formatPayout(val, game) {
// module.exports.formatPayout = function (val, game) {
    let formattedVal = ""
    if ( game.prop === 'stroke') {
        if (game.isStableford) {
            if (game.useQuota) {
                formattedVal = formatToPar(val)
            }
            else {
                formattedVal = val + " Points"
            }
            
        }
        else {
            formattedVal = formatToPar(val)
        }
        
    } else {
        formattedVal = val
    }
    return formattedVal
  
}

export function nth(val) {
// module.exports.nth = function (val) {
    let n
    let isTie = false
    if (val === undefined) return ''
    
    if (typeof val === 'string') {
        if (val.charAt(0) === 'T') {
            n = Number(val.substr(1))
            isTie = true
        }
        else {
            n = Number(val)
        }
    }
    else {
        n = val
    }
    
    const s=["th","st","nd","rd"]
    const v=n%100
    let r = n+(s[(v-20)%10]||s[v]||s[0])
  
    if (isTie) r = 'T' + r
    return r
}

export function isEmpty(obj) { 
// module.exports.isEmpty = function (obj) { 
    for (let x in obj) { return false }
    return true
}

export function uniqueId () {
// module.exports.uniqueId = function  () {
    // desired length of Id
    const idStrLen = 32
    // always start with a letter -- base 36 makes for a nice shortcut
    let idStr = (Math.floor((Math.random() * 25)) + 10).toString(36) + "_"
    // add a timestamp in milliseconds (base 36 again) as the base
    idStr += (new Date()).getTime().toString(36) + "_"
    // similar to above, complete the Id using random, alphanumeric characters
    do {
        idStr += (Math.floor((Math.random() * 35))).toString(36)
    } while (idStr.length < idStrLen)

    return idStr
}


export function uniqueArray(array, propertyName) {
// module.exports.uniqueArray = function (array, propertyName) {
    return array.filter((e, i) => array.findIndex(a => a[propertyName] === e[propertyName]) === i)
 }

export function getDataValue(data, field, defaultValue) {
// module.exports.getDataValue = function (data, field, defaultValue) {
    return data[field] === undefined ? defaultValue : data[field]
}

export function isSafari() {
// module.exports.isSafari = function () {
    return /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification))
}  

export function DateToFSTimestamp (date) {
    return fs.Timestamp.fromDate(date)
}

export function FSTimestampToDate (fsTimestamp) {
    return fsTimestamp.toDate()
}

export function gamesPayoutArray (games, payOutItem) {
    const payOutArray = []
    let score
    let game
    // for (let game of this.games.aGames) {
    for (let gameId in payOutItem.payOut) {
      if (gameId === 'leftover') {
        payOutArray.push({
          name: 'Pool Leftover',
          gameProp: '',
          scoreVal: '',
          score: '',
          payOut: formatCurrency(payOutItem.payOut[gameId],true)
        })
        
      }
      else {
        if (payOutItem.payOut[gameId] > 0) {
          game = games.get(gameId)
          if (game === null) {
            console.log('payouts game not found', gameId, games, payOutItem)
          }
          else
          {
            if (game.prop === 'stroke'){
                score = nth(payOutItem.pos[game.id]) + ' (' + formatPayout(payOutItem.score[game.id], game) + ')'
              }
              else {
                score = payOutItem.score[game.id]
              }
              
              payOutArray.push({
                name: game.name,
                gameProp: game.prop,
                scoreVal: payOutItem.score[game.id],
                score: score,
                payOut: formatCurrency(payOutItem.payOut[game.id],true)
              })
          }
          
        }
        
      }
    }
    return payOutArray
  }

  export function handicapDotsLabel (strokes) {
    let label = ''
    if (strokes > 0) {
        label = '•'.repeat(strokes)
    }
    else if (strokes < 0) {
        label = '+'.repeat(-strokes)
    }
    return label
  }

  export function getRandomScore(par) {
    const min = par - 1
    const max = par + 3
    return Math.floor(Math.random() * (max - min) + min)
  }

  export function isNumeric(num) {
    return (typeof(num) === 'number' || typeof(num) === "string" && num.trim() !== '') && !isNaN(num);
  } 
