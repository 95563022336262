

import Vue from 'vue'
import App from './App'
import Buefy from 'buefy'
import Vuelidate from 'vuelidate'
import Vuex from 'vuex'
import Cleave from 'cleave.js'
import VueNumeric from 'vue-numeric'
import VueDragDrop from 'vue-drag-drop'
// import VueTimepicker from 'vue2-timepicker'
// import 'vue2-timepicker/dist/VueTimepicker.css'

// import VueCleaveDirective from 'vue-cleave-directive'

import { MediaQueries, CommonBands } from 'vue-media-queries'

import '@mdi/font/css/materialdesignicons.css'

import router from './router'
import store from './store'
import { isSafari } from './lib'



import { auth } from './firebase'
// import wb from "./registerServiceWorker"
// import './registerServiceWorker'
// Vue.prototype.$workbox = wb


Vue.config.productionTip = false
Vue.prototype.$isSafari = isSafari()

const mediaQueries = new MediaQueries({
  bands: CommonBands.Bulma
})

Vue.use(Buefy)
Vue.use(Vuelidate)
Vue.use(Vuex)
Vue.use(VueNumeric)
Vue.use(VueDragDrop)

Vue.use(mediaQueries)
// Vue.use(VueCleaveDirective)

// Vue.directive('cleave', {
//   bind(el, binding) {
//     const input = el.querySelector('input')
//     input._vCleave = new Cleave(input, binding.value)
//   },
//   unbind(el) {
//     const input = el.querySelector('input')
//     input._vCleave.destroy()
//   }
// })

Vue.directive('cleave', (el, binding) => {
  const input = el.querySelector('input')
  // eslint-disable-next-line
  new Cleave(input, binding.value)
})
// Vue.directive('focus', {
//   // When the bound element is inserted into the DOM...
//   inserted: function (el) {
//     // Focus the element
//     el.focus()
//   }
// })


Vue.mixin(CommonBands.Bulma.mixin)

let app

store.dispatch('auth/loadAppSettings')

auth.onAuthStateChanged(user => {

   store.commit('auth/authStateChanged', user)
  if (!app) {

    app = new Vue({
      el: "#app",
      mediaQueries,
      mixins: [CommonBands.Bulma.mixin],
      router,
      store,
      render: h => h(App)
    })
  }

})
