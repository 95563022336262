<template>
  <div>
    <div class="level is-mobile  is-marginless">
      <div class="level-left">
        <div class="level-item">
          <p class="label is-size-5">Season</p>
        </div>
        <div class="level-item">
          <b-select :value="currentSeasonStandings.name" @input="value=>refreshStandings(value)">
            <option
              v-for="(season,index) in seasonsArray"
              :value="season.name"
              :key="index"
            >{{ season.name }}</option>
          </b-select>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-dropdown :triggers="['hover']" position="is-bottom-left">
            <button class="button" slot="trigger">
              <span>Export</span>
              <b-icon icon="menu-down"></b-icon>
            </button>
            <b-dropdown-item value="exportResults" @click="exportResults">Standings</b-dropdown-item>
            <b-dropdown-item value="exportDetails" @click="exportDetails">Round Details</b-dropdown-item>
    
          </b-dropdown>
        </div>
      </div>
    </div>

    
    <div v-if="isMobile">
      <table class="table" width="100%">
        <thead>
          <tr>
            <th>Name</th>
            <th>Rounds</th>
            <th>Winnings</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(result, index) in standings" :key="index" @click="openResultDetails(result)">
            <td>
              {{result.name}}
            </td>
            <td class="text-center">
              {{result.numRounds}}
            </td>
            <td class="text-center">
              {{formatCurrency(result.netWinnings)}}
            </td>
            <td >
              <a >
                <b-icon icon="chevron-right"/>
              </a>
              
            </td>
          </tr>
        </tbody>
      </table>
    </div>
 
   
    <div v-else class="b-table table-wrapper">
      
      <table class="table">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th
              v-for="(game,index) in standingsGames"
              :key="index"
              class="text-center vert-bottom"
              :colspan="2"
            >{{ game.name }}</th>
            <th class="text-center vert-bottom" colspan="2">Net Winnings</th>

          </tr>
        </thead>
        <thead>
          <tr>
            <th></th>
            <th class="vert-bottom">
              Name
            </th>
            <th class="text-center vert-bottom">Rounds</th>
            <th class="text-center vert-bottom">Total Bet</th>
            <template v-for="(game, index) in standingsGames" >
              <template>
                <th :key="index"
                  class="text-center vert-bottom"
                >{{ (game.prop === 'stroke') ? 'Avg Net' : 'Number' }}</th>
                <th class="text-center vert-bottom" :key="index+standingsGames.length">Payout</th>
            </template>
            </template>

            <th class="text-center vert-bottom">Total</th>
            <th class="text-center vert-bottom">Per Round</th>
          
          </tr>
        </thead>
        <tbody>
          <template v-for="(result, index) in standings">
            <template>
              <tr :key="index">
                <td class="chevron-cell" width="30">
                  <a
                    
                    role="button"
                    @click.stop="toggleDetails(result)">
                    <b-icon
                      icon="chevron-right"
                      both
                      :class="{'is-expanded': isVisibleDetailRow(result)}"/>
                  </a>
                </td>
                <td>{{ result.name }}</td>
                <td width="80" class="text-center">{{ result.numRounds }}</td>
                <td width="80" class="text-center">{{ formatCurrency(result.totalWagered) }}</td>
                <template v-for="(game, index2) in standingsGames">
                  <template>
                    <td
                      :key="index2"
                      width="80"
                      class="text-center"
                      >
                      {{ (game.prop === 'stroke') ? result.avgScore[game.name] : result.count[game.name] }}
                      </td>
                    <td
                      :key="game.id+index2"
                      width="80"
                      class="text-center"
                      >
                      {{ formatCurrency(result.payOut[game.name]) }}
                    </td>
                  </template>
                </template>
                <td :class="result.netWinnings>0?'has-text-success has-text-weight-bold':'has-text-danger has-text-weight-bold'" width="80" style="text-align: center;">{{ formatCurrency(result.netWinnings) }}</td>
                <td width="80" class="text-center">{{ formatCurrency(result.netPerRound, true) }}</td>

              </tr>
            </template>
            <tr v-if="isVisibleDetailRow(result)" class="detail" :key="index+'d'">
              <td :colspan="(standingsGames.length*2)+6">
                <div class="detail-container is-paddingless">
                   <template v-if="isMobile">
                    <table class="table">
                      <tbody>
                        <tr v-for="(userScore, index) in postedScoresArray(result.playerDocId)" :key="index" @click="openRoundDetails(userScore)">
                          <td>
                            <p>{{userScore.formattedDate + (userScore.wd ? ' (Withdrawn)':'')}}</p>
                            <p class="is-size-7">{{userScore.courseLabel}}</p>
                          </td>
                          <td>
                            {{ userScore.scoreFull18==='' ? '' : userScore.scoreFull18 + ' (' + formatToPar(userScore.toPar.full18) + ')'}}
                          </td>
                          <td >
                            <a >
                              <b-icon icon="chevron-right"/>
                            </a>
                            
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </template>
                  <template v-else>

                    <b-table
                      :data="postedScoresArray(result.playerDocId)" 
                      :mobile-cards="false"
                      default-sort="roundDate" 
                      default-sort-direction="DESC"
                      detailed
                      detail-key="docId">
                      
                  
                        <b-table-column
                          class="vcenter"
                          field="roundDate"
                          label="Date"
                          sortable
                          v-slot="props"
                        >
                          <p>{{props.row.formattedDate + (props.row.wd ? ' (Withdrawn)':'')}}</p>
                        </b-table-column>

                        <b-table-column
                          class="vcenter"
                          field="courselLabel"
                          label="Course"
                          v-slot="props"
                        >
                          <p>{{props.row.courseLabel}}</p>

                        </b-table-column>

                        <b-table-column
                          class="vcenter"
                          label="Index"
                          width="80"
                          centered
                          field="index"
                          sortable
                          :visible="!isMobile"
                          v-slot="props"
                        >{{ formatHandicap(props.row.index.full18)}}</b-table-column>
                        <b-table-column
                          class="vcenter"
                          label="Score"
                          width="80"
                          field="scoreFull18"
                          sortable
                          centered
                          v-slot="props"
                        >{{ props.row.wd ? '' : props.row.score.full18}}</b-table-column>
                        <b-table-column
                          class="vcenter"
                          label="Handicap"
                          width="80"
                          centered
                          field="handicap"
                          sortable
                          v-slot="props"
                        >{{ formatHandicapObj(props.row.handicap,'full18') + ' (' + props.row.tee + ')'}}</b-table-column>
                        <b-table-column
                          class="vcenter"
                          label="Net Score"
                          width="120"
                          centered
                          field="netScoreFull18"
                          sortable
                          v-slot="props"
                        ><p class="has-text-weight-bold">{{ props.row.wd ? '' : props.row.netScore.full18}}</p></b-table-column>
                        <b-table-column
                          class="vcenter"
                          label="To Par"
                          width="90"
                          centered
                          field="toPar"
                          sortable
                          v-slot="props"
                        ><p :class=" props.row.wd ? '' : (props.row.toPar.full18<0 ? 'has-text-danger':'')">{{ props.row.wd ? '' : formatToPar(props.row.toPar.full18) }}</p></b-table-column>
                        <b-table-column
                          class="vcenter"
                          label="Payout"
                          width="80"
                          centered
                          field="totalPayout"
                          sortable
                          v-slot="props"
                        ><p :class="props.row.totalPayout>0?'has-text-success':''">{{ formatCurrency(props.row.totalPayout)}}</p>
                        </b-table-column>
                      
                  
                      <template slot="detail" slot-scope="props">
                        <round-details :userScore="props.row" :groupDocId="group.docId" />
                      
                      </template>
                    </b-table>
                  </template>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      
    </div> 
    <b-loading is-full-page :active.sync="isLoadingStandings" :can-cancel="true"></b-loading>

    <b-modal 
      :active.sync="showResultDetails"
      has-modal-card 
      full-screen
      :can-cancel="false"
      :style="isMobile?'z-index:100;':''"
    >
      <div class="modal-card" style="width:auto">
        <div class="modal-body">
          <div class="level is-mobile has-margin-bottom-5" @click="showResultDetails=false">
            <div class="level-left">
              <div class="level-item">
                <a class="has-margin-top-5" >
                  <b-icon icon='chevron-left'></b-icon>
                </a>
              </div>
              <div class="level-item">
                <p class="subtitle">{{resultDetails?resultDetails.name:''}}</p>
              </div>
            </div>
          </div>
          
          <result-details :result="resultDetails" :games="group.games" />
        </div>
      </div>
      
    </b-modal>
    <b-modal 
      :active.sync="showRoundDetails"
      has-modal-card 
      full-screen
      style="z-index:100;"
      :can-cancel="false">
      <div class="modal-card" style="width:auto">
        <div class="modal-body">
          <div class="level is-mobile" @click="showRoundDetails=false">
            <div class="level-left">
              <div class="level-item">
                <a class="mt-5" >
                  <b-icon icon='chevron-left'></b-icon>
                </a>
              </div>
              <div class="level-item">
                <p class="subtitle">Round Details</p>
              </div>
            </div>
          </div>
          
          <round-details :userScore="roundDetails" :groupDocId="group.docId"/>
        </div>
      </div>
       
    </b-modal>
    <b-loading is-full-page :active.sync="isLoading"></b-loading>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

import { formatCurrency, formatToPar, sortArrayObj, formatHandicap, formatHandicapObj } from '../../lib'

import Papa from 'papaparse'
import FileSaver from 'file-saver'
import ResultDetails from '../Group/ResultDetails'
import RoundDetails from '../User/RoundDetails'
import ScoresController from '../../controllers/score'


export default {
  data() {
    return {
      showResultDetails: false,
      showRoundDetails: false,
      resultDetails: null,
      visibleDetailRows: [],
      postedScores: {},
      roundDetails: null,
      isLoading: false
    }
  },
  components: {
    'result-details' : ResultDetails,
    'round-details' : RoundDetails
  },

 
  computed: {
   ...mapGetters('group', 
   [
     'group',
     'tab',
     'seasonStandings',
     'currentSeasonStandings',
     'standingsGames'
    ]),
   
    standings () {
  
      if (this.seasonStandings === undefined) {
        this.refreshStandings(this.currentSeasonStandings)
      }
      return this.seasonStandings
    },
  

    seasonsArray () {
      let seasons = []
      if (this.group) seasons = this.group.seasons.aSeasons
      return seasons
    },
    openRoundDetails (userScore) {
      this.roundDetails = userScore
      this.showRoundDetails = true
    },
    isLoadingStandings: {
      get () {
        return this.$store.getters['group/isLoadingStandings']
      },
      set (val) {
        this.$store.commit('group/isLoadingStandings', val)
      }
    }
  },
  methods: {
    formatCurrency,
    formatToPar,
    formatHandicap, formatHandicapObj,

    postedScoresArray (playerDocId) {
      let scoresArray = []
      if (playerDocId in this.postedScores) {
        scoresArray = this.postedScores[playerDocId].aScores
      }
      return scoresArray
    },
    async loadPostedScores (playerDocId) {
   
      const queryInfo = {
        groupDocId: this.group.docId,
        playerDocId,
        season: this.currentSeasonStandings
      }
      const response = await ScoresController.getUserPostedScores(queryInfo)
      if (response.errorMsg.length > 0) {
          this.$store.commit('group/errorMsg', response.errorMsg)
      } 
      else {
        this.postedScores[playerDocId] = response.postedScores
      }
  
    },

    async refreshStandings (seasonName) {
    
      const season = this.group.seasons.aSeasons.find(s=>s.name===seasonName)
      if (season === undefined) {
       
      }
      else {
       await this.$store.dispatch('group/createSeasonStandings', season)
      
      }
      this.postedScores = {}
    },
    async toggleDetails(result) {
      const found = this.isVisibleDetailRow(result)
      if (found) {
        const i = this.visibleDetailRows.indexOf(result.playerDocId)
        this.visibleDetailRows.splice(i, 1)
        
      } else {
        await this.loadPostedScores(result.playerDocId)
        this.visibleDetailRows.push(result.playerDocId)
      }
    
    },
    isVisibleDetailRow(result) {
      return this.visibleDetailRows.indexOf(result.playerDocId) >= 0
   
    },

    openResultDetails (result) {
      this.resultDetails = result
      this.showResultDetails = true
    },
   
    exportResults () {
      const headers = [
        'Name',
        'Rounds',
        'Total Bet'
      ]
      this.standingsGames.forEach(game => {
        if (game.prop === 'stroke' && game.side === 'full18') {
          headers.push('Stroke 18 Net')
          headers.push('Stroke 18 Payout')
        }
        else if ( game.prop === 'stroke' && game.side === 'front') {
          headers.push('Stroke Front Net')
          headers.push('Stroke Front Payout')
        }
        else if ( game.prop === 'stroke' && game.side === 'back') {
          headers.push('Stroke Back Net')
          headers.push('Stroke Back Payout')
        }
        else if ( game.prop === 'kp') {
          headers.push('KP')
          headers.push('KP Payout')
        }
        else if ( game.prop === 'skins') {
          headers.push('Skins')
          headers.push('Skins Payout')
        }
        else if ( game.prop === 'dots') {
          headers.push('Dots')
          headers.push('Dots Payout')
        }
      })
      headers.push('Net Winnings')
      headers.push('Net per Round')

      const report = this.standings.map(result => {
        const line = [
          result.name,
          result.numRounds,
          this.formatCurrency(result.totalWagered)
        ]
        this.standingsGames.forEach(game => {
          if (game.prop === 'stroke') {
            line.push(result.avgScore[game.name])
          }
          else {
            line.push(result.count[game.name])
          }
          line.push(this.formatCurrency(result.payOut[game.name]))
        })
        line.push(this.formatCurrency(result.netWinnings))
        line.push(this.formatCurrency(result.netPerRound))

        return line
      })
      const csv = Papa.unparse({
        fields: headers,
        data: report
      })

      const blob = new Blob([csv], {type: "text/plain;charset=utf-8"})
      FileSaver.saveAs(blob, `${this.group.name} ${this.currentSeasonStandings.name} Standings.csv`)
    },
    async exportDetails () {
      let reportScores = []
      let response
      let queryInfo
      this.isLoading = true
   
      queryInfo = {
        groupDocId: this.group.docId,
        season: this.currentSeasonStandings
      }
      response = await ScoresController.getPostedScores(queryInfo)
      if (response.errorMsg.length > 0) {
          this.$store.commit('group/errorMsg', response.errorMsg)
      } 
      else {
        reportScores = reportScores.concat(response.postedScores.aScores)
      }
   
      sortArrayObj(reportScores, 'roundDate', 'asc')

      const headers = [
        'Round Date',
        'Course',
        'Name',
        'Email',
        'Ghin #',
        'WD',
        'Index',
        'Tee',
        'Handicap Front',
        'Handicap Back',
        'Handicap 18',
        'Score Front',
        'Score Back',
        'Score 18',
        'Net Score Front',
        'Net Score Back',
        'Net Score 18',
        'Excluded',
        'PCC',
        'Diff Front',
        'Diff Back',
        'Diff 18',
        'Bet',
        'Total Payout'
      ]
      this.standingsGames.forEach(game => {
        if ( game.prop === 'skins') {
          headers.push('Skins')
          headers.push('Skins Payout')
        }
        else if ( game.prop === 'kp') {
          headers.push('KP')
          headers.push('KP Payout')
        }
        else if ( game.prop === 'dots') {
          headers.push('Dots')
          headers.push('Dots Payout')
        }
        else if (game.prop === 'stroke' && game.side === 'full18') {
          headers.push('Stroke 18 To Par')
          headers.push('Stroke 18 Payout')
        }
        else if ( game.prop === 'stroke' && game.side === 'front') {
          headers.push('Stroke Front To Par')
          headers.push('Stroke Front Payout')
        }
        else if ( game.prop === 'stroke' && game.side === 'back') {
          headers.push('Stroke Back To Par')
          headers.push('Stroke Back Payout')
        }
       
      })

      const report = reportScores.map(score => {
        const line = [
          score.formattedDate,
          score.courseLabel,
          score.name,
          score.email,
          score.ghinNum,
          score.wd,
          score.index.full18,
          score.tee,
          score.handicap.front,
          score.handicap.back,
          score.handicap.full18,
          score.score.front,
          score.score.back,
          score.score.full18,
          score.netScore.front,
          score.netScore.back,
          score.netScore.full18,
          score.excluded,
          score.pcc,
          score.diff.front,
          score.diff.back,
          score.diff.full18,
          this.formatCurrency(score.totalBet),
          this.formatCurrency(score.totalPayout)
        ]
        this.standingsGames.forEach(game => {
          if ( game.prop === 'skins') {
            line.push(score.skinsCount)
            if (game.id in score.payOut) {
              line.push(this.formatCurrency(score.payOut[game.id]))
            }
            else {
              line.push(0)
            }
          }
          else if ( game.prop === 'kp') {
            line.push(score.kpCount)
            if (game.id in score.payOut) {
              line.push(this.formatCurrency(score.payOut[game.id]))
            }
            else {
              line.push(0)
            }
          }
          else if ( game.prop === 'dots') {
            line.push(score.dotsCount)
            if (game.id in score.payOut) {
              line.push(this.formatCurrency(score.payOut[game.id]))
            }
            else {
              line.push(0)
            }
          }
          else if (game.prop === 'stroke' && game.side === 'full18') {
            line.push(score.toPar.full18)
            if (game.id in score.payOut) {
              line.push(this.formatCurrency(score.payOut[game.id]))
            }
            else {
              line.push(0)
            }
          }
          else if ( game.prop === 'stroke' && game.side === 'front') {
            line.push(score.toPar.front)
            if (game.id in score.payOut) {
              line.push(this.formatCurrency(score.payOut[game.id]))
            }
            else {
              line.push(0)
            }
          }
          else if ( game.prop === 'stroke' && game.side === 'back') {
            line.push(score.toPar.back)
            if (game.id in score.payOut) {
              line.push(this.formatCurrency(score.payOut[game.id]))
            }
            else {
              line.push(0)
            }
          }
        
        })

        return line
      })

      const csv = Papa.unparse({
        fields: headers,
        data: report
      })

      const blob = new Blob([csv], {type: "text/plain;charset=utf-8"})
      FileSaver.saveAs(blob, `${this.group.name} ${this.currentSeasonStandings.name} Round Details.csv`)

      this.isLoading = false
    }
    
   
  }
};
</script>

<style>
.vert-middle {
  vertical-align: middle !important;
}
.vert-bottom {
  vertical-align: bottom !important;
}
.text-center {
  text-align: center !important;
}
</style>
