<template>
  <div>
    <template v-if="isMobile">
      <p class="is-size-6 has-text-weight-bold has-margin-left-10">FRONT</p>
      <div class="b-table">
        <table class="table is-bordered is-narrow center-table">
          <thead>
            <tr>
              <th class="is-size-7 has-text-centered header-cell"><p class="header-text-color">HOLE</p></th>
              <th class="is-size-7 has-text-centered header-cell" v-for="i in 9" :key="i">
                <p class="header-text-color" >{{ i }}</p>
              </th>
              <th class="is-size-7 has-text-centered header-cell"><p class="header-text-color">OUT</p></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="is-size-7 has-text-centered">Hdcp</td>
              <td class="is-size-7 has-text-centered" v-for="i in 9" :key="i">
                {{ score.getHandicapHoleAlloc(i)}}
              </td>
              <td class="is-size-7"></td>
            </tr>
            <tr>
              <td class="is-size-7 has-text-centered">Strokes</td>
              <td class="is-size-7 has-text-centered" v-for="i in 9" :key="i">
                {{ getHandicapDots(i, score) }}
              </td>
              <td class="is-size-7"></td>
            </tr>
            <tr>
              <td class="is-size-7 has-text-centered">Par</td>
              <td class="is-size-7 has-text-centered" v-for="i in 9" :key="i">
                {{ score.getHolePar(i) }}
              
              </td>
              <td class="is-size-7 has-text-centered">{{ score.getPar('front') }}</td>
            </tr>
           
            <tr>
              <td class="is-size-7 has-text-centered">Score</td>
              <td class=" text-center" :class="formatScoreCell(score, i)" v-for="i in 9" :key="i">
                <p class="is-size-7" :class="formatScore(score, i)">{{score.objType==='teamScore'?score.netHoleScore(i):score.getHoleScore(i)}}</p>
              </td>
              <td class="is-size-7 has-text-centered">{{ score.objType==='teamScore'?score.netScore('front'):score.score.front }}</td>
            </tr>

            <tr v-if="round.games.hasStablefordGame()">
              <td class="is-size-7 has-text-centered">Points</td>
              <td class=" text-center" v-for="i in 9" :key="i">
                <p class="is-size-7">{{score.getHoleNetPoints(i)}}</p>
              </td>
              <td class="is-size-7 has-text-centered">{{ score.getNetPoints('front') }}</td>
            </tr>

            <tr v-if="round.games.hasGame('dots')">
              <td class="is-size-7 has-text-centered">Dots</td>
              <td class=" text-center" v-for="i in 9" :key="i">
                <p class="is-size-7" >{{score.getDots(i)}}</p>
              </td>
              <td class="is-size-7 has-text-centered">{{ score.totalDots('front') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      
      <p class="is-size-6 has-text-weight-bold has-margin-left-10 has-margin-top-10">BACK</p>
      <div class="b-table">
        <table class="table is-bordered is-narrow center-table">
          <thead>
            <tr>
              <th class="is-size-7 has-text-centered header-cell"><p class="header-text-color">HOLE</p></th>
              <th class="is-size-7 has-text-centered header-cell" v-for="i in 9" :key="i+9">
                <p class="header-text-color" >{{ i+9 }}</p>
              </th>
              <th class="is-size-7 has-text-centered header-cell"><p class="header-text-color">IN</p></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="is-size-7 has-text-centered">Hdcp</td>
              <td class="is-size-7 has-text-centered" v-for="i in 9" :key="i+10">
                {{ score.getHandicapHoleAlloc(i+9) }}
              </td>
              <td class="is-size-7"></td>
            </tr>
            <tr >
              <td class="is-size-7 has-text-centered">Strokes</td>
              <td class="is-size-7 has-text-centered" v-for="i in 9" :key="i">
                {{ getHandicapDots(i+9, score) }}
              </td>
              <td class="is-size-7"></td>
            </tr>
            <tr>
              <td class="is-size-7 has-text-centered">Par</td>
              <td class="is-size-7 has-text-centered" v-for="i in 9" :key="i+10">
                {{ score.getHolePar(i+9) }}
              </td>
              <td class="is-size-7 has-text-centered">{{ score.getPar('back') }}</td>
            </tr>
            
            <tr>
              <td class="is-size-7 has-text-centered" >Score</td>
              <td class="text-center" v-for="i in 9" :key="i+10" :class="formatScoreCell(score, i+9)" >
                <p class="is-size-7" :class="formatScore(score, i+9)">{{score.objType==='teamScore'?score.netHoleScore(i+9):score.getHoleScore(i+9)}}</p>
                
              </td>
              <td class="is-size-7 has-text-centered" >{{ score.objType==='teamScore'?score.netScore('back'):score.score.back }}</td>
            
            </tr>

            <tr v-if="round.games.hasStablefordGame()">
              <td class="is-size-7 has-text-centered">Points</td>
              <td class=" text-center" v-for="i in 9" :key="i+9">
                <p class="is-size-7">{{score.getHoleNetPoints(i+9)}}</p>
              </td>
              <td class="is-size-7 has-text-centered">{{ score.getNetPoints('back') }}</td>
            </tr>

            <tr v-if="round.games.hasGame('dots')">
              <td class="is-size-7 has-text-centered">Dots</td>
              <td class=" text-center" v-for="i in 9" :key="i+10">
                <p class="is-size-7" >{{score.getDots(i+9)}}</p>
              </td>
              <td class="is-size-7 has-text-centered">{{ score.totalDots('back') }}</td>
            </tr>
          </tbody>
        </table>
    
        <p v-if="score.objType==='teamScore'" class="is-size-5 has-margin-left-10"><b>Total Net Score:</b> {{score.netScore('full18')}} {{`(${score.netScore('front')}/${score.netScore('back')})`}}</p>
        <p v-else class="is-size-5 has-margin-left-10"><b>Total Score:</b> {{score.score.full18}} {{`(${score.score.front}/${score.score.back})`}}</p>

      </div>
    </template>
    <template v-else>
      <div class="b-table">
        <table class="table is-bordered is-narrow center-table">
          <col style="width:80px"/>
          <col v-for="c in 9" :key="c" style="width:60px"/>
          <col style="width:50px"/>
          <col v-for="c2 in 9" :key="c2+9" style="width:60px"/>
          <col style="width:50px"/>
          <col style="width:50px"/>
          <thead>
            <tr>
              <th class="is-size-6 has-text-centered header-cell" ><p style="color:white;">HOLE</p></th>
              <th class="is-size-6 has-text-centered header-cell" v-for="i in 9" :key="i">
                <p class="header-text-color">{{ i }}</p>
              </th>
              <th class="is-size-6 has-text-centered header-cell"><p class="header-text-color">OUT</p></th>
              <th class="is-size-6 has-text-centered header-cell" v-for="i in 9" :key="i+9">
                <p class="header-text-color" >{{ i+9 }}</p>
              </th>
              <th class="is-size-6 has-text-centered header-cell"><p class="header-text-color">IN</p></th>
              <th class="is-size-6 has-text-centered header-cell"><p class="header-text-color">TOT</p></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="is-size-6 has-text-centered">Hdcp</td>
              <td class="is-size-6 has-text-centered" v-for="i in 9" :key="i">
                {{ score.getHandicapHoleAlloc(i) }}
              </td>
              <td class="is-size-6"></td>
              <td class="is-size-6 has-text-centered" v-for="i in 9" :key="i+10">
                {{ score.getHandicapHoleAlloc(i+9) }}
              </td>
              <td class="is-size-6"></td>
              <td class="is-size-6"></td>
            </tr>
            <tr>
              <td class="is-size-6 has-text-centered">Par</td>
              <td class="is-size-6 has-text-centered" v-for="i in 9" :key="i">
                {{ score.getHolePar(i) }}
              
              </td>
              <td class="is-size-6 has-text-centered">{{ score.getPar('front') }}</td>
              <td class="is-size-6 has-text-centered" v-for="i in 9" :key="i+10">
                {{ score.getHolePar(i+9) }}
              </td>
              <td class="is-size-6 has-text-centered">{{ score.getPar('back') }}</td>
              <td class="is-size-6 has-text-centered">{{ score.getPar('full18')}}</td>
            </tr>
            <tr v-if="score.objType==='score' || (score.objType==='teamScore' && score.format !== 'all')">
              <td class="is-size-6 has-text-centered" >Strokes</td>
              <td class="is-size-6 has-text-centered" v-for="i in 9" :key="i" >
                <div class="level is-paddingless">
                  <span class="has-text-info is-small level-left">{{ getHandicapDots(i, score) }}</span>
                </div>
              </td>
              <td class="is-size-6 has-text-centered" >{{ score.getHandicapStrokes('front') }}</td>
              <td class="is-size-6 has-text-centered" v-for="i in 9" :key="i+10" >
                <div class="level is-paddingless">
                  <span class="has-text-info">{{ getHandicapDots(i+9, score) }}</span>
                </div>
              </td>
              <td class="is-size-6 has-text-centered" >{{ score.getHandicapStrokes('back') }}</td >
              <td class="is-size-6 has-text-centered" >{{ score.getHandicapStrokes('full18') }}</td >
            </tr>

            <tr v-if="round.games.hasGame('kp') && score.objType==='score'">
              <td class="is-size-6 has-text-centered" >KP</td>
              <td class="is-size-6 has-text-centered" v-for="i in 9" :key="i" >
                <template v-if="isKPHole(i, score.tee)">
                  <p v-if="readOnly || !editScore" class="is-size-6">{{score.hasKP(i)?`Has KP at ${getKPDist(score, i)}`:''}}</p>
                  <template v-else>
                    <b-checkbox 
                      class="mt-2"
                      size="is-small" 
                      :value="score.hasKP(i)" 
                      @input="value => setKP(score, i, value)" >
                      KP
                    </b-checkbox>
                    <vue-numeric
                      class="input text-center is-small"
                      :value="getKPDist(score, i)"
                      currency=""
                      style="width:4em;"
                      thousand-separator=""
                      :min="0" 
                      :precision="0"
                      @blur="updateKPDist(score, i, $event.target.value)"
                      
                    /> 
                  </template>
                
                </template>
                  
               
              </td>
              <td class="is-size-6 has-text-centered" >{{score.getNumKPs('front')}}</td>
              <td class="is-size-6 has-text-centered" v-for="i in 9" :key="i+10" >
                
                <template v-if="isKPHole(i+9, score.tee)">
                  <p v-if="readOnly || !editScore" class="is-size-6">{{score.hasKP(i+9)?`Has KP at ${getKPDist(score, i+9)}`:''}}</p>
                  <template v-else>
                    <b-checkbox 
                      class="mt-2"
                      size="is-small" 
                      :value="score.hasKP(i+9)" 
                      @input="value => setKP(score, i+9, value)" >
                      KP
                    </b-checkbox>
                    <vue-numeric
                      class="input text-center is-small"
                      :value="getKPDist(score, i+9)"
                      currency=""
                      style="width:4em;"
                      thousand-separator=""
                      :min="0" 
                      :precision="0"
                      @blur="updateKPDist(score, i+9, $event.target.value)"
                      
                    /> 
                  </template>
                </template>
                  
              </td>
              <td class="is-size-6 has-text-centered" >{{score.getNumKPs('back')}}</td >
              <td class="is-size-6 has-text-centered" >{{score.kpCount}}</td >
            </tr>

            <tr v-if="round.games.hasGame('skins') && score.objType==='score'">
              <td class="is-size-6 has-text-centered" >Skins</td>
              <td class="is-size-6 has-text-centered" v-for="i in 9" :key="i" >
                <p class="is-size-6 has-text-centered">{{score.hasSkin(i)?'1':''}}</p>
              </td>
              <td class="is-size-6 has-text-centered" >{{score.getNumSkins('front')}}</td>
              <td class="is-size-6 has-text-centered" v-for="i in 9" :key="i+10" >
                  <p class="is-size-6 has-text-centered">{{score.hasSkin(i+9)?'1':''}}</p>
              </td>
              <td class="is-size-6 has-text-centered" >{{score.getNumSkins('back')}}</td >
              <td class="is-size-6 has-text-centered" >{{score.skinsCount}}</td >
            </tr>

            <tr>
              <td class="is-size-6 has-text-centered">{{score.objType==='teamScore'?'Net Score':'Score'}}</td>
              <td class=" text-center" v-for="i in 9" :key="i" style="text-align:center;" >
                <p v-if="readOnly || !editScore" class="is-size-6" :class="formatScoreCell(score, i)">{{score.objType==='teamScore'?score.netHoleScore(i):score.getHoleScore(i)}}</p>
                <b-input
                  v-else
                 
                  size="is-size-6 has-text-centered"
                  :value="score.getHoleScore(i)"
                  @blur="sumScore(score, i, $event.target.value)"
                  v-cleave="{numeral: true, numeralIntegerScale: 3, numeralThousandsGroupStyle: 'none', numeralDecimalScale: 0}"
                />
              </td>
              <td class="is-size-6 has-text-centered has-text-weight-bold" >{{ score.objType==='teamScore'?score.netScore('front'):score.score.front }}</td>
              
              <td class="text-center" v-for="i in 9" :key="i+10" style="text-align:center;" >
                <p v-if="readOnly || !editScore" class="is-size-6" :class="formatScoreCell(score, i+9)">{{score.objType==='teamScore'?score.netHoleScore(i+9):score.getHoleScore(i+9)}}</p>
                <b-input
                  v-else
               
                  size="is-size-6 has-text-centered"
                  :value="score.getHoleScore(i+9)"
                  @blur="sumScore(score, i+9, $event.target.value)"
                  v-cleave="{numeral: true, numeralIntegerScale: 3, numeralThousandsGroupStyle: 'none', numeralDecimalScale: 0}"
                />
              </td>
              <td class="is-size-6 has-text-centered has-text-weight-bold" >{{ score.objType==='teamScore'?score.netScore('back'):score.score.back }}</td>
              <td class="is-size-6 has-text-centered has-text-weight-bold" >{{ score.objType==='teamScore'?score.netScore('full18'):score.score.full18 }}</td>
            </tr>

            <tr v-if="round.games.hasStablefordGame()">
              <td class="is-size-6 has-text-centered">Points</td>
              <td class=" text-center" v-for="i in 9" :key="i" style="text-align:center;" >
                <p class="is-size-6" >{{score.getHoleNetPoints(i)}}</p>
              </td>
              <td class="is-size-6 has-text-centered has-text-weight-bold">{{ score.getNetPoints('front') }}</td>
              
              <td class="text-center" v-for="i in 9" :key="i+10" style="text-align:center;" >
                <p class="is-size-6" >{{score.getHoleNetPoints(i+9)}}</p>
              </td>
              <td class="is-size-6 has-text-centered has-text-weight-bold" >{{ score.getNetPoints('back') }}</td>
              <td class="is-size-6 has-text-centered has-text-weight-bold" >{{ score.getNetPoints('full18') }}</td>
            </tr>

            <tr v-if="round.games.hasGame('dots')">
              <td class="is-size-6 has-text-centered">Dots</td>
              <td class=" text-center" v-for="i in 9" :key="i" style="text-align:center;" >
                <p v-if="readOnly || !editScore" class="is-size-6" >{{score.getDots(i)}}</p>
                <b-input
                  v-else
                 
                  size="is-size-6 has-text-centered"
                  :value="score.getDots(i)"
                  @blur="sumDots(score, i, $event.target.value)"
                  v-cleave="{numeral: true, numeralIntegerScale: 3, numeralThousandsGroupStyle: 'none', numeralDecimalScale: 0}"
                />
              </td>
              <td class="is-size-6 has-text-centered has-text-weight-bold">{{ score.totalDots('front') }}</td>
              
              <td class="text-center" v-for="i in 9" :key="i+10" style="text-align:center;" >
                <p v-if="readOnly || !editScore" class="is-size-6" >{{score.getDots(i+9)}}</p>
                <b-input
                  v-else
               
                  size="is-size-6 has-text-centered"
                  :value="score.getDots(i+9)"
                  @blur="sumDots(score, i+9, $event.target.value)"
                  v-cleave="{numeral: true, numeralIntegerScale: 3, numeralThousandsGroupStyle: 'none', numeralDecimalScale: 0}"
                />
              </td>
              <td class="is-size-6 has-text-centered has-text-weight-bold" >{{ score.totalDots('back') }}</td>
              <td class="is-size-6 has-text-centered has-text-weight-bold" >{{ score.totalDots('full18') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </div>
</template>

<script>

import DBUpdates from '../../helpers/dbUpdates'
import { handicapDotsLabel } from '../../lib.js'

export default {
  data () {
    return {
     
    }
  },

  props: {
    score: Object,
    editScore: Boolean,
    round: Object
  },
  
  computed: {
    isAdmin () {
      return this.$store.getters['user/isAdmin']
    },
    readOnly () {
      return !this.isAdmin || !this.round.isInProgress
    }
   
  },
  methods: {
    handicapDotsLabel,

    getHandicapDots (holeNum, score) {
      const numDots = score.getHandicapStrokesHole(holeNum,'full18')
      let dots = ''
      if (numDots > 2) {
        dots = numDots
      }
      else {
        dots = handicapDotsLabel(numDots)
      }
       

      return dots
    },

    isKPHole (holeNum, tee) {
      return this.round.isKPHole(tee, holeNum) && this.round.games.hasGame('kp')
    },

    async setKP (score, holeNum, val) {
      await this.$store.dispatch('round/setKP', {score, holeNum, val})
   
    },



    formatScore (score, holeNum) {
    
      let viewClass = ''

      if (score.objType!=='teamScore') {
        const toPar = score.getHoleScoreToPar(holeNum)
     
        if (toPar) {
      // if (toPar === 2) viewClass += ' double-bogey'
          // if (toPar === 1) viewClass += ' bogey'
          if (toPar === -1) viewClass += ' birdie'
          if (toPar === -2) viewClass += ' eagle'
        }
      }
    
      return viewClass
    },

    formatScoreCell (score, holeNum) {
    
      let viewClass = ''

      if (score.objType!=='teamScore') {
        const toPar = score.getHoleScoreToPar(holeNum)

        if (toPar) {
          if (toPar > 3) viewClass = ' triple-bogey-cell'
          if (toPar === 3) viewClass = ' triple-bogey-cell'
          if (toPar === 2) viewClass = ' double-bogey-cell'
          if (toPar === 1) viewClass = ' bogey-cell'
          if (toPar === 0) viewClass = ' par-cell'
          if (toPar === -1) viewClass = ' birdie-cell'
          if (toPar === -2) viewClass = ' eagle-cell'
          if (toPar < -2) viewClass = ' eagle-cell'
        }
      }
     
      return viewClass
    },

    async sumScore (score, holeNum, val) {

      await this.$store.dispatch('round/sumScore', {score, holeNum, val: Number(val)})
     
    },

    async sumDots (score, holeNum, val) {

  
      await this.$store.dispatch('round/sumDots', {score, holeNum, val: Number(val)})

     
    },

    getKPDist (score, groupHoleNum) {
      let dist = score.getKPDist(groupHoleNum)
     
      if (dist === '' || Number.isNaN(dist)) dist = 0


      return dist
    },

    async updateKPDist (score, holeNum, value) {
      const scoreUpdates = new DBUpdates()
      const scoreUpdated = score.copy()
      scoreUpdated.updateKPDist(holeNum, value)

      scoreUpdates.add(score.docId, {kpDist: scoreUpdated.kpDist})

      await this.$store.dispatch('round/saveScoreValues', scoreUpdates)

     
    }
  
  }
  
  
}
// Eagle is nearly the same, but change border-style to double and border-width to 4px This solution should work for all major browsers. Take out the rounded corners to mark a bogey or double-bogey.

</script>
<style lang="scss">
  .text-center {
    text-align: center !important;
  }
  .vert-middle {
    vertical-align: middle !important   ;
  }

  .vert-bottom {
    vertical-align: bottom !important; 
  }

  .checkbox-cell-mobile {
      width: 20px;
      .checkbox {
          vertical-align: middle;
          .check {
              transition: none;
          }
      }
  }
 
 tr.rowhighlight td, tr.rowhighlight th{
    background-color:#f0f8ff;
  }
  .group-score {
    width: 768px;
    flex: 0 0 768px;
  }

  .selected-player {
    border-width: 2px;
    border-style: solid;
    border-color: blue;
  }
  .header-text-color {
    color: white
  }
  .header-cell {
    background-color: black;
    color: white;
    font-weight: bold;
  }

// Eagle is nearly the same, but change border-style to double and border-width to 4px This solution should work for all major browsers. Take out the rounded corners to mark a bogey or double-bogey.
  .skin {
    background-color: green;
    color: white;
    font-weight: bold;
  }
  
  .bogey {
  
      border-style: solid;
      border-width: 1px;
  }
  .bogey-cell {
    background-color:blue;
    color: white !important;
    font-weight: bold;
  }
  .double-bogey-cell {
    background-color:skyblue;
    color: white;
    font-weight: bold;
  }
  .triple-bogey-cell {
    background-color:navy;
    color: white;
    font-weight: bold;
  }
  .double-bogey {
    
      border-style: double;
      border-width: 4px;
  }
  .par-cell {
    font-weight: bold;
  }
  .birdie-cell {
    background-color: red;
    color: white;
    font-weight: bold;
  }
  .birdie {
    background-color: red;
    color: white;
    font-weight: bold;
  }
  .eagle {
    color: white;
    font-weight: bold;
 /*     border-style: double;
      border-width: 4px;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%; */
  }
  .eagle-cell {
    background-color:salmon;
    color: white;
    font-weight: bold;
  }
  .double-eagle-cell {
    background-color:darkred;
    color: white;
    font-weight: bold;
  }
</style>