import Course from './course'

export default class Courses {
  constructor() {
    this.initialize()
  }
  initialize () {
    this.aCourses = []
  }
  addDoc (docId, data) {
    const course = new Course()
    course.docId = docId
    course.setData(data)

    this.aCourses.push(course)

    return course
  }

  
  clear () {
    this.aCourses = []
  }
  get size () {
    return this.aCourses.length
  }
  
  toJSON () {
    return JSON.stringify(this.getObj(),null,2)
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  getObj () {
    return this.aCourses.map(course => course.getObj())
  }
  setObj (data) {
    this.aCourses = data.map(courseObj => {
      const course = new Course()
      course.setObj(courseObj)
      return course
    })
  }
  getData () {
    return this.aCourses.map(course => course.getData())
  }
  setData (data) {
    this.aCourses = data.map(courseData => {
      const course = new Course()
      course.setData(courseData)
      return course
    })
  }
}

