import { db, auth, fs } from '../firebase'
import User from '../models/user'
import Users from '../models/users'
import Courses from '../models/courses'

import Groups from '../models/groups'
import Players from '../models/players'
import Rounds from '../models/rounds'
import Scores from '../models/scores'
import UserSchRound from '../models/userSchRound'
import UserPostedScore from '../models/userPostedScore'
import GroupController from '../controllers/group'
import FileSaver from 'file-saver'
import dayjs from 'dayjs'
import Score from '../models/score'
import Round from '../models/round'
import Tournament from '../models/tournament'

export default {
  async getUser (uid) {
    let response
    console.log('get user',uid)
    try {
      response = {
        errorMsg: ''
      }
    
     
      const userDoc = await db.collection('users').doc(uid).get()

 
  
      const user = new User()
  
      user.docId = uid


      if (userDoc.exists) user.setData(userDoc.data())


      response.user = user
 
    }
    catch (error) {
      response = {
        errorMsg: 'SignIn user ' + error.code + ' ' + error.message
      }
    }
    
    return response

  },
  async addUser (user) {
    const response = {
      errorMsg: ''
    }
    try {
      const userRef = await db.collection('users').add(user.getData())
      user.docId = userRef.id
    }
    catch (error) {
      response.errorMsg = 'addUser user.js - ' + error.message
    }
    return response
  },
  async updateUser (user) {
    const response = {
      errorMsg: ''
    }
    try {
      await db.collection('users').doc(user.docId).set(user.getData())
    }
    catch (error) {
      response.errorMsg = 'updateUser user.js - ' + error.message
    }
    return response
  },
  async updateUserValues (userUpdates) {
    const response = {
      errorMsg: ''
    }
    try {

      if (userUpdates.size === 1) {
        const userUpdate = userUpdates.aUpdates[0]
        await db.collection('users').doc(userUpdate.docId).update(userUpdate.update)
      }
      else {
        await db.runTransaction(async tx => {
          const updates = []
          userUpdates.aUpdates.forEach(userUpdate => {
            const userRef = db.collection('users').doc(userUpdate.docId)
            updates.push(tx.update(userRef, userUpdate.update))
          })
          await Promise.all(updates)
        })
      }
      
    }
    catch(error) {
      response.errorMsg = 'updateUserValues user.js - ' + error.message
    }
    userUpdates.clear()
    return response
  },
  async signIn (email, password) {
    let response
    try {
      response = {
        errorMsg: ''
      }
    
      const userAccess = await auth.signInWithEmailAndPassword(email, password)
     
      const userDoc = await db.collection('users').doc(userAccess.user.uid).get()
  
      const user = new User()
  
      user.docId = userAccess.user.uid
      user.email = email

      if (userDoc.exists) user.setData(userDoc.data())

      response.user = user
 
    }
    catch (error) {
      response = {
        errorMsg: 'SignIn user ' + error.code + ' ' + error.message
      }
    }
    
    return response

  },
  async signInAsGuest (email) {
   
    await auth.signInAnonymously()

    let response
    try {
      response = {
        errorMsg: '',
        groupDocId: '',
        roundDocId:''
      }
      const user = new User()

      const groupDocs = await db.collection('groups').get()

      for (let groupDoc of groupDocs.docs) {

        const scoreDocs = await db.collection('groups').doc(groupDoc.id)
          .collection('scores')
          .where('email','==',email)
          .where('type','==','Guest')
          .where('posted', '==', false)
          .get()

        if (scoreDocs.size > 0) {
          
          const scoreDoc = scoreDocs.docs[0]
          response.roundDocId = scoreDoc.data().roundDocId
          response.groupDocId = groupDoc.id

          user.name = scoreDoc.data().name
          user.tee = scoreDoc.data().tee
          user.ghinNum = scoreDoc.data().ghinNum
          user.email = email
          user.groupDocId = groupDoc.id
          user.groupName = groupDoc.data().name


          break
        }
     
      }
     

      response.user = user
      

      if (response.roundDocId === null) await auth.signOut()
 
    }
    catch (error) {
      response = {
        errorMsg: 'SignInAsGuest user ' + error.code + ' ' + error.message
      }
    }
    
    return response

  },
  async getUserPostedScores (context, queryInfo) {
  
    
    
    try {
      context.commit('isLoadingPostedScores', true)
      context.commit('clearUserPostedScores')
      const scoreDocs = await db.collection('groups')
        .doc(queryInfo.groupDocId)
        .collection('scores')
        .where('playerDocId', '==', queryInfo.playerDocId)
        .where('roundDate', '>=', queryInfo.season.fromDateTS)
        .where('roundDate', '<=', queryInfo.season.toDateTS)
        .where('posted','==',true)
        // .where('roundStatus','==','Posted')
        .get()
     
      
     
      for (let scoreDoc of scoreDocs.docs) {
        context.state.userPostedScores.addDoc(scoreDoc.id, scoreDoc.data())
        // context.commit('addUserPostedScore', scoreDoc)
      }
    
      context.commit('isLoadingPostedScores', false)
      context.commit('createSeasonResults')


    }
    catch (error) {
      context.commit('errorMsg', 'getUserPostedScore user ' + error.code + ' ' + error.message)
    }
   
  },
  onUser (context) {
    let unsubscribe = function (){}
   
    try {
      context.commit('unsubscribeUser')

      const userDocId = context.state.user.docId

      unsubscribe = db.collection('users')
        .doc(userDocId)
        .onSnapshot( userDoc => {

          context.state.user.setData(userDoc.data())

        })
     
      context.commit('subscribeUser', unsubscribe)

    }
    catch (error) {
      context.commit('errorMsg', 'onUser - ' + error.code + ' ' + error.message)
    }
   
  },

  onUserPostedScores (context, queryInfo) {
    let unsubscribe = function (){}
   
    try {
      context.commit('unsubscribePostedScores')
      context.commit('isLoadingPostedScores', true)

      unsubscribe = db.collection('groups')
        .doc(queryInfo.groupDocId)
        .collection('scores')
        .where('playerDocId', '==', queryInfo.playerDocId)
        .where('roundDate', '>=', queryInfo.season.fromDateTS).where('roundDate', '<=', queryInfo.season.toDateTS)
        .where('posted','==',true)
        .orderBy('roundDate','desc')
        .onSnapshot( snapshot => {
        
          for (let change of snapshot.docChanges()) {
            if (change.type === 'added') {
              const score = new Score()
              score.docId = change.doc.id
              score.setData(change.doc.data())
            
              const userPostedScore = new UserPostedScore()
              userPostedScore.docId = change.doc.id
             
              userPostedScore.setFromScore(score)
              
              context.state.userPostedScores.add(userPostedScore)
              // context.commit('addUserPostedScore', change.doc)
           
            }
            if (change.type === 'modified') {
              const userScore = context.state.userPostedScores.aScores.find(s => s.docId === change.doc.id)
            
              if (userScore !== undefined) {
                const score = new Score()
                score.setData(change.doc.data())
                userScore.setFromScore(score)
              }
              // context.commit('updateUserPostedScore', change.doc)
            }
            if (change.type === 'removed') {
              if (context.state.userPostedScores.exists(change.doc.id)) {
                context.state.userPostedScores.deleteByDocId(change.doc.id)
              }
              // context.commit('deleteUserPostedScore', change.doc.id)
            
            }
          }
         
          context.commit('isLoadingPostedScores', false)
          context.commit('createSeasonResults')

        })
     
      context.commit('subscribePostedScores', unsubscribe)

    }
    catch (error) {
      context.commit('errorMsg', error.code + ' ' + error.message)
    }
   
  },
  // async getUserSchRounds (context, queryInfo) {
  
  //   try {
   
  //     context.commit('clearUserSchRounds')
  //     const scoreDocs = await db.collection('groups')
  //       .doc(queryInfo.groupDocId)
  //       .collection('scores')
  //       .where('playerDocId', '==', queryInfo.playerDocId)
  //       .where('posted','==',false)
  //       .get()
     
  //     for (let scoreDoc of scoreDocs.docs) {
  //       context.commit('addUserSchRound', scoreDoc)
        
  //     }


  //   }
  //   catch (error) {
  //     context.commit('errorMsg', 'getUserSchRound user ' + error.code + ' ' + error.message)
  //   }
   
  // },
  onUserSchRounds (context, queryInfo) {
   
    let unsubscribeSchRounds = function (){}
    try {
      context.commit("unsubscribeSchRounds")
      unsubscribeSchRounds = db.collection('groups')
        .doc(queryInfo.groupDocId)
        .collection('rounds')
        .where('posted','==',false)
        .onSnapshot( snapshot => {
     
          for (let change of snapshot.docChanges()) {
            if (change.doc.data().tournamentDocId === null) {
              
              if (change.type === 'added') {
                const round = new Round()
                round.docId = change.doc.id
                round.setData(change.doc.data())
                const userSchRound = new UserSchRound()
                userSchRound.setFromRound(round)
                context.state.userSchRounds.add(userSchRound)
              
                // context.commit('addUserSchRound', change.doc)
            
              }
              if (change.type === 'modified') {
                const userRound = context.state.userSchRounds.aRounds.find(s => s.docId === change.doc.id)
                if (userRound !== undefined) {
                  const round = new Round()
                  round.docId = change.doc.id
                  round.setData(change.doc.data())
                  userRound.setFromRound(round)
                 
                }
    
                // context.commit('updateUserSchRound', change.doc)
              }
              if (change.type === 'removed') {
      
                if (context.state.userSchRounds.exists(change.doc.id)) {
    
                  context.state.userSchRounds.deleteByDocId(change.doc.id)
                }
                // context.commit('deleteUserSchRound', change.doc.id)
              
              }
            }
          }
          context.state.userSchRounds.sort('roundDate')
     
       
        })
      
      context.commit('subscribeSchRounds', unsubscribeSchRounds)
    }
    catch (error) {
      context.commit('errorMsg', 'onUserSchRounds user.js - ' + error.code + ' ' + error.message)
    }

    let unsubscribeSchScores = function (){}
    try {
      context.commit("unsubscribeSchScores")
      unsubscribeSchScores = db.collection('groups')
        .doc(queryInfo.groupDocId)
        .collection('scores')
        .where('playerDocId', '==', queryInfo.playerDocId)
        .where('posted','==',false)
        .onSnapshot( snapshot => {
     
          for (let change of snapshot.docChanges()) {
         
            if (change.type === 'added') {
              const userRound = context.state.userSchRounds.aRounds.find(r => r.docId === change.doc.data().roundDocId)
              if (userRound !== undefined) {
             
                userRound.scoreDocId = change.doc.id
                userRound.teeTimeRequest = change.doc.data().teeTimeRequest
              }
          
            }
          
            if (change.type === 'removed') {
              const userRound = context.state.userSchRounds.aRounds.find(r => r.docId === change.doc.data().roundDocId)
              if (userRound !== undefined) {
                userRound.scoreDocId = ''
                userRound.teeTimeRequest = ''
              }
            
            }
          }
     
       
        })
      
      context.commit('subscribeSchScores', unsubscribeSchScores)
    }
    catch (error) {
      context.commit('errorMsg', 'onUserSchRounds user.js - ' + error.code + ' ' + error.message)
    }
  
  },
  onUserLimitScores (context, queryInfo) {
    let unsubscribe = function (){}
    try {
      context.commit("unsubscribeLimitScores")

      const endDate = fs.Timestamp.fromDate(dayjs().toDate())
      const begDate = fs.Timestamp.fromDate(dayjs().subtract(queryInfo.months,'month').toDate())

     
      unsubscribe = db.collection('groups')
        .doc(queryInfo.groupDocId)
        .collection('scores')
        .where('playerDocId', '==', queryInfo.playerDocId)
        .where('roundDate','>=',begDate).where('roundDate','<=',endDate)
        .where('posted', '==', true)
        .onSnapshot( snapshot => {
  
          context.commit('numLimitScores',snapshot.size)
         
       
          
        })
    
      context.commit('subscribeLimitScores', unsubscribe)
    }
    catch (error) {
      context.commit('errorMsg', 'onUserLimitScores user.js - ' + error.code + ' ' + error.message)
    }
  
  },

  onUserSchTournaments (context, queryInfo) {
   
    let unsubscribeSchTournaments = function (){}
    try {
      context.commit("unsubscribeSchTournaments")
      unsubscribeSchTournaments = db.collection('groups')
        .doc(queryInfo.groupDocId)
        .collection('tournaments')
        .where('posted','==',false)
        .onSnapshot( snapshot => {
     
          for (let change of snapshot.docChanges()) {
            if (change.doc.data().roundDocIds.length > 0) {
              if (change.type === 'added') {
                const tournament = new Tournament()
                tournament.docId = change.doc.id
                tournament.setData(change.doc.data())
                
                const round = new UserSchRound()
                round.setTournament(tournament)
              
                context.state.userSchRounds.add(round)
                // context.commit('addUserSchRound', change.doc)
            
              }
              if (change.type === 'modified') {
                const userRound = context.state.userSchRounds.aRounds.find(s => s.tournamentDocId === change.doc.id)
                if (userRound !== undefined) {
                  const tournament = new Tournament()
                  tournament.docId = change.doc.id
                  tournament.setData(change.doc.data())
                  userRound.setTournament(tournament)
                }
    
                // context.commit('updateUserSchRound', change.doc)
              }
              if (change.type === 'removed') {
              
                const index = context.state.userSchRounds.aRounds.findIndex(r=>r.tournamentDocId === change.doc.id)
                if (index >= 0) {
                  context.state.userSchRounds.aRounds.splice(index, 1)
                }
              
              }
            }
          }
          context.state.userSchRounds.sort('roundDate')
     
       
        })
      
      context.commit('subscribeSchTournaments', unsubscribeSchTournaments)

      
    }
    catch (error) {
      context.commit('errorMsg', 'onUserSchTournaments user.js - ' + error.code + ' ' + error.message)
    }

    

    
  
  },
  
  async isRegistered (email, groupDocId, playerDocId) {
    let response = {
      errorMsg: '',
      registered: false
    }
    try {
    
      const userDocs = await db.collection('users').where('email','==',email.toLowerCase()).get()
 

      if (userDocs.size > 0) {
        response.registered = true
        const userDoc = userDocs.docs[0]
        
        const groups = userDoc.data().groups
    
        const group = groups.find(g => g.docId === groupDocId)
       
        if (group === undefined) {
          groups.push ({docId: groupDocId})
          const update = {
            groups: groups
          }
          if (userDoc.data().groupDocId === '') update.groupDocId = groupDocId
          if (userDoc.data().playerDocId === '') update.playerDocId = playerDocId
          await db.collection('users').doc(userDoc.id).update(update)
        }
      }
    }
    catch (error) {
      response = {
        errorMsg: 'isRegistered user.js - ' + error.code + ' ' + error.message
      }
    }
    return response
  },
  
  async getGhinNum (email) {
    let response = {
      errorMsg: '',
      ghinNum: '',
      tee: ''
    }
    try {
    
      const userDocs = await db.collection('users').where('email','==',email).get()
      if (userDocs.size > 0) {
        const user = new User()
        user.setData (userDocs.docs[0].data())

        for (let groupObj of user.groups) {
          const resp = await GroupController.getPlayers(groupObj.docId, 'email', email)
          if (resp.players.size > 0) {
            response.ghinNum = resp.players.aPlayers[0].ghinNum
            response.tee = resp.players.aPlayers[0].tee
            break
          }


        }
      }
    }
    catch (error) {
      response = {
        errorMsg: 'getGhinNum user.js - ' + error.code + ' ' + error.message
      }
    }
    return response
  },
 
  async signOut (context) {
    await auth.signOut()
 
  },

  async getAllUsers () {
    const response = {
      errorMsg: '',
      users: new Users()
    }
    try {

      const userDocs = await db.collection('users').get()

      if (userDocs.size > 0) {
      
        for (let userDoc of userDocs.docs) {
          response.users.addDoc(userDoc.id, userDoc.data())
        }

      }

    }
    catch(error) {
      response.errorMsg = 'getAllUsers ' + error.message
    }
    return response
  },

  async backupDb () {
    let response = {
      errorMsg: ''
    }
    try {
    // courses
      const courseDocs = await db.collection('courses').orderBy('name','asc').get()
      const courses = new Courses
      for (let courseDoc of courseDocs.docs) {
        courses.addDoc(courseDoc.id, courseDoc.data())
      }

      let blob = new Blob([courses.toJSON()], {type: "text/plain;charset=utf-8"})
      FileSaver.saveAs(blob, 'GIRLAP courses ' + dayjs().format() + '.txt')

    // users
      const userDocs = await db.collection('users').orderBy('name','asc').get()
      const users = new Users
      for (let userDoc of userDocs.docs) {
        users.addDoc(userDoc.id, userDoc.data())
      }

      blob = new Blob([users.toJSON()], {type: "text/plain;charset=utf-8"})
      FileSaver.saveAs(blob, 'GIRLAP users ' + dayjs().format() + '.txt')

    // groups
      const groupDocs = await db.collection('groups').orderBy('name','asc').get()
      const groups = new Groups()
      for (let groupDoc of groupDocs.docs) {
        groups.addDoc(groupDoc.id, groupDoc.data())
      }

      blob = new Blob([groups.toJSON()], {type: "text/plain;charset=utf-8"})
      FileSaver.saveAs(blob, 'GIRLAP groups ' + dayjs().format() + '.txt')

    // group players
      for (let groupDoc of groupDocs.docs) {
        const playerDocs = await db.collection('groups').doc(groupDoc.id).collection('players').orderBy('name','asc').get()
        const players = new Players()
        for (let playerDoc of playerDocs.docs) {
          players.addDoc(playerDoc.id, playerDoc.data())
        }
        const header = {
          groupDocId: groupDoc.id
        }
        const headerJSON = JSON.stringify(header,null,2)

        blob = new Blob([headerJSON + '\n' + players.toJSON()], {type: "text/plain;charset=utf-8"})
        FileSaver.saveAs(blob, `GIRLAP players group ${groupDoc.id} ${dayjs().format()}.txt`)
      }
    // group rounds
      for (let groupDoc of groupDocs.docs) {
        const roundDocs = await db.collection('groups').doc(groupDoc.id).collection('rounds').orderBy('date','asc').get()
        const rounds = new Rounds()
        for (let roundDoc of roundDocs.docs) {
          rounds.addDoc(roundDoc.id, roundDoc.data())
        }
        const header = {
          groupDocId: groupDoc.id
        }
        const headerJSON = JSON.stringify(header,null,2)

        blob = new Blob([headerJSON + '\n' + rounds.toJSON()], {type: "text/plain;charset=utf-8"})
        FileSaver.saveAs(blob, `GIRLAP rounds group ${groupDoc.id} ${dayjs().format()}.txt`)
      }

      // group scores
      for (let groupDoc of groupDocs.docs) {
        const scoreDocs = await db.collection('groups').doc(groupDoc.id).collection('scores').orderBy('roundDate','asc').get()
        const scores = new Scores()
        for (let scoreDoc of scoreDocs.docs) {
          scores.addDoc(scoreDoc.id, scoreDoc.data())
        }
        const header = {
          groupDocId: groupDoc.id
        }
        const headerJSON = JSON.stringify(header,null,2)

        const blob = new Blob([headerJSON + '\n' + scores.toJSON()], {type: "text/plain;charset=utf-8"})
        FileSaver.saveAs(blob, `GIRLAP scores group ${groupDoc.id} ${dayjs().format()}.txt`)
      }
    }
    catch (error) {
      response = {
        errorMsg: error.code + ' ' + error.message
      }
    }
    return response
  }
  
}
