
<template>
  <b-tabs>
    <b-tab-item label="Calculation Rules">
      <handicap-fields :baseObj="group"/>
    </b-tab-item>
    <b-tab-item label="Handicap History">
      <div class="level">
        <div class="level-left">
          <div class="level-item" >
            <b-autocomplete
            style="width:300px;"
              rounded
              v-model="playerSearch"
              :data="filteredPlayers"
              field="name"
              placeholder="Search by Player's name"
              icon="magnify"
              clearable
              @select="player => searchScoresByPlayer(player)">
              <template slot="empty">No results found</template>
            </b-autocomplete>
          </div>
          <div class="level-item">
            <b-select v-model="dateSearch" @input="value=>searchScores()">
              <option
                v-for="(name,index) in searchDateArray"
                :value="name"
                :key="index"
              >{{ name }}</option>
            </b-select>
          </div>
        </div>
       
      </div>
      <template v-if="player">
        <p class="subtitle mb-1">Score Summary</p>
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              Index: {{indexDescrip}}
            </div>
            <div class="level-item">
              Tee:
            </div>
            <div class="level-item">
              <b-select
                v-model="tee"
                @input="updateTee()"
              >
                <option v-for="tee in playerTees" :value="tee" :key="tee">{{ tee }}</option>
              </b-select>
            </div>
            <div class="level-item">
              Handicap: {{formatHandicap(handicap)}}
            </div>
          </div>
        </div>
      </template>
      <div class="level mb-2">
        <div class="leve-left">
          <div class="level-item">
            <p class="subtitle">Scores</p>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <b-button>Export</b-button>
          </div>
        </div>
      </div>
      <b-table
        :data="playerScores" 
        default-sort="roundDate" 
        default-sort-direction="DESC"
      >
        <template slot="empty">
          <p>No scores found</p>
        </template>
    
        <b-table-column
          class="vcenter"
          field="roundDate"
          label="Date"
          sortable
          v-slot="props"
        >
          <p>{{props.row.formattedDateLabel + (props.row.wd ? ' (Withdrawn)':'')}}</p>
        </b-table-column>

        <b-table-column
          class="vcenter"
          field="courselLabel"
          label="Course"
          v-slot="props"
        >
          <p>{{props.row.courseLabel}}</p>

        </b-table-column>

        <b-table-column
          class="vcenter"
          label="Diff"
          width="80"
          centered
          field="diff"
          sortable
          :visible="!isMobile"
          v-slot="props"
        >{{ formatHandicap(props.row.diff.full18)}}
        </b-table-column>

        <b-table-column
          class="vcenter"
          label="Index"
          width="80"
          centered
          field="index"
          sortable
          :visible="!isMobile"
          v-slot="props"
        >{{ formatHandicap(props.row.index.full18)}}</b-table-column>
        <b-table-column
          class="vcenter"
          label="Score"
          width="80"
          field="score.full18"
          sortable
          centered
          v-slot="props"
        >{{ props.row.wd ? '' : props.row.score.full18}}</b-table-column>
        <b-table-column
          class="vcenter"
          label="Handicap"
          width="80"
          centered
          field="handicap.full18"
          sortable
          v-slot="props"
        >{{ formatHandicap(props.row.handicap.full18) + ' (' + props.row.tee + ')'}}</b-table-column>
        <b-table-column
          class="vcenter"
          label="Net Score"
          width="120"
          centered
          field="netScore.full18"
          sortable
          v-slot="props"
        ><p class="has-text-weight-bold">{{ props.row.wd ? '' : props.row.netScore.full18}}</p></b-table-column>
        <b-table-column
          class="vcenter"
          label="To Par"
          width="90"
          centered
          field="toPar"
          sortable
          v-slot="props"
        ><p :class=" props.row.wd ? '' : (props.row.toPar.full18<0 ? 'has-text-danger':'')">{{ props.row.wd ? '' : formatToPar(props.row.toPar.full18) }}</p></b-table-column>
        <b-table-column
          class="vcenter"
          label="Payout"
          width="80"
          centered
          field="totalPayout"
          sortable
          v-slot="props"
        ><p :class="props.row.totalPayout>0?'has-text-success':''">{{ formatCurrency(props.row.totalPayout)}}</p>
        </b-table-column>
        
    
        
      </b-table>
    </b-tab-item>
  </b-tabs>
 
  
</template>

<script>

import HandicapFields from '../Shared/HandicapFields'
import HandicapCalc from '../../helpers/handicapCalc'
import IndexLookup from '../../helpers/indexLookup'

import { mapGetters } from "vuex"
import ScoresController from '../../controllers/score'
import { formatHandicap, formatToPar, formatCurrency } from '../../lib'
import dayjs from 'dayjs'

export default {
  data() {
    return {
      playerSearch: '',
      player: null,
      playerScores: [],
      dateSearch: '',
      index: null,
      indexSource: '',
      indexMsg: '',
      handicap: '',
      tee: '',
      handicapCalc: null
    }
  },
  
  components: { "handicap-fields": HandicapFields },
  mounted () {
    this.dateSearch = this.currentSeasonStandings.name
    this.handicapCalc = new HandicapCalc(this.group.course, this.group.handicap.maxHandicap)
  },
  computed: {
    ...mapGetters('group', [
      'group',
      'playersArray',
      'currentSeasonStandings'
    ]),
    indexDescrip () {
      let descrip = ''
      if (this.index !== null) {
        descrip = `${this.index} (${this.indexSource}) ${this.indexMsg}`
      }
      return descrip
    },
    playerTees () {
      return this.group.course.getPlayerTees()
    },
    filteredPlayers() {
      return this.playersArray.filter((player) => {
        return player.name
          .toLowerCase()
          .indexOf(this.playerSearch.toLowerCase()) >= 0
      })
    },
    seasonsArray () {
      let seasons = []
      if (this.group) seasons = this.group.seasons.aSeasons
      return seasons
    },
    searchDateArray () {
      const search = this.seasonsArray.map(s=>s.name)
      search.push('Last 12 months')
      search.push('Last 20 scores')
      return search
    }
  },
  

  methods: {
    formatHandicap, formatToPar, formatCurrency,
    async searchScoresByPlayer (player) {
      this.player = player
      this.tee = player.tee
      await this.lookupHandicap()
      await this.searchScores()
    },
    async searchScores () {
      if (this.player === null) return
      let fromDate = null
      let toDate = null
      let limit = null
      if (this.dateSearch === 'Last 12 months') {
        toDate = new Date()
        fromDate = dayjs(toDate).subtract(12,'month')
      }
      else if (this.dateSearch === 'Last 20 scores') {
        limit = 20
      }
      else {
        const season = this.seasonsArray.find(s=>s.name===this.dateSearch)
        fromDate = season.fromDate
        toDate = season.toDate
      }
      const queryInfo = {
        groupDocId: this.group.docId,
        playerDocId: this.player.docId,
        fromDate,
        toDate,
        limit
      }
   
      const response = await ScoresController.getPlayerScores(queryInfo)
      if (response.errorMsg.length > 0) {
          this.$store.commit('group/errorMsg', response.errorMsg)
      } 
      else {
        this.playerScores = response.scores.aScores
      }
    },
    updateTee () {
      this.handicap = this.handicapCalc.playingHandicapSide(this.index, this.tee, this.group.getHandicapFac().full18, 'full18')
    },
    async lookupHandicap () {
      const handicapPlayerInfo = {
        playerDocId: this.player.docId,
        name: this.player.name,
        ghinNum: this.player.ghinNum,
        indexSource: 'Group',
        tee: this.tee,
        type: this.player.type,
        state: this.group.course.state,
        clubName: this.group.course.name
      }
      const settings = {
        handicapSettinga: this.group.handicap,
        groupDocId: this.group.docId,
        // state: this.group.course.state,
        // roundDate: dayjs().toDate(),
        // lookupGroupScores: this.group.handicap.useGameIndex,
        // lookupGhinIndex: this.group.handicap.lookupGhinIndex
      }
      const indexLookup = new IndexLookup(settings)

      const indexInfo = await indexLookup.getIndexInfo(handicapPlayerInfo)

      if (indexInfo.index) {
        this.index = indexInfo.getIndex('full18')
        this.handicap = this.handicapCalc.playingHandicapSide(this.index, this.tee, this.group.getHandicapFac().full18, 'full18')
      }
      else {
        this.index = 'n/a'
        this.handicap = 'n/a'
      }
      this.indexMsg = indexInfo.info
      this.indexSource = indexInfo.indexSource
           
     
      
      
      
    }
    
  }
}
</script>

<style scoped>

</style>
