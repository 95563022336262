<template>
  <div>
    <b-tabs>
      <b-tab-item v-for="(round, index) in tournament.rounds.aRounds" :key="index" :label="'Round ' + round.rounNum">
        <team-hole-scores :teamScore="teamScore" :round="round"/>
      </b-tab-item>
    </b-tabs>
    
  </div>
</template>
 
<script>


import TeamHoleScores from '../Round/TeamHoleScores'


export default {
  data () {
    return {

    }
  },

  props: {
    teamScore: Object,
    round: Object
  },

  components: {
    'team-hole-scores': TeamHoleScores
  },
  
  computed: {

  
   
  },
  methods: {
   
  }
  
  
}

</script>

<style lang="scss">
  
</style>