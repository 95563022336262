<template>
  <div class="modal-card" style="width: auto;">
    <header class="modal-card-head">
      <p class="modal-card-title">GHIN Course Lookup</p>
    </header>
    
    <div class="modal-card-body">
      <template v-if="isMobile">
        <div class="level is-mobile">
          <div class="level-left">
            
            <div class="level-item">
              <b-field label="Name">
                <b-input v-model="lookup.name" ></b-input>
              </b-field>
            </div>
            <div class="level-item">
              <b-field label="Country" style="width:120px;">
                <b-input v-model="lookup.country" ></b-input>
              </b-field>
            </div>
            
          </div>
        </div>
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <b-field label="State">
                <b-input v-model="lookup.state" style="width:60px;" ></b-input>
              </b-field>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
            
              <b-button type="is-info" class="has-margin-top-30" @click="searchGhin" :loading="isSearch">Search</b-button>
            
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="level is-mobile">
          <div class="level-left">
           
            <div class="level-item">
              <b-field label="Name">
                <b-input v-model="lookup.name" ></b-input>
              </b-field>
            </div>
            <div class="level-item">
              <b-field label="Country" >
                <b-autocomplete
                  v-model="courseCountry"
                  :data="filteredCountries"
                  field="name"
                  clearable
                  open-on-focus
                  @select="option=>selectCountry(option)"
                  append-to-body
                />
              </b-field>
            </div>
             <div class="level-item">
              <b-field label="State">
              
                <b-autocomplete
                  v-model="courseState"
                  :data="filteredStates"
                  field="name"
                  clearable
                  open-on-focus
                  @select="option=>selectState(option)"
                  append-to-body
                />
              </b-field>
            </div>
            <div class="level-item">
            
              <b-button 
                type="is-info" 
                class="has-margin-top-30" 
                @click="searchGhin" 
                :loading="isSearch"
                :disabled="emptySearch"
              >
                Search
              </b-button>
            
            </div>
          </div>
        </div>
      </template>
      <p>{{courses===null?'':courses.length===0?'No courses found':courses.length + pluralize(' course',courses.length) + ' found'}}</p>
      <b-table
        class="has-margin-top-10"
      
        style="padding-bottom: 10px;"
        bordered
        :selected.sync="selectedCourse"
        :mobile-cards="false"
        :data="courses===null?[]:courses" >
       
          <b-table-column field="name" label="Name" v-slot="props" sortable>
            {{props.row.name}}
          </b-table-column>
          <b-table-column field="location" label="Location" v-slot="props">
            {{ props.row.location }}
          </b-table-column>
          <b-table-column field="updated" label="Updated" v-slot="props">
            {{ props.row.updated }}
          </b-table-column>
         
      </b-table>
    </div>
    <footer class="modal-card-foot" style="justify-content: flex-end;">
      <b-button @click="closeDialog()" >Close</b-button>
      <b-button type="is-primary" @click="selectCourse" :disabled="isEmpty(selectedCourse)" >Select</b-button>
    </footer>
    
  </div>
   
       
</template>

<script>

import pluralize from 'pluralize'
import { isEmpty } from '../../lib'
import GhinController from '../../controllers/ghin'
import Course from '../../models/course'

export default {
  data () {
    return {
      lookup: {
        name: '',
        country: '',
        state: ''
      },
      courseCountry: '',
      courseState: '',
      isSearch: false,
      selectedCourse: {},
      courses: null,
      coursesAndStates: [],
      countries: [],
      states: []
    }
  },
  
  computed: {
    emptySearch () {
      let empty = true 
      if (this.lookup.name.length>0 || this.lookup.country.length>0 || this.lookup.state.length>0) empty=false
      return empty
    },
    filteredCountries() {
      return this.countries.filter((country) => {
        return country.name
          .toString()
          .toLowerCase()
          .indexOf(this.courseCountry.toLowerCase()) >= 0
      })
    },
    filteredStates() {
      return this.states.filter((state) => {
        return state.name
          .toString()
          .toLowerCase()
          .indexOf(this.courseState.toLowerCase()) >= 0
      })
    }
  },

  async mounted () {
    this.coursesAndStates = await GhinController.getCountriesAndStates()
    this.countries = this.coursesAndStates.map(c=>{
      return {
        name: c.name,
        courseCode: c.crs_code
      }
    })
  },
   

  methods: {
    isEmpty, pluralize,

    async searchGhin () {
      this.isSearch = true
      this.selectedCourse = {}

      this.courses = await GhinController.getCourses(this.lookup)

      this.isSearch = false
    },

    selectCountry (country) {
    
      if (country) {
        this.lookup.country = country.courseCode
        const ghinCountry = this.coursesAndStates.find(c=>c.name===country.name)
      
        if (ghinCountry !== undefined) {
          this.states = ghinCountry.states
        }
      }
      else {
        this.lookup.country = ''
        this.courseState = ''
        this.states = []
        this.lookup.state = ''
        this.courses = null
      }
      
    },
    selectState (state) {
      if (state) {
        this.lookup.state = state.course_code
      }
      else {
        this.lookup.state = ''
      }
      
      
    },
    async selectCourse () {
      const ghinCourse = await GhinController.getCourse(this.selectedCourse.id)
  
      const course = new Course()
     
      
      course.setFromGhin(ghinCourse)
      this.$emit('selectCourse',course)
      
    },
    
    closeDialog () {
      this.$parent.close()
    }
  }
}

</script>

<style>
 


</style>