import { db, fbFunc } from '../firebase'

import dayjs from 'dayjs'
import { formatHandicapSummary, newDate } from '../lib'

import Score from '../models/score'
import Round from '../models/round'
import Rounds from '../models/rounds'
import HandicapCalc from '../helpers/handicapCalc'

export default {
  onRound (context, queryInfo) {
    let unsubscribe = function (){}
    try {
      context.commit('unsubscribeRound')      
      const groupRef = db.collection('groups').doc(queryInfo.groupDocId)
      unsubscribe = groupRef.collection('rounds').doc(queryInfo.roundDocId)
        .onSnapshot( async roundDoc => {
         
          if (roundDoc.exists) {
        
            if (roundDoc.id === context.state.round.docId) {
              const round = context.state.round
              const scores = context.state.scores
  
              if (round === null) {
                round = new Round()
                round.docId = roundDoc.id
                context.state.round = round
              }
        
              const gamesBeforeStr = round.games.toJSON()
              const roundPayoutsBefore = round.roundPayouts
    
              round.setData(roundDoc.data())
  
              const gamesAfterStr = round.games.toJSON()
              const roundPayoutsAfter = round.roundPayouts
  
              if (scores) {
                scores.setRound (round)
                round.assignScores(scores.aScores)
              }
    
              if (gamesBeforeStr !== gamesAfterStr || roundPayoutsBefore !== roundPayoutsAfter) {

                context.commit('updateLeaderboards')
              }
            }

          }
        })
        context.commit('subscribeRound', unsubscribe) 
    }
    catch (error) {
      context.commit('errorMsg', 'onRound round.js - ' + error.code + ' ' + error.message)
    }
   
  
  },
  onRounds (context, queryInfo) {
    let unsubscribe = function (){}
    try {
      context.commit('unsubscribeRounds')
     
      context.commit('isLoadingRounds',true)

      const groupRef = db.collection('groups').doc(queryInfo.groupDocId)

      unsubscribe = groupRef.collection('rounds')
        .where('date','>=',queryInfo.season.fromDateTS).where('date','<=',queryInfo.season.toDateTS)
        .orderBy('date','desc')
        .onSnapshot( async snapshot => {
        
          if (context.state.rounds === null) context.commit('initRounds')
      
          for (let change of snapshot.docChanges()) {
            if (change.type === 'added') {
              let round = context.state.rounds.aRounds.find(r => r.docId === change.doc.id)
              if (round === undefined) {
                round = new Round()
                round.docId = change.doc.id
                round.groupDocId = context.state.group.docId
                context.state.rounds.add(round)
              }
         
              
              round.setData(change.doc.data())

              
              // context.commit('addRoundData', change.doc)

            }
            if (change.type === 'modified') {
              const round = context.state.rounds.aRounds.find(r => r.docId === change.doc.id)
              if (round !== undefined) round.setData(change.doc.data())
 
              // context.commit('setRoundData', change.doc)
   //           context.commit('user/updateUserRound', {docId: change.doc.id, data: change.doc.data()}, {root: true})
              // console.log('onRounds modify', change.doc.id)
            }
            if (change.type === 'removed') {
              if (context.state.rounds.exists(change.doc.id)) {
                context.state.rounds.deleteByDocId(change.doc.id)
                const tournamentDocId = change.doc.data().tournamentDocId
                if (tournamentDocId !== null) {
                  const tournaments = context.rootGetters['group/tournaments']
                  const tournament = tournaments.get(tournamentDocId)
                  if (tournament !== undefined) tournament.rounds.deleteByDocId (change.doc.id)
                }
              }
              // context.commit('deleteRoundByDocId', change.doc.id)
             
 
              // console.log('onRounds delete', change.doc.id)
            }
          }

          if (queryInfo.season.name !== context.state.currentSeasonRounds.name) {
           
            const latestSeason = context.state.group.latestSeason
          
            context.commit('currentSeasonRounds', latestSeason)
            context.dispatch('createSeasonStandings', latestSeason)
          }

          
       
          context.commit('sortRounds')

          context.commit('isLoadingRounds',false)

  
        })
      context.commit('subscribeRounds', unsubscribe)
    }
    catch (error) {
      context.commit('errorMsg', 'onRounds round.js - ' + error.code + ' ' + error.message)
    }
   
    
  },
  async getRounds (queryInfo) {
    const response = {
      errorMsg: ''
    }
    try {
    
      const groupRef = db.collection('groups').doc(queryInfo.groupDocId)
      const roundDocs = await groupRef.collection('rounds')
        .where('date','>=',queryInfo.season.fromDateTS)
        .where('date','<=',queryInfo.season.toDateTS)
        .get()

      const rounds = new Rounds()
      for (let doc of roundDocs.docs) {
        const round = rounds.addDoc(doc.id, doc.data())
        round.groupDocId = queryInfo.groupDocId
      }
      
      response.rounds = rounds
     
    }
    catch (error) {
      response.errorMsg = 'getRounds round.js - ' + error.code + ' ' + error.message
    }
    return response
  },
  async addRound (round) {
    const response = {
      errorMsg: ''
    }
    try {

      const groupRef = db.collection('groups').doc(round.groupDocId)

      // if (round.num === 0) {  // just created
      //   const roundSnapshot = await groupRef.collection('rounds')
      //     .where('season', '==', round.season)
      //     .orderBy('num','desc')
      //     .get()
  
      //   round.num = roundSnapshot.docs[0].data().num + 1
  
      // }

      const roundDoc = await groupRef.collection('rounds').add(round.getData())

      round.docId = roundDoc.id
      

    }
    catch(error) {
      response.errorMsg = 'addRound round.js - ' + error.message
    }
    return response
  },
  async updateRound (round) {
    const response = {
      errorMsg: ''
    }
    try {

      const groupRef = db.collection('groups').doc(round.groupDocId)

      await groupRef.collection('rounds').doc(round.docId).update(round.getData())

    }
    catch(error) {
      response.errorMsg = 'updateRound round.js - ' + error.message
    }
    return response
  },
  async updateRoundValues (groupDocId, roundUpdate) { // dbUpdate
   
    const response = {
      errorMsg: ''
    }
    try {
      const groupRef = db.collection('groups').doc(groupDocId)

      await groupRef.collection('rounds').doc(roundUpdate.docId).update(roundUpdate.update)
     
      
    }
    catch(error) {

      response.errorMsg = 'updateRoundValues round.js - ' + error.message
    }
    return response
  },

  async updateRoundsValues (groupDocId, roundUpdates) {

    const response = {
      errorMsg: ''
    }
    try {

      const groupRef = db.collection('groups').doc(groupDocId)

      if (roundUpdates.size === 1) {
        const roundUpdate = roundUpdates.aUpdates[0]
        await groupRef.collection('scores').doc(roundUpdate.docId).update(roundUpdate.update)
      }
      else {
        await db.runTransaction(async tx => {
          const updates = []
          roundUpdates.aUpdates.forEach(scoreUpdate => {
            const roundRef = groupRef.collection('scores').doc(roundUpdate.docId)
            updates.push(tx.update(roundRef, roundUpdate.update))
          })
          await Promise.all(updates)
        })
      }
      
    }
    catch(error) {
      response.errorMsg = 'updateRoundsValues round.js - ' + error.message
    }
    roundUpdates.clear()
    return response
  },

  async updatePcc (round, pccVal) {
    const response = {
      errorMsg: ''
    }
    try {

      const groupRef = db.collection('groups').doc(round.groupDocId)
      const roundRef = groupRef.collection('rounds').doc(round.docId)
      const scoreDocs = await groupRef.collection('scores').where('roundDocId','==',round.docId).get()

      const hcCalc = new HandicapCalc(round.course, round.handicap.maxHandicap)

      await db.runTransaction(async tx => {
        const updates = []

        updates.push(tx.update(roundRef, {pcc: pccVal}))

        for (const scoreDoc of scoreDocs.docs) {
          const update = {pcc: pccVal}
          if (round.isPosted) {
            update.diff = hcCalc.calcDiffObj(scoreDoc.data().score, scoreDoc.data().tee, pccVal, scoreDoc.data().gender)
          }
          updates.push(tx.update(scoreDoc.ref, update))
        }
       
        await Promise.all(updates)
      })
      

    }
    catch(error) {
      response.errorMsg = 'updatePcc round.js - ' + error.message
    }
    return response
  },
  async updateExcluded (round, excludeVal) {
    const response = {
      errorMsg: ''
    }
    try {

      const groupRef = db.collection('groups').doc(round.groupDocId)
      const roundRef = groupRef.collection('rounds').doc(round.docId)
      const scoreDocs = await groupRef.collection('scores').where('roundDocId','==',round.docId).get()

    

      await db.runTransaction(async tx => {
        const updates = []

        updates.push(tx.update(roundRef, {excluded: excludeVal}))

        for (const scoreDoc of scoreDocs.docs) {
          if (!scoreDoc.data().wd) {
            updates.push(tx.update(scoreDoc.ref, {exclude: excludeVal}))
          }
        }
       
        await Promise.all(updates)
      })
      

    }
    catch(error) {
      response.errorMsg = 'updatePcc round.js - ' + error.message
    }
    return response
  },
  async postRound (groupDocId, roundDocId, postInfo) {
    const response = {
      errorMsg: ''
    }
    try {

      const groupRef = db.collection('groups').doc(groupDocId)
      const roundRef = groupRef.collection('rounds').doc(roundDocId)

      await db.runTransaction(async tx => {
       
        tx.update(roundRef, postInfo.roundUpdate.update)

        postInfo.scoreUpdates.aUpdates.forEach(scoreUpdate => {
          const scoreRef = groupRef.collection('scores').doc(scoreUpdate.docId)
          tx.update(scoreRef, scoreUpdate.update)
        })
       
      })
      

    }
    catch(error) {
      response.errorMsg = 'postRound round.js - ' + error.message
      console.log("post error ", error.message)
    }
    return response
  },
  async unpostRound (round) {
    const response = {
      errorMsg: ''
    }
    try {
      round.status = 'Completed'
 
      const groupRef = db.collection('groups').doc(round.groupDocId)
      const roundRef = groupRef.collection('rounds').doc(round.docId)
      const scoreDocs = await groupRef.collection('scores').where('roundDocId','==',round.docId).get()

      await db.runTransaction(async tx => {
        await tx.update(roundRef, {status: 'Completed', posted:false})
        for (let scoreDoc of scoreDocs.docs) {
          await tx.update(scoreDoc.ref,{roundStatus: 'Completed', posted: false})
        }
      })
      

    }
    catch(error) {
      response.errorMsg = 'unPostRound round.js - ' + error.message
    }
    return response
  },
  async deleteRound (round) {
    const response = {
      errorMsg: ''
    }
    try {
      await db.collection('groups')
        .doc(round.groupDocId)
        .collection('rounds')
        .doc(round.docId)
        .delete()
// scores deleted in delete function
      
    }
    catch(error) {
      response.errorMsg = 'deleteRound round.js - ' + error.message
    }
    return response
  },
  async getRound (queryInfo) {

    const response = {
      errorMsg: ''
    }
    try {
      const groupRef = db.collection('groups').doc(queryInfo.groupDocId)

      const roundDoc = await groupRef.collection('rounds').doc(queryInfo.roundDocId).get()

      const round = new Round()
      round.docId = queryInfo.roundDocId
      round.groupDocId = queryInfo.groupDocId
    
      round.setData(roundDoc.data())
 
      response.round = round
    }
    catch(error) {
      response.errorMsg = 'getRound round.js - ' + error.message
    }
    return response
  },

  async hasRounds (groupDocId, season) {
    const response = {
      errorMsg: '',
      numRounds: 0
    }
    try {
    
      const roundDocs = await db.collection('groups')
        .doc(groupDocId)
        .collection('rounds')
        .where('date', '>=', season.fromDateTS)
        .where('date', '<=', season.toDateTS)
        .get()
  
      
      response.numRounds = roundDocs.size
  
    }
    catch(error) {
      response.errorMsg = 'hasRounds round.js - ' + error.message
    }
    return response
  },

  async signUp (round, player) {
    const response = {
      errorMsg: '',
      signedUpBefore: false
    }
    try {
      const groupRef = db.collection('groups').doc(round.groupDocId)

      const scoreDocs = await groupRef.collection('scores').where('roundDocId','==',round.docId).where('playerDocId','==',player.docId).get()
  
      let scoreDoc
      if (scoreDocs.empty) { // add player
        const score = new Score()
    
        score.name = player.name
        score.playerDocId = player.docId
        score.email = player.email
        score.type = player.type
        score.tee = player.tee
        score.gender = player.gender
        score.ghinNum = player.ghinNum
        score.signUpDate = new Date()
        // score.teeTimeRequest = teeTimeRequest
        score.setRound (round)
    

        scoreDoc = await groupRef.collection('scores').add(score.getData())

      }
      else {
    
        scoreDoc = scoreDocs.docs[0]
        response.signedUpBefore = true
      }
      response.scoreDocId = scoreDoc.id
    
    }
    catch(error) {
      response.errorMsg = 'signUp round.js - ' + error.message
    }
    return response
  },
 
  async cancelSignUp (signUpInfo) {
    const response = {
      errorMsg: ''
    }
    try {

      const scoreRef = db.collection('groups')
        .doc(signUpInfo.groupDocId)
        .collection('scores')
        .doc(signUpInfo.scoreDocId)

      await scoreRef.delete()
        
    }
    catch(error) {
      response.errorMsg = 'cancelSignup round.js - ' + error.message
    }
    return response
  },

  async sendInvitesEmail (group, round, players, fromEmail) {
    // const sendEmailBatch = fbFunc.httpsCallable('sendEmailBatch')  
    const sendEmailBatch = fbFunc.httpsCallable('sendMJEmail')
    
    const emailInfos = []

    for (let player of players) {
      
      const roundDate = dayjs(round.date).format('dddd MMM D, YYYY')
      // const respondByDate = dayjs(round.inviteRespondByDate).format('dddd MMM D, YYYY')

      const emailInfo = {
        templateId: 1430653,  

        to: [
          { Email: player.email }
        ],
        // from: fromEmail,
        replyTo: fromEmail,
        cc: [],
        subject: `${group.name} Round Invitation for ${roundDate}`,

        data: {
          groupName: group.name,
          roundDate: roundDate,
          roundName: round.name,
          courseLabel: round.course.label,
          acceptNeedTimeUrl: round.inviteUrl(process.env.VUE_APP_BASE_URL, player.docId, 1),
          acceptNoTimeUrl: round.inviteUrl(process.env.VUE_APP_BASE_URL, player.docId, 2),
          listUrl: round.inviteUrl(process.env.VUE_APP_BASE_URL, '', 3),
          // respondByDate: respondByDate,
          // body1: 'The Golf Shop will assign tee times.',
          // body2: `After ${respondByDate}, if you want to cancel, contact the Golf Shop and replace your name with a TBD in case someone else in our group wants your tee time.`,
          signedUp: player.autoSignUp
        }

        
      }
      
      emailInfos.push(emailInfo)

    }
    await sendEmailBatch(emailInfos)
   
  },

  async getEmails (roundInfo) {
    const response = {
      errorMsg: ''
    }
    try {
    
      const groupRef = db.collection('groups').doc(roundInfo.groupDocId)
      const scoreDocs = await groupRef.collection('scores').where('roundDocId','==',roundInfo.roundDocId).get()

      const emails = []
      for (let doc of scoreDocs.docs) {
        if (doc.data().email !== '') {
          emails.push(doc.data().email)
        }
      }
      
      response.emails = emails
     
    }
    catch (error) {
      response.errorMsg = 'getEmails round.js - ' + error.code + ' ' + error.message
    }
    return response
  },

  async sendTeeTimesEmail (fromEmail, round, emailData) {
    // const sendTeeTimes = fbFunc.httpsCallable('sendEmail')
    const sendTeeTimes = fbFunc.httpsCallable('sendMJEmail')

    const front = round.games.aGames.findIndex(g=>g.prop==='stroke' && g.side==='front') >= 0
    const back = round.games.aGames.findIndex(g=>g.prop==='stroke' && g.side==='back') >= 0

    const emailTeeTimes = round.teeTimes.aTeeTimes.map(teeTime => {
      const slots = []
      let name
      let score
      for (let i=0; i<4; i++){
        score = teeTime.scores[i]
        if (teeTime.scoreDocIds[i] === null) {
        }
        else if (teeTime.scoreDocIds[i] === '') {
          slots.push({
            name: 'Not in Game',
            tee: '',
            handicap: ''
          })
        }
        else {
          name = score.name
          if (score.type==='Guest') name += ' (G)'
       
          slots.push({
            name: name,
            tee: score.tee,
            handicap: formatHandicapSummary(score.handicap, score.indexSource, front, back)
          })
        }
       
      }
      
      return {
        groupNum: teeTime.groupNum,
        time: teeTime.time,
        startHole: teeTime.startHole,
        slots: slots
      }
    })

    let toArray = typeof emailData.to === 'string' ? emailData.to.split(',') : emailData.to
    toArray = toArray.filter(email => email!=='').map(email=>({Email:email}))

    let ccArray = typeof emailData.cc === 'string' ? emailData.cc.split(',') : emailData.cc
    ccArray = ccArray.filter(email => email!=='').map(email=>({Email:email}))

    // const emailInfo = {
    //   template: 'teeTimes',
    //   cc: ccArray,
    //   to: toArray,
    //   // to: 'Ken Elston <kjelston@gmail.com>',
    //   // to: {
    //   //   name: 'Ken Elston',
    //   //   email:  'kjelston@gmail.com'
    //   // },
    //   // to: [{
    //   //   Name: "Ken Elston",
    //   //   Email:  "kjelston@gmail.com"
    //   // }],
    //   // to: [{
    //   //   name: 'Ken Elston',
    //   //   address:  'kjelston@gmail.com'
    //   // }],
    
    //   from: fromEmail,
    //   groupName: emailData.groupName,
    //   title: emailData.title,
    //   msg: emailData.msg,
    //   roundDate: dayjs(round.date).format('dddd MMM D, YYYY'),
    //   courseLabel: round.course.label,
    //   teeTimes: emailTeeTimes
    // }

    const emailInfo = {
      templateId: 1425708,
      cc: ccArray,
      to: toArray,
      // to: [
      //   { Name: 'Ken Elston', Email:'kjelston@gmail.com' }
      // ],
      // from: fromEmail,
      replyTo: fromEmail,
      // from: { Name: 'Ken Elston', Email:'kjelston@gmail.com' },
      subject: emailData.subject,
      data: {
        groupName: emailData.groupName,
        msg: emailData.msg,
        roundDate: dayjs(round.date).format('dddd MMM D, YYYY'),
        courseLabel: round.course.label,
        teeTimes: emailTeeTimes
      }
    }

    await sendTeeTimes([emailInfo])

  }
}