import DBUpdate from './dbUpdate'


export default class DBUpdates {
  constructor() {
    this.initialize()
  }
  initialize () {
    this.aUpdates = []
  }

  hasUpdate () {
    if (this.aUpdates.length === 0) return false
    let hasUpdate = false
    for (let update of this.aUpdates) {
      if (update.hasUpdate()) {
        hasUpdate = true
        break
      }
    }
    return hasUpdate
  }
 
  addNew () {
    const update = new DBUpdate()
    this.aUpdates.push(update)
   
    return update
  }
  
  add (docId, update) {
    const updateIndex = this.aUpdates.findIndex(u => u.docId === docId)
    let dbUpdate
    if (updateIndex === -1) {
      dbUpdate = new DBUpdate(docId, update)
      this.aUpdates.push(dbUpdate)
    }
    else {
      dbUpdate = this.aUpdates[updateIndex]
      dbUpdate.add(update)
    }

    return dbUpdate
  }
 
  
  get size () {
    return this.aUpdates.length
  }
  
  clear () {
    this.aUpdates.length = 0
  }

  copy() {
    const dbUpdates = new DBUpdates()
    dbUpdates.setObj(this.getObj())
    return dbUpdates
  }

  toJSON () {
    return JSON.stringify(this.getObj())
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  getObj () {
    return this.aUpdates.map(update => update.getObj())
  }
  setObj (data) {
    this.aUpdates = data.map(updateObj => {
      const update = new DBUpdate()
      update.setObj(updateObj)
      return update
    })
  }
 
}

