<template>
  <div>
    <template v-if="scoresArray.length===0">
      <p class="subtitle has-margin-left-10">Create Pairings to Enter Scores</p>
    </template>
  
    <template v-else-if="!round.holeByHoleScores" >
      
      <div v-if="isMobile" >
        <table class="table" width="100%">
          <tbody>
            <tr v-for="(score, index) in scoresArray" :key="index" @click="openScoreDetails(score)">
              <td>
                <p>{{ score.fullSlotLabel() }}</p>
              </td>
              <td >
                <a >
                  <b-icon icon="chevron-right"/>
                </a>
                
              </td>
            </tr>
          </tbody>
        </table>
       
      </div>

      <div v-else class="b-table table-wrapper">
        <table class="table" >
          <thead>
            <tr>
              <th></th>
              <th class="text-center vert-bottom" v-for="(col,index) in columns1"
                :colspan="col.span" :key="index">
                {{ col.name }}
              </th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th @click.stop="sortByName()" style="cursor:pointer;">
                Name
                <b-icon
                  v-if="sortDir !== ''"
                  :icon="sortDir==='desc'?'arrow-up':'arrow-down'"
                  both
                  size="is-small"/>
              </th>
              <th class="text-center vert-bottom" v-for="(col,index) in columns2" :key="index" > {{ col.name }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(score, index) in scoresArray" >
              <tr v-if="!score.wd" :key="index">
          
                <td>
                  <a v-if="isAdmin && round.isNotPosted" class="has-text-link" @click="editPlayer(score)">{{ score.fullSlotLabel(round.games) }}</a>
                  <p v-else>{{ score.fullSlotLabel() }}</p>
                </td>
          
                <template v-if="isAdmin && round.isInProgress">
                  <td class="text-center vert-middle" v-for="(col,index) in columns2" :key="index" width="80"> 
                    <template v-if="col.name==='Score'">
                      <span v-if="full18 && col.side==='full18'">{{ score.score[col.side] }} </span>
                      <b-numberinput
                        v-else
                        class="text-center"
                        :value="Number(score.score[col.side])"
                        :controls="false"
                        :min="0" :max="200"
                        @blur="updateScore(score, col.side, $event.target.value)"
    
                      />
                    </template>
                  
                    <p class="has-text-weight-bold" v-if="col.name==='Net'">{{ score.netScore[col.side] }} </p>
                    <b-numberinput
                      v-if="col.name==='Number'"
                      class="text-center"
                      :value="Number(score[col.prop+'Count'])"
                      :controls="false"
                      :min="1" :max="18"
                      @blur="updateCount(score, col.prop, $event.target.value)"
                    />
                  </td>
                  
                </template>
                <template v-else>
                  <td class="text-center vert-middle" v-for="(col,index) in columns2" :key="index" width="80">

                    <span v-if="col.name==='Score'">{{ score.score[col.side] }} </span>
                  
                    <p class="has-text-weight-bold" v-if="col.name==='Net'">{{ score.netScore[col.side] }} </p>
                    <span v-if="col.name==='Number'">{{ score[col.prop+'Count'] }} </span>
                  </td>
                </template>
                  
              </tr>
            </template>
          </tbody>

        </table>
      </div>
    </template>
<!-- hole by hole scoring table -->
    <template v-else> 
      <template v-if="!editAllScores">
        <mobile-hole-scores />
      </template>
      <template v-else>
        <div class="buttons">
        <b-button v-if="isAdmin && round.isNotPosted"
         @click="showClearScores=true">
         Clear Scores...
        </b-button>
        <b-button v-if="isAdmin && round.isNotPosted"
         @click="fillScores">
         Fill Scores...
        </b-button>
        </div>

        <div class="b-table table-wrapper has-margin-top-10">
          <table class="table" >
            
            <tbody>
              <template v-if="round.games.hasScrambleGame()">
                <template v-for="(teamScore, index) in scrambleTeams">
                  <tr :key="index">
                    <td class="chevron-cell" width="30">
                      <a
                        role="button"
                        @click.stop="toggleDetails(teamScore.firstScore)">
                        <b-icon
                          icon="chevron-right"
                          both
                          :class="{'is-expanded': isVisibleDetailRow(teamScore.firstScore)}"/>
                      </a>
                    </td>
               
                    <td>{{ teamScore.fullSlotLabel() + ' ' + scoreStatusLabel(teamScore) }}</td>
                  </tr>
                  <tr v-if="isVisibleDetailRow(teamScore.firstScore)" class="detail" :key="index+'d'">
                    <td :colspan="columns2.length+3">
                      <div class="detail-container is-paddingless">
                        <template v-if="isMobile && round.isNotPosted">
                          <div class="b-table table-wrapper">
                            <table class="table is-narrow center-table">
                              <tbody>
                                <tr>
                                  <td class="chevron-cell-mobile" style="margin-left:0px;"  width="10">
                                    <a
                                    
                                      role="button"
                                      @click.stop="toggleFront(teamScore.firstScore)">
                                      <b-icon
                                        icon="chevron-right"
                                        both
                                        :class="{'is-expanded': isVisibleFrontRow(teamScore.firstScore)}"/>
                                    </a>
                                  </td>
                                  <td>FRONT</td>
                                  <td v-if="teamScore.holesCompleted>0" >{{teamScore.score('front')}} ({{toPar(teamScore, 'front')}})</td>
                                </tr>
                                <tr v-if="isVisibleFrontRow(teamScore.firstScore)" class="detail" :key="index+'f'">
                                  <td colspan="4">
                                    <div class="detail-container is-paddingless">
                                    
                                      <div class="b-table table-wrapper">
                                        <table class="table is-narrow center-table">
                                          <tbody>
                                            <tr v-for="n in 9" :key="n">
                                              <td width="80"><p>Hole {{n}} {{getHandicapDots(n, teamScore)}}</p></td>
                                              <td>Par {{getParHoles(n, teamScore.firstScore.tee)}} ({{getHandicapHoles(n, teamScore.firstScore.tee)}})</td>
                                            
                                              <td>
                                                <p v-if="readOnly" class="is-size-6">{{teamScore.getHoleScore(n)}}</p>
  
                                                <b-input
                                                  v-else
                                                  size="is-size-6"
                                                  style="width:40px;"
                                                  type="number"
                                                  :value="teamScore.getHoleScore(n)"
                                                  @blur="sumScore(teamScore.firstScore, n, Number($event.target.value))"
                                                  v-cleave="{numeral: true, numeralIntegerScale: 3, numeralThousandsGroupStyle: 'none', numeralDecimalScale: 0}"
                                                />
                                              </td>
                                            
                                             
                                              
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                  
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="chevron-cell-mobile" width="30">
                                    <a
                                  
                                      role="button"
                                      @click.stop="toggleBack(teamScore.firstScore)">
                                      <b-icon
                                        
                                        icon="chevron-right"
                                        both
                                        :class="{'is-expanded': isVisibleBackRow(teamScore.firstSscore)}"/>
                                    </a>
                                  </td>
                                  <td>BACK</td>
                                  <td v-if="teamScore.holesCompleted>9">{{teamScore.score('back')}} ({{toPar(teamScore.firstScore, 'back')}})</td>
                                </tr>
                                <tr v-if="isVisibleBackRow(teamScore.firstScore)" class="detail" :key="index+'b'">
                                  <td colspan="4">
                                    <div class="detail-container is-paddingless">
                                    
                                      <div class="b-table table-wrapper">
                                        <table class="table is-narrow center-table">
                                          <tbody>
                                            <tr v-for="n in 9" :key="n">
                                              <td width="80"><p>Hole {{n+9}} {{getHandicapDots(n+9, teamScore.firstScore)}}</p></td>
                                              <td>Par {{getParHoles(n+9, teamScore.firstScore.tee)}} ({{getHandicapHoles(n+9, teamScore.firstScore.tee)}})</td>
                                              <td >
                                                <p v-if="readOnly" class="is-size-6">{{teamScore.getHoleScore(n+9)}}</p>
                                                <b-input
                                                  v-else
                                                  size="is-size-6"
                                                  style="width:40px;"
                                                  type="number"
                                                  :value="teamScore.getHoleScore(n+9)"
                                                  @blur="sumScore(teamScore.firstScore, n+9, Number($event.target.value))"
                                                  v-cleave="{numeral: true, numeralIntegerScale: 3, numeralThousandsGroupStyle: 'none', numeralDecimalScale: 0}"
                                                />
                                              </td>
                                             
                                             
                                              
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                  
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </template>
                        <template v-else>
                          <hole-scores :score="teamScore.firstScore" editScore :round="round"  />
                        </template>
                      </div>
                    </td>
                  </tr>
                </template>
              </template>
              <template v-else>
                <template v-for="(score, index) in scoresArray" >
                
                  <tr v-if="!score.wd" :key="index">
                    <td class="chevron-cell" width="30">
                      <a
                      
                        role="button"
                        @click.stop="toggleDetails(score)">
                        <b-icon
                          icon="chevron-right"
                          both
                          :class="{'is-expanded': isVisibleDetailRow(score)}"/>
                      </a>
                    </td>
                    <template v-if="isMobile">
                      <td v-if="isAdmin && round.isNotPosted">
                        <a class="has-text-link" @click="editPlayer(score)">{{ score.fullSlotLabel() }}</a>
                        <p class="is-size-7">{{scoreStatusLabel(score)}}</p> 
                      </td>
                      <td v-else>
                        {{ score.fullSlotLabel() + ' ' + scoreStatusLabel(score) }}
                        <p class="is-size-7">{{scoreStatusLabel(score)}}</p> 
                      </td>

                    </template>
                    <template v-else>
                      <td v-if="isAdmin && round.isNotPosted"><a class="has-text-link" @click="editPlayer(score)">{{ score.fullSlotLabel() }}</a> {{scoreStatusLabel(score)}}</td>
                      <td v-else>{{ score.fullSlotLabel() + ' ' + scoreStatusLabel(score) }}</td>
                    </template>

                  </tr>
                  <tr v-if="isVisibleDetailRow(score)" class="detail" :key="index+'d'">
                    <td :colspan="columns2.length+3">
                      <div class="detail-container is-paddingless">
                        <template v-if="isMobile && round.isNotPosted">
                          <div class="b-table table-wrapper">
                            <table class="table is-narrow center-table">
                              <tbody>
                                <tr>
                                  <td class="chevron-cell-mobile" style="margin-left:0px;"  width="10">
                                    <a
                                    
                                      role="button"
                                      @click.stop="toggleFront(score)">
                                      <b-icon
                                        icon="chevron-right"
                                        both
                                        :class="{'is-expanded': isVisibleFrontRow(score)}"/>
                                    </a>
                                  </td>
                                  <td>FRONT</td>
                                  <td v-if="score.holesCompleted>0" >{{score.score.front}} ({{toPar(score, 'front')}})</td>
                                </tr>
                                <tr v-if="isVisibleFrontRow(score)" class="detail" :key="index+'f'">
                                  <td colspan="4">
                                    <div class="detail-container is-paddingless">
                                    
                                      <div class="b-table table-wrapper">
                                        <table class="table is-narrow center-table">
                                          <tbody>
                                            <tr v-for="n in 9" :key="n">
                                              <td width="80"><p>Hole {{n}} {{getHandicapDots(n, score)}}</p></td>
                                              <td>Par {{getParHoles(n, score.tee)}} ({{getHandicapHoles(n, score.tee)}})</td>
                                            
                                              <td>
                                                <p v-if="readOnly" class="is-size-6">{{score.getHoleScore(n)}}</p>
  
                                                <b-input
                                                  v-else
                                                  size="is-size-6"
                                                  style="width:40px;"
                                                  type="number"
                                                  :value="score.getHoleScore(n)"
                                                  @blur="sumScore(score, n, Number($event.target.value))"
                                                  v-cleave="{numeral: true, numeralIntegerScale: 3, numeralThousandsGroupStyle: 'none', numeralDecimalScale: 0}"
                                                />
                                              </td>
                                              <td v-if="round.games.hasGame('skins')">
                                                <p v-if="score.hasSkin(n)" class="is-size-6" >Skin</p>
                                              </td>
                                              <td v-if="round.games.hasGame('dots')">
                                                <p v-if="readOnly" class="is-size-6" >{{score.getDots(n)}}</p>
  
                                                <b-input
                                                  v-else
                                                  size="is-size-6"
                                                  style="width:40px;"
                                                  type="number"
                                                  :value="score.getDots(n)"
                                                  @blur="sumDots(score, n, Number($event.target.value))"
                                                  v-cleave="{numeral: true, numeralIntegerScale: 3, numeralThousandsGroupStyle: 'none', numeralDecimalScale: 0}"
                                                />
                                              </td>
                                              <td v-if="round.games.hasGame('kp')">
                                                <template v-if="isKPHole(n, score.tee)">
                                                  
                                                  <b-checkbox 
                                                    :disabled="readOnly"
                                                    :value="score.hasKP(n)" 
                                                    @input="value => setKP(score, n, value)" >
                                                    KP
                                                  </b-checkbox>
                                                  <vue-numeric
                                                    class="input text-center is-small"
                                                    :value="getKPDist(score, n)"
                                                    :disabled="readOnly"
                                                    currency=""
                                                    style="width:4em;"
                                                    thousand-separator=""
                                                    :min="0" 
                                                    :precision="0"
                                                    @blur="updateKPDist(score, n, $event.target.value)"
                                                    
                                                  /> 
                                                
                                                </template>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                  
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="chevron-cell-mobile" width="30">
                                    <a
                                  
                                      role="button"
                                      @click.stop="toggleBack(score)">
                                      <b-icon
                                        
                                        icon="chevron-right"
                                        both
                                        :class="{'is-expanded': isVisibleBackRow(score)}"/>
                                    </a>
                                  </td>
                                  <td>BACK</td>
                                  <td v-if="score.holesCompleted>9">{{score.score.back}} ({{toPar(score, 'back')}})</td>
                                </tr>
                                <tr v-if="isVisibleBackRow(score)" class="detail" :key="index+'b'">
                                  <td colspan="4">
                                    <div class="detail-container is-paddingless">
                                    
                                      <div class="b-table table-wrapper">
                                        <table class="table is-narrow center-table">
                                          <tbody>
                                            <tr v-for="n in 9" :key="n">
                                              <td width="80"><p>Hole {{n+9}} {{getHandicapDots(n+9, score)}}</p></td>
                                              <td>Par {{getParHoles(n+9, score.tee)}} ({{getHandicapHoles(n+9, score.tee)}})</td>
                                              <td >
                                                <p v-if="readOnly" class="is-size-6">{{score.getHoleScore(n+9)}}</p>
                                                <b-input
                                                  v-else
                                                  size="is-size-6"
                                                  style="width:40px;"
                                                  type="number"
                                                  :value="score.getHoleScore(n+9)"
                                                  @blur="sumScore(score, n+9, Number($event.target.value))"
                                                  v-cleave="{numeral: true, numeralIntegerScale: 3, numeralThousandsGroupStyle: 'none', numeralDecimalScale: 0}"
                                                />
                                              </td>
                                              <td v-if="round.games.hasGame('skins')">
                                                <p v-if="score.hasSkin(n+9)" class="is-size-6" >Skin</p>
                                              </td>
                                              <td v-if="round.games.hasGame('dots')">
                                                <p v-if="readOnly" class="is-size-6" >{{score.getDots(n+9)}}</p>
                                                <b-input
                                                  v-else
                          
                                                  size="is-size-6"
                                                  style="width:40px;"
                                                  type="number"
                                                  :value="score.getDots(n+9)"
                                                  @blur="sumDots(score, n+9, Number($event.target.value))"
                                                  v-cleave="{numeral: true, numeralIntegerScale: 3, numeralThousandsGroupStyle: 'none', numeralDecimalScale: 0}"
                                                />
                                              </td>
                                              <td v-if="round.games.hasGame('kp')">
                                              
                                                <template v-if="isKPHole(n+9, score.tee)">
                                                  
                                                  <b-checkbox 
                                                    :disabled="readOnly"
                                                    :value="score.hasKP(n+9)" 
                                                    @input="value => setKP(score, n+9, value)" >
                                                    KP
                                                  </b-checkbox>
                                                  <vue-numeric
                                                    class="input text-center is-small"
                                                    :value="getKPDist(score, n+9)"
                                                    :disabled="readOnly"
                                                    currency=""
                                                    style="width:4em;"
                                                    thousand-separator=""
                                                    :min="0" 
                                                    :precision="0"
                                                    @blur="updateKPDist(score, n+9, $event.target.value)"
                                                    
                                                  /> 
                                                
                                                </template>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                  
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </template>
                        <template v-else>
                          <hole-scores :score="score" editScore :round="round"  />
                        </template>
                      </div>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </template>
      
    </template>
    <b-switch v-if="isAdmin & round.isNotPosted" class="mt-4" v-model="editAllScores">Enter Scores for All Players</b-switch>
    <b-modal :active.sync="showPlayerEdit" has-modal-card :full-screen="isMobile" style="z-index:101">
      <player-edit :group="group" :round="round" :roundDate="round.date" :score="playerEditScore" :addGuest="false" :playerTees="playerTees" @saveModal="savePlayer"/>
    </b-modal>
    <b-modal 
      :active.sync="showScoreDetails"
      has-modal-card 
      full-screen
      :can-cancel="false"
      style="z-index:100"
    >
      <div class="modal-card" style="width:auto">
        <div class="modal-card-body">
          <div class="level is-mobile" @click="showScoreDetails=false">
            <div class="level-left">
              <div class="level-item">
                <a class="mt-5" >
                  <b-icon icon='chevron-left'></b-icon>
                </a>
              </div>
              <div class="level-item">
                 <p class="subtitle">{{scoreDetails?scoreDetails.fullSlotLabel():''}}</p>
 
              </div>
            </div>
          </div>
          <table class="table" width="100%">
            <tbody>
              <tr v-for="(game,index) in gamesArray" :key="index">
                <td class="has-text-weight-bold vert-middle">{{game.name + mobileHCLabel(scoreDetails, game)}}</td>
                <td v-if="game.isStrokeGame('front') || game.isStrokeGame('back')" class="has-text-centered vert-middle">
                  <b-numberinput
                    v-if="round.isInProgress"
                    class="text-center"
                    :value="Number(scoreDetails.score[game.side])"
                    :controls="false"
                    style="width:4em;"
                    :min="0" :max="200"
                    @blur="updateScore(scoreDetails, game.side, $event.target.value)"
                  />
                  <p v-else>{{scoreDetails.score[game.side]}}</p>
                </td>
                <td v-else-if="game.isStrokeGame('full18')" class="has-text-centered">
                  {{scoreDetails.score[game.side]}}
                </td>
                <td v-else-if="game.prop === 'skins' || game.prop === 'kp'" class="has-text-centered vert-middle">
                  <b-numberinput
                    v-if="round.isInProgress"
                    class="text-center "
                    :value="Number(scoreDetails[game.prop + 'Count'])"
                    :controls="false"
                    style="width:4em;"
                    :min="0" :max="50"
                    @blur="updateCount(scoreDetails, game.side, $event.target.value)"
                  />
                  <p v-else>{{scoreDetails[game.prop].count}}</p>
                </td>
                <td v-if="game.prop==='stroke'" class="has-text-centered vert-middle">
                  <p v-if="scoreDetails.score[game.side]!==''">{{toPar(scoreDetails, game.side)}}</p>
                </td>
                <td v-else>
                </td>
              </tr>
              <tr>
                <td>
                  <b-button @click="editPlayer(scoreDetails)">Edit Player</b-button>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
          
        
        </div>
      </div>
    
    </b-modal>


    <b-modal 
      :active.sync="showClearScores" 
      has-modal-card :full-screen="isMobile" 
      style="z-index:100;" 
    >
      <clear-scores @saveModal="clearScores" />
    </b-modal>
    
  </div>

</template>

<script>

import { mapGetters } from 'vuex'
import { sortArrayObj, formatToPar, handicapDotsLabel, getRandomScore } from "../../lib"
import pluralize from 'pluralize'

import playerEdit from '../Dialogs/PlayerEdit'
import Score from '../../models/score'
import HoleScores from './HoleScores'
import MobileHoleScores from './MobileHoleScores'
import ClearScores from './ClearScores'

import DBUpdate from '../../helpers/dbUpdate'
import DBUpdates from '../../helpers/dbUpdates'

export default {
  data () {
    return {
      adjustHandicaps: false,
      visibleDetailRows: [],
      visibleFrontRows: [],
      visibleBackRows: [],
      isNoHoleByHoleScoring: false,
      showClearScores: false,
      showPlayerEdit: false,
      playerEditScore: null,
      showScoreDetails: false,
      scoreDetails: new Score(),
      sortDir: '',
      editAllScores: false
    }
  },
  components: {
    'player-edit': playerEdit,
    'hole-scores': HoleScores,
    'mobile-hole-scores': MobileHoleScores,
    'clear-scores': ClearScores
  },

  mounted () {
    if (this.round.isPosted) this.editAllScores = true
  },

  // props: {
  //   scoringView: Number
  // },
  // watch: {
  //   tab(val) {
  //     if (val === 2) {
    
  //     }
  //   }
  
  // },

  computed: {

    scoresArray () {
      let scoresArray
      if (this.round === null) {
        scoresArray = []
      }
      else {
        
        scoresArray = this.round.teeTimes.scoresArray()
        if (scoresArray.length === 0) {
          scoresArray = this.$store.getters['round/scoresArray']
        }
      }
      return scoresArray
    },
    activeScoresArray () {  // exclude WD scores
      let scoresArray
      if (this.round === null) {
        scoresArray = []
      }
      else {
        scoresArray = this.round.teeTimes.scoresArray().filter(s=>!s.wd)
        if (scoresArray.length === 0) {
          scoresArray = this.$store.getters['round/scoresArray']
        }
      }
      return scoresArray
    },

    gamesArray () {
      return this.round.games.aGames
    },
   
    ...mapGetters('round', [
      'round',
      'tab',
      'leaderBoards'
    ]),
    ...mapGetters('group', ['group']),
    isAdmin () {
      return this.$store.getters['user/isAdmin']
    },
    isGuest () {
      return this.$store.getters['user/isGuest']
    },
    readOnly () {
      return !this.isAdmin
    },
    playerDocId () {
      return this.$store.getters['user/playerDocId']
    },
    playerTees () {
      return this.round.course.getPlayerTees()
    },
    columns1 () {
      const cols = []
      for(let game of this.gamesArray) {
        if(game.prop === 'stroke') {
          cols.push({name: game.name, span: 2})
        }
        else {
          cols.push({name: game.name, span: 1})
        }
      }
      return cols
    },
    columns2 () {
      const cols = []
      for(let game of this.gamesArray) {
        if(game.prop === 'stroke') {
          cols.push({name: 'Score', side: game.side})
          cols.push({name: 'Net', side: game.side})
        }
        else  {
          cols.push({name: 'Number', prop: game.prop})
        }
   
      }
      return cols
    },
    full18 () {
      const front = this.round.games.hasStrokeGame('front')
      const back = this.round.games.hasStrokeGame('back')

      return front && back
    },
    
    isScoringComplete () {

      let completed = true
      for (let score of this.activeScoresArray) {
       
        if (!score.isRoundComplete(this.round.numHoles)) {
          completed = false
          break
        }
      }
      return completed
    },

    scrambleTeams () {
      let teams = []
      const game = this.round.games.aGames.find(g=>g.prop==='stroke' && g.format==='scramble')
      if ( game !== undefined) {
        teams = game.teamScores.aTeams
      }
      return teams
    }
    
   
  },
  methods: {
    pluralize,

    sortByName () {
      if (this.sortDir === '') this.sortDir = 'asc'
      sortArrayObj(this.scoresArray,'name',this.sortDir)
      if (this.sortDir === 'asc') {
        this.sortDir = 'desc'
      }
      else {
        this.sortDir = 'asc'
      }
    },
   
    openScoreDetails (score) {
      this.scoreDetails = score
      this.showScoreDetails = true
    },
    

    scoreStatusLabel (sc) {
      let label = ''
      let score
      if (this.round.games.hasScrambleGame()) {
        score = sc.firstScore
      }
      else {
        score = sc
      }
      if (score.holesCompleted > 0) {
        let toPar = ''
        if (this.round.games.hasStablefordGame()) {
          const game = this.round.games.aGames.find(g=>(g.prop==='stroke' && g.side==='full18' && g.isStableford))
          toPar = formatToPar(score.toPoints('full18', game.useQuota))
        }
        else {
          toPar = this.toPar(score, 'full18')
        }
        label = `${score.score.full18} (${toPar}) thru ${score.holesCompleted}`
        if (score.skinsCount > 0) {
          let skinsData = ''
          if (score.skins.length>0) skinsData = score.skins.map(s=>`${s.holeNum} (${s.score})`).join(',')
          label += `, Skins: ${score.skinsCount} (${pluralize('hole',score.skinsCount)} ${skinsData})`
        }
        if (score.kpCount > 0) {
          let kpData = ''
          if (score.kp.length>0) kpData = score.kp.join(',') 
          label += `, KPs: ${score.kpCount} (${pluralize('hole',score.kpCount)} ${kpData})`
        }
      }
      return label
    },

    toPar (score, side='full18') {

      return formatToPar(score.toPar(side))
    },
   

    getHandicapHoles(holeNum, tee) {

      return this.round.handicapHoles(tee)[holeNum-1]
    },
    getParHoles (holeNum, scoreTee='') {
      let tee = ''
      if (scoreTee === '') {
      
        if (this.scoresArray.length > 0) tee = this.scoresArray[0].tee
      }
      else {
        tee = scoreTee
      }
   
      return this.round.parHoles(tee)[holeNum-1]
    },
    getHandicapDots (holeNum, score) {
      const strokes = score.getHandicapStrokesHole(holeNum,'full18')
      let dots = handicapDotsLabel(strokes)
  
      return dots
    },
    isKPHole (holeNum, tee) {
      return this.round.isKPHole(tee, holeNum) && this.round.games.hasGame('kp')
    },
   
    async setKP (score, holeNum, val) {
      await this.$store.dispatch('round/setKP', {score, holeNum, val})
    },


    

    updateScoreDetails(data) {
      this.updateScore(data.score, data.side, data.newValue)
    },
   
    async updateScore (score, side, newValue) {
      newValue = Number(newValue)
      if (newValue !== score.score[side] && newValue > 0) {
        const grossScore = {...score.score}
        // const netScore = {...score.netScore}
       
        grossScore[side] = newValue

        // const update = this.calcScore (grossScore, netScore, score.tee, score.handicap, side)

        const scoreUpdates = new DBUpdates()
        scoreUpdates.add(score.docId, {score: grossScore})
        await this.$store.dispatch('round/saveScoreValues', scoreUpdates)

      }

    },
    
    // calcScore (grossScore, netScore, tee, handicap, side) {
      
    //   if(grossScore[side] > 0) {
    //     netScore[side] = grossScore[side] - handicap[side]
    //   }
    //   else {
    //     netScore[side] = grossScore[side]
    //   }
      
    //   if(this.full18) {
    //     if (grossScore.back === 0) {
    //       grossScore.full18 = grossScore.front
    //     }
    //     else if (grossScore.front === 0) {
    //       grossScore.full18 = grossScore.back
    //     }
    //     else {
    //       grossScore.full18 = grossScore.front + grossScore.back
    //     }
          
    //     if (grossScore.full18 > 0) {
    //       netScore.full18 = grossScore.full18 - handicap.full18
    //     }
    //     else {
    //       netScore.full18 = grossScore.full18
    //     }
    //   }

    //   const update = {
    //     score: grossScore,
    //     netScore: netScore,
    //     toPar: {
    //       front: netScore.front - this.round.course.getPar(tee, 'front'),
    //       back: netScore.back  - this.round.course.getPar(tee, 'back'),
    //       full18: netScore.full18 - this.round.course.getPar(tee)
    //     }
    //   }

    //   return update
    // },
    async updateHandicap (score, side, newValue) {
      newValue = Number(newValue)
      if (newValue !== score.handicap[side]) {

        const handicap = {...score.handicap}
        handicap[side] = newValue
       
        // const update = updateHandicap(score, handicap, this.round)
        const scoreUpdates = new DBUpdates()
        scoreUpdates.add(score.docId, {handicap})

       
       
       
        await this.$store.dispatch('round/saveScoreValues', scoreUpdates)

      }
    },
    updateCountDetails (data) {
      this.updateCount(data.score, data.colProp, data.newValue)
    },
    async updateCount (score, colProp, newValue) {
      newValue = Number(newValue)

      if (newValue !== score[colProp+'Count']) {

        let update
        if (colProp === 'kp') {
          update = {
            kpCount: newValue
          }
        }
        if (colProp === 'skins') {
          update = {
            skinsCount: newValue
          }
        }
        if (colProp === 'dots') {
          update = {
            dotsCount: newValue
          }
        }
        const scoreUpdates = new DBUpdates()
        scoreUpdates.add(score.docId, update)
        await this.$store.dispatch('round/saveScoreValues', scoreUpdates)
      
      }
    
    
    },
   
    async updateHoleByHole () {
      // check and make sure all tees have holes defined
      let noHoles = false

      for (let tee of this.round.course.tees.tees) {
        if (tee.par.length !== 18 || tee.handicap.length !== 18) {
          noHoles = true
          break
        }
      }

      if (noHoles) {
        this.round.holeByHoleScores = false
        this.isNoHoleByHoleScoring = true
      }
      else {
       
        const roundUpdate = new DBUpdate(this.round.docId, {holeByHoleScores: this.round.holeByHoleScores})
        await this.$store.dispatch('round/saveValues', roundUpdate)
     
      }
  
    },
   
    toggleDetails(score) {
      const found = this.isVisibleDetailRow(score)
      if (found) {
        const i = this.visibleDetailRows.indexOf(score.docId)
        this.visibleDetailRows.splice(i, 1)
        
      } else {
        this.visibleDetailRows.push(score.docId)
      }
    
    },

    toggleFront(score) {
      const found = this.isVisibleFrontRow(score)
      if (found) {
        const i = this.visibleFrontRows.indexOf(score.docId)
        this.visibleFrontRows.splice(i, 1)
        
      } else {
        this.visibleFrontRows.push(score.docId)
      }
    
    },

    toggleBack(score) {
      const found = this.isVisibleBackRow(score)
      if (found) {
        const i = this.visibleBackRows.indexOf(score.docId)
        this.visibleBackRows.splice(i, 1)
        
      } else {
        this.visibleBackRows.push(score.docId)
      }
    
    },

    isVisibleDetailRow(score) {
      const result = this.visibleDetailRows.indexOf(score.docId) >= 0
      return result
    },

    isVisibleFrontRow(score) {
      const result = this.visibleFrontRows.indexOf(score.docId) >= 0
      return result
    },

    isVisibleBackRow(score) {
      const result = this.visibleBackRows.indexOf(score.docId) >= 0
      return result
    },

    async sumScore (score, holeNum, val) {
   
      await this.$store.dispatch('round/sumScore', {score, holeNum, val})
   
    },

  


    async sumDots (score, holeNum, val) {
       await this.$store.dispatch('round/sumDots', {score, holeNum, val})
    },
    
    editPlayer (score) {
      this.showPlayerEdit = true
      this.playerEditScore = score
    },

    async savePlayer (playerData) {
     
      let update = {}
      if (playerData.name !== this.playerEditScore.name) update.name = playerData.name
      if (playerData.email !== this.playerEditScore.email) update.email = playerData.email
      if (playerData.tee !== this.playerEditScore.tee) update.tee = playerData.tee
      if (playerData.ghinNum !== this.playerEditScore.ghinNum) update.ghinNum = playerData.ghinNum
      if (playerData.index.full18 !== this.playerEditScore.index.full18) update.index = playerData.index
      if (playerData.indexSource !== this.playerEditScore.indexSource) update.indexSource = playerData.indexSource
      if (playerData.handicap.full18 !== this.playerEditScore.handicap.full18 || 
        playerData.handicap.front !== this.playerEditScore.handicap.front ||
        playerData.handicap.back !== this.playerEditScore.handicap.back ) {

        const update2 = updateHandicap(this.playerEditScore, playerData.handicap, this.round)

        update = {...update, ...update2}

      
      }
      if (playerData.wd !== this.playerEditScore.wd) update.wd = playerData.wd
      if (playerData.exclude !== this.playerEditScore.exclude) update.exclude = playerData.exclude

     

      if (Object.keys(update).length > 0) {
        const scoreUpdates = new DBUpdates()
        scoreUpdates.add(this.playerEditScore.docId, update)
        await this.$store.dispatch('round/saveScoreValues', scoreUpdates)
      
      }
      
     
      this.playerEditScore = null
      
    },
    mobileHCLabel (score, game) {
      let label = ''
      if (score.handicap) {
        if (game.isStrokeGame('full18')) {
          label = score.handicap.full18
        }
        else if (game.isStrokeGame('front')) {
          label = score.handicap.front
        }
        if (game.isStrokeGame('back')) {
          label = score.handicap.back
        }
        if (label !== '') label = ' (Hdcp ' + label + ')'
      }
     
      return label
    },

    async clearScores (data) {
      const scoreUpdates = new DBUpdates()
      for (let score of this.activeScoresArray) {

        const sc = score.copy()
        sc.clearScores(data.clearOption, data.holes)
        let update = {
          exclude: sc.exclude,
          wd: sc.wd,
          final: sc.final,
          scores: sc.scores,
          score: sc.score,
          kp: sc.kp,
          kpDist: sc.kpDist,
          kpCount: sc.kpCount,
          skins: sc.skins,
          skinsCount: sc.skinsCount,
          dots: sc.dots,
          dotsCount: sc.dotsCount,
          payOut: sc.payOut,
          pcc: sc.pcc

        }
     
        scoreUpdates.add(score.docId, update)
      }
      await this.$store.dispatch('round/saveScoreValues', scoreUpdates)
     
    },
    getKPDist (score, groupHoleNum) {
      let dist = score.getKPDist(groupHoleNum)
     
      if (dist === '' || Number.isNaN(dist)) dist = 0


      return dist
    },

    async updateKPDist (score, holeNum, value) {
      const scoreUpdates = new DBUpdates()
      const scoreUpdated = score.copy()
      scoreUpdated.updateKPDist(holeNum, value)

      scoreUpdates.add(score.docId, {kpDist: scoreUpdated.kpDist})

      await this.$store.dispatch('round/saveScoreValues', scoreUpdates)

     
    },
  
    async fillScores () {
      this.$buefy.dialog.confirm({
        message:
          'Fill in with random scores?',
        onConfirm: async () => {
          for (let score of this.activeScoresArray) {
            for (let i=1; i<=18; i++) {
              const strokes = score.getHandicapStrokesHole (i, 'full18') 
              const toPar = score.getHolePar(i) + strokes
              await this.sumScore (score, i, getRandomScore(toPar))
            }
           
          }
       
        }
      })
      
    }
  }
}

</script>

<style lang="scss">

  .text-center {
    text-align: center !important;
  }
  .vert-middle {
    vertical-align: middle;
  }

  .vert-bottom {
    vertical-align: bottom !important; 
  }

  .header-cell {
    background-color: black;
    color: white;
    font-weight: bold;
  }

  .checkbox-cell-mobile {
      width: 20px;
      .checkbox {
          vertical-align: middle;
          .check {
              transition: none;
          }
      }
  }
 
 tr.rowhighlight td, tr.rowhighlight th{
    background-color:#caced1;
  }
  .group-score {
    width: 768px;
    flex: 0 0 768px;
  }

  .selected-player {
    border-width: 2px;
    border-style: solid;
    border-color: blue;

  }


</style>
