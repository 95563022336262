<template>
  <li>
    <a class="is-link" @click="selectItem(item)">
      <div class="level is-mobile">
        <div class="level-left">
          <div class="level-item">
            <b-icon :icon="icon" />
          </div>
          <div class="level-item">
            {{itemName}}
          </div>
        </div>
      </div>
    </a>
  </li>
</template>

<script>

export default {
  name: 'menuItem',
  data () {
    return {
    }
  },
  props: {
    icon: String,
    item: String,
    itemName: String
  },
  
  methods: {
    selectItem (item) {
      this.$emit('selected', item)
    }
   
  }

}
</script>