<template>
  <div>
   <toolbar/>
    <div class="column is-half is-offset-one-quarter">
      <div class="notification">
          <p class="subtitle has-text-centered">
            {{ msg1 }}
          </p>
          <p class="subtitle has-text-centered">
            {{ msg2 }}
          </p>
          <p class="subtitle has-text-centered">
            {{ msg3 }}
          </p>
      </div>
      <div class="column is-half is-offset-one-quarter" v-if="confirmedInvites.length > 0">
        <b-switch 
          v-if="scoreDocId !== ''" 
          class="subtitle" 
          v-model="teeTimeRequest" 
          @input="handleTeeTimeRequest"
          true-value="Please make me a tee time"
          false-value="I DO NOT need a tee time"
        >
          {{teeTimeRequest}}
        </b-switch>
        <p class="has-text-weight-bold mt-3" style="marginBottom:4px;">If you DON'T need a tee time, BE SURE to flip the switch above </p>
        <p class="has-text-weight-bold mt-3" style="marginBottom:4px;">Players Signed Up - {{ confirmedInvites.length }}</p>
        <ul>
          <li v-for="(inviteName, index) in confirmedInvites" :key="index">
            {{ inviteName }}
          </li>
         
        </ul>
      </div>
      <div class="column is-half is-offset-one-quarter" v-else-if="msg2.length > 0">
        <p class="has-text-weight-bold" style="marginBottom:4px;">No Players Signed Up</p>
      </div>
    </div>
 </div>
</template>

<script>
// localhost:8080/roundsignup?group=eWkX3BlQlQafqXP8QKSd&round=y9wRb2bTWJA8PThcEOcs&player=13&status=1

import GroupController from '../controllers/group'
import ScoreController from '../controllers/score'
import Toolbar from '../components/Shared/toolbar'
import { auth } from '../firebase'
import DBUpdates from '../helpers/dbUpdates'


export default {
  components: {
    'toolbar': Toolbar
  },
  data () {
    return {
      msg1: '',
      msg2: '',
      msg3: '',
      confirmedInvites: [],
      teeTimeRequest: 'Please make me a tee time',
      groupDocId: '',
      scoreDocId: ''
    }
  },
  async created () {
    await this.handleSignup(this.$route.query)
  },
  methods: {
    async handleSignup (query) {
      const inviteInfo = {}


      if (query.group === '' || query.group === undefined) {
        this.msg1 = 'There is an error with this invite'
    
        return
      }
      else {
        inviteInfo.groupDocId = query.group
      }

      if (query.roundId === '' || query.roundId === undefined) {
        this.msg1 = 'There is an error with this invite!'
      
        return
      }
      else {
        inviteInfo.roundDocId = query.roundId
      }

      if (query.status === '' || query.status === undefined) {
        this.msg1 = 'There is an error with this invite'

        return
      }
      else {
        inviteInfo.status = Number(query.status)
      }

      if (inviteInfo.status !== 3) {
        if (query.playerId === '' || query.playerId === undefined) {
          this.msg1 = 'There is an error with this invite'
  
          return
        }
        else {
          inviteInfo.playerDocId = query.playerId
        }
      }
      else {
        inviteInfo.playerDocId = ''
      }
      

      const user = this.$store.getters['user/user']

      if (user === null) {
        await auth.signInAnonymously()
      }

      const response = await GroupController.handleRoundInvite(inviteInfo)

      if (response.errorMsg.length > 0) {
        this.msg1 = response.errorMsg
       
      }
      else {
        this.groupDocId = inviteInfo.groupDocId
        this.scoreDocId = response.scoreDocId


        this.confirmedInvites = response.playerList
        if (inviteInfo.status === 1) {
          if (response.signedUpBefore) {
            this.msg1 = `You are already signed up to play with the ${response.group.name}`
          }
          else {
     
            this.msg1 = `You are signed up to play with the ${response.group.name}`
            
          }
          
          this.msg2 = response.round.formattedLongDate
          this.msg3 = response.round.course.label
        }
        // else if (inviteInfo.status === 2) {
        //   if (response.signedUpBefore) {
        //     if(dayjs().isAfter(dayjs(response.round.inviteRespondByDate))) {
        //       this.msg1 = 'You signup has been canceled, however you must also call the golf shop and cancel your tee time'
        //     }
        //     else {
        //       this.msg1 = 'You signup has been canceled!'
        //     }
            
        //     this.msg2 = response.round.formattedLongDate
        //     this.msg3 = response.round.course.label
        //   }
        //   else {
        //     this.msg1 = response.group.name
        //     this.msg2 = "No problem! We'll see you next time."
        //     this.msg3 = ''
        //   }
         
        // }
        else {
          this.msg1 = response.group.name
          this.msg2 = response.round.formattedLongDate
          this.msg3 = response.round.course.label
        }
        
      }
      // auth.signOut()
      // if (this.$store.getters['auth/isAnonymous']) {
      //   console.log('user anon delete')
      //   const uid = this.$store.getters['auth/uid']
      //   await auth.deleteUser(uid)
      //   this.$store.commit('auth/signOut')
      // }

  

    },

    async handleTeeTimeRequest () {
      if (this.groupDocId !== '' && this.scoreDocId !== '') {
        
        const scoreUpdates = new DBUpdates()
        scoreUpdates.add (this.scoreDocId, {teeTimeRequest: this.teeTimeRequest==='I DO NOT need a tee time'?'I DO NOT need a tee time':''})
        const response = await ScoreController.updateScoreValues (this.groupDocId, scoreUpdates)
      }
    }

    
  }
}
</script>
