<template>
    <div>
    <template v-if="round.isTournament">
      <b-tabs v-model="activeTab">
        <b-tab-item label="This Round">
          <round-payouts  />
        </b-tab-item>
        <b-tab-item label="Tournament">
          <tournament-payouts  />
        </b-tab-item>
     
      </b-tabs>
    </template>
    <template v-else>
      <round-payouts  />
    </template>

  </div>
</template>

<script>


import RoundPayouts from './RoundPayouts'
import TournamentPayouts from '../Tournament/Payouts'

export default {
  data() {
    return {
      activeTab: 0
    }
  },
  components: {
    'round-payouts': RoundPayouts,
    'tournament-payouts': TournamentPayouts
  },

  computed: {
    round () {
      return this.$store.getters['round/round']
    }
  },
  methods: {
    
  }
}
</script>

<style>
 
</style>
