import Vue from 'vue'
import Vuex from 'vuex'

import userModule from './userModule'
import groupModule from './groupModule'
import courseModule from './courseModule'
import roundModule from './roundModule'
import tournamentModule from './tournamentModule'
import authModule from './authModule'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user: userModule,
    group: groupModule,
    course: courseModule,
    round: roundModule,
    tournament: tournamentModule,
    auth: authModule
  }
})