<template>
 
    <div class="modal-card" >
      <header class="modal-card-head">
        <p v-if="isGuest" class="modal-card-title">{{ addGuest ? 'Add Guest Player' : 'Edit Guest Player' }}</p>
        <template v-else>
          <div class="media-content">
            <p class="title is-4">{{name}}</p>
            <p class="subtitle">{{email}}</p>
          </div>
        </template>
       
       
      </header>
      <div class="modal-card-body">
        <template v-if="isGuest">
          <b-field label="Name">
            <b-input v-model="name"  size="is-capitalized"/>
          
          </b-field>
          <b-field label="Email">
            <b-input v-model="email" type="email"/>
            
          </b-field>
        </template>
      
        <b-field grouped>
          <b-field label="Tee">
           <b-select
              v-model="tee"
              @input="updateHandicap()"
            >
              <option v-for="tee in playerTees" :value="tee" :key="tee">{{ tee }}</option>
            </b-select>
          </b-field>
          <b-field label="GHIN #">
            <b-input v-model="ghinNum" />
          </b-field>
          <b-field label="Gender">
            <b-select v-model="gender"  @input="updateHandicap()">
              <option value="M">M</option>
              <option value="F">F</option>
            </b-select>
          </b-field>
        </b-field>
 
        <b-field grouped>
          <b-field label="Index">
             <vue-numeric
              v-if="editIndex"
              class="input text-center"
              :value="indexInfo.getIndex('full18')"
              currency
              thousand-separator
              :precision="1"
              minus
              style="width:5em;"
              @blur="updateIndex($event.target.value)"
            />
            <p v-else class="has-margin-top-15">{{indexInfo.getIndex('full18')===null?'n/a':`${formatHandicap(indexInfo.getIndex('full18'))} (${indexInfo.indexSource})`}}</p>
          </b-field>
        
          <a class="control has-margin-top-35" @click="editIndex=!editIndex" >
            <b-icon icon='pencil'></b-icon>
          </a>
        
          <b-button v-if="useGameIndex || useGhinIndex" type="is-info" class="has-margin-top-30" @click="lookupIndex()">Lookup</b-button>
          
          <p class="control ml-4 has-margin-top-35">{{indexInfo.info}}</p>
  
        </b-field>
        <b-field grouped>
 
          <b-field label="Handicap">
            <p class="input is-static control">{{formatFullHandicap(handicapFull)}}</p>
          </b-field>
      
          <template v-if="!addGuest">
            <b-field label="Last Updated">
              <p class="input is-static control">{{ handicapDateFormatted }}</p>
            </b-field>
            <b-field label="Previous Handicap">
              <p class="input is-static control">{{ previousHandicap }}</p>
            </b-field>
          </template>
          
        </b-field>
       
        <template v-if="!addGuest">
          <b-field>
            <b-switch v-model="wd">Withdraw</b-switch>
          </b-field>
          <b-field>
            <b-switch v-model="exclude">Exclude this score from handicap calculation</b-switch>
          </b-field>
          <b-field label="Signed Up">
            <p>{{signedUpDateFormatted}}</p>
          </b-field>
        </template>
    
      </div>
      <footer class="modal-card-foot" style="justify-content: flex-end;">
        <b-button @click="cancelDialog" >Cancel</b-button>
        <b-button type="is-primary" @click="okDialog" >OK</b-button>
        
      </footer>

      <b-modal :active.sync="showHandicapDetails" has-modal-card>
        <handicap-details 
          :indexLookup = "indexLookup" 
          :handicapCalc = "handicapCalc"
          :playerInfo = "handicapPlayerInfo"
          :playerTees = "playerTees"
          :viewOnly = "false"
          :handicapFac = "handicapFac"
          @useHandicap="setIndex" />
      </b-modal>
      <b-modal :active.sync="showPlayerLookup" has-modal-card>
        <ghin-player-lookup :lookup="lookup" :indexLookup="indexLookup" @selectPlayer="selectPlayer" />
      </b-modal>
    
    </div>
 
</template>

<script>

import ScoreController from '../../controllers/score'
import IndexLookup from '../../helpers/indexLookup'
import HandicapCalc from '../../helpers/handicapCalc'
import IndexItem from '../../helpers/indexItem'
import HandicapDetails from '../Dialogs/HandicapDetails'
import GhinPlayerLookup from '../Dialogs/GhinPlayerLookup'

import { capitalizeWord, formatHandicap, formatHandicapObj } from "../../lib"

const parseFullName = require('parse-full-name').parseFullName

export default {
  data () {
    return {
      docId: this.score===null?'':this.score.docId,
      playerDocId: this.score===null?'':this.score.playerDocId,
      name: this.score===null?'':this.score.name,
      email: this.score===null?'':this.score.email,
      tee: this.score===null?'':this.score.tee,
      ghinNum: this.score===null?'':this.score.ghinNum,
      gender: this.gender===null?'M':this.score.gender,
      type: this.score===null?'Guest':this.score.type,
      indexInfo: new IndexItem(),
    
      handicapFull: this.score===null?{front:'',back:'',full18:''}:{...this.score.handicap},
      wd: this.score===null?false:this.score.wd,
      exclude: this.score===null?false:this.score.exclude,
      showHandicapDetails: false,
      indexLookup: null,
      handicapCalc: null,
      handicapPlayerInfo: {},
      handicapDateFormatted: this.score===null?'':this.score.handicapDateFormatted,
      signedUpDateFormatted: this.score===null?'':this.score.signedUpDateFormatted,
      previousHandicap: '',
      showPlayerLookup: false,
      lookup: {},
      editIndex: false
    }
  },
  props: ['group','round','roundDate','score','addGuest'],
  components: {
    'handicap-details': HandicapDetails,
    'ghin-player-lookup': GhinPlayerLookup
  },
  computed: {
    isGuest () {

      return this.type==='Guest'
      
    },
    useGameIndex () {
      let gameIndex = false
      if (this.round) gameIndex = this.round.handicap.useGameIndex
      return gameIndex
    },
    useGhinIndex () {
      let ghinIndex = false
      if (this.round) ghinIndex = this.round.handicap.lookupGhinIndex
      return ghinIndex
    },
    playerTees () {
      return this.round.course.getPlayerTees(this.gender)
    },
    handicapFac () {
      
      return this.round.getHandicapFac()
    }
    
  },

  mounted() {
    if (this.score !== null) {
      if (this.score.index !== null) {
        this.indexInfo.index = this.score.index.full18===null?null:this.score.index.full18
        this.indexInfo.indexSource = this.score.indexSource
      }
     
    }
  
    this.loadPreviousHandicap()
  },
 
  methods: {
    formatHandicap, formatHandicapObj,

    formatFullHandicap (handicapFull) {
      let label = ''
      if (handicapFull !== null) {
        if (handicapFull.full18 === '' || handicapFull.full18 === 'n/a') {
          label = 'n/a'
        }
        else {
          let handicapFacLabel = ''
        
          if (this.round.getHandicapFac().full18 < 1) {
    
            handicapFacLabel = `(${this.round.getHandicapFac().full18*100}%)`
          }
          label = `${formatHandicapObj(handicapFull,'full18')} (Front ${formatHandicapObj(handicapFull,'front')} Back ${formatHandicapObj(handicapFull,'back')}) ${handicapFacLabel}`
        }
      }
      
    
      return label
    },

    async loadPreviousHandicap () {
      if (!this.isGuest) {
        const response = await ScoreController.getPreviousPostedScore (this.group.docId, this.playerDocId, this.score.roundDate)
     
        if (response.score) {
          this.previousHandicap = `${formatHandicapObj(response.score.handicap,'full18')} (${response.score.tee}) on ${response.score.formattedDate}`
        }
        else {
          this.previousHandicap = 'n/a'
        }
      }
      else {
        this.previousHandicap = 'n/a'
      }
   
     
  
    },
    updateHandicap () {
      const hc = new HandicapCalc(this.round.course, this.round.handicap.maxHandicap)
      this.handicapFull = hc.getPlayingHandicapObj(this.indexInfo, this.tee, this.round.getHandicapFac(), this.gender)
    
    },

    updateIndex (val) {
      this.indexInfo.index = Number(val)
      const hc = new HandicapCalc(this.round.course, this.round.handicap.maxHandicap)
      this.handicapFull = hc.getPlayingCourseHandicapObj(this.indexInfo, this.tee, the.round.getHandicapFac, this.gender)
    
      this.indexInfo.indexSource = 'Manual'
      this.editIndex = false
    },

    lookupIndex () {
      const settings = {
        handicapSettings: this.round.handicap,
        groupDocId: this.group.docId,
        // state: this.round.course.state,
        // roundDate: this.roundDate,
        // lookupGroupScores: this.round.handicap.useGameIndex,
        // lookupGhinIndex: this.round.handicap.lookupGhinIndex
      }
   
      this.indexLookup = new IndexLookup(settings)

      if (this.useGameIndex) {
        this.handicapPlayerInfo = {
          // docId: this.docId,
          playerDocId: this.playerDocId,
          name: this.name,
          ghinNum: this.ghinNum,
          indexSource: this.indexInfo.indexSource,
          tee: this.tee,
          type: this.type,
          gender: this.gender,
          clubName: this.group.course.name,
          state: this.round.course.state
        }
        
        this.handicapCalc = new HandicapCalc(this.round.course, this.round.handicap.maxHandicap)
        this.showHandicapDetails = true
      }
      else {
        this.lookup = {
          state: this.round.course.state,
          firstName: parseFullName(this.name).first,
          lastName: parseFullName(this.name).last,
          ghinNum: this.ghinNum
        }
        this.showPlayerLookup = true
      }
    },
    

    setIndex (indexInfo) { // from handicap lookup dialog
 
      const hcCalc = new HandicapCalc(this.round.course, this.round.handicap.maxHandicap)
      this.indexInfo = indexInfo
      this.handicapFull = hcCalc.getPlayingHandicapObj(this.indexInfo, this.tee, this.round.getHandicapFac(), this.gender)
   
      if (this.ghinNum === '') this.ghinNum = indexInfo.ghinNum
    
    },

    selectPlayer (selectedPlayer) {
      this.showPlayerLookup = false
      const indexInfo = new IndexItem()
      indexInfo.index = selectedPlayer.index
      indexInfo.indexSource = 'GHIN'
      indexInfo.ghinNum = selectedPlayer.ghinNum
      this.setIndex ( indexInfo )
    
      
    },

    cancelDialog () {
      this.$parent.close()
    },
    okDialog () {
      this.$emit('saveModal', {
        name: capitalizeWord(this.name),
        email: this.email.toLowerCase(),
        tee: this.tee,
        ghinNum: this.ghinNum,
        indexInfo: this.indexInfo,
        handicap: this.handicapFull,
        gender: this.gender,
        wd: this.wd,
        exclude: this.exclude
      })
      this.$parent.close()
    }
   
  }
}

</script>

<style>
 
  

</style>