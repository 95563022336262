import { FSTimestampToDate, DateToFSTimestamp } from '../lib'
import dayjs from 'dayjs'

export default class Season {
  constructor() {
    this.name = dayjs().format('YYYY')
    this.fromDate = dayjs().startOf('year').toDate()
    this.toDate = dayjs().endOf('year').toDate()
  }

  get fromDateFormatted () {
    return dayjs(this.fromDate).format('YYYY')
  }

  get toDateFormatted () {
    return dayjs(this.toDate).format('YYYY')
  }

  get fromDateTS () {
    return DateToFSTimestamp(this.fromDate)
  }
  
  get toDateTS () {
    return DateToFSTimestamp(this.toDate)
  }

  copy () {
    const season = new Season()
    season.setObj(this.getObj())
    return season
  }

  toJSON () {
    return JSON.stringify(this.getObj())
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  getObj () {
    return {
      name: this.name,
      fromDate: new Date(Number(this.fromDate)),
      toDate: new Date(Number(this.toDate))
   
    }
  }
  setObj (data) {
    this.name = data.name,
    this.fromDate = new Date(Number(data.fromDate))
    this.toDate = new Date(Number(data.toDate))
    
  }
  getData () {
  
    return {
      name: this.name,
      fromDate: DateToFSTimestamp(this.fromDate),
      toDate: DateToFSTimestamp(this.toDate)
    }
  }
  setData (data) {
  
    this.name = data.name
    this.fromDate = FSTimestampToDate(data.fromDate)
    this.toDate = FSTimestampToDate(data.toDate)
  }
}
