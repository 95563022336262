
import { sortArrayObj } from '../lib'
import Tee from './tee'
import Tees from './tees'
import { getDataValue } from '../lib'


// const { sortArrayObj } = require('../lib')
// const Tee = require('./tee')
// const Tees = require('./tees')
 
export default class Course {
// class Course {

  constructor(objData) {
    if(objData) {
      this.setObj(objData)
    }
    else {
      this.initialize()
    }
   
  }
  initialize () {
    this.docId = ''
    this.ghinId = ''
    this.name = ''
    this.location = ''
    this.state = ''
    this.tees = new Tees()
  }
  get label () {
    let lab = this.name
    if( lab === '') {
      lab = this.location
    }
    else {
      lab = lab + ', ' + this.location
    }
    return lab
  }
  get searchName () {
    return this.name.toLowerCase()
  }
  getPlayerTees (gender='') {
    return this.tees.getPlayerTees(gender)
  }
  getSlope (shortTee, side='full18', gender='M') {
    return this.tees.getSlope(shortTee, side, gender)
  }
  getRating (shortTee, side='full18', gender='M') {
    return this.tees.getRating(shortTee, side, gender)
  }
  getPar (shortTee, side='full18', gender='M') {
    return this.tees.getPar(shortTee, side, gender)
  }
  getHolePar (holeNum, shortTee, gender='M') {
    const par = this.tees.getTee(shortTee, gender).par
    let parVal = 0
    if (holeNum >= 1 && holeNum <= 18) parVal = par[holeNum-1]
    return parVal
  }
 
  getPar3HoleNums () {
    const holeNums = []
    const pars = this.tees.tees[0].par // use first tee...assumes par 3s are the same for all tees
    for (let i=0; i<=pars.length; i++) {
      if (pars[i] === 3) holeNums.push(i+1)
    }
    return holeNums
  }
  teeExists (shortTee, gender='M') {
    return this.tees.teeExists(shortTee, gender)
  }
  isNew () {
    return this.docId === ''
  }

  setFromGhin (ghinCourse) {
    this.ghinId = ghinCourse.CourseId
    if (ghinCourse.FacilityName === ghinCourse.CourseName) {
      this.name = ghinCourse.CourseName
    }
    else {
      this.name = ghinCourse.Facility.FacilityName + ' - ' + ghinCourse.CourseName
    }
    
    this.location = ghinCourse.CourseCity
    this.state = ghinCourse.CourseState

    for (let teeSet of ghinCourse.TeeSets) {
      const tee = new Tee()
      tee.name = teeSet.TeeSetRatingName
      tee.shortName = tee.generateShortName()
      tee.gender = teeSet.Gender[0]
      for (let rating of teeSet.Ratings) {
        if (rating.RatingType === 'Total') {
          tee.rating.full18 = rating.CourseRating
          tee.slope.full18 = rating.SlopeRating
        }
        else if (rating.RatingType === 'Front') {
          tee.rating.front = rating.CourseRating
          tee.slope.front = rating.SlopeRating
        }
        else if (rating.RatingType === 'Back') {
          tee.rating.back = rating.CourseRating
          tee.slope.back = rating.SlopeRating
        }
      }
      sortArrayObj (teeSet.Holes, 'Number', 'asc')
      for (let hole of teeSet.Holes) {
        tee.par.push(hole.Par)
        tee.yards.push(hole.Length)
        tee.handicap.push(hole.Allocation)
      }
      this.tees.add (tee)
    }
  }
  getData () {
    return {
      ghinId: this.ghinId,
      name: this.name,
      location: this.location,
      state: this.state,
      searchName: this.searchName,
      tees: this.tees.getData()
    }
  }
  setData (data) {
    this.ghinId = getDataValue(data, 'ghinId', '')
    this.name = data.name
    this.location = data.location
    this.state = data.state
    this.tees.setData(data.tees) 
  }
  toJSON () {
    return JSON.stringify(this.getObj())
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  getObj () {
    return {
      docId: this.docId,
      ghinId: this.ghinId,
      name: this.name,
      location: this.location,
      state: this.state,
      tees: this.tees.getObj()
    }
  }
  setObj (data) {
    this.docId = data.docId
    this.ghinId = data.ghinId
    this.name = data.name
    this.location = data.location
    this.state = data.state
    this.tees.setObj(data.tees)
  }
  copy () {
    const course = new Course()

    course.docId = this.docId
    course.name = this.name
    course.location = this.location
    course.state = this.state
    course.tees = this.tees.copy()

    

    return course
  }
  
}

// module.exports = Course