
import { capitalizeWord, getDataValue } from '../lib'


export default class User {
  constructor() {
    this.initialize()
    
  }
  initialize () {
    this.docId = ''
    this._name = ''

    this.tee = ''
    this.ghinNum = ''
    this.email = ''
    this.gender = 'M'
  
    this.groupAdmin = false
    this.groupName = ''
    this.groupStatus = 'Inactive'
    this.groups = []
    this.groupDocId = ''
    this.playerDocId = ''
  
    

  }
  get name () {
    return this._name
  }
  set name (val) {
    this._name = capitalizeWord(val)
  }

  get emailAddress () {
    return `${this._name} <${this.email}>`
  }

  get emailAddressObj () {
    return {Name: this._name, Email: this.email}
  }

  get isGuest () {
    this.playerDocId === ''
  }
  
  isNew () {
    return this.docId === ''
  }
 
  
  copy () {
    const user = new User()
    user.setObj(this.getObj())
    return user
  }
  toJSON () {
    return JSON.stringify(this.getObj(),null,2)
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  getObj () {
    return {
      docId: this.docId,
      name: this._name,
      email: this.email,
      gender: this.gender,
      groupName: this.groupName,
      groupAdmin: this.groupAdmin,
      grouoStatus: this.groupStatus,
      groupDocId: this.groupDocId,
      groups: this.groups.map(g => ({...g})),
      playerDocId: this.playerDocId
    }
  }
  setObj (data) {
    this.docId = data.docId,
    this._name = data.name
    this.email = data.email
    this.groupDocId = data.groupDocId
    this.gender = data.gender
    this.groupName = data.groupName
    this.groupAdmin = data.groupAdmin
    this.groupStatus = data.groupStatus
    this.groups = data.groups.map(g => ({...g}))
    this.playerDocId = data.playerDocId
  }
  getData () {
    return {
      name: this._name,
      email: this.email,
      gender: this.gender,
      groupDocId: this.groupDocId,
      groupStatus: this.groupStatus,
      groups: this.groups.map(g=>({docId: g.docId})),
      playerDocId: this.playerDocId
    }
  }
  setData (data) {
    this._name = data.name
    this.email = data.email
    this.gender = getDataValue(data,'gender','M')
    this.groupDocId = data.groupDocId
    this.groupStatus = getDataValue(data,'groupStatus','Inactive')
    if (this.groupStatus === '') this.groupStatus = 'Inactive'
    this.groups = data.groups
    this.playerDocId = data.playerDocId

    for (let groupObj of this.groups) {
      groupObj.name = ''
    }

  }
}


