<template>
  <div>
    <div class="level is-mobile has-margin-bottom-5">
      <div class="level-left">
        <div v-if="!readOnly" class="level-item">
          <b-button @click="showCreateRound=true">Add Rounds</b-button>
        </div>
      </div>
    </div>
    <template v-if="isMobile">
      <div class="panel" v-for="(round,index) in roundsArray" :key="index">
        <p class="panel-heading">
          {{`Round ${round.roundNum} - ${round.formattedDate}`}}
        </p>
        <div class="panel-block">
          <p>{{round.course.label}}</p>
        </div>
        <div class="panel-block">
          <p>{{round.status}}</p>
        </div>
        <div class="panel-block>">
           <b-button type="is-primary" @click="openRound(round)">Open</b-button>
        </div>
      </div>
    </template>
    <template v-else>

      <b-table 
        :data="roundsArray"         
        default-sort="date" 
        default-sort-direction="asc"
      >
      

        <b-table-column
          cell-class="vert-middle"
          label="Date"
          field="date"
          width="140"
          :sortable="!isMobile"
          v-slot="props"
        >
          <p>{{ props.row.formattedDate}}</p>

        </b-table-column>

      <b-table-column
          cell-class="vert-middle"
          label="Round"
          width="60"
          centered
      
          v-slot="props"
        >
        <p>{{ props.row.roundNum}}</p>

        </b-table-column>

        <b-table-column
          cell-class="vert-middle"
          label="Name"
          field="name"
          v-slot="props"
        >
          <p>{{ props.row.name}}</p>

        </b-table-column>

        <b-table-column
          cell-class="vert-middle"
          field="course.label"
          label="Course"
          v-slot="props"
        
        >
          {{ props.row.course.label}}
        </b-table-column>

        <b-table-column
          cell-class="vert-middle"
          field="numPlayers"
          label="Players"
          width="50"
          centered
          :sortable="!isMobile"
          v-slot="props"
        >{{ props.row.numPlayers}}
        </b-table-column>

        <b-table-column
          cell-class="vert-middle"
          field="status"
          label="Status"
          :sortable="!isMobile"
          v-slot="props"
        >{{ props.row.excluded?'Excluded':props.row.status}}
        </b-table-column>

        <b-table-column cell-class="vert-middle" width="100" v-slot="props">
        
          <b-button type="is-primary" @click="openRound(props.row)">Open</b-button>
            
        </b-table-column>
    
      </b-table>
    </template>

    <b-modal :active.sync="showCreateRound" has-modal-card >
      <create-round @saveModal="addRounds"/>
    </b-modal>
  </div>
</template>

<script>


import { mapGetters } from "vuex"
import CreateRound from '../Dialogs/CreateRound'
import Tournament from '../../models/tournament'

export default {
  data() {
    return {
      showCreateRound: false
    
    }
  },
  
  components: {
    'create-round': CreateRound
  },
  computed: {
    roundsArray () {
      return this.tournament.rounds.aRounds
    },
    ...mapGetters('tournament', [
      'tab',
      'currentSeasonRounds'
    ]),
    tournament () {
      let tournament = this.$store.getters['tournament/tournament']
      if (tournament === null) tournament = new Tournament()
      return tournament
    },
    group () {
      return this.$store.getters['group/group']
    },
    isAdmin () {
      return this.$store.getters['user/isAdmin']
    },
    readOnly () {
      return !this.isAdmin
    },
   

  },
  methods: {


    async addRounds(roundDates) {
      await this.$store.dispatch("tournament/addRounds", roundDates)
    },
    openRound(round) {
      this.loading = true

      this.$store.dispatch('round/open', {groupDocId: this.group.docId, roundDocId: round.docId, playerScoreDocId: '', roundNum: round.roundNum, from: 'tournament'})
 
      this.loading = false
    }
    


   
    
  }
};
</script>

<style>
.vert-middle {
  vertical-align: middle !important;
}
.scrollable {
  max-height: 560px;
  overflow-y: auto;
}




</style>
