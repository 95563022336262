

export default class PointsTable {
  constructor() {
    this.rows = this.defaultPoints()
  }

  defaultPoints () {
    return [
      {toPar: -4, points: 7},
      {toPar: -3, points: 6},
      {toPar: -2, points: 5},
      {toPar: -1, points: 4},
      {toPar: 0, points: 3},
      {toPar: 1, points: 2},
      {toPar: 2, points: 1},
      {toPar: 3, points: 0}
    ]
  } 

  size () {
    return this.rows.length
  }
  copy () {
    const table = new PointsTable()
    table.setObj(this.getObj())
    return table
  }
  
 
  clear () {
    this.rows = []
   
  }
 
  getPoints (toPar) {
   
    if (toPar < -4) toPar = -4
    if (toPar > 3) toPar = 3

    const pointsObj = this.rows.find(r=>r.toPar===toPar)

    return pointsObj.points

    
  }

  getPointsQuota (holeNum=18) {
    const row = this.rows.find(r=>r.toPar===0)
    let points = 0
    for (let i=1; i<=holeNum; i++) {
      points += row.points
    }
    return points
  }

  getData () {
    return this.rows
  }
  setData (data) {
    this.rows = data
  }
  getObj () {
    return this.rows.map(r=>({...r}))
  }
  setObj (data) {
    this.rows = data.map(r=>({...r}))
  }
}





