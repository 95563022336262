<template>
  <div>
    <template v-if="isMobile">
      <table class="table is-narrow is-size-6" width="100%">
        <thead>
          <tr>
            <th class="has-text-centered">HOLE</th>
            <th class="has-text-centered">KP</th>
            <th class="has-text-centered">NAME</th>
            <th class="has-text-centered">DIST</th>
            <th class="has-text-centered">GROUP</th>
          
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row,index) in leaderBoardArray" :key="index" @click="openHoleScores(row)">
            <td class="has-text-centered" >{{ row.holeNum }}</td>
            <td>
              <b-icon v-if="row.kpPay===1" icon="check" />
              <p class="has-text-danger" v-else-if="row.kpPay===2">NO PAR</p>
              <p class="has-text-danger" v-else>CUT</p>
            </td>
            
            <td>{{ `${row.name} (${row.tee})` }}</td>
            <td  >{{ formatDist(row.kpDist) }}</td>
            <td class="has-text-centered" >{{ row.groupNum}}</td>
            
           
            <td v-if="round.holeByHoleScores" >
              <a >
                <b-icon icon="chevron-right"/>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <template v-else>
      <table class="table" width="100%">
        <thead>
          <tr>
            <th v-if="round.holeByHoleScores"></th>
            <th class="has-text-centered">HOLE</th>
            <th class="has-text-centered">KP</th>
            <th>NAME</th>
            <th class="has-text-centered">DISTANCE</th>
            <th class="has-text-centered">GROUP</th>
            <th class="has-text-centered" v-if="round.betOnGames">PAYOUT</th>
            <th class="has-text-centered">THRU</th>
            <th v-if="round.holeByHoleScores"></th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(row,index) in leaderBoardArray">
            <tr  :key="index">
              <td v-if="round.holeByHoleScores" class="chevron-cell" width="30">
                <a
                  role="button"
                  @click.stop="toggleDetails(index)">
                  <b-icon
                    icon="chevron-right"
                    both
                    :class="{'is-expanded': isVisibleDetailRow(index)}"/>
                </a>
              </td>
              <td class="has-text-centered" width="40">{{ row.holeNum }}</td>
              <td class="has-text-centered" width="80">
                <b-icon v-if="row.kpPay===1" icon="check" />
                <p class="has-text-danger" v-else-if="row.kpPay===2">NO PAR</p>
                <p class="has-text-danger" v-else>CUT</p>
              </td>
              <td>{{ row.nameLabel }}</td>
              <td  class="has-text-centered" width="40" >{{ formatDist(row.kpDist, 'ft') }}</td>
              <td class="has-text-centered" width="40">{{ row.groupNum }}</td>
              <td v-if="round.betOnGames" class="has-text-centered" width="100">{{ formatCurrency(row.payOut) }}</td>
              <td class="has-text-centered" width="40">{{ row.thru }}</td>
              
            </tr>
            <tr v-if="isVisibleDetailRow(index)" class="detail" :key="index+'d'">
              <td :colspan="7">
                <div class="detail-container is-paddingless">
                  <hole-scores :score="getScore(row.docId)" :editScore="false" :round="round" />
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </template>
    <b-modal 
      :active.sync="showHoleScores"
      has-modal-card 
      full-screen
      :can-cancel="false"
      style="z-index:100;"
     >
      <div class="modal-card" style="width:auto">
        <div class="modal-body">
          <div class="level is-mobile has-margin-bottom-5" @click="showHoleScores=false">
            <div class="level-left">
              <div class="level-item">
                <a class="mt-2" >
                  <b-icon icon='chevron-left'></b-icon>
                </a>
              </div>
              <div class="level-item">
                <p class="subtitle">{{activeScore.name}}</p>
              </div>
            </div>
          </div>


          <hole-scores 

            :score="activeScore" 
            :editScore="false" 
            :round="round" 
           
          />
        </div>
      </div>
     
  
    </b-modal>
  </div>
</template>

<script>

import { formatCurrency, formatToPar, formatDist } from "../../lib"

import HoleScores from './HoleScores'

import Score from '../../models/score'

export default {
  data() {
    return {
      visibleDetailRows: [],
      showHoleScores: false,
      activeScore: new Score(),
      activeRow: {holes:[]}
    }
  },
  props: ['round','leaderBoardArray'],

  components: {
    'hole-scores': HoleScores
  },

  computed: {

    scoresArray () {
      return this.round.teeTimes.scoresArray()
    }
  },
  
  methods: {
    formatDist, 
    formatCurrency,

    getScore (docId) {
      let score = this.scoresArray.find(s=>s.docId===docId)
      if (score ===  undefined) {
        score = new Score()
      }
      return score
    },
   

    toggleDetails (index) {
      const found = this.isVisibleDetailRow(index)
      if (found) {
        const i = this.visibleDetailRows.indexOf(index)
        this.visibleDetailRows.splice(i, 1)
        
      } else {
        this.visibleDetailRows.push(index)
      }
    
    },
    isVisibleDetailRow (index) {
      const result = this.visibleDetailRows.indexOf(index) >= 0
      return result
    },
    openHoleScores (row) {
      this.showHoleScores = true
   
      this.activeScore = this.getScore(row.docId)
     
      this.activeRow = row
    }
  
  }
}
</script>

<style>
 
  .text-center {
    text-align: center !important;
  }
  .vert-bottom {
    vertical-align: bottom !important;
  }
</style>
