
<template>
  <div>

    <b-field label="Tournament Name">
      <b-input :value="tournament.name" @blur="updateTournamentName($event.target.value)" />
    </b-field>
     <b-field>
      <b-switch :value="tournament.betOnGames" :disabled="tournament.isPosted" @input="value=>updateBetOnGames(value)">Bet On Games</b-switch>
    </b-field >


    <b-button type="is-danger" outlined @click="$emit('delete-tournament')" >Delete this tournament</b-button>

  </div>
</template>

<script>


import { mapGetters } from "vuex"
import DBUpdate from '../../helpers/dbUpdate'
import Tournament from '../../models/tournament'

export default {
  data() {
    return {
  
    }
  },
 
  

  computed: {
    ...mapGetters('tournament', [
      'tab'
    ]),
    tournament () {
      let tournament = this.$store.getters['tournament/tournament']
      if (tournament === null) tournament = new Tournament()
      return tournament
    }
  },
  

  methods: {
    async updateTournamentName (name) {
      const tournamentUpdate = new DBUpdate(this.tournament.docId, {name: name})
      
      await this.$store.dispatch('tournament/saveValues', tournamentUpdate)
  
    },
    async updateBetOnGames (val) {
      const tournamentUpdate = new DBUpdate(this.tournament.docId, {betOnGames: val})
      await this.$store.dispatch('tournament/saveValues', tournamentUpdate)
  
    }
    

  }
}
</script>

<style scoped>

</style>
