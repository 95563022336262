
import { fbFunc } from '../firebase'

import store from '../store'

export default {
  
 
  async getHandicapGhinScores (playerInfo) {

    if (playerInfo.state === null || playerInfo.state === undefined) playerInfo.state=''

    const appSettings = store.getters['auth/appSettings']

    const lookupGhinScores = fbFunc.httpsCallable('lookupGhinScores')
    let response = { data: {errorMsg: '', scoresArray: [], index: '', appSettings}}
    const data = {playerInfo, appSettings}
    try {
      response = await lookupGhinScores(data)
    }
    catch (error) {
      console.log('getHandicapScores', playerInfo.lastName, error.message)
      // response.data.errorMsg = playerInfo.name + ' ' + error.message
    }
    
    store.commit('auth/appSettings', response.data.appSettings)

    return response.data
  },

  async getGhinPlayers (playerInfo) {

    if (playerInfo.state === null || playerInfo.state === undefined) playerInfo.state=''
    if (playerInfo.ghinNum === null || playerInfo.ghinNum === undefined) playerInfo.ghinNum=''

    const appSettings = store.getters['auth/appSettings']

    const lookupGhinPlayers = fbFunc.httpsCallable('lookupGhinPlayers')

    const response = await lookupGhinPlayers({playerInfo, appSettings})

    store.commit('auth/appSettings', response.data.appSettings)

    return response.data.playersArray
  },

  

  async getCourses (lookupInfo) {


    const lookupGhinCourses = fbFunc.httpsCallable('lookupGhinCourses')

    const appSettings = store.getters['auth/appSettings']

    const response = await lookupGhinCourses({lookupInfo, appSettings})

    store.commit('auth/appSettings', response.data.appSettings)

    return response.data.coursesArray
  },

  async getCourse (courseId) {

    const lookupGhinCourse = fbFunc.httpsCallable('lookupGhinCourse')

    const appSettings = store.getters['auth/appSettings']

    const response = await lookupGhinCourse({courseId, appSettings})

    store.commit('auth/appSettings', response.data.appSettings)

    return response.data.course
  },

  async getCountriesAndStates () {

    const lookupGhinCountryStates = fbFunc.httpsCallable('lookupGhinCountryStates')

    const appSettings = store.getters['auth/appSettings']

    const response = await lookupGhinCountryStates(appSettings)

    store.commit('auth/appSettings', response.data.appSettings)

    return response.data.countries
  }
  
  

}