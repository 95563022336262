import PayTable from "./payTable"
import GameDefs from '../helpers/gameDefs'
import TeamScores from './teamScores'
import PointsTable from './pointsTable'
import { getDataValue } from '../lib'

export default class Game {
  constructor(id='') {

    this.initialize()
    if (id !== '') this.id = id

  }
  
  initialize () {

    this.id = ''
    this._prop = 'stroke'
    this.side = 'full18'
    this.holes = 'full18'
    this.playType = 'individual'
    this.scoreType = 'stroke'
    this.format = 'all'
    this.scoreCount = 1
    this.holes123 = 'seq'
    this.useQuota = false
    
    this.order = 1

    this.percentHandicap = 100
    this.maxHandicap = 36
    this.teamHandicap = 'none'
    this.carryOver = false
    this.skinsHalfStroke = false
    this.dotPool = false
    this.totalPool = 0
    this.dotPayout = 0
    this.kpUseDist = false
    this.kpParOrBetter = true
 
    this.bet = {
      amount: 0,
      payoutType: 'places', // or 'split' for Skins and KP games
      payout: {
        calc: 'percent', // or 'add'
        // 'percent' = bet is entered and places are calculated from percents
        // 'bet' = payout amounts are entered and bet is totaled
        places: 1,
        percents: {'1st': 100},
        payTable: new PayTable()
      }
   
    },

    this.gameDefs = new GameDefs()
    this.teamScores = new TeamScores()

    this.pointsTable = null
  }

  // get id () {
    // let id = this.prop
    // if (this.prop === 'stroke') {
    //   id += capitalizeFirst(this.side)
    //   if (this.playType !== 'individual') id += '|' + this.playType
    //   if (this.scoreType !== 'stroke') id += '|' + this.scoreType
    //   if (this.scoreType === 'bball') id += '|' + this.scoreCount
    //   if (this.seq.length>0) id += '#' + this.seq
    // }
    // return this._id
  // }

  // initFromId (id) {
  //   this.playType = 'individual'
  //   this.scoreType = 'stroke'
  //   this.scoreCount = 1
  //   this.side = 'full18'
  //   this.seq = ''

  //   if (id.substr(0,6) === 'stroke') {
  //     let idParts
  //     if (id.includes('#')){
  //       const seqParts = id.split('#')
  //       this.seq = seqParts[1]
  //       idParts = seqParts[0].split('|')
  //     }
  //     else {
  //       idParts = id.split('|')
  //     }
     

  //     this.prop = 'stroke'
  //     if (idParts[0].toUpperCase()==='STROKE18') this.side = 'full18'
  //     if (idParts[0].toUpperCase()==='STROKEFRONT') this.side = 'front'
  //     if (idParts[0].toUpperCase()==='STROKEBACK') this.side = 'back'
  //     if (idParts.length > 1) this.playType = idParts[1]
  //     if (idParts.length > 2) this.scoreType = idParts[2]
  //     if (idParts.length > 3) this.scoreCount = idParts[3]
     
   
  //   }
  //   else {
  //     this.prop = id
  //   }
  // }

  get name () {
   
    let name = this.gameDefs.getGameName(this.prop)
    if (this.prop === 'stroke') {
      if (this.side === 'full18') name += ' 18' 
      if (this.side === 'front') name += ' Front 9' 
      if (this.side === 'back') name += ' Back 9' 
      
      if (this.playType !== 'individual') name += ' - ' + this.gameDefs.getPlayType(this.playType)
      if (this.scoreType !== 'stroke') name += ' ' + this.gameDefs.getScoreType(this.scoreType)
      if (this.format === 'scramble') {
        name += ' - Scramble'
      }
      else if (this.format === '123') {
        name += ' - 123 - ' + this.gameDefs.getHoles123(this.holes123)
      }
      else {
        if (this.scoreCount > 0) name += ' - ' + this.gameDefs.getScoreCount(this.scoreCount)
      }
      
    }
    return name
  }

  get prop () {
    return this._prop
  }

  set prop (prop) {
    if ( (prop === 'kp' || prop === 'skins') && (this._prop !== 'kp' || this._prop !== 'skins') ) {
      this.bet.payoutType = 'split'
    }
    this._prop = prop
  }

  get selectName () {
    return this.gameDefs.getGameName(this.prop)
  }

  get handicapFac () {
    return this.percentHandicap / 100
  }

  isStrokeGame (side) {
    return (this.prop === 'stroke' && this.side === side)
  }

  get isTeamGame () {
    return this.playType !== 'individual'
  }

  get isStableford () {
    return this.scoreType === 'stableford'
  }

  getPointsQuota (holeNum) {
    let pointsTable
    if (this.pointsTable === null) {
      pointsTable = new PointsTable()
    }
    else {
      pointsTable = this.pointsTable
    }
    return pointsTable.getPointsQuota(this.side, holeNum)
  }

  hasUnassignedBlindDraws () {
    
    return this.teamScores.hasUnassignedBlindDraws()
  }

  getNumPlaces (numPlayers) {
    let places = this.bet.payout.places
    if (places === 4) {   // payout table
      places = this.bet.payout.payTable.getNumPlaces(numPlayers)
    }
    return places
  }
  hasPayTable () {
    return this.bet.payout.places === 4
  }

  // updatePool (numPlayers) {
  //   this.bet.pool = this.bet.amount * numPlayers
  // }

  assignScores (scoresArray) {
    this.teamScores.assignScores (scoresArray)
  }

  toJSON () {
    return JSON.stringify(this.getObj())
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  copy () {
    const game = new Game()

    game.setObj(this.getObj())

    return game
  }
  getObj () {
    return {
      id: this.id,
      prop: this._prop,

      side: this.side,
      playType: this.playType,
      scoreType: this.scoreType,
      scoreCount: this.scoreCount,
      format: this.format,
      holes123: this.holes123,

      holes: this.holes,
      order: this.order,
      percentHandicap: this.percentHandicap,
      maxHandicap: this.maxHandicap,
      teamHandicap: this.teamHandicap,
      carryOver: this.carryOver,
      skinsHalfStroke: this.skinsHalfStroke,
      dotPool: this.dotPool,
      kpUseDist: this.kpUseDist,
      kpParOrBetter: this.kpParOrBetter,

      bet: {
        amount: this.bet.amount,
        payoutType: this.bet.payoutType,
        payout: {
          places: this.bet.payout.places,
          percents: {...this.bet.payout.percents},
          payTable: this.bet.payout.payTable.getData()
        }
      },

      totalPool: this.totalPool,
      dotPayout: this.dotPayout,
      teamScores: this.teamScores.getObj(),
      pointsTable: this.pointsTable ? this.pointsTable.getObj() : null,
      useQuota: this.useQuota
  
    }
  }
  setObj (data) {
    this.id = data.id
    this._prop = data.prop
    this.side = data.side
    
    this.playType = data.playType
    this.scoreType = data.scoreType
    this.scoreCount = data.scoreCount
    this.format = data.format
    this.holes123 = data.holes123

    this.holes = data.holes
    this.order = data.order
    this.percentHandicap = data.percentHandicap
    this.maxHandicap = data.maxHandicap
    this.teamHandicap = data.teamHandicap
    this.carryOver = data.carryOver
    this.skinsHalfStroke = data.skinsHalfStroke
    this.dotPool = data.dotPool
    this.kpUseDist = data.kpUseDist
    this.kpParOrBetter = data.kpParOrBetter

    this.bet.amount = data.bet.amount
    this.bet.payoutType = data.bet.payoutType
    this.bet.payout.places = data.bet.payout.places
    this.bet.payout.percents = {...data.bet.payout.percents}
    this.bet.payout.payTable.setData(data.bet.payout.payTable)

    this.totalPool = data.totalPool
    this.dotPayout = data.dotPayout

    this.teamScores.setObj(data.teamScores)

    if (data.pointsTable) {
      this.pointsTable = new PointsTable()
      this.pointsTable.setObj(data.pointsTable)
    }
    else {
      this.pointsTable = null
    }
    this.useQuota = data.useQuota
    
  }
  getData () {
    return {
      id: this.id,
      prop: this._prop,
      name: this.name,  // store name so CreateTournamentPlayer Cloud function works

      side: this.side,
      playType: this.playType,
      scoreType: this.scoreType,
      scoreCount: this.scoreCount,
      format: this.format,
      holes123: this.holes123,
    
      holes: this.holes,
      order: this.order,
      percentHandicap: this.percentHandicap,
      maxHandicap: this.maxHandicap,
      teamHandicap: this.teamHandicap,
      carryOver: this.carryOver,
      skinsHalfStroke: this.skinsHalfStroke,
      dotPool: this.dotPool,
      dotPayout: 0,
      kpUseDist: this.kpUseDist,
      kpParOrBetter: this.kpParOrBetter,

      bet: {
        amount: this.bet.amount,
        payoutType: this.bet.payoutType,
        payout: {
          places: this.bet.payout.places,
          percents: {...this.bet.payout.percents},
          payTable: this.bet.payout.payTable.getData()
        }
      },

      teamScores: this.teamScores.getData(),
      pointsTable : this.pointsTable ? this.pointsTable.getData() : null,
      useQuota: this.useQuota
    }
  }
  setData (data) {
    
    if (data.prop === 'stroke18') {
      this._prop = 'stroke'
      this.side = 'full18'
    }
    else if (data.prop === 'strokeFront') {
      this._prop = 'stroke'
      this.side = 'front'
    }
    else if (data.prop === 'strokeBack') {
      this._prop = 'stroke'
      this.side = 'back'
    }
    else {
      this._prop = data.prop
      this.side = getDataValue(data,'side','full18')
    }
    this.id = data.id
     
    this.playType = getDataValue(data,'playType','individual')
    this.scoreType = getDataValue(data,'scoreType','stroke')
    this.scoreCount = getDataValue(data,'scoreCount',0)
    this.format = getDataValue(data, 'format', 'all')
    this.holes123 = getDataValue(data, 'holes123', 'seq')
    
    this.holes = data.holes
    this.order = data.order
    this.percentHandicap = data.percentHandicap
    this.maxHandicap = getDataValue(data,'maxHandicap',36)
    this.teamHandicap = getDataValue(data,'teamHandicap','none')
    this.carryOver = data.carryOver
    this.skinsHalfStroke = getDataValue(data,'skinsHalfStroke',false)
    this.dotPool = data.dotPool
    this.dotPayout = getDataValue(data,'dotPayout',0)
    this.kpUseDist = getDataValue(data,'kpUseDist',false)
    this.kpParOrBetter = getDataValue(data,'kpParOrBetter',true)

    this.bet.amount = data.bet.amount
    this.bet.payoutType = data.bet.payoutType === undefined ? ((data.prop==='kp'||data.prop==='skins') ? 'split':'places') : data.bet.payoutType
    this.bet.payout.places = data.bet.payout.places
    this.bet.payout.percents = {...data.bet.payout.percents}
    this.bet.payout.payTable.setData(data.bet.payout.payTable)

    if (data.teamScores !== undefined) this.teamScores.setData(data.teamScores)

    if (data.pointsTable === undefined) {
      this.pointsTable = null
    }
    else {
      if (data.pointsTable) {
        this.pointsTable = new PointsTable()
        this.pointsTable.setData(data.pointsTable)
      }
      else {
        this.pointsTable = null
      }
    }
    this.useQuota = getDataValue(data,'useQuota',false)
  }
}


