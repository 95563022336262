<template>
  <div>
    <div v-if="!readOnly && round.isScheduled" class="panel-block">
      <div v-if="game.format!=='scramble'" class="buttons">
        
        <b-button @click="resetTeamOrder(game)">Reset To Tee Time Order</b-button>
    
        <b-dropdown @click="resetTeamOrder(game)">
          <button class="button" slot="trigger" slot-scope="{ active }">
            <span>Same Order As</span>
            <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
          </button>
          <template v-for="(game2, index2) in gamesArray" >
            <b-dropdown-item 
              v-if="(game2.prop==='stroke' || game2.prop==='dots') && game2.id!==game.id && game2.playType!=='individual'"
              :key="index2"
              @click="setTeamOrder(game, game2)">
                {{game2.name}}
            </b-dropdown-item>
          </template>
        </b-dropdown>
        
      </div>
    </div>
    <div class="panel-block">
      <div class="columns is-multiline">
        <template v-if="readOnly || !round.isScheduled">
          <div class="panel column"  v-for="(team,index) in game.teamScores.aTeams" :key="index">
            <div class="panel-heading is-size-6">
              <div class="level is-mobile">
                <div class="level-left">
                  <div class="level-item">
                    {{teamLabel(team)}}
                  </div>
                </div>
              </div>
              <div class="panel-block has-background-white" v-for="n in team.size" :key="n+100">
                <p class="has-text-weight-normal">{{ team.slots(n-1)===null ? '' : team.slotLabel(n-1)}}</p>
              </div>
            </div>
          </div>
        </template>

        <template v-else>

          <template v-if="isMobile">
            <div class="column" 
              v-for="(team,index) in game.teamScores.aTeams" :key="index">

              <div class="panel">

                <div class="panel-heading is-size-6" >
                  <div class="level is-mobile">
                    <div class="level-left">
                      <div class="level-item">
                        {{ teamLabel(team)}}
                      </div>
                    </div>
                  </div>  
                </div>
                  
                <div
                  v-for="n in team.size"
                  :key="n+100"
                  class="panel-block" 
                  >
                    <a 
                      v-if="team.blindDraw[n-1]"
                      class="is-italic" 
                      @click="assignBlindDraw(game, index, n-1)">
                  
                      <span class="icon">
                        <i class="mdi mdi-alpha-b-box-outline"></i>
                      </span>
                      <span>{{team.slotsDocId(n-1)===null?'Assign Blind Draw':team.slotlabel(n-1)}}</span>
                    </a>
                    <div
                      v-else
                      class="tag is-medium"
                      :class="switchTeamIndex===index && team.slotsDocId(switchIndex)===team.slotsDocId(n-1)?'has-text-info':''"
                      @click="switchPlayers(game, index, n-1)"
                    > 
                      {{ team.slots(n-1)===null ? '' : team.slotLabel(n-1)}}
                  </div> 
                </div>
              </div> 
            
            </div>
          </template>

          <template v-else>
            <div class="column" 
              v-for="(team,index) in game.teamScores.aTeams" :key="index">

              <div class="panel">

                <div class="panel-heading is-size-6" >
                  <div class="level is-mobile">
                    <div class="level-left">
                      <div class="level-item">
                        {{ teamLabel(team) }}
                      </div>
                    </div>
                  </div>  
                </div>
                  
                <template v-if="game.format === 'scramble'">
                  <div
                    v-for="n in team.size"
                    :key="n"
                    class="panel-block" >
                      <a 
                        v-if="team.blindDraw[n-1]"
                        class="is-italic" 
                        @click="assignBlindDraw(game, index, n-1)">
                    
                        <span class="icon">
                          <i class="mdi mdi-alpha-b-box-outline"></i>
                        </span>
                        <span>{{team.slotsDocId(n-1)===null?'Assign Blind Draw':team.slots(n-1).slotLabel}}</span>
                      </a>
                      <div
                        v-else
                        class="tag is-medium"
                      > 
                          {{ team.slots(n-1)===null ? '' : team.slots(n-1).slotLabel}}
                    </div> 
                  </div>
                </template>
                <template v-else>
                  <drop
                
                    v-for="n in team.size"
                    :key="n"
                    class="panel-block" 
                    @drop="handleDrop(game, index, n-1, ...arguments)">
                      <a 
                        v-if="team.blindDraw[n-1]"
                        class="is-italic" 
                        @click="assignBlindDraw(game, index, n-1)">
                    
                        <span class="icon">
                          <i class="mdi mdi-alpha-b-box-outline"></i>
                        </span>
                        <span>{{team.slotsDocId(n-1)===null?'Assign Blind Draw':team.slots(n-1).slotLabel}}</span>
                      </a>
                      <drag
                        v-else
                        :tag="'div'"
                        class="tag is-medium"
                        :transfer-data="team.slots(n-1)"
                        :effect-allowed="['copy']"
                        drop-effect="copy"
                        @dragstart="handleChildDragstart"
                      > 
                          {{ team.slots(n-1)===null ? '' : team.slots(n-1).slotLabel}}
                    </drag> 
                  </drop>
                </template>
              </div> 
            
            </div>

          </template>
        </template>
      </div>

    </div>
  </div>
</template>

<script>
import DBUpdate from '../../helpers/dbUpdate'


export default {
  data () {
    return {

    }
  },


  props: ['round','game','games','readOnly'],

  mounted () {
    if (this.game.format === 'scramble' || this.game.format === '123') this.resetTeamOrder(this.game)
  },

  watch: {

    'round.teeTimes.aTeeTimess': {
      handler: function (val, oldVal) {
       
        if (val.length !== oldVal.length) { // if tee time is changed
          if (this.game.format === 'scramble' || this.game.format === '123') this.resetTeamOrder(this.game)
        }
      }
    }
  },

  computed: {
    gamesArray () {
      return this.games.aGames
    }
  },
  methods: {
    async updateGame (updatedGame) {
      const games = this.games.copy()
      const game = games.get(updatedGame.id)
      game.setObj(updatedGame.getObj())
      await this.updateGames(games)
    },

    async updateGames (games) {


      const gameUpdate = new DBUpdate(this.round.docId, {games: games.getData()})
    
      await this.$store.dispatch('round/saveValues', gameUpdate)
     
      
    },

    teamLabel (team) {
      let label = `Team ${ team.teamNum}`
      if (team.handicapType !== 'none') {
       
        label += ` Hdcp ${team.handicap('full18')}`
      }

      return label
    },
    
    handleDrop (game, toTeamIndex, toSlotIndex, droppedScore, dragEvent) {
     
      const updatedGame = game.copy()
      const teams = updatedGame.teamScores

      const fromTeam = teams.getTeamScoreByScoreDocId(droppedScore.docId)
      const toTeam = teams.getTeamScoreByIndex(toTeamIndex)


      if (fromTeam !== null) { // score dropped from another assigned time
      
          const fromSlotIndex = fromTeam.getSlotIndexByScore(droppedScore)
          fromTeam.scores[fromSlotIndex] = toTeam.slots(toSlotIndex)
          fromTeam.scoreDocIds[fromSlotIndex] = toTeam.scoreDocIds[toSlotIndex]
        // }
      }
      toTeam.scores[toSlotIndex] = droppedScore
      toTeam.scoreDocIds[toSlotIndex] = droppedScore.docId

      updatedGame.teamScores.deleteEmptyTeams ()

    
      
      this.updateGame (updatedGame)

      dragEvent.stopPropagation()

    },

    handleChildDragstart(data, event) {
      event.stopPropagation();
    },



    async resetTeamOrder (game) {
      const games = this.games.copy()
      const updatedGame = games.get(game.id)

      updatedGame.teamScores.clear()

      const teamTimeScoreDocIds = this.round.teeTimes.scoreDocIds()

      if (teamTimeScoreDocIds.length > 0) {
       
        updatedGame.teamScores.assign (teamTimeScoreDocIds)

        for (let team of updatedGame.teamScores.aTeams) {
          team.format = updatedGame.format
          team.handicapType = updatedGame.teamHandicap
          team.scoreCount = updatedGame.scoreCount
        }
    
      }
      await this.updateGames(games)
    },

    async setTeamOrder (game, game2) {
      const games = this.games.copy()
      const updatedGame = games.get(game.id)

      updatedGame.teamScores.clear()

      const teamTimeScoreDocIds = game2.teamScores.scoreDocIds()

      if (teamTimeScoreDocIds.length > 0) {
       
        updatedGame.teamScores.assign (teamTimeScoreDocIds)

        for (let team of updatedGame.teamScores.aTeams) {
          team.format = updatedGame.format
          team.handicapType = updatedGame.teamHandicap
          team.scoreCount = updatedGame.scoreCount
        }
    
      }
      await this.updateGames(games)
    },

    async assignBlindDraw (game, teamIndex, index) {

      const games = this.games.copy()
      const updatedGame = games.get(game.id)
      const team = updatedGame.teamScores.aTeams[teamIndex]

      if (team.slotsDocId(index) === null) {
        team.assignBlindDraw(updatedGame.teamScores.scoreDocIds(), index)
        await this.updateGames(games)
      }
      else {
        this.$buefy.dialog.confirm({
          message: 'Pick another player for the blind draw?',
          onConfirm: async () => {
            team.assignBlindDraw(updatedGame.teamScores.scoreDocIds(), index)
            await this.updateGames(games)
          }
        })
      }
      
    }

   
  
  }
}

</script>

<style>


</style>
