<template>
  <div>
    <div v-if="payOuts.length === 0">
      <p class="subtitle has-margin-left-10 has-margin-top-10">No scores recorded yet</p>
    </div>
    <template v-if="isMobile" >
     
      <b-collapse
      
        :open="false" 
        class="panel" 
        style="margin-top:6px;margin-bottom:0px"
        v-for="(payOut, index) in payOuts" :key="index">
        <div 
          slot="trigger" 
          slot-scope="props"
          class="panel-heading is-size-6 has-margin-bottom-10"  
          role="button">
            <div class="level is-mobile">
              <div class="level-left">
                <p class="level-item">{{ payOut.name }}</p>
              </div>
              <div class="level-right">
                <p class="level-item">{{ formatCurrency(payOut.tournamentTotal) }}</p>
                <span class="panel-icon level-item">
                  <b-icon
                      :icon="props.open ? 'menu-down' : 'menu-up'">
                  </b-icon>
                </span>
              </div>

            </div>
        </div>
        <p class="mb-2 ml-2 has-text-weight-bold">Tournament - {{formatCurrency(payOut.totalPayout)}}</p>
        <table class="table has-margin-bottom-5" width="100%">
          <tbody>
            <tr v-for="(detail,detailIndex) in gamesPayoutArray(tournament.games, payOut)" :key="detailIndex">
              <td>{{detail.name}}</td>
              <td>{{detail.score}}</td>
              <td style="text-align:right;">{{detail.payOut}}</td>
            </tr>
          </tbody>
        </table>
        <div v-for="(roundPayout, index) of payOut.rounds" :key="index">
          <p class="mb-2 mt-2 ml-2 has-text-weight-bold">{{`${roundPayout.title} - ${formatCurrency(roundPayout.payOutItem.totalPayout)}`}}</p>
           <table class="table has-margin-bottom-5" width="100%">
            <tbody>
              <tr v-for="(detail,detailIndex) in gamesPayoutArray(roundPayout.games, roundPayout.payOutItem)" :key="detailIndex">
                <td style="border-bottom:0pt">{{detail.name}}</td>
                <td style="border-bottom:0pt">{{detail.score}}</td>
                <td style=" border-bottom:0pt; text-align:right;">{{detail.payOut}}</td>
              </tr>
            </tbody>
          </table>
         
        </div>
        
      </b-collapse> 
    </template>
    <template v-else>

      <b-table
        :data="payOuts"
        detailed
        detail-key="playerDocId"
      >
       
          <b-table-column field="name" label="NAME" v-slot="props">
            {{props.row.name}}
          </b-table-column>
          <b-table-column field="totalPayout" label="TOTAL" centered v-slot="props" width="100">
            <b>{{formatCurrency(props.row.tournamentTotal)}}</b>
          </b-table-column>
       
     
        <template slot="detail" slot-scope="props">
          <template v-if="props.row.totalPayout>0">
            <p class="mb-2 has-text-weight-bold">Tournament - {{formatCurrency(props.row.totalPayout)}}</p>
            <b-table
              :data="gamesPayoutArray(tournament.games, props.row)"
            >
              
                <b-table-column label="Game" v-slot="props">
                  {{props.row.name}}
                </b-table-column>
                <b-table-column label="Score" centered v-slot="props" width="260">
                  <p :class="toParClassMobile(props.row.scoreVal)">{{ props.row.score }}</p>
    
                </b-table-column>
                <b-table-column label="Payout" centered v-slot="props" width="100">
                  <b>{{props.row.payOut}}</b>
                </b-table-column>
            
            </b-table>
          </template>
          <div v-for="(roundPayout, index) of props.row.rounds" :key="index">
            <p class="mb-2 mt-2 has-text-weight-bold">{{`${roundPayout.title} - ${formatCurrency(roundPayout.payOutItem.totalPayout)}`}}</p>
            <b-table
              :data="gamesPayoutArray(tournament.rounds.aRounds[index].games, roundPayout.payOutItem)"
            >
              
                <b-table-column label="Game" v-slot="props">
                  {{props.row.name}}
                </b-table-column>
                <b-table-column label="Score" centered v-slot="props" width="260">
                  <p :class="toParClassMobile(props.row.scoreVal)">{{ props.row.score }}</p>
    
                </b-table-column>
                <b-table-column label="Payout" centered v-slot="props" width="100">
                  <b>{{props.row.payOut}}</b>
                </b-table-column>
            
            </b-table>
          </div>
        </template>
      </b-table>
    
      
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { formatCurrency, gamesPayoutArray } from '../../lib'


export default {
  data() {
    return {
    }
  },


  computed: {
    ...mapGetters('tournament', [
      'tournament',
      'payOuts'
      // 'tab'
    ]),
    gamesArray () {
      return this.tournament.games.aGames
    }
  },
  methods: {
    formatCurrency, gamesPayoutArray,
  
    toParClassMobile (toPar) {
      let classVal = 'has-text-centered has-text-weight-bold'
      if (toPar < 0) classVal += ' has-text-danger'
      if (toPar > 0) classVal += ' has-text-info'
      return classVal
    }
  }
};
</script>

<style>
  .text-center {
    text-align: center;
  }
  .vert-bottom {
    vertical-align: bottom;
  }
  .vert-middle {
    vertical-align: middle;
  }
</style>
