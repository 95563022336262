<template>
 
  <div class="modal-card" >
    <header class="modal-card-head">
      <p class="modal-card-title">Clear Scores</p>
    </header>
    <div class="modal-card-body" >
      <div class="field">
        <b-radio v-model="clearOption" native-value="full18">All Scores</b-radio>
      </div>
      <div class="field">
        <b-radio v-model="clearOption" native-value="front">Front Scores</b-radio>
      </div>
      <div class="field">
        <b-radio v-model="clearOption" native-value="back">Back Scores</b-radio>
      </div>
      <div class="field">
        <b-radio v-model="clearOption" native-value="select">Select Holes</b-radio>
      </div>
      <div class="field">
        <b-dropdown :disabled="clearOption!=='select'" multiple ref="dropdown" v-model="holesSelected" >
          <button class="button" slot="trigger" slot-scope="{ active }">
              <span>Holes</span>
              <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
          </button>

          <b-dropdown-item v-for="n in 18" :key="n" 
            custom
            :value="n">
              
            <b-checkbox @input="$refs.dropdown.selectItem(n)"><span>Hole {{n}}</span></b-checkbox>
              
          </b-dropdown-item>
          
        </b-dropdown>
      </div>
    </div>
    <footer class="modal-card-foot" style="justify-content: flex-end;">
      <button class="button" type="button" @click="$parent.close()">Cancel</button>
      <button class="button is-primary" @click="handleClear()" >OK</button>
    </footer>
  </div>
 
</template>

<script>


export default {
  data () {
    return {
      clearOption: 'full18',
      holesSelected: []
    }
  },

  props: {
 
  },
  
  computed: {
 
   
  },
  methods: {
    handleClear () {
      this.$parent.close()
      
      this.$emit('saveModal', {
        clearOption: this.clearOption,
        holes: this.holesSelected
      })
    }
  
  }
  
  
}

</script>
