<template>
  <div style="padding-top: 5px;">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <p class="subtitle">{{ course.label }}</p>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <button class="button is-primary" @click="cancelCourse">OK</button>
        </div>
      </div>
    </div>
    
    <tees :tees="tees" />
  </div>

</template>

<script>
import Tees from '../../components/Course/Tees'

export default {
  data () {
    return {

    }
  },
  components: {
    'tees': Tees
  },
  computed: {
    course () {
      return this.$store.getters['course/course']
    },
    tees () {
      return this.course.tees.tees
    }
    
  },
  methods: {
    cancelCourse () {
      this.$store.dispatch('course/cancel')
    }
  }
}

</script>

<style>
  .center-table th {
    text-align: center;
    vertical-align: middle;
  }

  .center-table td {
    text-align: center;
    vertical-align: middle;
  }
  .text-center{
    text-align: center;
  }
</style>