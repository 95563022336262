<template>
  <div>
    <template v-if="isMobile">
      <b-collapse v-for="(game,index) in gamesArray"
        class="card has-margin-bottom-10" 
        :open="selectedGameId===game.id"
        @open="selectedGameId=game.id"
        
        :key="index">
        <div
          slot="trigger" 
          slot-scope="props"
          class="card-header"
          role="button">
          <p class="card-header-title">
              {{game.name + ' ' + placesLabel(game, numPlayers(game.id))}}
          </p>
        
       
         
          <a class="card-header-icon">
            <b-icon
                :icon="props.open ? 'menu-down' : 'menu-up'">
            </b-icon>
          </a>
        </div>
        <div class="card-content is-paddingless">
          <template v-if="game.prop==='stroke'">
            <div v-if="leaderBoardArray.length === 0">
              <p class="subtitle has-margin-left-10">No scores recorded yet</p>
            </div>
        
            <stroke-leaderboard v-else :tournament="tournament" :game="game" :leaderBoardArray="leaderBoardArray" :scores="scores"/>
            
          </template>
        
        
         
        </div>
           
      </b-collapse>
    </template>
    <template v-else>
      <div class="level is-mobile" style="margin-top:5px;margin-bottom:5px;">
        <div class="level-left">
          <div class="level-item">
          
              <b-select v-model="selectedGameId" >
                <option
                  v-for="(game,index) in gamesArray"
                  :value="game.id"
                  :key="index"
                >{{ game.name }}</option>
              </b-select>
          
          </div>
          <template v-if="!isMobile && tournament.betOnGames">
            <div class="level-item">
              <p class="field-label has-text-weight-bold">Pool: {{formatCurrency(selectedGame.totalPool)}}</p>

            </div>
            <div class="level-item">
              <p class="field-label">{{ placesLabel(selectedGame, numPlayers(selectedGame.id)) }}</p>
            </div>
          
            
          </template>
        </div>
      </div>

   
      <template v-if="selectedGame.prop==='stroke'">
        <div v-if="leaderBoardArray.length === 0">
          <p class="subtitle has-margin-left-10">No scores recorded yet</p>
        </div>
        
        <stroke-leaderboard v-else :tournament="tournament" :game="selectedGame" :leaderBoardArray="leaderBoardArray" :scores="scores"/>
        
      </template>
   
      
    
    </template>
    
 
  </div>

  
   
</template>

<script>

import StrokeLeaderboard from './StrokeLeaderboard'

import Game from "../../models/game"
import Tournament from '../../models/tournament'
import { mapGetters } from "vuex"
import { formatCurrency } from "../../lib"

export default {
  data() {
    return {
    }
  },

  components: {
    'stroke-leaderboard': StrokeLeaderboard
  },

  computed: {
    selectedGame () {
      let game
    
    
      game = this.tournament.games.get(this.selectedGameId)
      if (game === null) {
        if (this.gamesArray.length === 0) {
          game = new Game()
        }
        else {
          game = this.gamesArray[0]
        }
          
      }
      
      return game
    },
    games () {
      return this.tournament.games
    },
    gamesArray () {
      return this.tournament.games.aGames
    },
    selectedGameId: {
      get () {
        return this.$store.getters['tournament/selectedGameId']
      },
      set (val) {
        this.$store.commit('tournament/selectedGameId', val)
      }
    },

    leaderBoardArray () {
      let leaderBoard = []
       
      if (this.selectedGameId !== '') {
        if (Object.entries(this.leaderBoards).length > 0) {
        
          const selectedBoard = this.leaderBoards[this.selectedGameId]
          if (selectedBoard !== undefined) {
            leaderBoard = selectedBoard
          }
        }
      }
     
      return leaderBoard
      
    },
 
    ...mapGetters('tournament', [
      'leaderBoards',
      'scores',
      'tab'
    ]),

    tournament () {
      let tournament = this.$store.getters['tournament/tournament']
      if (tournament === null) {
     
        tournament = new Tournament()
      }
      return tournament
    },
    
  },
  methods: {
    formatCurrency,
    
    numPlayers (gameId) {
     
       if (Object.entries(this.leaderBoards).length === 0 || gameId === null) {
        return 0
      }
      else {
        if (gameId in this.leaderBoards) {
         
          return this.leaderBoards[gameId].length
        }
        else {
          return 0
        }
       
      }
    
    },
  
    placesLabel(game, numPlayers) {
      let label = ""
      if (game.prop === 'stroke') {
        const places = game.getNumPlaces(numPlayers)
        if (places === 1) {
          label = "first only)"
        } else {
          label = `${places} places)`
        }
       
        label = '(Pays ' + label
       
      }

      return label
    }
  }
}
</script>

<style>

</style>
