<template>
  <div>
    <template v-if="isMobile">
      <nav class="navbar is-fixed-bottom is-radiusless" role="navigation">
        <div class="navbar-brand">
  
          <template v-if="isUserPage">
            <div class="navbar-item is-expanded is-block has-text-centered has-text-white" @click="updateSelected('home')">
              <b-icon icon="home"/>
              <p class="is-size-7 has-text-white">Home</p>
            </div>
            <div class="navbar-item is-expanded is-block has-text-centered has-text-white" @click="updateSelected('games')">
              <b-icon icon="view-dashboard"></b-icon>
              <p class="is-size-7 has-text-white">Results</p>
            </div>
            <div class="navbar-item is-expanded is-block has-text-centered has-text-white" @click="updateSelected('rounds')">
              <b-icon icon="golf"></b-icon>
              <p class="is-size-7 has-text-white">Rounds</p>
            </div>
          </template>
          <template v-else-if="isCoursesPage">
            <div class="navbar-item is-block has-text-centered has-text-white" @click="goHome()">
              <b-icon icon="home"/>
              <p class="is-size-7 has-text-white">Home</p>
            </div>
          </template>
          <template v-else-if="isRoundPage">
            <div class="navbar-item is-expanded is-block has-text-centered has-text-white" @click="updateSelected('pairings')">
              <b-icon icon="account-multiple"></b-icon>
              <p class="is-size-7 has-text-white">Pairings</p>
            </div>
            <div class="navbar-item is-expanded is-block has-text-centered has-text-white" @click="updateSelected('scores')">
              <b-icon icon="table-edit"></b-icon>
              <p class="is-size-7 has-text-white">Scores</p>
            </div>
            <div class="navbar-item is-expanded is-block has-text-centered has-text-white" @click="updateSelected('leaderboard')">
              <b-icon icon="google-analytics"></b-icon>
              <p class="is-size-7 has-text-white">Leaderboard</p>
            </div>
             <div v-if="round.betOnGames" class="navbar-item is-expanded is-block has-text-centered has-text-white" @click="updateSelected('payouts')">
              <b-icon icon="currency-usd"></b-icon>
              <p class="is-size-7 has-text-white">Payouts</p>
            </div>
          </template>
          <template v-else-if="isTournamentPage">
            <div class="navbar-item is-expanded is-block has-text-centered has-text-white" @click="updateSelected('players')">
              <b-icon icon="account-multiple"></b-icon>
              <p class="is-size-7 has-text-white">Players</p>
            </div>
            <div class="navbar-item is-expanded is-block has-text-centered has-text-white" @click="updateSelected('rounds')">
              <b-icon icon="table-edit"></b-icon>
              <p class="is-size-7 has-text-white">Rounds</p>
            </div>
            <div class="navbar-item is-expanded is-block has-text-centered has-text-white" @click="updateSelected('leaderboard')">
              <b-icon icon="google-analytics"></b-icon>
              <p class="is-size-7 has-text-white">Leaderboard</p>
            </div>
             <div v-if="tournament.betOnGames" class="navbar-item is-expanded is-block has-text-centered has-text-white" @click="updateSelected('payouts')">
              <b-icon icon="currency-usd"></b-icon>
              <p class="is-size-7 has-text-white">Payouts</p>
            </div>
          </template>
          <template v-else-if="isGroupPage">
            <div class="navbar-item is-expanded is-block has-text-centered has-text-white" @click="goHome()">
              <b-icon icon="home"/>
              <p class="is-size-7 has-text-white">Home</p>
            </div>
            <div class="navbar-item is-expanded is-block has-text-centered has-text-white" @click="updateSelected('roster')">
              <b-icon icon="account"></b-icon>
              <p class="is-size-7 has-text-white">Roster</p>
            </div>
            <div class="navbar-item is-expanded is-block has-text-centered has-text-white" @click="updateSelected('rounds')">
              <b-icon icon="golf"></b-icon>
              <p class="is-size-7 has-text-white">Rounds</p>
            </div>
            <div class="navbar-item is-expanded is-block has-text-centered has-text-white" @click="updateSelected('standings')">
              <b-icon icon="receipt"></b-icon>
              <p class="is-size-7 has-text-white">Standings</p>
            </div>
          </template>
          
       
        
          <div v-if="!isSigninPage" class="navbar-burger is-expanded is-block has-text-centered" @click="showMobileMenu=!showMobileMenu">
            <span></span>
            <span></span>
            <span></span>
          </div>
            
        </div>
      </nav>
      <b-sidebar
        type="is-light"
        fullheight
        overlay
        right
        :open.sync="showMobileMenu"
       
      >
        <div class="menu">
          <ul class="menu-list">
            <menu-item icon="" item="dummy1" itemName=""  />
            <menu-item icon="" item="dummy2" itemName=""  />
    
            <template v-if="isGroupPage">
              <menu-item icon="tournament" item="tournaments" itemName="Tournaments" @selected="updateSelected" />
              <menu-item icon="settings" item="settings" itemName="Settings" @selected="updateSelected" />
              <menu-item icon="view-dashboard" item="games" itemName="Games" @selected="updateSelected" />
              <menu-item v-if="isAdmin" icon="emoticon-outline" item="handicaps" itemName="Handicaps" @selected="updateSelected" />
            </template>
            <template v-else-if="isRoundPage">
              <menu-item v-if="!isGuest" icon="home" item="home" itemName="Home" @selected="goHome()" />
              <menu-item v-if="!isGuest" icon="account-multiple" item="group" :itemName="'Go to ' + group.name" @selected="updateSelected" />
              <menu-item icon="settings" item="settings" itemName="Settings" @selected="updateSelected" />
              <menu-item icon="view-dashboard" item="games" itemName="Games" @selected="updateSelected" />
            </template>
            <template v-else-if="isTournamentPage">
              <menu-item v-if="!isGuest" icon="home" item="home" itemName="Home" @selected="goHome()" />
              <menu-item v-if="!isGuest" icon="account-multiple" item="group" :itemName="'Go to ' + group.name" @selected="updateSelected" />
              <menu-item icon="settings" item="settings" itemName="Settings" @selected="updateSelected" />
              <menu-item icon="view-dashboard" item="games" itemName="Games" @selected="updateSelected" />
           
            </template>
            <menu-item v-if="!isCoursesPage && !isGuest" icon="map-marker-multiple" item="settings" itemName="Courses" @selected="gotoCourses" />
            <menu-item v-if="isUserPage" icon="account-circle-outline" item="account" itemName="Account" @selected="updateSelected" />
            <menu-item icon="information-variant" item="about" itemName="About" @selected="updateSelected" />
            <menu-item v-if="!isGuest" icon="account" item="account" itemName="Account" @selected="updateSelected" />
            <menu-item v-if="!isSigninPage && !isRegisterPage" icon="logout" item="" itemName="Sign Out" @selected="onSignOut" />
          </ul>
        </div>
      </b-sidebar>
 
    </template>
    <template v-else>
      <nav class="navbar is-fixed-top is-radiusless" style="margin-bottom:8px;">
 
        <div class="navbar-brand">
          <a class=" navbar-item" style="color: #ecf0f1;" @click="goHome">
            <img src="../../assets/golf-icon-48x48.png" >
            <p class="has-margin-left-10" style="font-family:'Contrail One';font-size:large;">girlap</p><p class="has-margin-left-10">{{ version }}</p>
          </a>
        </div>
        <div id="navMenu" class="navbar-menu" >
          <div class="navbar-end">
            <template v-if="isUserAuthenticated && !isRegisterPage">
              <a
                v-if="(isGroupPage || isCoursesPage) && !isGuest"
                class="navbar-item"
                @click="goHome" >
                Home
              </a>
              <a
                v-if="!isGuest"
                class="navbar-item"
                @click="gotoCourses" >
                Courses
              </a>
              <a
                class="navbar-item"
                @click="onSignOut" >
                Sign Out
              </a>
            
            </template>
            <template v-else>
              <a
                v-if="!isSigninPage && !isRegisterPage"
                class="navbar-item"
                @click="onSignIn" >
                Sign In
              </a>
            </template>
          
            <div class="navbar-item has-dropdown is-hoverable">
              <a class="navbar-link">
                <b-icon icon="help-circle-outline" />
              </a>
              <div class="navbar-dropdown is-right">
                <a class="navbar-item" @click="aboutDialog">
                  <span class="icon is-size-5 has-margin-right-5"><i class="mdi mdi-information-variant"/></span>
                  <span class="is-size-5">About</span>
                </a>
                <a v-if="!isGuest" class="navbar-item" @click="editAccount">
                 
                  <span class="icon is-size-5 has-margin-right-5"><i class="mdi mdi-account"/></span>
                  <span class="is-size-5">Account</span>

                </a>
                <a v-if="!isGuest && !isNewGroupPage" class="navbar-item" @click="newGroup">
                 
                  <span class="icon is-size-5 has-margin-right-5"><i class="mdi mdi-account-multiple-plus"/></span>
                  <span class="is-size-5">Create Group</span>

                </a>
               
              </div>
            </div>
          </div>
        </div>

      </nav>
      <b-modal has-modal-card :active.sync="showAbout" >
        <about />
      </b-modal>
      <b-modal has-modal-card :active.sync="showAccount" >
        <account />
      </b-modal>
    
    </template>
  </div>
</template>

<script>

import MenuItem from './menuItem'
import About from '../Dialogs/About'
import Account from '../Dialogs/Account'


export default {
  name: 'toolbar',
  data () {
    return {
      showMobileMenu: false,
      showAbout: false,
      showAccount: false
    }
  },
  props: {
    itemSelected: String,
    group: Object,
    round: Object,
    tournament: Object
  },

  components: {
    'menu-item': MenuItem,
    'about': About,
    'account': Account
  },
  
  watch: {
    isMobile (val) {
      this.setNavbarClass(val)
    }
  },

  mounted () {
    if (this.isMobile) {
      document.body.classList.add('has-navbar-fixed-bottom')
    }
    else {
      document.body.classList.add('has-navbar-fixed-top')
    }
  },

  beforeDestroy () {
    if (this.isMobile) {
      document.body.classList.remove('has-navbar-fixed-bottom')
    }
    else {
      document.body.classList.remove('has-navbar-fixed-top')
    }
  },
  
  computed: {
    isSigninPage () {
      return this.$route.path === '/signin'
    },

    isRegisterPage () {
      return this.$route.path === '/user/register'
    },
    isGroupPage () {
      return this.$route.path === '/group'
    },
    isNewGroupPage () {
      return this.$route.path === '/group/create'
    },
    isUserPage () {
      return this.$route.path === '/user'
    },
    isRoundPage () {
      return this.$route.path === '/group/round'
    },
    isTournamentPage () {
      return this.$route.path === '/group/tournament'
    },
    isCoursesPage () {
      return this.$route.path === '/courses' || this.$route.path === '/courses/edit'
    },
    user () {
      return this.$store.getters['user/user']
    },
    isUserAuthenticated () {
      const isAuth = this.$store.getters['auth/isAuthenticated']

      return isAuth
    },
    isGuest () {
      return this.$store.getters['user/isGuest']
    },
    version () {

      return 'v'+process.env.VUE_APP_VERSION
    },
    isAdmin () {
      return this.$store.getters['user/isAdmin']
    }
  },

  methods: {
    aboutDialog () {
      this.showAbout = true
    },
    setNavbarClass (val) {
      if (val) {
        document.body.classList.add('has-navbar-fixed-bottom')
        document.body.classList.remove('has-navbar-fixed-top')
      }
      else {
        document.body.classList.add('has-navbar-fixed-top')
        document.body.classList.remove('has-navbar-fixed-bottom')
      }
    },
    onSignIn () {
      this.$router.push('/signin')
    },
    async onSignOut () {
      await this.$store.dispatch('user/signOut')
   
    },
    goHome () {
       if (!this.isGuest) this.$router.push('/user')
    },
    gotoCourses () {
      if (this.$route.path !== 'courses') this.$router.push('/courses')
    },
    updateSelected (tab) {
  
      this.showMobileMenu = false
      this.$emit('selected', tab)
    },
    editAccount () {
   
      this.showAccount = true
    },
    newGroup () {
      this.$router.push('/group/create')
    },
    screenWidth () {
      const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  
      return width
    },
    screenHeight () {
      const height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
    
      return height
    }
  }
  
}
</script>

<style>
</style>
