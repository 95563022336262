<template>
  <div>
 
      
    <div class="level is-mobile">
      <div class="level-left">

        <div v-if="isAdmin" class="level-item">
          <div class="buttons">
            <b-button class="control" icon-left="account" @click="showAddPlayers=true" :disabled="readOnly">Add From Roster</b-button>
            <b-button class="control" @click="addPlayerGuest" :disabled="readOnly">Add Guest</b-button>
        <!--    <b-button class="control" @click="sendInvites" :disabled="readOnly">Send Invites</b-button>-->
            <b-button class="control" @click="updateHandicaps" v-if="!readOnly && tournament.isScheduled">Update Handicaps</b-button>
          </div>
        </div>
      </div>
    </div>
    <b-progress v-if="showHandicapProgress" type="is-success" :value="handicapProgressCount" />

    <div v-if="isMobile">
      <table class="table" width="100%">
        <tbody>
          <tr v-for="(tournamentScore, index) in tournamentScoresArray" :key="index" >
            <td>
              <template v-if="tournamentScore.type==='Guest'">
              <a class="has-text-link" @click="editPlayerGuest(tournamentScore)">
                {{tournamentScore.name + '(Guest)'}}
              </a>
              </template>
              <template v-else>
                {{tournamentScore.name}}
              </template>
            </td>
            <td>
              {{tournamentScore.email}}
            </td>
            <td>
              <a class="has-text-link" @click="lookupHandicap(tournamentScore)">
                {{ tournamentScore.index===null ? 'Lookup' : tournamentScore.index.full18 }}
              </a>
             
            </td>
            <td >
              <b-tooltip :label="'Delete ' + tournamentScore.name">
                <span class="icon has-margin-left-10" @click="deleteTournamentScore(tournamentScore)">
                  <i class="delete"></i>
                </span>
              </b-tooltip>
              
            </td>
          </tr>
        </tbody>
      </table>
     
    </div>
    <div v-else>

      <b-table v-if="readOnly" :data="tournamentScoresArray" default-sort="name">
     
        <b-table-column field="name" label="Name" sortable v-slot="props">
          <p>{{ props.row.name }}</p>
        </b-table-column>

        <b-table-column field="email" label="Email" sortable v-slot="props">
          <p>{{ props.row.email }}</p>
        </b-table-column>

        <b-table-column field="gender" label="Gender" sortable v-slot="props">
          <p>{{ props.row.gender }}</p>
        </b-table-column>

        <b-table-column field="tee" label="Tee" centered sortable v-slot="props">
          <p>{{ props.row.tee }}</p>
        </b-table-column>

        <b-table-column field="ghinNum" centered label="GHIN #" width="120" v-slot="props">
          <p>{{ props.row.ghinNum }}</p>
        </b-table-column>

        <b-table-column
          cell-class="vert-middle"
          label="Index"
          centered
          v-slot="props"
        >
       
          <a :ref="'indexSource'+props.row.docId" class="has-text-link" @click="lookupHandicap(props.row)">
            {{ props.row.index===null ? 'Lookup' : props.row.index.full18 }}
          </a>
       
        </b-table-column>

        <b-table-column cell-class="vert-middle" custom-key="delete" v-slot="props">
          
          <b-tooltip :label="'Delete ' + props.row.name">
            <span class="icon has-margin-left-10" @click="deleteTournamentScore(props.row)">
              <i class="delete"></i>
            </span>
          </b-tooltip>
        </b-table-column>
      </b-table>

      <b-table v-else :data="tournamentScoresArray" default-sort="name" @keyup.enter="addTournamentScore">
        <b-table-column field="name" label="Name" sortable v-slot="props">
          <b-input
            :value="props.row.name"
            size="is-capitalized"
            @blur="capName(props.row.docId, $event.target.value)"
          />
        </b-table-column>

        <b-table-column cell-class="vert-middle" field="email" label="Email" sortable v-slot="props">
          
          <b-input
            v-if="props.row.type==='Guest'"
            :value="props.row.email"
            @blur="updateEmail(props.row.docId,$event.target.value)"
          />
          <p v-else>{{ props.row.email }}</p>
        </b-table-column>

        <b-table-column field="gender" label="Gender" sortable v-slot="props">
          <b-select :value="props.row.gender" @input="value=>updateField('gender',props.row.docId,value)">
            <option value="M">M</option>
            <option value="F">F</option>
          </b-select>
        </b-table-column>

        <b-table-column field="tee" label="Tee" v-slot="props">
          <b-select :value="props.row.tee" @input="value=>updateTee(props.row.docId,value)">
            <option v-for="tee in playerTees(props.row.gender)" :value="tee" :key="tee">{{ tee }}</option>
          </b-select>
        </b-table-column>

        <b-table-column field="ghinNum" label="GHIN #" width="120" v-slot="props">
          <b-input
            :value="props.row.ghinNum"
            @blur="updateField('ghinNum',props.row.docId,$event.target.value)"
          
          />
        </b-table-column>
       
        <b-table-column
          cell-class="vert-middle"
          field="handicap"
          label="Handicap"
          centered
          v-slot="props"
        >
      
          <a :ref="'indexSource'+props.row.docId" class="has-text-link" @click="lookupHandicap(props.row)">
            {{ props.row.handicap===null ? 'Lookup' : `${formatHandicapObj(props.row.handicap,'full18')} (${props.row.indexSource} ${ props.row.index.full18})` }}
          </a>
       
        </b-table-column>

        <b-table-column cell-class="vert-middle" custom-key="delete" v-slot="props">
          
          <b-tooltip :label="'Delete ' + props.row.name">
            <span class="icon has-margin-left-10" @click="deleteTournamentScore(props.row)">
              <i class="delete"></i>
            </span>
          </b-tooltip>
        </b-table-column>
      
      </b-table>
     
    </div>
    <b-modal 
      :active.sync="showAddPlayers" 
      has-modal-card 
      :full-screen="isMobile" 
      style="z-index:100;"
    >
      <select-players 
        :players="playersToSelect" 
        :preSelectedPlayers="[]"
        @saveModal="addTournamentScores" 
 
      />
    </b-modal>
    <b-modal :active.sync="showHandicapDetails" has-modal-card :full-screen="isMobile">
      <handicap-details 
        :indexLookup = "indexLookup" 
        :handicapCalc = "handicapCalc"
        :playerInfo = "handicapPlayerInfo"
        :playerTees = "playerTees(handicapPlayerInfo.gender)"
        :viewOnly = "false"
        :handicapFac = "tournament.getHandicapFac()"
        @useHandicap="updateIndex" />
    </b-modal>
    <b-modal :active.sync="showPlayerLookup" has-modal-card>
      <ghin-player-lookup :lookup="lookup" :indexLookup="indexLookup" @selectPlayer="selectPlayer" />
    </b-modal>
    
  
  </div>  
</template>

<script>

import SelectPlayers from '../Dialogs/SelectPlayers'

import HandicapDetails from '../Dialogs/HandicapDetails'

import IndexLookup from '../../helpers/indexLookup'
import IndexItem from '../../helpers/indexItem'
import Player from '../../models/player'

import DBUpdates from '../../helpers/dbUpdates'
import GhinPlayerLookup from '../Dialogs/GhinPlayerLookup'

import HandicapCalc from '../../helpers/handicapCalc'
import Tournament from '../../models/tournament'


import { mapGetters } from "vuex"

import { capitalizeWord, formatHandicapObj, sortArrayObj } from "../../lib"
const parseFullName = require('parse-full-name').parseFullName

export default {
  data() {
    return {
      showAddPlayers: false,
      showHandicapDetails: false,
      showPlayerLookup: false,

      indexLookup: null,
      handicapCalc: null,
      handicapPlayerInfo: {},
  
      lookup: {},

      showHandicapProgress: false,
      handicapProgressCount: 0
    };
  },
  components: {
    'select-players' : SelectPlayers,
    'handicap-details': HandicapDetails,

    'ghin-player-lookup': GhinPlayerLookup
  },


  computed: {
    ...mapGetters('tournament', [
      'tab'
    ]),
    tournament () {
      let tournament = this.$store.getters['tournament/tournament']
      if (tournament === null) tournament = new Tournament()
      return tournament
    },


    group () {
      return this.$store.getters['group/group']
    },

    groupPlayersArray () {
      let groupPlayersArray = []
      const groupPlayers = this.$store.getters['group/players']
      if (groupPlayers) groupPlayersArray = groupPlayers.aPlayers
      return groupPlayersArray
    },

    tournamentScores () {
      return this.tournament.scores
    },

    tournamentScoresArray () {
      return this.tournamentScores.aScores
    },
   
  

    user () {
      return this.$store.getters['user/user']
    },
    isAdmin () {
      let admin = this.$store.getters['user/isAdmin']
     
      return admin
    },
    readOnly () {
      return !this.isAdmin
    },
    useGameIndex () {
      let gameIndex = false
      if (this.group) gameIndex = this.group.handicap.useGameIndex
      return gameIndex
    },
    useGhinIndex () {
      let ghinIndex = false
      if (this.round) ghinIndex = this.round.handicap.lookupGhinIndex
      return ghinIndex
    },
    playersToSelect() {
      // remove players already listed for round
      const players = this.groupPlayersArray.filter(p => (this.tournamentScoresArray.findIndex(s => s.playerDocId === p.docId) === -1 ))
      sortArrayObj(players, 'name')
      return players
    }
   
  },
  methods: {
    formatHandicapObj,
    
    playerTees (gender) {
      return this.tournament.course.getPlayerTees(gender)
    },

    async capName(docId, name) {
      await this.updateField('name',docId, capitalizeWord(name))
    },

    async updateEmail (docId, email) {
      await this.updateField('email',docId, email.toLowerCase())
     
    },
    async updateTee (docId, val) {
       const tournamentScore = this.tournamentScoresArray.find(p => p.docId === docId)
       const tournamentScoreUpdates = new DBUpdates()
      if (tournamentScore.index !== null) {
        const indexInfo = new IndexItem()
        indexInfo.index = tournamentScore.index.full18
        indexInfo.indexSource = tournamentScore.indexSource
        const hc = new HandicapCalc(this.tournament.course, this.group.handicap.maxHandicap)

        const handicap = hc.getPlayingHandicapObj(indexInfo, val, this.tournament.getHandicapFac(), tournamentScore.gender)
        tournamentScoreUpdates.add(docId, {handicap})
      }
      tournamentScoreUpdates.add(docId, {tee: val})
      await this.updateTournamentScoreValue(tournamentScoreUpdates)
     
    },
    async updateField(field, docId, val) {
      const value = {}
      value[field] = val
      const tournamentScore = this.tournamentScoresArray.find(p => p.docId === docId)
      if (value[field] !== tournamentScore[field]) {
        const tournamentScoreUpdates = new DBUpdates()
        tournamentScoreUpdates.add(docId, value)
        await this.updateTournamentScoreValue(tournamentScoreUpdates)
      }
    },
   
    async updateTournamentScoreValue (tournamentScoreUpdates) {
      await this.$store.dispatch('tournament/saveTournamentScoreValues', tournamentScoreUpdates)
    },

    async addTournamentScores (selectedPlayers) {
      this.showAddPlayers = false
      const settings = this.getIndexLookupSettings()
      const hc = new HandicapCalc(this.tournament.course, this.group.handicap.maxHandicap)
 
      for (let player of selectedPlayers) {
       
      
        const indexLookup = new IndexLookup(settings) 

        const playerInfo = {
          playerDocId: player.docId,
          name: player.name,
          ghinNum: player.ghinNum,
          type: player.type
        }
        player.handicap = null
        const indexInfo = await indexLookup.getIndexInfo(playerInfo)
        if (indexInfo.index) {
          player.index = indexInfo.getIndexObj()
          player.indexSource = indexInfo.indexSource
          player.handicap = hc.getPlayingHandicapObj(indexInfo, player.tee, this.tournament.getHandicapFac(), player.gender)
        }
        else {
          player.index = null
          player.indexSource = ''
          player.handicap = null
        }
       
        player.signUpDate = new Date()
        player.handicapDate = new Date()
    
      }

      await this.$store.dispatch('tournament/addTournamentScores', selectedPlayers)
     
     
    },
  
    async addPlayerGuest () {
      const player = new Player()
      player.type='Guest'
      await this.$store.dispatch('tournament/addTournamentScores', [player])
     
    },
   
    async saveGuest (playerData) {
      const player = new Player()

      player.name = playerData.name
      player.email = playerData.email
      player.tee = playerData.tee
      player.ghinNum = playerData.ghinNum
      player.index = playerData.index
      player.indexSource = playerData.indexSource
      player.handicap = playerData.handicap
      player.type = 'Guest'
    

      await this.addTournamentScores([player])
     
    },
    sendInvites () {

    },
    getIndexLookupSettings () {
      const settings = {
        handicapSettings: this.group.handicap,
        groupDocId: this.group.docId,
        // state: this.group.course.state,
        // roundDate: this.tournament.startDate,
        // lookupGroupScores: this.group.handicap.useGameIndex,
        // lookupGhinIndex: this.group.handicap.lookupGhinIndex
      }
        
      return settings
    },
    async lookupHandicap (player) {
      const settings = this.getIndexLookupSettings()
      
      this.indexLookup = new IndexLookup(settings)
      this.handicapPlayerInfo = {
        playerDocId: player.docId,
        name: player.name,
        ghinNum: player.ghinNum,
        indexSource: player.indexSource,
        gender: player.gender,
        tee: player.tee,
        type: player.type,
        clubName: this.group.course.name,
        state: this.group.course.state
      }
 
      if (this.useGameIndex) {
        
        this.handicapCalc = new HandicapCalc(this.group.course, this.group.handicap.maxHandicap)
        this.showHandicapDetails = true
      }
      else {
        if (player.ghinNum === '') {
          this.lookup = {
            state: this.group.course.state,
            firstName: parseFullName(player.name).first,
            lastName: parseFullName(player.name).last
          }
          this.showPlayerLookup = true
        }
        else {
          const indexInfo = await this.indexLookup.getGhinIndex(this.handicapPlayerInfo)
         
          await this.updateIndex ( indexInfo )

        }
        
      }
    },
    async updateIndex (indexInfo) {
       const tournamentScore = this.tournamentScores.get(this.handicapPlayerInfo.playerDocId)
       const tournamentScoreUpdates = new DBUpdates()

       
       tournamentScoreUpdates.add(this.handicapPlayerInfo.playerDocId, {indexSource: indexInfo.indexSource})
  

      if (indexInfo.index === null) {
        tournamentScoreUpdates.add(this.handicapPlayerInfo.playerDocId, {index: null})
        tournamentScoreUpdates.add(this.handicapPlayerInfo.playerDocId, {handicap: null})
      }
      else {
        
        tournamentScoreUpdates.add(this.handicapPlayerInfo.playerDocId, {index: indexInfo.getIndexObj()})
        const hc = new HandicapCalc(this.tournament.course, this.group.handicap.maxHandicap)

        const handicapObj = hc.getPlayingHandicapObj(indexInfo, this.handicapPlayerInfo.tee, this.group.getHandicapFac(),this.handicapPlayerInfo.gender)
        tournamentScoreUpdates.add(this.handicapPlayerInfo.playerDocId, {handicap: handicapObj})
        tournamentScoreUpdates.add(this.handicapPlayerInfo.playerDocId, {handicapDate: new Date()})
      }

      if (tournamentScore.ghinNum==='' && indexInfo.ghinNum !== '') {
          tournamentScoreUpdates.add (this.handicapPlayerInfo.playerDocId, {ghinNum: indexInfo.ghinNum})
      }
      await this.updateTournamentScoreValue (tournamentScoreUpdates)
    },
    
    async deleteTournamentScore (tournamentScore) {
    
      if (this.tournament.isInProgress) {
        this.$buefy.dialog.confirm({
          title: 'Delele Player',
          message: 'This torunament is in progress. Any existing scores will be deleted. Do you REALLY want to delete?',
          onConfirm: async () => await this.$store.dispatch('tournament/deleteTournamentScore', tournamentScore)
        })
      }
      else {
        await this.$store.dispatch('tournament/deleteTournamentScore', tournamentScore)
      }
    },
  
    getIndexLookupSettings () {
      const settings = {
        handicapSettings: this.group.handicap,
        groupDocId: this.group.docId,
        state: this.group.course.state,
        // roundDate: this.tournament.startDate,
        // lookupGroupScores: this.group.handicap.useGameIndex,
        // lookupGhinIndex: this.group.handicap.lookupGhinIndex
      }
        
      return settings
    },

    async selectPlayer (selectedPlayer) {
    
      this.showPlayerLookup = false
      const indexInfo = new IndexItem()
      indexInfo.index = selectedPlayer.index
      indexInfo.indexSource = 'GHIN'
      indexInfo.ghinNum = selectedPlayer.ghinNum
      await this.updateIndex ( indexInfo )
     
      
    },

    async updateHandicaps () {
      this.showHandicapProgress = true
      this.handicapProgressCount = 0

      const indexLookup = new IndexLookup(this.getIndexLookupSettings()) 
      const hc = new HandicapCalc(this.tournament.course, this.group.handicap.maxHandicap)
     
      let count = 0

      const tournamentScoreUpdates = new DBUpdates()

      for (let tournamentScore of this.tournamentScoresArray) {
        const playerInfo = {
          // docId: score.docId,
          playerDocId: tournamentScore.playerDocId,
          name: tournamentScore.name,
          ghinNum: tournamentScore.ghinNum,
          indexSource: tournamentScore.indexSource,
          type: tournamentScore.type,
          tee: tournamentScore.tee,
          state: this.tournament.course.state
        }
      
        const indexInfo = await indexLookup.getIndexInfo(playerInfo)

        
        if (indexInfo.index) {
          tournamentScore.index = indexInfo.getIndexObj()
          tournamentScore.indexSource = indexInfo.indexSource
          tournamentScore.handicap = hc.getPlayingHandicapObj(indexInfo, tournamentScore.tee, this.tournament.getHandicapFac(), tournamentScore.gender)
        }
        else {
          tournamentScore.index = null
          tournamentScore.indexSource = ''
          tournamentScore.handicap = null
        }

        const update = {
          index: tournamentScore.index,
          handicap: tournamentScore.handicap,
          indexSource: tournamentScore.indexSource,
        }
        if (tournamentScore.ghinNum==='' && indexInfo.ghinNum !== '') {
          tournamentScore.ghinNum = indexInfo.ghinNum
          update.ghinNum = indexInfo.ghinNum
        }
        tournamentScoreUpdates.add(tournamentScore.docId, update)

       

        count++
        this.handicapProgressCount = count / this.tournamentScoresArray.length * 100
      }

      this.handicapProgressCount = 0
      count = 0
     
      await this.updateTournamentScoreValue (tournamentScoreUpdates)
     
      this.showHandicapProgress = false
    

    },
    
    invitePlayer (email) {
   
      this.$store.dispatch("group/sendRegisterInvite", {Email: email}, this.user.emailAddressObj)
      this.$buefy.toast.open({
        message: "Email Invitation Sent",
        type: 'is-success'
      })


    }
    
    
  }
}
</script>

<style scoped>
.is-scrollable {
  height: 520px;
  overflow-y: auto;
}

.vert-middle {
  vertical-align: middle;
}
</style>
