
import dayjs from 'dayjs'

import { newDate, FSTimestampToDate, DateToFSTimestamp,  formatPayout, formatCurrency, nth } from '../lib'

import Rounds from './rounds'
import Games from './games'
import Players from './players'
import Course from './course'


export default class Tournament {
  constructor(data) {
    if(data) {
      this.setObj(data)
    }
    else {
      this.initialize()
    }
  }
  
  initialize () {
    this.docId = ''
    this.groupDocId = ''
   
    this.startDate = newDate()
    this.endDate = newDate()
    this.roundDate = newDate()
    this.rounds = new Rounds()
    this.players = new Players()
    this.numPlayers = 0

    this.name = ''
    this.course = new Course()
    this.status = ''
 
    this.objName = 'Tournament'
    this.games = new Games()
    this.betOnGames = false
  
    this.roundPayouts = true
    this.posted = false

    this.currentRoundDocId = ''
  }

  get formattedDate () {
    // return moment(this.date).format('dddd ll')
    if (this.rounds.size === 0) return 'No Rounds Scheduled'

    const startDate = dayjs(this.startDate).format('ddd MMM D, YYYY')
    const endDate = dayjs(this.endDate).format('ddd MMM D, YYYY')
    let dateLabel = ''
    if (startDate === endDate) {
      dateLabel = startDate
    }
    else {
      dateLabel = startDate + ' to ' + endDate
    }
    return dateLabel
  }

  
  get tsStartDate () {
    return DateToFSTimestamp(this.startDate)
  }

  get tsEndDate () {
    return DateToFSTimestamp(this.endDate)
  }

  get tsRoundDate () {
    return DateToFSTimestamp(this.roundDate)
  }

  get title () {
    return `${this.name} ${this.formattedDate}`
  }

 

  get numRounds() {
    return this.rounds.size
  }

  get firstRound () {
    let round = null
    if (this.rounds.size > 0) {
      this.rounds.sort('date','asc')
      round = this.rounds.aRounds[0]
    }
    return round

  }
  setDates () {
    if (this.rounds.size>0){
      this.rounds.sort('date','asc')
      this.startDate = this.rounds.aRounds[0].date
      this.endDate = this.rounds.aRounds[this.rounds.size-1].date
      this.roundDate = this.startDate
      for (let round of this.rounds.aRounds) {
        if (round.isInProgress) {
          this.roundDate = round.date
          this.currentRoundDocId = round.docId
          break
        }
      }
    }
    
  }

  get holeByHoleScores () {
    let val = true
    for (let round of this.rounds.aRounds) {
      if (!round.holeByHoleScores) {
        val = false
        break
      }
    }
    return val
  }

  get hasPostedRounds () {
    let posted = false
    for (let round of this.rounds.aRounds) {
      if (round.isPosted) {
        posted = true
        break
      }
    }
    return posted
  }

  setStatus () {
  
    
    if (this.numRounds === 0) {
      
      return
    }
    
    let completeCount = 0
    let postedCount = 0
  
    for (let round of this.rounds.aRounds) {
      if (round.isScheduled) {
        this.status = `Round ${round.roundNum} Scheduled`
        this.currentRoundDocId = round.docId
        break
      }
      if (round.isInProgress) {
        this.status = `Round ${round.roundNum} In Progress`
        this.roundDate = round.date
        this.currentRoundDocId = round.docId
        break
      }
      if (round.isCompleted) completeCount++
      if (round.isPosted) postedCount++
    }
    if (postedCount === this.numRounds) {
      this.status = 'Posted'
      this.posted = true
      this.currentRoundDocId = ''
    }
    else if (completeCount === this.numRounds) {
      this.status = 'Completed'
      this.currentRoundDocId = this.rounds.aRounds[0].docId
    }
   
  }

  isOkToStartRound (roundNum) {
    let ok = true
    
    for (let round of this.rounds.aRounds) {
      if (round.roundNum < roundNum) {
        if (round.isInProgress || round.isScheduled) {
          ok = false
          break
        }
      }
    }
    if (!ok) {
      const round = this.rounds.aRounds.find(r=>r.roundNum === roundNum)
      if (round !== undefined) {
        if (round.isCompleted || round.isInProgress) ok = true
      }
     
    }
    return ok
  }

  get isPosted () {
    return this.status === 'Posted'
  }

  get isScheduled () {
    return this.status.includes('Scheduled')
  }

  get isCompleted () {
    return this.status === 'Completed'
  }

  get isInProgress () {
    return this.status.includes('In Progress')
  }

  get totalBet () {
    let roundBet = 0
    for (let round of this.rounds.aRounds) {
      roundBet += round.games.totalBet
    }
    return this.games.totalBet + roundBet
  }

  get totalPool () {

    return this.totalBet * this.numPlayers
  }

  getHandicapFac() {
    let facFull18 = 1.0
    let facFront = 1.0
    let facBack = 1.0

    let game = this.games.aGames.find(g=>(g.prop==='stroke' && g.side==='full18'))
    if (game !== undefined) {
      facFull18 = game.handicapFac
    }
    game = this.games.aGames.find(g=>(g.prop==='stroke' && g.side==='front'))
    if (game !== undefined) {
      facFront = game.handicapFac
    }
    game = this.games.aGames.find(g=>(g.prop==='stroke' && g.side==='back'))
    if (game !== undefined) {
      facBack = game.handicapFac
    }

    return {
      'full18': facFull18,
      'front': facFront,
      'back': facBack,
    }
  }

  
  
  copy () {
    const tournament = new Tournament()
    tournament.setObj(this.getObj())
    return tournament
  }

 
  
  toJSON () {
    return JSON.stringify(this.getObj())
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))

  }
  getObj () {

    return {
      docId: this.docId,
      groupDocId: this.groupDocId,
   
      startDate: new Date(Number(this.startDate)),
      endDate: new Date(Number(this.endDate)),
      roundDate: new Date(Number(this.roundDate)),
      name: this.name,
      course: this.course.getObj(),
      status: this.status,
      rounds: this.rounds.getObj(),
      numPlayers: this.numPlayers,
   
      games: this.games.getObj(),
      betOnGames: this.betOnGames,
      objName: this.objName,
    
      roundPayouts: this.roundPayouts,
      posted: this.posted,
      currentRoundDocId: this.currentRoundDocId
    }
  }
  setObj (data) {
    this.docId = data.docId,
    this.groupDocId = data.groupDocId,

    this.startDate = new Date(Number(data.startDate))
    this.endDate = new Date(Number(data.endDate))
    this.roundDate = new Date(Number(data.roundDate))
    this.name = data.name
    this.course.setObj(data.course)
    this.status = data.status
  
    this.rounds.setObj(data.rounds)
    this.numPlayers = data.numPlayers
  
    this.games.setObj(data.games)
    this.betOnGames = data.betOnGames
    this.objName = data.objName
  
    this.roundPayouts = data.roundPayouts
    this.posted = data.posted
    this.currentRoundDocId = data.currentRoundDocId
  }
  getData () {
    this.rounds.sort('date','asc')
 
    return {
   
      roundDocIds: this.rounds.aRounds.map(r=>r.docId), // only store docIds
      startDate: this.tsStartDate,
      endDate: this.tsEndDate,
      roundDate: this.tsRoundDate,
     
      name: this.name,
      course: this.course.getData(),
      status: this.status,
  
      games: this.games.getData(),
      betOnGames: this.betOnGames,
      roundPayouts: this.roundPayouts,
      posted: this.posted,
      numPlayers: this.numPlayers,
      currentRoundDocId: this.currentRoundDocId
     
    }
  }
  setData (data) {
    
    for (let docId of data.roundDocIds) {
      const round = this.rounds.get(docId)
      if (round === undefined) this.rounds.addNew(docId)
    }

    this.startDate = FSTimestampToDate(data.startDate) // stored as Timestamp object
    this.endDate = FSTimestampToDate(data.endDate) // stored as Timestamp object
    this.roundDate = FSTimestampToDate(data.roundDate) // stored as Timestamp object
   
    this.name = data.name
    this.course.setData(data.course)
    
    this.status = data.status

    this.games.setData(data.games)
    this.betOnGames = data.betOnGames
   
    this.roundPayouts = data.roundPayouts
    this.posted = data.posted
    this.numPlayers = data.numPlayers
    this.currentRoundDocId = data.currentRoundDocId
  }
}

