<template>
  
  <div class="card" style="width: auto">
    <div class="card-content">
       <div class="level is-marginless">
          <div class="level-left">
            <div class="level-item">
              <p class="title is-5 is-marginless">Payout Table</p>
            </div>
          </div>
          <div class="level-right" style="padding-left: 20px;" v-if="copyFromList.length > 0">
            <div class="level-item">
              <div >
                <label class="label" style="padding-right: 8px;">Copy from</label>
              </div>
              <b-select v-model="copyFrom" @input="copyTable">
                <option v-for="(copyFrom, index) in copyFromList" :value="copyFrom" :key="index">{{ copyFrom }}</option>
              </b-select>
            </div>
          </div>
        </div>
        <div class="level is-marginless">
          <div class="level-left">
            <div class="level-item">
              <p class="subtitle is-6">Adjust payout % by number of players</p>
              <p class="control">
                <button class="button" :disabled="newPayTable.rows.length>20" style="border:none;" @click="addRow">
                  <b-icon type="is-dark" icon="plus-circle"  />
                </button>
                <button class="button" :disabled="newPayTable.rows.length===1" style="border:none;" @click="deleteRow">
                  <b-icon type="is-dark" icon="minus-circle"  />
                </button>
              </p>
            </div>
          </div>
        </div>
        
        <div class="b-table">
          <table class="table is-bordered is-narrow center-table" style="padding-bottom:10px;">
            <thead>
              <tr>
                <th colspan="2">Players</th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th v-for="(col, index) in columns" :key=index>
                  {{ col }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in newPayTable.rows" :key="index">
                <td width="70" style="text-align: center;">
                  {{ row.from }}
                </td>
                <td width="70">
                  <b-numberinput
                    class="text-center"
                    v-model="row.to"
                    :min="1"
                    :controls="false"
                    @blur="resetFromTo" />
              <!--    <vue-numeric
                    class="input text-center"
                    v-model="row.to"
                    currency=""
                    :min="1"
                    :precision="0"
                    @blur="resetFromTo" />-->

                </td>
                <td width="70">
                  <b-numberinput
                    class="text-center"
                    v-model="row.places"
                    :max="10" :min="1"
                    :controls="false"
                    @blur="resetColumns" />
                

                </td>
                <td v-for="(col,idx) in placeColumns" :key="idx" width="100">
                 
                  <vue-numeric
                    v-if="(idx+1) <= row.places"
                    class="input"
                    v-model="row.percent[col]"
                    currency="%"
                    currency-symbol-position="suffix"
                     :precision="1"
                    :max="100" :min="0"
                    @blur="checkValid(index)" /> 
                </td>
              </tr>
            </tbody>
          </table>
        </div>
  
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <div class="field is-grouped">
                <p class="control">
                  <button class="button" type="button" @click="cancelTable">Cancel</button>
                </p>
                <p class="control">
                  <button class="button is-primary" @click="saveTable" :disabled="newPayTable.isInvalid()">Save</button>
                </p>
              </div>
            </div>
            <div class="level-item">
              <p> {{ percentErrorMsg }}</p>
            </div>
          </div>
   
        </div>
        
    </div>
  </div>
           
</template>

<script>

import PayTable from '../../models/payTable'
// const { PayTable } = require('../../models/payTable')

export default {
  data () {
    return {
      baseColumns: [
        'From', 'To', 'Places'
      ],
      columns: [],
      newPayTable: new PayTable(),
      placeColumns: [],
      percentErrorMsg: '',
      copyFromList: [],
      copyFrom: ''
    }
  },
  props: ['gameIndex', 'gamesArray', 'payTable'],
  mounted () {
    this.newPayTable = this.payTable.copy()
    this.copyFromList = []
   
    for (let i=0; i<this.gamesArray.length; i++) {
      const game = this.gamesArray[i]
      if (game.bet.payout.places === 4 && this.gameIndex !== i) this.copyFromList.push(game.name)
    }

    this.resetColumns()

  },
  computed: {

  },
  methods: {
    copyTable () {
      const game = this.gamesArray.find(game => game.name === this.copyFrom)
      this.newPayTable = game.bet.payout.payTable.copy()
      this.resetColumns()
    },
    resetFromTo () {
      this.newPayTable.resetFromTo()
    },
    resetColumns () {
      this.placeColumns = this.newPayTable.places().slice(0, this.newPayTable.maxPlaces())
      this.columns = this.baseColumns.concat(this.placeColumns)
    },
    checkValid (index) {
   
      this.newPayTable.checkValidRow(index)
      this.percentErrorMsg = this.newPayTable.isInvalidRow(index) ? 'Payout percentages must total 100%' : ''
    },

    addRow () {
      this.newPayTable.addRow()
    },
    deleteRow () {
      this.newPayTable.deleteRow()
    },
    cancelTable () {
      this.$parent.close()
    },
    
    saveTable () {
      this.$emit('saveModal', this.newPayTable.copy())
      this.newPayTable = new PayTable()
      this.$parent.close()
    }
  }
}

</script>

<style>
  .center-table th {
    text-align: center;
    vertical-align: middle;
  }

  .center-table td {
      text-align: center;
      vertical-align: middle;
  }
  .text-center{
    text-align: center;
  }
  

</style>