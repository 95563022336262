import { roundTo, formatPercent } from '../lib'
import IndexScores from '../models/indexScores'

export default class IndexItem {
  constructor() {
    this.index = null
    this.indexSource = ''
    this.indexDisplay = ''
   
    this.scoresUsed = 0
    this.scoresMax = 0
    this.adj = 0
    this.fac = 1
    this.ghinNum = ''
    this._info = ''
    this.indexScores = new IndexScores()
  }

  get info () {
    let info = ''
    if (this.index !== null) {
      info = this._info
      if (this.fac !== 1) info += ` Adjusted by ${formatPercent(this.fac)}`
    }
    else {
      if (this._info === '') {
        info = 'Not enough scores'
      }
      else {
        info = this._info
      }
    }
  
    return info
  }

  set info (info) {
    this._info = info
  }

  clear() {
    this.index = null
  }


  
  calcIndex(adj) {
    this.index = this.indexScores.calcIndex(adj)
  }

  getIndex (side='full18') {
   
    if (this.index === null) return null
    let index = this.index
    if (side === 'front' || side === 'back') index = roundTo(index / 2,1)
   
    // if (index >= 0) {
      index = index * this.fac
    // }
    // else {
    //   const adjFac = -index * (1 - this.fac)
    //   index -= adjFac
    // }
    return roundTo (index, 1)
  }

  getIndexObj () {
    return {
      front: this.getIndex ('front'),
      back: this.getIndex ('back'),
      full18: this.getIndex ('full18')
    }
  }

  // get info () {
  //   let msg = ''
  //   if (this.index === null) {
  //     msg = "Index not available"
  //   }
  //   else {
  //     if (this.scoresUsed === 0 || this.scoresMax === 0){
  //       msg = "Not enough scores"
  //     }
  //     else {
  //       msg = `Avg Lowest ${this.scoresUsed} of ${this.scoresMax} Scores`
  //       if (this.adj > 0) msg += ' Adjusted ' + this.adj
  //     }
      
      
  //   }
  //   return msg
  // }

  

 
  
}


