

export default class TeamHandicapDefs {
  constructor() {
    this.defs = [
      { type: 'scramble', facs: {
          4 : [.2,.15,.1,.05],
          3 : [.2,.15,.1],
          2 : [.35,.15]
        }
      }
    ]
   
  }

  getHandicapFac (type, numPlayers) {
    let fac = []
    const def = this.defs.find(g=>g.type===type)
    if (def !== undefined) {
      fac = def.facs[numPlayers]
    }

    return fac
  }

 
 
}

