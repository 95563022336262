
<template>
  <div>
  
    <div v-if="isMobile" class="buttons">
      <template v-if="round.isScheduled">
        <b-button type="is-primary" @click="$emit('start-round')">Start Round</b-button>
      </template>
      <template v-else-if="round.isInProgress">
        <b-button type="is-primary" @click="$emit('stop-round')">Stop Round</b-button>
        <b-button type="is-primary" @click="$emit('complete-round')">Complete Round</b-button>
      </template>
      <template v-else-if="round.isCompleted">
        <b-button type="is-primary" @click="$emit('restart-round')">Restart Round</b-button>
        <b-button type="is-primary" @click="$emit('post-round')">Post Round</b-button>

      </template>
      <template v-else-if="round.isPosted">
        <b-button type="is-primary" @click="$emit('unpost-round')">Unpost Round</b-button>
        <b-button @click="$emit('show-send-results-email')">Send Results Email...</b-button>

      </template>

    </div>
               
    <b-tabs v-model="roundSettingsTab">
      <b-tab-item label="General">
        <b-field label="Round Name">
       
          <b-input :disabled="round.isPosted"  :value="round.name" @blur="updateRoundName($event.target.value)" />
        </b-field>
        <b-field grouped>
          <b-field label="Round Date">
            <b-datepicker
              :value="round.date"
              :disabled="round.isPosted"
              placeholder="Click to select..."
              :icon="isMobile?'':'calendar-today'"
              append-to-body
              :date-parser="(date) => dayjs(date).format('ddd MMM D, YYYY')"
              @input="value=>updateRoundDate(value)">
            </b-datepicker>
          </b-field>
          <b-field label="Holes">
             <b-select :disabled="!round.isScheduled" :value="round.numHoles" @input="value=>updateNumHoles(value)">
              <option :value="18">18</option>
              <option :value="9">9</option>
            </b-select>
          </b-field>
        </b-field>
        
        <b-field>
          <select-course :course="round.course" label="Course" @courseSelected="selectCourse" :disabled="round.isPosted"/>
        </b-field>

        <b-field>
          <b-switch :value="round.betOnGames" :disabled="round.isPosted" @input="value=>updateBetOnGames(value)">Bet On Games</b-switch>
        </b-field >

        <b-field v-show="round.betOnGames">
          <b-switch :value="round.roundPayouts" @input="value=>updateRoundPayouts(value)" :disabled="round.isPosted">Round Payouts to Whole Number</b-switch>
        </b-field >

        <b-field >
          <b-switch :disabled="!round.isScheduled" :value="round.holeByHoleScores" @input="value=>updateHoleByHoleScores(value)">
            Hole by Hole Scoring
          </b-switch>
        </b-field>

     
      
        <b-field label="Playing Conditions Adjustment (PCC)">
          <b-select
            :disabled="round.isScheduled"
            :value="round.pcc" 
            @input="value=>updatePcc(value)"
          >
            <option :value="-1">-1</option>
            <option :value="0">0</option>
            <option :value="1">1</option>
            <option :value="2">2</option>
            <option :value="3">3</option>
          </b-select>
        </b-field>
        <b-field >
          <b-switch :disabled="!round.isCompleted && round.isNotPosted" :value="round.excluded" @input="value=>updateExcluded(value)">
            Exclude all scores for future handicaps
          </b-switch>
        </b-field>
        <b-button type="is-danger" outlined @click="$emit('delete-round')">Delete this round</b-button>
      </b-tab-item>
      <b-tab-item label="Handicaps">
          <handicap-fields :baseObj="round" :disabled="round.isPosted"/>
      </b-tab-item>
    </b-tabs>

    
  </div>
</template>

<script>

import SelectCourse from '../../components/Course/SelectCourse'
import HandicapFields from '../../components/Shared/HandicapFields'
import { DateToFSTimestamp } from '../../lib'
import DBUpdate from '../../helpers/dbUpdate'
import DBUpdates from '../../helpers/dbUpdates'

export default {
  data() {
    return {
      roundSettingsTab: 0,
    }
  },
  props: ['round','scoresArray'],

  components: {
    'select-course': SelectCourse,
    'handicap-fields': HandicapFields
  },

 
  

  methods: {
    async updateRoundName (name) {
      const roundUpdate = new DBUpdate(this.round.docId, {name: name})
      
      await this.$store.dispatch('round/saveValues', roundUpdate)
  
    },

    async updateRoundDate(val) {
      const roundUpdate = new DBUpdate(this.round.docId, {date: DateToFSTimestamp(val)})
      await this.$store.dispatch('round/saveValues', roundUpdate)
 
    },

    async selectCourse (course) {
      if (course.docId !== this.round.course.docId) {
        const update = {}

        update.courseDocId = course.docId
        update.course = course.getData()
         
        const roundUpdate = new DBUpdate(this.round.docId, update)
        await this.$store.dispatch('round/saveValues', roundUpdate)
       
      }
    },

    async updateBetOnGames (val) {
      const roundUpdate = new DBUpdate(this.round.docId, {betOnGames: val})
      await this.$store.dispatch('round/saveValues', roundUpdate)
  
    },

    async updateRoundPayouts (val) {
      const roundUpdate = new DBUpdate(this.round.docId, {roundPayouts: val})
      await this.$store.dispatch('round/saveValues', roundUpdate)
     
    },

    async updateNumHoles (val) {
      const roundUpdate = new DBUpdate(this.round.docId, {numHoles: val})
      await this.$store.dispatch('round/saveValues', roundUpdate)
    
    },

   
    async updatePcc (val) {
      await this.$store.dispatch('round/updatePcc', val)
    },
    async updateExcluded (val) {
      await this.$store.dispatch('round/updateExcluded', val)
    },

    async updateHoleByHoleScores (val) {
      if (!val) {
        this.$buefy.dialog.confirm({
          message: 'Hole by hole scores will be deleted, continue?',
          onConfirm: async () => {
            const scoreUpdates = new DBUpdates()
            for (let score of this.scoresArray) {
              scoreUpdates.add(score.docId, {score: {front: 0, back: 0, full18: 0}})
              // scoreUpdates.add(score.docId, {netScore: {front: '', back: '', full18: ''}})
              scoreUpdates.add(score.docId, {scores: []})
              scoreUpdates.add(score.docId, {skins: []})
              scoreUpdates.add(score.docId, {skinsCount: 0})
              scoreUpdates.add(score.docId, {kp: []})
              scoreUpdates.add(score.docId, {kpCount: 0})
              scoreUpdates.add(score.docId, {dots: []})
              scoreUpdates.add(score.docId, {dotsCount: 0})
              scoreUpdates.add(score.docId, {final: false})
            
              // scoreUpdates.add(score.docId, {toPar: {front: '', back: '', full18: ''}})
            }
          
            await this.$store.dispatch('round/saveScoreValues', scoreUpdates)
              
            const roundUpdate = new DBUpdate(this.round.docId, {holeByHoleScores: val})
            await this.$store.dispatch('round/saveValues', roundUpdate)
          
          }
        })
      }
      else {
        const roundUpdate = new DBUpdate(this.round.docId, {holeByHoleScores: val, final: false})
        await this.$store.dispatch('round/saveValues', roundUpdate)
      
      }

      
    }

  }
}
</script>

<style scoped>

</style>
