
import ScoreController from '../controllers/score'
import GhinController from '../controllers/ghin'



import { sortArrayObj, sortArrayNum, isNumeric} from '../lib'

import IndexAvgTable from '../models/indexAvgTable'
import IndexItem from './indexItem'


const parseFullName = require('parse-full-name').parseFullName
import dayjs from 'dayjs'

export default class IndexLookup {
  constructor(init) {

    this.handicapSettings = init.handicapSettings

    // this.handicap = settings.handicap
    this.groupDocId = init.groupDocId
    // this.state = init.state
    // this.roundDate = settings.roundDate
    // this.lookupGroupScores = settings.lookupGroupScores
    // this.lookupGhinIndex = settings.lookupGhinIndex
  
   
    // this.groupScoresCache = []
    // this.ghinScores = []
    // this.manualScores = []

    // this.cacheWhere = {
    //   playerDocId: ''
    // }

 
  }

  // init () {
  //   this.groupScoresCache = []
  //   this.ghinScores = []
  //   this.manualScores = []
  // }

  
  async getIndexInfo (playerInfo, getScoreHistory=false) {

    let indexInfoGroup
    let indexInfoGhin
    let indexInfo

    if (playerInfo.playerDocId !== '' && this.handicapSettings.useGameIndex) {
      indexInfoGroup = await this.getGroupIndexInfo(playerInfo)
     
      if (indexInfoGroup.index === null && this.handicapSettings.method.noGroupScoresGhin) {
        const indexInfoGhin = await this.getGhinIndexInfo(playerInfo)
        indexInfoGhin.fac = this.handicapSettings.method.noGroupScoresGhinFac
       
        indexInfo = indexInfoGhin
      }
      // else if ( this.groupScoresCache.length < this.handicap.method.useGhinIndexScores && this.handicap.method.lowestGhinGroup) { 
      //   indexInfoGhin = await this.getGhinIndex(asOfDate, playerInfo)
      
      //   if( indexInfoGroup.index && indexInfoGhin.index) {
      //     if (indexInfoGroup.index < indexInfoGhin.index) {
      //       indexInfo = indexInfoGroup
      //     }
      //     else {
      //       indexInfo = indexInfoGhin
      //     }
      //   }
      //   else {
      //     indexInfo = indexInfoGhin
      //   }
      // }
      else {
        indexInfo = indexInfoGroup
      }
    }
    else {
      indexInfoGhin = await this.getGhinIndexInfo(playerInfo, getScoreHistory)
    
      indexInfo = indexInfoGhin
    }

    return indexInfo
  }
  
  async getGroupIndexInfo (playerInfo) {
 
    const indexInfo = new IndexItem()
   
    if (this.handicapSettings.useGameIndex) {

      let months = 0
  
      if (this.handicapSettings.method.limitScores) {
        months = this.handicapSettings.method.limitScoresMonths
      }
      
      const response = await ScoreController.getHandicapGroupScores(this.groupDocId, playerInfo.playerDocId, months, this.handicapSettings.method.groupIndexTable.getMaxPostedScores())
  

      
      if (response.errorMsg.length == 0) {
    
        indexInfo.indexScores.setFromScores(response.scoresArray)
        const indexTable = this.handicapSettings.method.groupIndexTable
        indexInfo.indexScores.numScoresToUse = indexTable.getScoresToUse(response.scoresArray.length)
        indexInfo.indexScores.maxScoresToUse = indexTable.getMaxPostedScores()

        // let minScores = false
      
        // if (this.handicap.method.minScores) {
        //   const dtMinScores = dayjs().subtract(this.handicap.method.numMinScoresMonths,'month').toDate()
        
        //   const minHandicapScores = this.groupScoresCache.filter(s => dayjs(s.roundDate).isAfter(dtMinScores))
      
        //   if (minHandicapScores.length < this.handicap.method.numMinScores) minScores = true
        // }
      
     
        // if ((this.groupScoresCache.length < indexTable.minScores) || minScores) {
        if (this.handicapSettings.method.noGroupScoresGhin) {

          if ((indexInfo.indexScores.size < indexTable.getMaxPostedScores())) {
            const indexInfoGhin = await this.getGhinIndexInfo(playerInfo)
            indexInfoGhin.fac = this.handicapSettings.method.noGroupScoresGhinFac

            indexInfo.indexScores.addFillerScores(indexInfoGhin.getIndex())
            indexInfo.indexScores.numScoresToUse = indexTable.getScoresToUse(indexInfo.indexScores.scoresArray.length)
          }

          indexInfo.calcIndex(indexTable.getAdjustment(indexInfo.indexScores.size))
          indexInfo.info = indexTable.getInfo (indexInfo.indexScores.size)

        }
        else {
        
          if (indexInfo.indexScores.size < indexTable.getMinPostedScores()) {
            indexInfo.clear()
          }
          else {

            indexInfo.calcIndex(indexTable.getAdjustment(indexInfo.indexScores.size))
            indexInfo.info = indexTable.getInfo (indexInfo.indexScores.size)
        
            
          }
        }
        indexInfo.indexSource = 'Group'

      }
      
    }
    else {
      console.log(response.errorMsg)
    }
    
    return indexInfo
  }

  async getGhinIndexInfo (player, getScoreHistory=false) {
 
    const indexInfo = new IndexItem()

    const name = parseFullName(player.name)
    const playerInfo = {
      ghinNum: player.ghinNum,
      firstName: name.first,
      lastName: name.last,
      state: player.state,
      clubName: player.clubName
    }

    // if ( (this.lookupGhinIndex && !this.lookupGroupScores) || (this.handicap.method.groupIndexAvg === 'ghin')) {
      const playersArray = await GhinController.getGhinPlayers(playerInfo)


      if (playersArray.length > 0) {
      
        indexInfo.indexDisplay = playersArray[0].index
        if (isNumeric(indexInfo.indexDisplay)) indexInfo.index = parseFloat(playersArray[0].index)
        if (playersArray[0].index[0] === '+') indexInfo.index = -indexInfo.index
        indexInfo.info = "From GHIN"
        indexInfo.ghinNum = playersArray[0].ghinNum
        if (player.type === 'New'  || player.type === 'Guest' || player.type === 'Inactive') indexInfo.fac = this.handicapSettings.method.guestGhinFac
      }
      else {
        indexInfo.clear()
        indexInfo.info = "Index not found in GHIN"
      }

    if (getScoreHistory) {
     
      const response = await GhinController.getHandicapGhinScores(playerInfo)
      indexInfo.indexScores.setFromScores(response.scoresArray)
      const indexTable = new IndexAvgTable(true)
      indexInfo.indexScores.numScoresToUse = indexTable.getScoresToUse(response.scoresArray.length)
      indexInfo.indexScores.maxScoresToUse = indexTable.getMaxPostedScores()
      indexInfo.indexScores.calcIndex(indexTable.getAdjustment(indexInfo.indexScores.size))
      indexInfo.ghinNum = response.ghinNum


    }

    // }
    // else {
    //   const response = await GhinController.getHandicapGhinScores(playerInfo)
    //   this.ghinScores = response.scoresArray
    //   indexInfo.ghinNum = response.ghinNum
      
      
    //   // remove scores posted posted after asOfDate
  
    //   const d_asOfDate = dayjs(asOfDate)
    //   this.ghinScores = this.ghinScores.filter(s => {
    //     const d_ghinDate = dayjs(s.formattedDate)
    //     return d_ghinDate.isBefore(d_asOfDate) || d_ghinDate.isSame(d_asOfDate)
    //   })
  
  
    //   const indexTable = new IndexAvgTable(true)
  
    //   // get just the diffs and only get the number needed for calc
  
    //   let handicapDiffs = this.ghinScores.slice(0, indexTable.getMaxPostedScores()).map(score => score.diff)
  
    //   sortArrayNum (handicapDiffs) // sort to get lowest diffs
  
    //   // get lowest diffs
    //   handicapDiffs = handicapDiffs.slice(0, indexTable.getScoresToUse(handicapDiffs.length))
    //   indexInfo.scoresUsed = indexTable.getScoresToUse(handicapDiffs.length)
    //   indexInfo.scoresMax = this.ghinScores.length
    //   indexInfo.indexDisplay = indexInfo.index
    //   if (isNumeric(response.index)) indexInfo.index = parseFloat(response.index)
    //   // indexInfo.calcIndex (handicapDiffs, indexTable.getAdjustment(this.ghinScores.length))
    //   indexInfo.info = "From GHIN"

    // }

    
    indexInfo.indexSource = 'GHIN'
 
    
    return indexInfo
  }
  // getManualIndex () {
 
  //   const indexInfo = new IndexItem()
  
  //   const indexTable = this.getIndexTable()

  //     // get just the diffs and only get the number needed for calc

  //   let handicapDiffs = this.manualScores.map(score => score.diff)
  //   handicapDiffs = handicapDiffs.filter(diff => diff !== '') // only count numbers entered

  //   sortArrayNum (handicapDiffs) // sort to get lowest diffs

  //     // get lowest diffs
  //   handicapDiffs = handicapDiffs.slice(0, indexTable.getScoresToUse(handicapDiffs.length))

  //   indexInfo.calcIndex (handicapDiffs, 0)
  //   indexInfo.indexSource = 'Manual'
 
  //   return indexInfo
  // }

  // async getHandicapGroupScores (playerDocId) {
 

  //   let months = 0
    
   
  //   if (this.handicapSettings.method.limitScores) {
  //     months = this.handicapSettings.method.limitScoresMonths
  //   }
    
  //   const response = await ScoreController.getHandicapGroupScores(this.groupDocId, playerDocId, months, this.handicapSettings.method.groupIndexTable.getMaxPostedScores())
    
  //   return response
  // }
 
  

  // getHandicapDiffs (scoresArray, indexTable) {
 
  //   sortArrayObj(scoresArray, 'roundDate', 'desc')

  //   // if (scoresArray.length < indexTable.getMinPostedScores()) { // not enough scores
  //   //   return []
  //   // }
  //   const diffs = scoresArray.slice(0, indexTable.getMaxPostedScores()).map(score => score.diff['full18'])

  //   sortArrayNum (diffs)

  //   const handicapDiffs = diffs.slice(0, indexTable.getScoresToUse(diffs.length))

 

  //   return handicapDiffs
  // }
  
 
  
  // async getGroupDiffsDisplay (asOfDate, playerInfo) {
  //   let diffs = []
  //   const ret = {
  //     errorMsg: '',
  //     diffs: []
  //   }
   
  //   const response = await this.getHandicapGroupScores(asOfDate, playerInfo.playerDocId)
  //   if (response.errorMsg === '') {
  //     this.groupScoresCache = response.scoresArray
  //     const indexTable = this.getIndexTable()
  //     diffs = this.groupScoresCache.map(s => {
  //       return {
  //         diffUsed: false,
  //         roundDate: s.roundDate,
  //         formattedDate: s.formattedDate,
  //         score: s.score.full18,
  //         diff: s.diff.full18,
  //         pcc: s.pcc
  //       }
  //     })
     
  //     sortArrayObj(diffs, 'roundDate', 'desc')

  //     // if (diffs.length >= indexTable.getMinPostedScores()) { 
  //       diffs = diffs.slice(0, indexTable.getMaxPostedScores())

  //       if (this.settings.handicap.method.noGroupScoresGhin && diffs.length < indexTable.getMaxPostedScores()) {

  //       }
  //       sortArrayObj(diffs, 'diff', 'asc')
        
  //       const scoresToUse = indexTable.getScoresToUse(diffs.length)
  //       if (diffs.length >= indexTable.getMinPostedScores()) {
  //         for(let i=0; i<scoresToUse; i++) {
  //           diffs[i].diffUsed = true
  //         }
  //       }
  //       sortArrayObj(diffs, 'roundDate', 'desc')

  //       ret.diffs = diffs
  //     // }
  //   }
  //   else {
  //     ret.errorMsg = response.errorMsg
  //   }

  //   return ret
  // }
  async getGhinPlayers (lookupInfo) {
  
    return await GhinController.getGhinPlayers(lookupInfo)

  }
  // async getGhinDiffsDisplay (asOfDate, player) {
  //   const name = parseFullName(player.name)
  //   const playerInfo = {
  //     ghinNum: player.ghinNum,
  //     firstName: name.first,
  //     lastName: name.last,
  //     state: this.settings.state,
  //     clubName: player.clubName
  //   }

  //   let diffs = []
  //   const ret = {
  //     errorMsg: '',
  //     diffs: [],
  //     ghinNum: ''
  //   }
  //   const response = await GhinController.getHandicapGhinScores(playerInfo)
  //   this.ghinScores = response.scoresArray
  //   ret.ghinNum = response.ghinNum

  //   // remove scores posted posted after asOfDate

  //   const d_asOfDate = dayjs(asOfDate)
  //   this.ghinScores = this.ghinScores.filter(s => {
  //     const d_ghinDate = dayjs(s.formattedDate)
  //     return d_ghinDate.isBefore(d_asOfDate)
  //   })
  
  //   const indexTable = new IndexAvgTable(true)
  //   // scores from ghin are ordered by most recent so grab the ones needed for index calc (e.g., 10)
    
  //   diffs = this.ghinScores.slice(0,indexTable.getMaxPostedScores()).map((s,index) => {
    
  //     return {
  //       diffUsed: false,
  //       order: index,
  //       formattedDate: s.formattedDate,
  //       score: s.score, //+ ' (' + s.tee + ')',
  //       diff: s.diff,
  //       course: s.course,
  //       pcc: s.pcc
  //     }
  //   })
    
  //   if (diffs.length >= indexTable.getMinPostedScores()) { 

  //     sortArrayObj(diffs, 'diff', 'asc')
  //     const scoresToUse = indexTable.getScoresToUse(diffs.length)
      
  //     for(let i=0; i<scoresToUse; i++) {
  //       diffs[i].diffUsed = true
  //     }
  //     sortArrayObj(diffs, 'order', 'asc')
  //   }
  //   ret.diffs = diffs
  //   ret.adjustment = indexTable.getAdjustment(this.ghinScores.length)
   

  //   return ret
  // }
  // getManualDiffsDisplay () {
   
  //   const ret = {
  //     errorMsg: '',
  //     diffs: []
  //   }
   
  //   if (this.manualScores.length === 0) {
  //     const indexTable = this.getIndexTable()
     
  //     for(let i=0; i<indexTable.getMaxPostedScores(); i++) {
  //       const score = {
  //         diffUsed: false,
  //         order: i,
  //         formattedDate: '',
  //         score: '',
  //         diff: ''
  //       }
  //       this.manualScores.push(score)
  //     }
  //   }
    
      
  //   ret.diffs = this.manualScores
  
  //   return ret
  // }
}

