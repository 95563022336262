import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from './routes'
import store from '../store'


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach( async (to, from, next) => {

  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const isAuthed = store.getters['auth/isAuthenticated']

 

  if (!isAuthed && requiresAuth) {
  
    next('/sigin')
  }
  else if (isAuthed && store.getters['user/user'] === null) {
   
    await store.dispatch('user/initialize',null,{root:true})
    if (to.path === '/') {
      next('/user')
    }
    else {
      next()
    }
   
  }
  else {
    next()
  }

})

export default router
