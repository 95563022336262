
import dayjs from 'dayjs'
import TeeTimes from './teeTimes'
import { newDate } from '../lib'


export default class UserSchRound {
  constructor(data) {
    if(data) {
      this.setObj(data)
    }
    else {
      this.initialize()
    }
  }
  
  initialize () {
    this.scoreDocId = ''
    this.docId = ''
    this.tournamentDocId = null
    this.roundDate = newDate()
    this.startDate = newDate()
    this.endDate = newDate()
    this.name = ''
    this.roundStatus = ''
    this.courseLabel = ''
    this.numPlayers = 0
    this.teeTimes = new TeeTimes()
    this.roundNum = ''
    this.teeTimeRequest = ''
    this.closeSignups = false
  }
  
  get formattedDate () {
    // return moment(this.date).format('ddd L')
    return dayjs(this.roundDate).format('ddd MM/DD/YYYY')
  }

  get roundDateLabel () {
    let label =''
    if (this.tournamentDocId == null) {
      label = dayjs(this.roundDate).format('ddd MM/DD/YYYY')
    }
    else {
      const startDate = dayjs(this.startDate).format('ddd MM/DD/YYYY')
      const endDate = dayjs(this.endDate).format('ddd MM/DD/YYYY')
      if (startDate === endDate) {
        label = startDate
      }
      else {
        label = startDate + ' to ' + endDate
      }
    }
    return label
  }

 
  

  get isInProgress () {
    return this.roundStatus.includes('In Progress')
  }

  get isScheduled () {
    return this.roundStatus.includes('Scheduled')
  }

  get isCompleted () {
    return this.roundStatus === 'Completed'
  }

  get teeTimeLabel () {
    let label = ''
    if (this.scoreDocId !== '') {
      if (this.teeTimes.hasTeeTime (this.scoreDocId)){
        const teeTime = this.teeTimes.getTeeTimeByScoreDocId(this.scoreDocId)
        label = 'Tee Time: ' + teeTime.time
      }
      // else {
      //   label = this.teeTimeRequest
      // }
    }
  
    return label
  }

  // get hasTeeTimeRequest () {
  //   return this.teeTimeRequest.length > 0 && this.isScheduled
  // }
  
  copy () {
    const schRound = new UserSchRound()
    schRound.setObj(this.getObj())
    return schRound
  }
  toJSON () {
    return JSON.stringify(this.getObj())
  }
  fromJSON (strObj) {
    this.setObj(JSON.parse(strObj))
  }
  getObj () {
    return {
      scoreDocId: this.scoreDocId,
      docId: this.docId,
      tournamentDocId: this.tournamentDocId,
      roundDate: new Date(Number(this.roundDate)),
      startDate: new Date(Number(this.startDate)),
      endDate: new Date(Number(this.endDate)),
      name: this.name,
      roundStatus: this.roundStatus,
      courseLabel: this.courseLabel,
      numPlayers: this.numPlayers,
      teeTimes: this.teeTimes.copy(),
      roundNum: this.roundNum,
      teeTimeRequest: this.teeTimeRequest,
      closeSignups: this.closeSignups
    }
  }
  setObj (data) {
    this.scoreDocId = data.scoreDocId
    this.docId = data.docId,
    this.tournamentDocId = data.tournamentDocId
    this.roundDate = new Date(Number(data.roundDate))
    if(typeof data.roundDate === "string") this.roundDate = new Date(data.roundDate)
    this.startDate = new Date(Number(data.startDate))
    this.endDate = new Date(Number(data.endDate))
    this.name = data.name
    this.roundStatus = data.roundStatus
    this.courseLabel = data.courseLabel
    this.numPlayers = data.numPlayers
    this.teeTimes = data.teeTimes.copy()
    this.roundNum = data.roundNum
    this.teeTimeRequest = data.teeTimeRequest
    this.closeSignups = data.closeSignups
  }

  setFromRound (round) {
    this.docId = round.docId
    this.tournamentDocId = round.tournamentDocId
    this.roundDate = round.date
    this.name = round.name
    this.roundNum = round.roundNum
    this.roundStatus = round.status
    this.courseLabel = round.course.label
    this.numPlayers = round.numPlayers
    this.teeTimes = round.teeTimes
    this.closeSignups = round.closeSignups
    
  }

  setTournament (tournament) {
    this.tournamentDocId = tournament.docId
  
    this.docId = tournament.currentRoundDocId
  
    
    this.startDate =  tournament.startDate
    this.endDate = tournament.endDate
    this.name = tournament.name
    this.roundStatus = tournament.status
    this.courseLabel = tournament.course.label
    this.numPlayers = tournament.numPlayers
    this.roundDate = tournament.roundDate
    
  }
  
  
  
}


