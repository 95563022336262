<template>
  <b-table
    :data="tees"
   
    narrowed
    bordered
    detailed
    detail-key="shortName" >
   
      <b-table-column label="Tee" v-slot="props">{{ props.row.listName }} </b-table-column>
      <b-table-column label="Gender" centered v-slot="props">{{ props.row.gender }} </b-table-column>
      <b-table-column label="Rating/Slope - 18" centered v-slot="props">{{ `${props.row.rating.full18}/${props.row.slope.full18}` }} </b-table-column>
     
      <b-table-column label="Rating/Slope - Front" centered v-slot="props">{{ `${props.row.rating.front}/${props.row.slope.front}` }} </b-table-column>
     
      <b-table-column label="Rating/Slope - Back" centered v-slot="props">{{ `${props.row.rating.back}/${props.row.slope.back}` }} </b-table-column>
     
      <b-table-column label="Length" centered v-slot="props">{{ props.row.totalYards }} </b-table-column>

    <template slot="detail" slot-scope="props">
      <b-table
        :data="props.row.getHoles(isMobile?'':'wide')"
        :columns="holeColumns"
        :mobile-cards="false"
        narrowed >
      </b-table>
    </template>
  </b-table>
</template>

<script>

import { scoreCardHoleColumns } from '../../lib'

export default {
  data () {
    return {
     
    }
  },
  props: ['tees'],
  computed: {
    holeColumns () {
      return scoreCardHoleColumns(this.isMobile?'':'wide')
    }
  }

}

</script>

<style>

</style>
