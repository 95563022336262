<template>
  
  <div class="card" style="width: auto">
    <div class="card-content">
      <p class="title is-5">Stableford Points</p>
        
      <div class="b-table">
        <table class="table is-bordered is-narrow center-table" style="padding-bottom:10px;">
          <thead>
            <tr>
              <th>
                To Par
              </th>
              <th>
                Points
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in newPointsTable.rows" :key="index">
              <td width="70" style="text-align: center;">
                {{ row.toPar }}
              </td>
              <td width="70">
                <b-numberinput
                  class="text-center"
                  v-model="row.points"
                  :controls="false"
                   />
 
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <div class="field is-grouped">
              <p class="control">
                <button class="button" type="button" @click="cancelTable">Cancel</button>
              </p>
              <p class="control">
                <button class="button is-primary" @click="saveTable" >Save</button>
              </p>
            </div>
          </div>
     
        </div>
  
      </div>
        
    </div>
  </div>
           
</template>

<script>

import PointsTable from '../../models/pointsTable'

export default {
  data () {
    return {
  
      newPointsTable: new PointsTable()
      
    }
  },
  props: ['pointsTable'],
  mounted () {
    this.newPointsTable = this.pointsTable.copy()

  },
  computed: {

  },
  methods: {
    
    cancelTable () {
      this.$parent.close()
    },
    
    saveTable () {
      this.$emit('saveModal', this.newPointsTable.copy())
      this.$parent.close()
    }
  }
}

</script>

<style>
  .center-table th {
    text-align: center;
    vertical-align: middle;
  }

  .center-table td {
      text-align: center;
      vertical-align: middle;
  }
  .text-center{
    text-align: center;
  }
  

</style>