
<template>
  <div>
    <div class="field">
      <b-switch
        :value="handicap.useGameIndex"
        :disabled="disabled"
        @input="value=>updateUseGameIndex(value)"
      >Calculate Indexes from Rounds Played Within the Group (Group Index)</b-switch>
    </div>

    <b-field v-if="!handicap.useGameIndex">
      <b-switch
        :value="handicap.lookupGhinIndex"
        :disabled="disabled"
        @input="value=>updateLookupGhinIndex(value)"
      >Lookup indexes in GHIN</b-switch>
    </b-field>
      
    <template v-if="handicap.useGameIndex">
    
      
      <b-field label="Max Handicap">
        <b-numberinput
          class="text-center"
          :disabled="disabled"
          :value="handicap.maxHandicap"
          :controls="false"
          style="width:4em;"
          @blur="updateMaxHandicap($event.target.value)"
        />
      </b-field>
    </template>
  
    <div v-if="handicap.useGameIndex">
      <p class="subtitle has-margin-top-15 has-margin-bottom-15">Group Index Calculation</p>

      <p class="control field" style="padding-top:8px;">Scores Used in Calculation</p>
    
     
      

      
      <div class="card" style="width: auto">
        <div class="card-content">
          <div class="block">
        <b-radio
          v-model="groupIndexAvg"
          :disabled="disabled"
          native-value="ghin"
          @input="setGroupIndexAvg"
        >USGA
        </b-radio>
    
        <b-radio
          v-model="groupIndexAvg"
          :disabled="disabled"
          native-value="table"
          @input="setGroupIndexAvg"
          >Custom
        </b-radio>
      </div>
            <div class="level is-mobile" v-if="groupIndexAvg=='table'">
              <div class="level-left">
                <div class="level-item">
                  <p class="field-label has-text-weight-bold">Minimum posted scores:</p>
                </div>
                <div class="level-item">
                  <b-numberinput
                    class="text-center"
                 
                    :value="avgTable.getMinPostedScores()"
                  
                    :min="1" :max="99"
                    @input="setMinPostedScores"
                  />
             
                 
                </div>
              </div>
            </div>
            <div class="level is-mobile" v-if="groupIndexAvg=='table'">
              <div class="level-left">
                <div class="level-item">
                  <p class="field-label has-text-weight-bold">Maximum posted scores:</p>
                </div>
                <div class="level-item">
                  <b-numberinput
                    class="text-center"
               
                    :value="avgTable.getMaxPostedScores()"
                
                    :min="1" :max="99"
                    @input="setMaxPostedScores"
                 
                  />
             
                 
                </div>
              </div>
            </div>
            
            <div class="b-table">
              <table class="table is-bordered is-narrow center-table" style="padding-bottom:10px;">
                <thead>
                  <tr>
                    <th class="is-size-7">Posted Scores</th>
                    <th class="is-size-7">Lowest Scores to Use</th>
                    <th class="is-size-7">Adjustment</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="groupIndexAvg=='table'">
                    <tr v-for="(row, index) in avgTable.rows" :key="index">
                      <td class="is-size-7 text-center">
                        {{ row.postedScores }}
                      </td>
                    
                      <td class="is-size-7" >
                        <b-numberinput
                          class="text-center is-size-7"
                          v-model="row.scoresToUse"
                          :controls="false"
                          :min="1"
                        />

                      </td>
                      <td class="is-size-7">
                        <b-numberinput
                          class="text-center is-size-7"
                          v-model="row.adjustment"
                          :controls="false"
                          :min="1"
                        />

                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr v-for="(row, index) in avgTable.rows" :key="index">
                      <td class="is-size-7 text-center">
                        {{ row.postedScores }}
                      </td>
                      <td class="is-size-7 text-center">
                        {{ row.scoresToUse }}

                      </td>
                      <td class="is-size-7 text-center">
                        {{ row.adjustment }}

                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
      
            <div class="level is-mobile">
              <div class="level-left">
                <div class="level-item">
                  <div class="field is-grouped">
                    <p class="control">
                      <button class="button" type="button" @click="cancelTable">Cancel</button>
                    </p>
                    <p class="control">
                      <button class="button is-primary" :disabled="!tableEdit" @click="saveTable">Save</button>
                    </p>
                  </div>
                </div>
              </div>
      
            </div>
            
        </div>
      </div>
     
      <b-field grouped>
        <b-checkbox
          :value="method.limitScores"
          :disabled="disabled"
          @input="value=>updateLimitScores(value)">
          Only use scores posted in the last
        </b-checkbox>
       
        <div class="control">
          <b-numberinput
            class="text-center has-margin-left-5"
            style="width:3em;"
            :disabled="!method.limitScores || disabled"
            :value="method.limitScoresMonths"
            :min="1"
            :controls="false"
            @blur="updateLimitScoresMonths($event.target.value)"
          />
        </div>
        <p class="control mt-2" >
          months
        </p>
      </b-field> 

      

      <b-field grouped>
         <b-checkbox
          :value="method.noGroupScoresGhin"
          :disabled="disabled"
          @input="value=>updateNoGroupScoresGhin(value)">
            If not enough Group scores, add filler scores using GHIN index
        </b-checkbox>
 <!--       <div class="control">
          <vue-numeric
            class="input ext-center has-margin-left-5"
            :disabled="!method.noGroupScoresGhin || disabled"
            :value="method.noGroupScoresGhinFac*100"
            style="width:6em;"
            currency="%"
            currency-symbol-position="suffix"
            :max="100" :min="0"
            @blur="updateNoGroupScoresGhinFac($event.target.value)"
          />
         
        </div>
      -->       
      </b-field>

      <b-field grouped>
        <p class="control has-margin-top-10">For New, Guest or Inactive players modify GHIN index at </p>
        <div class="control">
          <vue-numeric
            class="input ext-center has-margin-left-5"
            :value="method.guestGhinFac*100"
            style="width:6em;"
            currency="%"
            currency-symbol-position="suffix"
            :max="100" :min="0"
            @blur="updateGuestGhinFac($event.target.value)"
          />
         
        </div>
       
      </b-field>
           
   
       
      

  
       
     
    </div>

 
  </div>
</template>

<script>

// import IndexAvgTableComp from "../Dialogs/IndexAvgTable"
import IndexAvgTable from "../../models/indexAvgTable"
import DBUpdate from "../../helpers/dbUpdate"

export default {
  data() {
    return {

      customAvgTable: this.baseObj.handicap.method.groupIndexTable,
      ghinAvgTable: new IndexAvgTable(true),
      groupIndexAvg:  this.baseObj.handicap.method.groupIndexAvg,
      tableEdit: false,

    };
  },
  
  props: {
    baseObj: Object,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  // components: { "index-avg-table": IndexAvgTableComp },

  computed: {
    handicap() {
      return this.baseObj.handicap
    },
    method() {
      return this.baseObj.handicap.method
    },
    isRound () {
      return this.baseObj.objName === 'Round'
    },
    avgTable () {
      if (this.groupIndexAvg == 'ghin') {
        return this.ghinAvgTable
      }
      else {
        return this.customAvgTable
      }
    }

  },

  methods: {
    updateUseGameIndex (value) {
      const handicap = this.baseObj.handicap.copy()
      handicap.useGameIndex = value
      this.saveHandicap(handicap)
    },

    updateLookupGhinIndex (value) {
      const handicap = this.baseObj.handicap.copy()
      handicap.lookupGhinIndex = value
      this.saveHandicap(handicap)
    },



    updateMaxHandicap (value) {
      const handicap = this.baseObj.handicap.copy()
      handicap.maxHandicap = Number(value)
      this.saveHandicap(handicap)
    },

    updateLimitScores (value) {
      const handicap = this.baseObj.handicap.copy()
      handicap.method.limitScores = value
      this.saveHandicap(handicap)
    },

    updateLimitScoresOption (value) {
      const handicap = this.baseObj.handicap.copy()
      handicap.method.limitScoresOption = value
      this.saveHandicap(handicap)
    },

    updateLimitScoresMonths (value) {
      const handicap = this.baseObj.handicap.copy()
      handicap.method.limitScoresMonths = Number(value)
      this.saveHandicap(handicap)
    },

    // updateMinScores (value) {
    //   const handicap = this.baseObj.handicap.copy()
    //   handicap.method.minScores = value
    //   this.saveHandicap(handicap)
    // },

    // updateNumMinScores (value) {
    //   const handicap = this.baseObj.handicap.copy()
    //   handicap.method.numMinScores = Number(value)
    //   this.saveHandicap(handicap)
    // },

    // updateNumMinScoresMonths (value) {
    //   const handicap = this.baseObj.handicap.copy()
    //   handicap.method.numMinScoresMonths = Number(value)
    //   this.saveHandicap(handicap)
    // },

    updateNoGroupScoresGhin (value) {
      const handicap = this.baseObj.handicap.copy()
      handicap.method.noGroupScoresGhin = value
      this.saveHandicap(handicap)
    },

     updateNoGroupScoresGhinFac (value) {
      const handicap = this.baseObj.handicap.copy()
      handicap.method.noGroupScoresGhinFac = value / 100
      this.saveHandicap(handicap)
    },

    updateGuestGhinFac (value) {
      const handicap = this.baseObj.handicap.copy()
      handicap.method.guestGhinFac = value / 100
      this.saveHandicap(handicap)
    },

    updateGroupIndexAvg (value) {
      const handicap = this.baseObj.handicap.copy()
      handicap.method.groupIndexAvg = value
      this.saveHandicap(handicap)
    },

    // updateLowestGhinGroup (value) {
    //   const handicap = this.baseObj.handicap.copy()
    //   handicap.method.lowestGhinGroup = value
    //   this.saveHandicap(handicap)
    // },

    // updateUseGhinIndexTable (value) {
    //   const handicap = this.baseObj.handicap.copy()
    //   handicap.method.useGhinIndexTable = value
    //   this.saveHandicap(handicap)
    // },

    // openAvgTable() {
    //   this.avgTable = this.baseObj.handicap.method.groupIndexTable
    //   this.title = "Group Index Score Averages"

    //   this.showAvgTable = true
    // },

    setMinPostedScores(value) {
      this.tableEdit = true
      this.avgTable.setMinPostedScores(value);
      
    },
    setMaxPostedScores(value) {
      this.tableEdit = true
      this.avgTable.setMaxPostedScores(value);
    },

    setGroupIndexAvg(value) {
      this.tableEdit = true
    },
 
    cancelTable() {
      this.customAvgTable = this.baseObj.handicap.method.groupIndexTable
      this.ghinAvgTable = new IndexAvgTable(TrustedTypePolicy)
      this.groupIndexAvg = this.baseObj.handicap.method.groupIndexAvg
      this.tableEdit = false
    },
    saveTable()
    {
      const handicap = this.baseObj.handicap.copy()
      if(this.groupIndexAvg == 'ghin') {
        handicap.method.groupIndexTable = this.ghinAvgTable
      }
      else {
        handicap.method.groupIndexTable = this.customAvgTable
      }
      handicap.method.groupIndexAvg = this.groupIndexAvg

      this.saveHandicap(handicap)
      this.tableEdit = false
    },
    updateAvgTable(avgTable) {
      const handicap = this.baseObj.handicap.copy()
      handicap.method.groupIndexTable = avgTable
      this.saveHandicap(handicap)

      this.avgTable.clear()
    },

    async saveHandicap(handicap) {
      const update = new DBUpdate(this.baseObj.docId, { handicap: handicap.getData() })
    
      if (this.isRound) {
        await this.$store.dispatch('round/saveValues', update)
      }
      else {
        await this.$store.dispatch('group/saveValues', update)
      }
   
    }
  }
};
</script>

<style lang="scss">

 
</style>
